import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Input, Typography, Divider } from 'antd';

const { TextArea } = Input;


let initialData = {
    taxType: '',
    taxComputationType: ''
}

class TermsConditions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            TermsConditions: {},
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        console.log('this.props.editData', this.props)
        if (this.props.editData) {
            console.log('type123', this.props.editData)
            let temp = {
                termsConditions: (this.props.editData.termsConditions ||  this.props.editData.termsConditions === '') ? this.props.editData.termsConditions : this.props.editData
            }
            console.log('temp', temp)
            this.setState({ TermsConditions: temp })
        }
    }


    ontermsConditionsChange(e) {
        const TermsConditions = {};
        TermsConditions.termsConditions = e.target.value;
        this.setState({ TermsConditions }, () => {
            this.props.termsConditionsDetailSave(TermsConditions);
        })
    }


    render() {
        const { TermsConditions } = this.state;
        let termValue = TermsConditions.hasOwnProperty('termsConditions') && TermsConditions.termsConditions !== '' ? TermsConditions.termsConditions : ''
        const { Text, Title } = Typography
        console.log('TermsConditions', TermsConditions, termValue)
        return (
            <div >
                <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Enter terms and conditions associated with the order
                    </span>
                </div>
                <TextArea placeholder="Enter Terms and Conditions" value={termValue.termsConditions ? termValue.termsConditions : termValue} onChange={(e) => { this.ontermsConditionsChange(e) }} />
            </div>
        );
    }

}

export default TermsConditions;

