export function saveRules(rulesArray) {

    let mutationGqlStr = ``;
    let mutationFragmentsGqlStr = ``;

    // get total count
    let totalRules = rulesArray.length;
    let processCompleted = 0;
    let i;

    // process each count
    for (i = 0; i < totalRules; i++) {

        let rulesObj = rulesArray[i];

        // dynamic gql
        if (rulesObj.id == null && rulesObj.type == "CREATE") {

            let smsValue = `${rulesObj.notificationChannel}` && `${rulesObj.notificationChannel.SMS}`
                ? `${`["${rulesObj.notificationChannel.SMS}"]`}` : `${`[]`}`

            let emailValue = `${rulesObj.notificationChannel}` && `${rulesObj.notificationChannel.EMAIL}`
                ? `${`["${rulesObj.notificationChannel.EMAIL}"]`}` : `${`[]`}`


            mutationGqlStr = `mutation_${i}: insert_transaction_template_rules(objects: {
                    transactionTemplateId: "${rulesObj.transactionTemplateId}",
                    status:  "${rulesObj.status}",
                    createdBy:  "${rulesObj.createdBy}",
                    ruleType:  "${rulesObj.ruleType}",
                    notificationChannel:  {
                        SMS: ${smsValue},
                        EMAIL:${emailValue}
                       
                    }
                }) {
                    returning {
                      ...transaction_template_rules_fields
                    }
                }
                `

            processCompleted = processCompleted + 1;

        } else {
            if (rulesObj.type == "UPDATE") {
                let smsValue = `${rulesObj.notificationChannel}` && `${rulesObj.notificationChannel.SMS}`
                ? `${`["${rulesObj.notificationChannel.SMS}"]`}` : `${`[]`}`

            let emailValue = `${rulesObj.notificationChannel}` && `${rulesObj.notificationChannel.EMAIL}`
                ? `${`["${rulesObj.notificationChannel.EMAIL}"]`}` : `${`[]`}`

                mutationGqlStr = `mutation_${i}: update_transaction_template_rules(where: {
                        id: {_eq: "${rulesObj.id}"}
                    }, _set: {
                        transactionTemplateId: "${rulesObj.transactionTemplateId}",
                        status:  "${rulesObj.status}",
                        createdBy:  "${rulesObj.createdBy}",
                        ruleType:  "${rulesObj.ruleType}",
                        notificationChannel:  {
                            SMS: ${smsValue},
                            EMAIL:${emailValue}
                           
                        }
                    }) {
                        returning {
                          ...transaction_template_rules_fields
                        }
                    }
                    `

                processCompleted = processCompleted + 1;
            }

            if (rulesObj.type == "DELETE") {

                mutationGqlStr = `mutation_${i}: delete_transaction_template_rules(where: {
                        id: {_eq: "${rulesObj.id}"}
                    }) {
                        returning {
                          ...transaction_template_rules_fields
                        }
                    }
                    `

                processCompleted = processCompleted + 1;

            }
        }

        mutationFragmentsGqlStr = `${mutationFragmentsGqlStr} ${mutationGqlStr} `;

    }

    let finalGqlStr = `mutation {
                    ${mutationFragmentsGqlStr}
                }
                fragment transaction_template_rules_fields on transaction_template_rules {
                    id
                    transactionTemplateId
                    status
                    createdBy
                    ruleType
                    notificationChannel
                }
            `;

    console.log('finalGqlStr', finalGqlStr);

    return finalGqlStr;

}


