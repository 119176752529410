import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Input, Typography, Divider } from 'antd';

const { TextArea } = Input;


let initialData = {
    taxType: '',
    taxComputationType: ''
}

class PaymentDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            PaymentDetails: [],
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        if (this.props.editData) {
            this.setState({ PaymentDetails: this.props.editData })
        }
    }


    onPaymentDetailChange(e) {
        const PaymentDetails = {};
        PaymentDetails.paymentDetail = e.target.value;
        this.setState({ PaymentDetails }, () => {
            this.props.paymentDetailSave(PaymentDetails);
        })
    }


    render() {
        const { PaymentDetails } = this.state;
        const { Text, Title } = Typography

        return (
            <div >
                <div style={{ paddingBottom: '17px' }} >
                    <span>
                    Enter payment related information associated with the order
                    </span>
                </div>
                <TextArea placeholder="Enter Payment Details" value={PaymentDetails.paymentDetail} onChange={(e) => { this.onPaymentDetailChange(e) }} />
            </div>
        );
    }

}

export default PaymentDetails;

