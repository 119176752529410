import React from "react";
import { Form } from "antd";
import { Mutation, Subscription } from 'react-apollo';
import SignupForm from '../../components/SignupForm';
import { USER_WITH_ID_SUB } from '../../graphQl/subscriptions';
import { ADD_USER_MUTATION } from '../../graphQl/mutations';

import { connect } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from "appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignUp extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignUp(values);
      }
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      uid: ''
    };
  }

  componentDidMount() {

    //get uid and phone number from local storage
    const phoneNumber = localStorage.getItem('phoneNumber');
    const uid = localStorage.getItem('uid');

    this.setState({
      phoneNumber,
      uid,
    });
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }

  // function to check if user id is already stored in user table, navigate to dashboard screen 
  // otherwise show register form to register a user
  confirmLogin = data => {
    const userLoggedIn = data.user.length > 0;
    if (userLoggedIn) {
      const listingName = localStorage.getItem('listingName')
        ? localStorage.getItem('listingName')
        : '';
      if (listingName.length > 0) {
        this.props.history.push(`/sample`);
      }
      if(this.props && this.props.location && this.props.location.state && this.props.location.state.value){
        this.props.history.push(`/${this.props.location.state.value}`)
      }
      else{
        this.props.history.push(`/app/console/listings`)
      }
     
    }
  };

  //when clicking register button
  onRegister = (insert_user, data) => {
    const addUserInfo = this.getAddUserInfo(data);
    insert_user({ variables: addUserInfo });
  };

  //get tped register form values
  getAddUserInfo = userInfo => {
    const addUserInfo = {};
    addUserInfo.firstName = userInfo.firstName;
    addUserInfo.lastName = userInfo.lastName;
    addUserInfo.email = userInfo.email;
    addUserInfo.phoneNumber = localStorage.getItem('phoneNumber');
    addUserInfo.id = localStorage.getItem('uid');
    addUserInfo.accountStatus = 'active';
    addUserInfo.updatedAt = new Date().toISOString();

    return addUserInfo;
  };

  render() {
    const userInfo = this.state;
    const { open, uid } = this.state;
    return (
      //call query to check user id already stored in user table
      <Subscription subscription={USER_WITH_ID_SUB} variables={{ id: uid }}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <div className="register">
                <div className="loader-view"><CircularProgress /></div>
              </div>
            );
          if (error) return error.message;

          //call function to check if user id is already stored in user table 
          this.confirmLogin(data);

          return (
            <div
              className="gx-app-login-wrap">
              {/* register form Component */}
              <Mutation mutation={ADD_USER_MUTATION}>
                {(insert_user, { data, loading, error }) => {
                  if (loading) {
                    return (
                      <div className="gx-loader-view">
                        <CircularProgress />
                      </div>
                    )
                  }
                  return <SignupForm
                    id="register-form"
                    userInfo={userInfo}
                    type="register"
                    loader={loading}
                    onRegister={data => {
                      this.onRegister(insert_user, data);
                    }}
                  />
                }}
              </Mutation>
            </div>
          );
        }}
      </Subscription>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedSignUpForm);
