import React from "react";
import CustomScrollbars from "util/CustomScrollbars";
import { Button, Checkbox, Form, Icon, Input, message, Card, Divider, Switch, List } from "antd";
import { Subscription } from "react-apollo";
import client from '../../apollo/apollo';
import {ADD_TAB_NAMES, REMOVE_TAB_NAMES  } from '../../graphQl/mutations';


import { TAB_NAMES } from '../../graphQl/subscriptions';
import { BREAK } from "graphql";

message.config({
    duration: 1,
});
 
class AddTranscation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ListingData: [
                'Contract', 'Lead', 'Order', 'Review', 'Registration', 'Shipment'
            ],
            tabsArray: []        
        }
    }


    click(tabsArray) {
        console.log(tabsArray)
    }


onChange(e, item, index) {
    console.log(e, index, item)
    item = item.toString();
    const pushitem = { listingId: localStorage.getItem('selectedListingId'),
        transactionType: item.toLowerCase() , tabName: item };
    if(e === true) {
        this.state.tabsArray.push(pushitem);
        client.mutate({ variables: pushitem, mutation: ADD_TAB_NAMES }).then(async (result) => {
            await message.success(`${item} Added to Listing!`);
        }).catch((err) => {
            console.log(err)

            message.error(`Failed adding ${item} from listing!`)
        });
    } else if (e === false) {
        this.state.tabsArray.some((tabs, index) => {
           if(tabs.tabName === item ) {
            const pushitem = { listingId: localStorage.getItem('selectedListingId'),
             transactionType: item.toLowerCase() , tabName: item };
               client.mutate({ variables: pushitem, mutation: REMOVE_TAB_NAMES }).then(async (result) => {
                await message.success(`${item} Removed from Listing!`);
                }).catch((err) => {
                    console.log(err)

                    message.error(`Failed removing ${item} from listing!`)
                });
           }
        })  
    }
    this.setState({ tabsArray: this.state.tabsArray})
}

    render() {

        return (
            <div>
                <Subscription
                    subscription={TAB_NAMES}
                    variables={{ listingId: localStorage.getItem('selectedListingId') }}>
                    {({ data, loading, error }) => {
                        console.log('***', data, loading, error)
                        if (loading)
                            return (
                                <div className="loading"></div>
                            );
                        if (error) return '';

                            this.state.tabsArray = data.transaction_ui;
                     
                            const length = this.state.tabsArray.length;
                            console.log('*****', this.state.tabsArray);   
                       
                        return <div className="transaction-list">
                            <p style={{fontSize:14,fontWeight: 500,marginBottom: 10}}>Digital Transaction Options:</p>
                            <p style={{fontSize:14,marginBottom:20}}>Use the toggle to turn on or turn off various types of transactions for this listing</p>
                            <List className="gx-mb-4"
                                size="small"
                                bordered
                                dataSource={this.state.ListingData}
                                renderItem={(item, index) => 
                                
                                    <List.Item>
                                        <div className="transaction-view-modal">
                                    <div style={{ width:'20%', alignContent: 'center'}}>{item}</div>
                                    <div className="">
                                        <Switch style={{marginLeft: '14%'}}
                                        onChange={(e) => this.onChange(e, item, index) }
                                        checked={this.state.tabsArray.some(tabs => tabs.tabName === item)}
                                         />
                                         </div>
                                         </div>
                                </List.Item>
                                
                                }
                            />
                        </div>
                    }}
                </Subscription>
            </div>
        )
    }
}


export default AddTranscation;
 