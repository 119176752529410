import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { firebase } from '../../firebase/firebase';

class UserInfo extends Component {

  //on logout user
  onLogoutUser = () => {
    console.log('onLogoutUser123')
    //call firebase signout function
    firebase.auth().signOut()
      .then(async () => {
        // Sign-out successful.
        await localStorage.clear();
        window.sessionStorage.clear();
        window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
        this.props.history.push('/signin')

      }, (error) => {
        console.log('logout susccess', error)
      });
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>My Account</li>
        <li>Connections</li>
        <li onClick={() => this.onLogoutUser()}>Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
        trigger="click">
        <Avatar src='https://via.placeholder.com/150x150'
          className="gx-avatar gx-pointer" alt="" />
      </Popover>
    )

  }
}

export default connect(null, { userSignOut })(UserInfo);
