import React from "react";
import {
  Avatar, Badge, Col, DatePicker, Input, Menu, Row, Button, Tooltip, Checkbox, Popover,
  Popconfirm, message, Select, Icon, Spin, Tag, Typography
} from "antd";
import Moment from "moment";
import CustomScrollbars from "util/CustomScrollbars";
import users from "routes/List/data/users";
import client from '../../../apollo/apollo';
import JsonFormBuilder from '../../JsonFormBuilder/index';
import { _history, TRANSACTION_DETAIL_ID, GET_TEMPLATE_STATUS } from '../../../graphQl/subscriptions';
import NotificationHistory from './NotificationHistory';
import gql from 'graphql-tag';
import { Query, Mutation, Subscription } from 'react-apollo';
import { FORM_TEMPLATE, ADMIN_CHECK, QUERY_ASSIGNED_TO, GET_PRODUCT_INVENTORY } from 'graphQl/queries';
import { ASSIGNED_TO, TEAM_MEMBERS_EXCEPT_ADMIN, GET_SHORT_URL_BY_TRANSACTION_ID } from 'graphQl/subscriptions';
import {
  INSERT_PRODUCT_INVENTORY,
  UPDATE_PRODUCT_INVENTORY,
  INSERT_PRODUCT_INVENTORY_HISTORY
} from 'graphQl/mutations';

import { ADD_SMS_MUTATION, UPDATE_ECOMMERCE_ORDER, ADD_TRANSACTION_UPLOAD, DELETE_TRANSACTION, STATUS_CHANGE, ASSIGN_MEMBERS, REMOVE_ASSIGNED, UPDATE_TRANSACTION, DELETE_SMS_MUTATION, SAVE_AS_DRAFT, ADD_TRANSACTION as transaction_mutation, } from 'graphQl/mutations'; import { withRouter } from 'react-router-dom'
import { CONFIG } from '../../../config/config';
import { getENVConfig } from '../../../util/common'
import {
  BrowserView,
} from "react-device-detect";
import EcommerceForm from '../../EcommerceForm/index'
import EditEcommerce from '../EditEcommerce/index'
import _ from 'lodash';
import { Collapse } from 'antd';
import moment from 'moment'


const { Panel } = Collapse;

const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;
const uuidv1 = require('uuid/v1');
var searchVal = '';


class ListDetail extends React.Component {

  constructor(props) {
    super(props);
    const { title, notes } = props.todo;
    this.state = {
      todo: props.todo,
      title,
      notes,
      anchorEl: undefined,
      userMenu: false,
      labelMenu: false,
      editTitle: false,
      editNote: false,
      message: '',
      eligibleStatusList: [],
      transactionStatus: "",
      notificationHistoryData: [],
      checkedArray: [],
      visible: false,
      visibleSave: false,
      modalId: null,
      shortURL: '',
      saveStatusId: null,
      isEditContract: true,
      transactionFormData: {},
      isSaveEditContract: false,
      isStatusEditable: false,
      displayMembers: false,
      EditEcommerceOrderValues: props.todo.formData.editedBySeller ? props.todo.formData.editedBySellerValues : {},
      productInventoryDetails: []
    };
  }

  componentDidMount() {

    this.getTransactionDetail()
    //get template status list to allow status change option
    this.getTemplateEligibleStatusList()

    const { shortUrl } = this.state
    const variables = {
      "transactionId": this.props.todo.id
    };

    const gqlValue = GET_SHORT_URL_BY_TRANSACTION_ID
    const query = gql`
        ${gqlValue}
      `
    client
      .subscribe({
        query,
        variables: variables
      })
      .subscribe(
        res => {
          if (res && res.data && res.data.short_url && res.data.short_url.length > 0) {
            let urlShort = res.data.short_url[0].shortURL
            this.setState({
              shortURL: urlShort
            }, () => {
            })
          } else {
            this.setState({
              shortURL: ""
            }, () => {
            })
          }
          // window.open(this.props.history.push(`/${shortUrl}`))
        },
        err => {
        }
      )

  }

  getTemplateEligibleStatusList = () => {
    if (this.props.todo && this.props.todo.template && this.props.todo.template.id) {
      const tempVariable = {
        transactionTemplateId: this.props.todo.template.id
      }
      try {
        client
          .subscribe({ query: GET_TEMPLATE_STATUS, variables: tempVariable })
          .subscribe(
            res => {
              if (res && res.data && res.data.transaction_template_status &&
                res.data.transaction_template_status.length > 0) {

                //if the status isChangeble value is true, allow them to change the status
                let status = res.data.transaction_template_status
                if (this.props.todo && this.props.todo.currentStatus) {
                  let value = this.props.todo.currentStatus
                  let data = _.find(status, function (item) {
                    if (item) {
                      return item.statusName === value
                    }
                  });

                  if (data) {
                    this.setState({ isStatusEditable: data.isChangeable })
                  } else {
                    this.setState({ isStatusEditable: false })
                  }
                }
              } else {
                this.setState({ isStatusEditable: false })
              }
            }, err => {
              this.setState({ isStatusEditable: false })
            })
      } catch (e) {
      }
    }
  }


  // transaction detail by id
  getTransactionDetail = () => {
    const variables = {
      "transactionId": this.props && this.props.todo ? this.props.todo.id : ''
    };

    const gqlValue = TRANSACTION_DETAIL_ID
    const query = gql`
        ${gqlValue}
      `
    client
      .subscribe({
        query,
        variables: variables
      })
      .subscribe(
        res => {
          if (res && res.data && res.data.transaction && res.data.transaction.length > 0) {
            let transaction = res.data.transaction[0].formData
            this.setState({
              transactionFormData: transaction
            }, () => {
              this.getinventoryDetails(this.state.transactionFormData);
            })
          }
          // // window.open(this.props.history.push(`/${shortUrl}`))
        },
        err => {
          console.log('res123 err', err)
        }
      )
  }

  getinventoryDetails = (transactionFormData) => {
    if (transactionFormData &&
      transactionFormData.LineItems && transactionFormData.LineItems.length > 0) {
      let productInventoryDetails = [];
      transactionFormData.LineItems.map(async (product, index) => {
        console.log('onaloop', product)
        if (product.hasVariant) {
          product.product_id = product.product_inventory_details &&
          product.product_inventory_details[0] && 
          product.product_inventory_details[0].product_id &&
          product.product_inventory_details[0].product_id;
        }
        if(product.product_id) {
          client.query({
            query: GET_PRODUCT_INVENTORY, variables: {
              templateId: product.transaction_template_id,
              productId: product.product_id
            },
            fetchPolicy: 'no-cache',
          }).then((d) => {
            console.log(d, 'res')
            productInventoryDetails.push(d.data.product_inventory)
            this.setState({ productInventoryDetails });
          });
        }
      });
    }
  }


  componentWillMount() {
    this.setState({ selectedTemplate: this.props.templateData }, () => {
      const { selectedTemplate } = this.state
      if (selectedTemplate && selectedTemplate.eligibleStatusList && selectedTemplate.eligibleStatusList.status) {
        this.setState({ eligibleStatusList: selectedTemplate.eligibleStatusList.status })
      }
    });
    if (this.props.todo && this.props.todo.currentStatus) {
      this.setState({ transactionStatus: this.props.todo.currentStatus })
    }

    this.getNotificationHistory();
  }

  //get transaction notification history
  getNotificationHistory = () => {
    let { id } = this.props.todo;
    const variables = {
      "transactionId": id
    };

    const gqlValue = _history
    const query = gql`
        ${gqlValue}
      `
    client
      .subscribe({
        query,
        variables: variables
      })
      .subscribe(
        res => {
          if (res && res.data) {
            let data = res.data
            let historyArray = data.transaction_status_history.length > 0 ? data.transaction_status_history : [];
            let alterArray = (data.transaction && data.transaction.length > 0 && data.transaction[0]['broadcastRelated'] && data.transaction[0]['broadcastRelated'].length > 0) ? data.transaction[0]['broadcastRelated'] : [];
            data = historyArray.map((val, index) => {
              if (val.relatedBroadcast != null) {
                return {
                  Status: val.status,
                  notifyType: val.relatedBroadcast.type,
                  Recipient: val.relatedBroadcast.logs[0] && val.relatedBroadcast.logs[0]['recipientContact'],
                  deliveryStatus: val.relatedBroadcast.logs[0] && val.relatedBroadcast.logs[0]['deliveryStatus'],
                  'linkClicked': val.relatedBroadcast.logs[0] && val.relatedBroadcast.logs[0]['transactionUrls'] && val.relatedBroadcast.logs[0]['transactionUrls'].length > 0 ?
                    <a href={`${getENVConfig(CONFIG.DOMAIN)}/${val.relatedBroadcast.logs[0]['transactionUrls'][0]['shortURL']}?mode=inapp`} target="_blank" >{`Transaction(${val.relatedBroadcast.logs[0]['transactionUrls'][0]['linkClickCount']})`}</a> : '',
                  'sentAt': Moment(val.relatedBroadcast.logs[0] && val.relatedBroadcast.logs[0]['createdAt']).format('MMMM Do YYYY, h:mm:ss a'),
                  // action: <div><button id="not-sent" type="button" onClick={() => this.renotify(val.status, _templateId, [id], val.relatedBroadcast.type)} class={"btn btn-primary"}> {'re notify'} </button></div>

                }
              } else if (alterArray && alterArray[index] !== undefined) {
                return {
                  Status: val.status,
                  notifyType: alterArray && alterArray[index] && alterArray[index]['type'],
                  Recipient: alterArray && alterArray[index] && alterArray[index]['logs'] && alterArray[index]['logs'][0]['recipientContact'],
                  deliveryStatus: alterArray && alterArray[index] && alterArray[index]['logs'] && alterArray[index]['logs'][0]['deliveryStatus'],
                  linkClicked: alterArray && alterArray[index] && alterArray[index]['logs'] && alterArray[index]['logs'][0]['transactionUrls'] && <a href={`${getENVConfig(CONFIG.DOMAIN)}/${alterArray[index]['logs'][0]['transactionUrls'][0]['shortURL']}?mode=inapp`} target="_blank" >{`Transaction(${alterArray[index]['logs'][0]['transactionUrls'][0]['linkClickCount']})`}</a>,
                  sentAt: alterArray && alterArray[index] && alterArray[index]['logs'] && Moment(alterArray[index]['logs'][0]['createdAt']).format('MMMM Do YYYY, h:mm:ss a'),
                  // action: <div><button id="not-sent" type="button" onClick={() => this.renotify(val.status, _templateId, [id], alterArray[index]['type'])} class={"btn btn-primary"}> {'re notify'} </button></div>

                }
              } else {
                return {}
              }
            })

            this.setState({ notificationHistoryData: data }, () => {

            })
          }
        },
        err => {
          console.log('res123 err', err)
        }
      )
  }

  onSelectFile = event => {
    const file = event.target.files[0];

    this.setState({
      file,
      fileName: file.name,
    });
  };

  onAddMembers = async (checkedarray) => {
    if (checkedarray && checkedarray.length > 0) {
      checkedarray.map((checkedData, index) => {
        if (checkedData.checked) {
          this.state.checkedArray.push(checkedData);
        }
      });
    }
    let { checkedArray } = this.state;
    if (checkedArray.length > 0) {
      let { id } = this.props.todo;

      let assignedBy = localStorage.getItem('uid');
      let assign_object = new Array();

      let transaction_variable = { 'transactionId': id }
      let responseOfAssigned = await client.mutate({ mutation: QUERY_ASSIGNED_TO, variables: transaction_variable })
      let assignedMembers = [];

      responseOfAssigned.data.transaction_assigned_to.map(members => assignedMembers.push(members.assignedTo))
      checkedArray.map((assignedTo) => {
        if (assignedMembers.indexOf(assignedTo) == -1) {
          assign_object.push({ "assignedBy": assignedBy, "assignedTo": assignedTo.id, "transactionId": id });
        }
      });

      let variables = { "assign_object": assign_object }
      await client.mutate({ mutation: ASSIGN_MEMBERS, variables: variables }).then((thendata) => {
        this.setState({ openAdmin: false, checkedArray: [] });
        message.success('Member Assigned SuccessFully');
      })
        .catch((err) => {
          message.error('Member Assigning Failed');
        });
    } else {
      message.error('No contacts selected');
    }
  }

  onDeleteTransaction = (item) => {

    if (item && item.id) {
      let variables = { transactionId: item.id, status: "ARCHIVED" }
      const response = client.mutate({ mutation: DELETE_TRANSACTION, variables: variables }).then((res) => {
        message.success("Transaction Deleted Successfully")
        if (this.props.onDeleteTransaction) {
          this.props.onDeleteTransaction()
        }

      }).catch(err => {
        message.error(err.message)
      })
    }
  }

  dropDownChange(value) {
    if (value) {
      this.setState({ transactionStatus: value })
    }
  }

  onSaveConfirm = (id) => {
    this.setState({
      saveStatusId: id
    }, () => {
      this.setState({
        visibleSave: true
      })
    })
  }

  handleSave = () => {
    this.setState({
      visibleSave: false,
      transactionStatus: this.props.todo.currentStatus
    })
  }

  onSaveWithStatus = (listItem) => {
    if (this.state.saveStatusId) {
      let { id } = this.props.todo;
      let { transactionStatus, transactionFormData, productInventoryDetails } = this.state;
      let createdBy = localStorage.getItem("uid");
      let updatedAt = Moment().format()

      let variables = {
        'transactionId': id,
        currentStatus: transactionStatus,
        createdBy: createdBy,
        updatedAt: updatedAt, reSend: "false"
      }
      const response = client.mutate({ mutation: STATUS_CHANGE, variables: variables }).then((res) => {
      ////////////////////////

      console.log(transactionStatus, 'transactionStatus')

      if (transactionStatus.toLowerCase() === 'delivered') {
        console.log(transactionFormData, 'transactionFormData')
        // productInventoryDetails.map(())
        let inventory_records = [];
        if (transactionFormData && 
          transactionFormData.LineItems && transactionFormData.LineItems.length > 0) {
          transactionFormData.LineItems.map((data, index) => {
            console.log('dtaaaa', data, data.product_inventory_details, productInventoryDetails)
            if (data && data !== {} &&    
              data.transaction_template_id &&
              data.product_inventory_details) {
              if (data.hasVariant) {
                data.product_id = data.product_inventory_details[0].product_id;
              }
              console.log(productInventoryDetails.filter((prod) => { if (prod[0] && prod[0].product_id) { return prod[0].product_id === data.product_id } })[0], 'find', data, productInventoryDetails)
              if (productInventoryDetails.filter((prod) => { if (prod[0] && prod[0].product_id) { return prod[0].product_id == data.product_id } }).length > 0) {
                let variantTableValues = productInventoryDetails.filter((prod) => { if (prod[0] && prod[0].product_id) { return prod[0].product_id === data.product_id } })[0];
                console.log('foundval', variantTableValues);

                client.mutate({
                  mutation: UPDATE_PRODUCT_INVENTORY, variables: {
                    transaction_template_id: variantTableValues[0].transaction_template_id,
                    product_id: variantTableValues[0].product_id,
                    onhand_quantity: variantTableValues[0].onhand_quantity - data.selected_quantity,
                  }
                }).then((res) => {
                });

                // UpdateProductVariants({
                //   variables: {
                //     transaction_template_id: variantTableValues[0].transaction_template_id,
                //     product_id: variantTableValues[0].product_id,
                //     onhand_quantity: variantTableValues[0].onhand_quantity - data.selected_quantity,
                //   }
                // });

                client.mutate({
                  mutation: INSERT_PRODUCT_INVENTORY_HISTORY, variables: {
                    prodInventoryHistory: [{
                      product_id: variantTableValues[0].product_id,
                      onhand_quantity: variantTableValues[0].onhand_quantity,
                      update_type: 'sale order',
                      update_quantity: variantTableValues[0].onhand_quantity - data.selected_quantity,
                      update_details: { orderId: transactionFormData.orderId, quantity: data.selected_quantity },
                      transaction_template_id: variantTableValues[0].transaction_template_id,
                    }]
                  }
                }).then((res) => {
                  this.getinventoryDetails(this.state.transactionFormData);
                });

              } else {
                console.log('unfoundval');
                let inventoryof_product = {
                  transaction_template_id: data.transaction_template_id,
                  product_id: data.product_id,
                  onhand_quantity: -1,
                }
                inventory_records.push(inventoryof_product);
                client.mutate({
                  mutation: INSERT_PRODUCT_INVENTORY_HISTORY, variables: {
                    prodInventoryHistory: [{
                      product_id: data.product_id,
                      onhand_quantity: 0,
                      update_type: 'sale order',
                      update_quantity: - 1,
                      update_details: { orderId: transactionFormData.orderId },
                      transaction_template_id: data.transaction_template_id,
                    }]
                  }
                }).then(() => {
                  this.getinventoryDetails(this.state.transactionFormData);
                });
              }
            }
          });

          console.log('variables1', inventory_records)
          if (inventory_records && inventory_records.length > 0) {

            client.mutate({
              mutation: INSERT_PRODUCT_INVENTORY, variables: {
                prodInventory: inventory_records
              }
            }).then(() => {

            });

            // addProductVariants({
            //   variables: {
            //     prodInventory: inventory_records
            //   }
            // })
          }
        }
      }
      //////////////////////////
      this.setState({
        visibleSave: false
      })
      message.success('Status has been changed')
      }).catch (err => {
        message.error(err.message)
      });
    }
  }

  handleOk = (id) => {
    this.setState({
      modalId: id
    }, () => {
      this.setState({
        visible: true
      })
    })
  }

  onRemoveMembers = async (assignedTo) => {
    let id = this.state.modalId;
    let variables = { "assignedTo": id, transactionId: this.props.todo.id };
    const response = await client.mutate({ mutation: REMOVE_ASSIGNED, variables: variables }).then((res) => {
      message.success('Removed Member successfully')
      this.setState({
        visible: false
      })
    }).catch(err => {
      message.error(err.message)
    });

  };

  handleCancel = () => {
    this.setState({
      visible: false
    })
  }

  onEditIconClick = () => {
    this.setState({ isEditContract: !this.state.isEditContract })
  }

  GoBackToDetail = () => {
    this.setState({ isEditContract: !this.state.isEditContract, isSaveEditContract: false })
  }

  EditEcommerceOrder = (EditEcommerceOrderValues) => {
    this.setState({ EditEcommerceOrderValues: EditEcommerceOrderValues })
  }

  editEcommerceSave = async () => {
    let editedFormValues = {};
    let editedLineItems = [];
    let editedTotal = {};
    let delivery = {};
    let Pickup = {};
    await this.getFormData().then(res => {
      editedFormValues = res;
      editedLineItems = editedFormValues.LineItems;
      editedTotal = editedFormValues.Total;
    });

    //append updated form data with old one
    let updatedFormdata = this.state.todo.formData;
    updatedFormdata.editedBySellerValues = this.state.EditEcommerceOrderValues;
    updatedFormdata.editedBySellerValues.editedLineItems = editedLineItems;
    updatedFormdata.editedBySellerValues.editedTotal = editedTotal;
    updatedFormdata.editedBySeller = true;
    let variables = {
      id: this.state.todo.id,
      fromdata: updatedFormdata
    }
    await client.mutate({ mutation: UPDATE_ECOMMERCE_ORDER, variables: variables }).then((thendata) => {
      this.setState({ openAdmin: false, checkedArray: [] });
      message.success('Order Updated Successfully');
      window.location.reload();
    })
      .catch((err) => {
        message.error('Order Updation Failed');
      });
  }

  renderSelectDropDown = (templateSignedStatus) => {
    return (
      <Select
        defaultValue="Eligible Status"
        className="add-drop-down"
        style={{ width: 250, marginLeft: '5%' }}
        value={this.state.transactionStatus}
        onChange={(value) => this.dropDownChange(value)}>
        {this.state.eligibleStatusList.length > 0 && this.state.eligibleStatusList.map(val => {
          //dont show signed status
          if (templateSignedStatus) {
            if (templateSignedStatus !== val) {
              return (
                <Option value={val}>{val}</Option>
              )
            }
          } else {
            return (
              <Option value={val}>{val}</Option>
            )
          }

        })
        }
      </Select>
    )
  }




  render() {
    const { todo, shortURL, transactionStatus } = this.state;
    const { Text } = Typography
    let user = null;
    if (todo.user > 0)
      user = users.find((user) => user.id === todo.user);

    let templateSignedStatus = (this.props.todo.template
      && this.props.todo.template.signedStatus) ? this.props.todo.template.signedStatus : ''

    let templateCurrentStatus = (this.props.todo && this.props.todo.currentStatus) ?
      this.props.todo.currentStatus : ''

    return (
      <div className="gx-module-detail gx-module-list">
        <CustomScrollbars className="gx-todo-detail-content-scroll" id="form-detail-view">
          <div className="gx-module-detail-item gx-module-detail-header">

            {this.props.todo && this.props.todo !== 'New' &&
              <div style={{ marginLeft: "20px", marginTop: "20px", marginRight: "20px", textTransform: 'capitalize' }}>

                <div className="add-user-view" style={{ display: 'flex' }}>




                  {this.state.eligibleStatusList.length > 0 &&
                    <div className="dropdown-content-view" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>

                      {
                        this.state.isEditContract === true &&
                        <div className="submit-drop-view">

                          {
                            this.state.isStatusEditable === true ?
                              <div>
                                {this.renderSelectDropDown(templateSignedStatus)}
                              </div> :
                              // for signed status
                              <Tag color="orange" style={{ marginTop: 10, fontSize: 14 }}>
                                {this.state.transactionStatus}</Tag>
                          }

                          {/* {templateSignedStatus ?
                            //if signed status assigned in rules
                            <div>
                              {
                                //other than igned status
                                (templateSignedStatus !== templateCurrentStatus) ?
                                  <div>
                                    {this.renderSelectDropDown(templateSignedStatus)}
                                  </div> :
                                  // for signed status
                                  <Tag color="orange" style={{ marginTop: 10, fontSize: 14 }}>
                                    {this.state.transactionStatus}</Tag>
                              }
                            </div> :
                            //if signedStatus status not assigned in rule
                            <div>
                              {this.renderSelectDropDown(templateSignedStatus)}
                            </div>
                          } */}

                        </div>
                      }
                      {this.state.visibleSave === true && this.props.todo && this.props.todo.currentStatus &&
                        <Popconfirm
                          placement="top"
                          visible={this.state.visibleSave}
                          title={"Are you sure to change status from" + ' ' + this.props.todo.currentStatus + ' ' + 'to' + ' ' + transactionStatus + '?'}
                          onConfirm={() => this.onSaveWithStatus()}
                          onCancel={this.handleSave}
                          okText="Proceed"
                          cancelText="Cancel"
                        >
                        </Popconfirm>
                      }

                      <div className="icon-container-view">
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                          {
                            ((this.state.isStatusEditable) &&
                              (this.state.isEditContract === true)) &&

                            <Tooltip title='Save'>
                              <Icon id="icon-view" className="gx-icon-btn icon icon-trash user-page-icon"
                                type="save" onClick={() => this.onSaveConfirm(this.props.todo)}
                                style={{ paddingLeft: 5, marginRight: 10 }}
                              />
                            </Tooltip>
                          }

                          {(this.state.isEditContract === false) &&
                            // for transaction edit save
                            <Tooltip title='Save'>
                              <Icon id="icon-view" className="gx-icon-btn icon icon-trash user-page-icon" type="save"
                                onClick={() => {
                                  if (this.props.todo.template.displayTemplateType === "EcommerceForm") {
                                    this.editEcommerceSave();
                                  } else {
                                    this.setState({ isSaveEditContract: true })
                                  }
                                }}
                                style={{ paddingRight: 5, marginRight: 10 }}
                              />
                            </Tooltip>
                          }

                          {/* edit icon */}
                          {(templateSignedStatus !== templateCurrentStatus &&
                            this.props.todo && this.props.todo.template && this.props.todo.template.displayTemplateType !== "EcommerceForm") &&
                            <Tooltip title='Edit'>
                              <Icon id="icon-view" style={{ marginRight: 10 }} className="gx-icon-btn icon icon-trash user-page-icon" type="edit"
                                onClick={() => this.onEditIconClick()} />
                            </Tooltip>
                          }
                          {(this.props.todo.template.displayTemplateType === 'EcommerceForm' &&
                            this.props.todo && this.props.todo.template) &&
                            <Tooltip title='Edit'>
                              <Icon id="icon-view" style={{ marginRight: 10 }} className="gx-icon-btn icon icon-trash user-page-icon" type="edit"
                                onClick={() => this.onEditIconClick()} />
                            </Tooltip>
                          }

                          {/* {
                            (templateSignedStatus !== templateCurrentStatus) && */}

                          <Popconfirm
                            placement="top"
                            title="Are you sure delete this transaction?"
                            onConfirm={() => this.onDeleteTransaction(this.props.todo)}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip title='Delete'>
                              <i className="gx-icon-btn icon icon-trash user-page-icon" style={{ width: 25, marginRight: 10 }} />
                            </Tooltip>
                          </Popconfirm>
                          {/* } */}


                          {/* <Tooltip title='Fav'>
                          <i className="
                          gx-icon-btn icon icon-star-o" style={{ width: 25 }} />
                        </Tooltip> */}
                          {shortURL &&
                            <Icon id="icon-view" style={{ marginRight: 10 }} className="gx-icon-btn icon icon-trash user-page-icon" type="download" onClick={() => window.open(`/${shortURL}?mode=inapp`)} />
                          }

                          {localStorage.getItem('role') === 'listingAdmin' &&

                            // <CustomScrollbars className="gx-todo-detail-content-scroll" id="add-user-container"> 
                            <div className="add-user-modal" >
                              <Popover
                                overlayClassName="user-popup"
                                content={
                                  <Subscription subscription={ASSIGNED_TO}
                                    variables={{ transactionId: this.props.todo.id }}>
                                    {({ data, loading, error }) => {
                                      // if (loading)
                                      //   return (
                                      //     <Grid item className="AvatarDataItem">
                                      //       <div className="chat-avatar" style={{ color: 'white' }}>
                                      //       </div>
                                      //     </Grid>
                                      //   );
                                      if (error) return error.message;

                                      const assignedMembers = data && data.transaction_assigned_to && data.transaction_assigned_to.length > 0 && data.transaction_assigned_to ? data.transaction_assigned_to : [];

                                      return (
                                        <>

                                          <Popover
                                            content={
                                              <Subscription
                                                subscription={TEAM_MEMBERS_EXCEPT_ADMIN}
                                                variables={{
                                                  listingId: localStorage.getItem('selectedListingId'),
                                                }}
                                              >
                                                {({ data, loading, error }) => {
                                                  if (loading)
                                                    return (
                                                      <div className="loader-view">

                                                      </div>
                                                    );
                                                  if (error) return error;
                                                  let fetchData = data.listing_team;
                                                  let newArray = new Array()
                                                  fetchData.map(user => {
                                                    if (user.userInfo) {
                                                      user.userInfo.checked = false;
                                                      newArray.push(user.userInfo);
                                                    }
                                                  });
                                                  fetchData = newArray;

                                                  const pageNumber = Math.ceil(fetchData.length / 100);
                                                  const pageData = fetchData.slice(0, 100);
                                                  return (

                                                    <div className="popOvers" style={{}}>
                                                      <div >
                                                        {/* <Search
                                              placeholder="input search text"
                                              onSearch={value => this.setState({ searchVal: value })}
                                              onChange={val => { searchVal = val.target.value }}
                                              enterButton
                                              defaultValue={this.state.searchVal}
                                            /> */}
                                                        <h6 className="PopoverAdminHeader">
                                                          Add Team Members
                                            </h6>
                                                      </div>
                                                      <div style={{ overflowY: 'scroll', height: '300px', paddingLeft: '11px' }}>
                                                        {fetchData.map((data, index) => {
                                                          return (
                                                            <div >
                                                              <Row>
                                                                <Col>
                                                                  <Checkbox color="primary"
                                                                    onClick={(event) => {
                                                                      data.checked = event.target.checked
                                                                    }}

                                                                    className="gx-icon-btn"
                                                                  />
                                                                </Col>
                                                                <Col style={{ display: 'flex', alignItems: 'center' }}>
                                                                  <span>{data.firstName}</span>
                                                                </Col>
                                                              </Row>
                                                            </div>
                                                          )
                                                        })}
                                                        {fetchData.length > 0 ?
                                                          <Button type="primary" onClick={() => this.onAddMembers(fetchData)}>Add</Button>
                                                          :
                                                          <span>No contacts found</span>
                                                        }
                                                      </div>
                                                    </div>

                                                  );
                                                }}
                                              </Subscription>
                                            }

                                            trigger="click">
                                            {/* <Tooltip title='Add New Member' > */}
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 0, fontWeight: 'bold', borderBottom: '1px solid lightgrey' }}>
                                              <p>Assigned users</p>
                                              <Avatar style={{ backgroundColor: 'black', verticalAlign: 'middle', marginBottom: '1%', marginRight: '1%', marginLeft: '1%', position: 'absolute', top: 5, right: 5 }} size="large">
                                                <Icon id="icon-view" type="plus" onClick={() => this.setState({ addNew: true })} />
                                              </Avatar>
                                            </div>
                                            {/* </Tooltip> */}
                                          </Popover>
                                          {assignedMembers.map((members, index) => {
                                            if (members && members.assigneeInfo) {
                                              let FullName = members.assigneeInfo.firstName + ' ' + members.assigneeInfo.lastName;
                                              let Initial = members.assigneeInfo.firstName.charAt(0) + members.assigneeInfo.lastName.charAt(0);
                                              let hash = 0;
                                              let i;

                                              for (i = 0; i < members.assigneeInfo.firstName.length; i += 1) {
                                                hash = members.assigneeInfo.firstName.charCodeAt(i) + ((hash << 5) - hash);
                                              }

                                              this.colour = '#';

                                              for (i = 0; i < 3; i += 1) {
                                                const value = (hash >> (i * 8)) & 0xff;
                                                this.colour += `00${value.toString(16)}`.substr(-2);
                                              }

                                              /* eslint-enable no-bitwise */

                                              return (
                                                // <Tooltip title={FullName}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: '1%', marginBottom: '1%' }}>
                                                  <Avatar style={{ backgroundColor: this.colour, verticalAlign: 'middle', marginBottom: '1%', marginRight: '1%', marginLeft: '1%' }} size="large" onClick={() => this.handleOk(members.assigneeInfo.id)}>
                                                    {Initial.toUpperCase()}
                                                  </Avatar>
                                                  <p style={{ marginBottom: '1%', marginRight: '1%', marginLeft: '2%' }}>{FullName}</p>
                                                </div>
                                                // </Tooltip>
                                              )
                                            }

                                          })
                                          }

                                          {this.state.visible === true &&
                                            <Popconfirm
                                              placement="top"
                                              visible={this.state.visible}
                                              title="Are you sure to remove this member?"
                                              onConfirm={() => this.onRemoveMembers()}
                                              onCancel={this.handleCancel}
                                              okText="Yes"
                                              cancelText="No"
                                            >
                                            </Popconfirm>
                                          }

                                        </>
                                      )
                                    }}
                                  </Subscription>
                                }
                                // title="Members"
                                trigger="click"
                              >
                                <Icon id="icon-view" st type="user" />
                              </Popover>
                            </div>
                            // </CustomScrollbars>
                          }
                        </div>
                      </div>

                    </div>
                  }
                </div>


                <div style={{ display: 'flex' }}>


                  {this.props.todo.tags && this.props.todo.tags.tagValues && this.props.todo.tags.tagValues.length > 0 &&
                    <div >
                      {this.props.todo.tags.tagValues.map((data, index) => {
                        return (
                          <Tag color="green" style={{ marginTop: 10, fontSize: 14 }}> {data}</Tag>
                        );
                      })}
                    </div>
                  }
                </div>
                <div className="add-member-form" style={{ overflow: 'auto', height: 'calc(100vh - 20vh)', marginTop: '1%' }}>
                  <BrowserView>
                    {this.props.todo.attachmentUrls && this.props.todo.attachmentUrls.attachedUrl && this.props.todo.attachmentUrls.attachedUrl[0] &&
                      <iframe
                        src={`${this.props.todo.attachmentUrls.attachedUrl[0]}#toolbar=0`}
                        width='100%' height='1200px'>
                      </iframe>
                    }
                  </BrowserView>

                  {
                    this.props.todo && this.props.todo.template && this.props.todo.template.displayTemplateType === "EcommerceForm" ?
                      <EcommerceForm
                        listingId={localStorage.getItem('selectedListingId')}
                        transactionData={this.props.todo}
                        templateId={(this.props.todo && this.props.todo.template) ? this.props.todo.template.id : ""}
                        screenFrom="listingDetail"
                        showOrderFormHeader={!this.state.isEditContract ? true : false}
                        getCurrentPoint={getFormData => { this.getFormData = getFormData }}
                      /> :
                      <div>
                        <div>
                          {
                            this.props.todo && this.props.todo.signedIP &&
                            <div>
                              <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                <Col xs={24} md={24} lg={24} id="contract-id-view">
                                  <Text> Signed IP: </Text>
                                  {/* </Col> */}
                                  <div className="sub-details">
                                    <Text style={{ paddingLeft: 15 }}>{this.props.todo.signedIP}</Text>
                                  </div>
                                </Col>

                              </Row>
                            </div>
                          }
                          {
                            this.props.todo && this.props.todo.formData && this.props.todo.formData.signedAt &&
                            <div>
                              <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                <Col xs={24} md={24} lg={24} id="contract-id-view">
                                  <Text> Signed At: </Text>
                                  {/* </Col> */}
                                  <div className="sub-details">
                                    <Text style={{ paddingLeft: 15 }}>{moment(this.props.todo.formData.signedAt).format('LLLL')}</Text>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          }
                          {
                            this.props.todo && this.props.todo.formData &&
                            templateSignedStatus === templateCurrentStatus &&
                            <div>
                              <Row id="order-view-contain" style={{ marginBottom: '2%' }}>
                                <Col xs={24} md={24} lg={24} id="contract-id-view">
                                  <Text> Version: </Text>
                                  {/* </Col> */}
                                  <div className="sub-details">
                                    <Text style={{ paddingLeft: 15 }}>{this.props.todo.formData.version ? this.props.todo.formData.version : "-"}</Text>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          }
                          <JsonFormBuilder
                            formData={this.props.todo.template.formDefinition}
                            detailData={this.state.transactionFormData}
                            contactId={this.props.todo.contactId}
                            isEdit={this.state.isEditContract}
                            ItemDetail={this.props.todo}
                            GoBackToDetail={this.GoBackToDetail}
                            isSaveEditContract={this.state.isSaveEditContract}
                          />
                        </div>


                      </div>
                  }

                  {!this.state.isEditContract && this.props.todo && this.props.todo.template && this.props.todo.template.displayTemplateType === "EcommerceForm" &&
                    <EditEcommerce editData={this.props.todo.formData.editedBySeller && this.props.todo} initialValue={!this.props.todo.formData.editedBySeller && this.props.todo} EditEcommerceOrder={this.EditEcommerceOrder.bind(this)} />
                  }

                  {this.state.notificationHistoryData.length > 0 &&
                    <NotificationHistory
                      notificationHistoryData={this.state.notificationHistoryData}
                      transactionId={this.props.todo.id}
                      transactionStatus={this.props && this.props.todo ? this.props.todo.currentStatus : ''}
                      transactionData={this.props.todo}
                    />
                  }
                </div>
              </div>
            }

          </div>
        </CustomScrollbars>
      </div >
    );
  }
}

export default withRouter(ListDetail);
