import React, { Component } from 'react'
import {
    Form, Input, Icon, Button, Radio, Select, message, Row, Col, Divider,
    Checkbox
} from 'antd';
import _ from 'lodash'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';
import { saveStatus } from '../../gql/mutation/templateTransaction/index'
import { UPDATE_TRANSACTION_TEMPLATE_FOR_ELIGIBLE_STATUS, UPDATE_FILTER_MASTER_DATA } from '../../graphQl/mutations'
import { GET_TEMPLATE_STATUS } from '../../graphQl/subscriptions'
import { deliveryOptions } from '../../util/DeliveryOptions'

const { Option } = Select

export class EligibleStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formKeys: [0],
            id: 0,
            valueRadio: null,
            dropDownVal: [],
            template: {},
            statusName: [],
            defaultValueState: '',
            editStatus: [],
            editDesc: [],
            editDefault: '',
            editData: [],
            deletedKeys: [],
            editScreen: false,
            editSignedStatus: "",
            editIsChangeable: [],
            includeDeliveryOption: false
        }
    }


    componentWillReceiveProps(nxtprops) {

        if (nxtprops && nxtprops.templateId && this.props.templateId !== nxtprops.templateId) {
            this.setState({ template: nxtprops.templateId }, () => {
                if (this.state.template && this.state.template && this.state.template.eligibleStatusList &&
                    this.state.template.eligibleStatusList.includeDeliveryOption) {
                    this.setState({ includeDeliveryOption: this.state.template.eligibleStatusList.includeDeliveryOption }, () => {
                    })
                }
            })
        }
        if (nxtprops &&
            nxtprops.screen &&
            nxtprops.screen === 'TemplateEdit' &&
            nxtprops.templateIdIdForEdit &&
            nxtprops.templateIdIdForEdit.length > 0 &&
            this.props.templateIdIdForEdit !== nxtprops.templateIdIdForEdit
        ) {
            this.setState({ editTemplateId: nxtprops.templateIdIdForEdit }, () => {
                this.fetchStatus(this.state.editTemplateId)
            })
        }
    }

    fetchStatus = (id) => {
        const tempVariable = {
            transactionTemplateId: id
        }
        try {
            client
                .subscribe({ query: GET_TEMPLATE_STATUS, variables: tempVariable })
                .subscribe(
                    res => {
                        if (res && res.data && res.data.transaction_template_status && res.data.transaction_template_status.length > 0) {
                            this.allocateInputs(res.data.transaction_template_status)
                        }
                    }, err => {
                    })
        } catch (e) {
        }
    }

    allocateInputs = (inputs) => {
        const tempKey = []
        const tempStatus = []
        const tempDes = []
        const tempChange = []
        const inputValue = inputs.map((ref, index) => {
            return {
                ...ref,
                keyId: index
            }
        })
        this.setState({ editData: inputValue })
        inputs.map((val, index) => {
            tempKey.push(index)
            tempDes.push(val.statusDesc)
            tempStatus.push(val.statusName)
            tempChange.push(val.isChangeable)
            if (inputs.length === tempKey.length && inputs.length === tempDes.length && inputs.length === tempStatus.length) {
                this.setState({
                    formKeys: tempKey,
                    editDesc: tempDes,
                    editStatus: tempStatus,
                    editIsChangeable: tempChange,
                    id: inputs.length - 1,
                    editScreen: true
                }, () => {
                    if (this.props.onSubmitEligibleStatus) {
                        this.props.onSubmitEligibleStatus(this.state.editStatus)
                    }
                })
            }
            if (val.isDefaultYn === true) {
                this.setState({
                    editDefault: val.statusName,
                    editSignedStatus: this.state.template && this.state.template.signedStatus ?
                        this.state.template.signedStatus : ""
                })
            }
        })
    }

    remove = (k) => {
        var tempRemoveKeys = [];

        const removekeys = _.filter(this.state.formKeys, (o) => {
            if (o !== k) {
                return tempRemoveKeys.push(o)
            } else {
                if (this.state.editData[o]) {
                    this.state.deletedKeys.push(o)
                }
            }
        });

        this.setState({
            formKeys: removekeys,
        })
    }

    add = () => {
        const incId = this.state.id + 1
        this.setState({ id: incId })
        this.state.formKeys.push(incId)
        this.setState({ formKeys: this.state.formKeys })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, val) => {
            if (!err) {

                const { defaultValues, names, discriptions, signatureStatus, isChangeable } = val
                this.setState({ defaultValueState: defaultValues, statusName: names })
                const { formKeys, template, editData, editTemplateId, deletedKeys } = this.state
                const payload = []
                const indexOfDefault = _.findIndex(names, (o) => { return o == defaultValues })

                if (deletedKeys.length > 0 && this.props.screen === 'TemplateEdit') {
                    deletedKeys.map(ref => {
                        const temp = editData[ref]

                        const deletePayload = {
                            type: "DELETE",
                            id: temp.id,
                            isDefaultYn: temp.isDefaultYn,
                            statusDesc: temp.statusDesc,
                            statusName: temp.statusName,
                            isChangeable: temp.isChangeable,
                            transactionTemplateId: temp.id
                        }
                        payload.push(deletePayload)
                    })
                }

                if (template.id || editTemplateId) {
                    formKeys.map((k, index) => {
                        const checkType = editData[k] ? true : false
                        const statusId = checkType ? editData[k].id : null

                        const tempPayload = {
                            type: checkType ? "UPDATE" : "CREATE",
                            id: statusId,
                            isDefaultYn: indexOfDefault === k ? true : false,
                            statusDesc: discriptions[k],
                            statusName: names[k],
                            isChangeable: isChangeable[k] === true ? isChangeable[k] : false,
                            transactionTemplateId: template.id || editTemplateId,
                            signedStatus: signatureStatus
                        }

                        payload.push(tempPayload)
                        if (payload.length === formKeys.length + deletedKeys.length) {

                            //append delivery option with status upon customer submission if include delivery option checkbox is checked
                            if (this.state.template && this.state.template.type === "order" &&
                                this.state.includeDeliveryOption) {
                                deliveryOptions.map((res) => {
                                    let deliveryPayload = {
                                        type: "CREATE",
                                        id: null,
                                        isDefaultYn: false,
                                        statusDesc: `${signatureStatus}-${res.key}`,
                                        statusName: `${signatureStatus}-${res.key}`,
                                        isChangeable: true,
                                        transactionTemplateId: template.id || editTemplateId,
                                        signedStatus: signatureStatus
                                    }
                                    payload.push(deliveryPayload)
                                })
                            }

                            //remove duplicates from status array
                            var removeDuplicates = _.uniqBy(payload, function (o) {
                                return o.statusName;
                            });
                            this.updateStatusTemplate(removeDuplicates, signatureStatus)
                        }
                    })
                } else {
                    message.error('Please save or create template')
                }
            }
        });
    };

    updateStatusTemplate = async (payload, signedStatus) => {
        const mutation = gql`${saveStatus(payload)}`
        try {
            client.mutate({ mutation }).then((response) => {

                if (this.props.onSubmitEligibleStatus) {
                    var dropdown = [];
                    payload.map((data, index) => {
                        if (data.type !== 'DELETE') {
                            dropdown.push(data.statusDesc);
                        }
                    })
                    this.props.onSubmitEligibleStatus(dropdown)
                }
                // if (type === 'call updateCreateTemplate') {
                this.updateCreateTemplate(signedStatus)
                // }
            })
                .catch(err => {
                    message.error(this.state.editScreen ? 'Edit Eligible Status Failed' : 'Add Eligible Status Failed')
                });
        } catch (e) {
        }
    }

    updateCreateTemplate = (signedStatus) => {
        const { template, defaultValueState, statusName, editTemplateId, editScreen } = this.state

        const tempStatusField = {
            status: statusName,
            initialStatus: defaultValueState,
            includeDeliveryOption: this.state.template && this.state.template.type === "order" ?
                this.state.includeDeliveryOption : null
        }
        const statusField = JSON.stringify(tempStatusField)
        const variableObject = {
            id: template.id || editTemplateId,
            name: template.name,
            // listingId: template.listingId,
            formDefinition: template.formDefinition,
            // type: template.type,
            // createdBy: template.createdBy,
            // owner: template.owner,
            UISchema: template.UISchema,
            displayTemplate: template.displayTemplate,
            eligibleStatusList: tempStatusField,
            signedStatus: signedStatus,
            templatepublicurl: template.templatepublicurl,
            templateSettings: template.templateSettings,
            displayTemplateType: template.displayTemplateType
        }
        console.log("template123", template)
        try {
            client.mutate({ mutation: UPDATE_TRANSACTION_TEMPLATE_FOR_ELIGIBLE_STATUS, variables: variableObject }).then((response) => {
                message.success(editScreen ? 'Eligible Status Edited SuccessFully' : 'Eligible Status Added SuccessFully')

                //update filter criteria data when editing template
                //filter criteria fields
                let value = {
                    formDefinition: template.formDefinition,
                    eligibleStatusList: tempStatusField,
                    createdAt: "",
                    updatedAt: "",
                    assignedTo: "",
                    linkCount: "",
                    linkCountStatus: '',
                    type: ''
                }

                let variablesData = {
                    tableName: "transaction",
                    listingId: localStorage.getItem('selectedListingId'),
                    templateId: template.id || editTemplateId,
                    filterCriteria: value,
                }

                client.mutate({ mutation: UPDATE_FILTER_MASTER_DATA, variables: variablesData }).then((response) => {
                })
                    .catch(err => {
                        message.error('Filter saved Failed')
                        this.setState({ loading: false })
                    });
            })
                .catch(err => {
                    message.error(editScreen ? 'Edit Eligible Status Failed' : 'Add Eligible Status Failed')
                });
        } catch (e) {
        }
    }

    namePlaceHoder = () => {
        this.setState({ dropDownVal: this.props.form.getFieldValue('names') })
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { formKeys, dropDownVal, editStatus, editDesc, editDefault, editSignedStatus, editIsChangeable } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        getFieldDecorator('keys', { initialValue: [] });
        const keys = formKeys
        const formItems = keys.map((k, index) => (
            <div>
                <Form.Item
                    {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                    required={false}
                    key={k}
                >
                    <Row span={24}>
                        <Col xs={9} sm={9} md={7} lg={7}>
                            {index === 0 && <h6 className="status-text-view">State</h6>}
                            {getFieldDecorator(`names[${k}]`, {
                                initialValue: editStatus && editStatus.length > 0 ? editStatus[k] : '',
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: "Please input the field.",
                                    },
                                ],
                            })(<Input
                                onChange={(val) => { this.namePlaceHoder() }}
                            />)}
                        </Col>
                        <Col xs={9} sm={9} md={7} lg={7}>
                            {index === 0 && <h6 className="status-text-view">Description</h6>}
                            {getFieldDecorator(`discriptions[${k}]`, {
                                initialValue: editDesc && editDesc.length > 0 ? editDesc[k] : '',
                                validateTrigger: ['onChange', 'onBlur'],
                                rules: [
                                    {
                                        required: true,
                                        message: "Please input the value.",
                                    },
                                ],
                            })(<Input />)}
                        </Col>

                        {/* is editable status */}
                        <Col xs={2} sm={2} md={2} lg={6} className="status-text-container">
                            {index === 0 && <h6 className="status-text-view"> Allow State Change</h6>}
                            {getFieldDecorator(`isChangeable[${k}]`, {
                                initialValue: editIsChangeable && editIsChangeable.length > 0 ? editIsChangeable[k] : '',
                                valuePropName: 'checked',
                                validateTrigger: ['onChange', 'onBlur'],
                            })(<Checkbox />)}
                        </Col>

                        <Col className="minus-icon-view" style={index === 0 && { marginTop: '26px' }}>
                            {keys.length > 1 ? (
                                <Icon
                                    id="icon-view"
                                    className="dynamic-delete-button"
                                    type="delete"
                                    onClick={() => this.remove(k)}
                                    style={{ fontSize: '16px' }}
                                />
                            ) : null}
                        </Col>


                    </Row>

                    {/* <Col xs={24} sm={24} md={24} lg={24} 
                    style={index === 0 && { marginTop: '26px' }}
                     >
                            {keys.length === index + 1 &&
                                <Form.Item {...formItemLayoutWithOutLabel} >
                                    <Button type="dashed" onClick={this.add} id="add-field-input" style={{ width: '100px' }} >
                                        <Icon id="icon-view" type="plus" />
                                    </Button>
                                </Form.Item>
                            }
                        </Col> */}
                    <div
                        style={{ marginTop: '26px' }}
                    >
                        {keys.length === index + 1 &&
                            <Form.Item {...formItemLayoutWithOutLabel} >
                                <Button type="dashed" onClick={this.add} id="add-field-input" style={{ width: '100px' }} >
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Form.Item>
                        }
                    </div>

                </Form.Item>
            </div>
        ));
        return (
            <div>
                <Form
                    onSubmit={this.handleSubmit}
                >
                    <div style={{ width: '100%' }}>
                        {/* <h6 className="eligible-status-header">Eligible Status</h6> */}
                        <h6 className="eligible-status-header">Contract State</h6>
                        <p style={{ fontWeight: 400, marginBottom: 25, marginTop: 8, color: 'black' }}> Each contract can have mulitple states like Draft,Sent,Signed etc</p>

                    </div>
                    <div >
                        <div>
                            {formItems}
                        </div>
                    </div>

                    <Row span={24} id="status-upon-input" style={{ marginRight: '3%' }} >
                        <Col xs={10} sm={10} md={7} lg={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div style={{ display: 'flex' }}>
                                <p style={{ color: 'red' }}>*</p>
                                {
                                    this.state.template && this.state.template.type === "order" ?
                                        <div className="status-text-view">Status upon seller creation </div> :
                                        <div className="status-text-view">Initial State upon creation </div>
                                }

                            </div></Col>
                        <Col xs={10} sm={11} md={8} lg={8}>

                            <Form.Item
                                {...formItemLayout}
                            >

                                {getFieldDecorator(`defaultValues`, {
                                    initialValue: editDefault && editDefault.length > 0 ? editDefault : '',
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [
                                        {
                                            required: true,
                                            message: "Please input the value.",
                                        },
                                    ],
                                })(
                                    <Select placeholder="Make as default" onFocus={() => { this.namePlaceHoder() }}>
                                        {dropDownVal && dropDownVal.length > 0 && dropDownVal.map(val => (
                                            <Option value={val}>{val}</Option>
                                        ))
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row span={24} id="status-upon-input" style={{ marginRight: '3%' }}  >
                        <Col xs={10} sm={10} md={7} lg={7}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <div className="status-text-view" style={{ display: 'flex' }}>
                                {/* <p>Status upon customer submission</p> */}
                                <p>State when signed</p>
                            </div> </Col>
                        <Col xs={10} sm={11} md={8} lg={8}>
                            <Form.Item
                                {...formItemLayout}
                            >
                                {getFieldDecorator(`signatureStatus`, {
                                    initialValue: editSignedStatus && editSignedStatus.length > 0 ? editSignedStatus : '',
                                    validateTrigger: ['onChange', 'onBlur'],
                                    rules: [
                                        {
                                            required: false,
                                            message: "Please input the value.",
                                        },
                                    ],
                                })(
                                    <Select placeholder="Make as default" onFocus={() => { this.namePlaceHoder() }}>
                                        {dropDownVal && dropDownVal.length > 0 && dropDownVal.map(val => (
                                            <Option value={val}>{val}</Option>
                                        ))
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        {
                            this.state.template && this.state.template.type === "order" &&
                            <Col xs={10} sm={11} md={8} lg={8}>
                                <Checkbox checked={this.state.includeDeliveryOption}
                                    onChange={(event) => this.setState({ includeDeliveryOption: event.target.checked })}>Include delivery option as status</Checkbox>
                            </Col>
                        }

                    </Row>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="primary" htmlType="submit" >
                                Save
                                </Button>
                        </Form.Item>
                    </div>
                </Form>
                <Divider />
            </div>
        )
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(EligibleStatus);
export default WrappedDynamicFieldSet