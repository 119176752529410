import React from "react";
import { Route, Switch } from "react-router-dom";
import axios from 'axios';
import { getENVConfig } from '../util/common';
import { CONFIG } from "config/config";
import Config from '../util/config'


import asyncComponent from "util/asyncComponent";

// const versionCheck = () => {
//   axios({ method: 'POST', url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/latestVersion` })
//     .then((result) => {
//       console.log(result, 'result')
//       if (result.data.latestVersion !== Config.version) {
//         console.log('Clearing cache and hard reloading...')
//         if (caches) {
//           console.log("caches",caches)
//           // Service worker cache should be cleared with caches.delete()
//           caches.keys().then(function (names) {
//             for (let name of names) caches.delete(name);
//             console.log(names,"names")
//           });
//           // delete browser cache and hard reload
//           // window.location.reload();
//           // window.location.href = window.location.href
//         }
//         localStorage.setItem('VersionChecked', result.data.latestVersion)
//       }
//       console.log("configv",Config.version)
//     })
//     .catch(err => {
//       console.log(err)
//     });
// }


const App = ({ match }) => {
  // versionCheck();
  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route exact path={`${match.url}app/console/myAccount`} component={asyncComponent(() => import('./MyAccount'))} />
        <Route exact path={`${match.url}app/console/:pathname`} component={asyncComponent(() => import('./ListingDetails'))} />
        <Route exact path={`${match.url}app/console/:pathname/contact/individual`} component={asyncComponent(() => import('./Contact'))} />
        <Route exact path={`${match.url}app/console/:pathname/contact/businessContact`} component={asyncComponent(() => import('./BusinessContact'))} />
        <Route exact path={`${match.url}app/console/:pathname/transactions/:id`}
          component={asyncComponent(() => import('./Transactions'))} />
        <Route path={`${match.url}app/console/:pathname/new_transaction`}
          component={asyncComponent(() => import('./CreateNewTemplate'))} />
        <Route exact path={`${match.url}app/console/:pathname/notify/:type`} component={asyncComponent(() => import('./Notify'))} />
        <Route exact path={`${match.url}app/console/:pathname/newSmsTemplate/`} component={asyncComponent(() => import('./CreateSmsTemplate'))} />
        <Route exact path={`${match.url}app/console/:pathname/newSmsTemplate/:id`} component={asyncComponent(() => import('./CreateSmsTemplate'))} />
        <Route exact path={`${match.url}app/console/:pathname/newSmsTemplate/newMessageTemplate`} component={asyncComponent(() => import('./CreateSmsTemplate'))} />
        <Route exact path={`${match.url}app/console/:pathname/received/contract/:type/:id`}
          component={asyncComponent(() => import('./ReceivedContract'))} />
        <Route exact path={`${match.url}app/console/:pathname/received/order/:type/:id`}
          component={asyncComponent(() => import('./ReceivedContract'))} />
        <Route exact path={`${match.url}app/console/:pathname/received/lead/:type/:id`}
          component={asyncComponent(() => import('./ReceivedContract'))} />
        <Route exact path={`${match.url}app/console/:pathname/received/review/:type/:id`}
          component={asyncComponent(() => import('./ReceivedContract'))} />
        <Route exact path={`${match.url}app/console/:pathname/received/registration/:type/:id`}
          component={asyncComponent(() => import('./ReceivedContract'))} />
        <Route exact path={`${match.url}app/console/personal/received/messages/:type`} component={asyncComponent(() => import('./Notify'))} />
        <Route exact path={`${match.url}app/console/personal/received`} component={asyncComponent(() => import('./Personal'))} />
      </Switch>
    </div>
  );
};

export default App;
