import React, { Component } from 'react'
import { storage } from '../../firebase/firebase';
import { Upload, Button, Icon, message, Spin, Tooltip } from 'antd'
import CircularProgress from "../../components/CircularProgress/index";

import { CONFIG } from '../../config/config';
import { getENVConfig } from '../../util/common'


const axios = require('axios');


export class UploadCsv extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedFileList: [],
            showLoading: false
        }
    }

    onUploadFile = (data) => {
        console.log('onUploadFile', data.fileList)
        if ((data.file.status === 'done') && (data && data.fileList[0] && data.fileList[0].originFileObj)) {
            this.props.loader(true)
            const file = data.fileList[0].originFileObj;
            const fileUrl = `businessContactCSV/${file.name}`;
            const uploadTask = storage.ref(fileUrl).put(file);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    console.log('snapshot', snapshot)
                    // progress function
                },
                error => {
                    console.log('err', error)
                    // this.setState({ showLoading: true })
                    //error function
                }, () => {
                    let listingId = localStorage.getItem('selectedListingId');
                    let createdBy = localStorage.getItem('uid');
                    var url = `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/readBusinessCSVFileFromCloudStorage`;
                    let data = {
                        file: fileUrl,
                        listingId: listingId,
                        createdBy: createdBy,
                    }

                    let config = { method: 'POST', url: url, data: data }

                    axios(config)
                        .then(res => {
                            if (res.data.length > 0) {
                                console.log('res', res.data)
                                // res.data.map(val => {
                                //     console.log('value', val)
                                //     val.PhoneNumber = '+' + val.PhoneNumber
                                // })
                                this.props.loader(false)
                                this.props.getData(res.data)
                            } else {
                                this.props.loader(false)
                                message.error("Something went wrong")
                            }
                        })
                        .catch(err => {
                            this.props.loader(false)
                            console.log(err)
                        });

                })
        }
    }

    onRemove = () => {
        console.log('onRemove')
        this.props.remove()
    }

    render() {
        const { showLoading } = this.state
        const { screen } = this.props
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -3, marginRight: 20, marginTop: screen === 'createTemplate' ? -25 : 0 }}>
                    <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        // fileList={this.state.selectedFileList}
                        // name = 'file'
                        onChange={(data) => this.onUploadFile(data)}
                        onRemove={this.onRemove}
                    >
                        <Tooltip title="Upload CSV">
                            {this.props.screen !== 'BusinessContact' &&
                                // <span style={{ fontWeight: 500, marginRight: 15 }}>Upload Product Catalog</span>
                                <Button type="primary">Upload catalog</Button>
                            }
                            {this.props.screen === 'BusinessContact' &&
                            <Icon id="icon-view" type="upload" style={{
                                cursor: 'pointer',
                                fontSize: 22
                            }} />
                        }
                        </Tooltip>
                    </Upload>
                </div>
            </div>
        )
    }
}

export default UploadCsv
