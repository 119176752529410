import React, { Component } from 'react'
import { withRouter } from "react-router-dom"
import { Divider, Radio, Form, Select, Input, Icon, message } from 'antd'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';
import _ from 'lodash'
import { Subscription } from "react-apollo";
import { ADD_TEMPLATE_MUTATION, UPDATE_TEMPLATE_MUTATION } from '../../graphQl/mutations'
import { SENDER_SUB, GET_TEMPLATES_BY_ID } from '../../graphQl/subscriptions'
import { GET_TEMPLATES_BY_LISTING_ID } from '../../graphQl/queries'
import { cloneDeep } from 'lodash'

const { Option } = Select
const { TextArea } = Input

export class MsgTemplateDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            msgType: 'SMS',
            propTrans: 'promotional',
            templateDetail: {},
            disabledStatus: false,
            type: '',
            iconVisible: false,
            senderId: ''
        }
    }

    componentDidMount() {
        if (this.props && this.props.type && this.props.type === 'View' && this.props.todo && this.props.todo.id) {
            this.setState({
                disabledStatus: true,
            }, () => {
                this.fetchTemplateDetails(this.props.todo.id)
            })
        }
        if (this.props && this.props.type) {
            this.setState({ type: this.props.type })
        }
    }

    fetchTemplateDetails = (templateId) => {
        const gqlValue = GET_TEMPLATES_BY_ID
        const query = gql`${gqlValue}`

        client
            .subscribe({
                query,
                variables: {
                    id: templateId,
                }
            })
            .subscribe(
                res => {
                    console.log('res', res)
                    if (res.data && res.data.template[0]) {
                        console.log('test111', res.data.template[0])
                        const temp = res.data.template[0]
                        this.setState({
                            templateDetail: temp,
                            msgType: temp.templateType,
                            propTrans: temp.promOrTrans,
                            senderId: temp.senderId
                        })
                    }
                },
                err => {
                    console.log('error')
                })
    }

    onclickEdit() {
        this.setState({ disabledStatus: false })
    }

    onClickSave() {
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log('onClickSave', values)
            if (!err) {
                let variableObject = {}
                let settingsValue = {
                    DLT_TE_ID: values.DLT_TE_ID ? values.DLT_TE_ID : '',
                    PE_ID: values.PE_ID ? values.PE_ID : ''
                }
                if (this.state.type === 'View') {
                    variableObject = {
                        id: this.state.templateDetail.id,
                        title: values.msgTitle,
                        content: values.msgDescription,
                        templateType: values.msgType,
                        promOrTrans: values.type,
                        senderId: values.senderId,
                        settings: values.msgType === 'SMS' ? settingsValue : null
                    }
                } else {
                    variableObject = {
                        createdBy: localStorage.getItem('uid'),
                        title: values.msgTitle,
                        content: values.msgDescription,
                        templateType: values.msgType,
                        listingId: localStorage.getItem('selectedListingId'),
                        promOrTrans: values.type,
                        senderId: values.senderId,
                        settings: values.msgType === 'SMS' ? settingsValue : null
                    }
                }

                console.log('variableObject123', variableObject)
                let setMutation = this.state.type === 'View' ? UPDATE_TEMPLATE_MUTATION : ADD_TEMPLATE_MUTATION

                client.mutate({ mutation: setMutation, variables: variableObject }).then((response) => {
                    this.props.gobackCall()
                    message.success(this.state.type === 'View' ? 'Message Template edited SuccessFully' : 'Message Template Added SuccessFully')
                })
                    .catch(err => {
                        message.error(this.state.type === 'View' ? 'Edit Message Template Failed' : 'Add Message Template Failed')
                    });
            }
        })
    }

    onSelectRadio = (stateVal, event) => {
        this.setState({ [stateVal]: event.target.value })
    }

    render() {
        const { propTrans, msgType, templateDetail, disabledStatus, iconVisible, senderId } = this.state
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }

        return (
            <div style={{ backgroundColor: 'white', paddingLeft: '6%', paddingRight: '6%' }}>

                {iconVisible === false &&
                    <Icon
                        id="icon-view"
                        type={disabledStatus ? "edit" : "save"}
                        style={{ marginRight: 20, marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}
                        onClick={() => disabledStatus ? this.onclickEdit() : this.onClickSave()}
                    />
                }

                <Form {...formItemLayout}>
                    <Form.Item label="Template Type"
                        labelCol={{ xs: 24, sm: 5 }}
                        wrapperCol={{ xs: 24, sm: 12 }}>
                        {getFieldDecorator('msgType', {
                            initialValue: msgType,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please selete Template type',
                                },
                            ],
                        })(
                            <Radio.Group onChange={(event) => this.onSelectRadio('msgType', event)} disabled={disabledStatus}>
                                <Radio value="SMS">SMS</Radio>
                                <Radio value="EMAIL">EMAIL</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Form.Item label="Message Type" labelCol={{ xs: 24, sm: 5 }}
                        wrapperCol={{ xs: 24, sm: 12 }}>
                        {getFieldDecorator('type', {
                            initialValue: propTrans,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please selete message type',
                                },
                            ],
                        })(
                            <Radio.Group onChange={(event) => this.onSelectRadio('propTrans', event)} disabled={disabledStatus}>
                                <Radio value="promotional">Promotional</Radio>
                                <Radio value="transactional">Transactional</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Subscription
                        subscription={SENDER_SUB}
                        variables={{
                            listingId: localStorage.getItem('selectedListingId'),
                            type: msgType === 'SMS' ? 'sms' : 'email'
                        }}
                    >
                        {({ data, error }) => {
                            if (error) return error.message;
                            const templateArray = data ? data.listing_communication_provider : [];
                            let transactionalData = []
                            return (
                                <Form.Item label="Sender Id" labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}>
                                    {getFieldDecorator('senderId', {
                                        initialValue: templateDetail && templateDetail.senderId ? templateDetail.senderId : '',
                                        rules: [{ required: true, message: 'Please select sender id' }],
                                    })(
                                        <Select placeholder="Select" style={{ width: '100%' }} disabled={disabledStatus} onChange={(event) => this.setState({senderId: event})}>
                                            {templateArray && templateArray.length > 0 && templateArray.map((types) => {
                                                let item = []
                                                console.log('this.state.propTrans123', this.state.propTrans)
                                                if (this.state.propTrans === "promotional") {
                                                    item = types.communicationScope.promotional
                                                } else if (this.state.propTrans === "transactional") {
                                                    transactionalData = []
                                                    if (msgType === 'SMS') {
                                                        transactionalData = cloneDeep(types.communicationScope.transactional)
                                                        console.log('transactionalData123', transactionalData, types.communicationScope.transactional)
                                                        transactionalData.push('PLIVO')
                                                        transactionalData.push('TOTELY-TXTART')
                                                        transactionalData.push('TTLYRC-TXTART')
                                                        transactionalData.push('AMAZTY-TXTART')
                                                        transactionalData.push('AMAZEX-TXTART')
                                                        transactionalData.push('AMAZRC-TXTART')
                                                        transactionalData.push('AMAZTY-TEXTLOCAL')
                                                        transactionalData.push('AMAZEX-TEXTLOCAL')
                                                        transactionalData.push('AMAZRC-TEXTLOCAL')
                                                        
                                                        item = transactionalData
                                                    } else {
                                                        item = types.communicationScope.transactional
                                                    }


                                                }
                                                return item.map((val) => {
                                                    return (
                                                        <Option value={val}>{val}</Option>
                                                    )
                                                })

                                            })}
                                        </Select>,
                                    )}
                                </Form.Item>
                            )
                        }
                        }
                    </Subscription>
                    {msgType === 'SMS' && _.includes(senderId, 'TXTART') &&
                        <Form.Item
                            label="DLT_TE_ID"
                            labelCol={{ xs: 24, sm: 5 }}
                            wrapperCol={{ xs: 24, sm: 12 }}
                        >
                            {getFieldDecorator('DLT_TE_ID', {
                                initialValue: templateDetail &&
                                 templateDetail.dlt_settings  &&
                                  templateDetail.dlt_settings .DLT_TE_ID ? templateDetail.dlt_settings .DLT_TE_ID : '',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input DLT_TE_ID',
                                    },
                                ],
                            })(<Input style={{ width: '100%' }} disabled={disabledStatus} />)}
                        </Form.Item>
                    }
                    {msgType === 'SMS' && _.includes(senderId, 'TXTART') &&
                        <Form.Item
                            label="PE_ID"
                            labelCol={{ xs: 24, sm: 5 }}
                            wrapperCol={{ xs: 24, sm: 12 }}
                        >
                            {getFieldDecorator('PE_ID', {
                                initialValue: templateDetail &&
                                templateDetail.dlt_settings  &&
                                 templateDetail.dlt_settings .PE_ID ? templateDetail.dlt_settings .PE_ID : '',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please input PE_ID',
                                    },
                                ],
                            })(<Input style={{ width: '100%' }} disabled={disabledStatus} />)}
                        </Form.Item>
                    }
                    <Form.Item
                        label="Message Title"
                        labelCol={{ xs: 24, sm: 5 }}
                        wrapperCol={{ xs: 24, sm: 12 }}
                    >
                        {getFieldDecorator('msgTitle', {
                            initialValue: templateDetail && templateDetail.title ? templateDetail.title : '',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input message title',
                                },
                            ],
                        })(<Input style={{ width: '100%' }} disabled={disabledStatus} />)}
                    </Form.Item>
                    <Form.Item
                        label="Message Description"
                        labelCol={{ xs: 24, sm: 5 }}
                        wrapperCol={{ xs: 24, sm: 12 }}
                    >
                        {getFieldDecorator('msgDescription', {
                            initialValue: templateDetail && templateDetail.content ? templateDetail.content : '',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input message title',
                                },
                            ],
                        })(<TextArea rows={3} style={{ width: '100%' }} disabled={disabledStatus} />)}
                    </Form.Item>
                </Form>
            </div >

        )
    }
}

const WrappedApp = Form.create()(MsgTemplateDetails);
export default withRouter(WrappedApp);
