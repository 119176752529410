import {
    FILTER_MASTER_DATA, GET_SAVED_FILTER_DATA_BY_IDS,
    GET_FILTER_DATA_BY_IDS
} from '../graphQl/subscriptions'
import client from '../apollo/apollo';

// care team member list
export function getFilterLogs(param) {
    return new Promise(function (resolve, reject) {
        try {
            const tempVariable = {
                tableName: param
            }

            client
                .subscribe({ query: FILTER_MASTER_DATA, variables: param })
                .subscribe(
                    res => {
                        if (res && res.data) {
                            resolve(res.data.filter_master)
                        }
                    }, err => {
                        reject(err)
                    })
        }
        catch (e) {
            reject(e)
        }
    })
}

// get saved filter data
export async function getSavedFilterList(param) {
    return new Promise(async (resolve, reject) => {
        try {
            if (param) {
                const tempVariable = {
                    listingId: param.listingId,
                    tableName: param.tableName,
                    refId: param.refId
                }

                // await client
                //     .subscribe({ query: GET_SAVED_FILTER_DATA_BY_IDS, variables: tempVariable })
                //     .subscribe(
                //         res => {
                //             console.log('res123 getSavedFilterList', res)
                //             if (res && res.data && res.data.filter_history && res.data.filter_history.length > 0) {
                //                 console.log('history123 common', res.data.filter_history)
                //                 resolve(res.data.filter_history)
                //             }
                //         }, err => {
                //             reject(err)
                //         })
            }

        }
        catch (e) {
            reject(e)
        }
    })
}

export function getSelectedFilterDataById(param) {
    return new Promise(function (resolve, reject) {
        try {
            if (param) {
                const tempVariable = {
                    filterHistId: param
                }

                client
                    .subscribe({ query: GET_FILTER_DATA_BY_IDS, variables: tempVariable })
                    .subscribe(
                        res => {
                            if (res && res.data && res.data.filter_history && res.data.filter_history.length > 0) {
                                resolve(res.data.filter_history[0])
                            }
                        }, err => {
                            reject(err)
                        })
            }

        }
        catch (e) {
            reject(e)
        }
    })
}