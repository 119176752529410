import gql from 'graphql-tag';


const ADD_TRANSACTION = gql`
mutation insert_transaction($lead_object:[transaction_insert_input!]!){
  insert_transaction(objects: $lead_object) {
    affected_rows
  }
}`;


const _signedFiles = gql`mutation fileUpload($fileUpload:[file_upload_insert_input!]! , $transactionId:uuid!, $fileId:uuid!){
  insert_file_upload(objects:$fileUpload){
    affected_rows
  }
  update_transaction(_set:{signedDocument:$fileId} ,where:{id:{_eq:$transactionId}}){
    affected_rows
  }
}`;

const SIGNED_MUTATION = gql`mutation transaction_status($statusUpdatedAt:timestamptz!  , $signedIP:String! , $createdBy:uuid $currentStatus: String!, $transactionId: uuid!,  $updatedAt: timestamptz! , $formData:jsonb!) {
  update_transaction(_set: {signedIP:$signedIP , statusUpdatedAt:$statusUpdatedAt currentStatus: $currentStatus, formData:$formData, updatedAt: $updatedAt}, where: {id: {_eq: $transactionId}}) {
    affected_rows
  }
  insert_status_history(objects:{created_by: $createdBy, status: $currentStatus, transactionid: $transactionId}){
      affected_rows
    }
}
`;

const REMOVE_ASSIGNED = gql`mutation delete_transaction_assigned_to($assignedTo: uuid!, $transactionId: uuid!) {
  delete_transaction_assigned_to(where: {_and: [{assignedTo: {_eq: $assignedTo}}, {transactionId: {_eq: $transactionId}}]}) {
    affected_rows
  }
}`;

const ASSIGN_MEMBERS = gql`mutation insert_transaction_assigned_to($assign_object:[transaction_assigned_to_insert_input!]!){
  insert_transaction_assigned_to(objects:$assign_object){
    affected_rows
  }
}`

const STATUS_CHANGE = gql`mutation transaction_status($reSend:String  , $currentStatus:String!, $transactionId:uuid! ,$createdBy:uuid! , $updatedAt:timestamptz!){
  update_transaction(_set: {reSend:$reSend, currentStatus: $currentStatus , updatedBy:$createdBy , updatedAt:$updatedAt}, where: {id: {_eq: $transactionId}}) {
    affected_rows
  }
}`;

// const BULK_STATUS_CHANGE = gql`mutation transaction_status($reSend:String  , $currentStatus:String!, $transactionId:[uuid!] ,$createdBy:uuid! , $updatedAt:timestamptz!){
//   update_transaction(_set: {reSend:$reSend, currentStatus: $currentStatus , updatedBy:$createdBy , updatedAt:$updatedAt}, where: {id: {_in: $transactionId}}) {
//     affected_rows
//   }
// }`;
const BULK_STATUS_CHANGE = gql`mutation transaction_status($reSend:String  , $currentStatus:String!, $transactionId:[uuid!] ,$createdBy:uuid!){
  update_transaction(_set: {reSend:$reSend, currentStatus: $currentStatus , updatedBy:$createdBy}, where: {id: {_in: $transactionId}}) {
    affected_rows
  }
}`;

// const UPDATE_TRANSACTION = gql`mutation update_trans($formData: jsonb!, $transactionId: uuid!, $listingId: uuid, $oldNumber: String, $newNumber: String, $oldEmail: String, $newEmail: String) {
//   update_transaction(_set: {formData: $formData}, where: {id: {_eq: $transactionId}}) {
//     affected_rows
//   }
//   updatContactPhone: update_contact(_set: {phoneNumber: $newNumber}, where: {_and: [{listingId: {_eq: $listingId}}, {phoneNumber: {_eq: $oldNumber}}]}) {
//     affected_rows
//   }
//   updatContactEmail: update_contact(_set: {email: $newEmail}, where: {_and: [{listingId: {_eq: $listingId}}, {email: {_eq: $oldEmail}}]}) {
//     affected_rows
//   }
// }`;


const UPDATE_TRANSACTION = gql`mutation update_trans($formData: jsonb!, $transactionId: uuid!,$contactId: uuid,$email:String,$phoneNumber:String) {
  update_transaction(_set: {formData: $formData}, where: {id: {_eq: $transactionId}}) {
    affected_rows
  }
  updatContact: update_contact(_set: {phoneNumber: $phoneNumber, email:$email}, where: {id: {_eq: $contactId}}) {
    affected_rows
  }
}`;

// const DELETE_TRANSACTION = gql`mutation delete_transaction($transactionId:uuid!){
//   delete_transaction(where: {id: {_eq: $transactionId}}) {
//     affected_rows
//   }
// }`;

const UPDATE_CONTACT = gql`mutation updateContactPhone($contactId: uuid!, $newmail: String!) {
  update_contact(_set: {email: $newmail }, where: {id: {_eq: $contactId}}) {
    affected_rows
  }
}`;


// const DELETE_TRANSACTION = gql`mutation delete_transaction ($transactionId:[uuid!]){
//   delete_transaction(where: {id: {_in: $transactionId}}) {
//     affected_rows
//   }
// }`;

const DELETE_TRANSACTION = gql`mutation update_transaction ($transactionId:[uuid!], $status:String!){
  update_transaction(_set:{status:$status},where: {id: {_in: $transactionId}
}) {
    affected_rows
  }
}`;

// about user table
const ADD_USER_MUTATION = gql`mutation add_user($accountStatus: String!, $email: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $updatedAt: timestamptz!, $id: uuid!) {
  insert_user(objects: {accountStatus: $accountStatus, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, updatedAt: $updatedAt, id: $id}) {
    affected_rows
  }
  update_listing_team(where: {phoneNumber: {_eq: $phoneNumber}}, _set: {phoneNumber: null, userId: $id}){
    affected_rows
  }
}`;

const ADD_TEMPLATE_MUTATION = gql`mutation($content:String! , $createdBy:uuid! ,$title:String! , $templateType: String! , $listingId:uuid!, $promOrTrans: String!, $senderId: String!, $settings: jsonb ) {
  insert_template(objects: {content: $content, createdBy: $createdBy, title: $title , templateType : $templateType , listingId:$listingId, promOrTrans: $promOrTrans, senderId: $senderId, dlt_settings : $settings}) {
    affected_rows
    returning {
      id
    }
  }
}`;

const UPDATE_TEMPLATE_MUTATION = gql`
  mutation update_template($id: uuid! $content:String! ,$title:String! , $templateType: String!, $promOrTrans: String!, $senderId: String!, $settings: jsonb ) {
    update_template(
      where: { id: { _eq: $id } }
      _set: { content: $content, title: $title , templateType : $templateType , promOrTrans: $promOrTrans, senderId: $senderId, dlt_settings : $settings}
    ) {
      affected_rows
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation update_user($email: String!, $firstName: String!, $lastName: String!, $id: uuid!,$updatedAt:timestamptz!) {
    update_user(
      where: { id: { _eq: $id } }
      _set: { email: $email, firstName: $firstName, lastName: $lastName , updatedAt:$updatedAt }
    ) {
      affected_rows
      returning {
        email
      }
    }
  }
`;

// about contact table
const ADD_CONTACT_MUTATION = gql`
  mutation add_contact(
    $firstName: String!
    $lastName: String!
    $email: String
    $phoneNumber: String
    $listingId: uuid!
    $notes: String!
    $customAttributes: jsonb,
    $createdBy:uuid
    $creationSource:String!
    $validEmail:Boolean!
    $mappingUserId:uuid
    $businessContactRole:String
    $internalUserContactId:String
  ) {
    insert_contact(
      objects: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        listingId: $listingId
        phoneNumber: $phoneNumber
        notes: $notes
        customAttributes: $customAttributes
        createdBy:$createdBy
        creationSource:$creationSource
        validEmail:$validEmail
        mappingUserId:$mappingUserId
        businessContactRole:$businessContactRole
        internalUserContactId: $internalUserContactId
      }
    ) {
      returning {
        id
        firstName
      }
      affected_rows
    }
  }
`;

const UPDATE_CONTACT_MUTATION = gql`
  mutation update_contact(
    $id: uuid!
    $firstName: String!
    $lastName: String!
    $email: String
    $phoneNumber: String
    $listingId:uuid!,
    $notes:String!,
    $validEmail:Boolean!,
    $createdBy:uuid!
    $creationSource:String!,
    $customAttributes: jsonb
    $internalUserContactId:String
  ) {
    update_contact(
      where: { id: { _eq: $id } }
      _set: { 
            firstName: $firstName,
            lastName: $lastName, 
            email: $email, 
            listingId:$listingId,
            phoneNumber:$phoneNumber,        
            notes:$notes,
            validEmail:$validEmail,
            createdBy:$createdBy,
            creationSource:$creationSource,
            customAttributes: $customAttributes
            internalUserContactId: $internalUserContactId
        }
    ) {
      affected_rows
      returning {
        email
      }
    }
  }
`;

const UPDATE_CONTACT_FROM_CONTRACT_MUTATION = gql`
  mutation update_contact(
    $id: uuid!
    $email: String
    $phoneNumber: String
  ) {
    update_contact(
      where: { id: { _eq: $id } }
      _set: { 
            email: $email, 
            phoneNumber:$phoneNumber,        
        }
    ) {
      affected_rows
      returning {
        email
      }
    }
  }
`;

const DELETE_CONTACT_MUTATION = gql`
  mutation delete_contact(
    $id: uuid!
  ) {
    delete_contact_group_rel(
      where:{ contact_id: {_eq:$id}}
    ){
      affected_rows
    }

    delete_contact(
      where: { id: { _eq: $id } }      
    ) {
      affected_rows
    }
  }
`;

// about contact_upload table
const ADD_CONTACT_UPLOAD_URL_MUTATION = gql`
  mutation add_upload_url($objects: [contact_upload_insert_input!]!) {
    insert_contact_upload(objects: $objects) {
      returning {
        id      
      }
      affected_rows
    }
  }
`;

const updateCount = gql`mutation updateCount($shortUrl:String! ,$count:numeric!){
  update_short_url(_set:{linkClickCount:$count } where:{shortURL:{_eq:$shortUrl}}){
    affected_rows
  }
}`;

const ADD_TRANSACTION_UPLOAD = gql`mutation add_upload_url($objects: [transaction_upload_insert_input!]!) {
  insert_transaction_upload(objects: $objects) {
    returning {
      id
    }
    affected_rows
  }
}`;

const NOTIFICATION_FILE_UPLOAD = gql`mutation notification_file($objects: [file_upload_insert_input!]! , $shortObject:[short_url_insert_input!]!) {
  insert_file_upload(objects: $objects) {
    affected_rows
  }
  insert_short_url(objects:$shortObject){
    affected_rows
  }
}`;


const SAVE_AS_DRAFT = gql`mutation upload_draft($draft_object:[broadcast_insert_input!]!){
  insert_broadcast(objects:$draft_object)  {
    affected_rows
  }
}`;
// contact_group table

const ADD_GROUP_MUTATION = gql`
  mutation add_contact_group(
    $id: uuid!
    $name: String!
    $description: String!
    $listingId: uuid!
    $createdBy: uuid!
    $objects:[contact_group_rel_insert_input!]!
  ) {
    insert_contact_group(
      objects: {
        id: $id
        name: $name
        description: $description
        listingId: $listingId
        createdBy: $createdBy
      }
    ) {
      affected_rows
    }
    insert_contact_group_rel(
      objects: $objects
    ) {
      affected_rows
    }
  }
`;

const UPDATE_GROUP_MUTATION = gql`
  mutation edit_contact_group(
    $id: uuid!
    $name: String!
    $description: String!
    $objects: [contact_group_rel_insert_input!]!
    $uncheckedArray: [uuid!]!
  ) {
    update_contact_group(
      where: { id: { _eq: $id } }
      _set: { name: $name, description: $description }
    ) {
      affected_rows
    }
    delete_contact_group_rel(
      where: { group_id: { _eq: $id } }
    ) {
      affected_rows
    }

    insert_contact_group_rel(objects: $objects) {
      affected_rows
    }
  }
`;

const DELETE_GROUP_MUTATION = gql`
  mutation delete_group($id: uuid!) {
    delete_contact_group_rel(where: { group_id: { _eq: $id } }) {
      affected_rows
    }
    delete_contact_group(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// sms table
const ADD_SMS_MUTATION = gql`
  mutation add_sms(
    $id: uuid!
    $title: String!
    $details: String!
    $listingId: uuid!
    $createdBy: uuid!
    $objects:[contact_sms_rel_insert_input!]!
  ) {
    insert_sms(
      objects: {
        id: $id
        title: $title
        details: $details
        listingId: $listingId
        createdBy: $createdBy
      }
    ) {
      affected_rows
    }
    insert_contact_sms_rel(
      objects: $objects
    ) {
      affected_rows
    }
  }
`;

const ADD_EMAIL_MUTATION = gql`
  mutation add_email(
    $id: uuid!
    $title: String!
    $details: String!
    $listingId: uuid!
    $createdBy: uuid!
    $objects:[contact_email_rel_insert_input!]!
  ) {
    insert_email(
      objects: {
        id: $id
        title: $title
        details: $details
        listingId: $listingId
        createdBy: $createdBy
      }
    ) {
      affected_rows
    }
    insert_contact_email_rel(
      objects: $objects
    ) {
      affected_rows
    }
  }
`;

const DELETE_DRAFT = gql`mutation delete_broadcast($id: uuid!) {
  delete_broadcast(where: {id: {_eq: $id}}) {
    affected_rows
  }
}`;

const DELETE_SMS_MUTATION = gql`
mutation delete_broadcast($id: uuid!) {
  delete_broadcast(where: {id: {_eq: $id}}) {
    affected_rows
  }
  delete_broadcast_logs(where: {brodcastId: {_eq: $id}}) {
    affected_rows
  }
}`;


const DELETE_BROADCAST = gql`mutation delete_broadcast($broadcastId: uuid!, $listingId: uuid!) {
  delete_broadcast(where: {_and: {id: {_eq: $broadcastId}, listingId: {_eq: $listingId}}}) {
    affected_rows
  }
  delete_broadcast_logs(where: {brodcastId: {_eq: $broadcastId}}) {
    affected_rows
  }
}`;


const DELETE_EMAIL_MUTATION = gql`
mutation delete_broadcast($id: uuid!) {
  delete_broadcast(where: {id: {_eq: $id}}) {
    affected_rows
  }
  delete_broadcast_logs(where: {brodcastId: {_eq: $id}}) {
    affected_rows
  }
}`;

//add memebrs to listing
const ADD_MEMBER_TO_LISTING = gql` mutation add_user($listingId: uuid, $phoneNumber: String, $role: String!, $userId: uuid) {
  insert_listing_team(objects: {listingId: $listingId, phoneNumber: $phoneNumber, role: $role, userId: $userId}) {
    affected_rows
  }
}`;

const REMOVE_MEMBER_FROM_LISTING = gql` mutation delete_listing_team($userId: uuid, $listingId: uuid!,$phoneNumber:String) {
  delete_listing_team(where: {_and: {userId: {_eq: $userId}, listingId: {_eq: $listingId},phoneNumber:{_eq:$phoneNumber}}}) {
    affected_rows
  }
}`;

const CREATE_LISTING = gql`mutation add_listing(
  $name: String!,
  $address1:String
  $address2:String 
  $city: String,
  $state: String,
  $country: String,
  $postalCode: String,
  $phoneNumber: String!,
  $description: String!,
  $type: String!,
  $createdBy:uuid!,
  $listing_img:String
  ) {
  insert_listing(objects: {
    name: $name,
    address1:$address1
    address2:$address2
    city: $city, 
    state: $state,
    country: $country, 
    postalCode: $postalCode,
    phoneNumber: $phoneNumber,
    description: $description,
    type: $type,
    createdBy:$createdBy,
    listing_img:$listing_img
    }) {
 returning{
  id
  listing_img
  name
}
    affected_rows
  }
}`;

const CREATE_LISTING_VIA_BUSINESS_CONTACT = gql`mutation add_listing(
  $name: String!,
  $address1:String
  $address2:String 
  $city: String,
  $state: String,
  $country: String,
  $postalCode: String,
  $phoneNumber: String!,
  $description: String!,
  $type: String!,
  $createdBy:uuid,
  $listing_img:String,
  $listingClaimed: Boolean,
  $listingCreationBySource:String,
  $listingCreationBySourceListingId:uuid
  ) {
  insert_listing(objects: {
    name: $name,
    address1:$address1
    address2:$address2,
    city: $city, 
    state: $state,
    country: $country, 
    postalCode: $postalCode,
    phoneNumber: $phoneNumber,
    description: $description,
    type: $type,
    createdBy:$createdBy,
    listing_img:$listing_img
    listingClaimed:$listingClaimed
    listingCreationBySource:$listingCreationBySource
    listingCreationBySourceListingId:$listingCreationBySourceListingId
    }) {
 returning{
  id
}
    affected_rows
  }
}`

const ADD_LISTING_URL = gql`mutation insert_listing_url($listingId: uuid!, $urlName: String!) {
  insert_listing_url(objects: {listingId: $listingId, urlName: $urlName}) {
    returning {
      listingId
      urlName
    }
  }
}`;

const UPDATE_LISTING_URL = gql`mutation edit_listing_url($listingId: uuid!, $urlName: String!) {
  update_listing_url(  where: { listingId: { _eq: $listingId } }
    _set: {listingId: $listingId, urlName: $urlName}) {
    returning {
      listingId
      urlName
    }
  }
}`;


const UPDATE_LISTING = gql`mutation edit_listing(
  $listingId: uuid,
  $address1:String
  $address2:String
  $city: String,
  $state: String,
  $country: String,
  $postalCode: String,
  $name: String!,
  $phoneNumber: String!,
  $description: String!,
  $type: String!,
  $createdBy:uuid!,
  $listing_img:String
  ) {
  update_listing(  where: { id: { _eq: $listingId } }
      _set:  {
        name: $name,
        address1:$address1
        address2:$address2
        city: $city, 
        state: $state,
        country: $country, 
        postalCode: $postalCode,
        phoneNumber: $phoneNumber,
        description: $description,
        type: $type,
        createdBy:$createdBy,
        listing_img:$listing_img
          }) {
    affected_rows
  }
}`;

const ADD_TAB_NAMES = gql`mutation insert_transaction_ui($listingId: uuid!,
  $transactionType :  String!, $tabName: String!){
  insert_transaction_ui(objects: {listingId: $listingId, transactionType : $transactionType, tabName: $tabName }){
    returning {
      tabName
      transactionType
      tabName
    }
  }
}`;

const REMOVE_TAB_NAMES = gql`mutation delete_transaction_ui($listingId: uuid!,
  $transactionType :  String!, $tabName: String!){
  delete_transaction_ui( 
    where: {_and: 
     [{listingId:  {_eq: $listingId},
      transactionType: {_eq: $transactionType}, 
      tabName:{_eq: $tabName }}]}) {
   affected_rows
  }
}`;


const CREATE_NEW_TEMPLATE = gql`mutation($name:String! , $listingId:uuid! ,$formDefinition:jsonb , $formUrl:String , $notificationRule:jsonb,$type:String,$createdBy:uuid!,$owner:uuid!,$UISchema:json,$displayTemplate: String,  $templateSettings: jsonb, $templatepublicurl: String, $displayTemplateType: String,$eligibleStatusList:jsonb,$signedStatus :String,
   $displayShop:Boolean,$shorturlForDeeplink :String) {
  insert_transaction_template(objects: {name:$name,listingId:$listingId,formDefinition:$formDefinition,formUrl:$formUrl,notificationRule:$notificationRule,type:$type,createdBy:$createdBy,owner:$owner,UISchema:$UISchema, displayTemplate: $displayTemplate, templateSettings:  $templateSettings, templatepublicurl: $templatepublicurl, displayTemplateType: $displayTemplateType,
    eligibleStatusList :  $eligibleStatusList,signedStatus :$signedStatus, displayShop: $displayShop,
    shorturlForDeeplink: $shorturlForDeeplink  }) {
      returning{
      id
      name
      listingId
      formDefinition
      formUrl
      notificationRule
      type
      createdAt
      updatedAt
      createdBy
      owner
      displayTemplate
      UISchema
      templateSettings
      templatepublicurl
      displayTemplateType
      displayShop
      }
  }
  }`;


const UPDATE_TRANSACTION_TEMPLATE = gql`mutation ($id: uuid!, $name: String!, $formDefinition: jsonb, $formUrl: String, $notificationRule: jsonb, $UISchema: json, $displayTemplate: String, $eligibleStatusList: jsonb,
    $signedStatus: String, $templateSettings: jsonb, $templatepublicurl : String, $displayTemplateType: String, $displayShop:Boolean,$shorturlForDeeplink:String) {
    update_transaction_template(where: {id: {_eq: $id}}, _set: {name: $name, formDefinition: $formDefinition, formUrl: $formUrl, notificationRule: $notificationRule,UISchema: $UISchema, displayTemplate: $displayTemplate, eligibleStatusList: $eligibleStatusList,
    signedStatus: $signedStatus, templateSettings:  $templateSettings, templatepublicurl: $templatepublicurl, displayTemplateType: $displayTemplateType, displayShop: $displayShop,
    shorturlForDeeplink: $shorturlForDeeplink}) {
      returning {
        name
        listingId
        formDefinition
        formUrl
        notificationRule
        type
        createdAt
        updatedAt
        createdBy
        owner
        UISchema
        eligibleStatusList
        displayTemplate
        signedStatus
        templateSettings
        templatepublicurl
        displayTemplateType
        displayShop
        __typename
      }
      __typename
    }
  }`;

const UPDATE_TRANSACTION_TEMPLATE_FOR_ELIGIBLE_STATUS = gql`mutation ($id: uuid!, $name: String!, $formDefinition: jsonb, $formUrl: String, $notificationRule: jsonb, $UISchema: json, $displayTemplate: String, $eligibleStatusList: jsonb,
    $signedStatus: String, $templateSettings: jsonb, $templatepublicurl : String, $displayTemplateType: String) {
    update_transaction_template(where: {id: {_eq: $id}}, _set: {name: $name, formDefinition: $formDefinition, formUrl: $formUrl, notificationRule: $notificationRule,UISchema: $UISchema, displayTemplate: $displayTemplate, eligibleStatusList: $eligibleStatusList,
    signedStatus: $signedStatus, templateSettings:  $templateSettings, templatepublicurl: $templatepublicurl, displayTemplateType: $displayTemplateType}) {
      returning {
        name
        listingId
        formDefinition
        formUrl
        notificationRule
        type
        createdAt
        updatedAt
        createdBy
        owner
        UISchema
        eligibleStatusList
        displayTemplate
        signedStatus
        templateSettings
        templatepublicurl
        displayTemplateType
        displayShop
        __typename
      }
      __typename
    }
  }`;

const ARCHIVE_TRANSACTION_TEMPLATE = gql`mutation update_transaction_template_status($transactionTemplateStatus:String! ,$id:uuid!){
    update_transaction_template(_set:{transactionTemplateStatus:$transactionTemplateStatus } where:{id:{_eq:$id}}){
      affected_rows
    }
  }`;

const ADD_PDF_URL = gql`mutation update_short_url($pdfURL:String! ,$shortURL:String!){
    update_short_url(_set:{pdfURL:$pdfURL } where:{shortURL:{_eq:$shortURL}}){
      affected_rows
    }
  }`;

const ADD_COMMUNICATION_PROVIDER = gql`mutation insert_listing_communication_provider($apiCredentials: jsonb!, $communicationProviderId: uuid!, $communicationScope: jsonb!, $listingId: uuid!, $type: String!) {
    insert_listing_communication_provider(objects: {communicationProviderId: $communicationProviderId, communicationScope: $communicationScope, apiCredentials: $apiCredentials, listingId: $listingId, type: $type}) {
      affected_rows
    }
  }`;

const UPDATE_COMMUNICATION_PROVIDER = gql`mutation update_listing_communication_provider($apiCredentials:jsonb ,$communicationProviderId:uuid!,$communicationScope:jsonb,$type:String,$listingId:uuid,$ID: uuid!){
  update_listing_communication_provider(_set:{apiCredentials:$apiCredentials,communicationProviderId:$communicationProviderId,communicationScope:$communicationScope,type:$type} where:{ID:{_eq:$ID}}){
    affected_rows
  }
}`;

const ADD_BUSINESS_CONTACT = gql`mutation insert_business_contact(
  $listingId: uuid!,
  $businessEmail:String,
  $businessPhoneNumber:String,
  $address1:String,
  $address2:String,
  $city: String,
  $state: String,
  $country: String,
  $postalCode: String,
  $internalBusinessContactId:String,
  $defaultUserContactId:uuid,
  $mappedListingId:uuid,
  $name:String,
  $description:String,
  $notes:String,
  $createdBy:uuid,
  $updatedBy:uuid,
  ) {
insert_business_contact(objects: {
  businessPhoneNumber: $businessPhoneNumber,
  businessEmail: $businessEmail,
  listingId: $listingId, 
  address1:$address1
  address2:$address2
  city: $city, 
  state: $state,
  country: $country, 
  postalCode: $postalCode,
  internalBusinessContactId:$internalBusinessContactId,
  defaultUserContactId:$defaultUserContactId,
  mappedListingId:$mappedListingId,
  name:$name,
  description:$description,
  notes:$notes,
  createdBy:$createdBy,
  updatedBy:$updatedBy,
}) {
  returning {
    id
  }
}
}`;

const EDIT_BUSINESS_CONTACT = gql`mutation update_business_contact(
  $id:uuid,
  $businessEmail:String,
  $businessPhoneNumber:String,
  $address1:String,
  $address2:String,
  $city: String,
  $state: String,
  $country: String,
  $postalCode: String,
  $internalBusinessContactId:String,
  $name:String,
  $description:String
  $notes:String
  $updatedBy:uuid
  ) {
  update_business_contact(
  where: { id: { _eq: $id } }
  _set: { 
    businessEmail: $businessEmail
    businessPhoneNumber: $businessPhoneNumber
    internalBusinessContactId: $internalBusinessContactId
    address1: $address1
    address2: $address2
    city: $city
    state: $state
    postalCode: $postalCode
    name: $name
    country: $country
    description:$description
    notes:$notes
    updatedBy:$updatedBy
    }
  ){
  affected_rows
  }}                                       
`
const DELETE_BUSINESS_CONTACT = gql`mutation update_business_contact_status($status:String! ,$id:uuid!){
update_business_contact(_set:{status:$status } where:{id:{_eq:$id}}){
affected_rows
}
}`;

const UPDATE_MAPPING_USERID = gql`mutation update_contact_mappingUserId($id:uuid! ,$mappingUserId:uuid!){
  update_contact(_set:{mappingUserId:$mappingUserId } where:{id:{_eq:$id}}){
    affected_rows
  }
}`

const UPDATE_BUSINESS_CONTACT_ID = gql`mutation update_contact_businessContactId($id:uuid! ,$businessContactId:uuid!){
  update_contact(_set:{businessContactId:$businessContactId } where:{id:{_eq:$id}}){
    affected_rows
  }
}`

const INSERT_FILTER = gql`mutation insert_filter($listingId:uuid! ,$tableName:String! , $refId:uuid ,$filterData:jsonb!,$createdBy:uuid!,$filterName:String, $userContactId:uuid,
  $businessContactId :uuid) {
  insert_filter_history(objects: { listingId: $listingId, tableName: $tableName, refId: $refId , filterData: $filterData,createdBy:$createdBy,filterName:$filterName,
    userContactId: $userContactId,businessContactId: $businessContactId}) {
    affected_rows
  }
}`
const UPDATE_FILTER = gql`mutation update_filter($filterHistId :uuid!,$listingId:uuid! ,$tableName:String! , $refId:uuid ,$filterData:jsonb!,$createdBy:uuid!,$filterName:String,
  $userContactId:uuid,$businessContactId :uuid){
  update_filter_history(_set:{listingId: $listingId, tableName: $tableName, refId: $refId , filterData: $filterData,createdBy:$createdBy,filterName:$filterName,
    userContactId: $userContactId,businessContactId: $businessContactId } where:{filterHistId :{_eq:$filterHistId}}){
  affected_rows
  }
  }`

const CREATE_FILTER_MASTER_DATA = gql`mutation($name:String, $description:String , $tableName:String , $templateId:uuid , $filterCriteria: json , $listingId:uuid!,$createdBy:uuid! ) {
  insert_filter_master(objects: { name:$name,description: $description, tableName: $tableName, templateId: $templateId ,  filterCriteria: $filterCriteria , listingId:$listingId,createdBy:$createdBy}) {
    affected_rows
  }
}`
const DELETE_FILTER = gql`mutation delete_filter_history($filterHistId:uuid!){
  delete_filter_history(where: {filterHistId: {_eq: $filterHistId}}) {
    affected_rows
  }
}`
const UPDATE_FILTER_MASTER_DATA = gql`mutation update_filter_master($filterCriteria:json ,$listingId:uuid!,$templateId:uuid!,$tableName:String){
  update_filter_master(_set: {filterCriteria:$filterCriteria}, where: {_and: [{listingId: {_eq: $listingId}}, {templateId: {_eq: $templateId}}, {tableName:{_eq:$tableName}}]}) {
    affected_rows
  }
}`
const UPDATE_CONTACT_STATUS = gql`mutation update_contact_status($status:String! ,$id:uuid!){
  update_contact(_set:{status:$status } where:{id:{_eq:$id}}){
    affected_rows
  }
}`

const INSERT_TAG = gql`mutation insert_filter($listingId:uuid! ,$tableName:String! , $refId:uuid ,$tagData :jsonb!,$createdBy:uuid!,$tagName :String,
  $userContactId:uuid,$businessContactId:uuid) {
  insert_tag_history(objects: { listingId: $listingId, tableName: $tableName, refId: $refId , tagData: $tagData,createdBy:$createdBy,tagName:$tagName,
    userContactId: $userContactId,businessContactId: $businessContactId,
  }) {
    affected_rows
  }
}`
const INSERT_TAG_TRANSACTION = gql`mutation transaction_tags($tags:jsonb!, $transactionId:[uuid!] ){
  update_transaction(_set: {tags:$tags}, where: {id: {_in: $transactionId}}) {
    affected_rows
  }
}`
const INSERT_TAG_INDIVIDUAL_CONTACT = gql`mutation contacts_tags($tags:jsonb!, $id:[uuid!] ){
  update_contact(_set: {tags:$tags}, where: {id: {_in: $id}}) {
    affected_rows
  }
}`
const INSERT_TAG_BUSINESS_CONTACT = gql`mutation contacts_tags($tags:jsonb!, $id:[uuid!] ){
  update_business_contact(_set: {tags:$tags}, where: {id: {_in: $id}}) {
    affected_rows
  }
}`
const INSERT_TAG_MESSAGES = gql`mutation contacts_tags($tags:jsonb!, $id:[uuid!] ){
  update_broadcast(_set: {tags:$tags}, where: {id: {_in: $id}}) {
    affected_rows
  }
}`
const DELETE_TAG_HISTORY_BY_ID = gql`mutation delete_tag_history($tagHistId: uuid!) {
  delete_tag_history( where:{ tagHistId: {_eq:$tagHistId}}){
    affected_rows
  }}`
const UPDATE_TAG_HISTORY_BY_ID = gql`mutation update_tag_history($tagHistId :uuid!,$listingId:uuid! ,$tableName:String! , $refId:uuid ,$tagData:jsonb!,$createdBy:uuid!,$tagName:String,
  $userContactId:uuid,$businessContactId:uuid){
  update_tag_history(_set:{listingId: $listingId, tableName: $tableName, refId: $refId , tagData: $tagData,createdBy:$createdBy,tagName:$tagName,
    userContactId: $userContactId,businessContactId: $businessContactId,
  } where:{tagHistId :{_eq:$tagHistId}}){
  affected_rows
  }
  }`
// const INSERT_TRANSACTION_FOR_PUBLIC_URL = gql`mutation insert_filter($listingId:uuid! ,$transactionTemplateId:uuid! ,$recipientBusinessContactId:uuid, $contactId:uuid ,$formData:jsonb!, $recipientUserContactId:uuid, $type:String,$createdBy:uuid!,$currentStatus:String!,
//   $statusUpdatedAt:timestamptz!, $signedIP:String!, $updatedAt: timestamptz! ) {
//   insert_transaction(objects: { listingId: $listingId, transactionTemplateId: $transactionTemplateId, contactId: $contactId , formData: $formData,recipientUserContactId:$recipientUserContactId, recipientBusinessContactId:$recipientBusinessContactId, type:$type,currentStatus: $currentStatus ,
//     createdBy:$createdBy,signedIP:$signedIP , statusUpdatedAt:$statusUpdatedAt,updatedAt: $updatedAt}) {
//     affected_rows
//   }
// }`

const INSERT_TRANSACTION_FOR_PUBLIC_URL = gql`mutation insert_transaction($lead_object: [transaction_insert_input!]! , $arrayOfAssignedObject:[transaction_assigned_to_insert_input!]!, $statusHistory: [status_history_insert_input!]!) {
  insert_transaction(objects: $lead_object) {
    returning {id}
  }
  insert_transaction_assigned_to(objects:$arrayOfAssignedObject){
    affected_rows
  }
  insert_status_history(objects: $statusHistory){
    affected_rows
  }
}`

const INSERT_PRODUCT_CSV = gql`mutation insert_products($categories: [listing_product_categories_insert_input!]!, $products: [listing_products_insert_input!]!) {
  insert_listing_product_categories(objects: $categories) {
    returning {
      category_id
      listing_id
      created_at
    }
  }
  insert_listing_products(objects: $products) {
    returning {
      product_id
      product_name
      product_description
      product_hsn_code
      product_config
      category_id
      listing_id
      created_at
    }
  }
}`

const CREATE_CATEGORIES = gql`mutation insert_products($categories: [listing_product_categories_insert_input!]!) {
  insert_listing_product_categories(objects: $categories) {
    returning {
      category_id
      listing_id
      created_at
    }
  }
}`

const UPDATE_CATEGORY = gql`mutation ($category_id: uuid!, $category_name : String) {
  update_listing_product_categories(where: {category_id: {_eq: $category_id}}, _set: {category_name:  $category_name}) {
    returning {
     category_id
      category_name 
    }
  }
}`

const DELETE_PRODUCTS = gql`mutation delete_products($listingId:uuid!, $templateId: uuid!){
  delete_listing_products(where: {_and: [{listing_id: {_eq: $listingId}}, {transaction_template_id: {_eq: $templateId}}]}) {
    returning {
      product_id
      product_name
      product_description
      product_hsn_code
      product_config
      category_id
      listing_id
      created_at
    }
  }
  delete_listing_product_categories(where: {_and: [{listing_id: {_eq: $listingId}}, {transaction_template_id: {_eq: $templateId}}]}) {
    returning {
      category_id
      listing_id
      created_at
    }
  }
}`

const DELETE_LISTING = gql`mutation update_listing($id:uuid!,$status:String) {
  update_listing(where:{id:{_eq:$id}} 
    _set:{status:$status}) {
    affected_rows
  }    
  }`

const UPDATE_LISTING_CREATEDBY = gql`mutation update_listing($id:uuid!,$createdBy:uuid!) {
  update_listing(where:{id:{_eq:$id}} 
    _set:{createdBy:$createdBy}) {
    affected_rows
  }    
  }`;


const UPDATE_ECOMMERCE_ORDER = gql` mutation edit__ecommerce_order($id: uuid!, $fromdata: jsonb!) {
    update_transaction(  where: { id: { _eq: $id } }
      _set: {formData: $fromdata}) {
      returning {
        listingId
      }
    }
  }`;

const UPDATE_UPLOAD_IMAGE_STATUS = gql`mutation ($id: uuid!, $templateSettings: jsonb!) {
    update_transaction_template(where: {id: {_eq: $id}}, _set: {templateSettings:  $templateSettings}) {
      returning {
        name
        listingId
        templateSettings
        __typename
      }
      __typename
    }
  }`

const BULK_UPDATE_CONTACT_STATUS = gql`mutation update_contact_status ($contactId:[uuid!]){
    update_contact(_set: {status: "ARCHIVED" }, where: {id: {_in: $contactId}}) {
      affected_rows
    }
  }`

const BULK_UPDATE_BUSINESS_CONTACT_STATUS = gql`mutation update_business_contact_status ($businessId:[uuid!]){
    update_business_contact(_set: {status: "ARCHIVED" }, where: {id: {_in: $businessId}}) {
      affected_rows
    }
  }`

const UPADTE_ORDER_COUNT = gql`mutation update_order_count($templateId: uuid!, $orderCount: Int){
    update_transaction_template(_set: {ordercount:$orderCount}, where: {id: {_eq: $templateId}}) {
      affected_rows
    }
  }`;



const INSERT_PRODUCT_INVENTORY = gql`mutation insert_product_inventory($prodInventory:[product_inventory_insert_input!]!){
  insert_product_inventory(objects:$prodInventory){
    affected_rows
  }
}`;

const UPDATE_PRODUCT_INVENTORY = gql`mutation update_product_inventory($transaction_template_id: uuid!, $product_id: String!, $onhand_quantity: Int) {
  update_product_inventory(where: {_and: {transaction_template_id: {_eq: $transaction_template_id}, product_id: {_eq: $product_id}}}
    _set: {onhand_quantity: $onhand_quantity }) {
      affected_rows
  }
}`

const INSERT_PRODUCT_INVENTORY_HISTORY = gql`mutation insert_product_inventory_history($prodInventoryHistory:[product_inventory_history_insert_input!]!){
  insert_product_inventory_history(objects:$prodInventoryHistory){
    affected_rows
  }
}`;

export {
  //inventory
  INSERT_PRODUCT_INVENTORY,
  UPDATE_PRODUCT_INVENTORY,
  INSERT_PRODUCT_INVENTORY_HISTORY,
  // user table
  ADD_USER_MUTATION,
  UPDATE_USER_MUTATION,
  SIGNED_MUTATION,
  //contact table
  ADD_CONTACT_MUTATION,
  UPDATE_CONTACT_MUTATION,
  UPDATE_CONTACT_FROM_CONTRACT_MUTATION,
  DELETE_CONTACT_MUTATION,
  ASSIGN_MEMBERS,
  UPDATE_TRANSACTION,
  DELETE_EMAIL_MUTATION,
  ADD_TRANSACTION_UPLOAD,
  DELETE_TRANSACTION,
  STATUS_CHANGE,
  // contact_upload table
  ADD_CONTACT_UPLOAD_URL_MUTATION,
  REMOVE_ASSIGNED,
  //contact_group table
  ADD_GROUP_MUTATION,
  UPDATE_GROUP_MUTATION,
  DELETE_GROUP_MUTATION,
  ADD_EMAIL_MUTATION,
  DELETE_BROADCAST,
  SAVE_AS_DRAFT,
  // sms table
  ADD_SMS_MUTATION,
  DELETE_SMS_MUTATION,
  ADD_TEMPLATE_MUTATION,
  DELETE_DRAFT,
  ADD_TRANSACTION,
  updateCount,
  NOTIFICATION_FILE_UPLOAD,
  _signedFiles,
  //listing_team table
  ADD_MEMBER_TO_LISTING,
  REMOVE_MEMBER_FROM_LISTING,
  CREATE_LISTING,
  ADD_LISTING_URL,
  UPDATE_LISTING,
  UPDATE_LISTING_URL,

  //transaction ui
  ADD_TAB_NAMES,
  REMOVE_TAB_NAMES,

  //transaction_template
  CREATE_NEW_TEMPLATE,
  UPDATE_TRANSACTION_TEMPLATE,

  UPDATE_TEMPLATE_MUTATION,
  BULK_STATUS_CHANGE,
  ARCHIVE_TRANSACTION_TEMPLATE,
  ADD_PDF_URL,
  ADD_COMMUNICATION_PROVIDER,
  UPDATE_COMMUNICATION_PROVIDER,
  ADD_BUSINESS_CONTACT,
  EDIT_BUSINESS_CONTACT,
  DELETE_BUSINESS_CONTACT,
  CREATE_LISTING_VIA_BUSINESS_CONTACT,
  UPDATE_MAPPING_USERID,
  INSERT_FILTER,
  UPDATE_FILTER,
  CREATE_FILTER_MASTER_DATA,
  DELETE_FILTER,
  UPDATE_FILTER_MASTER_DATA,

  UPDATE_BUSINESS_CONTACT_ID,
  UPDATE_CONTACT_STATUS,
  INSERT_TAG,
  INSERT_TAG_TRANSACTION,
  INSERT_TAG_INDIVIDUAL_CONTACT,
  INSERT_TAG_BUSINESS_CONTACT,
  INSERT_TAG_MESSAGES,
  DELETE_TAG_HISTORY_BY_ID,
  UPDATE_TAG_HISTORY_BY_ID,
  INSERT_TRANSACTION_FOR_PUBLIC_URL,
  INSERT_PRODUCT_CSV,
  DELETE_PRODUCTS,
  DELETE_LISTING,
  UPDATE_LISTING_CREATEDBY,

  UPDATE_ECOMMERCE_ORDER,
  CREATE_CATEGORIES,
  UPDATE_UPLOAD_IMAGE_STATUS,
  UPDATE_CATEGORY,
  UPDATE_CONTACT,
  BULK_UPDATE_CONTACT_STATUS,
  BULK_UPDATE_BUSINESS_CONTACT_STATUS,
  UPDATE_TRANSACTION_TEMPLATE_FOR_ELIGIBLE_STATUS,
  UPADTE_ORDER_COUNT
};
