export default [
  {
    'id': 1,
    'name': 'Alex Dolgove',
    'thumb': 'https://via.placeholder.com/150x150',
  }, {
    'id': 2,
    'name': 'Domnic Harris',
    'thumb': 'https://via.placeholder.com/150x150',
  }, {
    'id': 3,
    'name': 'Garry Sobars',
    'thumb': 'https://via.placeholder.com/150x150',
  }, {
    'id': 4,
    'name': 'Stella Johnson',
    'thumb': 'https://via.placeholder.com/150x150',
  }, {
    'id': 5,
    'name': 'John Smith',
    'thumb': 'https://via.placeholder.com/150x150',
  }, {
    'id': 6,
    'name': 'Domnic Brown',
    'thumb': 'https://via.placeholder.com/150x150',
  }
]
