import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'
import { storage } from '../../../firebase/firebase';

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox, Upload, Modal } from 'antd';

import DynamicFields from './DynamicFields';


const { Option } = Select;
const uuidv1 = require('uuid/v1');

let initialData = {
    attributeName: '',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: ''
}

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class HeaderDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            headerDetails: [],
            headerImage: {
                ImageDetail: [],
                showImage: false
            },
            id: 0,
            deletedKeys: [],
            previewVisible: false
        }
    };

    componentWillMount() {
        console.log(this.props.editData, 'editData')
        if (this.props.editData && this.props.editData.HeaderDetailValues) {
            this.setState({ headerDetails: this.props.editData.HeaderDetailValues });
        } else {
            if (this.state.headerDetails.length === 0) {
                let data = this.state.headerDetails
                data.push(initialData)
                this.setState({ headerDetails: data, loading: false })
            }
        }

        if (this.props.editData && this.props.editData.HeaderImage) {
            this.setState({ headerImage: this.props.editData.HeaderImage });
        }

    }

    onAddRow = () => {
        let data = this.state.headerDetails
        let initialData = {
            attributeName: '',
            attributeValue: '',
            showAttributeName: false,
            required: false,
            attributeType: ''
        }

        if(data) {data.push(initialData)}
        this.setState({ headerDetails: data })
    }


    LoopItemsChange(headerDetails) {
        this.setState({ headerDetails }, () => {
            this.props.HeaderDetailSave(headerDetails);
        })
    }

    onRemove(headerDetails) {
        this.setState({ headerDetails });
    }

    onImageChecked(e) {
        this.state.headerImage.showImage = e.target.checked ? true : false;
        this.setState({ headerImage: this.state.headerImage }, () => {
            this.props.HeaderImageSave(this.state.headerImage);
        });
    }


    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = (info) => {
        let data = cloneDeep(this.state.headerImage)
        let file = null;
        if (info.fileList) {
            if (info.fileList.length > 0) {
                file = info.fileList[info.fileList.length - 1] && info.fileList[info.fileList.length - 1].originFileObj;
            }
        }
        if (file) {
            const file = info.fileList[0].originFileObj;
            const fileName = file.name;
            const fileUrl = `ecommerce/${file.name}`;
            const uploadTask = storage.ref(fileUrl).put(file);
            uploadTask.on(
                'state_changed',
                snapshot => {
                    snapshot.ref.getDownloadURL().then((downloadURL) => {
                        // message.success(`${info.file.name} file uploaded successfully`);
                        if (downloadURL) {
                            let _fileId = uuidv1();
                            const imageData = [{
                                uid: _fileId,
                                name: fileName,
                                status: 'done',
                                url: downloadURL
                            }];
                            data.ImageDetail = imageData;
                            this.setState({ headerImage: data }, () => {
                                this.props.HeaderImageSave(this.state.headerImage);
                            })
                        }
                    });
                    // progress function
                },
                error => {
                    // this.setState({ showLoading: true })
                    //error function
                });
        }
    }

    onRemove() {
        let data = cloneDeep(this.state.headerImage)
        data.ImageDetail = []
        this.setState({ headerImage: data }, () => {
            this.props.HeaderImageSave(this.state.headerImage);
        })
    }

    handleCancel = () => this.setState({ previewVisible: false });


    render() {
        const { headerDetails, headerImage, previewVisible, previewImage } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        const uploadButton = (
            <div>
                <Icon id="icon-view" type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        console.log(headerDetails, 'cutomerdetails')
        return (
            <Form
                onSubmit={this.handleSubmit}
            >
                <div style={{ paddingBottom: '17px' }} >
                    <span>Add seller details you want to display in the order form. The form field name displays the title and the form field value displays the details. </span>
                </div>

                <div>
                    <Row span={24} style={{alignItems: 'center', marginBottom: '10px'}}>
                        <Col xs={12} sm={12} md={5} lg={5}>
                            <span>Add Store Image </span>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5}>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={headerImage.ImageDetail}
                                onPreview={this.handlePreview}
                                onChange={this.handleChange}
                                onRemove={this.onRemove.bind(this)}
                                disabled={this.state.disabled}
                            >
                                {headerImage.ImageDetail.length > 0 ? null : uploadButton}
                            </Upload>
                            <Modal className="modal-popup" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                               <img alt="example" style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={8}>
                            <Checkbox className="show-attribute-field"
                                defaultChecked={false}
                                checked={headerImage.showImage}
                                onChange={(e) => this.onImageChecked(e)}>
                            </Checkbox>
                            {/* <span style={{paddingLeft: '10px'}}>Show Image</span> */}
                            <span style={{paddingLeft: '10px'}}>Display store image</span>
                        </Col>
                    </Row>
                </div>
                <div >
                    <DynamicFields
                        accessFrom='Header'
                        LoopItems={headerDetails}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        isEdit={this.props.isEdit ? true : false}
                    />
                </div>
                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(HeaderDetails);
export default WrappedDynamicFieldSet

