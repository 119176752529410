import React from "react";
import _ from "lodash"
import CustomScrollbars from "util/CustomScrollbars";
import { CountryDropdown } from 'react-country-region-selector';

import { Link, withRouter } from "react-router-dom";
import axios from 'axios';
import Hashids from 'hashids';
import PhoneInput from 'react-phone-input-2'

import { Button, Checkbox, Form, Icon, Input, message, Card, Divider, Switch, Spin, Select, Tooltip, Popconfirm } from "antd";
import { storage } from '../../firebase/firebase';

import { Modal, Upload, Table } from "antd";
import { Query } from "react-apollo";
import { ADD_MEMBER_TO_LISTING, REMOVE_MEMBER_FROM_LISTING, CREATE_LISTING, ADD_LISTING_URL, UPDATE_LISTING, UPDATE_LISTING_URL, DELETE_LISTING } from '../../graphQl/mutations';
import { USER_WITH_PHONE_NUM_QUERY, USER_WITH_UID, CLONE_LISTING } from '../../graphQl/queries';
import { BREAK } from "graphql";
import client from '../../apollo/apollo';
import AddTransaction from './addTransaction';
import CircularProgress from "../../components/CircularProgress/index";
import AddCommunicationProvider from './AddCommunicationProvider'


import List from '../List/index'
import { CONFIG } from "config/config";
import { getENVConfig } from '../../util/common'
import { AddListingCommunictionProviderSMS, AddListingCommunictionProviderEMAIL } from '../../util/Listings'


const FormItem = Form.Item;
const Option = Select.Option;

const { TextArea } = Input;
const ListingData = [
    'contract', 'lead', 'order', 'review', 'registration'
]

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const uuidv3 = require('uuid/v3');
const uuidv4 = require('uuid/v4');
const uuidv1 = require('uuid/v1');

class Listings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            imageData: [],
            uploadingImage: false,
            submittingProfile: false,
            disabled: true,
            submittingTeam: false,
            imageReset: false,
            isEditCommunication: false,
            listingData: {},
            country: 'India',
            pageValue: 1,
            contactUserName: '',
            path: '',
            listingTeamMembers: []
        }
    }

    componentWillMount() {
        const data = this.props.data[0];
        this.subsCommunication(data)

        if (data) {
            if (this.state.imageData.length === 0) {
                data.listUrl = data.name.replace(/\s/g, '');
                data.listUrl = data.listUrl.slice(0, 15).toLowerCase()
                if (!this.state.deletedimage && (data.listing_img || data.listing_img !== '')) {
                    this.state.imageData = [{
                        url: data.listing_img,
                        uid: localStorage.getItem('uid')
                    }];
                }
            }
            // todo: naaziya: @shanmugapriya, @jagadeesh pls check this flow
            this.getListingContactDetails(data);
        } else {
            this.state.disabled = false;
        }
    }

    componentDidMount() {
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.pathname) {
            this.setState({ path: this.props.history.location.pathname })
        }

        // todo: naaziya: @shanmugapriya, @jagadeesh pls check this flow
        if (this.props.data) {
            if (this.props.data.length != 0) {
                this.setState({
                    listingTeamMembers: this.props && this.props.data.length > 0 ?
                        this.props.data[0].listing_teams : []
                })
                this.getListingContactDetails(this.props.data[0]);
            }
        }
    }

    // todo: naaziya: @shanmugapriya, @jagadeesh pls check this flow
    // bind the contact user name in profile
    getListingContactDetails(data) {

        let name = '';

        if (data) {
            if (data.hasOwnProperty('createdBy')) {
                if (data.createdBy != '' && data.createdBy != null) {

                    let userPayload = {
                        "uid": data.createdBy
                    }

                    client.query({ query: USER_WITH_UID, variables: userPayload }).then((userDetails) => {
                        if (userDetails && userDetails.data) {
                            if (userDetails.hasOwnProperty('data')) {
                                if (userDetails.data.hasOwnProperty('user')) {
                                    if (userDetails.data.user.length > 0) {

                                        let firstName = '';
                                        let lastName = '';

                                        if (userDetails.data.user[0].hasOwnProperty('firstName')) {
                                            if (userDetails.data.user[0].firstName != '' && userDetails.data.user[0].firstName != null) {
                                                firstName = userDetails.data.user[0].firstName;
                                            }
                                        }

                                        if (userDetails.data.user[0].hasOwnProperty('lastName')) {
                                            if (userDetails.data.user[0].lastName != '' && userDetails.data.user[0].lastName != null) {
                                                lastName = userDetails.data.user[0].lastName;
                                            }
                                        }
                                        name = firstName + ' ' + lastName;

                                        this.setState({
                                            contactUserName: name
                                        })
                                    }
                                }
                            }
                        }
                    })
                }
            }
        }

    }

    componentWillReceiveProps(newProps) {
        if (newProps.data !== this.props.data) {
            this.setState({
                listingTeamMembers: newProps.data.length > 0 &&
                    newProps.data[0].listing_teams ? newProps.data[0].listing_teams : []
            })
        }
        this.subsCommunication(newProps.data[0])
        // todo: naaziya: @shanmugapriya, @jagadeesh pls check this flow
        if (this.props.data !== newProps.data) {
            if (newProps.data.length != 0) {
                this.getListingContactDetails(newProps.data[0]);
            }
        }
    }

    subsCommunication = (propsValue) => {
        const data = propsValue
        if (data && data.listing_communication_providers.length > 0) {
            const communicationValues = data.listing_communication_providers.map(o => {
                if (o.type === 'email') {
                    return this.changePromotionalTransaction(o, 'email')
                } else {
                    return this.changePromotionalTransaction(o, 'sms')
                }
            });
            this.setState({ communicationData: communicationValues, listingData: data }, () => {
            })
        }

        if (data && data.listing_img) {
            this.state.imageData = [];
            const url = {
                url: data.listing_img,
                uid: localStorage.getItem('uid')
            }
            this.state.imageData.push(url);
            this.setState({ imageData: this.state.imageData });

        }
    }

    changePromotionalTransaction(o, value) {
        let data = []
        let temp = {}
        let apiData
        o.communicationScope.promotional.map(val => {
            const temp = {
                type: 'Promotional',
                email: val
            }
            data.push(temp)
        })

        o.communicationScope.transactional.map(val => {
            const temp = {
                type: 'Transactional',
                email: val
            }
            data.push(temp)
        })

        if (o.apiCredentials.promotional !== null) {
            const temp = {
                type: 'ApiPromotional',
                email: o.apiCredentials.promotional
            }
            data.push(temp)
        }

        if (o.apiCredentials.transactional !== null) {
            const temp = {
                type: 'ApiTransactional',
                email: o.apiCredentials.transactional
            }
            data.push(temp)
        }

        if (value === 'sms') {
            temp = {
                sms: data
            }
        } else {
            temp = {
                email: data
            }
        }
        return temp
    }

    removeMember(memberData) {
        this.setState({ submittingTeam: true });
        if (memberData.userInfo && memberData.userInfo.id) {
            const payload = {
                'userId': memberData.userInfo.id,
                'listingId': localStorage.getItem('selectedListingId'),
                'phoneNumber': memberData.phoneNumber
            };
            let response = client.mutate({ variables: payload, mutation: REMOVE_MEMBER_FROM_LISTING }).then(async (result) => {
                if (result.data.delete_listing_team.affected_rows > 0) {
                    await message.success("Member removed from listing!")
                    this.setState({ submittingTeam: false });
                }
            }).catch(error => {
                message.error("Failed removing member from listing!")
                this.setState({ submittingTeam: false });
            })
        } else if (memberData.phoneNumber) {
            const payload = {
                'listingId': localStorage.getItem('selectedListingId'),
                'phoneNumber': memberData.phoneNumber
            };
            let response = client.mutate({ variables: payload, mutation: REMOVE_MEMBER_FROM_LISTING }).then(async (result) => {
                if (result.data.delete_listing_team.affected_rows > 0) {
                    await message.success("Member removed from listing!")
                    this.setState({ submittingTeam: false });
                }
            }).catch(error => {
                message.error("Failed removing member from listing!")
                this.setState({ submittingTeam: false });
            })
        } else {
            message.error("Failed removing member from listing!")
            this.setState({ submittingTeam: false });
        }
    }

    addMember() {
        this.props.form.validateFields((err, values) => {

            const querypayload = {
                'phone_number': values.PhoneNumber
            };
            if (!err) {
                this.setState({ loading: true, submittingTeam: true });
                client.query({ query: USER_WITH_PHONE_NUM_QUERY, variables: querypayload }).then((isuser) => {
                    if (isuser.data.user.length > 0) {

                        const payload = {
                            listingUrl: localStorage.getItem('selectedListingName'),
                            isuserId: isuser.data.user[0].id,
                            listingId: localStorage.getItem('selectedListingId'),
                            phoneNumber: values.PhoneNumber,
                            roleOftheInvitee: localStorage.getItem('role'),
                            role: this.state.role, invitationType: 'SMS', userId: localStorage.getItem('uid'),
                            email: values.Email
                        };

                        axios({
                            'method': 'POST',
                            url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/inviteUser`,
                            // url: `http://localhost:5001/totely-prod1-ba624/us-central1/inviteUser`,
                            headers: { 'Content-Type': 'application/json' },
                            'data': payload
                        }).then((response) => {
                            message.success("Member  Added to listing and Invite Sent!")
                            this.setState({ addUsers: false, submittingTeam: false, role: '' });
                            this.props.form.resetFields();
                        }).
                            catch((err) => {
                                message.error("Failed adding member to listing!")
                                this.setState({ submittingTeam: false });
                            })
                    } else {
                        const payload = {
                            listingUrl: localStorage.getItem('selectedListingName'),
                            listingId: localStorage.getItem('selectedListingId'),
                            phoneNumber: values.PhoneNumber,
                            roleOftheInvitee: localStorage.getItem('role'),
                            role: this.state.role,
                            invitationType: 'SMS',
                            userId: localStorage.getItem('uid'),
                            email: values.Email
                        };
                        console.log(payload, 'isuser not payload')

                        axios({
                            'method': 'POST',
                            url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/inviteUser`,
                            headers: { 'Content-Type': 'application/json' },
                            'data': payload
                        }).then((response) => {
                            message.success("Member  Added to listing and Invite Sent!")
                            this.setState({ addUsers: false, submittingTeam: false, role: '' });
                            this.props.form.resetFields()
                        }).
                            catch((err) => {
                                message.error("Failed adding member to listing!")
                                this.setState({ submittingTeam: false });
                            })
                    }
                })

            };
        });

    }

    onSubmit(data) {

        if (data === 'submit') {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({ submittingProfile: true });
                    const payload = {
                        name: values.Name,
                        phoneNumber: values.PhoneNumber,
                        description: values.Description,
                        // address: values.Address,
                        // streetName: values.streetName,
                        address1: values.address1,
                        address2: values.address2,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                        postalCode: values.postalCode,
                        createdBy: localStorage.getItem('uid'),
                        type: 'Bussiness',
                        listing_img: this.state.imageData ? this.state.imageData.length > 0 ? this.state.imageData[0].url : '' : ''
                    };
                    client.mutate({ mutation: CREATE_LISTING, variables: payload }).then((response) => {
                        const addMember = {
                            "listingId": response.data.insert_listing.returning[0].id,
                            "role": "listingAdmin",
                            "userId": localStorage.getItem('uid')
                        };
                        client.mutate({ variables: addMember, mutation: ADD_MEMBER_TO_LISTING }).then((result) => {
                            const addUrl = {
                                "listingId": response.data.insert_listing.returning[0].id,
                                "urlName": values.ListingURL
                            };
                            client.mutate({ variables: addUrl, mutation: ADD_LISTING_URL }).then((urlres) => {
                                this.setState({ submittingProfile: false, imageData: [] });
                                if (urlres && urlres.data && urlres.data.insert_listing_url &&
                                    urlres.data.insert_listing_url.returning.length > 0 &&
                                    urlres.data.insert_listing_url.returning[0].listingId) {
                                    let listingId = urlres.data.insert_listing_url.returning[0].listingId
                                    AddListingCommunictionProviderEMAIL(listingId)
                                        .then((res) => {
                                        }).catch(err => {
                                            message.error('Error on creating AddListingCommunictionProviderEMAIL')
                                        })
                                    AddListingCommunictionProviderSMS(listingId)
                                        .then((res) => {
                                        }).catch(err => {
                                            message.error('Error on creating AddListingCommunictionProviderSMS')
                                        })
                                }
                                this.props.form.resetFields()
                                message.success('New Listing Created Sucessfully!')
                                this.props.history.push({
                                    pathname: `/app/console/${urlres.data.insert_listing_url.returning[0].urlName}`,
                                    state: { navigatedAfterCreate: true }
                                });
                                localStorage.setItem('selectedListingId', response.data.insert_listing.returning[0].id)
                                localStorage.setItem('selectedListingName', urlres.data.insert_listing_url.returning[0].urlName)
                                localStorage.setItem('listinglogo', response.data.insert_listing.returning[0].listing_img)
                                localStorage.setItem('logoListingName', response.data.insert_listing.returning[0].name)
                            }).catch((err) => {
                                this.setState({ submittingProfile: false });
                                message.error('New Listing Created but failed to add you as Admin');
                            })
                        }).catch(err => {
                            this.setState({ submittingProfile: false });
                            message.error('New Listing Creation Failed');
                        });
                    }).catch(err => {
                        this.setState({ submittingProfile: false });
                        message.error('New Listing Creation Failed')
                    });
                }
            });
        } else if (data === 'save') {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.setState({ submittingProfile: true });
                    const payload = {
                        name: values.Name,
                        phoneNumber: values.PhoneNumber,
                        description: values.Description,
                        // address: values.Address,
                        // streetName: values.streetName,
                        address1: values.address1,
                        address2: values.address2,
                        city: values.city,
                        state: values.state,
                        country: values.country,
                        postalCode: values.postalCode,
                        createdBy: localStorage.getItem('uid'),
                        type: 'Bussiness',
                        listing_img: this.state.imageData.length > 0 ? this.state.imageData[0].url ? this.state.imageData[0].url : '' : '',
                        listingId: localStorage.getItem('selectedListingId')
                    };
                    client.mutate({ mutation: UPDATE_LISTING, variables: payload }).then((response) => {
                        const addUrl = {
                            "listingId": localStorage.getItem('selectedListingId'),
                            "urlName": values.ListingURL
                        };
                        client.mutate({ variables: addUrl, mutation: UPDATE_LISTING_URL }).then((urlres) => {
                            this.setState({ submittingProfile: false });
                            message.success('Listing Details Saved Successfully!')
                            localStorage.setItem('listinglogo', payload.listing_img)
                            window.location.reload();
                        }).catch(err => {
                            this.setState({ submittingProfile: false });
                            message.error('Failed Saving Listing Details')
                        });
                    }).catch(err => {
                        this.setState({ submittingProfile: false });
                        message.error('Failed Saving Listing Details')
                    });
                }
            });
        } else if (data === 'edit') {
            this.setState({ disabled: false });
        }
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = (event) => {
        let file = null;
        // todo: naaziya: @jagadeesh fixed the bussiness image upload issue

        if (event.fileList) {
            if (event.fileList.length > 0) {
                file = event.fileList[event.fileList.length - 1] && event.fileList[event.fileList.length - 1].originFileObj;
            }
        }
        if (file) {
            this.setState({ uploadingImage: true });
            const fileName = file.name;
            const listingId = localStorage.getItem('selectedListingId');
            // if (fileName.length === 0) return;
            const fileUrl = `listingLogo/${file.name}`;
            let salt = String(fileUrl) + 'SMS';
            let urlString = new Hashids(salt, 8);
            let randomInteger = Math.floor(Math.random() * Math.floor(100000));
            urlString = urlString.encode(randomInteger);
            const uploadTask = storage.ref(fileUrl).put(file);
            uploadTask.on(
                'state_changed',
                snapshot => {
                    // progress function
                },
                error => {
                    //error function
                },
                () => {
                    // complete function
                    storage
                        .ref('listingLogo/')
                        .child(file.name)
                        .getDownloadURL()
                        .then(uploadUrl => {
                            let _fileId = uuidv1();
                            if (fileUrl.length > 0) {
                                const imageData = [{
                                    uid: _fileId,
                                    name: fileName,
                                    status: 'done',
                                    url: uploadUrl
                                }];
                                this.setState({ imageData, uploadingImage: false, deletedimage: false });
                            }
                        }).catch(() => {
                            this.setState({ uploadingImage: false });
                        })
                }
            );
        }
    }

    onRemove() {
        this.setState({ imageData: [], deletedimage: true }, () => { })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    onRoleChange(value) {
        this.setState({ role: value });
    }

    onEditCommunication = () => {
        this.setState({ isEditCommunication: true })
    }

    onBackToCommunicationList = () => {
        this.setState({ isEditCommunication: false })
    }

    setStateSelectedPage = (page) => {
        this.setState({ pageValue: page })
    }

    onClickDelete = () => {
        const payload = {
            id: localStorage.getItem('selectedListingId'),
            status: 'ARCHIVE'
        }

        client.mutate({ mutation: DELETE_LISTING, variables: payload }).then((response) => {
            message.success('Listing deleted successfully')
            this.props.history.push({
                pathname: `/app/console/listings`,
            })
            localStorage.removeItem('selectedListingId')
            localStorage.removeItem('selectedListingName')
            localStorage.removeItem('logoListingName')
        })
            .catch(err => {
                message.error('Listing delete failed')
            })

    }


    onClickClone = () => {
        let listingId = localStorage.getItem('selectedListingId')
        let uid = localStorage.getItem('uid')
        if (listingId && uid) {
            const params = {
                p_listingid: listingId,
                p_userid: uid
            }
            console.log('onClickClone', params)
            client.query({ query: CLONE_LISTING, variables: params }).then(response => {
                console.log('response', response)
            })
                .catch(err => {
                    console.log('clone gql err', err)
                })

        }

    }


    render() {

        const data = this.props.data[0];

        const userRole = localStorage.getItem('role')

        const { communicationData, imageReset, path } = this.state

        const { previewVisible, previewImage } = this.state;
        const uploadButton = (
            <div>
                <Icon id="icon-view" type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const { getFieldDecorator } = this.props.form;
        const emailColumns = [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            },
        ]
        const smsColumns = [
            {
                title: 'SMS',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
            },
        ]
        return (
            <div className="gx-main-content" style={{ paddingTop: '25px' }}>
                <div className="gx-module-detail gx-module-list" id={this.props.selectedTab === 'Team' && "main-form-content"}>
                    {this.props.selectedTab === 'Profile' &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '28px' }}>
                            {/* <Button onClick={() => this.onSubmit(data ? !this.state.disabled ? 'save' : "edit" : !this.state.disabled ? 'submit': 'edit')}>{data ? !this.state.disabled ? 'Save' : "EDIT" : !this.state.disabled ? 'Submit' : 'ENABLE'}</Button> */}
                            {/* {userRole === 'listingAdmin' && data &&
                                <div style={{ marginRight: 10, marginTop: -5 }}>
                                    <Button type="primary" onClick={() => this.onClickClone()}>Clone</Button>
                                </div>
                            } */}
                            <div>
                                {this.state.disabled &&
                                    // <Icon id="icon-view" type="edit" onClick={() => this.onSubmit('edit')} />
                                    <div style={{ backgroundColor: '#3396fa', color: '#fff', fontSize: 12, borderRadius: 3, padding: 6, cursor: 'pointer' }} onClick={() => this.onSubmit('edit')}>Edit</div>
                                }
                            </div>
                            <div>
                                {!this.state.disabled &&
                                    // <Icon id="icon-view" type="save" onClick={() => this.onSubmit(data ? 'save' : 'submit')} />
                                    <div style={{ backgroundColor: '#3396fa', color: '#fff', fontSize: 12, borderRadius: 3, padding: 6, cursor: 'pointer' }} onClick={() => this.onSubmit(data ? 'save' : 'submit')}>Save</div>
                                }
                            </div>
                            <div>
                                {path !== `/app/console/listings` &&
                                    data && data.createdBy && data.createdBy === localStorage.getItem('uid') &&
                                    <Popconfirm
                                        title="Are you sure delete this listing?"
                                        onConfirm={() => { this.onClickDelete() }}
                                        okText="Yes"
                                        cancelText="No"
                                        placement="bottomRight"
                                    >
                                        {/* <Icon id="icon-view" type="delete" style={{ marginLeft: 10 }} /> */}
                                        <div style={{ backgroundColor: 'red', color: '#fff', fontSize: 12, borderRadius: 3, padding: 6, marginLeft: 10, cursor: 'pointer' }}>Delete</div>
                                    </Popconfirm>
                                }
                            </div>

                        </div>
                    }
                    {/* <CustomScrollbars className="gx-todo-detail-content-scroll" id="main-form-view"> */}
                    <div className="">
                        {this.props.selectedTab === 'Profile' &&
                            <div className="business-form-content">
                                <Spin spinning={this.state.submittingProfile}>
                                    <Form
                                    >
                                        <FormItem
                                            label="Business Image"
                                            className="business-view"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('Bussiness Image', {
                                                initialValue: data ? data.listing_img ? data.listing_img : '' : '',
                                                rules: [{ required: false, message: 'Please upload Business Image!' }],
                                            })(

                                                <div className="clearfix">
                                                    <Spin spinning={this.state.uploadingImage}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={this.state.imageData}
                                                            onPreview={this.handlePreview}
                                                            onChange={this.handleChange}
                                                            onRemove={this.onRemove.bind(this)}
                                                            disabled={this.state.disabled}
                                                        >
                                                            {this.state.imageData.length > 0 ? null : uploadButton}
                                                        </Upload>
                                                    </Spin>
                                                    <Modal className="modal-popup" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                                    </Modal>
                                                </div>
                                            )}
                                        </FormItem>

                                        <FormItem
                                            label="Name"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('Name', {
                                                initialValue: data ? data.name ? data.name : '' : '',
                                                rules: [{ required: true, message: 'Please input Name!' }],
                                            })(
                                                <Input
                                                    disabled={this.state.disabled} />
                                            )}
                                        </FormItem>
                                        <FormItem
                                            label="Business URL"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('ListingURL', {
                                                initialValue: data ? data.listing_urls[0] ? data.listing_urls[0].urlName ? data.listing_urls[0].urlName : '' : '' : '',
                                                rules: [{ required: true, message: 'Please input ListingURL!' }],
                                            })(
                                                <Input
                                                    disabled={this.state.disabled} />
                                            )}
                                        </FormItem>



                                        {/* <FormItem
                                                label="Address"
                                                labelCol={{ xs: 24, sm: 5 }}
                                                wrapperCol={{ xs: 24, sm: 12 }}
                                            >
                                                {getFieldDecorator('Address', {
                                                    initialValue: data ? data.address ? data.address : '' : '',
                                                    rules: [{ required: true, message: 'Please input Address!' }],
                                                })(
                                                    <TextArea rows={3}
                                                        disabled={this.state.disabled} />
                                                )}
                                            </FormItem> */}

                                        <Form.Item className="Sample"
                                            label="Address1"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('address1', {
                                                initialValue: data && data.address1 ? data.address1 : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input address1',
                                                    },
                                                ],
                                            })(<Input disabled={this.state.disabled} />)}
                                        </Form.Item>
                                        <Form.Item className="Sample"
                                            label="Address2"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('address2', {
                                                initialValue: data && data.address2 ? data.address2 : '',
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please input address2',
                                                    },
                                                ],
                                            })(<Input disabled={this.state.disabled} />)}
                                        </Form.Item>
                                        <Form.Item className="Sample"
                                            label="City"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('city', {
                                                initialValue: data && data.city ? data.city : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input city',
                                                    },
                                                ],
                                            })(<Input disabled={this.state.disabled} />)}
                                        </Form.Item>
                                        <Form.Item
                                            label="Postal Code"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}>
                                            {getFieldDecorator('postalCode', {
                                                initialValue: data && data.postalCode ? data.postalCode : '',
                                                rules: [{ required: true, message: 'Please input postal code', }],
                                            })(<Input disabled={this.state.disabled} />)}
                                        </Form.Item>
                                        <Form.Item className="Sample"
                                            label="State"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('state', {
                                                initialValue: data && data.state ? data.state : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input state',
                                                    },
                                                ],
                                            })(<Input disabled={this.state.disabled} />)}
                                        </Form.Item>
                                        <Form.Item
                                            className="country-picker-selector"
                                            label="Country"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}>
                                            {getFieldDecorator('country', {
                                                initialValue: data && data.country ? data.country : this.state.country,
                                                rules: [{ required: true, message: 'Please input country', }],
                                            })(
                                                // <Input disabled={this.state.disabled} />
                                                <CountryDropdown
                                                    value={this.state.country}
                                                    disabled={this.state.disabled}

                                                    style={{ width: '100%', height: 40, borderRadius: 5, border: '1px solid #d9d9d9' }}
                                                />
                                            )}
                                        </Form.Item>

                                        <FormItem
                                            label="Phone Number"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('PhoneNumber', {
                                                initialValue: data ? data.phoneNumber ? data.phoneNumber : '' : '',
                                                rules: [{ required: true, message: 'Please input Phone Number!' }],
                                            })(
                                                // <Input
                                                //     disabled={this.state.disabled} />
                                                <PhoneInput
                                                    country={'in'}
                                                    enableAreaCodes={true}
                                                    enableSearch={true}
                                                    autoFormat={false}
                                                    inputStyle={{ width: '100%' }}
                                                    disabled={this.state.disabled}
                                                />
                                            )}
                                        </FormItem>

                                        <FormItem
                                            className="business-view description-view"

                                            label="Description"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('Description', {
                                                initialValue: data ? data.description ? data.description : '' : '',
                                                rules: [{ required: false, message: 'Please input Description!' }],
                                            })(
                                                <TextArea rows={3}
                                                    disabled={this.state.disabled} />
                                            )}
                                        </FormItem>

                                        <FormItem
                                            className="business-view description-view"
                                            label="Contact User"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('ContactUser', {
                                                initialValue: this.state.contactUserName,
                                                rules: [{ required: false, message: 'Please input Contact User!' }],
                                            })(
                                                <Input
                                                    disabled={this.state.disabled} />
                                            )}
                                        </FormItem>
                                    </Form>
                                </Spin>
                            </div>
                        }
                        {this.props.selectedTab === 'Team' &&
                            <div className="team-view">
                                <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>Team Settings:</p>
                                <p style={{ fontSize: 11, marginBottom: 30, fontWeight: 350 }}>You can add team members and admins by clicking on the add user icon above. Listing Members can access specific sections assigned to them by the admin. Listing admins can access all sections and add and remove team members.</p>
                                <div className="team-members-header" style={{ display: 'flex', marginBottom: '1%' }}>
                                    <span className="team-header">Team Members</span>
                                    <div style={{ marginBottom: '4%', marginLeft: 10 }}>
                                        {/* <Icon id="add-user-icon" type="user-add" style={{ fontSize: 24, cursor: 'pointer' }}
                                            
                                        /> */}
                                        <div style={{ backgroundColor: '#3396fa', color: '#fff', fontSize: 12, borderRadius: 3, padding: 6, marginLeft: 10, cursor: 'pointer' }} onClick={() => this.setState({ addUsers: true })}> Add User </div>
                                    </div>
                                </div>

                                <div>

                                    {this.state.listingTeamMembers && this.state.listingTeamMembers.length > 0 ?
                                        <List
                                            listData={this.state.listingTeamMembers}
                                            screenName="listingDetail"
                                            removeMember={this.removeMember.bind(this)}
                                            pageSelected={this.state.pageValue}
                                            selectedPage={this.setStateSelectedPage}
                                            totalCount={this.state.listingTeamMembers.length}
                                        />
                                        :
                                        <span style={{ fontSize: 11, fontWeight: 350 }}>No members available</span>
                                    }
                                    <Modal
                                        title="Add Member"
                                        visible={this.state.addUsers}
                                        closable={true}
                                        onCancel={() => this.setState({ addUsers: false })}
                                        onOk={() => this.addMember()}
                                    >
                                        <Spin spinning={this.state.submittingTeam}>
                                            <Form
                                            // onSubmit={this.handleSubmit}
                                            >
                                                <FormItem
                                                    label="First Name"
                                                    labelCol={{ xs: 24, sm: 7 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('FirstName', {
                                                        initialValue: this.state.firstName,
                                                        rules: [{ required: true, message: 'Please input First Name!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>
                                                <FormItem
                                                    label="Last Name"
                                                    labelCol={{ xs: 24, sm: 7 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('LastName', {
                                                        initialValue: this.state.lastName,
                                                        rules: [{ required: true, message: 'Please input Last Name!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>

                                                {/* <FormItem
                                                        label="Phone Number"
                                                        labelCol={{ xs: 24, sm: 7 }}
                                                        wrapperCol={{ xs: 24, sm: 12 }}
                                                    >
                                                        {getFieldDecorator('PhoneNumber', {
                                                            initialValue: this.state.phoneNumber,
                                                            rules: [{ required: true, message: 'Please input Phone Number!' }],
                                                        })(
                                                            <Input />
                                                        )}
                                                    </FormItem> */}
                                                <FormItem
                                                    label="Phone Number"
                                                    labelCol={{ xs: 24, sm: 7 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('PhoneNumber', {
                                                        initialValue: this.state.phoneNumber,
                                                        rules: [{ required: true, message: 'Please input Phone Number!' }],
                                                    })(
                                                        // <div className="mobile-input-containar">
                                                        <PhoneInput
                                                            country={'in'}
                                                            enableAreaCodes={true}
                                                            enableSearch={true}
                                                            autoFormat={false}
                                                            inputStyle={{ width: '100%' }}
                                                            onChange={(val) =>
                                                                this.setState({ phoneValue: val })
                                                            }
                                                        />
                                                        // </div>
                                                    )}
                                                </FormItem>


                                                <FormItem
                                                    label="Email"
                                                    labelCol={{ xs: 24, sm: 7 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('Email', {
                                                        initialValue: this.state.email,
                                                        rules: [{ required: false, message: 'Please input Email!' }],
                                                    })(
                                                        <Input />
                                                    )}
                                                </FormItem>

                                                <FormItem
                                                    label="Role"
                                                    labelCol={{ xs: 24, sm: 7 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('Role', {
                                                        initialValue: this.state.role,
                                                        rules: [{ required: true, message: 'Please assign Role!' }],
                                                    })(
                                                        <Select onChange={(e) => this.onRoleChange(e)}>
                                                            <Option value="listingAdmin">listingAdmin</Option>
                                                            <Option value="listingMember">listingMember</Option>
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Form>
                                        </Spin>
                                    </Modal>
                                </div>

                            </div>
                        }
                        {this.props.selectedTab == 'Communication' && this.state.isEditCommunication === false &&
                            <div id="communication-table" style={{ paddingLeft: 25, paddingRight: 25 }}>
                                <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 10 }}>SMS and Email Settings:</p>
                                <p style={{ fontSize: 12, }}>You can add, delete and modify settings for Email and SMS including adding your own API keys</p>
                                {
                                    communicationData && communicationData.length > 0 &&
                                    <div style={{
                                        marginBottom: 15, display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Icon
                                            id="icon-view"
                                            type={"edit"}

                                            onClick={() => this.onEditCommunication()}
                                        />
                                    </div>
                                }

                                {communicationData && communicationData.length > 0 ?
                                    communicationData.map((res, index) => {
                                        if (res && res.hasOwnProperty('email')) {
                                            let data = _.filter(communicationData[index].email, function (o) {
                                                return ((o.type !== "ApiPromotional") &&
                                                    (o.type !== "ApiTransactional"));
                                            });
                                            return (
                                                <Table dataSource={data}
                                                    // style={{ paddingTop: '5%', marginTop: '5%' }}
                                                    columns={emailColumns}
                                                    pagination={false}
                                                />
                                            )
                                        } else if (res && res.hasOwnProperty('sms')) {
                                            let data = _.filter(communicationData[index].sms, function (o) {
                                                return ((o.type !== "ApiPromotional") &&
                                                    (o.type !== "ApiTransactional"));
                                            });
                                            return (
                                                <Table
                                                    // style={{ paddingTop: '5%', marginTop: '5%' }}
                                                    dataSource={data}
                                                    pagination={false}
                                                    columns={smsColumns} />
                                            )
                                        }
                                    })
                                    :
                                    // <span>No data</span>
                                    <AddCommunicationProvider
                                        onBackToCommunicationList={this.onBackToCommunictionList}
                                        listingInfo={this.state.listingData}
                                    />
                                }
                            </div>
                        }
                        {this.props.selectedTab == 'Transaction Types' &&
                            <AddTransaction />
                        }
                        {
                            this.state.isEditCommunication === true &&
                            <AddCommunicationProvider
                                onBackToCommunicationList={this.onBackToCommunicationList}
                                listingInfo={this.state.listingData}
                            />
                        }
                        {this.props.selectedTab === 'Catalog' &&
                            <div>
                                <p style={{ fontSize: 14, marginLeft: 5, fontWeight: 500, marginBottom: 10, paddingLeft: 25 }}>Catalog Settings:</p>
                                <p style={{ fontSize: 12, marginLeft: 5, paddingLeft: 25 }}>Coming soon - You can add your business product and service catalogs here</p>
                            </div>
                        }
                    </div>
                    {/* </CustomScrollbars> */}
                </div>
            </div>
        )
    }
}

const WrappedApp = Form.create()(Listings);
export default withRouter(WrappedApp);