import React from "react";
import { Avatar, Badge, Col, Row, Popconfirm, message, Tag} from "antd";
import CustomScrollbars from "util/CustomScrollbars";
import moment from 'moment'
import Recipients from './Recipients'
import { DELETE_EMAIL_MUTATION } from '../../graphQl/mutations';
import client from '../../apollo/apollo';

class ListItemDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedItem: {},
            createdDate: '',
            listTitle: '',
            listDescription: '',
            listType: '',
            createdDate: '',
            senderId: '',
            listingId: ''
        }
    }

    componentDidMount() {
        if (this.props && this.props.todo) {
            console.log('this.props123', this.props)
            let value = this.props.todo
            let title = value.title ? value.title : ''
            let description = value.content ? value.content : ''
            let id = value.id ? value.id : ''
            let date = value.createdAt ? moment(value.createdAt).format('MMM DD YYYY, hh:mm A') : ''
            let type = value.selectedCommunicationOption &&
                value.selectedCommunicationOption.TYPE
                ? value.selectedCommunicationOption.TYPE : ''
            let senderId = value.selectedCommunicationOption &&
                value.selectedCommunicationOption.SENDERID
                ? value.selectedCommunicationOption.SENDERID : ''
            this.setState({
                selectedItem: this.props.todo, listTitle: title,
                listDescription: description, listType: type, createdDate: date, senderId: senderId,
                listingId: id
            }, () => {
            })
        }
    }

    onDeleteMessage = () => {

        if (this.state.listingId) {
            let variables = { id: this.state.listingId }
            console.log('variables123', variables)
            const response = client.mutate({ mutation: DELETE_EMAIL_MUTATION, variables: variables }).then((res) => {
                message.success("Message Deleted Successfully")
                if (this.props.onDeleteTransaction) {
                    this.props.onDeleteTransaction()
                }

            }).catch(err => {
                message.error(err.message)
            })
        }
    }

    render() {
const {todo} = this.props;
        return (
            <div className="gx-module-detail gx-module-list" >
                <CustomScrollbars className="gx-todo-detail-content-scroll" id="info-sms-view" >
                    <div className="gx-module-detail-item gx-module-detail-header">
                        <Row>

                            <Col xs={24} sm={12} md={17} lg={12} xl={17}>
                                <div className="gx-flex-row">
                                    <div className="gx-user-name gx-mr-md-2 gx-mr-2 gx-my-1"
                                        onClick={this.handleUserClick}>

                                        <div className="gx-flex-row gx-align-items-center gx-pointer">
                                            <Avatar className="gx-mr-2" src="https://via.placeholder.com/150x150" alt="https://via.placeholder.com/150x150" />
                                            <h4 className="gx-mb-0">{this.state.listTitle}</h4>
                                        </div>

                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} sm={12} md={7} lg={12} xl={7}>
                                <div className="gx-flex-row gx-justify-content-center">

                                    <Popconfirm
                                        placement="top"
                                        title="Are you sure delete this message?"
                                        onConfirm={() => this.onDeleteMessage()}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <i className="gx-icon-btn icon icon-trash" style={{ width: 25 }} />
                                    </Popconfirm>

                                    <i className="gx-icon-btn icon icon-star-o" style={{ width: 25 }} />
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span className="gx-d-block" style={{ marginLeft: 8, color: "#038fde" }}>
                                            {this.state.createdDate}
                                        </span>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>

                    <div className="gx-module-detail-item">
                        <div style={{display:'flex'}}>
                        <div className="gx-md-4 gx-mb-2">
                            <Badge count={this.state.listType} />
                        </div>
                        <div className="gx-flex-row">
                            {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                                <div style={{ paddingLeft: '10px'}}>
                                    {todo.tags.tagValues.map((data, index) => {
                                        return (
                                            <Tag color="green"> {data}</Tag>
                                        );
                                    })}
                                </div>
                            }
                        </div>
                      </div>
                        <div className="gx-form-group gx-flex-row gx-align-items-center gx-mb-0 gx-flex-nowrap">

                            <div className="gx-flex-row gx-align-items-center gx-justify-content-between gx-flex-1 gx-flex-nowrap">
                                <div className="gx-task-des gx-col" id="sms-tag-desc">
                                    <p className="chatText" dangerouslySetInnerHTML={{ __html: this.state.listDescription }} />
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Recipients table */}
                    <Recipients selectedListingId={this.state.listingId}
                        screenName="notifyDetail"
                    />
                </CustomScrollbars>

            </div>
        );
    }
}

export default ListItemDetail;
