import React, { Component } from "react";
import { Button, Checkbox, Drawer, Dropdown, Menu, message, Icon, Form, Tooltip, Popconfirm } from "antd";
import { withRouter } from 'react-router-dom'
import CustomScrollbars from "util/CustomScrollbars";
import toDos from "./data/todo";
import filters from "./data/filters";
import options from "./data/options";
import todoConversation from "./data/todoConversation";
import ListScreen from "components/List/List";
import ListItemDetail from "components/Notify/ListItemDetail";
import ListDetail from "components/List/ListDetail";
import CreateTemplate from "components/CreateTemplate/index";
import Listing from '../Listings'
import CircularProgress from "../../components/CircularProgress/index";
import Auxiliary from "../../util/Auxiliary";
import TagModal from "./TagModal"
import NewBroadCast from '../../components/Notify/NewBroadCast'
import ContactDetail from '../../components/contact/contactDetail'
import BusinessContactDetail from '../../components/contact/businessContactDetail'
import MsgTemplateDetails from '../../components/MsgTemplate/msgTemplateDetail'
import ReceivedContract from '../../components/receivedDetails/receivedContract'
import { GET_TEMPLATE_STATUS, GET_STATUS_COUNT, GET_COUNT_STATUS } from '../../graphQl/subscriptions';
import { TRANSACTION_DATA_BY_SHORT_URL_QUERY, GET_PRODUCT_INVENTORY } from '../../graphQl/queries';
import client from '../../apollo/apollo';
import { CSVLink, CSVDownload } from "react-csv";
import * as _ from "lodash";
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { getYearMonthDate } from '../../util/DateFormat'

import { fetch_tag_history } from '../../util/TagLogs'

import FilterIcon from './FilterIcon'
import SavedFilterList from './SavedFilterList'


import { TRANSACTION_SUB, TRANSACTION_LIST_WITH_SUBS, GET_SHORT_URL_BY_TRANSACTION_ID } from 'graphQl/subscriptions';
import gql from 'graphql-tag';

const ITEM_HEIGHT = 34;

const contactFilters = ['VIP Customers', 'Recent', 'Date', 'Favourite']
const contractFilters = ['Loyalty contract sent', 'Signed', 'Date', 'Favourite', 'Drafts']
const smsFilters = ['SMS', 'Delivered', 'Favorite', 'Drafts']
const emailFilters = ['Email', 'Delivered', 'Favorite', 'Drafts']

const { SubMenu } = Menu;

class List extends Component {

  constructor() {
    super();
    this.state = {
      searchTodo: '',
      alertMessage: '',
      loader: false,
      showMessage: false,
      drawerState: false,
      optionName: 'Actions',
      anchorEl: null,
      allToDos: toDos,
      currentTodo: null,
      user: {
        name: 'Robert Johnson',
        email: 'robert.johnson@example.com',
        avatar: 'https://via.placeholder.com/150x150'
      },
      selectedToDos: 0,
      statusCount: 0,
      limitValue: 50,
      labelMenuState: false,
      optionMenuState: false,
      toDos: toDos,
      filter: -1,
      todoConversation,
      conversation: null,
      showNewSMSView: false,
      selectedTab: 'Profile',
      selectList: false,
      selectAdd: false,
      selectNewContact: false,
      selectSettings: false,
      selectBusinessContact: false,
      selectMessageTemplate: false,
      saveTemplate: false,
      listData: [],
      allSelected: false,
      saveIndividual: false,
      editIndividual: false,
      templateStatusList: [],
      visibleSave: true,
      actionsConfirmMsg: "",
      selectedPageIndex: 1,
      listDataCount: 0,
      editFilterItem: {},
      isOpenFilterModal: false,
      tagView: false,
      FilterItemIndex: '',
      hoveredTagItem: null,
      tagEditedItem: {},
      recipientId: "",
      recipientType: "",
      selectedStatusFilter: '',
      templateFilterValues: [],
      selectTemplateIndex: null,
      assignMeColor: false,
      isCallFilterList: false,
      templateId: "",
      ProdInventoryList: []
    }
  }

  componentDidMount() {
    this.setState({
      FilterItemIndex: this.props.selectedFilterItemIndex,
      selectedStatusFilter: this.props.selectedFilterName,
      recipientId: this.props.recipientUserId,
      recipientType: this.props.receivedType
    }, () => {

    })
    if (this.props && this.props.messageTemplateId) {
      if (this.props.messageTemplateId.id === 'newMessageTemplate') {
        this.setState({ selectMessageTemplate: true, currentTodo: 'New Message Template' }, () => {
        })
      } else {
        this.setState({
          currentTodo: this.props.messageTemplateId
        })
      }
    }
    if (this.props) {
      this.setState({ listData: this.props.listData })
    }
    if (this.props) {
      this.setState({
        templateId: this.props && this.props.templateData && this.props.templateData.id ?
          this.props.templateData.id : ""
      }, () => {
        this.fetchStatus()
      })
    }
    this.fetchStatus()
    if (this.props.totalCount) {
      this.setState({ listDataCount: this.props.totalCount })
    }
    this.getTagsList()
    this.getAllStatusCout()
  }

  componentWillMount() {
    if (this.props.selectedFilterName !== 'reset') {
      this.setState({ selectedFilter: this.props.selectedFilterName })
    }
    if (this.props.screenName === 'listingScreen') {
      if (this.props.navigatedAfterCreate) {
        this.setState({ selectedTab: 'Team' });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.templateFilters !== this.props.templateFilters) {
      this.setState({ templateFilterValues: newProps.templateFilters })
    }

    if (this.props.listData !== newProps.listData) {
      this.setState({ listData: newProps.listData, toDos: newProps.listData })
    }
    if (newProps && newProps.checkSelect && newProps.checkSelect === true) {
      this.setState({
        allSelected: false
      })
    }
    if (newProps.templateData !== this.props.templateData) {
      this.setState({
        templateId: newProps && newProps.templateData && newProps.templateData.id ?
          newProps.templateData.id : ""
      }, () => {
        this.fetchStatus()
      })

    }

    if (newProps.totalCount !== this.props.totalCount) {
      this.setState({ listDataCount: newProps.totalCount }, () => {
        this.fetchStatus(true)
      })
    }
    if (this.props.selectedFilterItemIndex !== newProps.selectedFilterItemIndex) {
      this.setState({
        FilterItemIndex: newProps.selectedFilterItemIndex,
        selectedStatusFilter: ""
      })
    }
    if (this.props.selectedFilterName !== newProps.selectedFilterName) {
      this.setState({
        selectedStatusFilter: newProps.selectedFilterName,
        FilterItemIndex: ""
      })
    }
    if (this.props.recipientUserId !== newProps.recipientUserId) {
      this.setState({ recipientId: newProps.recipientUserId }, () => {
        this.getTagsList()
      })
    }
    if (this.props.receivedType !== newProps.receivedType) {
      this.setState({ recipientType: newProps.receivedType }, () => {
        this.getTagsList()
      })
    }
  }

  getAllStatusCout = (key) => {
    if (this.props && this.props.templateId) {
      const pData = {
        p_data: {
          listingId: localStorage.getItem('selectedListingId'),
          templateId: this.props.templateId,
          status: "ACTIVE"
          // eligibleStatusList: tagString
        }
      }
      client
        .subscribe({ query: GET_STATUS_COUNT, variables: pData })
        .subscribe(
          res => {
            this.setState({ statusCount: res.data.get_transaction_filter_by_params_aggregate.aggregate.count }, () => {
              if (this.props.refreshList && !key) {
                this.props.refreshList()
              }
            })
          }, err => {
          }

        )
    }
  }

  getTagsList = () => {
    const { recipientType, recipientId } = this.state
    let contactId = (recipientType && recipientType === "personal") ? recipientId : null
    let businessId = (recipientType && recipientType === "business") ? recipientId : null
    let listingId = recipientType ? localStorage.getItem('receivedListingId') : localStorage.getItem('selectedListingId')

    fetch_tag_history(listingId, this.props.filterScreenName,
      this.props.location.state && this.props.location.state.templatedata && this.props.location.state.templatedata.id, contactId, businessId).then(async (res) => {
        this.setState({ tagHistory: res });
      })
      .catch((err) => {
      })
  }

  getTransactionData = (selectedStatus) => {
    if (this.props.onFilterDataList) {
      this.props.onFilterDataList()
    }

    this.setState({ loader: true, FilterItemIndex: 'All' }, () => {
      let gqltag = TRANSACTION_LIST_WITH_SUBS({
        startNumber: 0,
        limit: this.state.limitValue,
        transactionType: localStorage.getItem('transactionType'),
        role: localStorage.getItem('role'),
        listingId: localStorage.getItem('selectedListingId'),
        userId: localStorage.getItem('uid'),
        transactionTemplateId: this.props.location.state ? this.props.location.state.templatedata.id : '',
        status: selectedStatus,
        tags: this.state.selectedTag,
        transactionActiveStatus: "ACTIVE"
      });

      const gqlValue = gqltag
      const query = gql`
          ${gqlValue}
        `
      client
        .subscribe({
          query,
        })
        .subscribe(
          res => {
            if (res && res.data) {
              this.setState({ listData: res.data.transaction, loader: false })
            }
          },
          err => {
            this.setState({ loader: false })
          }
        )
    })

  }

  ResetFilterData = () => {
    if (this.props.onFilterDataList) {
      this.props.onFilterDataList()
    }
  }

  //fetch template status
  fetchStatus = (option) => {

    if (this.state.templateId) {
      // if (propsValue && propsValue.templateData && propsValue.templateData.id) {
      const tempVariable = {
        transactionTemplateId: this.state.templateId,
        // status:"ACTIVE"
      }
      try {
        client
          .subscribe({ query: GET_COUNT_STATUS, variables: tempVariable })
          .subscribe(
            res => {
              if (res && res.data && res.data.list_transaction_template_status && res.data.list_transaction_template_status.length > 0) {
                // this.allocateInputs(res.data.transaction_template_status)
                this.setState({ templateStatusList: res.data.list_transaction_template_status }, () => {
                  this.getAllStatusCout(option);
                  if (this.state.templateStatusList && this.state.templateStatusList.length > 0) {
                    var tagString = '{';
                    var length = this.state.templateStatusList.length;
                    this.state.templateStatusList.map((val, i) => {
                      tagString = tagString + val.statusName;
                      if (i + 1 === length) {
                        tagString = tagString + '}'
                      } else {
                        tagString = tagString + ','
                      };
                    });
                  }
                })
              }
            }, err => {
            })
      } catch (e) {
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      toDos: (this.state.toDos, oldIndex, newIndex),
    });
  };


  onAllTodoSelect() {
    this.setState({ allSelected: this.state.allSelected ? !this.state.allSelected : true }, () => {
      if (this.state.allSelected) {
        this.getAllTodo();
      } else {
        this.getUnselectedAllTodo();
      }
    });
  }

  getAllTodo = () => {
    let toDos = this.state.listData.map((todo) => todo ? {
      ...todo,
      selected: true
    } : todo);
    this.setState({
      selectedToDos: toDos.length,
      allToDos: toDos,
      // optionName: 'All',
      toDos: toDos,
      selectedList: toDos,
      listData: toDos
    }, () => {
    });
  };

  getUnselectedAllTodo = () => {
    let toDos = this.state.listData.map((todo) => todo ? {
      ...todo,
      selected: false
    } : todo);
    this.setState({
      selectedToDos: 0,
      allToDos: toDos,
      // optionName: 'None',
      toDos: toDos,
      selectedList: toDos,
      listData: toDos
    });
  };

  getStarredToDo = () => {
    let selectedToDos = 0;
    let toDos = this.state.allToDos.map((todo) => {
      if (todo.starred) {
        selectedToDos++;
        return { ...todo, selected: true };
      }
      return { ...todo, selected: false }
    });
    this.setState({
      selectedToDos: selectedToDos,
      allToDos: toDos,
      toDos: toDos.filter(todo => !todo.deleted)
    });
    return toDos;
  };

  getUnStarredTodo = () => {
    let selectedToDos = 0;
    let toDos = this.state.listData.map((todo) => {
      if (!todo.starred) {
        selectedToDos++;
        return { ...todo, selected: true };
      }
      return { ...todo, selected: false }
    });
    this.setState({
      selectedToDos: selectedToDos,
      allToDos: toDos,
      optionName: 'Unstarred',
      toDos: toDos.filter(todo => !todo.deleted)
    });
    return toDos;
  };

  handleRequestClose = () => {
    this.setState({ showMessage: false, addTodo: false, labelMenuState: false, optionMenuState: false, });
  };

  onMarkAsStart = (data) => {
    const toDos = this.state.allToDos.map(todo => {
      if (todo.id === data.id) {
        return data;
      } else {
        return todo;
      }
    });
    this.setState({
      alertMessage: 'ToDo Updated Successfully',
      showMessage: true,
      allToDos: toDos,
      toDos: toDos,
    });
  };

  removeMember(value) {
    this.props.removeMember(value)
  }

  getNavFilters = (filte) => {
    if (this.props.screenName === 'listingScreen') {
      return filte.map((filter, index) =>
        <li key={index} onClick={() => {
          this.setState({
            loader: true,
            selectedTab: filter.title
          });
          setTimeout(() => {
            this.setState({ loader: false });
          }, 1500);
        }
        }>
          <span
            className={filter.title === this.state.selectedTab ? 'gx-link active' : 'gx-link'}
          >
            <Icon id="icon-view" type={filter.icon} />
            <span style={{
              fontSize: 15
            }}>{filter.title}</span>
          </span>
        </li>
      )
    } else {
      return filte.map((filter, index) =>
        <li key={index} onClick={() => {
          const filterMails = this.state.allToDos.filter(todo => {
            if (filter.status === todo.currentStatus) {
              return todo
            }
          });
          this.setState({
            loader: true,
            currentTodo: null,
            filter: filter.title,
            toDos: filterMails,
            selectedFilter: filter.title
          });
          setTimeout(() => {
            this.setState({ loader: false });
          }, 1500);
        }
        }>
          <span className={filter.title === this.state.filter ? 'gx-link active' : 'gx-link'}>
            <i className="icon icon-menu" />
            <span style={{
              fontSize: 15,
            }}>{filter.title}</span>
          </span>
        </li>
      )
    }
  };

  //when click filter refresh icon
  onRefreshFilter = () => {
    this.fetchStatus()
    this.getAllStatusCout()
  }

  ToDoSideBar = () => {
    // this.state.FilterItemIndex = this.props.selectedFilterName;
    return <div className="gx-module-side" style={{ width: '230px' }}>
      <div
        className="gx-module-side-header" id="content-header-view"
      >
        <div
          className="gx-module-logo"
          id="comon-name-view"
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {this.props.fromtrans === 'listings' &&
            <div className="middle-sidebar-name" style={{ width: '170px', display: 'flex', justifyContent: 'center', fontSize: '15px', textAlign: 'center' }}>
              {/* {this.state.listData[0] && this.state.listData[0].name ? this.state.listData[0].name :
                this.props.listingName} */}
              Settings
            </div>
          }
          {this.props.screenName === 'notifyScreen' &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div> {this.props.broadcastTab === 'sms' ? "SMS" : "Email"} </div>
            </div>
          }

          {this.props.screenName === 'received_transaction' &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div> {localStorage.getItem('receivedListingName') ? localStorage.getItem('receivedListingName').toUpperCase() : ''} </div>
            </div>
          }

          {this.props.screenName === 'contactScreen' &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div> Contacts </div>
            </div>
          }

          {this.props.screenName === 'businessContactScreen' &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>Business Contacts </div>
            </div>
          }

          {this.props.screenName === 'messageTemplateScreen' &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div> Message Template </div>
            </div>
          }

          {(this.props.fromtrans === 'Contract' || this.props.fromtrans === 'contract') &&
            <div>{this.props.templateData.name}</div>
          }
          {(this.props.fromtrans === 'Order' || this.props.fromtrans === 'order') &&
            <div>{this.props.templateData.name}</div>
          }

          {this.props.fromtrans === 'Lead' &&
            <div>{this.props.templateData.name}</div>
          }
        </div>

      </div>
      <div className="gx-module-side-content">
        <CustomScrollbars className="gx-module-side-scroll" id="side-scroll-view">
          <div className="gx-module-add-task" style={{ display: 'flex', justifyContent: 'center', paddingRight: '13%' }}>
            {
              this.props.screenName === "transactionScreen" &&
              <Button variant="raised" type="primary" className="gx-btn-block"
                onClick={() => {
                  this.setState({
                    currentTodo: 'New', templateData: this.props.templateData,
                    selectAdd: true, selectList: false, selectSettings: false
                  })
                }}> Add {this.props.fromtrans}</Button>
              //  <div style={{ textAlign: 'center', marginLeft: 20, color: '#038FDE', marginTop: 10 }}>All {this.props.fromtrans}s</div> 
            }
            {this.props.screenName === 'notifyScreen' && !this.props.receivedScreen &&
              <Button variant="raised" type="primary" className="gx-btn-block"
                onClick={() => {
                  this.setState({ showNewSMSView: true })
                }}
              > New {this.props.broadcastTab === 'sms' ? "SMS" : "Email"} </Button>
            }
            {
              this.props.screenName === "contactScreen" &&
              <div>
                <Button variant="raised" type="primary" className="gx-btn-block"
                  onClick={() => {
                    this.setState({ selectNewContact: true, currentTodo: 'New contact' }, () => {
                    })
                  }}> New Contact</Button>
                {/* <div style={{ textAlign: 'center', marginLeft: 20, color: '#038FDE', marginTop: 10 }}>All Contacts</div> */}
              </div>
            }

            {
              this.props.screenName === "businessContactScreen" &&
              <div>
                <Button variant="raised" type="primary" className="gx-btn-block"
                  onClick={() => {
                    this.setState({ selectBusinessContact: true, currentTodo: 'New business contact' }, () => {
                    })
                  }}> New Business Contact</Button>
              </div>
            }

            {
              this.props.screenName === "messageTemplateScreen" &&
              <div>
                <Button variant="raised" type="primary" className="gx-btn-block"
                  onClick={() => {
                    this.setState({ selectMessageTemplate: true, currentTodo: 'New Message Template' }, () => {
                    })
                  }}> New Message Template</Button>
              </div>
            }


          </div>

          <ul className="gx-module-nav" id="listing-view">
            {this.props.screenName === 'transactionScreen' &&
              <li className="gx-module-nav-label">
                <span
                  style={{ color: this.state.selectSettings ? '#038fde' : '#000000', cursor: 'pointer' }}
                  onClick={() => {
                    this.setState({
                      currentTodo: 'New',
                      selectSettings: true,
                      selectAdd: false,
                      selectList: false,
                    })
                  }}

                ><Icon type="setting" />Settings</span>
              </li>
            }

            <li onClick={() => {
              this.setState({
                currentTodo: null,
                toDos: this.state.allToDos
              });
            }
            }>

            </li>
            {this.state.templateFilterValues && this.state.templateFilterValues.length > 0 &&
              <div>
                <li>
                  <span className="gx-link" style={{ color: '#000000', cursor: 'text' }}> <Icon type="file" />Templates</span>
                </li>
                {this.state.templateFilterValues.map((val, index) => (
                  <li
                    onClick={() => {
                      this.setState({ selectTemplateIndex: index })
                      this.props.callTemplateFilter(val)
                    }}
                  >
                    <span
                      className="gx-link"
                      style={{ cursor: 'pointer', color: this.state.selectTemplateIndex === index ? '#038fde' : '#000000' }}
                    >
                      {val.name}
                    </span>
                  </li>

                ))
                }
              </div>
            }




            <li>
              {/* {(this.props.fromtrans === 'Contract' || this.props.fromtrans === 'contract') && */}
              {(this.props.screenName !== 'messageTemplateScreen' &&
                this.props.receivedViewType !== 'order' &&
                this.props.screenName !== "listingScreen" && !this.props.receivedScreen) &&

                <div>
                  <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span className="gx-link" style={{ color: '#000000', cursor: 'text' }}> <Icon type="filter" />Filters</span>
                    {/* {this.state.templateId &&
                      <Icon onClick={() => this.onRefreshFilter()} type="reload" style={{
                        cursor: "pointer",
                        marginTop: '6%', fontSize: 17
                      }} />
                    } */}

                  </li>
                  <li className="gx-module-nav-label">

                    <span
                      style={{ cursor: 'pointer', color: (this.state.FilterItemIndex === 'All' || this.state.FilterItemIndex === 'reset') ? '#038fde' : '#000000' }}
                      // style={{ cursor: 'pointer', color: ((this.state.selectedFilter === 'reset' || !this.state.selectedFilter) && !this.state.selectSettings) ? '#038fde' : '#000000' }}
                      onClick={() => {
                        this.setState({
                          loader: true,
                          currentTodo: null,
                          selectedFilter: '',
                          selectSettings: false,
                          FilterItemIndex: 'All',
                          limitValue: 50,
                          selectTemplateIndex: null,
                          assignMeColor: false
                          // listData: this.props.listData
                        }, () => {
                          this.ResetFilterData()
                          this.props.onFilterDataList({ status: true, selectedStatus: 'All' })
                        });
                        setTimeout(() => {
                          this.setState({ loader: false });
                        }, 1500);
                      }
                      }>
                      <Icon type="unordered-list" />
                      All {this.props.screenName === 'transactionScreen' && `(${this.state.statusCount})`}
                    </span>
                  </li>
                </div>
              }
              {/* } */}

              {this.props.screenName === 'transactionScreen' &&
                <li>
                  <span
                    onClick={() => {
                      this.setState({
                        assignMeColor: true,
                        selectedStatusFilter: ''
                      }, () => {
                        this.props.assignedMe()
                      })
                    }}
                    className="gx-link" style={{ color: this.state.assignMeColor ? '#038fde' : '#000000', cursor: 'pointer' }}
                  >
                    <Icon type="user" />
                    Assigned to me
                </span>
                </li>
              }

              {this.state.templateStatusList.length > 0 &&
                <div>
                  {this.state.templateStatusList.map((val, index) => (
                    // <span style={{ marginLeft: 20, color: '#9B9B9B', marginTop: 5 }}></span>
                    <li>
                      <span
                        className="gx-link"
                        style={{ color: (this.state.selectedStatusFilter === val.statusName && !this.state.selectSettings) ? '#038fde' : '', cursor: 'pointer' }}
                        onClick={() => {
                          const filterList = this.props.listData.filter(todo => {
                            if (val.statusName === todo.currentStatus) {
                              return todo
                            }
                          });
                          this.setState({
                            loader: true,
                            currentTodo: null,
                            selectedFilter: val.statusName,
                            selectSettings: false,
                            selectedPageIndex: 1,
                            limitValue: 50,
                            assignMeColor: false
                            // FilterItemIndex: null
                            // FilterItemIndex: val.statusName
                            // listData: filterList
                          }, () => {
                            // this.getTransactionData(this.state.selectedFilter)
                            this.props.onFilterDataList({ status: true, selectedStatus: val.statusName })
                          });
                          setTimeout(() => {
                            this.setState({ loader: false });
                          }, 1500);
                        }
                        }>
                        <Icon type="book" />
                        {val.statusName} ({val.total_count})
                      </span>

                    </li>

                  ))}
                </div>
              }

              {(this.props.screenName !== 'messageTemplateScreen' &&
                this.props.receivedViewType !== 'order' && !this.props.receivedScreen &&
                this.props.screenName !== "listingScreen") &&
                <SavedFilterList
                  screenName={this.props.filterScreenName}
                  templateId={this.props.location.state ? this.props.location.state.templatedata.id : ''}
                  option="middle_bar"
                  filteredData={this.props.selectedFilterData}
                  onSelectFilterItem={() => this.onSelectFilterItem}
                  onFilterData={this.onFilterData}
                  onEditSavedFilter={this.onEditSavedFilter}
                  selectedFilterItemIndex={this.state.FilterItemIndex}
                  recipientId={this.state.recipientId}
                  receivedUserType={this.state.recipientType}
                  isCallFilterList={this.state.isCallFilterList}
                />
              }
            </li>

            {this.props.fromtrans === 'listings' &&
              this.getNavFilters(filters)
            }

            {/* Tags UI */}
            {(this.props.screenName !== 'messageTemplateScreen' &&
              this.props.screenName !== "listingScreen") &&
              <div>
                {this.state.tagHistory &&
                  <li>
                    <span className="gx-link" style={{ color: '#000000', cursor: 'text' }}> <Icon type="filter" />Tags</span>
                  </li>
                }
                {this.state.tagHistory && this.state.tagHistory.map((val, i) => (
                  <li>
                    <span
                      className="gx-link"
                      style={{ color: (this.props.selectedFilterName === val.tagName && !this.state.selectSettings) ? '#038fde' : '', cursor: 'pointer' }}
                      onMouseEnter={() => this.onEnterMouseInTagList(i)}
                      onMouseLeave={() => this.setState({ hoveredTagItem: null })}
                      onClick={() => {
                        this.setState({
                          loader: true,
                          currentTodo: null,
                          selectedTag: val.tagName,
                          selectSettings: false,
                          selectedPageIndex: 1,
                          limitValue: 50,
                          FilterItemIndex: val.tagName
                          // listData: filterList
                        }, () => {
                          var filterData = {};

                          if (val.tagData && val.tagData.length > 0) {
                            var tagString = '{';
                            var length = val.tagData.length;
                            val.tagData.map((val, i) => {
                              tagString = tagString + val.text;
                              if (i + 1 === length) {
                                tagString = tagString + '}'
                              } else {
                                tagString = tagString + ','
                              };
                            });
                            filterData.tags = tagString;

                          }
                          filterData.isTag = true;
                          filterData.tagName = this.state.selectedTag;
                          this.props.onFilterDataList(filterData)
                        });
                        setTimeout(() => {
                          this.setState({ loader: false });
                        }, 1500);
                      }
                      }
                    >
                      <Icon type="book" />
                      {val.tagName} {val.total_count}
                      {
                        this.state.hoveredTagItem !== null && i === this.state.hoveredTagItem &&
                        <Icon type="edit"
                          style={{ marginLeft: 20 }}
                          onClick={() => this.onEditTagItem(val)} />
                      }
                    </span>
                  </li>
                ))}
              </div>
            }



          </ul>
        </CustomScrollbars>
      </div>
    </div>
  };

  onEnterMouseInTagList = (index) => {
    this.setState({ hoveredTagItem: index })
  }
  onEditTagItem = (item) => {
    this.setState({ tagEditedItem: item, tagView: true })
  }

  onEditSavedFilter = (item) => {
    this.setState({ editFilterItem: item, isCallFilterList: false })
  }

  callNext(values) {
    if (this.props.screenName === 'transactionScreen') {
      this.setState({ limitValue: values }, () => {
        if (Object.keys(this.props.selectedFilterData).length > 0) {
          if (this.props.onFilterDataList) {
            this.props.onFilterDataList(this.props.selectedFilterDataFormat, this.props.selectedFilterData,
              this.state.FilterItemIndex, values)
          }
        } else {
          this.getTransactionData(this.state.selectedFilter)
        }
      })
    } else {
      this.props.callNext(values)
    }
  }

  onChangePageIndex = (index) => {
    // this.setState({ selectedPageIndex: index }, () => {
    // })
    this.props.selectedPage(index)
  }

  showToDos = ({ currentTodo, toDos, conversation, user }) => {
    return currentTodo === null ?
      <div>
        {this.props.loadingValue &&
          <div className="loader-view"
            style={{ height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}
          >
            <CircularProgress />
          </div>
        }
        {!this.props.loadingValue &&
          <ListScreen toDos={this.state.listData} onSortEnd={this.onSortEnd}
            onMarkAsStart={this.onMarkAsStart.bind(this)}
            onTodoSelect={this.onTodoSelect.bind(this)}
            onTodoChecked={this.onTodoChecked.bind(this)}
            removeMember={this.removeMember.bind(this)}
            useDragHandle={true}
            screenFrom={this.props.screenName}
            callListNext={this.callNext.bind(this)}
            pageIndex={this.props.pageSelected}
            onChangePageIndex={this.onChangePageIndex}
            totalDataCount={this.state.listDataCount}

          />
        }
      </div>
      :
      <div>
        {this.props.screenName === 'notifyScreen' &&
          <ListItemDetail
            todo={currentTodo}
            onDeleteTransaction={this.onDeleteTransaction}
          />}

        {this.props.screenName === 'contactScreen' && this.state.selectNewContact === false &&
          <ContactDetail
            todo={currentTodo}
            type={'View'}
            gobackCall={this.onClickBack}
          // saveContact={this.state.saveIndividual}
          // editContact={this.state.editIndividual}
          />
        }

        {this.props.screenName === 'received_transaction' &&
          <ReceivedContract
            todo={currentTodo}
          />
        }

        {this.state.selectNewContact === true &&
          <ContactDetail
            type={'New'}
            gobackCall={this.onClickBack}
          // saveContact={this.state.saveIndividual}
          />
        }

        {this.state.selectBusinessContact === true &&
          <BusinessContactDetail type={'New'}
            gobackCall={this.onClickBack}
          />
        }

        {this.state.selectBusinessContact === false && this.props.screenName === 'businessContactScreen' &&
          <BusinessContactDetail
            type={'View'}
            todo={currentTodo}
            gobackCall={this.onClickBack}
          />
        }

        {this.props.screenName === 'messageTemplateScreen' && this.state.selectMessageTemplate === true &&
          <MsgTemplateDetails
            type={'New'}
            gobackCall={this.onClickBack}
            isCallSubmit={this.state.saveTemplate}
            reAssign={() => this.setState({ saveTemplate: false }, () => {
            })}
          />
        }

        {this.props.screenName === 'messageTemplateScreen' && this.state.selectMessageTemplate === false &&
          <MsgTemplateDetails
            todo={currentTodo}
            type={'View'}
            gobackCall={this.onClickBack}
          />
        }

        {this.props.screenName === 'transactionScreen' &&
          <div>
            <div>
              {this.state.selectAdd === true &&
                <CreateTemplate
                  screenName="TransactionList"
                  listBack={this.onClickBack}
                  templateName={this.props.templateData.name}
                  from={this.props.from}
                />
              }
              {this.state.selectSettings === true &&
                <CreateTemplate
                  screenName="TemplateEdit"
                  editData={this.props}
                  listBack={this.onClickBack}
                  from={this.props.from}
                />
              }
            </div>
            <div>
              {this.state.selectList === true &&
                <ListDetail todo={currentTodo} user={user}
                  templateData={this.props.templateData}
                  conversation={conversation}
                  onDeleteTransaction={this.onDeleteTransaction}
                />
              }
            </div>
          </div>
        }
        {this.props.screenName === 'listingDetail' &&
          <ContactDetail
            todo={currentTodo.userInfo}
            type={'View'}
            gobackCall={this.onClickBack}
            fromlistingMember={true}
          />
        }
      </div>
  };

  onDeleteTransaction = () => {
    this.setState({ currentTodo: null })
    this.getTransactionData(this.state.selectedFilter)
  }

  optionMenu = () => {
    let templateSignedStatus = ((this.props && this.props.templateData &&
      this.props.templateData.signedStatus) ?
      this.props.templateData.signedStatus : '')
    return (
      <div>
        {this.props.screenName === "transactionScreen" &&
          <Menu id="option-menu" onClick={this.onOptionMenuItemSelect.bind(this)}
            onClose={this.handleRequestClose}
            style={{ maxHeight: ITEM_HEIGHT * 5.5 }}>
            {options.map(option => {
              if (option) {


                if (templateSignedStatus === this.state.selectedFilter) {
                  if (option.showForSignedStatus === true) {
                    //for status change submenu status
                    if (option.title === "Status Change") {
                      if (this.state.templateStatusList.length > 0) {
                        let data = this.state.templateStatusList
                        return (
                          <SubMenu title={option.title}>
                            {
                              data.map((res) => {
                                //dont push signed status in dropdown
                                if (templateSignedStatus) {
                                  if (templateSignedStatus !== res.statusName) {
                                    return <Menu.Item
                                      style={{ textTransform: 'capitalize' }}
                                      message={option.confirmMsg}
                                      key={res.statusName}>{res.statusName}</Menu.Item>
                                  }
                                } else {
                                  return <Menu.Item key={res.statusName} message={option.confirmMsg}
                                  >{res.statusName}</Menu.Item>
                                }

                              })
                            }
                          </SubMenu>
                        )
                      }
                    }
                    //other than status change option
                    else {

                      if (option.title === "Download item order summary CSV") {
                        if (this.props.fromtrans === 'order' || this.props.fromtrans === 'Order') {
                          return (
                            <Menu.Item key={option.title} message={option.confirmMsg}
                            >
                              {option.title}
                            </Menu.Item>
                          )
                        }
                      } else {
                        return (
                          <Menu.Item key={option.title} message={option.confirmMsg}
                          >
                            {option.title}
                          </Menu.Item>
                        )
                      }

                    }
                  }

                } else {
                  //for status change submenu status
                  if (option.title === "Status Change") {
                    if (this.state.templateStatusList.length > 0) {
                      let data = this.state.templateStatusList
                      return (
                        <SubMenu title={option.title}>
                          {
                            data.map((res) => {
                              //dont push signed status in dropdown
                              if (templateSignedStatus) {
                                if (templateSignedStatus !== res.statusName) {
                                  return <Menu.Item
                                    style={{ textTransform: 'capitalize' }}
                                    message={option.confirmMsg}
                                    key={res.statusName}>{res.statusName}</Menu.Item>
                                }
                              } else {
                                return <Menu.Item key={res.statusName} message={option.confirmMsg}
                                >{res.statusName}</Menu.Item>
                              }

                            })
                          }
                        </SubMenu>
                      )
                    }
                  }
                  //other than status change option
                  else {

                    if (option.title === "Download item order summary CSV") {
                      if (this.props.fromtrans === 'order' || this.props.fromtrans === 'Order') {
                        return (
                          <Menu.Item key={option.title} message={option.confirmMsg}
                          >
                            {option.title}
                          </Menu.Item>
                        )
                      }
                    } else {
                      return (
                        <Menu.Item key={option.title} message={option.confirmMsg}
                        >
                          {option.title}
                        </Menu.Item>
                      )
                    }

                  }
                }
              }
            })
            }
          </Menu>
        }

        {
          this.props.screenName !== "transactionScreen" && this.props.screenName !== "contactScreen" && this.props.screenName !== "businessContactScreen" &&
          <Menu id="option-menu" onClick={this.onOptionMenuItemSelect.bind(this)}
            onClose={this.handleRequestClose}
            style={{ maxHeight: ITEM_HEIGHT * 5.5 }}>
            <Menu.Item key='Add Tags' message="Are you sure you want to add tags?"
            >
              Add Tags
                      </Menu.Item>
          </Menu>
        }

        {
          (this.props.screenName === "contactScreen" || this.props.screenName === "businessContactScreen") &&
          <Menu id="option-menu" onClick={this.onOptionMenuItemSelect.bind(this)}
            onClose={this.handleRequestClose}
            style={{ maxHeight: ITEM_HEIGHT * 5.5 }}>
            <Menu.Item key='Add Tags' message="Are you sure you want to add tags?"
            >
              Add Tags
                      </Menu.Item>
            <Menu.Item key='Delete' message="Are you sure you want to delete?"
            >
              Delete
                      </Menu.Item>
          </Menu>
        }

      </div>
    )

  };

  onOptionMenuItemSelect = (option) => {
    this.setState({
      optionName: option.key,
      visibleSave: true,
      tagView: option.key === 'Add Tags' ? true : false,
      actionsConfirmMsg: option.item.props.message
    })
  };

  closeTagModal = (option) => {
    this.setState({ tagView: false, tagEditedItem: {} }, () => {
      if (!option) {
        this.getTagsList()
      }
      this.onClearActionItem()
    })

    const { recipientType, recipientId } = this.state
    let contactId = (recipientType && recipientType === "personal") ? recipientId : null
    let businessId = (recipientType && recipientType === "business") ? recipientId : null
    let listingId = recipientType ? localStorage.getItem('receivedListingId') : localStorage.getItem('selectedListingId')

    fetch_tag_history(listingId, this.props.filterScreenName,
      this.props.location.state && this.props.location.state.templatedata.id, contactId, businessId).then(async (res) => {
        this.setState({ tagHistory: res });
      })
      .catch((err) => {
      })
  }

  getToDoConversation(id) {
    return todoConversation.find((conversation) => conversation.id === id);
  }

  onTodoChecked(data) {

    data.selected = !data.selected;
    let selectedToDos = 0;
    const toDos = this.state.listData.map(todo => {
      if (todo.selected) {
        selectedToDos++;
      }
      if (todo.id === data.id) {
        if (todo.selected) {
          selectedToDos++;
        }
        return data;
      } else {
        return todo;
      }
    }
    );
    this.setState({
      selectedToDos: selectedToDos,
      toDos: toDos
    }, () => {
    });
  }


  onTodoSelect(todo) {
    const listingName = localStorage.getItem('selectedListingName')

    let conversationList = this.getToDoConversation(todo.id);
    if (conversationList) {
      conversationList = conversationList.conversationData;
    } else {
      conversationList = [];
    }
    this.setState({
      currentTodo: todo,
      loader: true,
      conversation: conversationList,
      selectList: true,
      selectAdd: false,
      selectSettings: false
    });
    setTimeout(() => {
      this.setState({ loader: false });
    }, 1500);
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState
    });
  }

  //when clicking back button
  onClickBack = () => {
    this.setState({
      currentTodo: null,
      selectNewContact: false,
      selectBusinessContact: false,
      selectMessageTemplate: false,
      saveTemplate: false
    }, () => {
      if (this.props.onBackToList) {
        // this.props.onBackToList(this.state.selectedFilter)
        if (!this.state.templateFilterValues) {
          this.getTransactionData(this.state.selectedFilter)
        }

      }
    })
  }

  onOkActionItem = () => {
    this.setState({ visibleSave: false }, () => {
      if (this.props.onSelectAction) {
        this.props.onSelectAction(this.state.optionName, this.state.toDos, this.state.templateStatusList)
      }
    })
  }

  onClearActionItem = () => {
    this.setState({
      optionName: "Actions",
      allSelected: false
    }, () => {
      this.getUnselectedAllTodo()
    })
    // if (this.props.onSelectAction) {
    //   this.props.onSelectAction(this.state.optionName, this.state.toDos)
    // }
  }

  handleCancel = () => {
    this.setState({
      visibleSave: false
    })
  }

  onOkText = () => {
    const { listData } = this.state
    let linkCount
    let smslinkClickCount = "-"
    let emaillinkClickCount = "-"
    let assignedMembers
    const listingName = localStorage.getItem('selectedListingName')
    var today = new Date();
    let initialDate = moment(today).format("MMMM Do YYYY, h:mm a");
    let formData = []
    let nameData = []
    let data = _.filter(this.state.listData, (item, index) => {
      if (item.selected === true) {
        if (item) {
          console.log('item112233', item)
          let nameData = []
          // let transLink = item.transactionHistory
          // transLink.length > 0 && transLink.map((count, index) => {
          //   let linkLogs = count.typeOfNotification
          //   let relatedBroadcast = count.relatedBroadcast.logs
          //   if (linkLogs === 'SMS') {
          //     relatedBroadcast.length > 0 && relatedBroadcast.map((logs, index) => {
          //       linkCount = logs.transactionUrls
          //       linkCount.length > 0 && linkCount.map((finalCount, index) => {
          //         smslinkClickCount = finalCount.linkClickCount
          //       })
          //     })
          //   }
          //   if (linkLogs === 'EMAIL') {
          //     relatedBroadcast.length > 0 && relatedBroadcast.map((logs, index) => {
          //       linkCount = logs.transactionUrls
          //       linkCount.length > 0 && linkCount.map((finalCount, index) => {
          //         emaillinkClickCount = finalCount.linkClickCount
          //       })
          //     })
          //   }

          // })
          assignedMembers = item.assignedMembers
          assignedMembers.map(assign => {
            let name = ""
            if (assign && assign.assigneeInfo) {
              if (assign.assigneeInfo.firstName) {
                name = assign.assigneeInfo.firstName
              }
              if (assign.assigneeInfo.lastName) {
                name = name + ' ' + assign.assigneeInfo.lastName
              }
              nameData.push(name)
            }

          })

          if (item.type === "contract") {
            formData.push(item.formData)
            let len = formData.length - 1
            formData[len].contactId = item.contactId
            // formData[len].contactNameOverride = item.contactNameOverride
            formData[len].createdAt = item.createdAt
            formData[len].createdBy = item.createdBy
            // formData[len].id = item.id
            // formData[len].listingId = item.listingId
            // formData[len].source = item.source
            // formData[len].transactionTemplateId = item.transactionTemplateId
            formData[len].currentStatus = item.currentStatus
            // formData[len].template = item.template.id
            formData[len].template = item.template.name
            // formData[len].smslinkClickCount = smslinkClickCount
            // formData[len].emaillinkClickCount = emaillinkClickCount
            formData[len].assignedMembers = nameData
          }
          //if template type is order
          else if (item.type === "order") {
            //generate csv row based on products
            if (item.formData.LineItems.length > 0) {
              item.formData.LineItems.map((res, index) => {
                console.log(res, 'resresresrs')
                if (res && ((res.product_config && res.product_config.length > 0 && res.product_config[0].selected_quantity) ||
                  res.selected_quantity)) {

                  //order id
                  let option = {
                    "orderId": item.formData.orderId,
                  }
                  let duplicateOption = cloneDeep(option)

                  if (item.createdAt) {
                    duplicateOption['Order Date & Time'] = moment(item.createdAt).format('MMM DD YYYY, hh:mm A')
                  }

                  if (item.currentStatus) {
                    console.log('currentStatus123', item.currentStatus)
                    duplicateOption.currentStatus = item.currentStatus
                  }
                  //product variants
                  if (res && res.product_variant_option) {
                    console.log('variant options', res.product_variant_option)
                    Object.keys(res.product_variant_option).map(key => {
                      console.log('variant options key', key, duplicateOption)
                      if (res.product_variant_option[key]) {
                        duplicateOption[`${key}`] = res.product_variant_option[key]
                      }
                    })
                  }

                  //order total
                  if (item.formData.Total) {
                    duplicateOption['Sub Total'] = item.formData.Total.TotalPriceBeforeTax.toFixed(2)
                    duplicateOption.TotalDiscount = item.formData.Total.TotalDiscount ?
                      item.formData.Total.TotalDiscount.toFixed(2) : "-"
                    duplicateOption.DeliveryFee = item.formData.Total.DeliveryFee ?
                      item.formData.Total.DeliveryFee : "-"

                    if ((!item.formData.Total.TaxLevel) || (item.formData.Total.TaxLevel === "SubTotalLevel")) {
                      if (Array.isArray(item.formData.Total.TaxDetails)) {
                        item.formData.Total.TaxDetails.map((res) => {
                          if (res) {
                            let percentageValue = (res.taxComputationType / 100)
                            let price = item.formData.Total.TotalPriceBeforeTax * percentageValue;
                            duplicateOption[`${res.taxType} At ${res.taxComputationType}%`] = price.toFixed(2)
                            // return price
                          }

                        })
                      }
                    } else {
                      if (item.formData.Total.TaxLevel === "ItemLevel") {

                        Object.keys(item.formData.Total.TaxDetails).map(option => {
                          let totaltaxPrice = 0;
                          let items = item.formData.LineItems

                          items && items.map((response) => {
                            let taxTypePrice = 0
                            if (response && response.product_taxes) {

                              let amt = response.product_config ? (response.product_config[0].selected_quantity
                                * parseFloat(response.product_config[0].price)).toFixed(2) : res.total

                              if (this.props.templateData && this.props.templateData.templateSettings &&
                                this.props.templateData.templateSettings.fieldValues &&
                                this.props.templateData.templateSettings.fieldValues.taxesValues) {
                                let tax = response.product_taxes

                                let taxData = this.props.templateData.templateSettings.fieldValues.taxesValues
                                if ((tax.CGST && taxData.CGST && ("CGST" === option))) {
                                  let percentageValue = (parseFloat(tax.CGST) / 100)
                                  taxTypePrice = amt * percentageValue;
                                }
                                if (tax.IGST && taxData.IGST && ("IGST" === option)) {
                                  let percentageValue = (parseFloat(tax.IGST) / 100)
                                  taxTypePrice = amt * percentageValue;
                                }
                                if (tax.SGST && taxData.SGST && ("SGST" === option)) {
                                  let percentageValue = (parseFloat(tax.SGST) / 100)
                                  taxTypePrice = amt * percentageValue;
                                }
                              }

                            }
                            totaltaxPrice = totaltaxPrice + taxTypePrice

                          })
                          duplicateOption[`Total ${option}%`] = totaltaxPrice.toFixed(2)

                        })
                      }
                    }
                    duplicateOption['Total'] = item.formData.Total.TotalPriceAfterTax.toFixed(2)
                  }



                  //customer data
                  if (item.formData.UserFormFullData) {
                    let name = _.find(item.formData.UserFormFullData, function (res) {
                      if (res.attributeType === "customerName") {
                        return res.attributeValue
                      }
                    })
                    // let phone = _.find(item.formData.UserFormFullData, function (res) {
                    //   if (res.attributeType === "PhoneNumber") {
                    //     return res.attributeValue
                    //   }
                    // })
                    let email = _.find(item.formData.UserFormFullData, function (res) {
                      if (res.attributeType === "customerEmail") {
                        return res.attributeValue
                      }
                    })
                    let address = _.find(item.formData.UserFormFullData, function (res) {
                      if (res.attributeType === "AddressField") {
                        return res.attributeValue
                      }
                    })
                    duplicateOption.customerName = name ? name.attributeValue : "-"
                    duplicateOption.customerEmail = email ? email.attributeValue : "-"
                    duplicateOption.deliveryAddress = address ? address.attributeValue.replace(/\r?\n/g, '') : "-"
                  }

                  let phone = item.formData && item.formData.loggedinPhoneNumber ?
                    item.formData.loggedinPhoneNumber : "-"
                  duplicateOption.customerPhone = phone ? phone : "-"

                  console.log(item.formData, 'item.formData', Object.keys(item.formData.DeliveryDetailsFullData).length)

                  //delivery detail
                  if (item.formData.DeliveryDetailsFullData && Object.keys(item.formData.DeliveryDetailsFullData).length > 0) {

                    item.formData.DeliveryDetailsFullData.map((res) => {
                      let paymentType = ""
                      if (res) {

                        if (res.attributeType !== "MinOrderValue" && res.attributeType !== "Orderahead"
                          && res.attributeType !== "Workinghours" && res.attributeType !== "OrderaheadStartDay") {
                          if ((res.attributeType === "PaymentType") ||
                            (res.attributeType === "deliveryFee") ||
                            (res.attributeType === "Option")
                          ) {
                            let deliverydetail = res
                            if (deliverydetail && deliverydetail.hasOwnProperty('duplicateAttributeValue')) {

                              if (typeof deliverydetail.duplicateAttributeValue === "string"
                                && deliverydetail.duplicateAttributeValue) {
                                let splitArray = deliverydetail.duplicateAttributeValue.split(":")

                                if (splitArray.length > 0) {
                                  paymentType = splitArray[0]
                                }
                              } else {
                                paymentType = deliverydetail.duplicateAttributeValue.length > 0 ?
                                  deliverydetail.duplicateAttributeValue[0] : "-"
                              }

                              duplicateOption[`${res.attributeName}`] = paymentType
                            }
                          } else {
                            duplicateOption[`${res.attributeName}`] = res.attributeValue
                          }
                        }
                      }

                    })

                    //selected delivery option
                    duplicateOption.deliveryType = item.formData.DeliveryDetails ?
                      item.formData.DeliveryDetails.selectedOption : "-"

                  } else {
                    if (item.formData.DeliveryDetails.detail) {
                      Object.keys(item.formData.DeliveryDetails.detail).map(key => {
                        if (item.formData.DeliveryDetails.detail[key]) {
                          duplicateOption[`${key}`] = item.formData.DeliveryDetails.detail[key]
                        }
                      })
                    }
                    //selected delivery option
                    duplicateOption.deliveryType = item.formData.DeliveryDetails ?
                      item.formData.DeliveryDetails.selectedOption : "-"
                  }

                  //product detail
                  duplicateOption.item = res.product_name
                  duplicateOption.quantity = res.product_config ? res.product_config[0].selected_quantity : res.selected_quantity
                  duplicateOption.unitPrice = res.product_config ? res.product_config[0].price ? res.product_config[0].price : "-" : res.unit_price
                  duplicateOption.totalPrice = res.product_config ? (res.product_config[0].selected_quantity
                    * parseFloat(res.product_config[0].price)).toFixed(2) : res.total
                  console.log('final123 duplicateOption', duplicateOption)
                  return formData.push(duplicateOption)
                }
              })
            }
          }
        }
      }
    });



    if (formData.length === 0) {
      message.warning("Please select any list")
    } else {
      return (<CSVLink data={formData} filename={listingName + '_' + initialDate + '.csv'}
        onClick={() => {
          this.setState({
            //  visibleSave: false
          }, () => {
            this.onClearActionItem()
          })
        }}>
        Download
      </CSVLink>
      )
    }
  }

  onOkTextDownloadOrderItem = () => {
    let allProductList = []

    const listingName = localStorage.getItem('selectedListingName')
    var today = new Date();
    let initialDate = moment(today).format("MMMM Do YYYY, h:mm a");

    let data = _.filter(this.state.listData, (item, index) => {
      if (item.selected === true) {
        if (item && item.formData) {

          if (item.formData.LineItems && item.formData.LineItems.length > 0) {
            item.formData.LineItems.map((res, index) => {
              if (res) {

                let deliveryDate = ""
                if (item.formData.DeliveryDetailsFullData) {

                  let deliveryDetail = _.find(item.formData.DeliveryDetailsFullData, function (res) {
                    if (res.attributeType === "RequestDate") {
                      return res.attributeValue
                    }
                  })
                  deliveryDate = deliveryDetail ? deliveryDetail.attributeValue : ""
                }

                console.log('resresres', res)

                //push initial data
                if (allProductList.length === 0) {

                  //if product has product_attribute
                  if (res.product_attribute && res.product_attribute.length > 0) {
                    let config = res.product_config[0]
                    let option = {
                      productId: res.product_id,
                      itemName: res.product_name,
                      totalQuantity: config.selected_quantity,
                      unitPrice: res.product_baseprice ? parseFloat(res.product_baseprice).toFixed(2) : 0,
                      totalPrice: res.product_baseprice ? (parseFloat(res.product_baseprice) * config.selected_quantity).toFixed(2) : 0,
                      // deliveryDate: moment(item.createdAt).format('MMM DD YYYY, hh:mm A'),
                      deliveryDate: deliveryDate ? deliveryDate : "-",
                      productAttribute: false
                    }
                    allProductList.push(option)

                    //push inital product attributes
                    if (res.selected_product_attributes) {
                      Object.keys(res.selected_product_attributes).map((key) => {
                        if (key) {
                          return res.selected_product_attributes[key].map(attributeRes => {
                            if (attributeRes && attributeRes[0]
                              && attributeRes[1]) {
                              let attribute_option = {
                                productId: res.product_id,
                                itemName: res.product_name,
                                AddOn: attributeRes[0],
                                totalQuantity: config.selected_quantity,
                                unitPrice: parseFloat(attributeRes[1]).toFixed(2),
                                totalPrice: (parseFloat(attributeRes[1]) * config.selected_quantity).toFixed(2),
                                deliveryDate: deliveryDate ? deliveryDate : "-",
                                productAttribute: true
                              }
                              allProductList.push(attribute_option)
                            }
                          })
                        }
                      })
                    }
                  }
                  //if product has no product_attribute
                  else {
                    if (res.product_config && res.product_config.length > 0) {
                      let config = res.product_config[0]

                      let option = {
                        productId: res.product_id,
                        itemName: res.product_name,
                        totalQuantity: config.selected_quantity,
                        unitPrice: parseFloat(config.price).toFixed(2),
                        totalPrice: (parseFloat(config.price) * config.selected_quantity).toFixed(2),
                        deliveryDate: deliveryDate ? deliveryDate : "-",
                        productAttribute: false
                      }
                      allProductList.push(option)
                    } else {

                      let option = {
                        productId: res.product_id,
                        itemName: res.product_name,
                        totalQuantity: res.quantity,
                        unitPrice: parseFloat(res.unit_price).toFixed(2),
                        totalPrice: res.total,
                        deliveryDate: deliveryDate ? deliveryDate : "-",
                        productAttribute: false
                      }
                      allProductList.push(option)

                    }
                  }
                }

                //check whether item is already pushed in array
                else {
                  let sameProduct = _.find(allProductList, function (allListRes, indexValue) {

                    if (allListRes.productId === res.product_id && !allListRes.productAttribute) {
                      let detail = cloneDeep(allProductList)
                      // let productOrderDate = moment(item.createdAt).format('ddd, MMM Do')
                      // let productOrderDate = moment(item.createdAt).format('YYYY MM DD')
                      let allListResDate = allListRes.deliveryDate
                      let checkDate = moment(deliveryDate).isSame(allListResDate); // true
                      if (allListResDate === deliveryDate) {
                        if (res.product_config && res.product_config.length > 0) {
                          let config = res.product_config[0]

                          //sum total quantity with before pushed item
                          detail[indexValue].totalQuantity = detail[indexValue].totalQuantity + config.selected_quantity
                          if (res.product_attribute && res.product_attribute.length > 0) {
                            detail[indexValue].totalPrice = res.product_baseprice ? (parseFloat(res.product_baseprice) * detail[indexValue].totalQuantity).toFixed(2) : 0
                          } else {
                            detail[indexValue].totalPrice = (parseFloat(config.price) * detail[indexValue].totalQuantity).toFixed(2)
                          }

                          allProductList = detail
                          if (res.product_attribute && res.product_attribute.length > 0 && res.selected_product_attributes) {

                            Object.keys(res.selected_product_attributes).map((key) => {
                              if (key) {
                                return res.selected_product_attributes[key].map(attributeRes => {

                                  if (attributeRes && attributeRes[0]
                                    && attributeRes[1]) {

                                    let config = res.product_config[0]
                                    let sameProductAttributes = _.find(allProductList, function (allListRes, indexValue) {
                                      if (allListRes.productId === res.product_id &&
                                        allListRes.productAttribute && allListRes.AddOn === attributeRes[0]) {

                                        detail[indexValue].totalQuantity = detail[indexValue].totalQuantity + config.selected_quantity
                                        detail[indexValue].totalPrice = (parseFloat(attributeRes[1]) * detail[indexValue].totalQuantity).toFixed(2)
                                        return detail[indexValue]
                                      }
                                    })
                                    if (!sameProductAttributes) {
                                      let attribute_option = {
                                        productId: res.product_id,
                                        itemName: res.product_name,
                                        AddOn: attributeRes[0],
                                        totalQuantity: config.selected_quantity,
                                        unitPrice: parseFloat(attributeRes[1]).toFixed(2),
                                        totalPrice: (parseFloat(attributeRes[1]) * config.selected_quantity).toFixed(2),
                                        deliveryDate: deliveryDate ? deliveryDate : "-",
                                        productAttribute: true
                                      }
                                      allProductList.push(attribute_option)
                                      return detail[indexValue]
                                    } else {
                                      return detail[indexValue]
                                    }
                                  }
                                })
                              }
                            })
                          } else {
                            return detail[indexValue]
                          }
                        }
                        // else {
                        return detail[indexValue]
                        // }
                      }
                    }

                  })
                  if (!sameProduct) {

                    //if product has product_attribute
                    if (res.product_attribute && res.product_attribute.length > 0) {
                      let config = res.product_config[0]
                      let option = {
                        productId: res.product_id,
                        itemName: res.product_name,
                        totalQuantity: config.selected_quantity,
                        unitPrice: res.product_baseprice ? parseFloat(res.product_baseprice).toFixed(2) : 0,
                        totalPrice: res.product_baseprice ? (parseFloat(res.product_baseprice) * config.selected_quantity).toFixed(2) : 0,
                        deliveryDate: deliveryDate ? deliveryDate : "-",
                        productAttribute: false
                      }
                      allProductList.push(option)

                      //push inital product attributes
                      if (res.selected_product_attributes) {
                        Object.keys(res.selected_product_attributes).map((key) => {
                          if (key) {
                            return res.selected_product_attributes[key].map(attributeRes => {
                              if (attributeRes && attributeRes[0]
                                && attributeRes[1]) {
                                let attribute_option = {
                                  productId: res.product_id,
                                  itemName: res.product_name,
                                  AddOn: attributeRes[0],
                                  totalQuantity: config.selected_quantity,
                                  unitPrice: parseFloat(attributeRes[1]).toFixed(2),
                                  totalPrice: (parseFloat(attributeRes[1]) * config.selected_quantity).toFixed(2),
                                  deliveryDate: deliveryDate ? deliveryDate : "-",
                                  productAttribute: true
                                }
                                allProductList.push(attribute_option)
                              }
                            })
                          }
                        })
                      }

                    }
                    //if product has no product_attribute
                    else {
                      if (res.product_config && res.product_config.length > 0) {
                        let config = res.product_config[0]

                        let option = {
                          productId: res.product_id,
                          itemName: res.product_name,
                          totalQuantity: config.selected_quantity,
                          unitPrice: parseFloat(config.price).toFixed(2),
                          totalPrice: (parseFloat(config.price) * config.selected_quantity).toFixed(2),
                          deliveryDate: deliveryDate ? deliveryDate : "-",
                          productAttribute: false
                        }
                        allProductList.push(option)
                      } else {
                        let option = {
                          productId: res.product_id,
                          itemName: res.product_name,
                          totalQuantity: res.quantity,
                          unitPrice: parseFloat(res.unit_price).toFixed(2),
                          totalPrice: res.total,
                          deliveryDate: deliveryDate ? deliveryDate : "-",
                          productAttribute: false
                        }
                        allProductList.push(option)
                      }
                    }
                  }

                }
              }
            })
          }

        }
      }
    });

    allProductList.forEach(function (v) {
      delete v.productId
      delete v.productAttribute

    });

    if (allProductList.length === 0) {
      message.warning("Please select any list")
    }
    return (

      <CSVLink data={allProductList} filename={listingName + '_' + initialDate + '.csv'}
        onClick={() => {
          this.setState({
            //  visibleSave: false
          }, () => {
            this.onClearActionItem()
          })
        }}>
        Download
      </CSVLink>
    )
  }


  onProductInventoryCsv = async () => {
    let allProductList = [];
    let totalProductsAcrossOrders = 0;

    this.state.listData.map((item, index) => {

      if (item.selected === true) {
        if (item && item.formData) {
          console.log(item.formData, totalProductsAcrossOrders, 'titititi');
          if (item.formData.LineItems && item.formData.LineItems.length > 0) {
            var CompleteLoop = item.formData.LineItems.map(async (eachProd, i) => {
              totalProductsAcrossOrders = totalProductsAcrossOrders + 1;
              return this.getinventoryDetails(eachProd).then((gotit) => {
                console.log(gotit, 'allprodlistiner');
                allProductList.push(gotit);

              });
            });
            Promise.all(CompleteLoop).then(() => {

              console.log(withduplicates, this.state.listData, allProductList, totalProductsAcrossOrders, 'final')
              if (allProductList.length === totalProductsAcrossOrders) {
                var withduplicates = allProductList.filter(value => JSON.stringify(value) !== '{}');

                console.log(withduplicates, 'completeProductList')

                this.setState({ ProdInventoryList: withduplicates }, () => {
                  console.log(this.csvLink);
                  if (this.csvLink) {
                    this.csvLink.link.click();
                    this.onClearActionItem();
                  }
                });
              }
            });

          }
        }
      }
    });

    // console.log(CompleteLoop, 'allprodlist')
  }

  getFileName = () => {
    const listingName = localStorage.getItem('selectedListingName')
    var today = new Date();
    let initialDate = moment(today).format("MMMM Do YYYY, h:mm a");
    return listingName + '_' + initialDate + '.csv';
  }

  getinventoryDetails = (eachProd) => {
    return new Promise(async (resolve, reject) => {
      client.query({
        query: GET_PRODUCT_INVENTORY,
        variables: {
          templateId: eachProd.transaction_template_id,
          productId: eachProd.product_id
        }
      })
        .then((res) => {
          console.log(res, 'rresrsr')
          if (res && res.data && res.data.product_inventory && res.data.product_inventory.length > 0) {
            let final = {
              productId: eachProd.product_id,
              productName: eachProd.product_name,
              onHandQuantity: res.data.product_inventory[0].onhand_quantity,
              updatedAt: moment(res.data.product_inventory[0].updated_at).format("MMMM Do YYYY, h:mm a")
            }
            resolve(final);
          } else {
            resolve({})
          }
        });
    })
  }

  //when search filter data
  onFilterData = (data, filterData, i) => {

    // this.setState({ FilterItemIndex: i })
    let value = cloneDeep(data)
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (key === "createdAt" && value[key].length > 0) {
          value.createdAtFrom = value[key][0]
          value.createdAtTo = value[key][1]
        } else if (key === "updatedAt" && value[key].length > 0) {
          value.updatedAtFrom = value[key][0]
          value.updatedAtTo = value[key][1]
        }
        else {
          if (Array.isArray(value[key]) && key !== "createdAt" && key !== "updatedAt"
            && key !== "assignedToNames") {
            var topicsArray = value[key]
            var txt = '';
            topicsArray.forEach(function (val, index) {
              txt = txt + val + ','
            });
            txt = txt.substring(0, txt.length - 1);
            txt = '{' + txt + '}';
            value[key] = txt
          }
        }

        // if ((key === "createdAtFilterRadioOption" || key === "updatedAtFilterRadioOption")
        //   && value[key]) {
        //   let currentDate = new Date()
        //   // getYearMonthDate(dateValue[0])
        //   let startDate
        //   let endDate
        //   if (value[key] === "TODAY") {

        //     startDate = getYearMonthDate(currentDate) + ' 00:00:00'
        //     endDate = getYearMonthDate(currentDate) + ' 23:59:59'

        //     if (key === "createdAtFilterRadioOption") {
        //       value.createdAtFrom = startDate
        //       value.createdAtTo = endDate
        //     }
        //     if (key === "updatedAtFilterRadioOption") {
        //       value.updatedAtFrom = startDate
        //       value.updatedAtTo = endDate
        //     }

        //   }
        //   if (value[key] === "SINCE_YESTERDAY") {
        //     endDate = getYearMonthDate(currentDate) + ' 23:59:59'
        //     let start = moment(currentDate).subtract(1, 'days');
        //     startDate = getYearMonthDate(start) + ' 00:00:00'
        //     if (key === "createdAtFilterRadioOption") {
        //       value.createdAtFrom = startDate
        //       value.createdAtTo = endDate
        //     }
        //     if (key === "updatedAtFilterRadioOption") {
        //       value.updatedAtFrom = startDate
        //       value.updatedAtTo = endDate
        //     }
        //   }
        //   if (value[key] === "SINCE_LAST_WEEK") {
        //     endDate = getYearMonthDate(currentDate) + ' 23:59:59'
        //     let start = moment(currentDate).subtract(7, 'days');
        //     startDate = getYearMonthDate(start) + ' 00:00:00'
        //     if (key === "createdAtFilterRadioOption") {
        //       value.createdAtFrom = startDate
        //       value.createdAtTo = endDate
        //     }
        //     if (key === "updatedAtFilterRadioOption") {
        //       value.updatedAtFrom = startDate
        //       value.updatedAtTo = endDate
        //     }
        //   }
        // }
      }
    }
    if (this.props.onFilterDataList) {
      this.props.onFilterDataList(value, filterData, i)
    }
  }

  onCloseFilterModal = (value) => {
    this.setState({ editFilterItem: {}, isOpenFilterModal: false }, () => {
      if (!value) {
        this.setState({ isCallFilterList: true })
      }
    })
  }
  callFilterList = () => {
    this.setState({ isCallFilterList: false })
  }

  render() {
    const { selectedToDos, loader, drawerState, toDos, alertMessage, showMessage, optionName } = this.state;
    return (
      <div className="gx-main-content" id="gx-container-view">
        <div className="gx-app-module">

          {
            this.props.screenName !== "listingDetail" &&
            <div>
              <div className="gx-d-block gx-d-lg-none">
                <Drawer
                  placement="left"
                  closable={false}
                  visible={drawerState}
                  onClose={this.onToggleDrawer.bind(this)}>
                  {this.ToDoSideBar()}
                </Drawer>
              </div>
              <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
                {this.ToDoSideBar()}
              </div>
            </div>
          }

          {/* <div className="gx-module-box-header">
            <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
              <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                onClick={this.onToggleDrawer.bind(this)} />
            </span>
          </div> */}

          {
            this.state.showNewSMSView === false &&
            <div className="gx-module-box" id="list-content-view">

              <div className="gx-module-box-header">

                <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                  <i className="icon icon-menu gx-icon-btn" aria-label="Menu"
                    onClick={this.onToggleDrawer.bind(this)} />
                </span>
                {this.props && this.props.templateName &&
                  <p id="header-title-view" className="list-header-view">{this.props.templateName}</p>
                }
              </div>

              <div className="gx-module-box-content">
                {
                  this.props.screenName !== "listingDetail" && this.props.fromtrans !== "listings" &&
                  <div>
                    {
                      this.state.currentTodo === null ?
                        <div className="gx-module-box-topbar gx-module-box-topbar-todo">
                          <div style={{ display: 'flex', alignItems: 'baseline', width: '100%', marginTop: 5 }}>
                            {this.state.toDos.length > 0 ?
                              <Auxiliary>
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}>

                                  <div style={{
                                    display: 'flex',
                                    alignItems: 'center',

                                  }}>
                                    <Checkbox className="gx-icon-btn" color="primary"
                                      checked={this.state.allSelected}
                                      onChange={this.onAllTodoSelect.bind(this)}
                                      value="SelectMail" />
                                    <Dropdown
                                      style={{ width: 'max-content' }}
                                      overlay={this.optionMenu()}
                                      placement="bottomRight"
                                      trigger={['click']}>
                                      <div>
                                        <span className="gx-px-2"> {this.state.optionName}</span>
                                        <i className="icon icon-charvlet-down" />
                                      </div>
                                    </Dropdown>
                                  </div>
                                  {
                                    this.state.optionName !== "Actions" && this.state.optionName !== "Download as CSV" && this.state.optionName !== "Download item order summary CSV" && this.state.optionName !== 'Add Tags' &&
                                    <div style={{
                                      display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                      marginTop: 10
                                    }}>
                                      <Popconfirm
                                        visible={this.state.visibleSave}
                                        title={this.state.actionsConfirmMsg}
                                        onConfirm={() => this.onOkActionItem()}
                                        onCancel={this.onClearActionItem}
                                        okText="Proceed"
                                        cancelText="Cancel"></Popconfirm>
                                      {/* <Button onClick={() => this.onClearActionItem()}>Cancel</Button> */}
                                    </div>
                                  }
                                  {
                                    this.state.optionName !== "Actions" && this.state.optionName === "Download as CSV" && this.state.optionName !== 'Add Tags' &&
                                    <div style={{
                                      display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                      marginTop: 10
                                    }}>
                                      <Popconfirm
                                        visible={this.state.visibleSave}
                                        title={"Are you sure to download as CSV?"}
                                        //  onConfirm={()=>this.onOkText()}
                                        onCancel={this.onClearActionItem}
                                        okText={this.onOkText()}
                                        cancelText="Cancel"
                                      >
                                      </Popconfirm>
                                    </div>
                                  }
                                  {
                                    this.state.optionName !== "Actions" && this.state.optionName === "Download item order summary CSV" && this.state.optionName !== 'Add Tags' &&
                                    <div style={{
                                      display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                      marginTop: 10
                                    }}>
                                      <Popconfirm
                                        visible={this.state.visibleSave}
                                        title={"Are you sure to download order item summary as CSV?"}
                                        //  onConfirm={()=>this.onOkText()}
                                        onCancel={this.onClearActionItem}
                                        okText={this.onOkTextDownloadOrderItem()}
                                        cancelText="Cancel"
                                      >
                                      </Popconfirm>
                                      {/* <Button onClick={() => this.onClearActionItem()}>Cancel</Button> */}
                                    </div>
                                  }

                                  {
                                    this.state.optionName !== "Actions" && this.state.optionName === "Download Product Inventory CSV" && this.state.optionName !== 'Add Tags' &&
                                    <div style={{
                                      display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                      marginTop: 10
                                    }}>

                                      <Popconfirm
                                        visible={this.state.visibleSave}
                                        title={"Are you sure to download Product Inventory as CSV?"}
                                        onConfirm={() => this.onProductInventoryCsv()}
                                        onCancel={this.onClearActionItem}
                                        // okText={this.downloadCSV()}
                                        cancelText="Cancel"
                                      >
                                      </Popconfirm>

                                      <CSVLink
                                        data={this.state.ProdInventoryList}
                                        filename={this.getFileName()}
                                        className="hidden"
                                        ref={(r) => this.csvLink = r}
                                        target="_blank"
                                      />
                                      {/* <Button onClick={() => this.onClearActionItem()}>Cancel</Button> */}
                                    </div>
                                  }

                                  {this.props.screenName !== 'messageTemplateScreen' && this.props.receivedViewType !== 'order' && !this.props.receivedScreen &&
                                    <FilterIcon screenName={this.props.filterScreenName}
                                      templateId={this.props.location.state ?
                                        this.props.location.state.templatedata.id : ''}
                                      option="filter_list"
                                      onFilterData={this.onFilterData}
                                      filteredData={this.props.selectedFilterData}
                                      transactionTemplateData={this.props.templateData}
                                      broadcastType={this.props.broadcastTab}
                                      editFilterItem={this.state.editFilterItem}
                                      onCloseFilterModal={this.onCloseFilterModal}
                                      isOpenFilterModal={this.state.isOpenFilterModal}
                                      onResetFilterData={this.ResetFilterData}
                                      recipientId={this.props.recipientUserId}
                                      receivedUserType={this.props.receivedType}
                                      callFilterList={this.callFilterList}
                                    />
                                  }
                                </div>
                              </Auxiliary> : null}
                          </div>
                        </div>
                        :
                        <div className="gx-module-box-topbar">
                          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                            <i className="icon icon-arrow-left gx-icon-btn"
                              onClick={() => this.onClickBack()}
                            />

                            {this.props.screenName === 'contactScreen' && this.state.currentTodo === 'New contact' &&
                              // <div>
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                Add New Contact
                                </div>
                              /* <Tooltip title="Save">
                                <Icon type="save" onClick={() => this.setState({ saveIndividual: true })} />
                              </Tooltip> */
                              // </div>
                            }

                            {this.props.screenName === 'contactScreen' && this.state.currentTodo !== 'New contact' &&
                              // <div>
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                Contact Details
                                 </div>
                              /* {this.state.editIndividual ?
                                <Tooltip title="Save">
                                  <Icon type="save" onClick={() => this.setState({ saveIndividual: true })} />
                                </Tooltip>
                                :
                                <Tooltip title="Edit">
                                  <Icon type="edit" onClick={() => this.setState({ editIndividual: true })} />
                                </Tooltip>
                              } */
                              // </div>
                            }

                            {this.props.screenName === 'businessContactScreen' && this.state.currentTodo === 'New business contact' &&
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                Add New Business Contact
                                </div>
                            }

                            {this.props.screenName === 'businessContactScreen' && this.state.currentTodo !== 'New business contact' &&
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                Business Contact Detail
                                </div>
                            }

                            {this.props.screenName === 'messageTemplateScreen' && this.state.currentTodo === 'New Message Template' &&
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                New Message Template
                                </div>
                            }

                            {this.props.screenName === 'messageTemplateScreen' && this.state.currentTodo !== 'New Message Template' &&
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                Message Template Detail
                                </div>
                            }

                            {this.props.screenName === 'transactionScreen' && this.state.currentTodo === 'New' &&
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: '16px', fontWeight: 500 }}>
                                {this.state.selectSettings === false ? `New ${this.props.fromtrans}` : 'Edit Template'}
                              </div>
                            }

                          </div>
                        </div>
                    }

                  </div>
                }

                {loader ?
                  <div className="gx-loader-view">
                    <CircularProgress />
                  </div> :
                  <div>
                    {this.props.screenName === 'listingScreen' ?
                      <Listing selectedTab={this.state.selectedTab} data={this.state.listData} /> :
                      this.showToDos(this.state)
                    }
                  </div>
                }
              </div>

            </div>
          }

          {
            this.state.showNewSMSView === true &&
            <div className="gx-module-box">
              <NewBroadCast
                broadcastType={this.props.broadcastTab}
                optionFor="messageList"
              />
            </div>
          }

          {((this.state.optionName === 'Add Tags') ||
            (Object.keys(this.state.tagEditedItem).length > 0)) &&
            <TagModal
              openModal={this.state.tagView}
              closeModal={this.closeTagModal}
              SelectedListData={this.state.toDos}
              screenName={this.props.filterScreenName}
              templateId={this.props.location.state ?
                this.props.location.state.templatedata.id : ''}
              editItem={this.state.tagEditedItem}
              recipientUserId={this.state.recipientId}
              receivedUserType={this.state.recipientType}
            />
          }
        </div>
        {showMessage && message.info(<span id="message-id">{alertMessage}</span>, 3, this.handleRequestClose)}
      </div>
    )
  }
}

export default withRouter(List);
