import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from './DynamicFields';


const { Option } = Select;

let initialData = {
    DefaultValue: '',
    AlternateValue: '',
    FieldType: '',
}

class LocalizationDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            localizationDetails: [],
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        console.log(this.props.editData, 'editData')
        if (this.props.editData) {
            this.setState({ localizationDetails: this.props.editData })
        } else {
            if (this.state.localizationDetails.length === 0) {
                let data = this.state.localizationDetails
                data.push(initialData)
                this.setState({ localizationDetails: data, loading: false })
            }
        }

    }

    onAddRow = () => {
        let data = this.state.localizationDetails
        let initialData = {
            DefaultValue: '',
            AlternateValue: '',
            FieldType: '',
        }

        if(data) {data.push(initialData)}
        this.setState({ localizationDetails: data })
    }


    LoopItemsChange(localizationDetails) {
        this.setState({ localizationDetails }, () => {
            this.props.localizationDetailsave(localizationDetails);
        })
    }

    onRemove(localizationDetails) {
        this.setState({ localizationDetails });
    }


    render() {
        const { localizationDetails } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        console.log(localizationDetails, 'cutomerdetails')
        return (
            <Form
                onSubmit={this.handleSubmit}
            >

                <div >
                    <DynamicFields
                        accessFrom='Localization'
                        LoopItems={localizationDetails}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        isEdit={this.props.isEdit ? true : false}
                        listingDetail={this.props.listingDetail && this.props.listingDetail}
                    />
                    {/* {formItems} */}
                </div>
                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item {...formItemLayoutWithOutLabel} >
                        <Button type="primary" htmlType="submit" >
                            Save
                            </Button>
                    </Form.Item>
                </div> */}
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(LocalizationDetails);
export default WrappedDynamicFieldSet

