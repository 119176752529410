import React, { Component } from 'react'
import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';
import _, { cloneDeep } from 'lodash'

export class AddProductConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            LoopItems: [],
        }
    }

    componentDidMount() {
        this.setState({
            LoopItems: this.props.LoopItems,
            final: this.props.final,
            propsInex: this.props.index
        })
    }

    onRemoveRow = (index) => {
        let LoopItems = cloneDeep(this.state.LoopItems)
        LoopItems[index].isDelete = true
        this.setState({ LoopItems }, () => {
            this.props.form.validateFields(async (err, val) => {
                if (!err) {
                    const {final, propsInex} = this.state
                    let value = final[propsInex]
                    value.productConfig = this.state.LoopItems
                    this.props.LoopItemsChange(value, this.props.accessFrom);
                } else {
                    // this.props.LoopItemsChange([], this.props.accessFrom);
                }
            });
        });
    }


    onvaluesChange(index, fieldName, e) {

        this.state.LoopItems[index][fieldName] = e.target.value;

        this.setState({ LoopItems: this.state.LoopItems }, () => {
            this.props.form.validateFields(async (err, val) => {
                if (!err) {                 
                    const {final, propsInex} = this.state
                    console.log('final', final)
                    // let value = final[propsInex]
                    // console.log('value[0]', value)
                    final[propsInex].productConfig = this.state.LoopItems
                    console.log('final value', final)
                    this.props.LoopItemsChange(final, this.props.accessFrom);
                } else {
                    // this.props.LoopItemsChange([], this.props.accessFrom);
                }
            })
        })
    }

    render() {
        const { LoopItems } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 4 },
            },
        };
        let first = true;
        return (
            <div>
                {LoopItems.map((k, index) => {
                    if (k && !k.isDelete) {
                        try {
                            return (
                                <div>
                                    <Form.Item
                                        {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                        required={false}
                                        key={k}

                                    >
                                        <Row span={12}>
                                            <Col xs={12} sm={12} md={5} lg={5}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Config Name</h6>}
                                                {getFieldDecorator(`name[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].name ? LoopItems[index].name : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'name', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={5} lg={5}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Config price</h6>}
                                                {getFieldDecorator(`price[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].price ? LoopItems[index].price : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'price', e)} />)}
                                            </Col>
                                            <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                {/* {keys.length > 1 ? ( */}
                                                <Icon
                                                    id="icon-view"
                                                    className="dynamic-delete-button"
                                                    type="delete"
                                                    onClick={() => this.onRemoveRow(index)}
                                                    style={{ fontSize: '16px' }}
                                                />
                                                {/* ) : null} */}
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </div>
                            )
                        } finally {
                            first = false;

                        }
                    }
                })
                }
            </div>
        )
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(AddProductConfig);
export default WrappedDynamicFieldSet
