import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'
import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox, Spin, Modal } from 'antd';
import UploadCsv from '../../../components/contact/uploadCsv'

import DynamicFields from './DynamicFields';
import { formatCsv } from '../../../util/ProductCsv'
import ProductCatalog from './ProductCatalog'


let initialData = {
    attributeName: '',
    attributeValue: '',
    required: false,
    attributeType: ''
}

class OrderDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orderDetails: [],
            id: 0,
            deletedKeys: [],
            csvLoading: false,
            templateId: null,
            editProductData: [],
            visible: false,
            tempProductData: [],
            uploadProductImage: false
        }
    };

    componentWillMount() {
        console.log('OrderDetails', this.props)
        if (this.props.editData) {
            this.setState({ orderDetails: this.props.editData })
        } else {
            if (this.state.orderDetails.length === 0) {
                let data = this.state.orderDetails
                data.push(initialData)
                this.setState({ orderDetails: data, loading: false })
            }
        }
        if (this.props.isEdit && this.props.templateId) {
            this.setState({ templateId: this.props.templateId })
        }
    }


    onAddRow = () => {
        let data = this.state.orderDetails
        let initialData = {
            attributeName: '',
            attributeValue: '',
            required: false,
            attributeType: ''
        }

        if(data) {data.push(initialData)}
        this.setState({ orderDetails: data })
    }


    LoopItemsChange(orderDetails) {
        this.setState({ orderDetails }, () => {
            this.props.orderDetailSave(orderDetails);
        })
    }

    onRemove(orderDetails) {
        this.setState({ orderDetails });
    }

    getCsvData = (data) => {
        console.log('order detail csv', data)
        const { templateId } = this.state
        formatCsv(data, templateId)
            .then(val => {
                console.log('getCsvData', val)
                this.setState({
                    productParamsCsv: val.products,
                    categoryParamsCsv: val.categories
                }, () => {
                    console.log(this.state.productParamsCsv, this.state.categoryParamsCsv, 'formtscvs')
                    this.props.csvDetails(this.state.productParamsCsv, this.state.categoryParamsCsv)
                })
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    getProducts = (data) => {
        this.setState({ tempProductData: data }, () => {
            console.log('getProducts', this.state.tempProductData)
        })
    }

    handleOk = () => {
        this.setState({ visible: false }, () => {
            this.getCsvData(this.state.tempProductData)
        })
    }



    removeCsv = () => {
        console.log('removeCsv')
    }


    setLoader = (val) => {
        console.log('setLoader', val)
        this.setState({ csvLoading: val })
    }

    imageUpload = (e) => {
        console.log('imageUpload', e.target.checked)
        this.setState({ uploadProductImage: e.target.checked }, () => {
            this.props.uploadProductImage(e.target.checked)
        })
    }

    render() {
        const { orderDetails, csvLoading, editProductData, uploadProductImage } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        console.log('editProductData', editProductData)

        return (
            <Form
                onSubmit={this.handleSubmit}
            >
                <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Add any order related details and instructions you want to display in the order form
                    </span>
                </div>

                {!this.props.isEdit &&
                    <div style={{ marginTop: '20px' }}>
                        <Spin spinning={csvLoading} >
                            <UploadCsv
                                getData={this.getCsvData}
                                loader={this.setLoader}
                                screen='createTemplate'
                                remove={this.removeCsv}
                            />
                        </Spin>
                    </div>
                }
                <div >
                    <DynamicFields
                        accessFrom='Order'
                        LoopItems={orderDetails}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        isEdit={this.props.isEdit ? true : false}

                    />
                    {/* {formItems} */}
                </div>

                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>


                {/* <Button type="primary" onClick={() => this.setState({ visible: true })}>
                    {this.props && this.props.isEdit ? "Edit Products" : "Add Products"}
                </Button>
                <Modal
                    title={this.props && this.props.isEdit ? "Edit Products" : "Add Products"}
                    width={'95%'}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    okText="Done"
                    onCancel={() => this.setState({ visible: false })}
                > */}

                {!this.props.isEdit &&
                    <div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <h6>Add Product</h6>
                            <Checkbox onChange={(e) => this.imageUpload(e)}>Display product image</Checkbox>
                        </div>
                        <ProductCatalog
                            formatCatalog={this.getCsvData}
                            productImage={uploadProductImage}
                        />
                    </div>
                }
                {/* </Modal> */}
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(OrderDetails);
export default WrappedDynamicFieldSet

