export function saveProducts(productsArray) {
    console.log('productsArray', productsArray)
    let mutationGqlStr = ``;
    let mutationFragmentsGqlStr = ``;
    // get total count
    let totalProducts = productsArray.length;
    let processCompleted = 0;
    let i;
    // process each count
    for (i = 0; i < totalProducts; i++) {
        // get single products
        let productsObj = productsArray[i];

        let descStr = null;
        let codeStr = null;
        let configStr = null;
        let taxesStr = null;
        let productImageStr = null;
        let hideFromCatalog = false;

        if (productsObj.product_description) {
            descStr = `"${productsObj.product_description}"`
        } else {
            descStr = null
        }

        if (productsObj.product_code) {
            codeStr = `"${productsObj.product_code}"`
        } else {
            codeStr = null
        }

        if (productsObj.hide_from_catalog) {
            hideFromCatalog = `"${productsObj.hide_from_catalog}"`
        } else {
            hideFromCatalog = false
        }


        if (productsObj.product_config.length != 0) {
            configStr = `[{
                            name: "${productsObj.product_config[0].name}", 
                            price:  "${productsObj.product_config[0].price}", 
                            max_length:  ${productsObj.product_config[0].max_length}, 
                            min_length:  ${productsObj.product_config[0].min_length}, 
                        }]`
        }

        if (productsObj.product_image !== null) {
            productImageStr = `[{
                name: "${productsObj.product_image[0].name}",
                url: "${productsObj.product_image[0].url}",
                uid: "${productsObj.product_image[0].uid}",
                status: "${productsObj.product_image[0].status}"
            }]`
        }

        if (productsObj.product_taxes.length != 0) {
            taxesStr = `{
                SGST: "${productsObj.product_taxes.SGST}",
                IGST: "${productsObj.product_taxes.IGST}",
                CGST: "${productsObj.product_taxes.CGST}"
            }`
        }


        // dynamic gql
        if (productsObj.product_id == null && productsObj.type == "CREATE") {
            mutationGqlStr = `mutation_${i}: insert_listing_products(objects: {
					category_id: "${productsObj.category_id}",
					listing_id: "${productsObj.listing_id}",
					transaction_template_id: "${productsObj.transaction_template_id}",
					product_name: "${productsObj.product_name}",
					product_description: ${descStr},
					product_hsn_code: ${productsObj.product_hsn_code},
					product_config: ${configStr},
					product_code: ${codeStr},
                    product_taxes: ${taxesStr},
                    product_image: ${productImageStr},
                    product_serial_no: ${productsObj.indexValue}
                }) {
                    returning {
                      ...listing_product_fields
                    }
                }
                `
            processCompleted = processCompleted + 1;
        } else {
            if (productsObj.product_id && productsObj.type == "UPDATE") {
                mutationGqlStr = `mutation_${i}: update_listing_products(where: {
                        product_id: {_eq: "${productsObj.product_id}"}
                    }, _set: {
						product_name: "${productsObj.product_name}",
						product_description: ${descStr},
						product_hsn_code: ${productsObj.product_hsn_code},
						product_config: ${configStr},
						product_code: ${codeStr},
                        product_taxes: ${taxesStr},
                        product_image: ${productImageStr},
                        hide_from_catalog:${hideFromCatalog}
                    }) {
                        returning {
                          ...listing_product_fields
                        }
                    }
                    `
                processCompleted = processCompleted + 1;
            }
            if (productsObj.product_id && productsObj.type == "DELETE") {
                mutationGqlStr = `mutation_${i}: delete_listing_products(where: {
                        product_id: {_eq: "${productsObj.product_id}"}
                    }) {
                        returning {
                          ...listing_product_fields
                        }
                    }
                    `
                processCompleted = processCompleted + 1;
            }
        }
        mutationFragmentsGqlStr = `${mutationFragmentsGqlStr} ${mutationGqlStr} `;
    }
    let finalGqlStr = `mutation {
			${mutationFragmentsGqlStr}
		}
		fragment listing_product_fields on listing_products {
			  category_id
			  listing_id
			  product_id
			  product_name
			  product_description
			  product_hsn_code
			  product_config
			  product_per_unit_price
			  product_taxes
			  product_code
		}
    `;
    console.log('finalGqlStr', finalGqlStr)
    return finalGqlStr;
}