import moment from "moment"

//getting date-month-year format
export const getYearMonthDate = (key: string) => {
    return (
        moment(key)
            .format(yrMonthDateFormat)
    )
}

// Disable past date in antd date picker
export const disabledDate = (current) => {
    // Can not select days before today
    let dateFormat: string = getDate(current)
    let currentDate: Object = moment(dateFormat)
      .add(1, "day")
    let dateVariable: Object = moment()
    return currentDate && currentDate < dateVariable
      .endOf("day")
  }

  //getting Time format
export const getDate = (key: string) => {
    return (
      moment(key)
        .format(mentorSessionDate)
    )
  }

export const yrMonthDateFormat = "YYYY-MM-DD"
export const mentorSessionDate = "MM/DD/YYYY"