import gql from 'graphql-tag';
// user table

//{formData:{_contains:$spec} } , {  }

const TRANSACTION_SUB = gql`subscription transaction($updatedAtFrom:timestamptz , $updatedAtTo:timestamptz ,$linkClicked:numeric ,  $typeOfNotificationIn:[String!] ,$DeliveryStatus:String , $createdAtFrom: timestamptz, $createdAtTo: timestamptz, $filters: jsonb, $statusFilter: String, $lastName: String, $firstName: String, $transactionType: String!, $startNumber: Int, $limit: Int!, $searchValue: String!, $listingId: uuid!, $userId: uuid!) {
  transaction(offset: $startNumber, order_by: {createdAt: desc}, where: { _and: [
    
    {listingId: {_eq: $listingId}}, 
    
    {template: {type: {_eq: $transactionType}}}, 
    
    {_and: [ 
      {_or: [
        {assignedMembers: {assignedBy: {_eq: $userId}}}, 
        {assignedMembers: {assignedTo: {_eq: $userId}}}, 
        {_and: [
          {listingId: {_eq: $listingId}}, 
          {assignedMembers: {assigneeInfo: {listinTeam: {_and: [{userId: {_eq: $userId}}, {role: {_eq: "listingAdmin"}}]}}}}]
        }]
      }
       
      ]
    },
    
    {_and: [ {transactionHistory: {relatedBroadcast: {logs: {transactionUrls: {linkClickCount: {_neq: $linkClicked}}}}}}, 
      {transactionHistory: {status: {_eq: $statusFilter}}}]
    },
    
    {transactionHistory: {typeOfNotification: {_in: $typeOfNotificationIn}}},

    {formData: {_contains: $filters}}, 
    
    {transactionHistory: {typeOfNotification: {_in: $typeOfNotificationIn}}},
    {transactionHistory: {relatedBroadcast: {logs: {deliveryStatus: {_eq: $DeliveryStatus}}}} }
    
    {_and: [ 
      {updatedAt: {_gt: $updatedAtFrom}}, 
      {updatedAt: {_lt: $updatedAtTo}}, 
      {createdAt: {_gt: $createdAtFrom}}, 
      {createdAt: {_lt: $createdAtTo}}]
    },
    {currentStatus: {_eq: $statusFilter}}, 
    
    {_and: [
      {assignedMembers: {assigneeInfo: {firstName: {_eq: $firstName}}}}, 
      {assignedMembers: {assigneeInfo: {lastName: {_eq: $lastName}}}}]
    }, 
    
    {_or: [
      {currentStatus: {_ilike: $searchValue}}, 
      {assignedMembers: {assigneeInfo: {firstName: {_ilike: $searchValue}}}}]
    }, 
    
    ]
  }) {
    contactId
    contactNameOverride
    createdAt
    createdBy
    id
    listingId
    source
    transactionTemplateId
    currentStatus
    assignedTo
    formData
    tags
    template {
      id
      name
      formDefinition
      UISchema
      eligibleStatusList
      displayTemplate
    }
    transactionHistory {
      broadCastId
      typeOfNotification
    }
    urlCreated{
      shortURL
    }
  }
}`;


const _history = gql`subscription history($transactionId: uuid!) {
  transaction_status_history(order_by: { createdAt: desc } where: {transactionId: {_eq: $transactionId}}) {
    status
    relatedBroadcast {
      type
      logs {
        recipientContact
        deliveryStatus
        createdAt
        transactionUrls{
          linkClickCount
          shortURL
        }
      }
    }
  }
}`;

const ASSIGNED_TO = gql`subscription transaction($transactionId:uuid!) {
  transaction_assigned_to(where:{transactionId:{_eq:$transactionId}}){
    assigneeInfo{
      id
      firstName
      lastName
      phoneNumber
      email
    }
  }
}`;

// const TEAM_MEMBERS_EXCEPT_ADMIN = gql`subscription teamMembers($startNumber: Int, $limit: Int!, $searchValue: String!, $listingId: uuid!) {
//   listing_team(limit:$limit , offset:$startNumber, order_by: {userInfo: {firstName: asc}}, where: {_and: [{listingId: {_eq: $listingId}}, {role: {_neq: "listingAdmin"}}, {_or: [{userInfo: {firstName: {_ilike: $searchValue}}}, {userInfo: {lastName: {_ilike: $searchValue}}}, {userInfo: {email: {_ilike: $searchValue}}}, {userInfo: {phoneNumber: {_ilike: $searchValue}}}]}]}) {
//     userInfo {
//       id
//       firstName
//       lastName
//       phoneNumber

//     }
//   }
// }`


const TEAM_MEMBERS_EXCEPT_ADMIN = gql`subscription teamMembers( $listingId: uuid!) {
  listing_team( where: {_and: [{listingId: {_eq: $listingId}}, {role: {_neq: "listingAdmin"}}]}) {
    userInfo {
      id
      firstName
      lastName
      phoneNumber
    }
  }
}
`


const USER_SUB = gql`
    subscription user{
        user {
            id,
            email,
            firstName,
            lastName,
            phoneNumber
        }
    }
`;

const FETCH_FILES = gql`subscription listOfFiles($listingId: uuid!, $searchValue: String!) {
  file_upload(order_by: {createdAt: desc}, where: {_and: [{listingId: {_eq: $listingId}}, {fileName: {_ilike: $searchValue}}]}) {
    fileName
    id
    createdAt
    shortURL{
      shortURL
      broadcastLogId
    }
  }
}
`;

const USER_WITH_PHONE_NUM_SUB = gql`
    subscription user_with_phone_num($phone_number: String!) {
        user(
            where: {
                phoneNumber: {
                    _eq: $phone_number
                }
            }
        ) {
            id
        }
    }
`;

const SMS_RECIPIENTS_SUB = gql`subscription broadcast_logs($broadcastId: uuid!) {
  broadcast_logs(where: {_and:[ {brodcastId: {_eq: $broadcastId}} 
  ] }) {
    id
    firstName
    lastName
    recipientContact
    updatedAt
    deliveryStatus
    transactionUrl
    attachedFileLinks {
      shortURL
      linkClickCount
      files{
        fileName
      }
    }
    transactionUrls(where:{transaction:{}}) {
      linkClickCount
      shortURL
    }
  }
}`;


const SENDER_SUB = gql`subscription getSenderId($listingId:uuid! , $type:String!){
  listing_communication_provider(where: {_and: {listingId: {_eq: $listingId}, type: {_eq: $type}}}){
    communicationScope
  }
}`;

const TEMPLATE_SUB = gql`
subscription($listingId:uuid! , $templateType:String!) {
  template(where: {_and: {listingId: {_eq: $listingId}}, templateType: {_eq: $templateType}}) {
  id
  title
  content
  templateType
  dlt_settings
  }
}`;

const TEMPLATE_SUB_ALL = gql`
subscription($listingId:uuid!, $limit:Int!,$offset:Int!) {
  template(
    order_by: {createdAt: desc},
    limit:$limit,
    offset:$offset,
     where: {_and: {listingId: {_eq: $listingId}}}) {
      content
      title
      id
      templateType
      promOrTrans
      senderId
      createdAt
      dlt_settings
    }
  }`;

const TEMPLATE_SUB_ALL_COUNT = gql`subscription($listingId:uuid!, $limit:Int,$offset:Int) {
    template_aggregate(
      order_by: {createdAt: desc},
      limit:$limit,
      offset:$offset,
       where: {_and: {listingId: {_eq: $listingId}}}) {
       aggregate{
        count
      }
      }
    }`

const USER_WITH_ID_SUB = gql`
    subscription user_with_id($id: uuid!) {
        user(
            where: {
                id: {
                    _eq: $id
                }
            }
        ) {
          firstName
          lastName
          email
          phoneNumber
          id
        }
    }
`;

const DRAFT_SUB = gql`subscription draft_sub($listingId: uuid! , $createdBy : uuid! , $type:String!) {
  broadcast(where: {_and: {listingId:{_eq:$listingId }, isDraft:{ _eq: true } ,type:{ _ilike: $type } ,createdBy : {_eq : $createdBy}}}, order_by: {createdAt: desc}){
   id
   title
   content 
   savedContacts
   createdAt
   type
 }
}`;
// contact table
const CONTACT_SUB = gql`
    subscription contact{
        contact(order_by:{firstName:asc}, limit:4, offset:0) {
            id,
            email,
            firstName,
            lastName,
            phoneNumber,
            notes,
            customAttributes,
            validEmail,
            tags
        }
    }
`;

const CONTACT_WITH_NUMBER_SUB = gql`
subscription contact($startNumber: Int, $limit: Int!, $searchValue: String!, $listingId: uuid!,$status:String!) {
  contact(
    order_by: { firstName: asc }      
    where: {
      _and: [
          { listingId: { _eq: $listingId }, status:{_eq:$status}
          _or: [
              { firstName: { _ilike: $searchValue } }
              { lastName: { _ilike: $searchValue } }
              { email: { _ilike: $searchValue } }
              { phoneNumber: { _ilike: $searchValue } }
              { notes: { _ilike: $searchValue } }
          ]
          }
      ]
    }
    limit: $limit
    offset: $startNumber
  ) {
    id
    email
    firstName
    lastName
    phoneNumber
    notes
    customAttributes
    validPhoneNumer
    validEmail
    internalUserContactId 
    status
    tags
  }
}
`;

const GET_CONTACT_COUNT = gql`subscription contact($startNumber: Int, $limit: Int, $searchValue: String, $listingId: uuid!, $status: String!) {
  contact_aggregate(order_by: {firstName: asc}, 
    where: {_and: [{listingId: {_eq: $listingId}, 
      status: {_eq: $status}, _or: [{firstName: {_ilike: $searchValue}}, {lastName: {_ilike: $searchValue}}, {email: {_ilike: $searchValue}}, {phoneNumber: {_ilike: $searchValue}}, {notes: {_ilike: $searchValue}}]}]}, limit: $limit, offset: $startNumber) {
    aggregate {
      count
    }
  }
}`

const CONTACT_WITH_IDS_SUB = gql`
  subscription contact_with_ids($ids: [uuid!]) {
    contact(
      order_by: { firstName: asc }
      where: { id: { _in: $ids } }
    ) {
      firstName
      lastName
      phoneNumber
      email
      id
      validEmail 
      validPhoneNumer 
      tags
    }
  }
`;

// contact group table
const GROUP_WITH_NUMBER_SUB = gql`
  subscription contact_group($startNumber: Int, $limit: Int!, $searchValue: String!,$listingId:uuid!) {
    contact_group(
      order_by: { createdAt: desc }
      limit: $limit
      offset: $startNumber
      where: {
          _and:[{
            listingId: { _eq: $listingId },
            _or: [{name: {_ilike: $searchValue}}, {description: {_ilike: $searchValue}}]
          }]
      }
    ) {
      id
      name
      description
      listingId
      createdBy
    }
  }
`;

const CONTACT_UPLOAD_URL_SUB = gql`
    subscription contact_upload($listingId:uuid!){
        contact_upload(order_by:{createdAt:asc}, where: {
                    listingId: {
                        _eq: $listingId
                    }
                }
        ) {
            createdAt,
            upload_url,
            fileName,
            uploadComplete,
        }
    }
`;

const LISTING_WITH_NAME_SUB = gql`
    subscription listing_with_name($name:String!){
        listing( where: {
                    name: {
                        _ilike: $name
                    }
                }
        ) {
            id,
        }
    }
`;
const LISTING_WITH_NAMEURL_SUB = gql`
subscription listing_with_name($name:String!){
    listing_url( where: {
                urlName: {
                    _ilike: $name
                }
            }
    ) {
        listingId,
    }
}
`

// contact_group_rel table
const RELATIVE_CONTACT_IDS_SUB = gql`
    subscription contact_group_rel($group_id:uuid!){
        contact_group_rel( where: {
                    group_id: {
                        _eq: $group_id
                    }
                }
        ) {
            contact_id,
            contact{
                id
                firstName,
                lastName,
                email,
                phoneNumber
            }
           
        }
    }
`;

//  sms table
const SMS_SUB = gql`
subscription sms($listingId:uuid!){
    sms(order_by :{createdAt : desc} , where: {
                listingId: {
                    _eq: $listingId
                },
    }
    ) {
        id
        title
        footer
        details
        createdAt            
    }
}
`;


const EMAIL_SUB = gql`
subscription email($listingId:uuid!, $createdBy :uuid!){
  email(order_by :{createdAt : desc} , where: {
              listingId: {
                  _eq: $listingId
              },
              createdBy : {
                _eq : $createdBy
              }
          }
  ) {
      id
      title
      footer
      details   
      createdAt        
  }
}
`;

// const EMAIL_BROADCAST = gql``;

const BROADCAST_SUB = gql`subscription broadcast_sub($limit:Int!,$offset:Int!,$listingId: uuid!, $type: String!) {
  broadcast(
    order_by: {createdAt: desc},
    limit:$limit
    offset:$offset
    where: {
      _and: {
        listingId: {_eq: $listingId}, 
        isDraft: {_eq: false}, 
        type: {_ilike: $type}
      }
    }) {
    id
    title
    content
    createdAt
    deliveryList
    savedContacts
    isDraft
    tags
    selectedCommunicationOption
    logs{
      attachedFileLinks {
     shortURL
     linkClickCount
     files{
       fileName
     }
   }
   }
  }
}`;

const BROADCAST_SUB_COUNT = gql`subscription broadcast_sub($limit:Int,$offset:Int,$listingId: uuid!, $type: String!) {
  broadcast_aggregate(
    order_by: {createdAt: desc},
    limit:$limit
    offset:$offset
    where: {
      _and: {
        listingId: {_eq: $listingId}, 
        isDraft: {_eq: false}, 
        type: {_ilike: $type}
      }
    }) {
    aggregate {
      count
    }
  }
}`

const RECEIVED_MESSAGES = gql`subscription broadcast($type: String!, $id: [uuid!]) {
  broadcast(order_by: {createdAt: desc}, where: {logs: {type: {_eq: $type}, contactId: {_in: $id}}}) {
    id
    title
    content
    createdAt
    deliveryList
    savedContacts
    tags
    isDraft
    selectedCommunicationOption
  }
}`

const RECEIVED_MESSAGES_COUNT = gql`subscription broadcast($type: String!, $id: [uuid!]) {
  broadcast_aggregate(where: {logs: {type: {_eq: $type}, contactId: {_in: $id}}}) {
    aggregate{
      count
    }
  }
}`

const GET_STATUS_COUNT = gql`query get_transaction_filter_by_params_aggregate($p_data: json) {
  get_transaction_filter_by_params_aggregate(args: {p_data: $p_data}) {
    aggregate {
      count
    }
  }
}`


function GET_NOTIFY_LIST(params) {
  console.log('GET_NOTIFY_LIST', params)
  let offsetParams = ``;
  let limitParams = ``;

  let orderByParams = `order_by: {createdAt: desc}`;

  let filterParams = ` where: {
      _and: [ 
        { listingId: { _eq: "${params.listingId}" },
          type : { _eq: "${params.type}" } ,
          isDraft: {_eq: false}, 
       }
      ]
    } `;

  //limit
  if (params.hasOwnProperty('limit')) {
    limitParams = `limit: ${params.limit}`
  } else {
    limitParams = ``
  }

  // Offset
  if (params.hasOwnProperty('startNumber')) {
    offsetParams = `offset: ${params.offset}`
  } else {
    offsetParams = ``
  }

  let gqlStr = `subscription broadcast {
    broadcast(
      ${filterParams}
      ${orderByParams}
      ${limitParams}
      ${offsetParams}
     ) {
      id
      title
      content
      createdAt
      deliveryList
      savedContacts
      isDraft
      selectedCommunicationOption
    }
  }`

  return gqlStr;

}

function TRANSACTION_LIST_WITH_SUBS(params) {
  console.log(params, 'prams', params.hasOwnProperty('status'))
  let filterParams = ``;
  let orderByParams = ``;
  let offsetParams = ``;
  let limitParams = ``;
  //
  let filterStr = ``;
  // Filter by listing Id
  if (params.hasOwnProperty('listingId')) {
    filterStr = `
        { listingId: { _eq: "${params.listingId}" } }
    `;
  }
  // Filter by type
  if (params.hasOwnProperty('transactionType')) {
    filterStr = `
        { template: {type:{ _eq: "${params.transactionType}" } } }
    `;
  }
  // Filter ids
  if (params.hasOwnProperty('role')) {
    if (params.role === 'listingMember') {
      filterStr = ` {
          _and: [
            {
              _or: [
                { assignedMembers: { assignedBy: { _eq: "${params.userId}"  } } }
                { assignedMembers: { assignedTo: { _eq: "${params.userId}"  } } }
                {
                  _and: [
                    { listingId: { _eq: "${params.listingId}" } }
                    {
                      assignedMembers: {
                        assigneeInfo: {
                          listinTeam: {
                            _and: [
                              { userId: { _eq: "${params.userId}" } }
                              { role: { _eq: "listingAdmin" } }
                            ]
                          }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      `;
    }
  }
  if (params.hasOwnProperty('status') && params.status) {
    filterStr = `
    { currentStatus: { _eq: "${params.status}" } } 
`;
  }
  if (params.hasOwnProperty('transactionActiveStatus') && params.transactionActiveStatus) {
    filterStr = filterStr + `
    { status: { _eq: "${params.transactionActiveStatus}" } } 
`;
  }
  if (params.hasOwnProperty('transactionTemplateId')) {
    console.log(params, 'params')
    filterStr = filterStr + `
         { transactionTemplateId:{_eq: "${params.transactionTemplateId}"}
           }`;
  }
  if (filterStr !== '') {
    filterParams = ` where: {
      _and: [ 
          ${filterStr}
      ]
    } `
  }
  // OrderBy
  if ((params.hasOwnProperty('orderBy')) && (params.hasOwnProperty('orderByDirection'))) {
    orderByParams = `orderBy: { ${params.orderBy} : ${params.orderByDirection} }`
  } else {
    orderByParams = `order_by: { updatedAt: desc }`
  }
  // limit
  if (params.hasOwnProperty('limit')) {
    limitParams = `limit: ${params.limit}`
  } else {
    limitParams = `limit: 10`
  }
  // Offset
  if (params.hasOwnProperty('startNumber')) {
    offsetParams = `offset: ${params.startNumber}`
  } else {
    offsetParams = `offset: 0`
  }

  let gqlStr = `query transaction {
    transaction(
        ${filterParams}
        ${orderByParams}
        ${limitParams}
        ${offsetParams}
      ){
        contactId
        contactNameOverride
        createdAt
        createdBy
        updatedAt
        id
        listingId
        source
        transactionTemplateId
        currentStatus
        assignedTo
        formData
        tags
        attachmentUrls
        signedIP 
        type
        template {
          id
          name
          formDefinition
          UISchema
          eligibleStatusList
          displayTemplate
          signedStatus
          displayTemplateType 
          templateSettings
        }
        urlCreated {
          shortURL
        }
        contactRelated {
          phoneNumber
          email
          firstName
          lastName
          notes
          internalUserContactId
        }
        assignedMembers{
          assigneeInfo{
            firstName
            lastName
            email
            phoneNumber
          }
        }
      }
    }`;
  return gqlStr;
}

function TRANSACTION_LIST_COUNT_WITH_SUBS(params) {
  console.log(params, 'prams', params.hasOwnProperty('status'))
  let filterParams = ``;
  let orderByParams = ``;
  let offsetParams = ``;
  let limitParams = ``;
  //
  let filterStr = ``;
  // Filter by listing Id
  if (params.hasOwnProperty('listingId')) {
    filterStr = `
        { listingId: { _eq: "${params.listingId}" } }
    `;
  }
  // Filter by type
  if (params.hasOwnProperty('transactionType')) {
    filterStr = `
        { template: {type:{ _eq: "${params.transactionType}" } } }
    `;
  }
  // Filter ids
  if (params.hasOwnProperty('role')) {
    if (params.role === 'listingMember') {
      filterStr = ` {
          _and: [
            {
              _or: [
                { assignedMembers: { assignedBy: { _eq: "${params.userId}"  } } }
                { assignedMembers: { assignedTo: { _eq: "${params.userId}"  } } }
                {
                  _and: [
                    { listingId: { _eq: "${params.listingId}" } }
                    {
                      assignedMembers: {
                        assigneeInfo: {
                          listinTeam: {
                            _and: [
                              { userId: { _eq: "${params.userId}" } }
                              { role: { _eq: "listingAdmin" } }
                            ]
                          }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      `;
    }
  }
  if (params.hasOwnProperty('status') && params.status) {
    console.log("filterStr123 status", params.status)
    filterStr = `
    { currentStatus: { _eq: "${params.status}" } } 
`;
  }

  if (params.hasOwnProperty('transactionActiveStatus') && params.transactionActiveStatus) {
    filterStr = filterStr + ` 
    { status: { _eq: "${params.transactionActiveStatus}" } } 
`;
  }

  if (params.hasOwnProperty('transactionTemplateId')) {
    console.log(params, 'params')
    filterStr = filterStr + `
         { transactionTemplateId:{_eq: "${params.transactionTemplateId}"}
           }`;
  }
  if (filterStr !== '') {
    filterParams = ` where: {
      _and: [ 
          ${filterStr}
      ]
    } `
  }

  console.log("filterStr123", filterParams)
  // OrderBy
  if ((params.hasOwnProperty('orderBy')) && (params.hasOwnProperty('orderByDirection'))) {
    orderByParams = `orderBy: { ${params.orderBy} : ${params.orderByDirection} }`
  } else {
    orderByParams = `order_by: { updatedAt: desc }`
  }
  // limit
  // if (params.hasOwnProperty('limit')) {
  //   limitParams = `limit: ${params.limit}`
  // } else {
  //   limitParams = `limit: 10`
  // }
  // // Offset
  // if (params.hasOwnProperty('startNumber')) {
  //   offsetParams = `offset: ${params.startNumber}`
  // } else {
  //   offsetParams = `offset: 0`
  // }
  let gqlStr = `query transaction_aggregate {
    transaction_aggregate(
        ${filterParams}
        ${orderByParams}
        ${limitParams}
        ${offsetParams}
      ){
        aggregate {
			count
		}
      }
    }`;
  return gqlStr;
}

const CONTACT_LIST_BY_LISTING_ID = gql`
subscription contact($startNumber: Int, $limit: Int, $listingId: uuid!) {
  contact(order_by: {firstName: asc}, where: {_and: {listingId: {_eq: $listingId}}}, limit: $limit,offset: $startNumber) {
    id
    email
    firstName
    lastName
    phoneNumber
    notes
    customAttributes
    validPhoneNumer
    validEmail
  }
}
`;

const CONTACT_LIST_EMAIL = gql`
subscription contact($startNumber: Int, $limit: Int, $listingId: uuid!) {
  contact(order_by: {firstName: asc}, where: {_and: {listingId: {_eq: $listingId}, email: {_neq: ""}}}, limit: $limit, offset: $startNumber) {
    id
    email
    firstName
    lastName
    phoneNumber
    notes
    customAttributes
    validPhoneNumer
    validEmail
  }
}
`;

const CONTACT_LIST_NUMBER = gql`
subscription contact($startNumber: Int, $limit: Int, $listingId: uuid!) {
  contact(order_by: {firstName: asc}, where: {_and: {listingId: {_eq: $listingId}, phoneNumber: {_neq: ""}}}, limit: $limit, offset: $startNumber) {
    id
    email
    firstName
    lastName
    phoneNumber
    notes
    customAttributes
    validPhoneNumer
    validEmail
  }
}
`;

const GET_TEMPLATE_STATUS = gql`subscription transaction_template_status($transactionTemplateId: uuid!) {
  transaction_template_status(where: {transactionTemplateId: {_eq:$transactionTemplateId}}) {
    id
    statusName
    statusDesc
    isDefaultYn
    transactionTemplateId
    isChangeable
  }
}`

const GET_COUNT_STATUS = gql`subscription list_transaction_template_status($transactionTemplateId: uuid!) {
  list_transaction_template_status(where: {transactionTemplateId: {_eq: $transactionTemplateId}}) {
    id
    statusName
    statusDesc
    isDefaultYn
    transactionTemplateId
    total_count
  }
}`

const GET_TEMPLATE_RULES_BY_TEMPLATE_ID = gql`subscription transaction_template_rules($transactionTemplateId: uuid!) {
  transaction_template_rules(where: {transactionTemplateId: {_eq:$transactionTemplateId}}) {
    id
    notificationChannel
    status
  transactionTemplateId
  }
}`

const GET_TEMPLATES_BY_LISTING_ID = gql`subscription get_templates($listingId:uuid!){
  template(where:{listingId:{_eq:$listingId}}){   
  id
  title
  content
  templateType
  }
  }`

const GET_TEMPLATES_BY_ID = gql`subscription get_templates($id:uuid!){
    template(where:{id:{_eq:$id}}){   
    id
    title
    content
    templateType
    promOrTrans
    senderId
    dlt_settings 
    }
    }`





const GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID = gql`subscription get_transaction_template($id:uuid!){
  transaction_template(where:{id:{_eq:$id}}){
  name
  id
  listingId
  type
  createdAt
  eligibleStatusList
  formDefinition
  UISchema
  displayTemplate
  owner
  createdBy
  signedStatus
  templateSettings
  templatepublicurl
  displayTemplateType
  displayShop
  shorturlForDeeplink
    }
  }`;

// const CONTACT_LIST_BY_LISTING_ID = gql`
// subscription contact($startNumber: Int, $limit: Int!, $listingId: uuid!) {
//   contact(order_by: {firstName: asc}, where: {_and: {listingId: {_eq: $listingId},
//      phoneNumber: {_in: ["9677578589", "8681948656", "6382147430", "9489639819", "7373879779","+14083329537","+14085071636"]}}}, 
//      limit: $limit, offset: $startNumber) {
//     id
//     email
//     firstName
//     lastName
//     phoneNumber
//     notes
//     customAttributes
//     validPhoneNumer
//     validEmail
//   }
// }
// `;


/*

*/


const USERID_TO_GET_LISTING = gql`subscription listingId($userId: uuid!) {
  listing_team(where: {userId : {_eq: $userId}}) {
    role
    listing {
      name
      status
      listing_urls {
        urlName
        listingId
      }
      businessContact {
        id
        name
      }
      listing_img
    }
  }
}`;

const TAB_NAMES = gql`subscription transaction_ui($listingId:uuid!){
  transaction_ui(where:{listingId:{_eq:$listingId}}){
    tabName
    transactionType
  }
}`;

const FORM_TEMPLATE = gql`subscription get_transaction_form($listingId:uuid!,$transactionTemplateStatus:String!){
  transaction_template(
    where: {_and: {listingId: {_eq: $listingId}transactionTemplateStatus:{_eq:$transactionTemplateStatus}}}
    order_by:{createdAt:desc_nulls_last}
  ) {
    formUrl
    id
    name
    sharedWithListings
    type
    formDefinition
    eligibleStatusList
    transactionTemplateStatus
    signedStatus
    templateSettings
    templatepublicurl
  }
}`;

const GET_LISTINGDETAILS = gql`subscription listing($listingId:uuid!){
  listing(where:{
    id:{
      _eq:$listingId
    }
  }){
      id
    listing_img
    name
    description
    phoneNumber
    address
    streetName
    address1
    address2
    city
    state 
    country 
    postalCode
    createdBy
    type 
    listing_teams_aggregate{
      aggregate{
        count
      }
    }
    listing_teams{
      id
      phoneNumber
      role
      createdAt
      userInfo{
        id
        firstName
        lastName
        email
        phoneNumber
      }
    }
    listing_urls {
      urlName
    }
    listing_communication_providers{
      communicationProviderId 
      communicationScope
      type 
      apiCredentials 
      ID
    }
  }
}
`;

const TRANSACTION_DATA_BY_SHORT_URL = gql`subscription reactUrl($shortUrl: String!) {
  short_url(where: {shortURL: {_eq: $shortUrl}}) {
    id
    shortURL
    transactionId
    TransactiondisplayName
    linkClickCount
    pdfURL
    transaction {
      createdAt
      updatedAt 
      id
      transactionTemplateId
      attachmentUrls
      formData
      signedIP
      currentStatus
      contactRelated {
        phoneNumber
      }
      recipientBusinessContact{
        name
      }
      shipmentStatus {
        TrackResponse
      }
      transactionTemplate {
        id
        displayTemplate
        signedStatus
        templateSettings 
        type
        displayTemplateType
        templatepublicurl 
        shorturlForDeeplink
        listing {
          id
          name
          listing_urls {
            urlName
          }
        }
      }
    }
  }
}`;

const GET_SHORT_URL_BY_TRANSACTION_ID = gql`subscription reactUrl($transactionId: uuid!) {
  short_url(where: {transactionId: {_eq: $transactionId}}) {
    id
    shortURL
    transactionId
    }
  }`;

const GET_COMMUNICATION_PROVIDER = gql`subscription provider{
  communication_provider {
    deliveryStatusApiEndpoints
    id
    providerName
    sendApiEndpoints
    type
  }
}`;

const GET_BUSINESS_CONTACT_LIST = gql`subscription business_contact($startNumber: Int!, $limit: Int!, $status: String!,$listingId:uuid!) {
  business_contact(order_by:{createdAt:desc} where: {_and: {status: {_eq: $status}, listingId: {_eq: $listingId}}}, limit: $limit, offset: $startNumber){
    id
    city
    country
    createdAt
    defaultUserContactId
    description
    internalBusinessContactId
    listingId
    mappedListingId
    name
    notes
    postalCode
    state
    status
    streetName
    address1 
    address2 
    tags
    contactId{
      businessContactId
      firstName
      email 
      phoneNumber 
    }
  }
}`;

const GET_BUSINESS_CONTACT_COUNT = gql`subscription business_contact($startNumber: Int, $limit: Int, $status: String!,$listingId:uuid!) {
  business_contact_aggregate(order_by:{createdAt:desc} where: {_and: {status: {_eq: $status}, listingId: {_eq: $listingId}}}, limit: $limit, offset: $startNumber){
aggregate {
      count
    }
  }
}`

const GET_BUSINESS_CONTACT_ID = gql`query contact($id: uuid!) {
  business_contact(where: { id: {_eq: $id}}) {
    id
    city
    country
    createdAt
    defaultUserContactId
    description
    internalBusinessContactId
    listingId
    mappedListingId
    name
    notes
    postalCode
    state
    status
    tags
    streetName
    address1
    address2
    businessEmail
    businessPhoneNumber
    mappedListing{
      name
    }
    contactId{
      firstName
      phoneNumber
      email
    }
  }
}`;

const TRANSACTION_DETAIL_ID = gql`subscription transaction($transactionId: uuid!) {
  transaction(where: {id: {_eq: $transactionId}}) {
    id
    formData
    }
  }`;

const FILTER_LOGS_BY_NAME = gql`subscription filter($tableName: String!) {
  filter_logs(where: {tableName : {_eq: $tableName}}) {
    filterId 
    name 
    description 
    tableName 
    filterCriteria 
    listingId 
    createdAt 
    createdBy 
    }
  }`;

const FILTER_MASTER_DATA = gql`subscription transaction_ui($tableName:String!,$listingId:uuid,$templateId:uuid){
  filter_master(where:{_and:[{listingId:{_eq:$listingId}},{tableName:{_eq:$tableName}},{templateId:{_eq:$templateId}}]}){
    filterId 
    name 
    description 
    tableName 
    filterCriteria 
    listingId 
    createdAt 
    createdBy 
  }
}`;

const LISTING_TEAM_MEMBERS = gql`subscription teamMembers( $listingId: uuid!) {
    listing_team( where: {_and: [{listingId: {_eq: $listingId}}]}) {
      userInfo {
        id
        firstName
        lastName
        phoneNumber
      }
    }
  }
  `
const GET_SAVED_FILTER_DATA_BY_IDS = gql`query list_filter_history_by_ids($listingId:uuid!,$tableName:String!,$refId:uuid,
  $userContactId: uuid,$businessContactId: uuid,){
  filter_history(where:{_and:[{listingId:{_eq:$listingId}},{tableName:{_eq:$tableName}},{refId:{_eq:$refId}}
    {userContactId: {_eq: $userContactId}},{businessContactId: {_eq: $businessContactId}}]}){
    filterData
    filterHistId
    filterName
    listingId
    refId
    tableName
    createdAt
    createdBy
  }
}
  `
const GET_SAVED_FILTER_DATA_BY_IDS_COUNT = gql`query list_filter_history_by_ids($listingId: uuid!, $tableName: String!, $refId: uuid,
    $userContactId: uuid,$businessContactId: uuid,) {
    list_filter_history(where: {_and: [{listingId: {_eq: $listingId}}, {tableName: {_eq: $tableName}}, {refId: {_eq: $refId}}
      {userContactId: {_eq: $userContactId}},{businessContactId: {_eq: $businessContactId}} ]}) {
      filterData
      filterHistId
      filterName
      listingId
      refId
      tableName
      createdAt
      createdBy
      total_count
    }
  }
    `

const GET_FILTER_DATA_BY_IDS = gql`subscription list_filter_history($filterHistId:uuid!){
  filter_history(where:{filterHistId:{_eq:$filterHistId}}){
    filterData
    filterHistId
    filterName
    listingId
    refId
    tableName
    createdAt
    createdBy
  }
}
  `

const CHECK_CONTRACT_ASSIGNED = gql`subscription transaction($transactionId: uuid!, $userId: uuid) {
    transaction_assigned_to(where: {transactionId: {_eq: $transactionId}  _and: {assignedTo: {_eq: $userId }}}) {
      assignId
    }
  }`;

const GET_RECEIVED_CONTRACT_LISTING = gql`subscription get_received_listing_by_params($p_id: String, $p_type: String, $p_view_type: String) {
  get_received_listing_by_params(args: {p_id: $p_id, p_type: $p_type, p_view_type: $p_view_type}) {
    address
    address1
    address2
    city
    country
    description
    id
    listingClaimed
    listingCreationBySource
    listingCreationBySourceListingId
    name
    listing_urls {
      urlName
    }
  }
}`;

const GET_RECEIVED_TRANSACTIONS_BUSINESS = gql`subscription transaction_list($startNumber: Int, $limit: Int!,$listingId:uuid!,$recipientBusinessContactId:uuid!, $type: String!){
  list_received_transactions(      limit: $limit
      offset: $startNumber where:{listingId:{_eq:$listingId}recipientBusinessContactId:{_eq:$recipientBusinessContactId}type:{_eq:$type}}){
  formData
  id 
  createdAt 
  tags 
  currentStatus 
  }
}`

const GET_RECEIVED_TRANSACTIONS_CONTACTS = gql`subscription transaction_list_by_individual_contact($startNumber: Int, $limit: Int!, $listingId: uuid!, $recipientUserContactId: [uuid!], $type: String!) {
  list_received_transactions(limit: $limit, offset: $startNumber, where: {listingId: {_eq: $listingId}, type: {_eq: $type}, recipientUserContactId: {_in: $recipientUserContactId}}) {
    formData
    id
    createdAt
    tags
    currentStatus
  }
}`

const RECEIVED_CONTRACT_BUSINESS_COUNT = gql`subscription transaction_list($listingId:uuid!,$recipientBusinessContactId:uuid!, $type: String!){
  list_received_transactions_aggregate(where:{listingId:{_eq:$listingId}recipientBusinessContactId:{_eq:$recipientBusinessContactId}type: {_eq: $type}}){
 aggregate{
  count
   }
  }
}`

const RECEIVED_CONTRACT_CONTACT_COUNT = gql`subscription transaction_list_by_individual_contact($listingId: uuid!, $recipientUserContactId: [uuid!], $type: String!) {
  list_received_transactions_aggregate(where: {listingId: {_eq: $listingId}, type: {_eq: $type}, recipientUserContactId: {_in: $recipientUserContactId}}) {
   aggregate{
  count
   }
  }
}`

const RECEIVED_CONTRACT_TEMPLATE_FILTER = gql`subscription get_received_transaction_templates($pData: json) {
  get_received_transaction_templates(args: {p_data: $pData}) {
    id
    name
    listingId
  }
}`


const FETCH_TAGS_HISTORY = gql`subscription fetch_tag_history($listingId: uuid!, $tableName: String, $refId: uuid,
  $userContactId: uuid, $businessContactId: uuid) {
  list_tags_history(where: {listingId: {_eq: $listingId}, _and: [{tableName: {_eq: $tableName}}, {refId: {_eq: $refId}},
    {userContactId: {_eq: $userContactId}},{businessContactId: {_eq: $businessContactId}}]}) {
    tagData
    tagName
    tagHistId
    refId
    total_count
  }
}`

const GET_LEAD_TRANSACTION_DATA = gql`query transaction($startNumber: Int, $limit: Int!,$type:String,$createdBy:uuid,$transactionTemplateId:uuid){
  transaction(order_by:{createdAt:desc} limit: $limit
      offset: $startNumber where:{_and:[{type:{_eq:$type}},{createdBy:{_eq:$createdBy}},{transactionTemplateId:{_eq:$transactionTemplateId}}]}){
  id 
  }
}`

const GET_SHORT_URL_SUBS = gql`subscription transaction_list($transactionId:uuid!){
  short_url(where:{transactionId:{_eq:$transactionId}}){
shortURL
  }
}`


function RECEIVED_TRANSACTION_LIST_WITH_SUBS(params) {
  let filterParams = ``;
  let orderByParams = ``;
  let offsetParams = ``;
  let limitParams = ``;

  let filterStr = ``;

  // Filter by listing Id
  if (params.hasOwnProperty('listingId')) {
    filterStr = filterStr + `
      listingId: { _eq: "${params.listingId}" }
    `;
  }

  // Filter by type
  if (params.hasOwnProperty('type')) {
    filterStr = filterStr + `
      type: { _eq: "${params.type}" }
    `;
  }

  // Filter by transaction templateId
  if (params.hasOwnProperty('transactionTemplateId')) {
    filterStr = filterStr + `
      transactionTemplateId:{_eq: "${params.transactionTemplateId}" } 
    `;
  }

  // Filter by recipientUserContactId
  let ids = ``;
  if (params.hasOwnProperty('recipientUserContactId')) {
    for (var i = 0; i < params.recipientUserContactId.length; i++) {
      ids = ids + `"${params.recipientUserContactId[i]}",`;
    }
    console.log('ids', ids)
    filterStr = filterStr + `
      recipientUserContactId: {_in: [${ids}]}
    `;
  }

  // Filter by recipientBusinessContactId
  if (params.hasOwnProperty('recipientBusinessContactId')) {
    filterStr = filterStr + `
      recipientBusinessContactId:{_eq: "${params.recipientBusinessContactId}" }
    `;
  }

  if (filterStr !== '') {
    filterParams = ` where: {
      ${filterStr}
    } `
  }

  // OrderBy
  if ((params.hasOwnProperty('orderBy')) && (params.hasOwnProperty('orderByDirection'))) {
    orderByParams = `orderBy: { ${params.orderBy} : ${params.orderByDirection} }`
  } else {
    orderByParams = `order_by: { updatedAt: desc }`
  }

  // limit
  if (params.hasOwnProperty('limit')) {
    limitParams = `limit: ${params.limit}`
  } else {
    limitParams = `limit: 10`
  }

  // // Offset
  if (params.hasOwnProperty('startNumber')) {
    offsetParams = `offset: ${params.startNumber}`
  } else {
    offsetParams = `offset: 0`
  }

  let gqlStr = `query list_received_transactions {
    list_received_transactions(
        ${filterParams}
        ${orderByParams}
        ${limitParams}
        ${offsetParams}
      ){
        formData
        id
        createdAt
        tags
        currentStatus
      }
    }`;
  return gqlStr;
}

function RECEIVED_TRANSACTION_LIST_COUNT_WITH_SUBS(params) {

  let filterParams = ``;
  let orderByParams = ``;
  let offsetParams = ``;
  let limitParams = ``;

  let filterStr = ``;

  // Filter by listing Id
  if (params.hasOwnProperty('listingId')) {
    filterStr = filterStr + `
      listingId: { _eq: "${params.listingId}" }
    `;
  }

  // Filter by type
  if (params.hasOwnProperty('type')) {
    filterStr = filterStr + `
      type: { _eq: "${params.type}" }
    `;
  }

  // Filter by transaction templateId
  if (params.hasOwnProperty('transactionTemplateId')) {
    filterStr = filterStr + `
      transactionTemplateId:{_eq: "${params.transactionTemplateId}" } 
    `;
  }

  // Filter by recipientUserContactId
  let ids = ``;
  if (params.hasOwnProperty('recipientUserContactId')) {
    for (var i = 0; i < params.recipientUserContactId.length; i++) {
      ids = ids + `"${params.recipientUserContactId[i]}",`;
    }
    filterStr = filterStr + `
      recipientUserContactId: {_in: [${ids}]}
    `;
  }

  // Filter by recipientBusinessContactId
  if (params.hasOwnProperty('recipientBusinessContactId')) {
    filterStr = filterStr + `
      recipientBusinessContactId:{_eq: "${params.recipientBusinessContactId}" }
    `;
  }

  if (filterStr !== '') {
    filterParams = ` where: {
      ${filterStr}
    } `
  }

  // OrderBy
  if ((params.hasOwnProperty('orderBy')) && (params.hasOwnProperty('orderByDirection'))) {
    orderByParams = `orderBy: { ${params.orderBy} : ${params.orderByDirection} }`
  } else {
    orderByParams = `order_by: { updatedAt: desc }`
  }

  let gqlStr = `query list_received_transactions_aggregate {
    list_received_transactions_aggregate(
        ${filterParams}
        ${orderByParams}
        ${limitParams}
        ${offsetParams}
      ){
        aggregate{
          count
        }
      }
    }`;

  return gqlStr;
}

const GET_LISTING_PRODUCTS_CATEGORIES_SUB = gql`subscription listing_product_categories($listingId: uuid,, $templateId: uuid) {
  listing_product_categories(where: {listing_id: {_eq: $listingId}, transaction_template_id : {_eq: $templateId}}) {
    category_id
    category_name
    listing_id
    listing_products {
      category_id
      listing_id
      product_id
      product_name
      product_description
      product_hsn_code
      product_image
      product_config
      product_per_unit_price
      product_taxes
      product_code
    }
  }
}`

const GET_LISTING_PRODUCT_SUB = gql`subscription listing_products($limit:Int,$offset:Int,$listingId: uuid,, $templateId: uuid) {
  listing_products(order_by: {product_serial_no: asc}, limit:$limit,offset:$offset,where: {listing_id: {_eq: $listingId}, transaction_template_id : {_eq: $templateId}}) {
      category_id
      listing_id
      product_id
      product_name
      product_description
      product_hsn_code
      product_config
      product_per_unit_price
      product_taxes
      product_code
      product_image
      product_serial_no
      hide_from_catalog
      listing_product_category{
        category_id
        category_name
        category_serial_no
      }
    }
  }`

const GET_LISTING_PRODUCT_COUNT = gql`subscription listing_products($listingId: uuid,, $templateId: uuid) {
  listing_products_aggregate(where: {listing_id: {_eq: $listingId}, transaction_template_id : {_eq: $templateId}}) {
  aggregate{
        count
      }
    }
  }`

const GET_CONTACT_BY_ID = gql`subscription contact($id: uuid,) {
  contact(where: {id: {_eq: $id}}) {
        email
        phoneNumber
    }
  }`

  const GET_CATAGORIES_BY_TEMPLATE_ID_SUB = gql`subscription product_categories($listingId: uuid, $templateId: uuid) {
    product_categories(order_by: {category_sequence_no: asc},where: {listing_id: {_eq: $listingId}, transaction_template_id: {_eq: $templateId}}) {
      product_category_id
      listing_id
      product_category_name
      transaction_template_id
      category_image
      category_discount
      product_masters_aggregate {
        aggregate {
          count(columns: product_category_id)
        }
      }
      product_subcategories{
        product_subcategory_id
        product_subcategory_name
      }
    }
  }`

export {

  RECEIVED_TRANSACTION_LIST_WITH_SUBS,
  RECEIVED_TRANSACTION_LIST_COUNT_WITH_SUBS,

  GET_RECEIVED_CONTRACT_LISTING,
  GET_RECEIVED_TRANSACTIONS_BUSINESS,
  GET_RECEIVED_TRANSACTIONS_CONTACTS,
  RECEIVED_CONTRACT_TEMPLATE_FILTER,

  RECEIVED_MESSAGES,

  GET_BUSINESS_CONTACT_LIST,
  GET_BUSINESS_CONTACT_ID,


  GET_TEMPLATE_RULES_BY_TEMPLATE_ID,
  FORM_TEMPLATE,
  USER_SUB,
  USER_WITH_PHONE_NUM_SUB,
  BROADCAST_SUB,
  USER_WITH_ID_SUB,
  GET_CONTACT_BY_ID,
  //contact table
  CONTACT_SUB,
  CONTACT_WITH_NUMBER_SUB,
  CONTACT_WITH_IDS_SUB,
  //contact_upload table
  CONTACT_UPLOAD_URL_SUB,
  SMS_RECIPIENTS_SUB,
  //listing table
  LISTING_WITH_NAME_SUB,
  //contact_group table
  GROUP_WITH_NUMBER_SUB,
  //contact_group_rel table
  RELATIVE_CONTACT_IDS_SUB,
  TEMPLATE_SUB_ALL,
  TEMPLATE_SUB,
  DRAFT_SUB,
  LISTING_WITH_NAMEURL_SUB,
  SENDER_SUB,
  //sms table
  SMS_SUB,
  EMAIL_SUB,
  ASSIGNED_TO,
  TRANSACTION_SUB,
  TRANSACTION_LIST_COUNT_WITH_SUBS,
  TRANSACTION_LIST_WITH_SUBS,
  FETCH_FILES,
  TEAM_MEMBERS_EXCEPT_ADMIN,
  _history,
  CONTACT_LIST_BY_LISTING_ID,
  //listing table
  GET_LISTINGDETAILS,
  USERID_TO_GET_LISTING,
  //transaction ui
  TAB_NAMES,
  TRANSACTION_DATA_BY_SHORT_URL,
  GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID,
  GET_TEMPLATE_STATUS,
  GET_TEMPLATES_BY_LISTING_ID,
  GET_TEMPLATES_BY_ID,
  GET_SHORT_URL_BY_TRANSACTION_ID,
  CONTACT_LIST_EMAIL,
  CONTACT_LIST_NUMBER,
  GET_COMMUNICATION_PROVIDER,
  TRANSACTION_DETAIL_ID,
  FILTER_LOGS_BY_NAME,
  LISTING_TEAM_MEMBERS,
  GET_SAVED_FILTER_DATA_BY_IDS,
  GET_FILTER_DATA_BY_IDS,
  GET_NOTIFY_LIST,
  CHECK_CONTRACT_ASSIGNED,
  FILTER_MASTER_DATA,

  GET_CONTACT_COUNT,
  GET_COUNT_STATUS,
  GET_STATUS_COUNT,
  GET_BUSINESS_CONTACT_COUNT,
  BROADCAST_SUB_COUNT,
  TEMPLATE_SUB_ALL_COUNT,
  RECEIVED_MESSAGES_COUNT,
  RECEIVED_CONTRACT_BUSINESS_COUNT,
  RECEIVED_CONTRACT_CONTACT_COUNT,
  GET_SAVED_FILTER_DATA_BY_IDS_COUNT,
  FETCH_TAGS_HISTORY,
  GET_LEAD_TRANSACTION_DATA,
  GET_SHORT_URL_SUBS,
  GET_LISTING_PRODUCTS_CATEGORIES_SUB,
  GET_LISTING_PRODUCT_SUB,
  GET_LISTING_PRODUCT_COUNT,
  GET_CATAGORIES_BY_TEMPLATE_ID_SUB
};
