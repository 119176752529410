import React, { Component } from "react";
import { Icon, Modal, Tag } from 'antd'
import TransactionFilter from './TransactionFilter'
import { getSavedFilterList } from '../../util/FilterLogs'
import {
    FILTER_MASTER_DATA, GET_SAVED_FILTER_DATA_BY_IDS, GET_SAVED_FILTER_DATA_BY_IDS_COUNT,
    GET_FILTER_DATA_BY_IDS
} from '../../graphQl/subscriptions'
import client from '../../apollo/apollo';

class SavedFilterList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpenFilter: false,
            tableName: this.props.screenName,
            selectedTemplateId: this.props.templateId,
            optionFor: this.props.option,
            filterListData: [],
            selectedFilteritem: {},
            selectedFilterData: this.props.filteredData,
            hoveredFilterItem: null,
            highLightedIndex: '',
            recipientType: this.props.receivedUserType,
            recipientUser: this.props.recipientId
        }
    }

    componentDidMount() {
        this.setState({
            highLightedIndex: this.props.selectedFilterItemIndex,
            recipientUser: this.props.recipientId,
            recipientType: this.props.receivedUserType
        })
        if (this.props) {
            this.getSavedFiltersListById(this.props)
        }
    }

    componentWillReceiveProps(newProps) {
        if ((newProps.recipientId !== this.props.recipientId) &&
            (newProps.receivedUserType !== this.props.recipientType)) {
            this.setState({
                recipientUser: newProps.recipientId,
                recipientType: newProps.receivedUserType
            }, () => {
                this.getSavedFiltersListById(newProps)
            })
        }

        if (this.props.selectedFilterItemIndex !== newProps.selectedFilterItemIndex) {
            this.setState({ highLightedIndex: newProps.selectedFilterItemIndex })
        }
        if ((this.props.isCallFilterList !== newProps.isCallFilterList) && newProps.isCallFilterList === true) {
            this.getSavedFiltersListById(newProps)
        }

    }

    getSavedFiltersListById = async (propsValue) => {
        const { recipientUser, recipientType } = this.state
        let contactId = (recipientType && recipientType === "personal") ? recipientUser : null
        let businessId = (recipientType && recipientType === "business") ? recipientUser : null
        let listingId = recipientType ? localStorage.getItem('receivedListingId') : localStorage.getItem('selectedListingId')

        let data = {
            listingId: listingId,
            tableName: this.state.tableName,
            refId: this.state.selectedTemplateId ? this.state.selectedTemplateId : null,
            userContactId: contactId,
            businessContactId: businessId
        }

        await client
            // .subscribe({ query: GET_SAVED_FILTER_DATA_BY_IDS_COUNT, variables: data })
            .subscribe({ query: GET_SAVED_FILTER_DATA_BY_IDS, variables: data })
            .subscribe(
                res => {
                    if (res && res.data && res.data.filter_history && res.data.filter_history.length > 0) {
                        if (res.data.filter_history.length > 0) {
                            this.setState({ filterListData: res.data.filter_history })
                        } else {
                            this.setState({ filterListData: [] })
                        }
                    } else {
                        this.setState({ filterListData: [] })
                    }
                }, err => {
                })
        // })

    }

    onOpenFilter = () => {
        this.setState({ isOpenFilter: true })
    }

    onOpenFilterWithItem = (item) => {
        if (this.props.onEditSavedFilter && item && item.filterData) {
            this.props.onEditSavedFilter(item)
        }
        // this.setState({ isOpenFilter: true, selectedFilteritem: item })
    }

    onShowFilterTag = (data) => {
        this.setState({ selectedFilterData: data, isOpenFilter: false }, () => {
            if (this.props.onFilterData) {
                this.props.onFilterData(data)
            }
        })
    }

    onEnterMouseInList = (index) => {
        this.setState({ hoveredFilterItem: index })

    }

    //on selecting list item
    onSelectListItem = (data, index) => {

        if (data && data.filterData) {
            if (this.props.onFilterData) {
                this.props.onFilterData(data.filterData, data.filterData, index)
            }
        }
    }

    render() {
        const { optionFor, filterListData, selectedFilterData, hoveredFilterItem, highLightedIndex } = this.state
        const { selectedFilterItemIndex } = this.props
        return (
            <div>
                <div>
                    {/* {
                        filterListData.length > 0 &&
                        <li>
                            <span className="gx-link" style={{ color: '#000000', cursor: 'text' }}> <Icon type="filter" />Filters</span>
                        </li>
                    } */}

                    {filterListData.length > 0 && filterListData.map((item, i) => {
                        if (item) {
                            return (
                                <div>
                                    <li>
                                        <p
                                            className="gx-link"
                                            onMouseEnter={() => this.onEnterMouseInList(i)}
                                            onMouseLeave={() => this.setState({ hoveredFilterItem: null })}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex', flexDirection: 'row',
                                                    alignItems: 'center', width: 'max-content'
                                                }} >
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }} onClick={() => this.onSelectListItem(item, i)}>

                                                    <Icon type="book" />
                                                    <p
                                                        style={{
                                                            cursor: 'pointer', marginBottom: '0px',
                                                            color: ((highLightedIndex !== 'All') &&
                                                                (i === highLightedIndex) ? '#038fde' : '#000000')
                                                        }}
                                                    >
                                                        {item.filterName}</p>
                                                    {/* {item.filterName} {item.total_count}</p> */}
                                                </div>
                                                {
                                                    hoveredFilterItem !== null && i === hoveredFilterItem &&
                                                    <Icon type="edit"
                                                        style={{ marginLeft: 20 }}
                                                        onClick={() => this.onOpenFilterWithItem(item)} />
                                                }
                                            </div>
                                        </p>

                                    </li>
                                </div>
                            )
                        }
                    })
                    }
                </div>

                {/* {
                    this.state.isOpenFilter === true &&
                    <div>
                        {this.state.tableName === "transaction" &&
                            <TransactionFilter templateId={this.state.selectedTemplateId}
                                isOpenFilter={this.state.isOpenFilter}
                                isSavedFilter={this.props && this.props.option === "middle_bar" ? true : false}
                                onCloseModal={this.onCloseModal}
                                selectedFilteritem={this.state.selectedFilteritem}
                                onShowFilterTag={this.onShowFilterTag}
                            />
                        }
                    </div>
                } */}
            </div >
        );
    }

};

export default SavedFilterList;
