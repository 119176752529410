import gql from 'graphql-tag';


// const TAB_NAMES = gql`query transaction_ui($listingId:uuid!){
//   transaction_ui(where:{listingId:{_eq:$listingId}}){
//     tabName
//     transactionType
//   }
// }`

const TRANSACTION_REPORT_QUERY = gql`query transaction($id: [uuid!]!) {
  transaction(where: {_and: [{id: {_in: $id}}]}) {
    id
    currentStatus
    formData
    signedIP
    transactionHistory(order_by: {createdAt: desc}, limit: 1, where: {_and: [{status: {_eq: "LOYALTY CONTRACT SENT"}}]}) {
      status
      broadCastId
      typeOfNotification
      relatedBroadcast {
        logs(order_by: {createdAt: desc}, limit: 1) {
          createdAt
          deliveryStatus
          transactionUrls(order_by: {createdAt: desc}, limit: 1) {
            linkClickCount
          }
        }
      }
    }
  }
}`;



const getDownloadLink = gql`query fetch_file($shortURL: String!) {
  short_url(where: {_and: [{shortURL: {_eq: $shortURL}}, {fileId: {_is_null: false}}]}) {
    linkClickCount
    files{
      uploadUrl
    }
  }
}`;


const _history = gql`query history($transactionId: uuid!) {
  transaction_status_history(where: {transactionId: {_eq: $transactionId}}) {
    status
    relatedBroadcast {
      type
      logs {
        recipientContact
        deliveryStatus
        createdAt
        transactionUrls{
          linkClickCount
          shortURL
        }
      }
    }
  }
  transaction(where:{id:{_eq:$transactionId}}){
    broadcastRelated{
      type
      logs{
        recipientContact
        deliveryStatus
        createdAt
        transactionUrls{
          linkClickCount
          shortURL
        }
      }
    }
  }
}`;

const shortUrl = gql`query reactUrl($shortUrl: String!) {
  short_url(where: {shortURL: {_eq: $shortUrl}}) {
    id
    shortURL
    transactionId
    TransactiondisplayName
    linkClickCount
    transaction {
      id
      transactionTemplateId
      formData
      signedIP
      contactRelated {
        phoneNumber
      }
      transactionTemplate{
        id
        displayTemplate
      }
    }
  }
}`;

const TRANSACTION_FORM_FILTER = gql`query transaction_template($transactionType:String!,$listingId:uuid!){
  transaction_template(where:{ _and:[{type:{_eq:$transactionType}} , {listingId:{_eq:$listingId}}] }){
    formDefinition
    eligibleStatusList
    listing{
      listing_teams{
        userInfo{
          firstName
          lastName
        }
      }
    }
  }
}`

const USERS_QUERY = gql`
  query{
      user{
          id,
          email
      }
  }
`;

//"191f2df0-bff4-11e9-8930-cb873ff59fae"
const QUERY_ASSIGNED_TO = gql`query transaction($transactionId: uuid!) {
  transaction_assigned_to(where: {transactionId: {_eq: $transactionId}}) {
    assignedTo
  }
}`;

const TRANSACTION_QUERY = gql`query transaction($id:uuid) {
    transaction(where: {id: {_eq: $id}}) {
      id
      formData
      createdBy
      transactionTemplateId
      signedIP
    }
  }`;

const GET_LISTING_DETAIL_BY_URL = gql`query get_name($listingName:String!){
    listing_url(where:{urlName:{_ilike:$listingName}}){
      listingId
      listing{
        phoneNumber
        listing_img
        status
      }
    }
  }`;

const ADMIN_CHECK = gql`query adminCheck($listingId:uuid!,$userId:uuid!){
  listing_team(where:{_and:[{listingId:{_eq:$listingId}}, {userId:{_eq:$userId}} ]}){
    role
  }
}`

const USERID_TO_GET_LISTING = gql`query listingId($userId: uuid!) {
  listing_team(where: {userId : {_eq: $userId}}) {
    role
    listing {
      name
      status
      listing_urls {
        urlName
        listingId
      }
      businessContact {
        id
        name
      }
      listing_img
    }
  }
}`;

const CHECK_CATEGORIES = gql`query listing_product_categories_is_avail_or_not($listingId: uuid,, $templateId: uuid,$category_name:String) {
  listing_product_categories(where: {listing_id: {_eq: $listingId}, transaction_template_id : {_eq: $templateId},category_name:{_ilike:$category_name}}) {
    category_id
  }
}`

// const FORM_TEMPLATE = gql`query get_transaction_form($listingId:uuid!){
//   transaction_template(where: {_and: {listingId: {_eq: $listingId}}}) {
//     formUrl
//     id
//     name
//     sharedWithListings
//     type
//     formDefinition
//     eligibleStatusList
//   }
// }`;


const USER_WITH_PHONE_NUM_QUERY = gql`
    query user_with_phone_num($phone_number: String!) {
        user(
            where: {
                phoneNumber: {
                    _eq: $phone_number
                }
            }
        ) {
            id
        }
    }
`;

const USER_WITH_UID = gql`
query user_with_uid($uid: uuid!) {
  user(where: {id: {_eq: $uid}}) {
    id
    firstName
    lastName
    __typename
  }
}
`;

const USER_WITH_ID_QUERY = gql`query user_with_id($id: uuid!, $listingId: uuid!) {
  user(where: {_and: [{listinTeam:{listing:{id:{_eq:$listingId}}}}, {id: {_eq: $id}}]}) {
    firstName
    lastName
    phoneNumber
    email
    id
    listinTeam(where:{listingId:{_eq:$listingId}}) {
      role
    }
  }
}
`;

const CONTACT_WITH_IDS_QUERY = gql`
  query contact_with_ids($ids: [uuid]!) {
    contact(
      order_by: { firstName: asc }
      where: { id: { _in: $ids } }
    ) {
      firstName
      lastName
      phoneNumber
      email
      id
    }
  }
`;

//contact_group_rel table

const RELATIVE_CONTACT_IDS_QUERY = gql`
    query contact_group_rel($group_id:uuid!){
        contact_group_rel( where: {
                    group_id: {
                        _eq: $group_id
                    }
                }
        ) {
            contact_id,
            contact{
                id
                firstName,
                lastName,
                email,
                phoneNumber
            }
           
        }
    }
`;

const LISTINGNAME = gql`query listingname($url: String){
  listing_url(where: {urlName: {_eq: $url}}) {
    urlName
    listing {
      name
    }
  }
}`

const QRLIST = gql`query qllist($id: uuid){
 transaction(where: {listingId: {_eq: $id}}) {
   id
   formData
 }
 listing_url(where: {listingId: {_eq: $id}}) {
   urlName
 }
}`


const QRCODEUSER = gql`query qllist($id: uuid) {
 transaction(where: {id: {_eq: $id}}) {
   id
   formData
 }
}
`

const QRDETAILS = gql`query qrdetails($id: uuid){
 transaction(where: {id: {_eq: $id}}) {
   id
   formData
   assignedTo
   contactId
   contactNameOverride
   createdAt
   createdBy
   currentStatus
   listingId
   source
   statusUpdatedAt
   tags
   template {
     UISchema
     createdAt
     createdBy
     formDefinition
     eligibleStatusList
     formUrl
     id
     listingId
     notificationRule
     name
     owner
     sharedWithListings
     source
     type
     updatedAt
   }
   transactionTemplateId
   updatedAt
 }
}`
const LISTINGDETAILS = gql`query listingDetails($listingId:uuid!){
 listing(where: {id: {_eq: $listingId}}) {
   address
   phoneNumber
   name
 }
}`


const GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID = gql`query get_transaction_template($id:uuid!){
  transaction_template(where:{id:{_eq:$id}}){
  name
  id
  listingId
  type
  createdAt
  eligibleStatusList
  formDefinition
  UISchema
  displayTemplate
  owner
  createdBy
    }
  }`

const GET_TEMPLATES_BY_LISTING_ID = gql`query get_templates($listingId:uuid!){
    template(where:{listingId:{_eq:$listingId}}){   
    id
    title
    content
    templateType
    }
    }`


const GET_TEMPLATE_RULES_BY_TEMPLATE_ID = gql`query transaction_template_rules($transactionTemplateId: uuid!) {
  transaction_template_rules(where: {transactionTemplateId: {_eq:$transactionTemplateId}}) {
    id
    notificationChannel
    status
  transactionTemplateId
  }
}`

const GET_TRANSACTION_FILTER_DATA = gql`query get_transaction_filter_by_params($limit:Int!,$offset:Int!,$p_data:json){
  get_transaction_filter_by_params( 
    limit:$limit,offset:$offset,args:{p_data:$p_data}){
    contactId
    contactNameOverride
    createdAt
    createdBy
    updatedAt
    id
    listingId
    source
    transactionTemplateId
    currentStatus
    assignedTo
    tags
    formData
    type
    template {
      id
      name
      formDefinition
      UISchema
      eligibleStatusList
      displayTemplate
      signedStatus
      displayTemplateType 
    }
    urlCreated {
      shortURL
    }
    assignedMembers{
      assigneeInfo{
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
}`

const GET_BUSINESS_CONTACT_FILTER_DATA = gql`query get_business_contact_filter_by_params($p_data:json){
  get_business_contact_filter_by_params(args:{p_data:$p_data}){
    id
    city
    country
    createdAt
    defaultUserContactId
    description
    internalBusinessContactId
    listingId
    mappedListingId
    name
    notes
    postalCode
    state
    status
    streetName
    tags
  }
}`

const GET_MESSAGES_FILTER_DATA = gql`query get_message_filter_by_params($p_data:json){
  get_message_filter_by_params(args:{p_data:$p_data}){
    id
    title
    content
    createdAt
    deliveryList
    savedContacts
    isDraft
    selectedCommunicationOption
    tags
  }
}`

const GET_INDIVIDUAL_CONTACT_FILTER_DATA = gql`query get_contact_filter_by_params($p_data:json){
  get_contact_filter_by_params(args:{p_data:$p_data}){
    id
    email
    firstName
    lastName
    phoneNumber
    notes
    customAttributes
    validPhoneNumer
    validEmail 
    internalUserContactId
    tags
  }
}`

const TRANSACTION_DATA_BY_SHORT_URL_QUERY = gql`query reactUrl($shortUrl: String!) {
  short_url(where: {shortURL: {_eq: $shortUrl}}) {
    id
    shortURL
    transactionId
    TransactiondisplayName
    linkClickCount
    transaction {
      id
      transactionTemplateId
      formData
      signedIP
      contactRelated {
        phoneNumber
      }
      transactionTemplate {
        id
        displayTemplate
        signedStatus
      }
    }
  }
}`;

const CHECK_PHONE = gql`query check_mobile_number_or_email_exist_or_not($phoneNumber: String,$listingId:uuid!) {
  contact(order_by:{createdAt:desc} where: {_and: [{phoneNumber: {_ilike: $phoneNumber}}, {listingId: {_eq: $listingId}}]}){
     id
     firstName
     email
     createdAt
     phoneNumber
   }
 }`

const CHECK_EMAIL_PHONE = gql`query check_mobile_number_or_email_exist_or_not($phoneNumber: String,$email:String,$listingId:uuid) {
  contact(order_by:{createdAt:desc} where: {_or: {phoneNumber: {_ilike: $phoneNumber}, email: {_ilike: $email}}_and:{listingId:{_eq:$listingId}}}){
     id
     firstName
     email
     createdAt
     phoneNumber
   }
 }`

const GET_CONTACT_LIST = gql`query get_list_contacts($listingId:uuid!, $status:String!) {
  contact(order_by:{createdAt:desc} where: {_and: [{status:{_eq:$status}}, {listingId: {_eq: $listingId}}]}){
     id
     firstName
     email
     createdAt
     phoneNumber
   }
 }`

const CHECK_LISTING = gql`query ($p_name:String,$p_address1:String,$p_city:String,$p_country:String){
  search_listings(args:{p_name:$p_name,p_address1:$p_address1,p_city:$p_city,p_country:$p_country}){
    address
    city
    country
    createdAt
    createdBy
    description
    id
    listingClaimed
    listingCreationBySource
    listingCreationBySourceListingId
    name
    notifyTestMode
    phoneNumber
    postalCode
    state
    streetName
    type
  }
}
`

const CHECK_PHONENUMBER_WITH_CONTACT = gql`query check_contact($phoneNumber:String) {
  contact(where:{phoneNumber:{_eq:$phoneNumber}}){
    phoneNumber
    id
  }
}`

const GET_CONTACT_ID = gql`query check_contact($mappingUserId:uuid) {
  contact(where:{mappingUserId:{_eq:$mappingUserId}}){
    id
  }
}`

const GET_INDIVIDUAL_LISTING = gql`query getContactUserId($userId: uuid, $listingId: uuid) {
  contact(where: {listingId: {_eq: $listingId}, mappingUserId: {_eq: $userId}}) {
    id
  }
}`

const CHECK_BUSINESS_CONTACT_WITH_LISTING = gql`query get_business_contact_defaultUserContactId($defaultUserContactId:uuid!){
  business_contact(where:{defaultUserContactId:{_eq:$defaultUserContactId}}){
    mappedListingId
    }
}`

const GET_SHORT_URL = gql`query transaction_list($transactionId:uuid!){
  short_url(where:{transactionId:{_eq:$transactionId}}){
shortURL
  }
}`

const GET_TEMPLATE_DETAIL_BY_URL = gql`query fetch_template($listingId: uuid!,$templatepublicurl:String!,
  $transactionTemplateStatus:String!) {
  transaction_template(where: {_and: [{listingId: {_eq: $listingId}}, {transactionTemplateStatus: {_eq: $transactionTemplateStatus}}, {templatepublicurl: {_ilike:$templatepublicurl}}]}) {
    id
    name 
    formDefinition 
    displayTemplate 
    templatepublicurl 
    signedStatus 
    type
    transactionTemplateStatus
    displayTemplateType
    eligibleStatusList
    otpVerification
    listing {
      id
    }
    templateSettings
    shorturlForDeeplink
    }
  }`

const CHECK_CONTACT_WITH_PHONE_NUMBER_LISTING = gql`query get_contact($listingId: uuid!,$phoneNumber:String!) {
  contact(where: {_and: [{listingId: {_eq: $listingId}}, {phoneNumber: {_eq:$phoneNumber}}]}) {
    createdAt
    id
    email
    phoneNumber
    }
  }`


const CHECK_BUSINESS_CONTACT = gql`query contact($name:String,$postalCode:String,$address1:String,$city:String,$country:String,$state:String,$internalBusinessContactId:String,$listingId:uuid){
  business_contact(where:{_and:[{name:{_eq:$name}},{postalCode:{_eq:$postalCode}},{address1:{_eq:$address1}},{city:{_eq:$city}},{country:{_eq:$country}},{state:{_eq:$state}},{internalBusinessContactId:{_eq:$internalBusinessContactId}},{listingId:{_eq:$listingId}}]}){
  id
  defaultUserContactId
  contactId {
    email
    phoneNumber
  }
  }
}`

const GET_LISTING_CATEGORIES = gql`query listing_product_categories($listingId: uuid, $categoryName: String, $templateId: uuid) {
  listing_product_categories(order_by: {category_serial_no: asc}, where: {listing_id: {_eq: $listingId},, transaction_template_id : {_eq: $templateId}, category_name: {_ilike: $categoryName}}) {
    category_id
    category_name
    listing_id
    category_serial_no
  }

}`

const GET_LISTING_PRODUCTS_CATEGORIES = gql`query listing_product_categories($listingId: uuid,, $templateId: uuid) {
  listing_product_categories(where: {listing_id: {_eq: $listingId}, transaction_template_id : {_eq: $templateId}}) {
    category_id
    category_name
    listing_id
    listing_products {
      category_id
      listing_id
      product_id
      product_name
      product_description
      product_hsn_code
      product_image
      product_config
      product_per_unit_price
    }
  }
}`

// const GET_LISTING_PRODUCTS_BY_CATEGORY_ID = gql`query listing_products($offset:Int!,$limit:Int!,$listingId: uuid, $categoryId: [uuid!],, $templateId: uuid) {
//   listing_products(offset:$offset,limit:$limit,where: {listing_id: {_eq: $listingId},transaction_template_id : {_eq: $templateId}, category_id: { _in: $categoryId }}) {
//     category_id
//     listing_id
//     product_id
//     product_name
//     product_description
//     product_hsn_code
//     product_config
//     product_per_unit_price
//     product_taxes 
//   }
// }`
const GET_LISTING_PRODUCTS_BY_CATEGORY_ID = gql`query listing_products($listingId: uuid, $categoryId: [uuid!],, $templateId: uuid) {
  listing_products(order_by: {product_serial_no: asc}, where: {listing_id: {_eq: $listingId},transaction_template_id : {_eq: $templateId}, category_id: { _in: $categoryId }}) {
    category_id
    listing_id
    product_id
    product_name
    product_description
    product_hsn_code
    product_image
    product_config
    product_per_unit_price
    product_taxes 
    product_serial_no
    hide_from_catalog
    listing_product_category{
      category_id
      category_name
      category_serial_no
    }
  }
}`

const GET_TRANSACTION_TEMPLATE_LISTING_DETAIL_BY_ID = gql`query get_transaction_template($id:uuid!){
  transaction_template(where:{id:{_eq:$id}}){
    name
    id
    templateSettings
    listing {
      id
      name
      listing_img
      address1
      address2
      city
      state
      country
      postalCode
      phoneNumber
    }
  }
  }`

const GET_ADMINS_BY_LISTING_ID = gql`query fecthAdmin($listingId: uuid!) {
    listing_team(where: {_and: [{listingId: {_eq: $listingId}}, {role: {_eq: "listingAdmin"}}], userInfo: {id: {_neq: null}}}) {
      userId
    }
  }`


const GET_LISTINGDETAILS_BY_SHORTURL = gql`query fetch_file($shortURL: String!) {
    short_url(where: {_and: [{shortURL: {_eq: $shortURL}}]}) {
      transaction{
        transactionTemplate {
          listing {
            phoneNumber
          }
          templateSettings
        }
        type
      }
    }
  }`

const MESSAGE_TEMPLATE_RULES_BY_STATUS = gql`query rules($transactionTemplateId: uuid!, $currentStatus:String!) {
    transaction_template_rules(where: {_and: {status: {_eq: $currentStatus}, transactionTemplateId: {_eq: $transactionTemplateId}}}) {
      createdAt createdBy id notificationChannel
      remindAt ruleType shareWithListing status
      transactionTemplateId updatedAt updatedBy user
    }
  }`

const MESSAGE_TEMPLATE_BY_ID = gql`query fecthTemplate($templateId:uuid!){
  template(where:{id:{_eq:$templateId}}){
    title
    content
    templateType
    senderId
    promOrTrans
    listing{
        listing_urls{
          urlName
        }
      }
  }
}`

const SEARCH_CONTACTS = gql`query search_contacts_by_type($p_listingid:uuid,$p_value:String,$p_type:String){
  search_contacts_by_type(args:{p_listingid:$p_listingid,p_value:$p_value,p_type:$p_type}){
    id
    email
    firstName
    lastName
    phoneNumber
    notes
    customAttributes
    validPhoneNumer
    validEmail 
    tags
  }
}`

const GET_STATUS_DESC = gql`query fecth_status_description($transactionTemplateId:uuid!, $statusName:String!) {
  transaction_template_status(where: {_and: [{transactionTemplateId: {_eq: $transactionTemplateId }}, {statusName: {_eq: $statusName}}]}) {
    statusDesc
  }
}`

const CHECK_CONTACT_DATA = gql`query search_contact_by_params($p_email:String,$p_phone_no:String,$p_listingid:uuid!,$p_internal_user_contact_id:String){
  search_contacts_by_params(args:{
    p_email:$p_email,
    p_phone_no:$p_phone_no,
    p_listingid:$p_listingid,
    p_internal_user_contact_id:$p_internal_user_contact_id
  }){
    id
    email
    phoneNumber
    internalUserContactId
  }
}`

const CLONE_LISTING = gql`query copy_listing_data($p_listingid:uuid,$p_userid:uuid){
  copy_listing_data(args:{p_listingid:$p_listingid,p_userid:$p_userid}){
    id
    address
    address1
    address2
    city
    country
  }
}`

const GET_SHOP_ORDER_COUNT = gql`query get_order_count($templateId: uuid!) {
  transaction_template(where: {_and: [{id: {_eq: $templateId}}]}) {
   ordercount
    }
  }`

const GET_PRODUCT_INVENTORY = gql`query product_option_lookup( $productId: String!,$templateId: uuid!) {
    product_inventory(where: {transaction_template_id : {_eq: $templateId},product_id: {_eq: $productId} }) {
      onhand_quantity
      updated_at
      product_id
      transaction_template_id
    }
    }`;


const GET_PRODUCT_MASTER = gql`query product_master($transaction_template_id: uuid!) {
  product_master(where: {transaction_template_id: {_eq :$transaction_template_id}}) {
    product_subcategory_id
    id
    product_id
    hasVariant
    product_name
    product_subcategory_id
    product_category_id
    transaction_template_id
    product_image
    product_variant {
      product_variant_id
      listing_id
      transaction_template_id
      product_master_id
      price
      created_at
      created_by
      updated_at
      updated_by
      id
      product_id
      product_variant_option
      product_settings
      product_image
    }
  }
}`;

const GET_PRODUCTS_BY_CATEGORY_ID = gql`query product_master( $listingId: uuid, $categoryId: [uuid!], $templateId: uuid!) {
  product_master(order_by: {product_sequence_no: asc},where:
     {listing_id: {_eq: $listingId},
    transaction_template_id : {_eq: $templateId},
     product_category_id: { _in: $categoryId },
     status: { _eq: "ACTIVE" }}) {
    product_category_id
    listing_id
    product_id
    product_name
    product_image
    hide_from_catalog
    product_description
    product_category {
      product_category_id
      listing_id
      product_category_name
      transaction_template_id
      category_image
      category_discount
      product_masters_aggregate {
        aggregate {
          count(columns: product_category_id)
        }
      }
      product_subcategories{
        product_subcategory_id
        product_subcategory_name
      }
    }
    product_taxes
    hasVariant
    product_list_price
    transaction_template_id
    product_subcategory_id
    id
    product_settings
  
  }
}`

const GET_PRODUCT_OPTION_LOOKUP = gql`query product_option_lookup( $productId: uuid,$listingId: uuid!,$templateId: uuid,$productCategoryId: uuid,$productSubcategoryId: uuid, $productOptionId:uuid) {
  product_option_lookup(where: {listing_id: {_eq: $listingId},template_id : {_eq: $templateId}, product_category_id: { _eq: $productCategoryId },product_subcategory_id:{_eq: $productSubcategoryId},product_master_id: {_eq: $productId} }) {
    product_option_name
    option_value_list
  }
  }`;

const GET_VARIANTS_RESULT = gql`query get_product_master_filter_by_params($p_data:json){
    get_product_variant_filter_by_params(args:{p_data:$p_data}){
      id
      product_id
      price
      listing_id
      product_master_id
      product_variant_id
      product_variant_option
      transaction_template_id
      product_settings 
      product_image
      product_description
    }
  }`;


export {

  USERS_QUERY,
  USER_WITH_PHONE_NUM_QUERY,
  GET_LISTING_DETAIL_BY_URL,
  USER_WITH_ID_QUERY,
  CONTACT_WITH_IDS_QUERY,
  //contact_group_rel table
  RELATIVE_CONTACT_IDS_QUERY,
  USERID_TO_GET_LISTING,
  // GET_LISTINGDETAILS,
  // FORM_TEMPLATE,
  LISTINGNAME,
  QRLIST,
  QRCODEUSER,
  QRDETAILS,
  LISTINGDETAILS,
  QUERY_ASSIGNED_TO,
  TRANSACTION_QUERY,
  TRANSACTION_FORM_FILTER,
  // TAB_NAMES,
  ADMIN_CHECK,
  shortUrl,
  getDownloadLink,
  _history,
  TRANSACTION_REPORT_QUERY,
  USER_WITH_UID,
  GET_TEMPLATES_BY_LISTING_ID,
  GET_TEMPLATE_RULES_BY_TEMPLATE_ID,
  TRANSACTION_DATA_BY_SHORT_URL_QUERY,
  CHECK_BUSINESS_CONTACT,
  GET_INDIVIDUAL_LISTING,
  GET_CONTACT_LIST,

  CHECK_EMAIL_PHONE,
  CHECK_PHONE,
  CHECK_LISTING,
  CHECK_PHONENUMBER_WITH_CONTACT,
  CHECK_BUSINESS_CONTACT_WITH_LISTING,
  GET_TRANSACTION_FILTER_DATA,
  GET_BUSINESS_CONTACT_FILTER_DATA,
  GET_INDIVIDUAL_CONTACT_FILTER_DATA,
  GET_MESSAGES_FILTER_DATA,
  GET_SHORT_URL,
  GET_CONTACT_ID,
  GET_TEMPLATE_DETAIL_BY_URL,
  CHECK_CONTACT_WITH_PHONE_NUMBER_LISTING,

  GET_LISTING_CATEGORIES,
  GET_LISTING_PRODUCTS_BY_CATEGORY_ID,
  GET_LISTING_PRODUCTS_CATEGORIES,
  GET_TRANSACTION_TEMPLATE_LISTING_DETAIL_BY_ID,
  GET_ADMINS_BY_LISTING_ID,
  CHECK_CATEGORIES,
  GET_LISTINGDETAILS_BY_SHORTURL,
  MESSAGE_TEMPLATE_RULES_BY_STATUS,
  MESSAGE_TEMPLATE_BY_ID,
  SEARCH_CONTACTS,
  GET_STATUS_DESC,
  CHECK_CONTACT_DATA,
  CLONE_LISTING,
  GET_SHOP_ORDER_COUNT,
  GET_PRODUCT_INVENTORY,
  GET_PRODUCT_MASTER,
  GET_PRODUCTS_BY_CATEGORY_ID,
  GET_PRODUCT_OPTION_LOOKUP,
  GET_VARIANTS_RESULT
};
