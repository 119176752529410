import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from './DynamicFields';


let initialData = {
    taxType: '',
    taxComputationType: ''
}

class Taxes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            TaxesDetails: [],
            id: 0,
            deletedKeys: [],
            taxValues: {},
            taxLevel: 'SubTotalLevel'
        }
    };

    componentWillMount() {
        if (this.props.editData && this.props.editData.taxesDetailsValues) {
            this.setState({ TaxesDetails: this.props.editData.taxesDetailsValues });
        } else {
            if (this.state.TaxesDetails.length === 0) {
                let data = this.state.TaxesDetails
                data.push(initialData)
                this.setState({ TaxesDetails: data, loading: false })
            }
        }
        if (this.props.editData && this.props.editData.taxesValues) {
            this.setState({ taxValues: this.props.editData.taxesValues ? this.props.editData.taxesValues : {} });
        }
        if (this.props.editData && this.props.editData.taxLevel) {
            this.setState({ taxLevel: this.props.editData.taxLevel ? this.props.editData.taxLevel : '' });
        }

    }

    onAddRow = () => {
        let data = this.state.TaxesDetails
        let initialData = {
            taxType: '',
            taxComputationType: ''
        }

        if(data) {data.push(initialData)}
        this.setState({ TaxesDetails: data })
    }


    LoopItemsChange(TaxesDetails) {
        this.setState({ TaxesDetails }, () => {
            this.props.taxesDetailSave(TaxesDetails);
        })
    }

    onRemove(TaxesDetails) {
        this.setState({ TaxesDetails });
    }

    onRadioChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            taxLevel: e.target.value,
        }, () => {
            this.props.taxLevelSave(this.state.taxLevel);
        });
    };

    onChecked(item, e) {
        if (item === 'Cgst') {
            this.state.taxValues.CGST = e.target.checked;
        }
        if (item === 'Sgst') {
            this.state.taxValues.SGST = e.target.checked;
        }
        if (item === 'Igst') {
            this.state.taxValues.IGST = e.target.checked;
        }
        this.setState({ taxValues: this.state.taxValues }, () => {
            this.props.taxesValuesSave(this.state.taxValues);
        })
    }

    render() {
        const { TaxesDetails, taxValues, taxLevel } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        console.log(TaxesDetails, 'TaxesDetails')
        return (
            <Form
                onSubmit={this.handleSubmit}
            >

                <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Add any tax fields you want to display  in the order form. Enter the name of the tax type like VAT or Sales tax in the form field name and the percentage amount in the form field value.
                    </span>
                </div>

                <div style={{ marginBottom: 15 }}>
                    <Radio.Group onChange={this.onRadioChange} value={taxLevel}>
                        <Radio value={'ItemLevel'}>Item Level</Radio>
                        <Radio value={'SubTotalLevel'}>Sub-Total Level</Radio>
                    </Radio.Group>
                    {taxLevel === 'ItemLevel' &&
                        <div>
                            <Row span={24}  style={{ paddingLeft: 15, paddingTop: 15 }}>
                                <Checkbox className="show-attribute-field" defaultChecked={false} checked={taxValues.CGST} onChange={(e) => this.onChecked('Cgst', e)}></Checkbox>
                                <span className="status-rule-text" style={{ marginLeft: 7 }}>CGST</span>
                            </Row>
                            <Row span={24}  style={{ paddingLeft: 15, paddingTop: 15 }}>

                                <Checkbox className="show-attribute-field" defaultChecked={false} checked={taxValues.SGST} onChange={(e) => this.onChecked('Sgst', e)}></Checkbox>
                                <span className="status-rule-text" style={{ marginLeft: 7 }}>SGST</span>
                            </Row>
                            <Row span={24}  style={{ paddingLeft: 15, paddingTop: 15 }}>
                                <Checkbox className="show-attribute-field" defaultChecked={false} checked={taxValues.IGST} onChange={(e) => this.onChecked('Igst', e)}></Checkbox>
                                <span className="status-rule-text" style={{ marginLeft: 7 }}>IGST</span>
                            </Row>
                        </div>
                    }
                </div>
                {(taxLevel == '' || taxLevel === 'SubTotalLevel') &&
                    <div>
                        <DynamicFields
                            accessFrom='Taxes'
                            LoopItems={TaxesDetails}
                            LoopItemsChange={this.LoopItemsChange.bind(this)}
                            onRemove={this.onRemove.bind(this)}
                            isEdit={this.props.isEdit ? true : false}
                        />
                        <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Item {...formItemLayoutWithOutLabel} >
                                <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                }

            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(Taxes);
export default WrappedDynamicFieldSet

