import React, { Component } from 'react'
import { Icon, Tooltip, message, Popconfirm } from 'antd'
import { withRouter } from 'react-router-dom'
import client from '../../apollo/apollo'
import { ARCHIVE_TRANSACTION_TEMPLATE } from '../../graphQl/mutations'
import options from "../../routes/List/data/options";
import Auxiliary from "../../util/Auxiliary";

const ITEM_HEIGHT = 34;

export class HeaderLayout extends Component {
    constructor() {
        super();
        this.state = {
            optionName: 'Actions',
            templateId: ''
        }
    }

    componentDidMount() {
        if (this.props && this.props.templateId) {
            this.setState({ templateId: this.props.templateId }, () => {
            })
        }
    }

    archiveTemplate() {
        var listingName = localStorage.getItem('selectedListingName');

        const variableObject = {
            transactionTemplateStatus: "ARCHIVED",
            id: this.state.templateId
        }
        client.mutate({ mutation: ARCHIVE_TRANSACTION_TEMPLATE, variables: variableObject }).then((response) => {
            message.success('Archived SuccessFully')
            this.props.history.push({
                pathname: `/app/console/${listingName}/new_transaction`,
                state: { from: localStorage.getItem('transactionType') }
            });
        })
            .catch(err => {
                message.error('Archived Failed')
            });
    }

    render() {
        const { templateId } = this.state
        return (
            <div id="order-list-view" style={{ backgroundColor: '#fffff' }}>
                <div className="gx-module-box-content">
                    <div className="gx-module-box-topbar gx-module-box-topbar-todo">
                        <div
                            style={!templateId?{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }:
                            { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}
                        >
                            {!templateId &&
                                <div style={{ fontWeight: 600, fontSize: '18px',width:'100%',display:'flex',justifyContent:'center' }}>New Template</div>
                            }

                            <div style={{ display: 'flex' }}>
                                <Tooltip title="Save">
                                    <Icon id="icon-view" type="save" disabled={this.props.disable ? true : false} style={{ fontSize: 17, marginRight: 10, color: this.props.disable ? 'black' : 'gray' }} onClick={this.props.disable && this.props.onSubmit} />
                                </Tooltip>
                                {templateId &&
                                    <Popconfirm
                                        title="Are you sure archive this contract template?"
                                        onConfirm={() => { this.archiveTemplate() }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Tooltip title="Archive">
                                            <Icon id="icon-view" type="delete" style={{ fontSize: 17, cursor: 'pointer' }} />
                                        </Tooltip>
                                    </Popconfirm>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(HeaderLayout)
