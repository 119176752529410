import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input } from 'antd';

const { TextArea } = Input;


class EditOrderDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orderDetails: [],
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        if (this.props.editData) {
            this.setState({ orderDetails: this.props.editData })
        }
    }


    onOrderdetailChange(e) {
        const orderDetails = {};
        orderDetails.notes = e.target.value;
        this.setState({ orderDetails }, () => {
            this.props.onOrderDetailEdit(orderDetails);
        })
    }

    render() {
        const { orderDetails } = this.state;
        console.log(orderDetails, 'cutomerdetails')
        return (
            <div >
                <TextArea placeholder="Enter Order Notes" value={orderDetails.notes} onChange={(e) => { this.onOrderdetailChange(e) }} />
            </div>
        );
    }

}


export default EditOrderDetails;


