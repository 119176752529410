export const CONFIG = {
    //Firefabase config
    FIREBASE_API_KEY: 'FIREBASE_API_KEY',
    AUTHDOMAIN: 'AUTHDOMAIN',
    DATABASEURL: 'DATABASEURL',
    PROJECTID: 'PROJECTID',
    STORAGEBUCKET: 'STORAGEBUCKET',
    MESSAGINGSENDERID: 'MESSAGINGSENDERID',
    DOMAIN: 'DOMAIN',
    GRAPHQL: 'GRAPHQL',
    WEBSOCKET: 'WEBSOCKET',
    CLOUDFUNCTIONS: 'CLOUDFUNCTIONS',
    APPSPOT: 'APPSPOT',
    GRAPHQL_PASS: 'GRAPHQL_PASS'
  }