import React, { Component } from "react";
import { Button, Card, Form, Input, Select, Radio, Icon, Spin, message, Upload } from "antd";
import { Mutation, Subscription } from "react-apollo";
import { withRouter } from 'react-router-dom'
import * as _ from "lodash"
import { TEMPLATE_SUB, SENDER_SUB, FETCH_FILES } from '../../graphQl/subscriptions';
import { NOTIFICATION_FILE_UPLOAD } from '../../graphQl/mutations';
import AddUser from './AddUser'
import Recipients from './Recipients'
import { cloneDeep } from 'lodash'
import client from '../../apollo/apollo';
import Hashids from 'hashids';
import { storage } from '../../firebase/firebase';
import gql from 'graphql-tag';
import SelectFiles from './SelectFiles'
// import { height } from "window-size";
import { CONFIG } from '../../config/config'
import { getENVConfig } from '../../util/common'
import CustomScrollbars from "util/CustomScrollbars";
import { getFilterLogs, getSelectedFilterDataById } from '../../util/FilterLogs'

message.config({
    duration: 2,
});

const FormItem = Form.Item;
const Option = Select.Option;

const { Dragger } = Upload;

const { TextArea } = Input;

let templatesData = []

const broadcastTypeData = [
    {
        title: 'Promotional',
        key: 'PROMOTIONAL'
    },
    {
        title: 'Transactional',
        key: 'TRANSACTIONAL'
    },
]

const uuidv3 = require('uuid/v3');
const uuidv4 = require('uuid/v4');
const uuidv1 = require('uuid/v1');
const axios = require('axios');

let messageDetail = "messageDetail"
let messageType = "messageType"
let messageTemplate = "messageTemplate"
let messageSender = "messageSender"
let messageTitle = "messageTitle"
let recipients = "recipients"
let fileAttachments = "fileAttachments"

const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

class NewBroadCast extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openAddCustomerModal: false,
            broadcastTitle: '',
            broadcastDetail: '',
            selectedTemplate: {},
            broadcastType: "",
            senderId: '',
            selectedContactsIds: [],
            selectedContactsList: [],
            alertMessage: '',
            showMessage: false,
            showLoading: false,
            broadcastSender: '',
            showAddCustomerView: false,
            selectedTemplateId: '',
            showMessage: true,
            broadcastFor: this.props.broadcastType,
            allRecipientsList: [],
            showSelectFileModal: false,
            selectedFilesId: [],
            selectedFileList: [],
            allSelectedFilesList: [],
            filteredUploadListCount: 0,
            screenOption: '',
            selectedFilterData: {},
            disabledState: false,
            selectedTemplateFromDropDown: {}
        }
    }

    componentDidMount() {
        let text = "Dear Business Partner, Please find the link to the {#var#} Loyalty Contract {#var#} . Please review and sign to confirm your acceptance of the terms and conditions. Best Wishes, Team Royal Canin- Totely";
        let output_text = text.replace(/{#var#}/g, "********");
        console.log("output_text12", output_text);

        this.setState({ screenOption: this.props.optionFor }, () => {
            if (this.state.screenOption === "messageFilter") {
                // this.getFilterCriteria()
                if (document.getElementById("messageForm")) {
                    document.getElementById("recipients").style.display = 'none'
                    document.getElementById("fileAttachments").style.display = 'none'
                    document.getElementById("messageTemplate").style.display = 'none'
                }

                this.setState({
                    disabledState: this.props.isFieldsReadOnly,
                    // eidtField: this.props.isFieldsReadOnly,
                    selectedFilterData: this.props.editFilterItem ? this.props.editFilterItem : {},
                }, () => {

                    const { selectedFilterData } = this.state
                    if (selectedFilterData && selectedFilterData.filterData) {
                        this.setStateFormValues(selectedFilterData.filterData)
                    } else {
                        if (this.props.selectedData) {
                            this.setStateFormValues(this.props.selectedData)
                        } else {
                            let data = {
                                messageDetail: "",
                                messageType: "",
                                messageSender: "",
                                messageTitle: "",
                            }
                            this.setStateFormValues(data)
                        }
                    }
                })
            }
        })
    }



    componentWillReceiveProps(newProps) {
        if (this.props.isClickedOk !== newProps.isClickedOk && newProps.isClickedOk === true) {
            this.onSearchData()
        }

        if (this.props.onSaveFilter !== newProps.onSaveFilter && newProps.onSaveFilter === true) {
            this.onSaveFilter()
        }

        if (this.props.isFieldsReadOnly !== newProps.isFieldsReadOnly) {
            this.setState({ disabledState: newProps.isFieldsReadOnly })
        }
    }

    setStateFormValues = (value) => {
        this.setState({
            broadcastDetail: value.messageDetail,
            broadcastSender: value.messageSender,
            broadcastType: value.messageType,
            broadcastTitle: value.messageTitle,
        }, () => {
        })
    }

    onSearchData = () => {
        // this.props.form.validateFieldsAndScroll((err, values) => {
        const { selectedTemplateId, broadcastTitle, broadcastSender, broadcastType, broadcastDetail } = this.state
        // if (broadcastDetail && broadcastType && broadcastSender && broadcastTitle && selectedTemplateId) {
        let data = {
            messageDetail: this.state.broadcastDetail,
            messageType: this.state.broadcastType,
            messageSender: this.state.broadcastSender,
            messageTitle: this.state.broadcastTitle,
        }

        if (this.props.onShowFilterTag) {
            this.props.onShowFilterTag(data)
        }
        // }
        // })
    }

    onSaveFilter = () => {
        const { selectedFilterData } = this.state
        let data = {
            messageDetail: this.state.broadcastDetail,
            messageType: this.state.broadcastType,
            messageSender: this.state.broadcastSender,
            messageTitle: this.state.broadcastTitle,
        }
        let variablesData = {}
        if (Object.keys(selectedFilterData).length > 0) {
            let id = selectedFilterData && selectedFilterData.filterHistId ? selectedFilterData.filterHistId : null
            variablesData = {
                filterHistId: id,
                listingId: localStorage.getItem('selectedListingId'),
                tableName: this.state.broadcastFor === "sms" ? "message_sms" : "message_email",
                refId: null,
                createdBy: localStorage.getItem('uid'),
                filterData: data
            }
            if (this.props.onSaveFilterData) {
                this.props.onSaveFilterData(variablesData, 'update')
            }
        } else {

            variablesData = {
                listingId: localStorage.getItem('selectedListingId'),
                tableName: this.state.broadcastFor === "sms" ? "message_sms" : "message_email",
                refId: null,
                createdBy: localStorage.getItem('uid'),
                filterData: data
            }
            if (this.props.onSaveFilterData) {
                this.props.onSaveFilterData(variablesData, 'create')
            }
        }
    }


    onFilterData = () => {
        let data = {
            messageDetail: this.state.broadcastDetail,
            messageType: this.state.broadcastType,
            messageSender: this.state.broadcastSender,
            messageTitle: this.state.broadcastTitle,
        }
        if (this.props.onShowFilterTag) {
            this.props.onShowFilterTag(data)
        }
    }

    //get filter criteria from filter master table
    getFilterCriteria = () => {
        let data = {
            tableName: this.state.broadcastFor === "sms" ? "message_sms" : "message_email",
            listingId: null,
            templateId: null
        }
        getFilterLogs(data)
            .then((res) => {
                if (res.length > 0) {
                    let data = res[0]
                    if (data.filterCriteria) {
                        let p = data.filterCriteria
                        if (document.getElementById("messageForm")) {
                            document.getElementById("recipients").style.display = 'none'
                            document.getElementById("fileAttachments").style.display = 'none'
                        }

                    }
                }

            }).catch(err => {
            })
    }

    onSelectTemplate = (event) => {
        if (this.state.screenOption !== "messageFilter") {
            let findId = _.find(templatesData, function (data) { return data.id === event; });
            if (findId) {
                this.setState({
                    broadcastTitle: findId.title, broadcastDetail: findId.content,
                    selectedTemplateId: event, selectedTemplateFromDropDown: findId
                })
            }
        } else {
            this.setState({ selectedTemplateId: event })
        }

    }

    onSelectSender = (event) => {
        this.setState({ broadcastSender: event })
    }

    onSelectBroadcastType = (event) => {
        this.setState({ broadcastType: event.target.value, broadcastSender: '' })
    }

    onCloseModal = () => {
        this.setState({
            showAddCustomerView: false
        })
        if (this.state.allRecipientsList.length === 0) {
            this.setState({
                showAddCustomerView: false, selectedContactsList: [],
                selectedContactsIds: [], allRecipientsList: []
            })
        }
    }

    // onAddCustomer = (userIds, userData) => {
    onAddCustomer = (userData, userIds) => {

        let data = _.filter(userData, function (o) { return o.checkedValue === true; });
        this.setState({
            selectedContactsList: data,
            selectedContactsIds: userIds,
            showAddCustomerView: false,
            allRecipientsList: userData
        })
    }


    onAppendURL = (arrayOfURL) => {
        let cString = '';
        let { broadcastDetail, selectedFilesId } = this.state;
        selectedFilesId = selectedFilesId.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.fileId === thing.fileId
            ))
        )

        arrayOfURL.map((_t) => {
            if (broadcastDetail.indexOf(_t.url) == -1) {
                cString = cString + ` ${_t.url} `;
            }
        });

        this.setState({ broadcastDetail: broadcastDetail + cString, selectedFilesId: arrayOfURL }, () => {
            if (this.state.broadcastFor === "sms") {
                this.onSubmitNewSMS()
            } else {
                this.onSubmitNewEMAIL()
            }
        });
    };

    handleSubmit = (e) => {

        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {

                this.props.form.resetFields()
                if (this.state.selectedContactsIds.length > 0) {
                    this.setState({ showLoading: true }, () => {

                        //if any files chosen
                        if (this.state.selectedFileList.length > 0) {

                            let filteredUploadData = _.filter(this.state.selectedFileList,
                                function (list) { return list.status && list.status === "done" });

                            // if files need to be uploaded (using upload button)
                            if (filteredUploadData.length > 0) {

                                this.setState({ filteredUploadListCount: filteredUploadData }, () => {
                                    this.uploadFiles(this.state.selectedFileList, this.state.selectedFilesId,
                                        this.state.filteredUploadListCount
                                    ).then((response) => {

                                        if (this.state.filteredUploadListCount.length > 0) {
                                            let concatedArray = _.concat(response.idList, this.state.selectedFilesId)
                                            this.setUpdate(response.object,
                                                response.shortUrl, concatedArray)
                                        }
                                    })
                                })
                            } //if files selected from modal
                            else {
                                this.onAppendURL(this.state.selectedFilesId)
                            }

                        }
                        //no file attchments
                        else {
                            if (this.state.broadcastFor === "sms") {
                                this.onSubmitNewSMS()
                            } else {
                                this.onSubmitNewEMAIL()
                            }
                        }
                    })
                }
                else {
                    message.warning("Please Add Recipients")
                }
            }
        });
    }

    uploadFiles = async (fileList, fileIds, count) => {

        return new Promise(async function (resolve, reject) {

            let objectArray = []
            let uploadListCount = 0

            let shortUrlObjectArray = []
            let uploadedFileId = []
            await count.map(async (res, index) => {

                if (res && res.originFileObj && Object.keys(res.originFileObj).length > 0) {

                    let tempObject = {}
                    let tempShortObject = {}
                    let idObject = {}

                    const file = res.originFileObj;

                    const fileName = file.name;

                    const listingId = localStorage.getItem('selectedListingId');

                    // if (fileName.length === 0) return;

                    const fileUrl = `transactionCSV/${file.name}`;

                    let salt = String(fileUrl) + 'SMS';

                    let urlString = new Hashids(salt, 8);

                    let randomInteger = Math.floor(Math.random() * Math.floor(100000));

                    urlString = urlString.encode(randomInteger);

                    const uploadTask = storage.ref(fileUrl).put(file);
                    uploadTask.on("state_changed", (snapshot) => {
                    }, (error) => {
                        console.log('error upload', error)
                        // Handle unsuccessful uploads
                    }, () => {
                        uploadListCount = uploadListCount + 1;
                    });
                    uploadTask.then(async function (snapshot) {
                        await storage
                            .ref('transactionCSV/')
                            .child(file.name)
                            .getDownloadURL()
                            .then(async uploadUrl => {

                                let _fileId = uuidv1();
                                if (fileUrl.length > 0) {

                                    tempObject = {
                                        id: _fileId,
                                        listingId: listingId,
                                        fileName: fileName,
                                        uploadUrl: uploadUrl
                                    }

                                    tempShortObject = {
                                        id: uuidv4(),
                                        shortURL: urlString,
                                        linkClickCount: 0,
                                        fileId: _fileId
                                    }

                                    idObject = {
                                        'fileId': _fileId,
                                        'url': `${getENVConfig(CONFIG.DOMAIN)}/f/${urlString}`
                                    }

                                    await objectArray.push(tempObject)
                                    await shortUrlObjectArray.push(tempShortObject)
                                    await uploadedFileId.push(idObject)
                                }

                                if (uploadListCount === count.length && objectArray.length === count.length && shortUrlObjectArray.length === count.length) {
                                    let value = {
                                        object: objectArray,
                                        shortUrl: shortUrlObjectArray,
                                        idList: uploadedFileId
                                    }
                                    await resolve(value)
                                }

                            })

                    })
                    .catch(err => {
                        console.log('error 111', err)
                    })

                }
            })
        });
    }

    setUpdate = (objectArray, shortUrlObjectArray, uploadedFileId) => {
        if (objectArray.length > 0 && shortUrlObjectArray.length > 0) {

            let variables = {
                'objects': objectArray,
                'shortObject': shortUrlObjectArray
            }

            client.mutate({ mutation: NOTIFICATION_FILE_UPLOAD, variables: variables }).then((response) => {
                this.onAppendURL(uploadedFileId)
                // message.success('File Uploaded Sucessfully')
            }).catch(err => {
                message.error('error on uploading files')
            });
        } else {
            this.onAppendURL(uploadedFileId)
        }
    }

    onSubmitNewSMS = (insert_sms) => {
        let listingName = localStorage.getItem('selectedListingName')

        const { broadcastDetail } = this.state

        let _data = {
            'smsId': uuidv4(),
            'broadCastId': uuidv1(), /*the timeStamp for broadCast */
            'uid': localStorage.getItem("uid"),
            'content': this.state.broadcastDetail,
            'contactList': this.state.selectedContactsIds,
            arrayOfShortURL: this.state.selectedFilesId,
            'listingId': localStorage.getItem('selectedListingId'),
            'typeOfSMS': this.state.broadcastType,
            'senderId': this.state.broadcastSender,
            "groupList": [],
            'title': this.state.broadcastTitle,
            "smsTemplateSettings": (this.state.selectedTemplateFromDropDown
                && this.state.selectedTemplateFromDropDown.hasOwnProperty('dlt_settings')
                && this.state.selectedTemplateFromDropDown.dlt_settings)
                ? this.state.selectedTemplateFromDropDown.dlt_settings : {}

        };
        console.log('_data123', _data)

        axios({
            method: 'post',
            // url: 'http://localhost:5001/totely-prod1-ba624/us-central1/sendTextLocalSMS',
            url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/sendTextLocalSMS`,
            headers: { 'Content-Type': 'application/json' },
            data: _data
        })
            .then(async result => {
                message.success("Thank you ! Your SMS has been submitted")
                this.setState({
                    broadcastTitle: '', broadcastDetail: '', selectedContactsIds: [],
                    selectedContactsList: [], selectedTemplateId: '',
                    broadcastSender: '', broadcastType: '', showLoading: false,
                    allRecipientsList: [], selectedFileList: [], selectedFilesId: [],
                    allSelectedFilesList: [], selectedTemplateFromDropDown: {}

                }, () => {
                    this.props.history.push(`/app/console/${listingName}/notify/${this.state.broadcastFor}`)
                })
            }
            )
            .catch(err => {
                message.error(err.message)
                this.setState({ showLoading: false })
            })

    }

    onSubmitNewEMAIL = () => {
        let listingName = localStorage.getItem('selectedListingName')
        let { broadcastTitle, broadcastDetail, broadcastSender, broadcastType,
            selectedContactsIds } = this.state;
        let footerVBF = '<small>Click <a href="%unsubscribe_url%">unsubscribe</a> to stop receiving messages. Brought to you by Vet Business Forum </small>';

        let reqObj = {
            'title': broadcastTitle,
            'footer': footerVBF,
            'broadCastId': uuidv1(), /*the timeStamp for broadCast */
            'content': broadcastDetail ? broadcastDetail.replace(/\n\r?/g, '<br/>') : broadcastDetail,
            // 'content': broadcastDetail,
            'listingId': localStorage.getItem('selectedListingId'),
            'promOrTrans': broadcastType,
            'contactList': selectedContactsIds,
            'domain': broadcastSender,
            'uid': localStorage.getItem("uid"),
            "groupList": [],
            "uploadUrl": "",
            "fileId": "",
            "arrayOfShortURL": this.state.selectedFilesId,
            // "listingUrl": window.location.pathname.split("/").pop()
            "listingUrl": localStorage.getItem('selectedListingName')
        };


        axios({
            method: 'post', url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/sendEmail`,
            headers: { 'Content-Type': 'application/json' }, data: reqObj
        })
            .then(result => {
                message.success("Thank you! Your Email has been submitted")
                this.setState({
                    broadcastTitle: '', broadcastDetail: '', selectedContactsIds: [],
                    selectedContactsList: [], selectedTemplateId: '',
                    broadcastSender: '', broadcastType: '', showLoading: false,
                    allRecipientsList: [], selectedFileList: [], selectedFilesId: [],
                    allSelectedFilesList: [], selectedTemplateFromDropDown: {}

                }, () => {
                    this.props.history.push(`/app/console/${listingName}/notify/${this.state.broadcastFor}`)
                })
            }
            )
            .catch(err => {
                message.error(err.message)
                this.setState({ showLoading: false })
            })
    }


    onSaveTemplate = (add_template) => {
        let { broadcastTitle, broadcastDetail, } = this.state;

        const createdBy = localStorage.getItem('uid');
        const listingId = localStorage.getItem('selectedListingId');

        const MY_NAMESPACE = '97da8844-aa0b-4adc-b109-e6623df5a90a';
        const id = uuidv3(String(new Date().getTime()), MY_NAMESPACE);

        var addTemplateData = {
            "content": broadcastDetail,
            "id": id,
            'listingId': listingId,
            "createdBy": createdBy,
            "title": broadcastTitle,
            templateType: 'SMS'
        };
        add_template({ variables: addTemplateData });
    }

    renderTitle = () => {
        let listingName = localStorage.getItem('selectedListingName')
        return (

            <div class="gx-module-box-topbar" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <i className="icon icon-arrow-left gx-icon-btn"
                    onClick={() => this.props.history.push(`/app/console/${listingName}/notify/${this.state.broadcastFor}`)}
                ></i>
                <span className="gx-module-logo" style={{ fontWeight: 600, fontSize: '18px' }}>{this.state.broadcastFor === "sms" ? 'New SMS' : 'New Email'}</span>

                <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '20%' }}>
                    <Icon id="icon-view" type="save" style={{ cursor: 'pointer' }} onClick={(e) => this.handleSubmit(e)} />
                </div>
            </div>
        )
    }

    onChangeTitle = (event, stateValue) => {
        this.setState({ broadcastTitle: event.target.value }, () => {
        })
    }

    onChangeDetail = (event, stateValue) => {
        this.setState({ broadcastDetail: event.target.value }, () => {
        })
    }

    onRemoveRecipients = (item) => {
        let list = []

        let users = _.filter(this.state.selectedContactsList, (data) =>
            !_.includes(item.id, data.id))

        this.state.allRecipientsList.map((res, index) => {
            let userData = cloneDeep(res)
            if (res) {
                if (res.id === item.id) {
                    userData.checkedValue = false
                }
                list.push(userData)
            }
        })

        let ids = _.filter(this.state.selectedContactsIds, (data) =>
            !_.includes(item.id, data))

        this.setState({ selectedContactsList: users, selectedContactsIds: ids, allRecipientsList: list }, () => {
        })
    }

    onUploadFile = (event) => {
        console.log('onUploadFile', event);
        this.setState({ selectedFileList: event.fileList }, () => {
        })
    }

    onCloseSelectFileModal = () => {
        this.setState({ showSelectFileModal: false })
    }

    // onAddCustomer = (userIds, userData) => {
    onAddFiles = (fileData, fileIds) => {
        let listData = this.state.selectedFileList
        let data = _.filter(fileData, function (o) { return o.checkedValue === true; });
        data.map((res, index) => {
            let option = {
                uid: res.id,
                name: res.fileName,
            }
            listData.push(option)
        })
        this.setState({
            selectedFilesId: fileIds,
            showSelectFileModal: false,
            selectedFileList: _.uniqBy(listData, 'uid'),
            allSelectedFilesList: fileData
        }, () => {
        })
    }


    onRemove = (event) => {
        if (event) {
            let list = []

            this.state.allSelectedFilesList.map((res, index) => {
                let userData = cloneDeep(res)
                if (res) {
                    if (res.id === event.uid) {
                        userData.checkedValue = false
                    }
                    list.push(userData)
                }
            })

            let ids = _.filter(this.state.selectedFilesId, (data) =>
                !_.includes(event.uid, data))

            let listids = _.remove(this.state.selectedFileList, function (n) {
                return n.id === event.uid;
            });
            // this.setState({ uploadedList })

            this.setState({ selectedFileList: listids, selectedFilesId: ids, allSelectedFilesList: list }, () => {
            })
        }
    }

    


    render() {
        const { getFieldDecorator } = this.props.form;
        const { disabledState } = this.state
        let broadcastTitle = this.state.broadcastFor === "sms" ? 'SMS' : 'Email'
        let formRequiredValue = this.state.screenOption !== "messageFilter" ? true : false

        return (
            <Spin spinning={this.state.showLoading} >
                <CustomScrollbars className="gx-layout-sider-scrollbar" id="new-sms-view">
                    <Card className="gx-card"
                        title={this.state.screenOption !== "messageFilter" ? this.renderTitle() : ""} >
                        <Form id="messageForm">

                            {/* template */}
                            <div id={messageTemplate}>
                                <Subscription
                                    subscription={TEMPLATE_SUB}
                                    variables={{
                                        listingId: localStorage.getItem('selectedListingId'),
                                        templateType: this.state.broadcastFor === "sms" ? 'SMS' : 'EMAIL'
                                    }}
                                >
                                    {({ data, loading, error }) => {
                                        templatesData = data ? data.template : [];

                                        if (templatesData.length > 0) {
                                            return (
                                                <div id={"messageTemplate"}>
                                                    <FormItem
                                                        // id="template"
                                                        label={`Select ${broadcastTitle} Template`}
                                                        labelCol={{ xs: 24, sm: 5 }}
                                                        wrapperCol={{ xs: 24, sm: 12 }}
                                                    >
                                                        {
                                                            getFieldDecorator('template', {
                                                                initialValue: this.state.selectedTemplateId,
                                                            })
                                                                (
                                                                    <Select
                                                                        placeholder="Select Template"
                                                                        onChange={(event) => this.onSelectTemplate(event)}
                                                                        disabled={disabledState}
                                                                    >
                                                                        {templatesData && templatesData.length > 0 && templatesData.map((res) => {
                                                                            return (
                                                                                <Option value={res.id} key={res.title}>{res.title}</Option>
                                                                            )
                                                                        })}

                                                                    </Select>
                                                                )}
                                                    </FormItem>
                                                </div>
                                            )
                                        } else {
                                            return ''
                                        }

                                    }}
                                </Subscription>
                            </div>

                            {/* type and sender */}
                            <Subscription
                                subscription={SENDER_SUB}
                                variables={{
                                    listingId: localStorage.getItem('selectedListingId'),
                                    type: this.state.broadcastFor
                                }}
                            >
                                {({ data, error }) => {
                                    if (error) return error.message;
                                    let transactionalData = []
                                    const templateArray = data ? data.listing_communication_provider : [];
                                    return (
                                        <div>
                                            {/* type */}
                                            <div id={"messageType"}>
                                                <FormItem
                                                    label={`${broadcastTitle} Type`}
                                                    labelCol={{ xs: 24, sm: 5 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('type', {
                                                        initialValue: this.state.broadcastType,
                                                        rules: [{ required: formRequiredValue, message: 'Please input type!' }],
                                                    })(
                                                        <Radio.Group onChange={(event) => this.onSelectBroadcastType(event)} disabled={disabledState}>
                                                            {
                                                                broadcastTypeData && broadcastTypeData.map((res, index) => {
                                                                    return (
                                                                        <Radio value={res.key} key={res.key}>
                                                                            {res.title}
                                                                        </Radio>
                                                                    )
                                                                })
                                                            }

                                                        </Radio.Group>
                                                    )}
                                                </FormItem>
                                            </div>

                                            {/* sender */}
                                            <div id={"messageSender"}>
                                                <FormItem
                                                    label="Select Sender"
                                                    labelCol={{ xs: 24, sm: 5 }}
                                                    wrapperCol={{ xs: 24, sm: 12 }}
                                                >
                                                    {getFieldDecorator('sender', {
                                                        initialValue: this.state.broadcastSender,
                                                        rules: [{ required: formRequiredValue, message: 'Please select sender!' }],
                                                    })(
                                                        <Select
                                                            placeholder="Select Sender"
                                                            onChange={(event) => this.onSelectSender(event)}
                                                            disabled={disabledState}
                                                        >
                                                            {templateArray && templateArray.length > 0 && templateArray.map((types) => {
                                                                let item = ''
                                                                if (this.state.broadcastType === "PROMOTIONAL") {
                                                                    item = types.communicationScope.promotional
                                                                } else {
                                                                    transactionalData = []
                                                                    if (this.state.broadcastFor === "sms") {
                                                                        transactionalData = cloneDeep(types.communicationScope.transactional)
                                                                        transactionalData.push('PLIVO')
                                                                        transactionalData.push('TOTELY-TXTART')
                                                                        transactionalData.push('TTLYRC-TXTART')
                                                                        transactionalData.push('AMAZTY-TXTART')
                                                                        transactionalData.push('AMAZEX-TXTART')
                                                                        transactionalData.push('AMAZRC-TXTART')
                                                                        transactionalData.push('AMAZTY-TEXTLOCAL')
                                                                        transactionalData.push('AMAZEX-TEXTLOCAL')
                                                                        transactionalData.push('AMAZRC-TEXTLOCAL')
                                                                        item = transactionalData
                                                                    } else {
                                                                        item = types.communicationScope.transactional
                                                                    }
                                                                }
                                                                return item.map((val) => {
                                                                    return (
                                                                        <Option value={val}>{val}</Option>
                                                                    )
                                                                })

                                                            })}

                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </div>
                                        </div>
                                    )
                                }}
                            </Subscription>

                            <div id={"messageTitle"}>
                                {/* title */}
                                <FormItem
                                    label={`${broadcastTitle} Title`}
                                    // label="SMS Title"
                                    labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}
                                >
                                    {getFieldDecorator('title', {
                                        initialValue: this.state.broadcastTitle,
                                        rules: [{ required: formRequiredValue, message: 'Please input title!' }],
                                    })(
                                        <Input onChange={(event) => this.onChangeTitle(event, 'broadcastTitle')}
                                            disabled={disabledState}
                                        />
                                    )}
                                </FormItem>
                            </div>

                            <div id={"messageDetail"}>
                                <FormItem
                                    label={`${broadcastTitle} Detail`}
                                    // label="SMS Title"
                                    labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}
                                >
                                    {getFieldDecorator('smsdetail', {
                                        initialValue: this.state.broadcastDetail,
                                        rules: [{ required: formRequiredValue, message: 'Please input detail!' }],
                                    })(
                                        <TextArea rows={6}
                                            // onChange={(event) => this.onChangeTitle(event, 'broadcastDetail')}
                                            onChange={(event) => this.onChangeDetail(event, 'broadcastDetail')}
                                            disabled={disabledState}
                                        />
                                    )}
                                </FormItem>
                            </div>


                            {/* file attachments */}
                            <div id={"recipients"}>
                                <FormItem
                                    label="Recipients"
                                    labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}
                                >
                                    {getFieldDecorator('detail', {

                                    })(
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Icon id="icon-view" type="user-add" style={{ fontSize: 25, cursor: 'pointer', marginTop: 5 }}
                                                onClick={() => this.setState({ showAddCustomerView: true })}
                                            />
                                        </div>
                                    )}
                                </FormItem>
                            </div>

                            <div id={"fileAttachments"}>
                                {/* file attachments */}
                                <FormItem
                                    label="File Attachments"
                                    labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}
                                >
                                    {getFieldDecorator('detail', {

                                    })(
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Upload
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                fileList={this.state.selectedFileList}
                                                style={{ marginRight: 25 }}
                                                onChange={(data) => this.onUploadFile(data)}
                                                multiple={true}
                                                onRemove={(event) => this.onRemove(event)}
                                                // beforeUpload={() => false}
                                                customRequest={dummyRequest}
                                            >
                                                <Button>
                                                    <Icon id="icon-view" type="upload" /> Upload
                                        </Button>
                                                {/* <Icon type="upload" style={{ fontSize: 25, cursor: 'pointer', marginTop: 5 }} />Upload */}
                                            </Upload>
                                            <Button type="primary" ghost
                                                style={{ marginLeft: 15 }}
                                                onClick={() => this.setState({ showSelectFileModal: true })}
                                            >
                                                Select File
                                </Button>

                                        </div>
                                    )}
                                </FormItem>
                            </div>
                        </Form>


                        {/* add customer modal */}
                        {
                            this.state.showAddCustomerView === true &&
                            <AddUser
                                isOpen={this.state.showAddCustomerView}
                                onCloseModal={this.onCloseModal}
                                onAddCustomer={this.onAddCustomer}
                                selectedContactsList={this.state.allRecipientsList}
                                selectedContactsIdsData={this.state.selectedContactsIds}
                                messageType={this.state.broadcastFor}
                                screenName="messages"
                            />
                        }

                        {/* selected sms recipients list list */}
                        {
                            this.state.selectedContactsList.length > 0 &&
                            <div>
                                <Recipients screenName="newBroadCast"
                                    recipientsList={this.state.selectedContactsList}
                                    onRemoveRecipients={this.onRemoveRecipients}
                                />
                            </div>
                        }

                        {
                            this.state.showSelectFileModal === true &&
                            <SelectFiles
                                isOpen={this.state.showSelectFileModal}
                                onCloseSelectFileModal={this.onCloseSelectFileModal}
                                onAddFiles={this.onAddFiles}
                                selectedFilesList={this.state.allSelectedFilesList}
                                selectedFilesIdData={this.state.selectedFilesId}
                            />
                        }

                        {
                            this.state.showSelectFileModal.length > 0 &&
                            <div></div>
                        }
                    </Card>
                </CustomScrollbars>
            </Spin >
        );
    }

}

const WrappedApp = Form.create()(NewBroadCast);


export default (withRouter(WrappedApp));






