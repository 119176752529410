import React from "react";
import { Tag, Table, Card, Icon } from "antd";
import _ from 'lodash';
import { withRouter } from "react-router-dom"
import { Subscription } from "react-apollo";
import moment from 'moment'
import {
    SMS_RECIPIENTS_SUB,
} from '../../graphQl/subscriptions';
import { CONFIG } from '../../config/config'
import { getENVConfig } from '../../util/common'




class Recipients extends React.Component {

    renderView = (columns, list) => {
        const type = _.includes(this.props.history.location.pathname, 'sms') ? 'SMS' : 'Email'
        return (
            <div style={{ padding: 15, }}>
                <Card className="smsRecipientsTitle">
                    <div style={{ fontWeight: 'bolder', paddingBottom: 12 }}>Recipients</div>
                    <Table className="gx-table-responsive"
                        id="sms-table-view"
                        columns={columns}
                        dataSource={list} />
                </Card>
            </div>
        )
    }

    render() {

        const notifyDetailColumns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Phone Number',
                dataIndex: 'recipientContact',
                key: 'recipientContact',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Status',
                dataIndex: 'deliveryStatus',
                key: 'deliveryStatus',
                className: "name",
                render: text => <span>{text ? <Tag color="orange">{text}</Tag> : '-'}</span>,
            },
            {
                title: 'Link',
                dataIndex: 'attachedFileLinks',
                key: 'transactionUrls',
                className: "name",
                render: (val, record, key) => {
                    if (val.length > 0) {
                        //if any file attached
                        return val.map((_d, index) => {
                            return <a target="_blank" href={`${getENVConfig(CONFIG.DOMAIN)}/f/${_d.shortURL}?mode=inapp`}>
                                {_d.files ? `${_d.files.fileName} (${_d.linkClickCount})` : 'file'}</a>
                        })
                    }
                    //for transaction url
                    else if (record.transactionUrls.length > 0) {
                        return record.transactionUrls.map((res, index) => {
                            return <a href={`${getENVConfig(CONFIG.DOMAIN)}/${res.shortURL}?mode=inapp`} target="_blank" >
                                {`Transaction(${res.linkClickCount})`}</a>
                        })
                    }
                    else {
                        return <p>-</p>
                    }
                }
            },

            {
                title: 'Date and Time',
                dataIndex: 'updatedAt',
                key: 'updatedAt',

                render: (val, record, key) => {
                    if (val) {
                        let date = moment(val).format('MMM DD YYYY, hh:mm A')
                        return <p>{date}</p>
                    }
                    else {
                        return <p>-</p>
                    }
                }
            },
        ];


        const newBroadCastColumns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {

                dataIndex: 'email',
                key: 'action',
                render: (val, record, key) => {
                    return <Icon id="icon-view" type="delete" style={{
                        fontSize: 20,
                        cursor: 'pointer'
                    }} onClick={() => this.props.onRemoveRecipients(record)} />
                }
            },
        ];

        return (
            <div>
                {
                    this.props.screenName === "notifyDetail" &&
                    <Subscription
                        subscription={SMS_RECIPIENTS_SUB}
                        variables={{
                            broadcastId: this.props.selectedListingId
                        }}
                    >
                        {({ data, loading, error }) => {
                            if (error) return error.message

                            return (
                                <div>
                                    {this.renderView(notifyDetailColumns,
                                        data && data.broadcast_logs ? data.broadcast_logs : [])}
                                </div>
                            )
                        }}
                    </Subscription>
                }

                {
                    this.props.screenName === "newBroadCast" &&
                    <div>

                        {this.renderView(newBroadCastColumns, this.props && this.props.recipientsList ? this.props.recipientsList : [])}
                    </div>
                }

            </div >

        );
    }

};

export default withRouter(Recipients)
