import React, { Component } from "react";
import { Table, Form, Input, Button, Popconfirm } from 'antd';
import EligibleStatus from './EligibleStatus'
import Status from './Status'

class Rules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eligibleStatusData: [],
      templateId: {},
      templateIdIdForEdit: ''
    };
  }

  componentDidMount() {
    this.setState({
      templateId: this.props.newTemplateId,
      templateIdIdForEdit: this.props.templateId
    }, () => {
    })
  }

  componentWillReceiveProps(newProps) {

    //create template id
    if (this.props.newTemplateId !== newProps.newTemplateId) {
      this.setState({ templateId: newProps.newTemplateId }, () => {
      })
    }
    //edit template id
    if (this.props.templateId !== newProps.templateId) {
      this.setState({ templateIdIdForEdit: newProps.templateId }, () => {
      })
    }
  }

  onSubmitEligibleStatus = (statusData) => {
    this.setState({ eligibleStatusData: statusData }, () => {
    })
  }


  render() {
    return (
      <div>
        <EligibleStatus
          templateId={this.state.templateId}
          templateIdIdForEdit={this.state.templateIdIdForEdit}
          onSubmitEligibleStatus={this.onSubmitEligibleStatus}
          screen={this.props.screen}
        />
        <Status eligibleStatus={this.state.eligibleStatusData}
          createdTemplateId={this.state.templateId}
          templateIdIdForEdit={this.state.templateIdIdForEdit}
        />
      </div>
    );
  }
}

export default Rules;
