import React, { Component } from "react";
import { firebase } from '../../firebase/firebase';
import { TRANSACTION_DATA_BY_SHORT_URL, CHECK_CONTRACT_ASSIGNED } from '../../graphQl/subscriptions';
import {
    GET_LISTING_DETAIL_BY_URL, GET_TEMPLATE_DETAIL_BY_URL, CHECK_CONTACT_WITH_PHONE_NUMBER_LISTING,
    GET_SHORT_URL, CHECK_LISTING, CHECK_BUSINESS_CONTACT, MESSAGE_TEMPLATE_BY_ID,
    GET_ADMINS_BY_LISTING_ID, MESSAGE_TEMPLATE_RULES_BY_STATUS, GET_SHOP_ORDER_COUNT
} from '../../graphQl/queries';
import {
    INSERT_TRANSACTION_FOR_PUBLIC_URL, ADD_CONTACT_MUTATION,
    ADD_LISTING_URL, CREATE_LISTING_VIA_BUSINESS_CONTACT, ADD_MEMBER_TO_LISTING,
    UPDATE_BUSINESS_CONTACT_ID, ADD_BUSINESS_CONTACT, UPDATE_CONTACT, UPADTE_ORDER_COUNT
} from '../../graphQl/mutations';
import { GET_LEAD_TRANSACTION_DATA, GET_SHORT_URL_SUBS } from '../../graphQl/subscriptions';
import client from '../../apollo/apollo'
import {
    Card, Button, Alert, Tooltip, Spin, Modal, Row, Col, Typography, Layout, Badge
} from 'antd'
import { message, Popconfirm, Icon } from 'antd'
import { withRouter } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars'
import moment from 'moment'
import { AddListingCommunictionProviderSMS, AddListingCommunictionProviderEMAIL } from '../../util/Listings'
import EcommerceForm from '../EcommerceForm/index'
import { cloneDeep } from 'lodash'
import { CONFIG } from '../../config/config';
import { getENVConfig } from '../../util/common'

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import Config from '../../util/config'

// import { CheckCircleOutlined } from '@ant-design/icons';

const uuidv1 = require('uuid/v1');

const publicIp = require('public-ip');

const { Header, Footer, Sider, Content } = Layout;


class OrderSuccessView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sessionId: '',
            loading: false,
            temptransactionId: false,
            userSubmissionMsg: false,
            templateSettings: {},
            listingDetail: {}
        }
    }

    componentDidMount() {
        if (this.props && this.props.history && this.props.history.location && this.props.history.location.pathname) {
            console.log('2222successurl', this.props.history.location.pathname)

            const split = _.split(this.props.history.location.pathname, 'ordersuccess/');
            if (split[1]) {
                const sessionId = split[1]
                this.setState({
                    sessionId: sessionId
                }, () => {
                    console.log('2222', sessionId)
                    this.getSession(sessionId);
                })
            }
        }
    }


    getSession(sessionId) {
        console.log(sessionId, ' i m sessionid')
        // let res = localStorage.getItem('PendingOrderdetails')
        // if (res) {
        //     let pendingOrderDetails = JSON.parse(res);
        //     let templateId = pendingOrderDetails.templateId;

        //     axios({
        //         'method': 'POST',
        //         url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/stripeSessionRetrive`,
        //         // url: `http://localhost:5001/totely-dev1/us-central1/stripeSessionRetrive`,
        //         headers: { 'Content-Type': 'application/json' },
        //         data: {
        //             sessionId: sessionId,
        //             templateId: templateId
        //     }
        //     }).then((res) => {
        //         console.log('Push notification response', res)
        //         if (res && res.data && res.data.success && res.data.success && res.data.success.payment_status === 'paid') {
                    let res = localStorage.getItem('PendingOrderdetails')
                    if (res) {
                        let pendingOrderDetails = JSON.parse(res);

                        this.setState({
                            temptransactionId: pendingOrderDetails.temptransactionId,
                            userSubmissionMsg: pendingOrderDetails.userSubmissionMsg,
                            templateSettings: pendingOrderDetails.templateSettings,
                            listingDetail: pendingOrderDetails.listingDetail
                        }, () => {
                            this.insertTransaction(pendingOrderDetails);
                        })
                    }
                // }
        //     }).
        //         catch((err) => {
        //             console.log('Push notification err', err)
        //         })
        // }
    }

    locale = (string) => {
        console.log(this.state.templateData, 'this.state.templateData')
        if (this.state.templateData &&
            this.state.templateData.templateSettings &&
            this.state.templateData.templateSettings.fieldValues &&
            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues &&
            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
    }

    okPress() {
        // window.location.reload();
        console.log(this.state.temptransactionId, 'this.state.temptransactionId')
        this.getShortUrl(this.state.temptransactionId).then((short_url) => {
            console.log(short_url, 'this.state.temptransactionId short_url')
            if (short_url) {
                window.open(`/${short_url}`, '_self')
            }
        })
    }

    getShortUrl(transId) {
        return new Promise(function (resolve, reject) {
            client.subscribe({ query: GET_SHORT_URL_SUBS, variables: { 'transactionId': transId } })
                .subscribe((res) => {
                    console.log(res, 'this.state.temptransactionId res')
                    if (res && res.data && res.data.short_url.length > 0) {

                        resolve(res.data.short_url[0].shortURL)
                    } else {
                        resolve('')
                    }
                }, err => {
                    resolve('')
                })
        });
    }


    insertTransaction(pendingOrderDetails) {

        let variableObject = pendingOrderDetails.lead_object;
        let arrayOfAssignedObject = pendingOrderDetails.arrayOfAssignedObject;
        let statusHistory = pendingOrderDetails.statusHistory;
        let formValues = pendingOrderDetails.formValues;
        let templateId = pendingOrderDetails.templateId;

        variableObject[0].formData.PaymentDetails = {
            paid: true,
            selectedOption: 'Stripe',
            status: "SUCCESS"
        };
        console.log('insertTransaction', variableObject)
        this.setState({ loading: true }, () => {
            client.mutate({
                mutation: INSERT_TRANSACTION_FOR_PUBLIC_URL,
                variables: {
                    "lead_object": variableObject,
                    "arrayOfAssignedObject": arrayOfAssignedObject,
                    "statusHistory": statusHistory
                }

            }).then((response) => {
                console.log('response', response)
                //for ecommerce order id
                localStorage.removeItem('orderFormData');
                localStorage.removeItem('PendingOrderdetails');


                if (formValues && formValues.orderId) {
                    this.setState({ ecommerceOrderid: formValues.orderId, loading: false, isSumbitSuccess: true }, () => {
                        console.log('formValues.orderId', formValues.orderId)
                        let orderId = formValues.orderId
                        let splitOrderId = orderId.split("-")
                        let tempOrderId = splitOrderId[splitOrderId.length - 1]
                        console.log('splitOrderId', splitOrderId, tempOrderId)
                        client.mutate({
                            mutation: UPADTE_ORDER_COUNT,
                            variables: {
                                templateId: templateId && templateId,
                                orderCount: tempOrderId
                            }

                        }).then((value) => {
                            console.log('UPADTE_ORDER_COUNT', value)
                            //Push notification end point
                            if (response.data &&
                                response.data.insert_transaction &&
                                response.data.insert_transaction.returning &&
                                response.data.insert_transaction.returning[0] &&
                                response.data.insert_transaction.returning[0].id) {
                                axios({
                                    'method': 'POST',
                                    url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/appNotification`,
                                    headers: { 'Content-Type': 'application/json' },
                                    'data': {
                                        transactionId: response.data.insert_transaction.returning[0].id
                                    }
                                }).then((res) => {
                                    console.log('Push notification response', res)
                                }).
                                    catch((err) => {
                                        console.log('Push notification err', err)
                                    })
                            }
                        })
                            .catch(err => {
                                console.log('UPADTE_ORDER_COUNT err', err)
                            })
                        //SELLER NOTIFICAION FLOW STARTS
                        // if (this.state.templateData.templateSettings.fieldValues &&
                        //     this.state.templateData.templateSettings.fieldValues.hasOwnProperty('sellerNotificationDetailsValues') &&
                        //     this.state.templateData.templateSettings.fieldValues.sellerNotificationDetailsValues.length > 0
                        // ) {
                        //     if (response && response.data && response.data.insert_transaction &&
                        //         response.data.insert_transaction.returning.length > 0 &&
                        //         response.data.insert_transaction.returning[0].id) {

                        //         client.subscribe({
                        //             query: GET_SHORT_URL_SUBS,
                        //             variables: { 'transactionId': response.data.insert_transaction.returning[0].id }
                        //         })
                        //             .subscribe((res) => {
                        //                 if (res && res.data && res.data.short_url.length > 0) {
                        //                     const { location } = this.props;
                        //                     var segment_array = [];
                        //                     if (location) {
                        //                         var segment_str = location.pathname; // return segment1/segment2/segment3/segment4
                        //                         segment_array = segment_str.split('/');
                        //                     }
                        //                     const _data = {
                        //                         notificaitonDetails: this.state.templateData.templateSettings.fieldValues.sellerNotificationDetailsValues,
                        //                         orderId: this.state.ecommerceOrderid,
                        //                         customerDetails: this.state.orderSubmittedBy,
                        //                         listingUrl: segment_array[1],
                        //                         listingId: this.state.templateListingId,
                        //                         userId: localStorage.getItem('uid'),
                        //                         transactionId: response.data.insert_transaction.returning[0].id
                        //                     };
                        //                     axios({
                        //                         method: 'post',
                        //                         // url: 'http://localhost:5001/totely-prod1-ba624/us-central1/sellerNotification',
                        //                         url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/sellerNotification`,
                        //                         headers: { 'Content-Type': 'application/json' },
                        //                         data: _data
                        //                     })
                        //                         .then(async result => {
                        //                         })
                        //                         .catch((err) => {
                        //                             message.warning('Failed notifying seller about your order')

                        //                         });
                        //                 } else {

                        //                 }
                        //             }, err => {

                        //             })



                        //     }
                        //     this.setState({ isSumbitSuccess: true }, () => {
                        //         this.setState({ loading: false })
                        //     })
                        // }

                        // //SELLER NOTIFICAION FLOW ENDS
                        // else {
                        //     this.setState({ loading: false, isSumbitSuccess: true });
                        // }
                    })
                } else {
                    this.setState({ loading: false, isSumbitSuccess: true })
                }
            })
                .catch(err => {
                    message.error('Transaction added Failed')
                    console.log(err, 'ordernow er')
                    this.setState({ loading: false, isSumbitSuccess: false })
                });
        });
    }


    render() {
        const { templateSettings, listingDetail, templateData } = this.state
        const productImageVisible = templateSettings && templateSettings.uploadProductImage ? true : false
        const headerImage = templateSettings &&
            templateSettings.fieldValues &&
            templateSettings.fieldValues.HeaderImage &&
            templateSettings.fieldValues.HeaderImage.ImageDetail[0] &&
            templateSettings.fieldValues.HeaderImage.ImageDetail[0].url ?
            templateSettings.fieldValues.HeaderImage.ImageDetail[0].url : null
        const listingImage = listingDetail && listingDetail.listing && listingDetail.listing.listing_img ? listingDetail.listing.listing_img : null
        const showImage = templateSettings &&
            templateSettings.fieldValues &&
            templateSettings.fieldValues.HeaderImage &&
            templateSettings.fieldValues.HeaderImage.showImage ?
            templateSettings.fieldValues.HeaderImage.showImage : false
        const imageUrl = showImage ? headerImage : listingImage
        return (
            <Spin spinning={this.state.loading}>
                <div style={{
                    width: '100%', height: '100vh', display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    {imageUrl &&
                        <img
                            // id="form-upl-profile"
                            style={{ objectFit: 'contain', width: 400 }}
                            src={imageUrl}
                        />
                    }
                    <h5
                        style={{ marginTop: '1%' }}>
                        {
                            this.state.userSubmissionMsg ?
                                <div>
                                    {this.state.userSubmissionMsg}
                                </div> :
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                                    {this.locale('Thank You for ordering')}
                                    {this.state.ecommerceOrderid &&
                                        <div>
                                            , {this.locale('your order id is')} {this.state.ecommerceOrderid}
                                        </div>
                                    }
                                </div>
                        }
                    </h5>
                    <div>
                        {/* {this.state.ecommerceOrderid && */}
                        <Button type="primary" onClick={() => { this.okPress() }}>{this.locale('View Order')}</Button>
                        {/* } */}
                    </div>
                </div>

            </Spin>
        )
    }
}

export default withRouter(OrderSuccessView);
