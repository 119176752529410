import React, { Component } from "react";
import { connect } from "react-redux";
import _ from 'lodash'
import { Menu, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import { Query, Subscription } from "react-apollo";


import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import client from '../../apollo/apollo';
import gql from 'graphql-tag';
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { FORM_TEMPLATE } from '../../graphQl/subscriptions';
import { LISTING_WITH_NAMEURL_SUB, USERID_TO_GET_LISTING, TAB_NAMES, GET_RECEIVED_CONTRACT_LISTING } from 'graphQl/subscriptions';


import IntlMessages from "../../util/IntlMessages";
import Config from '../../util/config'

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

let pathname = '';

const broadCastTabType = [
  {
    title: "SMS",
    type: 'sms'
  },
  {
    title: "Email",
    type: 'email'
  },
]

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listArray: [],
      receivedFlag: false,
      sentVisible: false,
      receivedVisible: false
    }
  }

  componentDidMount() {

    const gqlValue = USERID_TO_GET_LISTING
    const query = gql`
              ${gqlValue}
            `
    client
      .subscribe({
        query,
        variables: { "userId": localStorage.getItem('uid') }
      })
      .subscribe(
        res => {
          if (res.data && res.data.listing_team && res.data.listing_team.length > 0) {
            const dataLength = res.data.listing_team.length
            const temp = []
            res.data.listing_team.map(res => {
              if (res && res.listing && res.listing.listing_urls[0] && res.listing.listing_urls[0].urlName) {
                temp.push(`app/console/${res.listing.listing_urls[0].urlName}`)
                if (dataLength === temp.length) {
                  this.setState({ listArray: temp })
                }
              }
            })
          }
        },
        err => {
          console.log('err', err)
        })
  }

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  componentWillReceiveProps() {
  }

  //when selecting listing team
  onSelectListingName = (res) => {
    if (res.listing && res.listing.businessContact[0] && res.listing.businessContact[0].id) {
      localStorage.setItem('listingBusinessContactId', res.listing.businessContact[0].id)
    } else {
      localStorage.setItem('listingBusinessContactId', '')
    }
    localStorage.setItem('selectedListingId', res.listing.listing_urls[0].listingId)
    localStorage.setItem('selectedListingName', res.listing.listing_urls[0].urlName)
    localStorage.setItem('role', res.role)
    localStorage.setItem('listinglogo', res.listing.listing_img)
    localStorage.setItem('logoListingName', res.listing.name)
    this.setState({ selectedlisting: res.listing.listing_urls[0].urlName, listinglogo: res.listing.listing_img, receivedFlag: false })
  }

  onclickReceied = (res) => {
    localStorage.setItem('receivedListingName', res.name)
    localStorage.setItem('receivedListingId', res.id)
  }

  onClickSent = () => {
    console.log('onClickSent')
    this.setState({ sentVisible: !this.state.sentVisible })
  }

  onClickReceived = () => {
    this.setState({ receivedVisible: !this.state.receivedVisible })
  }

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const forListing = selectedKeys.split('/')[2]
    const forListingSeletedKey = `app/console/${forListing}`
    const defaultOpenKeys = selectedKeys.split('/')[1];
    var words = this.props.pathname.split('/');
    const listingName = words ? words.length > 0 ? words[3] ? words[3] : '' : '' : '';
    const listSelectedKey = _.filter(this.state.listArray, (val) => {
      if (_.includes(selectedKeys, val)) {
        return val
      }
    })
    const checkListSelectedKey = listSelectedKey.toString().length > 0 ? listSelectedKey.toString() : selectedKeys
    const checkPersonalSelectedKey = 'app/console/personal/received'
    const ListSelectedKey = listingName === 'personal' ? checkPersonalSelectedKey : checkListSelectedKey
    return (
      <Auxiliary>
        <SidebarLogo selectedlisting={this.state.selectedlisting} listinglogo={this.state.listinglogo} />
        <div className="gx-sidebar-content" id="sidebar-container">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`} id="login-name-modal">
            <UserProfile />
            {/* <AppsNavigation /> */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar" id="sidebar-scrollbar">

            <Subscription subscription={USERID_TO_GET_LISTING} variables={{ "userId": localStorage.getItem('uid') }}>
              {/* <Query query={query} variables={{ "userId": '2da6c7bb-2fd1-3507-8426-90d70a45b582' }}> */}

              {({ loading, error, data }) => {
                if (loading) {
                  return <div></div>
                }
                if (error) {
                  return false;
                }

                return <Menu
                  defaultOpenKeys={"listingSubMenu"}
                  selectedKeys={[forListingSeletedKey]}
                  theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                  mode="inline">

                  <MenuItemGroup key="main" className="gx-menu-group" style={{ paddingLeft: '0px' }} title={<IntlMessages id="sidebar.main" />}>
                    {/* <div> */}
                    <SubMenu key="listingSubMenu" id="submenu-view-listing" className={this.getNavStyleSubMenuClass(navStyle)}

                      title={<div className="listing-view" style={{ height: 'auto' }}>
                        <Icon type="unordered-list" id="list-icon" />  Listings</div>}>
                      <Menu.Item id="sub-content-view" key={`app/console/listings`}>
                        <Link to={`/app/console/listings`}
                          onClick={() => {
                            localStorage.removeItem('selectedListingId')
                            localStorage.removeItem('selectedListingName')
                            this.setState({ selectedlisting: null, listinglogo: null })
                          }}
                        >
                          <Icon id="icon-view" type="file-add" style={{ marginReft: '10px' }} />
                          <span >Add New Listing</span>
                        </Link>
                      </Menu.Item>
                      {data && data.listing_team && data.listing_team.length > 0 && data.listing_team.map(res => {
                        if (res && res.listing && res.listing.name && res.listing.status && res.listing.status === 'ACTIVE' && res.listing.listing_urls[0] && res.listing.listing_urls[0].urlName) {
                          return (
                            <Menu.Item id="sub-content-view" key={`app/console/${res.listing.listing_urls[0].urlName}`}>
                              <Link to={`/app/console/${res.listing.listing_urls[0].urlName}`}
                                onClick={() => this.onSelectListingName(res)}
                              >
                                <Icon id="icon-view" type="switcher" style={{ marginReft: '10px' }} />
                                <span>{_.startCase(res.listing.name)}</span>
                              </Link>
                            </Menu.Item>
                          )
                        } else {
                          return null
                        }
                      }
                      )}

                      <Menu.Item id="sub-content-view" key={`app/console/personal`}>
                        <Link to={`/app/console/personal/received`}
                          onClick={() => {
                            localStorage.removeItem('selectedListingId')
                            localStorage.removeItem('selectedListingName')
                            this.setState({ selectedlisting: null, listinglogo: null, receivedFlag: true })
                          }}
                        >
                          <Icon id="icon-view" type="user" style={{ marginReft: '10px' }} />
                          <span>Personal</span>
                        </Link>
                      </Menu.Item>



                    </SubMenu>
                    {/* </div> */}
                  </MenuItemGroup>
                </Menu>
              }}

            </Subscription>
            <Subscription
              subscription={LISTING_WITH_NAMEURL_SUB}
              variables={{ name: listingName }}
            >
              {({ data, loading, error }) => {
                if (loading) return null;
                if (error) { return error.message }
                const listing = data.listing_url;
                // debugger
                return <div>
                  {listing.length > 0 &&
                    <Subscription
                      subscription={TAB_NAMES}
                      variables={{ listingId: listing[0].listingId }}>
                      {({ data, loading, error }) => {
                        if (loading)
                          return (
                            <div className="loading"></div>
                          );
                        if (error) return '';

                        const tabsArray = data.transaction_ui;
                        const length = tabsArray.length;

                        // if (length > 0) {
                        return <div>
                          <Menu
                            defaultOpenKeys={[defaultOpenKeys]}
                            selectedKeys={[selectedKeys]}
                            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                            mode="inline">
                            <MenuItemGroup key="main" className="gx-menu-group">
                              <SubMenu id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)} title={<div className="nav-text"><Icon id="icon-view" type="user" />Contact</div>}>
                                <Menu.Item id="sub-content-view" key={`app/console/${listingName}/contact/individual`} style={{ marginBottom: '10px' }}>
                                  <Link to={`/app/console/${listingName}/contact/individual`} id="sample-view">
                                    <Icon id="icon-view" type="file" style={{ marginReft: '10px' }} />
                                    <span>Individual</span>
                                  </Link>
                                </Menu.Item>
                                <Menu.Item id="sub-content-view" key={`app/console/${listingName}/contact/businessContact`} style={{ marginBottom: '10px' }}>
                                  <Link to={`/app/console/${listingName}/contact/businessContact`} id="sample-view">
                                    <Icon type="file" style={{ marginReft: '10px' }} />
                                    <span>Business</span>
                                  </Link>
                                </Menu.Item>
                              </SubMenu>
                            </MenuItemGroup>
                          </Menu>


                          <div style={{ color: 'white', paddingLeft: '30px', marginTop: "0%", fontSize: '14px', marginTop: '15%', marginBottom: '9px', cursor: 'pointer' }} onClick={() => this.onClickSent()}>
                            <Icon id="icon-view" type="unordered-list" class="anticon anticon-message" style={{ marginRight: '12px' }} />
                            {navStyle && navStyle === 'NAV_STYLE_FIXED' ? 'Sent' : ''}</div>
                          {this.state.sentVisible &&
                            <Menu
                              defaultOpenKeys={[defaultOpenKeys]}
                              selectedKeys={[selectedKeys]}
                              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                              mode="inline">
                              <MenuItemGroup key="main" className="gx-menu-group" >
                                <SubMenu key="dashboard" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                  title={<div>
                                    <Icon id="icon-view" type="message" />
                                    Messages

                                    </div>}>

                                  {
                                    broadCastTabType && broadCastTabType.map((res, index) => {
                                      return (
                                        // <ul style={{marginBottom:'8px'}}>
                                        <Menu.Item id="sub-content-modal" key={`app/console/${listingName}/notify/${res.type}`}>
                                          <Link
                                            to={`/app/console/${listingName}/notify/${res.type}`}
                                          //  to={`/notify/${res.type}`}
                                          >
                                            <i className="icon icon-email" />
                                            <span>{res.title}</span>
                                          </Link>
                                        </Menu.Item>
                                        // </ul>
                                      )
                                    })
                                  }

                                  <Menu.Item id="sub-content-modal" key={`app/console/${listingName}/newSmsTemplate`}>
                                    <Link
                                      to={`/app/console/${listingName}/newSmsTemplate`}>
                                      <i className="icon icon-email" />
                                      <span>Templates</span>
                                    </Link>
                                  </Menu.Item>

                                </SubMenu>
                                {/* <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} title={<div className="nav-text"><Icon type="menu-fold" />SENT</div>}> */}
                                {tabsArray.map((tabs, index) =>
                                  // <li className="ui_tooltip menu">
                                  //     <span className="nav-text">{tabs.tabName}</span>
                                  // </li>
                                  <SubMenu key={index} id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)} title={<div className="nav-text">
                                    {/* {tabs.tabName} */}

                                    {tabs.tabName === 'Contract' && <Icon id="icon-view" type="file-pdf" />}
                                    {tabs.tabName === 'Review' && <Icon id="icon-view" type="file-text" />}
                                    {tabs.tabName === 'Registration' && <Icon id="icon-view" type="file-done" />}
                                    {tabs.tabName === 'Lead' && <Icon id="icon-view" type="snippets" />}
                                    {tabs.tabName === 'Order' && <Icon id="icon-view" type="shopping-cart" />}
                                    {tabs.tabName === 'Shipment' && <Icon id="icon-view" type="shopping-cart" />}
                                    {tabs.tabName}</div>}>
                                    <Subscription
                                      subscription={FORM_TEMPLATE}
                                      variables={{ listingId: listing[0].listingId, transactionTemplateStatus: "ACTIVE" }}>
                                      {({ data, loading, error }) => {

                                        if (loading)
                                          return (
                                            <div className="loading">
                                            </div>
                                          );
                                        if (error) return '';

                                        let templateArray = data.transaction_template;

                                        templateArray = templateArray.filter((_) => _.type.toLowerCase() == tabs.transactionType.toLowerCase())
                                        return <div>
                                          <Menu
                                            defaultOpenKeys={[defaultOpenKeys]}
                                            selectedKeys={[selectedKeys]}
                                            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                                            mode="inline">
                                            <MenuItemGroup key="main" className="gx-menu-group">
                                              <Menu.Item id="sub-content-view" key={`app/console/${listingName}/new_transaction`}
                                                onClick={() => {
                                                  this.props.history.push({
                                                    pathname: `/app/console/${listingName}/new_transaction`,
                                                    state: { from: tabs.tabName }
                                                  })
                                                  localStorage.setItem('transactionType', tabs.transactionType);
                                                }}>
                                                {/* <Link to={`/app/console/${listingName}/new_transaction`} */}

                                                <Icon id="icon-view" type="file-add" style={{ marginReft: '10px' }} />
                                                <span>Create New Template</span>
                                                {/* </Link> */}
                                              </Menu.Item>
                                            </MenuItemGroup>
                                          </Menu>
                                          {/* <CustomScrollbars className="gx-todo-detail-content-scroll" id="sidebar-custom-scroll"> */}
                                          <div className={templateArray.length > 4 && "sidbar-text-scroll"} >
                                            <Menu
                                              defaultOpenKeys={[defaultOpenKeys]}
                                              selectedKeys={[selectedKeys]}
                                              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                                              mode="inline">

                                              <MenuItemGroup key="main" className="gx-menu-group">
                                                {templateArray.map((data, item) =>
                                                  <Menu.Item id="sub-content-view" key={`app/console/${listingName}/transactions/${data.id}`} onClick={() => {
                                                    this.props.history.push({
                                                      pathname: `/app/console/${listingName}/transactions/${data.id}`,
                                                      state: { fromTrans: true, tabName: tabs.tabName, templatedata: data }
                                                    })
                                                    localStorage.setItem('transactionType', tabs.transactionType);
                                                  }}>
                                                    <Icon id="icon-view" type="file" style={{ marginReft: '10px' }} />
                                                    <span>{data.name}</span>

                                                  </Menu.Item>

                                                )}

                                              </MenuItemGroup>
                                            </Menu>
                                          </div>
                                          {/* </CustomScrollbars> */}
                                        </div>
                                      }}
                                      {/* </Query> */}
                                    </Subscription>
                                  </SubMenu>
                                )}

                                {/* </SubMenu> */}
                              </MenuItemGroup>
                            </Menu>
                          }

                        </div>
                      }}
                    </Subscription>
                  }
                </div>
              }}
            </Subscription>

            {(this.state.receivedFlag || localStorage.getItem('selectedListingId')) &&
              <div>
                <div style={{ color: 'white', paddingLeft: '30px', marginTop: "0%", fontSize: '14px', marginTop: '15%', marginBottom: '9px', cursor: 'pointer' }} onClick={() => this.onClickReceived()}>
                  <Icon id="icon-view" type="unordered-list" class="anticon anticon-message" style={{ marginRight: '12px' }} />
                  {navStyle && navStyle === 'NAV_STYLE_FIXED' ? 'Received' : ''}</div>
                <Subscription subscription={GET_RECEIVED_CONTRACT_LISTING} variables={{ "p_id": this.state.receivedFlag ? localStorage.getItem('uid') : localStorage.getItem('selectedListingId'), "p_type": this.state.receivedFlag ? "INDIVIDUAL" : "BUSINESS", p_view_type: 'contract' }}>
                  {({ loading, error, data }) => {
                    if (loading) {
                      return <div></div>
                    }
                    if (error) {
                      return false;
                    }

                    return (
                      <div>
                        {this.state.receivedVisible &&
                          <Menu
                            defaultOpenKeys={[defaultOpenKeys]}
                            selectedKeys={[selectedKeys]}
                            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                            mode="inline">
                            <MenuItemGroup key="main" className="gx-menu-group" >
                              <SubMenu key="dashboard" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                title={<div>
                                  <Icon id="icon-view" type="file-pdf" />
                                   Contract
                                   </div>}>

                                {data && data.get_received_listing_by_params && data.get_received_listing_by_params.length > 0 && data.get_received_listing_by_params.map(res => {
                                  if (res && res.listing_urls[0] && res.listing_urls[0].urlName) {
                                    return (
                                      <Menu.Item id="sub-content-view" key={`app/console/${listingName}/received/contract/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}>
                                        <Link to={`/app/console/${listingName}/received/contract/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}
                                          onClick={() => this.onclickReceied(res)}
                                        >
                                          <Icon id="icon-view" type="file" />
                                          <span>{res.listing_urls[0].urlName.toUpperCase()}</span>
                                        </Link>
                                      </Menu.Item>
                                    )
                                  } else {
                                    return null
                                  }
                                }
                                )}
                              </SubMenu>

                              <SubMenu key="receivedOrder" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                title={<div>
                                  <Icon id="icon-view" type="shopping-cart" />
                                   Order
                                   </div>}>

                                <Subscription subscription={GET_RECEIVED_CONTRACT_LISTING} variables={{ "p_id": this.state.receivedFlag ? localStorage.getItem('uid') : localStorage.getItem('selectedListingId'), "p_type": this.state.receivedFlag ? "INDIVIDUAL" : "BUSINESS", p_view_type: 'order' }}>
                                  {({ loading, error, data }) => {
                                    if (loading) {
                                      return <div></div>
                                    }
                                    if (error) {
                                      return false;
                                    }
                                    return (
                                      <div>
                                        {data && data.get_received_listing_by_params && data.get_received_listing_by_params.length > 0 && data.get_received_listing_by_params.map(res => {
                                          if (res && res.listing_urls[0] && res.listing_urls[0].urlName) {
                                            return (
                                              <Menu.Item id="sub-content-view" className="ant-menu-item" key={`app/console/${listingName}/received/order/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}>
                                                <Link to={`/app/console/${listingName}/received/order/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}
                                                  onClick={() => this.onclickReceied(res)}
                                                >
                                                  <Icon id="icon-view" type="file" />
                                                  <span>{res.listing_urls[0].urlName.toUpperCase()}</span>
                                                </Link>
                                              </Menu.Item>
                                            )
                                          } else {
                                            return null
                                          }
                                        }
                                        )}
                                      </div>
                                    )
                                  }}
                                </Subscription>
                              </SubMenu>



                              <SubMenu key="receivedLead" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                title={<div>
                                  <Icon id="icon-view" type="snippets" />
                                   Lead
                                   </div>}>

                                <Subscription subscription={GET_RECEIVED_CONTRACT_LISTING} variables={{ "p_id": this.state.receivedFlag ? localStorage.getItem('uid') : localStorage.getItem('selectedListingId'), "p_type": this.state.receivedFlag ? "INDIVIDUAL" : "BUSINESS", p_view_type: 'lead' }}>
                                  {({ loading, error, data }) => {

                                    if (loading) {
                                      return <div></div>
                                    }
                                    if (error) {
                                      return false;
                                    }
                                    return (
                                      <div>
                                        {data && data.get_received_listing_by_params && data.get_received_listing_by_params.length > 0 && data.get_received_listing_by_params.map(res => {
                                          if (res && res.listing_urls[0] && res.listing_urls[0].urlName) {
                                            return (
                                              <Menu.Item id="sub-content-view" className="ant-menu-item" key={`app/console/${listingName}/received/lead/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}>
                                                <Link to={`/app/console/${listingName}/received/lead/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}
                                                  onClick={() => this.onclickReceied(res)}
                                                >
                                                  <Icon id="icon-view" type="file" />
                                                  <span>{res.listing_urls[0].urlName.toUpperCase()}</span>
                                                </Link>
                                              </Menu.Item>
                                            )
                                          } else {
                                            return null
                                          }
                                        }
                                        )}
                                      </div>
                                    )
                                  }}
                                </Subscription>
                              </SubMenu>

                              <SubMenu key="receivedReview" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                title={<div>
                                  <Icon id="icon-view" type="file-text" />
                                   Review
                                   </div>}>

                                <Subscription subscription={GET_RECEIVED_CONTRACT_LISTING} variables={{ "p_id": this.state.receivedFlag ? localStorage.getItem('uid') : localStorage.getItem('selectedListingId'), "p_type": this.state.receivedFlag ? "INDIVIDUAL" : "BUSINESS", p_view_type: 'review' }}>
                                  {({ loading, error, data }) => {

                                    if (loading) {
                                      return <div></div>
                                    }
                                    if (error) {
                                      return false;
                                    }
                                    return (
                                      <div>
                                        {data && data.get_received_listing_by_params && data.get_received_listing_by_params.length > 0 && data.get_received_listing_by_params.map(res => {
                                          if (res && res.listing_urls[0] && res.listing_urls[0].urlName) {
                                            return (
                                              <Menu.Item id="sub-content-view" className="ant-menu-item" key={`app/console/${listingName}/received/review/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}>
                                                <Link to={`/app/console/${listingName}/received/review/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}
                                                  onClick={() => this.onclickReceied(res)}
                                                >
                                                  <Icon id="icon-view" type="file" style={{ marginLeft: '10px' }} />
                                                  <span>{res.listing_urls[0].urlName.toUpperCase()}</span>
                                                </Link>
                                              </Menu.Item>
                                            )
                                          } else {
                                            return null
                                          }
                                        }
                                        )}
                                      </div>
                                    )
                                  }}
                                </Subscription>
                              </SubMenu>

                              <SubMenu key="receivedRegistration" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                title={<div>
                                  <Icon id="icon-view" type="file-done" />
                                   Registration
                                   </div>}>

                                <Subscription subscription={GET_RECEIVED_CONTRACT_LISTING} variables={{ "p_id": this.state.receivedFlag ? localStorage.getItem('uid') : localStorage.getItem('selectedListingId'), "p_type": this.state.receivedFlag ? "INDIVIDUAL" : "BUSINESS", p_view_type: 'registration' }}>
                                  {({ loading, error, data }) => {

                                    if (loading) {
                                      return <div></div>
                                    }
                                    if (error) {
                                      return false;
                                    }
                                    return (
                                      <div>
                                        {data && data.get_received_listing_by_params && data.get_received_listing_by_params.length > 0 && data.get_received_listing_by_params.map(res => {
                                          if (res && res.listing_urls[0] && res.listing_urls[0].urlName) {
                                            return (
                                              <Menu.Item id="sub-content-view" className="ant-menu-item" key={`app/console/${listingName}/received/registration/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}>
                                                <Link to={`/app/console/${listingName}/received/registration/${this.state.receivedFlag ? 'personal' : 'business'}/${res.id}`}
                                                  onClick={() => this.onclickReceied(res)}
                                                >
                                                  <Icon id="icon-view" type="file" style={{ marginLeft: '10px' }} />
                                                  <span>{res.listing_urls[0].urlName.toUpperCase()}</span>
                                                </Link>
                                              </Menu.Item>
                                            )
                                          } else {
                                            return null
                                          }
                                        }
                                        )}
                                      </div>
                                    )
                                  }}
                                </Subscription>
                              </SubMenu>

                              {this.state.receivedFlag &&
                                <SubMenu key="receivedMessages" id="submenu-view" className={this.getNavStyleSubMenuClass(navStyle)}
                                  title={<div>
                                    <Icon id="icon-view" type="message" />
                                    Messages
                                    </div>}>

                                  {
                                    broadCastTabType && broadCastTabType.map((res, index) => {
                                      return (
                                        <Menu.Item id="sub-content-modal" key={`app/console/personal/received/messages/${res.type}`}>
                                          <Link
                                            to={`/app/console/personal/received/messages/${res.type}`}
                                          >
                                            <i className="icon icon-email" />
                                            <span>{res.title}</span>
                                          </Link>
                                        </Menu.Item>
                                      )
                                    })
                                  }


                                </SubMenu>
                              }
                            </MenuItemGroup>
                          </Menu>
                        }
                      </div>
                    )
                  }}
                </Subscription>
              </div>
            }

          </CustomScrollbars>
        </div>
        <div style={{
           borderTop: '1px solid',  
        position: 'absolute',
            bottom: 10, width:'100%'}} 
            className="sidebar-footer">
          <div
           style={{
            position: 'relative',
            left: '33%'
          }}
          >
            {/* <img src={require("assets/images/logo.png")} style={{ width: 35, paddingLeft: '12px' }} /> */}
            <span align="center" style={{ paddingLeft: '5px', color: '#fff', fontSize: '10px' }}> {`V${Config.version}`} </span>
          </div>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname }
};
export default withRouter(connect(mapStateToProps)(SidebarContent));

