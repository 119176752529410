import React, { Component } from "react";
import { Upload, Button, Icon, message, Spin, Tooltip } from 'antd'
import { storage } from '../../firebase/firebase';
import { withRouter } from 'react-router-dom'
import { GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID } from '../../graphQl/subscriptions'
import client from '../../apollo/apollo';
import { CONFIG } from '../../config/config';
import { getENVConfig } from '../../util/common'


const axios = require('axios');

class UploadFile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedFileList: [],
            showLoading: false,
            templateId: '',
            templateInitailStatus: ''
        }
    }

    componentDidMount() {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({ templateId: this.props.match.params.id }, () => {

                const variables = { "id": this.props.match.params.id };

                client
                    .subscribe({
                        query: GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID,
                        variables: variables
                    })
                    .subscribe(
                        res => {

                            if (res && res.data && res.data.transaction_template.length > 0) {
                                let data = res.data.transaction_template[0]
                                let status = data.eligibleStatusList && data.eligibleStatusList.initialStatus ?
                                    data.eligibleStatusList.initialStatus : ""
                                this.setState({ templateInitailStatus: status })

                            }
                        },
                        err => {
                            console.log('ress on  errr', err);
                        }
                    );
            })
        }
    }

    onUploadFile = (event) => {
        this.setState({ selectedFileList: event.fileList }, () => {
        })
    }

    onUploadFileInStorage = () => {
        if (this.state.selectedFileList.length > 0) {
            this.setState({ showLoading: true }, () => {
                const file = this.state.selectedFileList[0].originFileObj;

                // const fileName = file.name;

                const fileUrl = `transactionCSV/${file.name}`;
                const uploadTask = storage.ref(fileUrl).put(file);

                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        // progress function
                    },
                    error => {
                        this.setState({ showLoading: true })
                        //error function
                    },
                    () => {
                        // complete function
                        storage
                            .ref('transactionCSV')
                            .child(file.name)
                            .getDownloadURL()
                            .then(uploadUrl => {
                                if (fileUrl.length > 0) {
                                    let listingId = localStorage.getItem('selectedListingId');
                                    let createdBy = localStorage.getItem('uid');
                                    let transactionTemplateId = this.state.templateId ? this.state.templateId : '';
                                    var url = `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/readTransactionCSVFileFromCloudStorage`;
                                    // var url = `http://localhost:5001/totely-dev1/us-central1/readTransactionCSVFileFromCloudStorage`
                                    let data = {
                                        initialStatus: this.state.templateInitailStatus,
                                        file: fileUrl,
                                        listingId: listingId,
                                        createdBy: createdBy,
                                        transactionTemplateId: transactionTemplateId
                                    }

                                    console.log('data123', data)

                                    let config = { method: 'POST', url: url, data: data }
                                    message.success("Your file has been submitted")
                                    axios(config)
                                        .then(res => {
                                            console.log('response', res)
                                            if (res.data.count > 0) {
                                                message.success(res.data.message)
                                            } else {
                                                message.error("Something went wrong")
                                            }
                                            this.setState({
                                                showLoading: false,
                                                selectedFileList: []
                                            });
                                        })
                                        .catch(err => {
                                            console.log(err)
                                            this.setState({
                                                fileName: '',
                                            });
                                        });
                                }
                            });
                    }
                );

            })

        }
    }

    render() {
        return (
            <Spin spinning={this.state.showLoading} >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        fileList={this.state.selectedFileList}

                        onChange={(data) => this.onUploadFile(data)}
                    >
                        <Tooltip title="Bulk Upload">
                            <Icon id="icon-view" type="upload" style={{
                                cursor: 'pointer',
                                fontSize: 22
                            }} />
                        </Tooltip>
                    </Upload>
                    {
                        this.state.selectedFileList.length > 0 &&
                        <Tooltip title="Submit">
                            <Icon id="icon-view" type="save" onClick={(e) => this.onUploadFileInStorage(e)} />
                        </Tooltip>

                    }
                </div>
            </Spin>
        )
    }

};

export default (withRouter(UploadFile));
