import React from 'react';
import { connect } from 'react-redux';
import { Button, Checkbox, Form, Icon, Input } from "antd";
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from '../../appRedux/actions/Auth';

const FormItem = Form.Item;

class SignupForm extends React.Component {

  constructor(props) {
    super(props);
    const { name, email, phoneNumber, id, thumb } = props.userInfo;
    this.state = {
      name,
      email,
      phoneNumber,
      id,
      thumb,
      validEmail: true,
    };
  }

  //when typing in fields
  onChangeFields = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { onRegister } = this.props;
        const { name, email, id } = this.state;
        let userName = name ? name.split(" ") : '';
        onRegister({
          firstName: userName && userName[0] ? userName[0] : '',
          lastName: userName && userName[1] ? userName[1] : '',
          email: email,
          id: id,
          thumb: '',
        });

      }
    });
  };

  render() {
    const {
      name,
      email,
      phoneNumber
    } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                {/* <img src='https://via.placeholder.com/272x395' alt='Neature' /> */}
              </div>
              <div className="gx-app-logo-wid">
                <h1><IntlMessages id="app.userAuth.signUp" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
                <p><IntlMessages id="app.userAuth.getAccount" /></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('userName', {
                    initialValue: name,
                    rules: [{ required: true, message: 'Please input your Name!' }],
                  })(
                    <Input placeholder="Name"
                      name="name"
                      onChange={event => this.onChangeFields(event)}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: email,
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email"
                      name="email"
                      onChange={event => this.onChangeFields(event)}
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    initialValue: phoneNumber,
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input placeholder="Phone Number" disabled={true} />
                  )}
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                  {/* <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signin"><IntlMessages
                    id="app.userAuth.signIn" /></Link> */}
                </FormItem>
              </Form>
            </div>
            {/* {loader &&
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            }
            {showMessage &&
              message.error(alertMessage)} */}
          </div>
        </div>
      </div>
    )
  }
}

const WrappedSignUpForm = Form.create()(SignupForm);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn
})(WrappedSignUpForm);
