export const deliveryOptions =
    [
        {
            key: "Delivery",
            value: "Delivery"
        },
        {
            key: "Pickup",
            value: "Pick Up"
        },
        {
            key: "Seating",
            value: "Seating"
        },
   
    ]
