import React from "react";
import { Tag, Table, Button, Popconfirm } from "antd";
import { Subscription } from "react-apollo";
import moment from 'moment'
import { message, Spin } from 'antd'
import { CONFIG } from '../../../config/config';
import { getENVConfig } from '../../../util/common'

const axios = require('axios');

class NotificationHistory extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showLoading: false
        }
    }

    onRenotifyItem = (record) => {
        this.setState({ showLoading: true }, () => {
            let _data = { transactionArray: this.props.transactionId, notifyOptions: this.props.transactionStatus }
            let headers = { 'Content-Type': 'application/json' };

            axios({ method: 'POST', url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/renotify`, headers: headers, data: _data }).then((_res) => {
                this.setState({ showLoading: false })
                message.success('Message sent sucessfully!')
            }).catch(err => {
                this.setState({ showLoading: false })
                message.error(err.message)
            });
        })
    }

    render() {
        const notifyDetailColumns = [
            {
                title: 'Transaction Status',
                dataIndex: 'Status',
                key: 'Status',
                className: "name",
                render: text => <span className="gx-link">{text ? text : '-'}</span>,
            },
            {
                title: 'Type',
                dataIndex: 'notifyType',
                key: 'notifyType',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Recipient',
                dataIndex: 'Recipient',
                key: 'Recipient',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            {
                title: 'Delivery Status',
                dataIndex: 'deliveryStatus',
                key: 'deliveryStatus',
                className: "name",
                render: text => <span className="gx-link">{text ? <Tag color="orange">{text}</Tag> : '-'}</span>,
            },
            {
                title: 'Link Clicked',
                dataIndex: 'linkClicked',
                key: 'linkClicked',
                className: "name",
                render: text => <span className="gx-link">{text ? text : '-'}</span>,
            },
            {
                title: 'Sent At',
                dataIndex: 'sentAt',
                key: 'sentAt',
                className: "name",
                render: text => <span>{text ? text : '-'}</span>,
            },
            // {
            //     // title: 'Sent At',
            //     dataIndex: 'reNotify',
            //     key: 'reNotify',
            //     className: "name",
            //     render: (val, record, key) => {
            //         return <Button style={{
            //             cursor: 'pointer'
            //         }} onClick={() => this.onRenotifyItem(record)}>Re-Notify</Button>
            //     }
            // },   
        ];

        console.log('this.props notify his', this.props)

        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',marginTop:'3%' }}>
                    <h5 className="notification-history-view">Notification History</h5>
                    <div style={{ paddingRight: 10 }}>
                        <Popconfirm
                            title="Are you sure to renotify?"
                            onConfirm={() => { this.onRenotifyItem() }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button style={{
                                cursor: 'pointer'
                            }} >Re-Notify</Button>
                        </Popconfirm>

                    </div>
                </div>
                <Spin spinning={this.state.showLoading}>
                    <Table className="gx-table-responsive"
                        columns={notifyDetailColumns}
                        dataSource={this.props && this.props.notificationHistoryData.length > 0 ?
                            this.props.notificationHistoryData : []} />
                </Spin>

            </div>

        );
    }

};

export default NotificationHistory;
