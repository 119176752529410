import React, {Component} from 'react';
import { getDownloadLink  } from '../../graphQl/queries';
import { updateCount } from '../../graphQl/mutations';
import client from '../../apollo/apollo';
import { Redirect , Route} from 'react-router-dom'


class RedirectToD extends Component{
	constructor() {
		super();
		this.state = { 
			loading:true,
			redirectUrl:''
		}
	}
	
	async componentDidMount(){
		const { fileUrl } = this.props.match.params;
		const { search } = this.props.location;
		let _downloadLink = await this.FdownloadLink(fileUrl);
		if(_downloadLink['success'] == true){
			let count = _downloadLink['linkClickCount'] + 1;
			
			if(search.includes('inapp') == false){
				this.updateCount(fileUrl ,count);
			}
			this.setState({ loading:false , redirectUrl: _downloadLink['redirectUrl'] });
		}
	}
	redirect(url){
		window.location.href = url;
	}
	updateCount(fileUrl , count){
		return new Promise((resolve ,reject) => {
			client.mutate({mutation:updateCount , variables:{ shortUrl:fileUrl , count:Number(count) } }).then((_res) => {
				
				console.log(_res)
				resolve({_res:_res})
			}).catch((_err) => {
				console.log(_err)
				reject({_err:_err})
			})
		});
	}
	FdownloadLink(fileUrl){
	    return new Promise((resolve, reject) => {
	      client.query({ query: getDownloadLink, variables: { shortURL: fileUrl } }).then((_res) => {
	        if (_res.data.short_url.length > 0 && _res.data.short_url[0]['files'] != null ) {
	          
	          resolve({ redirectUrl : _res.data.short_url[0]['files']['uploadUrl'] , success:true , linkClickCount: _res.data.short_url[0]['linkClickCount'] })
	        } else {
	          resolve({ success: false })
	        }
	      }).catch(_err => reject({ success: false, _err: _err }));
	    });
	}
	render(){
		let { loading , redirectUrl} = this.state;
		
		if(loading){
			return 'Loading'
		}else{
			return(
				<React.Fragment>
					{this.redirect(redirectUrl)}
				</React.Fragment>
				)
		}
	}
}

export default RedirectToD;
