import React, { Component } from 'react'
import _, { cloneDeep } from 'lodash'
import { storage } from '../../../firebase/firebase';
import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox, Upload, Modal, Spin } from 'antd';
import AddProductConfig from './AddProductConfig'

const uuidv1 = require('uuid/v1');

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export class AddProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            LoopItems: [],
            config: [],
            previewVisible: false,
            headerImage: {
                ImageDetail: [],
                showImage: false
            },
            imageLoading: false
        }
    }

    componentWillMount() {
        console.log('add will', this.props)
        this.setState({ LoopItems: this.props.LoopItems }, () => { })

    }

    componentWillReceiveProps(nxtprops) {
        console.log('add product', nxtprops, this.props)
        if (nxtprops.LoopItems && nxtprops.LoopItems && nxtprops.LoopItems !== this.props.LoopItems) {
            this.setState({ LoopItems: nxtprops.LoopItems })
        }
    }

    onRemoveRow = (index) => {
        let LoopItems = cloneDeep(this.state.LoopItems)
        LoopItems[index].isDelete = true
        this.setState({ LoopItems }, () => {
            this.props.form.validateFields(async (err, val) => {
                if (!err) {
                    this.props.LoopItemsChange(this.state.LoopItems, this.props.accessFrom);
                } else {
                    // this.props.LoopItemsChange([], this.props.accessFrom);
                }
            });
        });
    }


    onvaluesChange(index, fieldName, e, option) {
        if (option === "checkbox") {
            this.state.LoopItems[index][fieldName] = e.target.checked
        } else {
            if (fieldName === 'productImage') {
                this.state.LoopItems[index][fieldName] = e
            }
            else {
                this.state.LoopItems[index][fieldName] = e.target.value;
            }
        }

        this.setState({ LoopItems: this.state.LoopItems }, () => {
            this.props.form.validateFields(async (err, val) => {
                if (!err) {
                    this.props.LoopItemsChange(this.state.LoopItems, this.props.accessFrom);
                } else {
                    // this.props.LoopItemsChange([], this.props.accessFrom);
                }
            })
        })
    }

    AddConfigRow = () => {
        let data = this.state.config
        let config = {
            name: '',
            price: ''
        }

        data.push(config)

        this.setState({ config: data })
    }

    LoopItemsChange(config, index) {
        this.setState({ LoopItems: config }, () => {
            this.props.LoopItemsChange(this.state.LoopItems, this.props.accessFrom);
        })
    }

    onRemove(config) {
        this.setState({ config });
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = (info, index) => {
        console.log('handleChange', info, index)
        let data = cloneDeep(this.state.headerImage)
        let file = null;
        if (info.fileList) {
            if (info.fileList.length > 0) {
                file = info.fileList[info.fileList.length - 1] && info.fileList[info.fileList.length - 1].originFileObj;
            }
        }
        if (file) {
            this.setState({ imageLoading: true }, () => {
                const file = info.fileList[0].originFileObj;
                const fileName = file.name;
                const fileUrl = `ecommerce/${file.name}`;
                const uploadTask = storage.ref(fileUrl).put(file);
                uploadTask.on(
                    'state_changed',
                    snapshot => {
                        snapshot.ref.getDownloadURL().then((downloadURL) => {
                            // message.success(`${info.file.name} file uploaded successfully`);
                            if (downloadURL) {
                                let _fileId = uuidv1();
                                const imageData = [{
                                    uid: _fileId,
                                    name: fileName,
                                    status: 'done',
                                    url: downloadURL
                                }];
                                data.ImageDetail = imageData;
                                this.setState({ headerImage: data, imageLoading: false }, () => {
                                    console.log('headerImage', this.state.headerImage)
                                    this.onvaluesChange(index, 'productImage', this.state.headerImage.ImageDetail)
                                })
                            } else {
                                this.setState({ imageLoading: false })
                            }
                        });
                        // progress function
                    },
                    error => {
                        this.setState({ imageLoading: false })
                    });
            })
        } else {
            console.log('else', file)
            this.onvaluesChange(index, 'productImage', null)
        }

    }

    onRemoveImage(index) {
        const { LoopItems } = this.state
        let data = LoopItems
        data[index].productImage = null
        this.setState({ LoopItems: data })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    render() {
        const { LoopItems, config, headerImage, previewVisible, previewImage, imageLoading } = this.state;
        const visibleCategory = this.props && this.props.type && this.props.type === 'edit' ? true : false
        const imagevisible = this.props && this.props.productImage ? true : false
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const imageUrl = "https://firebasestorage.googleapis.com/v0/b/totely…=media&token=e7884d10-ed02-468e-9590-c0d3e2af0947"
        getFieldDecorator('keys', { initialValue: [] });
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 4 },
            },
        };
        let first = true;
        const uploadButton = (
            <div>
                <Icon id="icon-view" type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div>
                {LoopItems && LoopItems.length > 0 && LoopItems.map((k, index) => {
                    if (k && !k.isDelete) {
                        try {
                            return (
                                <div>
                                    <Form.Item
                                        {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                        required={false}
                                        key={k}

                                    >
                                        <Row span={24} className="category-container">
                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                {first && <h6>Category</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                {first && <h6>Product Name</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={3} lg={4}>
                                                {first && <h6>Product Description</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2} className="product-code-text">
                                                {first && <h6>Product code</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6>CGST</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6>SGST</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6>IGST</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={3}>
                                                {first && <h6>Config Name</h6>}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6>Config Price</h6>}
                                            </Col>
                                            {imagevisible &&
                                                <Col xs={12} sm={12} md={2} lg={2}>
                                                    {first && <h6 style={{ position: 'absolute', top: 46 }}>Image</h6>}
                                                </Col>
                                            }

                                        </Row>

                                        <Row span={24} className="category-container">

                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                {getFieldDecorator(`category[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].category ? LoopItems[index].category : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'category', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                {getFieldDecorator(`productName[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productName ? LoopItems[index].productName : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productName', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={3} lg={4}>
                                                {getFieldDecorator(`productDescription[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productDescription ? LoopItems[index].productDescription : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: false,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productDescription', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {getFieldDecorator(`productCode[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productCode ? LoopItems[index].productCode : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productCode', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {getFieldDecorator(`CGST[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].CGST ? LoopItems[index].CGST : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'CGST', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {getFieldDecorator(`SGST[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].SGST ? LoopItems[index].SGST : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'SGST', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {getFieldDecorator(`IGST[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].IGST ? LoopItems[index].IGST : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'IGST', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={3}>
                                                {getFieldDecorator(`productConfigName1[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productConfigName1 ? LoopItems[index].productConfigName1 : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productConfigName1', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {getFieldDecorator(`productConfigPrice1[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productConfigPrice1 ? LoopItems[index].productConfigPrice1 : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productConfigPrice1', e)} />)}
                                            </Col>

                                            {imagevisible &&
                                                <Col xs={12} sm={12} md={2} lg={2} style={{ position: 'relative', top: 35 }}>
                                                    {getFieldDecorator(`productImage[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].productImage ? LoopItems[index].productImage.url : null,
                                                        rules: [
                                                            {
                                                                required: false,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        // <Input onChange={(e) => this.onvaluesChange(index, 'image', e)} />
                                                        <div>
                                                            <Spin spinning={imageLoading}>
                                                                <Upload
                                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                                    listType="picture-card"
                                                                    fileList={LoopItems[index].productImage}
                                                                    onPreview={this.handlePreview}
                                                                    onChange={(val) => this.handleChange(val, index)}
                                                                    onRemove={() => this.onRemoveImage(index)}
                                                                // disabled={this.state.disabled}
                                                                >
                                                                    {LoopItems[index].productImage ? null : uploadButton}
                                                                </Upload>
                                                            </Spin>
                                                        </div>
                                                    )}
                                                </Col>
                                            }
                                            <Modal className="modal-popup" visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                                <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                            </Modal>

                                            {
                                                localStorage.getItem("role") && localStorage.getItem("role") === "listingAdmin" &&
                                                <Col xs={12} sm={12} md={12} lg={4} style={{marginTop:35, marginLeft:12}}>
                                                    {getFieldDecorator(`hideFromCatalog[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].hideFromCatalog ? LoopItems[index].hideFromCatalog : '',

                                                    })(
                                                        <Checkbox checked={LoopItems[index].hideFromCatalog}
                                                            onChange={(e) => this.onvaluesChange(index, 'hideFromCatalog', e, 'checkbox')}>Hide from catalog</Checkbox>)}
                                                </Col>
                                            }


                                            {/* <Col>
                                                <AddProductConfig
                                                    LoopItems={config}
                                                    final={LoopItems}
                                                    index={index}
                                                    LoopItemsChange={this.LoopItemsChange.bind(this)}
                                                    onRemove={this.onRemove.bind(this)}
                                                />
                                                <Form.Item {...formItemLayoutWithOutLabel} >
                                                    <Button type="dashed" onClick={() => this.AddConfigRow(index)} style={{ width: '100px' }} >
                                                        <Icon id="icon-view" type="plus" />
                                                    </Button>
                                                </Form.Item>
                                            </Col> */}

                                            <Col className="minus-icon-view" span={1}
                                                style={imagevisible && { position: 'relative', top: 35, left: 35 }}
                                            >
                                                {/* {keys.length > 1 ? ( */}
                                                <Icon
                                                    id="icon-view"
                                                    className="dynamic-delete-button"
                                                    type="delete"
                                                    onClick={() => this.onRemoveRow(index)}
                                                    style={{ fontSize: '16px' }}
                                                />
                                                {/* ) : null} */}
                                            </Col>
                                        </Row>

                                        <Row span={24} className="product-mobile-container">
                                            <Col xs={12} sm={12} md={3} lg={2}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Category</h6>}
                                                {getFieldDecorator(`category[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].category ? LoopItems[index].category : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'category', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={3} lg={3}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Product Name</h6>}
                                                {getFieldDecorator(`productName[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productName ? LoopItems[index].productName : '',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productName', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={3} lg={4}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Product Description</h6>}
                                                {getFieldDecorator(`productDescription[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productDescription ? LoopItems[index].productDescription : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productDescription', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={3}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Product code</h6>}
                                                {getFieldDecorator(`productCode[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productCode ? LoopItems[index].productCode : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productCode', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>CGST</h6>}
                                                {getFieldDecorator(`CGST[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].CGST ? LoopItems[index].CGST : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'CGST', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>SGST</h6>}
                                                {getFieldDecorator(`SGST[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].SGST ? LoopItems[index].SGST : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'SGST', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>IGST</h6>}
                                                {getFieldDecorator(`IGST[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].IGST ? LoopItems[index].IGST : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'IGST', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={3}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Config Name</h6>}
                                                {getFieldDecorator(`productConfigName1[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productConfigName1 ? LoopItems[index].productConfigName1 : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productConfigName1', e)} />)}
                                            </Col>

                                            <Col xs={12} sm={12} md={2} lg={2}>
                                                {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Config Price</h6>}
                                                {getFieldDecorator(`productConfigPrice1[${index}]`, {
                                                    initialValue: LoopItems[index] && LoopItems[index].productConfigPrice1 ? LoopItems[index].productConfigPrice1 : '',
                                                    rules: [
                                                        {
                                                            required: false,
                                                            whitespace: true,
                                                            message: "Please input the field.",
                                                        },
                                                    ],
                                                })(
                                                    <Input onChange={(e) => this.onvaluesChange(index, 'productConfigPrice1', e)} />)}
                                            </Col>


                                            {/* <Col>
                                                <AddProductConfig
                                                    LoopItems={config}
                                                    final={LoopItems}
                                                    index={index}
                                                    LoopItemsChange={this.LoopItemsChange.bind(this)}
                                                    onRemove={this.onRemove.bind(this)}
                                                />
                                                <Form.Item {...formItemLayoutWithOutLabel} >
                                                    <Button type="dashed" onClick={() => this.AddConfigRow(index)} style={{ width: '100px' }} >
                                                        <Icon id="icon-view" type="plus" />
                                                    </Button>
                                                </Form.Item>
                                            </Col> */}

                                            <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                {/* {keys.length > 1 ? ( */}
                                                <Icon
                                                    id="icon-view"
                                                    className="dynamic-delete-button"
                                                    type="delete"
                                                    onClick={() => this.onRemoveRow(index)}
                                                    style={{ fontSize: '16px' }}
                                                />
                                                {/* ) : null} */}
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </div>
                            )
                        } finally {
                            first = false;

                        }
                    }
                })
                }
            </div>
        )
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(AddProducts);
export default WrappedDynamicFieldSet