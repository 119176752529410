import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from './DynamicFields';


const { Option } = Select;

let initialData = {
    attributeName: '',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: ''
}

class DiscountPromotions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            DiscoutsDetails: [],
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        console.log(this.props.editData, 'editData')
        if (this.props.editData) {
            this.setState({ DiscoutsDetails: this.props.editData })
        } else {
            if (this.state.DiscoutsDetails.length === 0) {
                let data = this.state.DiscoutsDetails
                data.push(initialData)
                this.setState({ DiscoutsDetails: data, loading: false })
            }
        }

    }

    onAddRow = () => {
        let data = this.state.DiscoutsDetails
        let initialData = {
            attributeName: '',
            attributeValue: '',
            showAttributeName: false,
            required: false,
            attributeType: ''
        }

        if(data) {data.push(initialData)}
        this.setState({ DiscoutsDetails: data })
    }


    LoopItemsChange(DiscoutsDetails) {
        this.setState({ DiscoutsDetails }, () => {
            this.props.DiscountPromotionsSave(DiscoutsDetails);
        })
    }

    onRemove(DiscoutsDetails) {
        this.setState({ DiscoutsDetails });
    }


    render() {
        const { DiscoutsDetails } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        console.log(DiscoutsDetails, 'cutomerdetails')
        return (
            <Form
                onSubmit={this.handleSubmit}
            >

                {/* <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Add fields that you want the customer to fill such as Name, address etc in the order form. The form field name displays the title like Name. Leave the form field value blank for the customer to fill.
                    </span>
                </div> */}

                <div >
                    <DynamicFields
                        accessFrom='DiscountsPromotions'
                        LoopItems={DiscoutsDetails}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        isEdit={this.props.isEdit ? true : false}
                        listingDetail={this.props.listingDetail && this.props.listingDetail}
                    />
                    {/* {formItems} */}
                </div>
                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item {...formItemLayoutWithOutLabel} >
                        <Button type="primary" htmlType="submit" >
                            Save
                            </Button>
                    </Form.Item>
                </div> */}
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(DiscountPromotions);
export default WrappedDynamicFieldSet

