import client from '../apollo/apollo'
import { CHECK_CATEGORIES } from '../graphQl/queries'
import _ from 'lodash'
import { create } from 'html-pdf';
const uuidv1 = require('uuid/v4');


export function formatCsv(data, tempId) {
    return new Promise(function (resolve, reject) {
        try {
            const productArr = []
            const categoryArr = []
            const uniqueCat = []
            console.log('formatCsv', data)
            data.map(res => {
                categoryArr.push(res.category)
                if (categoryArr.length === data.length) {
                    const temp = _.uniq(categoryArr);

                    temp.map((val, categoryIdex) => {
                        let categoryId = generateUuid()
                        let categoryParams = {
                            category_name: val,
                            category_id: categoryId,
                            listing_id: localStorage.getItem('selectedListingId'),
                            transaction_template_id: tempId,
                            category_serial_no: categoryIdex + 1
                        }
                        uniqueCat.push(categoryParams)
                        if (uniqueCat.length === temp.length) {
                            data.map((ref, index) => {
                                let nameCount = 0
                                let priceCount = 0
                                let attributeNameCout = 0
                                let attributeValueCout = 0
                                let lowerCaseKeys = {};
                                for (let [key, value] of Object.entries(ref)) {
                                    lowerCaseKeys[key.toLowerCase()] = value;
                                }
                                let tempArr = []
                                let attributeArr = []
                                _.mapKeys(ref, function (value, key) {
                                    if (_.startsWith(key, 'productConfigName')) {
                                        nameCount++
                                        if (`productConfigName${nameCount}` === key) {
                                            let val = {
                                                name: value,
                                                max_length: null,
                                                min_length: null
                                            }
                                            tempArr.push(val)
                                        }
                                    }

                                    if (_.startsWith(key, 'productConfigPrice')) {
                                        priceCount++
                                        if (`productConfigPrice${priceCount}` === key) {
                                            let pricevalue = value.replace(/[ ,]/g, "");
                                            tempArr[priceCount - 1].price = pricevalue
                                        }
                                    }

                                    if (_.startsWith(key, 'attributeName')) {
                                        attributeNameCout++
                                        if (`attributeName${attributeNameCout}` === key) {
                                            let val = {
                                                attributeName: value
                                            }
                                            attributeArr.push(val)
                                        }
                                    }

                                    if (_.startsWith(key, 'attributeValue')) {
                                        attributeValueCout++
                                        if (`attributeValue${attributeValueCout}` === key) {
                                            attributeArr[attributeValueCout - 1].attributeValue = value
                                        }
                                    }
                                    

                                })

                                console.log('attributeArr', attributeArr)

                                let finalCatId = ''
                                uniqueCat.map(val => {
                                    if (val.category_name === ref.category) {
                                        finalCatId = val.category_id
                                    }
                                })

                                let productId = generateUuid()

                                let taxes = {
                                    CGST: ref.CGST ? ref.CGST : '',
                                    SGST: ref.SGST ? ref.SGST : '',
                                    IGST: ref.IGST ? ref.IGST : ''
                                }


                                let productParams = {
                                    product_id: productId,
                                    product_code: ref.productCode,
                                    product_name: ref.productName,
                                    product_description: ref.productDescription,
                                    product_config: tempArr,
                                    product_attribute: attributeArr,
                                    product_baseprice: ref.basePrice,
                                    product_hsn_code: null,
                                    category_id: finalCatId,
                                    listing_id: localStorage.getItem('selectedListingId'),
                                    transaction_template_id: tempId,
                                    product_taxes: taxes,
                                    product_image: ref.productImage ? ref.productImage : null,
                                    product_serial_no: index + 1,
                                    sellQuantity:  ref.sellQuantity,
                                    displayQuantity: ref.displayQuantity
                                }
                                productArr.push(productParams)

                                if (productArr.length === data.length) {
                                    let returnParams = {
                                        products: productArr,
                                        categories: uniqueCat
                                    }
                                    resolve(returnParams)
                                }
                            })
                        }
                    })
                }
            })
        }
        catch (e) {
            reject(e)
        }
    })
}

function generateUuid() {
    let MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
    let randomValue = Math.random().toString(36).substring(7)
    let uuid = uuidv1(randomValue, MY_NAMESPACE);
    return uuid
}


export function formatEditProductCatalog(data, tempId) {
    return new Promise(function (resolve, reject) {
        try {
            console.log('formatEditProductCatalog', data, tempId)
            const productArr = []
            data.map(ref => {
                let nameCount = 0
                let priceCount = 0
                let lowerCaseKeys = {};
                for (let [key, value] of Object.entries(ref)) {
                    lowerCaseKeys[key.toLowerCase()] = value;
                }
                let tempArr = []
                _.mapKeys(ref, function (value, key) {
                    if (_.startsWith(key, 'productConfigName')) {
                        nameCount++
                        if (`productConfigName${nameCount}` === key) {
                            let val = {
                                name: value,
                                max_length: null,
                                min_length: null
                            }
                            tempArr.push(val)
                        }
                    }
                    if (_.startsWith(key, 'productConfigPrice')) {
                        priceCount++
                        if (`productConfigPrice${priceCount}` === key) {
                            let pricevalue = value.replace(/[ ,]/g, "");
                            tempArr[priceCount - 1].price = pricevalue
                        }
                    }

                })

                // let finalCatId = ''
                // uniqueCat.map(val => {
                //     if (val.category_name === ref.category) {
                //         finalCatId = val.category_id
                //     }
                // })

                // let productId = generateUuid()

                let taxes = {
                    CGST: ref.CGST,
                    SGST: ref.SGST,
                    IGST: ref.IGST
                }


                let productParams = {
                    product_image: ref.productImage ? ref.productImage : null,
                    category_name: ref.category,
                    product_id: ref.product_id ? ref.product_id : null,
                    product_code: ref.productCode,
                    product_name: ref.productName,
                    product_description: ref.productDescription,
                    product_config: tempArr,
                    product_hsn_code: null,
                    category_id: ref.category_id ? ref.category_id : null,
                    listing_id: localStorage.getItem('selectedListingId'),
                    transaction_template_id: tempId,
                    product_taxes: taxes,
                    type: ref.isDelete ? 'DELETE' : ref.product_id ? 'UPDATE' : 'CREATE',
                    createCategory: false,
                    hide_from_catalog: ref.hideFromCatalog ? ref.hideFromCatalog : false,
                }
                productArr.push(productParams)

                if (productArr.length === data.length) {

                    let returnParams = {
                        products: productArr,
                    }
                    resolve(returnParams)


                    // let createArr = _.filter(productArr, (o) => {
                    //     if (o.type === 'CREATE') {
                    //         return o
                    //     }
                    // })

                    // let exceptCreate = _.filter(productArr, (o) => {
                    //     if (o.type !== 'CREATE') {
                    //         return o
                    //     }
                    // })

                    // let tempArr = []

                    // createArr.map(val => {
                    //     let params = {
                    //         listingId: val.listing_id,
                    //         templateId: val.transaction_template_id,
                    //         category_name: val.category_name
                    //     }
                    //     checkCategories(params)
                    //         .then(ref => {
                    //             console.log('1111', ref)
                    //             let tempId = generateUuid()
                    //             val.category_id = ref ? ref : tempId
                    //             val.createCategory = ref ? false : true
                    //             tempArr.push(val)

                    //             if (tempArr.length === createArr.length) {
                    //                 console.log('tempArr', tempArr)
                    //                 const contact = _.concat(exceptCreate, tempArr)
                    //                 console.log('contact', contact)
                    //                 if (contact.length === productArr.length) {
                    //                     let returnParams = {
                    //                         products: contact,
                    //                     }
                    //                     resolve(returnParams)
                    //                 }
                    //             }
                    //         })
                    //         .catch(err => {
                    //             console.log('err', err)
                    //         })
                    // })


                    // if (productArr.length === createArr.length) {
                    //     let returnParams = {
                    //         products: createArr,
                    //     }
                    //     resolve(returnParams)
                    // }
                }
            })
        }
        catch (e) {
            reject(e)
        }
    })
}

function checkCategories(params) {
    return new Promise(function (resolve, reject) {
        client.query({ query: CHECK_CATEGORIES, variables: params })
            .then((response) => {
                if (response.data && response.data.listing_product_categories && response.data.listing_product_categories[0] && response.data.listing_product_categories[0].category_id) {
                    let id = response.data.listing_product_categories[0].category_id
                    resolve(id)
                } else {
                    resolve(null)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}
