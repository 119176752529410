import React, { Component } from 'react'
import { Form, Icon, Button, Select, message, Row, Col, Spin } from 'antd';
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';
// import { GET_TEMPLATES_BY_LISTING_ID } from '../../graphQl/queries'
import { saveRules } from '../../gql/mutation/templateTransaction/index'
import { TEMPLATE_SUB, GET_TEMPLATE_RULES_BY_TEMPLATE_ID, GET_TEMPLATES_BY_LISTING_ID } from '../../graphQl/subscriptions'
import { cloneDeep } from 'lodash'

const { Option } = Select

let initialData = {
    id: 0,
    status: "",
    type: "",
    template: ""
}

export class Status extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statusList: [],
            templateId: '',
            templatesData: [],
            eligibleStatusData: [],
            msgType: "",
            duplicateStatusList: [],
            loading: false
        }
    }

    componentDidMount() {

        if (this.props && this.props.createdTemplateId) {
            this.setState({ templateId: this.props.createdTemplateId.id }, () => {
            })
        }

        if (this.props && this.props.templateIdIdForEdit) {
            this.setState({ templateId: this.props.templateIdIdForEdit }, () => {
                this.getTemplateRules()
            })
        }
        this.fetchAllTemplate()
    }

    componentWillReceiveProps(newProps) {
        if (this.props.eligibleStatus !== newProps.eligibleStatus) {
            this.setState({ eligibleStatusData: newProps.eligibleStatus })
        }
        if (this.props.createdTemplateId !== newProps.createdTemplateId) {
            this.setState({ templateId: newProps.createdTemplateId.id }, () => {
            })
        }
        if (this.props.templateIdIdForEdit !== newProps.templateIdIdForEdit) {
            this.setState({ templateId: newProps.templateIdIdForEdit }, () => {
                this.getTemplateRules()
            })
        }
    }

    fetchAllTemplate = () => {
        const variables = {
            "listingId": localStorage.getItem('selectedListingId'),
        };
        try {
            client.subscribe({
                query: GET_TEMPLATES_BY_LISTING_ID,
                variables: variables
            }).subscribe(res => {
                if (res && res.data && res.data.template) {
                    this.setState({ templatesData: res.data.template })
                }
            }, err => {
            }, err => {
            })
        } catch (e) {
        }
    }

    getTemplateRules = () => {
        this.setState({ loading: true }, () => {
            const variables = { "transactionTemplateId": this.state.templateId };
            try {
                client.subscribe({
                    query: GET_TEMPLATE_RULES_BY_TEMPLATE_ID,
                    variables: variables
                }).subscribe(async res => {

                    let listData = []
                    let listDataIds = []
                    if (res && res.data && res.data.transaction_template_rules.length > 0) {
                        let data = res.data.transaction_template_rules

                        await data.map((item, index) => {

                            if (item) {
                                let status = item.status
                                let id = item.id
                                if (item.notificationChannel) {
                                    if (item.notificationChannel.SMS.length > 0) {
                                        let list = {
                                            id: id,
                                            status: status,
                                            type: "SMS",
                                            template: item.notificationChannel.SMS[0]
                                        }
                                        listData.push(list)
                                    }
                                    if (item.notificationChannel.EMAIL.length > 0) {
                                        let list = {
                                            id: id,
                                            status: status,
                                            type: "EMAIL",
                                            template: item.notificationChannel.EMAIL[0]
                                        }
                                        listData.push(list)
                                    }
                                }
                                let listIds = {
                                    id: id,
                                    type: "DELETE"
                                }
                                listDataIds.push(listIds)
                            }
                        })
                        this.setState({
                            statusList: listData.length > 0 ? listData : [],
                            duplicateStatusList: listDataIds.length > 0 ? listDataIds : [],
                            loading: false
                        }, () => {
                        })
                    }
                    else {
                        if (this.state.statusList.length === 0) {
                            let data = []
                            let initialDataValue = {
                                id: 0,
                                status: "",
                                type: "",
                                template: ""
                            }
                            data.push(initialDataValue)
                            this.setState({ statusList: data, loading: false }, () => {
                            })
                        }
                    }
                }, err => {
                    this.setState({ loading: false })
                })
            } catch (e) {
                this.setState({ loading: false })
            }
        })

    }

    onAddStatusRow = () => {
        let data = this.state.statusList
        let initialData = {
            id: data.length,
            status: "",
            type: "",
            template: ""
        }
        data.push(initialData)
        this.setState({ statusList: data })
    }

    onChangeStatus = (event, index, option) => {
        if (event) {
            let statusList = this.state.statusList
            statusList[index][option] = event
            this.setState({ statusList }, () => {
                // if (option === "type") {
                //     this.assignTypevalue(event)
                // }
            })
        }
    }

    assignTypevalue = (val) => {
        this.setState({ msgType: val }, () => {
            this.fetchTemplate()
        })
    }

    selectTemplate = (index) => {
        const messageTypeArr = this.props.form.getFieldValue('messageType')
        this.setState({ msgType: messageTypeArr[index] }, () => {
            this.fetchTemplate()
        })
    }

    fetchTemplate = () => {
        const variables = {
            "listingId": localStorage.getItem('selectedListingId'),
            "templateType": this.state.msgType
        };
        try {
            client.subscribe({
                query: TEMPLATE_SUB,
                variables: variables
            }).subscribe(res => {
                if (res && res.data && res.data.template) {
                    this.setState({ templatesData: res.data.template })
                }
            }, err => {
            }, err => {
            })
        } catch (e) {
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, val) => {
            if (!err) {
                this.setState({ loading: true }, () => {
                    if (this.state.duplicateStatusList.length > 0) {
                        const mutation = gql`${saveRules(this.state.duplicateStatusList)}`
                        try {
                            client.mutate({ mutation }).then((response) => {
                                this.saveRules()
                            })
                                .catch(err => {
                                    this.setState({ loading: false })
                                    message.error('Delete Eligible Status Failed', err)
                                });
                        } catch (e) {
                            this.setState({ loading: false })
                        }
                    } else {
                        this.saveRules()
                    }
                })

            }
        })
    }

    saveRules = () => {

        let listArray = this.state.statusList
        let savedList = [];

        listArray.map((item, i) => {
            if (item && !item.isDelete) {
                let type = listArray[i].type;
                let status = listArray[i].status;
                let template = listArray[i].template;
                let uid = localStorage.getItem('uid')
                let ruleType = "statusChangeNotification"
                let templateId = this.state.templateId

                if (savedList.length == 0) {

                    let notification = {
                        "SMS": type === "SMS" ? template : [],
                        "EMAIL": type === "EMAIL" ? template : []
                    }

                    const tempPayload = {
                        type: "CREATE",
                        id: null,
                        transactionTemplateId: templateId,
                        status: status,
                        createdBy: uid,
                        ruleType: ruleType,
                        notificationChannel: notification,
                    }
                    savedList.push(tempPayload);
                }
                else {
                    // check if status is already exists
                    let index = savedList.findIndex(function (o) {
                        return o["status"] === listArray[i].status
                    });
                    //if not exists
                    if (index == -1) {

                        let notification = {
                            "SMS": type === "SMS" ? template : [],
                            "EMAIL": type === "EMAIL" ? template : []
                        }

                        const tempPayload = {
                            type: "CREATE",
                            id: null,
                            transactionTemplateId: templateId,
                            status: status,
                            createdBy: uid,
                            ruleType: ruleType,
                            notificationChannel: notification,
                        }
                        savedList.push(tempPayload);

                    } else {

                        let data = savedList[index].notificationChannel

                        if (data.SMS.length === 0) {
                            savedList[index].notificationChannel.SMS = (type === "SMS" ? template : [])
                        }
                        if (data.EMAIL.length === 0) {
                            savedList[index].notificationChannel.EMAIL =
                                (type === "EMAIL" ? template : [])
                        }

                    }
                }
            }
        })


        if (savedList.length > 0) {
            const mutation = gql`${saveRules(savedList)}`
            try {
                client.mutate({ mutation }).then((response) => {
                    message.success('Rules Saved SuccessFully')
                    initialData = initialData
                    this.setState({ loading: false, duplicateStatusList: [], statusList: [] })
                })
                    .catch(err => {
                        this.setState({ loading: false })
                        message.error('Add Eligible Status Failed', err)
                    });
            } catch (e) {
                this.setState({ loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    onRemoveStatusRow = (index) => {
        let statusList = cloneDeep(this.state.statusList)
        statusList[index].isDelete = true
        this.setState({ statusList })
    }

    openMsgTemplate = (k) => {
        const listingName = localStorage.getItem('selectedListingName')
        const tempTypeId = this.props.form.getFieldValue('templates')
        window.open(`/app/console/${listingName}/newSmsTemplate/${tempTypeId[k]}`)
    }

    createTemplate = () => {
        const listingName = localStorage.getItem('selectedListingName')
        window.open(`/app/console/${listingName}/newSmsTemplate/newMessageTemplate`)
    }



    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { formKeys, dropDownVal, editTemplates, editStatus, statusList } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        getFieldDecorator('keys', { initialValue: [] });
        const formItems = statusList.map((k, index) => {
            if (k && !k.isDelete) {

                return (
                    <div>
                        <Form.Item
                            {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                            required={false}
                            key={k}

                        >
                            <Row span={24}>
                                <Col xs={6} sm={7} md={7} lg={7}>
                                    {index === 0 && <h6 className="status-rule-text" >State</h6>}
                                    {getFieldDecorator(`status[${index}]`, {
                                        initialValue: statusList[index] && statusList[index].status ? statusList[index].status : '',
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input the field.",
                                            },
                                        ],
                                    })(

                                        <Select placeholder="Select Status"
                                            onChange={(event) => this.onChangeStatus(event, index, 'status')}
                                        >
                                            {this.state.eligibleStatusData && this.state.eligibleStatusData.length > 0 && this.state.eligibleStatusData.map(val => (
                                                <Option value={val}>{val}</Option>
                                            ))
                                            }
                                        </Select>)}
                                </Col>
                                <Col xs={6} sm={7} md={7} lg={7}>
                                    {index === 0 && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Notification</h6>}
                                    {getFieldDecorator(`messageType[${index}]`, {
                                        initialValue: statusList[index] && statusList[index].type ? statusList[index].type : '',
                                        rules: [
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input the field.",
                                            },
                                        ],
                                    })(<Select placeholder="Select Message Type"
                                        onChange={(event) => this.onChangeStatus(event, index, 'type')}
                                    >
                                        <Option value='SMS'>SMS</Option>
                                        <Option value='EMAIL'>EMAIL</Option>
                                    </Select>)}
                                </Col>
                                <Col xs={6} sm={7} md={7} lg={7}>
                                    {index === 0 && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Use Template</h6>}
                                    {getFieldDecorator(`templates[${index}]`, {
                                        initialValue: statusList[index] && statusList[index].template ? statusList[index].template : '',
                                        rules: [
                                            {
                                                required: true,
                                                // whitespace: true,
                                                message: "Please input the value.",
                                            },
                                        ],
                                    })(<Select placeholder="Select Template"
                                        onChange={(event) => this.onChangeStatus(event, index, 'template')}
                                        onFocus={() => { this.selectTemplate(index) }}
                                    >
                                        {this.state.templatesData && this.state.templatesData.length > 0 && this.state.templatesData.map(val => (
                                            <Option value={val.id} key={val.id}>{val.title}</Option>
                                        ))
                                        }
                                    </Select>)}
                                </Col>
                                {/* For preview Message template */}
                                <Col className="minus-icon-view" span={1} style={index === 0 && { marginTop: '26px' }}>
                                    {getFieldDecorator(`preview[${index}]`, {
                                    })(
                                        <Icon id="icon-view" type="eye" style={{ fontSize: 20 }}
                                            onClick={() => this.openMsgTemplate(index)} />
                                    )}
                                </Col>

                                <Col className="minus-icon-view" span={1} style={index === 0 && { marginTop: '26px' }}>
                                    {/* {keys.length > 1 ? ( */}
                                    <Icon
                                        id="icon-view"
                                        className="dynamic-delete-button"
                                        type="delete"
                                        onClick={() => this.onRemoveStatusRow(index)}
                                        style={{ fontSize: '16px' }}
                                    />
                                    {/* ) : null} */}
                                </Col>
                            </Row>
                        </Form.Item>
                    </div>
                )
            }
        })

        // ));
        return (
            <div>

                <Form
                    onSubmit={this.handleSubmit}
                >
                    <div>
                    </div>
                    <div className="note-view" style={{}}>
                        <h6 className="eligible-status-header">Rules</h6>
                        <p style={{ marginBottom:15}}>To trigger notifications, create rules for each state saved above </p>
                        <h6 style={{ color: 'red',marginBottom:25 }}>Note: To share the contracts please add rules here with the available eligible status you have saved</h6>
                        
                    </div>
                    <Spin spinning={this.state.loading} >
                        <div>

                            <div>
                                {formItems}
                            </div>
                        </div>
                        <div>
                            <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                                <Form.Item {...formItemLayoutWithOutLabel} >
                                    <Button type="dashed" onClick={() => this.onAddStatusRow()} style={{ width: '100px' }} >
                                        <Icon id="icon-view" type="plus" />
                                    </Button>
                                </Form.Item>
                                <div style={{ marginLeft: 10 }}>
                                    <Form.Item {...formItemLayoutWithOutLabel}>
                                        <Button type="primary" onClick={() => this.createTemplate()}>
                                            Create Message Template
                                </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Form.Item {...formItemLayoutWithOutLabel}>
                                <Button type="primary" htmlType="submit"
                                    disabled={this.state.eligibleStatusData.length === 0 ? true : false}>
                                    Save
                                </Button>
                            </Form.Item>
                        </div>
                    </Spin>
                </Form>

            </div>
        )
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(Status);
export default withRouter(WrappedDynamicFieldSet)