import React, { Component } from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import _ from 'lodash'
import { Select, Tabs, Row, Col, Input, Icon, Checkbox } from "antd";
import parse, { domToReact } from 'html-react-parser';
import CustomScrollbars from "util/CustomScrollbars";

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CONFIG } from 'config/config';

import { getENVConfig } from '../../util/common'
import EcommerceFormData from '../CreateTemplate/EcommerceFormdata/EcommerceFormData';
import { shortUrlDeeplinkUrlPrefix } from './const/index'

const Option = Select.Option;
const { TabPane } = Tabs;

let SortableItem = '';

const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
});

const options = [
    'string',
    'integer'
];



class VariableFields extends Component {

    constructor() {
        super();
        this.state = {
            temp: [],
            Values: [],
            finalVal: [],
            properties: {},
            duplicateVal: [],
            Required: [],
            uiSChema: {},
            externalLinkValue: '',
            externalLink: false,
            signedData: false,
            GSTIN: '',
            PAN: '',
            priceUnit: '',
            ecommerceFormData: {},
            productImage: false,
            shopFlag: false,
            shorturlForDeeplinking: "",
            copiedForDeeplinkURl: false,
            OtpForShortUrl: false,
            paymentData: {},
            paymentType: ''
        }
        SortableItem = sortableElement(({ value, index }) =>
            <Row span={24} style={{ paddingBottom: 30 }} key={index}>
                <Col>
                    <Icon id="icon-view" type="menu" />
                </Col>
                <Col span={12}>
                    <Input disabled value={value} key={index} />
                </Col>
                <Col>
                    <Select defaultValue='string' style={{ width: 120 }} onChange={(selectOption) => this.handleChange(value, selectOption)}>
                        {options.map((val) => {
                            return (
                                <Option value={val}>{val}</Option>
                            )
                        })
                        }
                    </Select>
                </Col>
                <Col span={6}>
                    <Checkbox defaultChecked={this.state.Required.includes(value)} onChange={(e) => this.onrequireChange(e, value)}>Required</Checkbox>
                </Col>
            </Row>);
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    componentWillMount() {
        if (this.props.editData) {
            console.log(this.props.editData, 'this.props.editData')
            this.setState({ finalVal: this.props.editData ? this.props.editData.templateData.formDefinition.UISchema['ui:order'] : [] }, () => {
                this.setState({ duplicateVal: this.state.finalVal })
            });
            this.setState({ Required: this.props.editData.templateData.formDefinition.JSONSchema.required });
            if (this.props.editData.templateData.templateSettings) {

                if (this.props.editData.templateData.templateSettings &&
                    this.props.editData.templateData.templateSettings.paymentType) {
                    this.setState({ paymentType: this.props.editData.templateData.templateSettings.paymentType })
                }
                if (this.props.editData.templateData.templateSettings &&
                    this.props.editData.templateData.templateSettings.paymentKeys) {
                    this.setState({ paymentData: this.props.editData.templateData.templateSettings.paymentKeys })
                }
                this.setState({ externalLink: this.props.editData.templateData.templateSettings.externalLink }, () => {
                    if (this.state.externalLink) {
                        this.setState({ externalLink: true });
                    }
                });

                this.setState({ signedData: this.props.editData.templateData.templateSettings.displaySignedData });
                this.setState({
                    OtpForShortUrl: this.props.editData.templateData.templateSettings.OtpForShortUrl ?
                        this.props.editData.templateData.templateSettings.OtpForShortUrl : false
                });

                this.setState({ externalLinkValue: this.props.editData.templateData.templatepublicurl });
                this.setState({ GSTIN: this.props.editData.templateData.templateSettings.GSTINNumber });
                this.setState({ PAN: this.props.editData.templateData.templateSettings.PANNumber });
                this.setState({ priceUnit: this.props.editData.templateData.templateSettings.priceUnit });
                this.setState({ CGSTValue: this.props.editData.templateData.templateSettings.CGSTValue });
                this.setState({ SGSTValue: this.props.editData.templateData.templateSettings.SGSTValue });
                this.setState({ ecommerceFormData: this.props.editData.templateData.templateSettings.fieldValues })
                this.setState({ shopFlag: this.props.editData.templateData.displayShop })

            }

            //for deeplink
            if (this.props.editData && this.props.editData.templateData
                && this.props.editData.templateData.shorturlForDeeplink) {
                let splitUrl = this.props.editData.templateData.shorturlForDeeplink.split(shortUrlDeeplinkUrlPrefix)
                if (splitUrl.length > 0) {
                    this.setState({ shorturlForDeeplinking: splitUrl[1] })
                }
            }

            if (this.props.editData &&
                this.props.editData.templateData &&
                this.props.editData.templateData.templateSettings &&
                this.props.editData.templateData.templateSettings.uploadProductImage) {
                this.setState({ productImage: true })
            }

        } else {
            this.parseData()
        }
        // "JSONSchema": { "type": "object", "title": "HNI Immigration Property Information Request\n", "required": [ "firstName", "lastName", "phoneNumber" ], "properties": { "city": { "type": "string", "title": "City", "default": "" }, "email": { "type": "string", "title": "Email", "default": "" }, "remarks": { "type": "string", "title": "Remarks", "default": "" }, "lastName": { "type": "string", "title": "Last Name", "default": "" }, "firstName": { "type": "string", "title": "First Name", "default": "" }, "companyName": { "type": "string", "title": "Company Name", "default": "" }, "phoneNumber": { "type": "integer", "title": "Mobile phone", "default": "" } }, "description": "\nBy entering your First Name and Last Name and clicking Submit, you acknowledge that you have read and agree to the terms of this document" } }
    }


    componentWillReceiveProps() {
        if (this.props.editData && this.props.editData.templateData && this.props.editData.templateData.templateSettings && this.props.editData.templateData.templateSettings.uploadProductImage === true) {
            console.log('if rec', this.props.editData.templateData.templateSettings.uploadProductImage)
            this.setState({ productImage: true })
        } else if (this.props.editData && this.props.editData.templateData && this.props.editData.templateData.templateSettings && this.props.editData.templateData.templateSettings.uploadProductImage === false) {
            console.log('else if rec', this.props.editData.templateData.templateSettings.uploadProductImage)
            this.setState({ productImage: false })
        }

        if (!this.props.displayHtml) {
            // if(this.state.finalVal === this.state.duplicateFinalVal) {
            this.setState({ finalVal: this.props.editData ? this.props.editData.templateData.formDefinition.UISchema['ui:order'] : [] }, () => {
                this.setState({ duplicateVal: this.state.finalVal })
            });
            // } else {
            //     console.log(this.state.finalVal, 'finalelse')
            // }
        } else {
            this.parseData()
        }
    }

    onrequireChange(e, value) {
        const leadData = {
            externalLink: this.state.externalLink,
            displaySignedData: this.state.signedData
        };
        if (this.props.selectedForm === 'EcommerceForm') {
            leadData.GSTINNumber = this.state.GSTIN;
            leadData.PANNumber = this.state.PAN;
            leadData.priceUnit = this.state.priceUnit ? this.state.priceUnit !== '' ? this.state.priceUnit : 'Rs' : 'Rs';
        }
        if (e.target.checked) {
            this.state.Required.push(value);
        } else {
            var index = this.state.Required.indexOf(value);
            this.state.Required.splice(index, 1);
        }
        this.props.saveVariables(this.state.finalVal, this.state.Required, leadData, this.state.externalLinkValue);
    }



    parseData() {
        this.state.finalVal = [];
        this.state.properties = {};
        this.state.uiSChema = {};
        this.state.temp = [];
        var template = '';
        //to parse varibales from html data   
        if (typeof this.props.displayHtml === 'string') {
            template = this.props.displayHtml;
        } else {
            template = this.props.displayHtml;
        }
        if (!template) { template = `<p>no data</p>` }

        const options = {
            replace: ({ attribs, children }) => {
                if (!attribs) return;
                children.map((data, index) => {
                    if (data.data) {
                        var data = data.data;
                        var regex = /\[\[((\w+)]\])/g;
                        var variable = data.match(regex);
                        if (variable && variable.length > 0) {
                            variable.map((variableData, index) => {
                                this.state.temp.push(variableData);
                            })
                        }
                    }
                    if (data.name === 'input') {
                        var changeddata = data.attribs.name;
                        var updated_changeddata = changeddata.replace(/[^A-Z0-9]/ig, "");

                        // this.state.properties[changeddata] = data.attribs.type !== 'text' ? { type: 'boolean' } : { type: 'string' };

                        this.state.properties[updated_changeddata] = { type: 'string' };
                        this.state.temp.push(updated_changeddata);
                        this.state.uiSChema[updated_changeddata] = { "ui:widget": 'text' };
                    }
                    if (data.name === 'textarea') {
                        var data = data.attribs.name;
                        this.state.temp.push(data);
                    }
                })
                // }
            }
        }
        if (template) {
            renderToStaticMarkup(parse(template, options));

        }

        this.state.Values = this.state.temp.filter((x, i, a) => {
            return a.indexOf(x) == i;
        });
        this.state.Values.map((data, index) => {
            var updated = data.replace(/[^A-Z0-9]/ig, "");
            this.state.finalVal.push(updated);
            let property = {};
            if (this.state.properties[updated] && (this.state.properties[updated].type === 'boolean')) {
                property = {
                    type: this.state.properties[updated] ? this.state.properties[updated].type : 'string',
                    title: updated,
                    default: '',
                    enum: [data]
                };
            } else {
                property = {
                    type: this.state.properties[updated] ? this.state.properties[updated].type : 'string',
                    title: updated,
                    default: '',
                };
            }
            this.state.properties[updated] = property;
        });

        const tempArrr = []
        const notExistArr = []
        if (this.state.duplicateVal.length > 0) {
            this.state.duplicateVal.map((val, index) => {
                if (_.includes(this.state.finalVal, val)) {
                    tempArrr.push(val)
                }
            })
            this.state.finalVal.map((val, index) => {
                if (_.includes(this.state.duplicateVal, val) === false) {
                    tempArrr.push(val)
                }
            })
        }

        if (notExistArr.length > 0) {
            notExistArr.map(val => {
                tempArrr.push(val)
            })
        }

        this.setState({ finalVal: tempArrr.length > 0 ? tempArrr : this.state.finalVal });
        const JsonSchema = {
            "type": "object",
            "required": this.state.Required,
            'properties': this.state.properties,
        };
        const formDef = {
            "UISchema":
            {
                "ui:order": this.state.finalVal
            },
            "formData": {},
            "JSONSchema": JsonSchema
        };
        this.props.variablesParse(this.state.finalVal);
    }

    handleChange(variable, Datatype) {
        // const property = {
        //     type: Datatype,
        //     title: variable,
        //     default: '',
        //     enum: this.state.properties[variable] ? (this.state.properties[variable].type !== 'boolean')  ? [] : [variable] : []
        // };
        let property = {};
        if (this.state.properties[variable] && (this.state.properties[variable].type === 'boolean')) {
            property = {
                type: Datatype,
                title: variable,
                default: '',
                enum: [variable]
            };
        } else {
            property = {
                type: this.state.properties[variable] ? this.state.properties[variable].type : 'string',
                title: variable,
                default: '',
            };
        }
        this.state.properties[variable] = property;

    }

    onSelectTemplate(eve) {
    }


    onSortEnd = ({ oldIndex, newIndex }) => {
        const leadData = {
            externalLink: this.state.externalLink,
            displaySignedData: this.state.signedData
        };
        if (this.props.selectedForm === 'EcommerceForm') {
            leadData.GSTINNumber = this.state.GSTIN;
            leadData.PANNumber = this.state.PAN;
            leadData.priceUnit = this.state.priceUnit ? this.state.priceUnit !== '' ? this.state.priceUnit : 'Rs' : 'Rs';
        }
        this.setState(({ finalVal }) => ({
            finalVal: arrayMove(finalVal, oldIndex, newIndex),
        }), () => {
            this.setState({ duplicateVal: this.state.finalVal });
            this.state.duplicateFinalVal = this.state.finalVal;
        });
        this.props.saveVariables(this.state.finalVal, this.state.Required, leadData, this.state.externalLinkValue);
    };


    onSave() {
        const JsonSchema = {
            "type": "object",
            "required": this.state.Required,
            'properties': this.state.properties,
        };
        const formDef = {
            "UISchema":
            {
                "ui:order": this.state.finalVal
            },
            "formData": {},
            "JSONSchema": JsonSchema
        };
        return formDef;
    }

    passChangesToParent() {
        console.log(this.state.ecommerceFormData, this.props.selectedForm, 'comform')
        const leadData = {
            externalLink: this.state.externalLink,
            displaySignedData: this.state.signedData,
            OtpForShortUrl: this.state.OtpForShortUrl
        };
        if (this.props.selectedForm === 'EcommerceForm') {
            leadData.fieldValues = this.state.ecommerceFormData;
            // leadData.PANNumber = this.state.PAN;
            leadData.priceUnit = this.state.priceUnit ? this.state.priceUnit !== '' ? this.state.priceUnit : 'Rs' : 'Rs';
            leadData.uploadProductImage = this.state.productImage
            if (this.state.paymentData) {
                leadData.paymentKeys = this.state.paymentData
            }
            if (this.state.paymentType) {
                leadData.paymentType = this.state.paymentType
            }
        }

        if (this.props.selectedForm === 'ShipmentForm') {
            leadData.fieldValues = this.state.ecommerceFormData;
        }

        this.props.saveVariables(this.state.finalVal, this.state.Required, leadData,
            this.state.externalLinkValue, this.state.shopFlag, this.state.shorturlForDeeplinking);
    }

    ExternalLinkCheck(e) {
        this.setState({ externalLink: e.target.checked, copied: false }, () => {

            // Create a default order url with order when creating template
            if (this.props.selectedForm === 'EcommerceForm' && !this.props.editData) {
                this.setState({ externalLinkValue: e.target.checked ? "order" : false })
            }

            this.passChangesToParent();
        });
    }

    signedDataCheck(e) {
        this.setState({ signedData: e.target.checked }, () => {
            this.passChangesToParent();
        })
    }


    ecommerceFormDataSaved(ecommerceFormData) {
        this.setState({ ecommerceFormData }, () => {
            this.passChangesToParent();
        });

    }

    saveUploadImage(value) {
        console.log('saveUploadImage', value)
        this.setState({ productImage: value }, () => {
            this.passChangesToParent();
        })
    }

    shopCheck(e) {
        this.setState({ shopFlag: e.target.checked }, () => {
            console.log('shopCheck', this.state.shopFlag)
            this.passChangesToParent();
        })
    }

    OtpForExternalLink(e) {
        this.setState({ OtpForShortUrl: e.target.checked }, () => {
            this.passChangesToParent();
        })
    }


    render() {
        const { finalVal, temp } = this.state;
        //to convert parsed variables in correct format
        return (
            <div style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
                <div style={{ marginBottom: '20px' }}>
                    <Row style={{ paddingLeft: 30, display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={this.state.externalLink} onChange={(e) => this.ExternalLinkCheck(e)}> External Link</Checkbox>
                        {(this.state.externalLink) &&
                            <div>
                                <Input disabled={false} value={this.state.externalLinkValue} style={{ width: 225, marginRight: 8 }} onChange={(val) => { this.setState({ externalLinkValue: val.target.value }, () => { this.passChangesToParent(); }) }} />
                                <CopyToClipboard text={getENVConfig(CONFIG.DOMAIN) + '/' + localStorage.getItem('selectedListingName') + '/' + this.state.externalLinkValue} onCopy={() => this.setState({ copied: true })}>
                                    <Icon type="copy" />

                                </CopyToClipboard>
                                {this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}

                            </div>
                        }
                    </Row>
                    <Row style={{ paddingLeft: 30, paddingTop: 15 }} >
                        <Checkbox checked={this.state.signedData} onChange={(e) => this.signedDataCheck(e)}> Display signed data</Checkbox>
                    </Row>
                    <Row style={{ paddingLeft: 30, paddingTop: 15 }} >
                        <Checkbox checked={this.state.OtpForShortUrl} onChange={(e) => this.OtpForExternalLink(e)}> OTP to view external link</Checkbox>
                    </Row>
                </div>
                {(this.props.selectedForm === 'EcommerceForm' || this.props.selectedForm === 'ShipmentForm') &&
                    <div>
                        {/* <Row style={{ paddingLeft: 30, paddingTop: 15 }}>
                            <span style={{ paddingRight: 10 }}>GSTIN Number </span>
                            <Input style={{ width: 225, marginRight: 8 }} value={this.state.GSTIN} placeholder="Please enter GSTIN number" onChange={(e) => this.setState({ GSTIN: e.target.value }, () => { this.passChangesToParent(); })} />
                        </Row>
                        <Row style={{ paddingLeft: 30, paddingTop: 15 }}>
                            <span style={{ paddingRight: 10 }}>PAN Number </span>
                            <Input style={{ width: 225, marginRight: 8 }} value={this.state.PAN} placeholder="Please enter PAN number" onChange={(e) => this.setState({ PAN: e.target.value }, () => { this.passChangesToParent(); })} />
                        </Row> */}
                        <Row style={{ paddingLeft: 30 }} >
                            <Checkbox checked={this.state.shopFlag} onChange={(e) => this.shopCheck(e)}> Display in shop</Checkbox>
                        </Row>
                        {this.props.selectedForm !== 'ShipmentForm' &&
                            <div style={{ display: 'flex', paddingLeft: 16, paddingRight: 16, alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
                                <p style={{ marginRight: 10, marginBottom: 0 }}>Short url for deeplink</p>
                                <Input disabled={false} value={this.state.shorturlForDeeplinking} style={{ width: 225, marginRight: 8 }} onChange={(val) => { this.setState({ shorturlForDeeplinking: val.target.value }, () => { this.passChangesToParent(); }) }} />
                                <CopyToClipboard text={shortUrlDeeplinkUrlPrefix + this.state.shorturlForDeeplinking} onCopy={() => this.setState({ copiedForDeeplinkURl: true })}>
                                    <Icon type="copy" />

                                </CopyToClipboard>
                                {this.state.copiedForDeeplinkURl ? <span style={{ color: 'red' }}>Copied.</span> : null}

                            </div>
                        }
                        {this.props.selectedForm !== 'ShipmentForm' &&
                            <Row style={{ paddingLeft: 30, paddingTop: 10, paddingBottom: 15, display: 'flex', alignItems: 'center' }}>
                                <span style={{ paddingRight: 10 }}>Currency</span>
                                <Input style={{ width: 225, marginRight: 8 }} value={this.state.priceUnit} placeholder="Please enter price unit" onChange={(e) => this.setState({ priceUnit: e.target.value }, () => { this.passChangesToParent(); })} />
                            </Row>
                        }
                        <EcommerceFormData editData={this.props.editData && this.props.editData.templateData.templateSettings}
                            ecommerceFormDataSaved={this.ecommerceFormDataSaved.bind(this)}
                            csvDetails={this.props.csvDetails}
                            templateId={this.props.editData && this.props.editData.templateId}
                            uploadProductImage={this.saveUploadImage.bind(this)}
                            formType={this.props.selectedForm}
                        />
                    </div>
                }
                <SortableContainer onSortEnd={this.onSortEnd}>

                    {finalVal.length > 0 ? finalVal.map((val, index) => {
                        return (
                            <SortableItem key={`item-${val}`} index={index} value={val} />
                        )
                    })
                        :
                        (this.props.selectedForm !== 'EcommerceForm' && this.props.selectedForm !== 'ShipmentForm') && <span>No variables </span>
                    }
                </SortableContainer>

            </div>
        )

    }
};

export default VariableFields;
