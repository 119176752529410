import React, { Component } from 'react'
import { Form, Input, Icon, message, Popconfirm, Modal, Button, Tag } from 'antd'
import { withRouter } from "react-router-dom"
import CircularProgress from "../../components/CircularProgress/index";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import client from '../../apollo/apollo'
import {
    ADD_CONTACT_MUTATION, UPDATE_CONTACT_MUTATION, DELETE_CONTACT_MUTATION,
    UPDATE_CONTACT_STATUS
} from '../../graphQl/mutations'
import { USER_WITH_PHONE_NUM_QUERY, CHECK_CONTACT_DATA } from '../../graphQl/queries'
import CustomScrollbars from "util/CustomScrollbars";
import CustomFields from './customFields'
import UploadCsv from './uploadCsv'
import { CONFIG } from '../../config/config';
import { getENVConfig } from '../../util/common'

const axios = require('axios');

const { TextArea } = Input;

export class ContactDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contactData: {},
            disabledState: false,
            type: '',
            emailValue: '',
            phoneValue: '',
            visible: false,
            custom: {},
            customString: '',
            saveModal: false,
            userId: '',
            selectedFilterData: {},
            tableName: this.props.screenName,
            showLoading: false
        }
    }

    componentDidMount() {
        if (this.props && this.props.todo) {
            this.setState({ contactData: this.props.todo })
        }
        if (this.props && this.props.type === 'View') {
            this.setState({ disabledState: true })
        }
        if (this.props && this.props.type) {
            this.setState({ type: this.props.type })
        }

        if (this.props.type === "filter") {
            if (document.getElementById("individual_contact")) {
                document.getElementById('saveEditButton').style.display = 'none'
                document.getElementById('custom_attributes').style.display = 'none'
            }
            this.setState({
                disabledState: this.props.isFieldsReadOnly,
                eidtField: this.props.isFieldsReadOnly,
                selectedFilterData: this.props.editFilterItem ? this.props.editFilterItem : {},
            }, () => {

                const { selectedFilterData } = this.state
                if (selectedFilterData && selectedFilterData.filterData) {
                    this.setState({
                        contactData: selectedFilterData.filterData,
                    }, () => {
                        console.log('contactData123', this.state.contactData)
                    })
                } else {
                    this.setState({
                        contactData: this.props.selectedData,
                    }, () => {
                    })
                }
            })
        }
    }

    componentWillReceiveProps(newProps) {
        //when save filter button clicked
        if (this.props.onSaveFilter !== newProps.onSaveFilter && newProps.onSaveFilter === true) {
            this.onSaveFilter()
        }
        // when edit button clicked
        if (this.props.isFieldsReadOnly !== newProps.isFieldsReadOnly) {
            this.setState({ disabledState: newProps.isFieldsReadOnly, eidtField: newProps.isFieldsReadOnly, })
        }

        //when search button clicked
        if (this.props.isClickedOk !== newProps.isClickedOk && newProps.isClickedOk === true) {
            this.onSearchData()
        }
    }

    onSaveFilter = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            const { selectedFilterData } = this.state
            let variablesData = {}

            //for edit filter   
            if (Object.keys(selectedFilterData).length > 0) {
                let id = selectedFilterData && selectedFilterData.filterHistId ? selectedFilterData.filterHistId : null
                variablesData = {
                    filterHistId: id,
                    listingId: localStorage.getItem('selectedListingId'),
                    tableName: this.state.tableName,
                    refId: null,
                    createdBy: localStorage.getItem('uid'),
                    filterData: values
                }
                if (this.props.onSaveFilterData) {
                    this.props.onSaveFilterData(variablesData, 'update')
                }
            }
            //to create filter
            else {
                variablesData = {
                    listingId: localStorage.getItem('selectedListingId'),
                    tableName: this.state.tableName,
                    refId: null,
                    createdBy: localStorage.getItem('uid'),
                    filterData: values
                }
                if (this.props.onSaveFilterData) {
                    this.props.onSaveFilterData(variablesData, 'create')
                }
            }
        })
    }

    //when searching data
    onSearchData = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (this.props.onShowFilterTag) {
                this.props.onShowFilterTag(values)
            }
        })
    }

    // componentWillReceiveProps(nxtprops) {    
    //     // console.log('editIndividual', nxtprops) 
    //     if (nxtprops.saveContact !== this.props.saveContact) {
    //         console.log('nxtprops1234 saveContact', nxtprops.saveContact, this.props.saveContact)
    //         this.saveContact()
    //     }
    //     if (nxtprops.editContact !== this.props.editContact) {
    //         console.log('editIndividual', nxtprops.editContact, this.props.editContact)
    //         this.onClickEdit()
    //     }

    // }

    checkContactDataExit = () => {
        if (this.state.type === 'New') {
            const phone = this.props.form.getFieldValue('phoneNumber')
            const email = this.props.form.getFieldValue('email')
            const userCode = this.props.form.getFieldValue('customerCode')
            const params = {
                p_email: email ? email : null,
                p_phone_no: phone ? phone : null,
                p_listingid: localStorage.getItem('selectedListingId'),
                p_internal_user_contact_id: userCode ? userCode : null
            }
            console.log('params', params)
            client
                .query({
                    query: CHECK_CONTACT_DATA,
                    variables: params
                })
                .then(
                    res => {
                        console.log('checkContactDataExit', res)
                        if (res && res.data && res.data.search_contacts_by_params && res.data.search_contacts_by_params.length > 0) {
                            message.error("Entered contact already exist")
                        } else {
                            this.CheckUser()
                        }
                    })
                .catch(err => {
                    console.log('checkContactDataExit err', err)
                })
        } else {
            this.CheckUser()
        }
    }


    CheckUser = () => {
        const phone = this.props.form.getFieldValue('phoneNumber')
        if (phone) {
            client
                .query({
                    query: USER_WITH_PHONE_NUM_QUERY,
                    variables: {
                        phone_number: phone
                    }
                })
                .then(
                    response => {
                        console.log('CheckUser', response)
                        if (response.data && response.data.user[0] && response.data.user[0].id) {
                            this.setState({ userId: response.data.user[0].id }, () => {
                                console.log('wwwwwweerr', this.state.userId)
                                this.saveContact(response.data.user[0].id)
                            })
                        } else {
                            this.saveContact(null)
                        }
                    })
                .catch(err => {
                    console.log(err)
                })
        } else {
            message.error('Please input phone number')
        }
    }


    saveContact(uid) {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                const customTemp = JSON.stringify(this.state.custom)
                if (this.state.type === 'New') {
                    const variableObject = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email || null,
                        listingId: localStorage.getItem('selectedListingId'),
                        phoneNumber: values.phoneNumber || null,
                        notes: values.notes,
                        customAttributes: customTemp,
                        createdBy: localStorage.getItem('uid'),
                        mappingUserId: uid,
                        internalUserContactId: values.customerCode ? values.customerCode : null,
                        businessContactRole: null,
                        creationSource: 'single',
                        validEmail: false
                    }
                    console.log('variableObject', variableObject)


                    client.mutate({ mutation: ADD_CONTACT_MUTATION, variables: variableObject }).then((response) => {
                        console.log('response', response)
                        this.props.gobackCall()
                        message.success('Contact Added SuccessFully')
                    })
                        .catch(err => {
                            console.log('error', err)
                            message.error('Add Contact Failed')
                        });
                }
                if (this.state.type === 'View') {
                    const editableObject = {
                        id: this.state.contactData.id,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email || null,
                        listingId: localStorage.getItem('selectedListingId'),
                        phoneNumber: values.phoneNumber || null,
                        notes: values.notes,
                        customAttributes: customTemp,
                        internalUserContactId: values.customerCode ? values.customerCode : null,
                        createdBy: localStorage.getItem('uid'),
                        creationSource: 'single',
                        validEmail: false
                    }
                    console.log('editableObject', editableObject)

                    client.mutate({ mutation: UPDATE_CONTACT_MUTATION, variables: editableObject }).then((response) => {
                        console.log('response', response)
                        message.success('Contact Edited SuccessFully')
                        this.setState({ disabledState: true })
                    })
                        .catch(err => {
                            console.log('error', err)
                            message.error('Edit Contact Failed')
                        });
                }
            }
        });
    }

    onClickEdit() {
        this.setState({ disabledState: false })
    }

    onClickDelete() {
        console.log('delete', this.state.contactData.id)
        if (this.state.contactData && this.state.contactData.id) {

            const deleteVariable = {
                status: 'ARCHIVED',
                id: this.state.contactData.id
            }

            client.mutate({ mutation: UPDATE_CONTACT_STATUS, variables: deleteVariable }).then((response) => {
                console.log('onClickDelete response', response)
                this.props.gobackCall()
                message.success('Contact Deleted SuccessFully')
            })
                .catch(err => {
                    console.log('onClickDelete error', err)
                    message.error('Delete Contact Failed')
                });
        }
    }

    saveCustomAttributes(val) {
        console.log('saveCustomAttributes', val)
        this.setState({ custom: val, customString: JSON.stringify(val), visible: false }, () => {
            console.log('custom', this.state.custom, this.state.visible, JSON.stringify(this.state.custom))
        })
    }

    bulkUpload = (data) => {
        console.log('contact data', data)
        this.setState({ showLoading: true }, () => {
            if (data.length > 0) {
                const temp = []
                data.map(val => {
                    let lowerCaseKeys = {};
                    for (let [key, value] of Object.entries(val)) {
                        lowerCaseKeys[key.toLowerCase()] = value;
                    }
                    temp.push(lowerCaseKeys)
                })
                if (temp.length === data.length) {
                    console.log('value123', temp)

                    const params = {
                        createdBy: localStorage.getItem('uid'),
                        listingId: localStorage.getItem('selectedListingId'),
                        value: temp
                    }
                    console.log('params', params)
                    // https://us-central1-totely-dev1.cloudfunctions.net/insertIndividualContact
                    var url = `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/insertIndividualContact`


                    let config = { method: 'POST', url: url, data: params }

                    axios(config)
                        .then(res => {
                            console.log('contact bulkUpload123', res)
                            this.setState({ showLoading: false })
                            if (res.data) {
                                console.log('res', res.data)
                                if (res.data === 'uploaded csv have duplicate contact') {
                                    message.error(res.data)
                                } else {
                                    this.props.gobackCall()
                                    message.success(res.data)
                                }
                            } else {
                                message.error("Something went wrong")
                            }
                        })
                        .catch(err => {
                            this.setState({ showLoading: false })
                            message.error("Something went wrong")
                            console.log(err)
                        });
                }
            } else {
                message.error('Something went wrong')
                this.setState({ showLoading: false })
            }
        })
    }

    setLoader = (val) => {
        this.setState({ showLoading: val })
    }

    render() {
        const { contactData, disabledState, type, customString, showLoading } = this.state
        console.log('this.props', contactData)
        const { getFieldDecorator } = this.props.form
        const tempCustom = contactData && contactData.customAttributes && contactData.customAttributes.length > 2 ? contactData.customAttributes : ''
        let formRequiredValue = type === "filter" ? false : true
        const phone = contactData && contactData.phoneNumber ? contactData.phoneNumber : ''
        console.log('phone', phone, type)
        return (
            <div>
                {showLoading ?
                    <div className="loader-view"
                        style={{ height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}
                    >
                        <CircularProgress />
                    </div>
                    :
                    <div className="gx-main-content" style={{ paddingTop: '25px' }}>
                        {this.props.fromlistingMember &&
                            <i className="icon icon-arrow-left gx-icon-btn"
                                onClick={() => this.props.gobackCall()}
                            />
                        }
                        <div className="gx-module-detail gx-module-list" id="individual_contact">
                            <CustomScrollbars className="gx-todo-detail-content-scroll" id="main-form-view">
                                <div className="gx-module-detail-item gx-module-detail-header">
                                    <div className="gx-flex-row">
                                        {this.props && this.props.todo && this.props.todo.tags && this.props.todo.tags.tagValues && this.props.todo.tags.tagValues.length > 0 &&
                                            <div style={{ paddingLeft: '10px', display: 'flex' }}>
                                                {this.props.todo.tags.tagValues.map((data, index) => {
                                                    return (
                                                        <Tag color="green"> {data}</Tag>
                                                    );
                                                })}
                                            </div>
                                        }
                                    </div>
                                    {!this.props.fromlistingMember &&
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                                            {type && type !== 'View' && type !== 'filter' &&
                                                <UploadCsv
                                                    getData={this.bulkUpload}
                                                    loader={this.setLoader}
                                                    screen='BusinessContact'
                                                />
                                            }
                                            {type && type === 'View' &&
                                                <Popconfirm
                                                    title="Are you sure delete this Contact?"
                                                    onConfirm={() => { this.onClickDelete() }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Icon id="icon-view" type="delete" style={{ marginRight: 10 }} />
                                                </Popconfirm>
                                            }
                                            <div id="saveEditButton">
                                                {disabledState === false ?
                                                    <Icon id="icon-view" type="save" onClick={() => { this.checkContactDataExit() }} />
                                                    :
                                                    <Icon id="icon-view" type="edit" onClick={() => this.onClickEdit()} />
                                                }
                                            </div>

                                        </div>
                                    }
                                    <Form>
                                        <Form.Item className="Sample"

                                            label="First Name"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator('firstName', {
                                                initialValue: contactData ? contactData.firstName ? contactData.firstName : '' : '',
                                                rules: [
                                                    {
                                                        required: formRequiredValue,
                                                        message: 'Please input your first name',
                                                    },
                                                ],
                                            })(<Input disabled={disabledState} />)}
                                        </Form.Item>
                                        <Form.Item
                                            label="Last Name"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}>
                                            {getFieldDecorator('lastName', {
                                                initialValue: contactData ? contactData.lastName ? contactData.lastName : '' : '',
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please input your last name',
                                                    },
                                                ],
                                            })(<Input disabled={disabledState} />)}
                                        </Form.Item>

                                        <Form.Item label="E-mail"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}>
                                            {getFieldDecorator('email', {
                                                initialValue: contactData ? contactData.email ? contactData.email : '' : '',
                                                rules: [
                                                    {
                                                        type: 'email',
                                                        message: 'The input is not valid E-mail',
                                                    },
                                                    {
                                                        required: this.state.phoneValue.length > 9 || (contactData && contactData.phoneNumber && contactData.phoneNumber.length > 9) ? false : formRequiredValue,
                                                        message: 'Please input your E-mail',
                                                    },
                                                ],
                                            })(<Input disabled={disabledState} onChange={(val) => { this.setState({ emailValue: val.target.value }) }} />)}
                                        </Form.Item>

                                        <Form.Item label="Phone Number"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}>
                                            {getFieldDecorator('phoneNumber', {
                                                initialValue: phone,
                                                rules: [{ required: this.state.emailValue.length > 0 || (contactData && contactData.email && contactData.email.length > 0) ? false : formRequiredValue, min: 10, message: 'Please input your phone number' }],
                                            })(
                                                // <div className="mobile-input-containar">
                                                <PhoneInput
                                                    country={'in'}
                                                    enableAreaCodes={true}
                                                    enableSearch={true}
                                                    autoFormat={false}
                                                    inputStyle={{ width: '100%' }}
                                                    disabled={disabledState}
                                                    onChange={(val) =>
                                                        // console.log('wwwwww', val)
                                                        this.setState({ phoneValue: val })
                                                    }
                                                />
                                                // </div>
                                            )}
                                        </Form.Item>

                                        <Form.Item className="Sample"
                                            label="User code"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}
                                        >
                                            {getFieldDecorator(type === "filter" ? 'internalUserContactId' : 'customerCode', {
                                                initialValue: contactData ? contactData.internalUserContactId ? contactData.internalUserContactId : '' : '',
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please input your customer code',
                                                    },
                                                ],
                                            })(<Input disabled={disabledState} />)}
                                        </Form.Item>
                                        <Form.Item
                                            label="Notes"
                                            labelCol={{ xs: 24, sm: 5 }}
                                            wrapperCol={{ xs: 24, sm: 12 }}>
                                            {getFieldDecorator('notes', {
                                                initialValue: contactData ? contactData.notes ? contactData.notes : '' : '',
                                                rules: [{ required: false, }],
                                            })(<TextArea rows={3} disabled={disabledState} />)}
                                        </Form.Item>
                                        <div id="custom_attributes">
                                            <Form.Item
                                                label="Custom Attributes"
                                                labelCol={{ xs: 24, sm: 5 }}
                                                wrapperCol={{ xs: 24, sm: 12 }}>
                                                {getFieldDecorator('customAttributes', {
                                                    initialValue: customString && customString.length > 0 ? customString : tempCustom,
                                                    // initialValue: contactData ? contactData.customAttributes ? contactData.customAttributes : customString : customString,
                                                    rules: [{ required: false, }],
                                                })(<TextArea
                                                    rows={3}
                                                    disabled={disabledState}
                                                    onClick={() => { this.setState({ visible: true }) }}
                                                // value={'111111'}
                                                />)}
                                            </Form.Item>
                                        </div>
                                    </Form>
                                    <Modal
                                        title="Custom Attributes"
                                        visible={this.state.visible}
                                        footer={false}
                                        // okText={'Save'}
                                        // onOk={() => { this.setState({ saveModal: true }) }}
                                        onCancel={() => { this.setState({ visible: false }) }}
                                    >
                                        <CustomFields
                                            editValues={customString && customString.length > 0 ? customString : tempCustom}
                                            onClickSave={this.saveCustomAttributes.bind(this)}
                                        // saveOnModal={this.state.saveModal}
                                        />
                                    </Modal>

                                </div>
                            </CustomScrollbars>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const WrappedApp = Form.create()(ContactDetail);
export default withRouter(WrappedApp);