import React, { Component } from "react";
import { firebase } from '../../firebase/firebase';
import { TRANSACTION_DATA_BY_SHORT_URL, CHECK_CONTRACT_ASSIGNED } from '../../graphQl/subscriptions';
import {
    GET_LISTING_DETAIL_BY_URL, GET_TEMPLATE_DETAIL_BY_URL, CHECK_CONTACT_WITH_PHONE_NUMBER_LISTING,
    GET_SHORT_URL, CHECK_LISTING, CHECK_BUSINESS_CONTACT, MESSAGE_TEMPLATE_BY_ID,
    GET_ADMINS_BY_LISTING_ID, MESSAGE_TEMPLATE_RULES_BY_STATUS, GET_SHOP_ORDER_COUNT
} from '../../graphQl/queries';
import {
    INSERT_TRANSACTION_FOR_PUBLIC_URL, ADD_CONTACT_MUTATION,
    ADD_LISTING_URL, CREATE_LISTING_VIA_BUSINESS_CONTACT, ADD_MEMBER_TO_LISTING,
    UPDATE_BUSINESS_CONTACT_ID, ADD_BUSINESS_CONTACT, UPDATE_CONTACT, UPADTE_ORDER_COUNT
} from '../../graphQl/mutations';
import { GET_LEAD_TRANSACTION_DATA, GET_SHORT_URL_SUBS } from '../../graphQl/subscriptions';
import client from '../../apollo/apollo'
import {
    Card, Button, Alert, Tooltip, Spin, Modal, Row, Col, Typography, Layout, Badge
} from 'antd'
import { message, Popconfirm, Icon } from 'antd'
import { withRouter } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars'
import moment from 'moment'
import { AddListingCommunictionProviderSMS, AddListingCommunictionProviderEMAIL } from '../../util/Listings'
import EcommerceForm from '../EcommerceForm/index'
import { cloneDeep } from 'lodash'
import { CONFIG } from '../../config/config';
import { getENVConfig } from '../../util/common'

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

import Config from '../../util/config'

// import { CheckCircleOutlined } from '@ant-design/icons';

const uuidv1 = require('uuid/v1');
const publicIp = require('public-ip');

const { Header, Footer, Sider, Content } = Layout;


class TransactionTemplateView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            templateListingId: '',
            templateViewData: '',
            loginModalOpen: false,
            templateData: {},
            loading: false,
            userPreviousShortUrl: '',
            mappedListingId: '',
            contactId: '',
            updateContact: true,
            isSumbitSuccess: false,
            ecommerceOrderid: "",
            temptransactionId: '',
            orderSubmittedBy: {},
            listingDetail: {},
            templateSettings: {},
            userSubmissionMsg: "",
            minorderModal: false,
            minorderText: '',
            showDeeplinkViewApp: true,
            orderNumber: 1,
            scrollViewSection: null,
            cartIconCount: 0,
            orderData: {},
            paylater: false,
            idempotencyKey: '',
            classactive: false,
            openRightModal: false

        }
    }

    componentDidMount() {
        console.log('hahahahah', this.props)
        if (this.props.location.state && this.props.location.state.loggedinForPublicurl) {
            this.state.loading = true;
            this.setState({ loading: true }, () => {
                this.getUrlDetail(true)
            });
        } else if (this.props.location.state && this.props.location.state.signatureFormData) {
            this.state.loading = true;
            this.setState({ loading: true }, () => {
                this.getUrlDetail(true)
            });
            //  this.checkContact(this.props.location.state.signatureFormData)
        } else {
            this.getUrlDetail()
        }

        let res = localStorage.getItem('PendingOrderdetails')
        if (res) {
            let pendingOrderDetails = JSON.parse(res);
            if (pendingOrderDetails && pendingOrderDetails.publicUrl &&
                pendingOrderDetails.publicUrl === window.location.href) {
                console.log('localstoreinside', pendingOrderDetails, window.location.href.toString())

                this.setState({ pendingPaymentModal: true, orderData: pendingOrderDetails.orderData });
            }
        }
    }

    pendingPaymentOKCLicked() {
        let res = localStorage.getItem('PendingOrderdetails')
        if (res) {
            let pendingOrderDetails = JSON.parse(res);
            console.log('localstoreinside', pendingOrderDetails)
            this.insertTransaction(pendingOrderDetails)
        }
    }

    pendingPaymentCancelCLicked() {
        localStorage.removeItem('PendingOrderdetails')
        localStorage.removeItem('orderdata')
        this.setState({ pendingPaymentModal: false });
    }

    insertTransaction(pendingOrderDetails) {

        let variableObject = pendingOrderDetails.lead_object;
        let arrayOfAssignedObject = pendingOrderDetails.arrayOfAssignedObject;
        let statusHistory = pendingOrderDetails.statusHistory;
        let formValues = pendingOrderDetails.formValues;

        variableObject[0].formData.PaymentDetails = {
            paid: false,
            selectedOption: 'Stripe',
            status: "ABANDONED"
        };

        this.setState({ loading: true, pendingPaymentModal: false }, () => {
            client.mutate({
                mutation: INSERT_TRANSACTION_FOR_PUBLIC_URL,
                variables: {
                    "lead_object": variableObject,
                    "arrayOfAssignedObject": arrayOfAssignedObject,
                    "statusHistory": statusHistory
                }

            }).then((response) => {
                console.log('response', response)
                //for ecommerce order id
                localStorage.removeItem('orderFormData');
                localStorage.removeItem('PendingOrderdetails');


                if (formValues && formValues.orderId) {
                    this.setState({ ecommerceOrderid: formValues.orderId, loading: false, isSumbitSuccess: true }, () => {
                        console.log('formValues.orderId', formValues.orderId)
                        let orderId = formValues.orderId
                        let splitOrderId = orderId.split("-")
                        let tempOrderId = splitOrderId[splitOrderId.length - 1]
                        console.log('splitOrderId', splitOrderId, tempOrderId)
                        client.mutate({
                            mutation: UPADTE_ORDER_COUNT,
                            variables: {
                                templateId: this.state.templateData && this.state.templateData.id ? this.state.templateData.id : '',
                                orderCount: tempOrderId
                            }

                        }).then((value) => {
                            console.log('UPADTE_ORDER_COUNT', value)
                            //Push notification end point
                            if (response.data &&
                                response.data.insert_transaction &&
                                response.data.insert_transaction.returning &&
                                response.data.insert_transaction.returning[0] &&
                                response.data.insert_transaction.returning[0].id) {
                                this.setState({ temptransactionId: response.data.insert_transaction.returning[0].id })
                                axios({
                                    'method': 'POST',
                                    url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/appNotification`,
                                    headers: { 'Content-Type': 'application/json' },
                                    'data': {
                                        transactionId: response.data.insert_transaction.returning[0].id
                                    }
                                }).then((res) => {
                                    console.log('Push notification response', res)
                                }).
                                    catch((err) => {
                                        console.log('Push notification err', err)
                                    })
                            }
                        })
                            .catch(err => {
                                console.log('UPADTE_ORDER_COUNT err', err)
                            })
                        //SELLER NOTIFICAION FLOW STARTS
                        // if (this.state.templateData.templateSettings.fieldValues &&
                        //     this.state.templateData.templateSettings.fieldValues.hasOwnProperty('sellerNotificationDetailsValues') &&
                        //     this.state.templateData.templateSettings.fieldValues.sellerNotificationDetailsValues.length > 0
                        // ) {
                        //     if (response && response.data && response.data.insert_transaction &&
                        //         response.data.insert_transaction.returning.length > 0 &&
                        //         response.data.insert_transaction.returning[0].id) {

                        //         client.subscribe({
                        //             query: GET_SHORT_URL_SUBS,
                        //             variables: { 'transactionId': response.data.insert_transaction.returning[0].id }
                        //         })
                        //             .subscribe((res) => {
                        //                 if (res && res.data && res.data.short_url.length > 0) {
                        //                     const { location } = this.props;
                        //                     var segment_array = [];
                        //                     if (location) {
                        //                         var segment_str = location.pathname; // return segment1/segment2/segment3/segment4
                        //                         segment_array = segment_str.split('/');
                        //                     }
                        //                     const _data = {
                        //                         notificaitonDetails: this.state.templateData.templateSettings.fieldValues.sellerNotificationDetailsValues,
                        //                         orderId: this.state.ecommerceOrderid,
                        //                         customerDetails: this.state.orderSubmittedBy,
                        //                         listingUrl: segment_array[1],
                        //                         listingId: this.state.templateListingId,
                        //                         userId: localStorage.getItem('uid'),
                        //                         transactionId: response.data.insert_transaction.returning[0].id
                        //                     };
                        //                     axios({
                        //                         method: 'post',
                        //                         // url: 'http://localhost:5001/totely-prod1-ba624/us-central1/sellerNotification',
                        //                         url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/sellerNotification`,
                        //                         headers: { 'Content-Type': 'application/json' },
                        //                         data: _data
                        //                     })
                        //                         .then(async result => {
                        //                         })
                        //                         .catch((err) => {
                        //                             message.warning('Failed notifying seller about your order')

                        //                         });
                        //                 } else {

                        //                 }
                        //             }, err => {

                        //             })



                        //     }
                        //     this.setState({ isSumbitSuccess: true }, () => {
                        //         this.setState({ loading: false })
                        //     })
                        // }

                        // //SELLER NOTIFICAION FLOW ENDS
                        // else {
                        //     this.setState({ loading: false, isSumbitSuccess: true });
                        // }
                    })
                } else {
                    this.setState({ loading: false, isSumbitSuccess: true })
                }
            })
                .catch(err => {
                    message.error('Transaction added Failed')
                    console.log(err, 'ordernow er')
                    this.setState({ loading: false, isSumbitSuccess: false })
                });
        });
    }



    //get url details from url
    getUrlDetail = (publicUrl) => {
        const { location } = this.props;
        if (location) {
            this.setState({ loading: true }, () => {
                var segment_str = location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split('/');
                var last_segment = segment_array.pop();

                client.query({ query: GET_LISTING_DETAIL_BY_URL, variables: { listingName: segment_array[1] } })
                    .then((res) => {
                        console.log('GET_LISTING_DETAIL_BY_URL', res)
                        //show data if listing is in active 
                        if (res && res.data && res.data.listing_url.length > 0 && res.data.listing_url[0].listing &&
                            res.data.listing_url[0].listing.status && res.data.listing_url[0].listing.status === "ACTIVE") {
                            this.setState({
                                templateListingId:
                                    res.data.listing_url[0].listingId ? res.data.listing_url[0].listingId : '',
                                listingDetail: res.data.listing_url[0]
                            }, () => {
                                this.getTemplateDetail(last_segment, publicUrl)
                            })
                        } else {
                            this.setState({ templateListingId: '', listingDetail: {} }, () => {
                                this.getTemplateDetail(last_segment, publicUrl)
                            })
                        }
                    }).catch((err) => {
                        this.setState({ loading: false })
                    })
            })
        }
    }

    //get template details using listing id and public url
    getTemplateDetail = (url, publicUrl) => {

        let variableData = {
            'listingId': this.state.templateListingId,
            'templatepublicurl': url,
            'transactionTemplateStatus': "ACTIVE"
        }
        client.query({ query: GET_TEMPLATE_DETAIL_BY_URL, variables: variableData })
            .then((res) => {
                console.log('GET_TEMPLATE_DETAIL_BY_URL', res)
                if (res && res.data && res.data.transaction_template.length > 0) {
                    this.setState({
                        templateViewData:
                            res.data.transaction_template[0].displayTemplate ?
                                res.data.transaction_template[0].displayTemplate : '',
                        templateData: res.data.transaction_template[0],
                        templateSettings: res.data.transaction_template[0].templateSettings
                    }, () => {
                        if (publicUrl) {
                            if (this.props.location.state.signatureFormData) {
                                this.checkContact(this.props.location.state.signatureFormData)
                            } else {
                                this.checkContact(this.props.location.state.loggedinForPublicurl)
                            }
                        } else {
                            this.setState({ loading: false }, () => {
                                this.getUserPreviousSubmission()
                            })
                        }
                    })
                    console.log(this.state.templateData, "templatedetails")
                } else {
                    this.setState({ templateViewData: '', templateData: {}, loading: false }, () => {
                    })
                }
            }).catch((err) => {
                this.setState({ loading: false })
            })
    }

    //get user latest previous transaction data
    getUserPreviousSubmission = () => {
        let uid = localStorage.getItem('uid')
        if (uid) {
            let variableData = {
                "startNumber": 0,
                "limit": 1,
                'createdBy': localStorage.getItem('uid'),
                'type': this.state.templateData.type,
                'transactionTemplateId': this.state.templateData ? this.state.templateData.id : ""
            }

            client.subscribe({ query: GET_LEAD_TRANSACTION_DATA, variables: variableData })
                .subscribe(res => {
                    if (res && res.data && res.data.transaction.length > 0) {
                        let transactionId = res.data.transaction[0].id
                        let data = {
                            'transactionId': transactionId
                        }

                        client.subscribe({ query: GET_SHORT_URL_SUBS, variables: data })
                            .subscribe((res) => {
                                if (res && res.data && res.data.short_url.length > 0) {

                                    this.setState({ userPreviousShortUrl: res.data.short_url[0].shortURL }, () => {

                                    })
                                } else {
                                    this.setState({ userPreviousShortUrl: "" })
                                }
                            }, err => {
                                this.setState({ loading: false })
                            })

                    } else {
                        this.setState({ userPreviousShortUrl: "" })
                    }
                }, err => {
                    this.setState({ loading: false })
                })

        } else {
            this.setState({ loading: false })
        }
    }

    //when click on submit
    onSubmitFormFields = () => {
        this.setState({ loading: true }, () => {
            // let uid = localStorage.getItem('uid')

            let signatureFormData = {};
            var inputs, index;

            inputs = document.getElementsByTagName('input');
            for (index = 0; index < inputs.length; ++index) {
                var s = inputs[index].name;
                var replaced = s.replace(/[^A-Z0-9]/ig, "");
                signatureFormData[replaced] = inputs[index].value;
            }

            //for text area tag
            var inputsText = document.getElementsByTagName('textarea');
            var indextext = 0;
            if (inputsText.length > 0) {
                for (indextext = 0; indextext < inputsText.length; ++indextext) {
                    var t = inputsText[indextext].name;
                    if (inputs.length > 0) {
                        signatureFormData[t] = inputsText[indextext].value;
                    }
                }
            }

            signatureFormData['signedAt'] = String(new Date());

            this.setState({ formData: signatureFormData });
            const isEmptyvalue = !Object.values(signatureFormData).every(x => (x !== null && x !== ''));
            console.log('cal;lllled')
            if (localStorage.getItem('uid')) {

                if (isEmptyvalue === false) {
                    this.checkContact(signatureFormData)
                    this.setState({ loginModalOpen: false })
                }
                //if any field is empty in form
                else {
                    message.warning(this.locale("Please fill all the form fields"))
                    this.setState({ loading: false })
                }
            } else {
                this.setState({ loginModalOpen: true })
            }
        })

    }

    checkContact = (formData) => {
        if (formData &&
            formData.businessName &&
            formData.businessCode &&
            formData.address1 &&
            formData.city &&
            formData.state &&
            formData.postalCode &&
            formData.country
        ) {
            this.checkBusiness(formData)
        } else {
            this.checkPhoneNumber(formData, 'individual')
        }
    }

    checkBusiness = (formData) => {
        let variableData = {
            name: formData.businessName,
            postalCode: formData.postalCode,
            address1: formData.address1,
            city: formData.city,
            country: formData.country,
            state: formData.state,
            internalBusinessContactId: formData.businessCode,
            listingId: this.state.templateListingId
        }
        client.query({ query: CHECK_BUSINESS_CONTACT, variables: variableData })
            .then((res) => {
                if (res && res.data && res.data.business_contact && res.data.business_contact.length > 0) {
                    this.setState({
                        contactId: res.data.business_contact[0].defaultUserContactId,
                        orderSubmittedBy:
                        {
                            phoneNumber: res.data.business_contact[0].contactId.phoneNumber,
                            email: res.data.business_contact[0].contactId.email && res.data.business_contact[0].contactId.email
                        }
                    }, () => {
                        this.insertInTransaction(formData, res.data.business_contact[0].id, 'business')
                    })
                } else {
                    this.checkPhoneNumber(formData, 'business')
                }
            })
            .catch(err => {
            })
    }

    //check phone number and get contact id
    checkPhoneNumber = (formData, type) => {
        console.log(formData, "afterplaceorder")
        if (localStorage.getItem('phoneNumber')) {

            //check loggedin phone number is already created in contact table
            let variableData = {
                'listingId': this.state.templateListingId,
                'phoneNumber': localStorage.getItem('phoneNumber')
            }
            client.query({ query: CHECK_CONTACT_WITH_PHONE_NUMBER_LISTING, variables: variableData })
                .then((res) => {
                    //if phoneNumber exist
                    if (res && res.data && res.data.contact.length > 0) {
                        if (this.state.templateData && this.state.templateData.displayTemplateType &&
                            this.state.templateData.displayTemplateType === 'EcommerceForm') {
                            let userMail = "";
                            //get user name/email from customer details which has customer email/name type

                            if (formData && formData.UserFormFullData) {
                                _.find(formData.UserFormFullData, (resp, i) => {
                                    if ((resp && (resp.attributeType === "customerEmail"))) {
                                        userMail = resp.attributeValue;
                                        this.UpdateContact(res.data.contact[0].id, userMail);
                                    }
                                });
                            }
                        }

                        let contactId = res.data.contact[0].id
                        this.setState({
                            contactId: contactId,
                            orderSubmittedBy:
                            {
                                phoneNumber: localStorage.getItem('phoneNumber'),
                                email: res.data.contact[0].email && res.data.contact[0].email
                            }
                        }, () => {
                        })
                        if (type === 'individual') {
                            this.insertInTransaction(formData, contactId, 'individual')
                        } else if (type === 'business') {
                            this.checkListing(formData)
                        }
                    }
                    //phoneNumber not exist
                    else {
                        let userMail = ""
                        let name = ""

                        if (this.state.templateData && this.state.templateData.displayTemplateType &&
                            this.state.templateData.displayTemplateType === 'EcommerceForm') {

                            //get user name/email from customer details which has customer email/name type
                            if (formData && formData.UserFormFullData) {
                                _.find(formData.UserFormFullData, (res, i) => {
                                    if ((res && (res.attributeType === "customerName"))) {
                                        name = res.attributeValue
                                    }
                                    if ((res && (res.attributeType === "customerEmail"))) {
                                        userMail = res.attributeValue
                                    }
                                });

                            }

                        } else {
                            userMail = formData.email ? formData.email : ""
                            name = formData.firstName ? formData.firstName : ""
                        }
                        //create contact
                        let variableData = {
                            listingId: this.state.templateListingId,
                            firstName: name,
                            lastName: formData.lastName ? formData.lastName : "",
                            email: userMail,
                            // phoneNumber: localStorage.getItem('phoneNumber'),
                            phoneNumber: formData && formData.loggedinPhoneNumber ? formData.loggedinPhoneNumber :
                                localStorage.getItem('phoneNumber'),
                            notes: "",
                            customAttributes: null,
                            createdBy: localStorage.getItem('uid'),
                            mappingUserId: null,
                            internalUserContactId: null,
                            businessContactRole: null,
                            creationSource: this.state.templateData.type.charAt(0).toUpperCase() + this.state.templateData.type.slice(1),
                            validEmail: false
                        }
                        client.mutate({ mutation: ADD_CONTACT_MUTATION, variables: variableData })
                            .then((response) => {
                                if (response && response.data && response.data.insert_contact &&
                                    response.data.insert_contact.returning &&
                                    response.data.insert_contact.returning[0] &&
                                    response.data.insert_contact.returning[0].id) {
                                    let contactId = response.data.insert_contact.returning[0].id
                                    this.setState({
                                        contactId: contactId, updateContact: true,
                                        orderSubmittedBy:
                                        {
                                            phoneNumber: localStorage.getItem('phoneNumber'),
                                            email: userMail
                                        }
                                    })
                                    if (type === 'individual') {
                                        this.insertInTransaction(formData, contactId, 'individual')
                                    } else if (type === 'business') {
                                        this.checkListing(formData)
                                    }
                                }
                            })
                            .catch(err => {
                                message.error('Add Contact Failed')
                                this.setState({ loading: false })
                            });

                    }
                })
                .catch((err) => {
                })
        } else {
            let userMail = ""
            let name = ""
            let ph = ''
            console.log("insideelse")
            if (this.state.templateData && this.state.templateData.displayTemplateType &&
                this.state.templateData.displayTemplateType === 'EcommerceForm') {

                //get user name/email from customer details which has customer email/name type
                if (formData && formData.UserFormFullData) {
                    _.find(formData.UserFormFullData, (res, i) => {
                        if ((res && (res.attributeType === "customerName"))) {
                            name = res.attributeValue
                        }
                        if ((res && (res.attributeType === "customerEmail"))) {
                            userMail = res.attributeValue
                        }
                        if ((res && (res.attributeType === "PhoneNumber"))) {
                            ph = res.attributeValue
                        }
                    });

                }
                console.log(ph, "insideelse11")
            } else {
                userMail = formData.email ? formData.email : ""
                name = formData.firstName ? formData.firstName : ""
                ph = formData.phoneNumber ? formData.phoneNumber : ""
            }


            if (ph && ph !== '') {

                //check loggedin phone number is already created in contact table
                let variableData = {
                    'listingId': this.state.templateListingId,
                    'phoneNumber': ph
                }
                client.query({ query: CHECK_CONTACT_WITH_PHONE_NUMBER_LISTING, variables: variableData })
                    .then((res) => {
                        //if phoneNumber exist
                        if (res && res.data && res.data.contact.length > 0) {
                            if (this.state.templateData && this.state.templateData.displayTemplateType &&
                                this.state.templateData.displayTemplateType === 'EcommerceForm') {
                                let userMail = "";
                                //get user name/email from customer details which has customer email/name type

                                if (formData && formData.UserFormFullData) {
                                    _.find(formData.UserFormFullData, (resp, i) => {
                                        if ((resp && (resp.attributeType === "customerEmail"))) {
                                            userMail = resp.attributeValue;
                                            this.UpdateContact(res.data.contact[0].id, userMail);
                                        }
                                    });
                                }
                            }
                            console.log(res, "insideelse222")
                            let contactId = res.data.contact[0].id
                            this.setState({
                                contactId: contactId,
                                orderSubmittedBy:
                                {
                                    phoneNumber: ph,
                                    email: res.data.contact[0].email && res.data.contact[0].email
                                }
                            }, () => {
                            })
                            if (type === 'individual') {
                                this.insertInTransaction(formData, contactId, 'individual')
                            } else if (type === 'business') {
                                this.checkListing(formData)
                            }
                        }
                        //phoneNumber not exist
                        else {
                            //create contact
                            let variableData = {
                                listingId: this.state.templateListingId,
                                firstName: name,
                                lastName: formData.lastName ? formData.lastName : "",
                                email: userMail,
                                // phoneNumber: localStorage.getItem('phoneNumber'),
                                phoneNumber: ph,
                                notes: "",
                                customAttributes: null,
                                createdBy: localStorage.getItem('uid'),
                                mappingUserId: null,
                                internalUserContactId: null,
                                businessContactRole: null,
                                creationSource: this.state.templateData.type.charAt(0).toUpperCase() + this.state.templateData.type.slice(1),
                                validEmail: false
                            }
                            console.log(variableData, "beforquery")
                            client.mutate({ mutation: ADD_CONTACT_MUTATION, variables: variableData })
                                .then((response) => {
                                    console.log(response, "insideelse333")
                                    if (response && response.data && response.data.insert_contact &&
                                        response.data.insert_contact.returning &&
                                        response.data.insert_contact.returning[0] &&
                                        response.data.insert_contact.returning[0].id) {
                                        let contactId = response.data.insert_contact.returning[0].id
                                        this.setState({
                                            contactId: contactId,
                                            updateContact: true,
                                            orderSubmittedBy:
                                            {
                                                phoneNumber: ph,
                                                email: userMail
                                            }
                                        })

                                        if (type === 'individual') {
                                            this.insertInTransaction(formData, contactId, 'individual')
                                        } else if (type === 'business') {
                                            this.checkListing(formData)
                                        }
                                    }
                                })
                                .catch(err => {
                                    message.error('Add Contact Failed')
                                    console.log(err, "addcontacterr")
                                    this.setState({ loading: false })
                                });

                        }
                    })
                    .catch((err) => {
                        console.log(err, "queryerr")
                    })
            } else {
                this.insertInTransaction(formData, null, 'individual')
            }
        }
    }

    UpdateContact = (contactId, mail) => {
        let values = {
            newmail: mail,
            contactId: contactId
        }
        client.mutate({ mutation: UPDATE_CONTACT, variables: values })
            .then((response) => {
            })
            .catch((e) => {
                console.log('errorcontactupdate', e);
            })
    }

    checkListing = (formData) => {
        const checkListParams = {
            p_name: formData.businessName,
            p_address1: formData.address1,
            p_city: formData.city,
            p_country: formData.country
        }

        client
            .query({
                query: CHECK_LISTING,
                variables: checkListParams
            })
            .then(
                response => {
                    if (response.data && response.data.search_listings && response.data.search_listings.length > 0) {
                        this.setState({ mappedListingId: response.data.search_listings[0].id }, () => {
                            this.createBusinessContact(formData)
                        })
                    }
                    else {
                        this.createListing(formData)
                    }
                })
            .catch(err => {
            })
    }

    createListing = (formData) => {
        const payload = {
            name: formData.businessName,
            phoneNumber: localStorage.getItem('phoneNumber'),
            description: '',
            address1: formData.address1,
            address2: '',
            city: formData.city,
            state: formData.state,
            country: formData.country,
            postalCode: formData.postalCode,
            createdBy: localStorage.getItem('uid'),
            type: this.state.templateData.type.charAt(0).toUpperCase() + this.state.templateData.type.slice(1),
            listingClaimed: false,
            listingCreationBySource: this.state.templateData.type.charAt(0).toUpperCase() + this.state.templateData.type.slice(1),
            listingCreationBySourceListingId: this.state.templateListingId,
            listing_img: null
        };
        client.mutate({ mutation: CREATE_LISTING_VIA_BUSINESS_CONTACT, variables: payload }).then((response) => {
            if (response.data && response.data.insert_listing && response.data.insert_listing.returning[0] && response.data.insert_listing.returning[0].id) {
                this.setState({ mappedListingId: response.data.insert_listing.returning[0].id }, () => {
                    this.addListingAdmin(`${formData.city}.${formData.businessName}`, formData)
                })
            }
        }).catch(err => {
            message.error('New Listing Creation Failed')
        });
    }

    addListingAdmin = (name, formData) => {
        const addMember = {
            "listingId": this.state.mappedListingId,
            "role": "listingAdmin",
            "userId": localStorage.getItem('uid')
        };
        if (localStorage.getItem('uid')) {
            client.mutate({ variables: addMember, mutation: ADD_MEMBER_TO_LISTING }).then((result) => {
                this.addListingUrl(name, formData)
            }).catch(err => {
                message.error('New Listing Created but failed to add listing member');
            });
        } else {
            message.error('Listing member id missing')
        }
    }

    addListingUrl = (name, formData) => {
        const addUrl = {
            "listingId": this.state.mappedListingId,
            "urlName": name
        };
        client.mutate({ variables: addUrl, mutation: ADD_LISTING_URL }).then((urlres) => {
            if (urlres && urlres.data && urlres.data.insert_listing_url &&
                urlres.data.insert_listing_url.returning.length > 0 &&
                urlres.data.insert_listing_url.returning[0].listingId) {
                let listingId = urlres.data.insert_listing_url.returning[0].listingId
                AddListingCommunictionProviderEMAIL(listingId)
                    .then((res) => {
                    }).catch(err => {
                        message.error('Error on creating AddListingCommunictionProviderEMAIL')
                    })
                AddListingCommunictionProviderSMS(listingId)
                    .then((res) => {
                    }).catch(err => {
                        message.error('Error on creating AddListingCommunictionProviderSMS')
                    })
            }
            this.setState({ listingName: name, visibleCreateListingModal: false })
            this.createBusinessContact(formData)
            message.success('New listing created sucessfully!')
        }).catch((err) => {
            message.error('New Listing Created but failed to create listing url');
        })
    }

    createBusinessContact = (formData) => {
        const { contactId, mappedListingId } = this.state
        if (contactId && mappedListingId) {
            const variableObj = {
                listingId: localStorage.getItem('selectedListingId'),
                address1: formData.address1,
                address2: '',
                city: formData.city,
                state: formData.state,
                country: formData.country,
                postalCode: formData.postalCode,
                internalBusinessContactId: formData.businessCode,
                defaultUserContactId: contactId,
                mappedListingId: mappedListingId,
                name: formData.businessName,
                description: '',
                notes: '',
                createdBy: localStorage.getItem('uid'),
                updatedBy: null
            }

            client.mutate({
                mutation: ADD_BUSINESS_CONTACT,
                variables: variableObj
            }).then((response) => {
                this.insertInTransaction(formData, response.data.insert_business_contact.returning[0].id, 'business')
                if (this.state.updateContact && response.data && response.data.insert_business_contact && response.data.insert_business_contact.returning[0] && response.data.insert_business_contact.returning[0].id) {
                    this.updateBusinessId(response.data.insert_business_contact.returning[0].id)
                }
            })
                .catch(err => {
                    message.error(`Added business contact Failed`)
                });
        } else {
            message.error('Contact and listing missing')
        }
    }

    updateBusinessId = (id) => {
        client.mutate({
            mutation: UPDATE_BUSINESS_CONTACT_ID,
            variables: {
                id: this.state.contactId,
                businessContactId: id
            }
        }).then((response) => {
        })
            .catch(err => {
            })
    }


    getShortUrl(transId) {
        return new Promise(function (resolve, reject) {
            client.subscribe({ query: GET_SHORT_URL_SUBS, variables: { 'transactionId': transId } })
                .subscribe((res) => {
                    if (res && res.data && res.data.short_url.length > 0) {

                        resolve(res.data.short_url[0].shortURL)
                    } else {
                        resolve('')
                    }
                }, err => {
                    resolve('')
                })
        });
    }

    //insert in transaction
    insertInTransaction = async (formValues, id, type) => {
        console.log('ordernow', this.state.templateData)
        let paylater = JSON.parse(localStorage.getItem('paylater'))
        if (this.state.templateData) {
            this.setState({ loading: true }, async () => {
                let submissionMsg

                if (formValues && formValues.UserForm) {
                    Object.keys(formValues.UserForm).map((item, i) => {
                        if (item.replace(/ /g, '').toLowerCase() === 'customername'
                            || item.replace(/ /g, '').toLowerCase() === 'name'
                            || item.replace(/ /g, '').toLowerCase() === 'username'
                            || item.replace(/ /g, '').toLowerCase() === 'firstname') {
                            var name = formValues.UserForm[item];
                            formValues.customername = name;
                        }
                    });
                }

                //set signed status based on delivery option
                let signedStatus
                if (this.state.templateData && this.state.templateData.eligibleStatusList &&
                    this.state.templateData.eligibleStatusList.includeDeliveryOption) {
                    if (formValues.DeliveryDetails && formValues.DeliveryDetails.selectedOption) {
                        if (this.state.templateData && this.state.templateData.signedStatus) {
                            signedStatus = `${this.state.templateData.signedStatus}-${formValues.DeliveryDetails.selectedOption}`
                        }
                    } else {
                        signedStatus = this.state.templateData.signedStatus ? this.state.templateData.signedStatus : "";
                    }
                }
                else {
                    signedStatus = this.state.templateData.signedStatus ? this.state.templateData.signedStatus : "";
                }
                let messageId
                client.query({
                    query: MESSAGE_TEMPLATE_RULES_BY_STATUS,
                    variables: { transactionTemplateId: this.state.templateData.id, currentStatus: signedStatus }
                })
                    .then((res) => {
                        if (res && res.data && res.data.transaction_template_rules.length > 0 &&
                            res.data.transaction_template_rules[0].ruleType === "statusChangeNotification"
                            && res.data.transaction_template_rules[0].notificationChannel) {

                            let data = res.data.transaction_template_rules[0].notificationChannel
                            if (data.hasOwnProperty('SMS') && data.SMS && data.SMS.length) {
                                messageId = data.SMS[0]

                            } else if (data.hasOwnProperty('EMAIL') && data.EMAIL && data.EMAIL.length) {
                                messageId = data.SMS[0]
                            }

                            if (messageId) {
                                client.query({
                                    query: MESSAGE_TEMPLATE_BY_ID,
                                    variables: { templateId: messageId }
                                })
                                    .then((res) => {
                                        if (res && res.data && res.data.template.length > 0 &&
                                            res.data.template[0].content) {
                                            let data = res.data.template[0].content
                                            console.log("data123", data)
                                            if (data.indexOf('{#var#}') > -1) {
                                                submissionMsg = data.replace(/{#var#}/g, "")
                                            }
                                            //  else {
                                            //     submissionMsg = data.replace(/#(.*)#/, "")
                                            // }
                                            // submissionMsg = data.replace(/#(.*)#/, "")
                                            this.setState({ userSubmissionMsg: submissionMsg }, () => {
                                            })
                                        }
                                        else {
                                            submissionMsg = ""
                                        }

                                    }).catch((err) => {
                                    })
                            } else {
                                submissionMsg = ""
                            }

                        }
                    }).catch((err) => {
                        console.log('ordernowerr', err)
                    })

                let userIp = await publicIp.v4();
                let updatedAt = moment().format();
                let transactionId = uuidv1();
                this.setState({ temptransactionId: transactionId });
                //fetch listing team admins and assignes tp transaction as default
                let teamMembers = this.fetchListingTeamAdmins(this.state.templateListingId, transactionId).then((res) => {
                    let variableObject = [{
                        id: transactionId,
                        listingId: this.state.templateListingId,
                        transactionTemplateId: this.state.templateData.id,
                        formData: formValues,
                        type: this.state.templateData.type,
                        recipientUserContactId: type === 'individual' ? id : null,
                        recipientBusinessContactId: type === 'business' ? id : null,
                        contactId: this.state.contactId ? this.state.contactId : null,
                        createdBy: localStorage.getItem('uid') ? localStorage.getItem('uid') : null,
                        currentStatus: signedStatus,
                        // currentStatus: this.state.templateData.signedStatus ? this.state.templateData.signedStatus : "",
                        signedIP: userIp,
                        updatedAt: updatedAt,
                        statusUpdatedAt: updatedAt,
                    }]
                    console.log('variableObject', variableObject)
                    let statusParams = [{
                        transactionid: transactionId,
                        status: signedStatus,
                        created_by: localStorage.getItem('uid') ? localStorage.getItem('uid') : null,
                    }]
                    this.props.history.replace({ pathname: "", state: "" })

                    if ((this.state.templateData && this.state.templateData.templateSettings &&
                        this.state.templateData.templateSettings.paymentType && this.state.templateData.templateSettings.paymentType === 'Stripe') && !paylater) {
                        if (this.state.orderData && Object.keys(this.state.orderData).length === 0) {
                            let neworderdata = localStorage.getItem("orderdata")
                            localStorage.removeItem('paylater')
                            this.setState({ orderData: JSON.parse(neworderdata) }, () => {
                                let pendingOrderdetails = {
                                    templateId: this.state.templateData && this.state.templateData.id ? this.state.templateData.id : '',
                                    "lead_object": variableObject,
                                    "arrayOfAssignedObject": res,
                                    "statusHistory": statusParams,
                                    formValues: formValues,
                                    publicUrl: window.location.href,
                                    temptransactionId: transactionId,
                                    userSubmissionMsg: this.state.userSubmissionMsg,
                                    templateSettings: this.state.templateSettings,
                                    listingDetail: this.state.listingDetail,
                                    orderData: JSON.parse(neworderdata)
                                }
                                localStorage.setItem('PendingOrderdetails', JSON.stringify(pendingOrderdetails))
                                localStorage.removeItem("orderdata")
                            })


                        } else {
                            let pendingOrderdetails = {
                                templateId: this.state.templateData && this.state.templateData.id ? this.state.templateData.id : '',
                                "lead_object": variableObject,
                                "arrayOfAssignedObject": res,
                                "statusHistory": statusParams,
                                formValues: formValues,
                                publicUrl: window.location.href,
                                temptransactionId: transactionId,
                                userSubmissionMsg: this.state.userSubmissionMsg,
                                templateSettings: this.state.templateSettings,
                                listingDetail: this.state.listingDetail,
                                orderData: this.state.orderData
                            }
                            localStorage.setItem('PendingOrderdetails', JSON.stringify(pendingOrderdetails))
                        }

                        this.onStripePaymentClicked()

                    } else {

                        client.mutate({
                            mutation: INSERT_TRANSACTION_FOR_PUBLIC_URL,
                            variables: {
                                "lead_object": variableObject,
                                "arrayOfAssignedObject": res,
                                "statusHistory": statusParams
                            }

                        }).then((response) => {
                            console.log('response', response)
                            //for ecommerce order id
                            localStorage.removeItem('orderFormData');

                            if (formValues && formValues.orderId) {
                                this.setState({ ecommerceOrderid: formValues.orderId, loading: false, isSumbitSuccess: true }, () => {
                                    console.log('formValues.orderId', formValues.orderId)
                                    let orderId = formValues.orderId
                                    let splitOrderId = orderId.split("-")
                                    let tempOrderId = splitOrderId[splitOrderId.length - 1]
                                    console.log('splitOrderId', splitOrderId, tempOrderId)
                                    client.mutate({
                                        mutation: UPADTE_ORDER_COUNT,
                                        variables: {
                                            templateId: this.state.templateData && this.state.templateData.id ? this.state.templateData.id : '',
                                            orderCount: tempOrderId
                                        }

                                    }).then((value) => {
                                        console.log('UPADTE_ORDER_COUNT', value)
                                        //Push notification end point
                                        if (response.data &&
                                            response.data.insert_transaction &&
                                            response.data.insert_transaction.returning &&
                                            response.data.insert_transaction.returning[0] &&
                                            response.data.insert_transaction.returning[0].id) {
                                            axios({
                                                'method': 'POST',
                                                url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/appNotification`,
                                                headers: { 'Content-Type': 'application/json' },
                                                'data': {
                                                    transactionId: response.data.insert_transaction.returning[0].id
                                                }
                                            }).then((res) => {
                                                console.log('Push notification response', res)
                                            }).
                                                catch((err) => {
                                                    console.log('Push notification err', err)
                                                })
                                        }
                                    })
                                        .catch(err => {
                                            console.log('UPADTE_ORDER_COUNT err', err)
                                        })
                                    //SELLER NOTIFICAION FLOW STARTS
                                    // if (this.state.templateData.templateSettings.fieldValues &&
                                    //     this.state.templateData.templateSettings.fieldValues.hasOwnProperty('sellerNotificationDetailsValues') &&
                                    //     this.state.templateData.templateSettings.fieldValues.sellerNotificationDetailsValues.length > 0
                                    // ) {
                                    //     if (response && response.data && response.data.insert_transaction &&
                                    //         response.data.insert_transaction.returning.length > 0 &&
                                    //         response.data.insert_transaction.returning[0].id) {

                                    //         client.subscribe({
                                    //             query: GET_SHORT_URL_SUBS,
                                    //             variables: { 'transactionId': response.data.insert_transaction.returning[0].id }
                                    //         })
                                    //             .subscribe((res) => {
                                    //                 if (res && res.data && res.data.short_url.length > 0) {
                                    //                     const { location } = this.props;
                                    //                     var segment_array = [];
                                    //                     if (location) {
                                    //                         var segment_str = location.pathname; // return segment1/segment2/segment3/segment4
                                    //                         segment_array = segment_str.split('/');
                                    //                     }
                                    //                     const _data = {
                                    //                         notificaitonDetails: this.state.templateData.templateSettings.fieldValues.sellerNotificationDetailsValues,
                                    //                         orderId: this.state.ecommerceOrderid,
                                    //                         customerDetails: this.state.orderSubmittedBy,
                                    //                         listingUrl: segment_array[1],
                                    //                         listingId: this.state.templateListingId,
                                    //                         userId: localStorage.getItem('uid'),
                                    //                         transactionId: response.data.insert_transaction.returning[0].id
                                    //                     };
                                    //                     axios({
                                    //                         method: 'post',
                                    //                         // url: 'http://localhost:5001/totely-prod1-ba624/us-central1/sellerNotification',
                                    //                         url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/sellerNotification`,
                                    //                         headers: { 'Content-Type': 'application/json' },
                                    //                         data: _data
                                    //                     })
                                    //                         .then(async result => {
                                    //                         })
                                    //                         .catch((err) => {
                                    //                             message.warning('Failed notifying seller about your order')

                                    //                         });
                                    //                 } else {

                                    //                 }
                                    //             }, err => {

                                    //             })



                                    //     }
                                    //     this.setState({ isSumbitSuccess: true }, () => {
                                    //         this.setState({ loading: false })
                                    //     })
                                    // }

                                    // //SELLER NOTIFICAION FLOW ENDS
                                    // else {
                                    //     this.setState({ loading: false, isSumbitSuccess: true });
                                    // }
                                })
                            } else {
                                this.setState({ loading: false, isSumbitSuccess: true })
                            }
                        })
                            .catch(err => {
                                message.error('Transaction added Failed')
                                console.log(err, 'ordernow er')
                                this.setState({ loading: false, isSumbitSuccess: false })
                            });
                    }
                });
            })

        }
    }

    //fetch listing team admins by listing id to assing in transaction
    fetchListingTeamAdmins = (listingId, transId) => {
        return new Promise(function (resolve, reject) {
            if (listingId) {

                client.query({
                    query: GET_ADMINS_BY_LISTING_ID,
                    variables: { listingId: listingId }
                })
                    .then((res) => {
                        if (res && res.data && res.data.listing_team.length > 0) {
                            let arrayOfAdmins = res.data.listing_team.map(({ userId }) => userId);

                            if (arrayOfAdmins.length > 0) {
                                let createdBy = localStorage.getItem('uid')
                                let arrayOfAssignedObject
                                let transactionId = transId

                                if (arrayOfAdmins.indexOf(createdBy) == -1) {
                                    /*Insert all admins */
                                    arrayOfAssignedObject = arrayOfAdmins.map(userId => {
                                        return { 'assignedBy': createdBy, 'assignedTo': userId, transactionId: transactionId }
                                    });
                                    /* Insert the person who created the transaction */
                                    arrayOfAssignedObject.push({ 'assignedBy': createdBy, 'assignedTo': createdBy, transactionId: transactionId })
                                } else {
                                    /*If admin created the transaction then assign all admins to the transaction by default */
                                    arrayOfAssignedObject = arrayOfAdmins.map(userId => {
                                        return { 'assignedBy': createdBy, 'assignedTo': userId, transactionId: transactionId }
                                    });
                                }
                                resolve(arrayOfAssignedObject)
                            }
                        } else {
                            resolve([])
                        }
                    }).catch((err) => {
                        reject(err)
                    })
            }
        })
    }

    logout = () => {
        if (localStorage.getItem('uid')) {
            var provider = new firebase.auth.GoogleAuthProvider();

            provider.setCustomParameters({ prompt: 'select_account' });
            firebase.auth().signOut()
                .then(async (res) => {
                    localStorage.removeItem('uid')
                    localStorage.removeItem('phoneNumber')
                    this.handleOk()
                }, (error) => {
                });
        } else {
            this.handleOk()
        }
    }

    handleOk = () => {
        let phnumber = '';
        let formListingCountry = 'in'
        if (this.state.formData && this.state.formData.UserFormFullData) {
            _.find(this.state.formData.UserFormFullData, (res, i) => {
                if ((res && (res.attributeType === "PhoneNumber"))) {
                    phnumber = res.attributeValue
                }
            });
        }
        const phoneNumber = parsePhoneNumberFromString(this.state.listingDetail.listing.phoneNumber)
        if (phoneNumber && phoneNumber.country) {
            formListingCountry = phoneNumber.country.toLowerCase();
        } else {
            formListingCountry = 'in'
        }
        localStorage.setItem('orderFormData', JSON.stringify(this.state.formData))
        // localStorage.setItem("")
        this.props.history.push({
            pathname: '/signin',
            state: {
                fromPublicUrl: true,
                url: this.props.location.pathname,
                type: (this.state.templateData && this.state.templateData.displayTemplateType && this.state.templateData.displayTemplateType === 'EcommerceForm') ? 'order' : 'contract',
                signatureFormData: (this.state.templateData && this.state.templateData.displayTemplateType && this.state.templateData.displayTemplateType === 'EcommerceForm') ? null : this.state.formData,
                defaultValues: { phonenumber: phnumber, code: formListingCountry }
            }
        })
    }

    handleCancel = () => {
        this.setState({
            loginModalOpen: false,
            loading: false,
        });
    }

    //when opening previus link
    onOpenPreviousLink = () => {
        let url = this.state.userPreviousShortUrl
        if (url) {
            window.open(`/${url}`)
        }

    }

    onStripePaymentClicked(formValues, id, type) {
        console.log(window.location.href, this.state.orderData, this.state.templateData.id, `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/stripeSessionCreate`, 'metadata')
        axios({
            'method': 'POST',
            url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/stripeSessionCreate`,
            // url: `http://localhost:5001/totely-prod1-ba624/us-central1/stripeSessionCreate`,
            headers: { 'Content-Type': 'application/json' },
            data: {
                cancel_url: window.location.href,
                orderData: this.state.orderData,
                templateId: this.state.templateData.id,
            }
        }).then((res) => {
            console.log('Push notification response', res)
            if (res && res.data && res.data.success && res.data.success.url) {
                window.location = res.data.success.url
            } else {
                this.setState({ loading: false })
                message.error('Payment process failed in then')
            }
        }).
            catch((err) => {
                console.log('Push notification err', err)
                this.setState({ loading: false })
                message.error('Payment process failed in catch')

            })
    }

    //on submit ecommerce form
    onSubmitEcommerce = async () => {
        let canProceed = true;
        let minimumOrderPrice = [];
        client.query({ query: GET_SHOP_ORDER_COUNT, variables: { templateId: this.state.templateData.id } })
            .then((res) => {
                console.log('GET_SHOP_ORDER_COUNT', res.data.transaction_template[0])
                if (res.data && res.data.transaction_template[0] && res.data.transaction_template[0].ordercount) {
                    let lastOrderCount = res.data.transaction_template[0].ordercount
                    console.log('lastOrderCount', lastOrderCount)
                    this.setState({ orderNumber: lastOrderCount + 1 })
                }



                this.setState({ loading: true }, async () => {
                    let fromData = {}
                    let uid = localStorage.getItem('uid')

                    //     //get customer form detail
                    await this.getFormData().then(res => {
                        fromData = res
                    })
                    if (fromData) {
                        if (fromData && fromData.DeliveryDetails && fromData.DeliveryDetails.minimumOrderPrice && fromData.DeliveryDetails.minimumOrderPrice.length > 0) {
                            let orderTotal
                            //exclude delivery fee to check min amt
                            // if (fromData.Total && fromData.Total.DeliveryFee) {
                            //     orderTotal = fromData.Total.TotalPriceBeforeTax - fromData.Total.DeliveryFee
                            // } else {
                            //     orderTotal = fromData.Total.TotalPriceBeforeTax 
                            // }
                            if (parseInt(fromData.DeliveryDetails.minimumOrderPrice[0]) <= fromData.Total.TotalPriceBeforeTax) {
                                canProceed = true;
                            } else {
                                canProceed = false;
                                minimumOrderPrice = fromData.DeliveryDetails.minimumOrderPrice;
                                this.setState({ minorderText: minimumOrderPrice[1] })
                            }
                        } else {
                            canProceed = true;
                        }
                        const isEmptyvalue = !Object.values(fromData).every(x => (x !== null && x !== ''));
                        if (canProceed === false) {
                            this.setState({ loading: false, minorderModal: true }, () => {

                            })
                        } else {
                            if (!isEmptyvalue) {
                                if (fromData.LineItems.length > 0) {
                                    //set order id 
                                    let data = cloneDeep(fromData)
                                    console.log(data, "submitvalues")
                                    // let id = Math.random().toString(36).substr(2, 6)
                                    let shopName = this.state.templateData && this.state.templateData.name ? this.state.templateData.name : ''
                                    let removeSpaceShopName = shopName.replace(" ", "")
                                    let shopNameUppercase = removeSpaceShopName.toUpperCase()
                                    let shopNameSplit = shopNameUppercase.length > 4 ? shopNameUppercase.slice(0, 4) : shopNameUppercase
                                    let generateNumber = `${shopNameSplit}-${this.state.orderNumber}`
                                    console.log('generateNumber', generateNumber)
                                    let id = generateNumber
                                    data.signedAt = String(new Date());
                                    data.orderId = id
                                    data.version = Config.version
                                    let line_items = []
                                    fromData.LineItems.map((items) => {
                                        console.log(items, 'heyitems')
                                        let priceUnit_lowered = this.state.templateData.templateSettings.priceUnit.toLowerCase();
                                        let price_unit = priceUnit_lowered.includes('rs') ? 'inr' : 'usd'
                                        let new_items = {
                                            price_data: {
                                                currency: price_unit,
                                                product_data: {
                                                    name: items.product_name
                                                },
                                                unit_amount: items.discountedUnitPrice ?
                                                    parseFloat(items.discountedUnitPrice) * 100 :
                                                    parseFloat(items.product_list_price) * 100
                                            },
                                            quantity: items.quantity
                                        }
                                        line_items.push(new_items)

                                    })
                                    if (fromData.Total.DeliveryFee) {
                                        let priceUnit_lowered = this.state.templateData.templateSettings.priceUnit.toLowerCase();
                                        let price_unit = priceUnit_lowered.includes('rs') ? 'inr' : 'usd'
                                        let new_items = {
                                            price_data: {
                                                currency: price_unit,
                                                product_data: {
                                                    name: "Delivery Fee"
                                                },
                                                unit_amount: fromData.Total.DeliveryFee * 100

                                            },
                                            quantity: 1
                                        }
                                        line_items.push(new_items)
                                    }
                                    this.setState({ formData: data })
                                    this.setState({
                                        orderData: {
                                            shopName,
                                            totalAmount: data.Total.TotalPriceAfterTax,
                                            priceUnit: this.state.templateData.templateSettings.priceUnit === "Rs" ? "inr" : "usd",
                                            line_items,
                                            idempotency_key: id
                                        }
                                    })
                                    console.log('afterorder', this.state.orderData)
                                    console.log('generateNumber', this.state.templateData)

                                    //if otp verification is enabled
                                    if (this.state.templateData && this.state.templateData.otpVerification) {
                                        console.log('generateNumber 1', this.state.templateData.otpVerification)
                                        this.setState({ loading: false }, () => {
                                            localStorage.setItem("orderdata", JSON.stringify(this.state.orderData))
                                            this.logout()
                                            // this.insertInTransaction(data, null, 'individual')
                                        })
                                    }  //if otp verification is not enabled
                                    else {
                                        // this.insertInTransaction(data, null, 'individual')
                                        console.log("checkphone")
                                        this.checkPhoneNumber(data, 'individual')
                                    }

                                    // this.setState({ loading: false }, () => {
                                    //     // this.logout()
                                    //     this.insertInTransaction(data, null, 'individual')
                                    // })
                                    // if user is already loggedin
                                    // if (uid) {
                                    //     this.checkContact(data)
                                    //     this.setState({ loginModalOpen: false })
                                    // }
                                    // // if user not loggedin
                                    // else {
                                    //     this.setState({ loginModalOpen: true, loading: false })
                                    // }
                                }
                                //if any field is empty in form
                                else {
                                    toast.error(this.locale("Please fill any products"));
                                    // message.warning("Please fill any products")
                                    this.setState({ loading: false })
                                }
                            } else {
                                toast.error(this.locale("Please fill required details and try again"));
                                // message.warning("Please fill required details and try again")
                                this.setState({ loading: false })
                            }
                        }
                    }
                });
            })
            .catch(err => {
                console.log('get count err', err)
            })
    }

    okPress() {
        // window.location.reload();
        this.getShortUrl(this.state.temptransactionId).then((short_url) => {
            if (short_url) {
                window.open(`/${short_url}`, '_self')
            }
        })
    }

    locale = (string) => {
        if (this.state.templateData &&
            this.state.templateData.templateSettings &&
            this.state.templateData.templateSettings.fieldValues &&
            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues &&
            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
            this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.DefaultValue === string)) {
            return this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.DefaultValue === string).AlternateValue
        } else {
            return string
        }
    }

    scrollToSection = (option) => {
        this.setState({ scrollViewSection: option })
    }
    getCartIconCount = (count) => {
        console.log("count123", count)
        this.setState({ cartIconCount: count })
        // return count
    }
    placeorder = (e) => {
        this.setState({ classactive: e })
    }

    checkOut = () => {
        console.log('checkOut', JSON.parse(localStorage.getItem('paylater')))
        this.onSubmitEcommerce()
    }

    onChangeCartModal = (res) => {
        console.log('onChangeCartModal', res)
        this.setState({ openRightModal: res })
    }


    render() {
        const { templateSettings, listingDetail, templateData } = this.state
        const productImageVisible = templateSettings && templateSettings.uploadProductImage ? true : false
        const headerImage = templateSettings &&
            templateSettings.fieldValues &&
            templateSettings.fieldValues.HeaderImage &&
            templateSettings.fieldValues.HeaderImage.ImageDetail[0] &&
            templateSettings.fieldValues.HeaderImage.ImageDetail[0].url ?
            templateSettings.fieldValues.HeaderImage.ImageDetail[0].url : null
        const listingImage = listingDetail && listingDetail.listing && listingDetail.listing.listing_img ? listingDetail.listing.listing_img : null
        const showImage = templateSettings &&
            templateSettings.fieldValues &&
            templateSettings.fieldValues.HeaderImage &&
            templateSettings.fieldValues.HeaderImage.showImage ?
            templateSettings.fieldValues.HeaderImage.showImage : false
        const imageUrl = showImage ? headerImage : listingImage
        let deeplinkurl = templateData && templateData.shorturlForDeeplink ? templateData.shorturlForDeeplink : ""
        return (
            <div style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                // paddingTop: 20
            }}>
                <Spin spinning={this.state.loading}>
                    {this.state.templateData &&
                        this.state.templateData.transactionTemplateStatus &&
                        this.state.templateData.transactionTemplateStatus === 'ACTIVE' ?
                        <CustomScrollbars className="gx-todo-detail-content-scroll pdf-scroll-view" id="pdf-custom-scrollbar" style={{ background: '#f5f5f5' }}>

                            {
                                this.state.isSumbitSuccess === false &&
                                <div>

                                    {/* <Button className="app-view-button" type="primary" onClick={() => deeplinkurl && window.open(deeplinkurl,
                                    "_self")}>
                                    View in app
                                                            </Button> */}
                                    <Card className={(this.state.templateData && this.state.templateData.displayTemplateType &&
                                        this.state.templateData.displayTemplateType === 'EcommerceForm') ? "Ecommerce-pdf-view" : "rena-pdf-view"} >
                                        {this.state.templateData && this.state.templateData.displayTemplateType && this.state.templateData.displayTemplateType !== 'EcommerceForm' &&
                                            <>
                                                < p id="divToPrint"
                                                    className="templetePdf"
                                                    style={{
                                                        margin: 'auto',
                                                        marginTop: '4%',
                                                        // whiteSpace:'nowrap',
                                                        // marginRight: '1%'
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: this.state.templateViewData
                                                    }} />
                                                <div className="submit-button-view" style={{
                                                    display: 'flex', justifyContent: 'center',
                                                }}>
                                                    <Popconfirm
                                                        title="Are you sure to submit?"
                                                        onConfirm={() => this.onSubmitFormFields()}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button type="primary" >Submit</Button>
                                                    </Popconfirm>
                                                </div>
                                            </>
                                        }
                                        {this.state.templateData.displayTemplateType === 'EcommerceForm' &&
                                            <>
                                                <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />

                                                <EcommerceForm
                                                    listingId={this.state.templateData ? this.state.templateData.listing[0].id : ''}
                                                    templateId={this.state.templateData ? this.state.templateData.id : ""}
                                                    templateData={this.state.templateData}
                                                    getCurrentPoint={getFormData => { this.getFormData = getFormData }}
                                                    showOrderFormHeader={true}
                                                    isloginModal={this.state.loginModalOpen}
                                                    formListingDetail={this.state.listingDetail}
                                                    productImageView={productImageVisible}
                                                    scrollViewSection={this.state.scrollViewSection}
                                                    getCartIconCount={this.getCartIconCount}
                                                    placeOrderdisable={this.placeorder}
                                                    openCartModal={this.state.openRightModal}
                                                    onChangeCartModal={this.onChangeCartModal}
                                                    checkOut={this.checkOut}
                                                />
                                                {/* <div class="ecommerce-button" style={{
                                                display: 'flex', justifyContent: 'center',
                                            }}>
                                                {(this.state.templateData && this.state.templateData.templateSettings &&
                                                    this.state.templateData.templateSettings.paymentType && this.state.templateData.templateSettings.paymentType === 'Stripe') ?

                                                    <Popconfirm
                                                        title={this.locale("Would you like to proceed with the order with payment?")}
                                                        onConfirm={() => {
                                                            this.setState({ paylater: false }, () => {
                                                                this.onSubmitEcommerce()
                                                            })
                                                        }}
                                                        okText={this.locale("Pay Now")}
                                                        cancelText={this.locale("Pay Later")}
                                                        onCancel={() => this.setState({ paylater: true }, () => {
                                                            this.onSubmitEcommerce()
                                                        })}
                                                    >
                                                        <Button type="primary" >
                                                            {(this.state.templateData && this.state.templateData.templateSettings &&
                                                                this.state.templateData.templateSettings.fieldValues &&
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues &&
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order') ?
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order').AlternateValue : 'Place Order')}
                                                        </Button>
                                                    </Popconfirm>
                                                    :
                                                    <Popconfirm
                                                        title={this.locale("Would you like to proceed with the order?")}
                                                        onConfirm={() => this.onSubmitEcommerce()}
                                                        okText={this.locale("Yes")}
                                                        cancelText={this.locale("No")}
                                                    >
                                                        <Button type="primary" >
                                                            {(this.state.templateData && this.state.templateData.templateSettings &&
                                                                this.state.templateData.templateSettings.fieldValues &&
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues &&
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.length > 0 &&
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order') ?
                                                                this.state.templateData.templateSettings.fieldValues.LocalizationDetailValues.find(item => item.FieldType === 'Place order').AlternateValue : 'Place Order')}
                                                        </Button>
                                                    </Popconfirm>

                                                }
                                            </div> */}
                                            </>
                                        }

                                        {this.state.templateData.displayTemplateType === 'EcommerceForm' &&
                                            // this.state.classactive === false &&
                                            this.state.cartIconCount > 0 &&
                                            <div class="ecommerce-button" style={{
                                                display: 'flex', justifyContent: 'center',
                                            }}>
                                                <Button type="primary" style={{ minWidth: 120 }} onClick={() => this.setState({ openRightModal: true })}>{`Place Order  (${this.state.cartIconCount})`}</Button>
                                            </div>
                                        }


                                        {/* submit button */}

                                        <div className="submission-view">
                                            {/* previous link view */}
                                            {
                                                this.state.userPreviousShortUrl &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <p><a
                                                        style={{
                                                            color: '#007bff',
                                                            textDecoration: 'underline'
                                                        }}
                                                        onClick={() => this.onOpenPreviousLink()}>{this.locale('Click here')}</a> {this.locale('to view your previous submission')}</p>
                                                </div>

                                            }
                                            {this.state.classactive === false &&
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <p style={{ fontSize: 10 }}>{`${this.locale('Powered by')} Totely ${Config.version}`}</p>
                                                </div>
                                            }
                                        </div>

                                        {/* <Layout id="public-footer-view">
                                        <Footer>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center', paddingLeft: 20, paddingRight: 20 }}>
                                                <Icon onClick={() => this.scrollToSection("homeSection")}
                                                    style={{ fontSize: 20, color: 'grey' }}
                                                    type="home" />
                                                <Icon onClick={() => this.scrollToSection("menuSection")}
                                                    style={{ fontSize: 20, color: 'grey' }}
                                                    type="menu" />
                                                <div>
                                                    <Badge style={{ cursor: "pointer" }}
                                                        count={this.state.cartIconCount} onClick={() => this.scrollToSection("cartSection")}>
                                                        <Icon onClick={() => this.scrollToSection("cartSection")}
                                                            style={{ fontSize: 20, color: 'grey' }}
                                                            type="shopping-cart" />
                                                    </Badge>

                                                </div>
                                            </div>
                                        </Footer>
                                    </Layout> */}

                                        <Modal
                                            title={this.locale("Please Login")}
                                            visible={this.state.loginModalOpen}
                                            onOk={() => this.logout()}
                                            okText={this.locale('Ok')}
                                            cancelText={this.locale('Cancel')}
                                            onCancel={() => this.setState({ loginModalOpen: false })}
                                        >
                                            {this.locale('Please confirm your phone number to submit your order')}
                                        </Modal>

                                        <Modal
                                            visible={this.state.minorderModal}
                                            onOk={() => this.setState({ minorderModal: false })}
                                            okText={this.locale('Ok')}
                                            cancelText={this.locale('Cancel')}
                                            onCancel={() => this.setState({ minorderModal: false })}
                                            closable={false}
                                        >
                                            {this.state.minorderText}
                                        </Modal>
                                    </Card>
                                </div>
                            }
                            {/* for ecommerce form */}
                            {
                                (this.state.isSumbitSuccess === true && this.state.templateData &&
                                    this.state.templateData.displayTemplateType === 'EcommerceForm') &&
                                <div className="submit-alert-view">
                                    {/* <Alert
                                    message="Thank You for ordering"
                                    // message="Thank You for ordering"
                                    type="success" showIcon
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                /> */}
                                    {/* <h5
                                    style={{ marginTop: '1%' }}
                                >Thank You for ordering</h5> */}
                                    {imageUrl &&
                                        <img
                                            // id="form-upl-profile"
                                            style={{ objectFit: 'contain', width: 400 }}
                                            src={imageUrl}
                                        />
                                    }
                                    <h5
                                        style={{ marginTop: '1%' }}>
                                        {
                                            this.state.userSubmissionMsg ?
                                                <div>
                                                    {this.state.userSubmissionMsg}
                                                </div> :
                                                <div>
                                                    {this.locale('Thank You for ordering'), this.locale('your order id is')} {this.state.ecommerceOrderid}
                                                </div>
                                        }
                                    </h5>
                                    <Button type="primary" onClick={() => { this.okPress() }}>{this.locale('View Order')}</Button>
                                </div>
                            }
                            {/* other than ecommercr form */}
                            {
                                (this.state.isSumbitSuccess === true && this.state.templateData &&
                                    this.state.templateData.displayTemplateType !== 'EcommerceForm') &&
                                <div className="submit-alert-view">
                                    <Alert
                                        message="Submitted Sccessfully"
                                        type="success" showIcon
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    />
                                </div>
                            }
                        </CustomScrollbars>
                        :
                        // <div>
                        //     {!this.state.loading &&
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100vh',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'red',
                                    fontSize: 20,
                                    fontWeight: "500"
                                }}>{!this.state.loading && 'Invalid link'}</div>
                        //     }
                        // </div>
                    }
                </Spin>
                {/* deeplink url view */}
                {this.state.showDeeplinkViewApp && deeplinkurl
                    && this.state.templateData.displayTemplateType === 'EcommerceForm' &&
                    <MobileView>
                        <div style={{
                            width: '100%',
                            height: 50,
                            backgroundColor: '#038fde',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            position: 'fixed',
                            // bottom: 0
                            top: 0,
                        }}>
                            <div style={{ width: '90%' }} onClick={() => deeplinkurl && window.open(deeplinkurl,
                                "_self")}>
                                <p style={{
                                    color: '#fff',
                                    fontSize: 18,
                                    marginBottom: 2,
                                    textAlign: "center"
                                }}>View in app</p>
                            </div>
                            <div>
                                <Icon type="close-circle"
                                    onClick={() => this.setState({ showDeeplinkViewApp: false })}
                                    style={{
                                        fontSize: '25px', color: '#fff', zIndex: 999
                                    }}
                                />
                            </div>
                        </div>
                    </MobileView>
                }

                <Modal
                    title={this.locale("Confirmation")}
                    visible={this.state.pendingPaymentModal}
                    footer={[
                        <Button key="back" onClick={() => this.pendingPaymentCancelCLicked()}>
                            Cancel
                        </Button>,
                        <Button
                            key="retry"
                            type="primary"
                            onClick={() => this.onStripePaymentClicked()}
                        >
                            Retry Payment
                        </Button>,
                        <Tooltip title="Proceed Without Payment">
                            <Button key="ok" type="primary" onClick={() => this.pendingPaymentOKCLicked()}>
                                Proceed
                            </Button>
                        </Tooltip>
                        ,


                    ]}
                >
                    {this.locale('Unable to process with payment. Do u want to create a order without payment? or Retry Payment?')}
                </Modal>


            </div>
        );
    }
};

export default withRouter(TransactionTemplateView);
