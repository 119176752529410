import React, { Component } from "react";
import { connect } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Subscription } from "react-apollo";
import { USER_WITH_ID_SUB } from '../../graphQl/subscriptions';
import { firebase } from '../../firebase/firebase';
import {withRouter} from 'react-router-dom'

class UserProfile extends Component {

  //on logout user
  onLogoutUser = () => {
    console.log('onLogoutUser123')
    //call firebase signout function
    firebase.auth().signOut()
      .then(async () => {
        // Sign-out successful.
        await localStorage.clear();
        window.sessionStorage.clear();
        window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
        this.props.history.push('/signin')

      }, (error) => {
        console.log('logout susccess', error)
      });
  }

  render() {
    let id = 1
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.history.push({ pathname: `/app/console/myAccount`})}>Settings</li>
        <li onClick={() => this.onLogoutUser()}>Logout
        </li>
      </ul>
    );

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Subscription subscription={USER_WITH_ID_SUB} variables={{ "id": localStorage.getItem('uid') }}>
          {({ loading, error, data }) => {
            let userName = ""
            if (error) return error.message
            if (data && data.user && data.user[0]) {
              let value = data.user[0]
              if (value.firstName) {
                userName = value.firstName
              }
              if (value.lastName) {
                userName = `${userName} ${value.lastName}`
              }
            }
            return <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
              <div style={{ display: 'flex' }}>
                <Avatar src='https://via.placeholder.com/150x150'
                  className="gx-size-40 gx-pointer gx-mr-3" alt="" />
                <div id="user-view">
                  <div className="gx-avatar-name" id="name-view">{userName} <i
                    className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></div>
                </div>
              </div>
            </Popover>
          }}
        </Subscription>
      </div>

    )

  }
}

export default connect(null, { userSignOut })(withRouter(UserProfile));
