import React, { Component } from 'react'
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

const Option = Select.Option;

export class DynamicFields extends Component {


    constructor(props) {
        super(props)
        this.state = {
            LoopItems: [],
            phoneValue: ''
        }
    }

    componentWillMount() {
        this.setState({ LoopItems: this.props.LoopItems });
        if (this.props.listingDetail &&
            this.props.listingDetail.phoneNumber && this.props.listingDetail.phoneNumber !== '') {
            let mobileNumber = this.props.listingDetail.phoneNumber;
            let countryCode = mobileNumber.substring(0, mobileNumber.length - 10);
            this.setState({ phoneValue: countryCode });
        }
    }

    componentWillReceiveProps() {
    }



    onRemoveRow = (index) => {
        let LoopItems = cloneDeep(this.state.LoopItems)
        LoopItems[index].isDelete = true
        this.setState({ LoopItems }, () => {
            this.props.form.validateFields(async (err, val) => {
                if (!err) {
                    this.props.LoopItemsChange(this.state.LoopItems, this.props.accessFrom);
                } else {
                    this.props.LoopItemsChange(null, this.props.accessFrom);
                }
            });
        });
    }


    onvaluesChange(index, fieldName, e) {
        if (fieldName === 'showAttributeName' || fieldName === 'required') {
            this.state.LoopItems[index][fieldName] = e.target.checked ? true : false;
        } else if (fieldName === 'attributeType') {
            if (e === 'PhoneNumber') {
                if (this.state.LoopItems[index]['attributeValue'] === '') {
                    this.state.LoopItems[index]['attributeValue'] =
                        this.state.phoneValue !== '' ? this.state.phoneValue :
                            '';
                }
            }
            this.state.LoopItems[index][fieldName] = e;
        } else if (fieldName === 'FieldType') {
            console.log(this.state.LoopItems[index], 'this.state.LoopItems[index]')
            this.state.LoopItems[index][fieldName] = e;
            // this.state.LoopItems[index]['DefaultValue'] = e;
        } else {
            this.state.LoopItems[index][fieldName] = e.target.value;
        }
        this.setState({ LoopItems: this.state.LoopItems }, () => {
            this.props.form.validateFields(async (err, val) => {
                if (!err) {
                    this.props.LoopItemsChange(this.state.LoopItems, this.props.accessFrom);
                } else {
                    this.props.LoopItemsChange(null, this.props.accessFrom);
                }
            })
        })
    }

    render() {
        const { LoopItems } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 24, offset: 4 },
            },
        };
        let first = true;
        return (
           
            <div>
                {LoopItems && LoopItems.length > 0 && LoopItems.map((k, index) => {
                    if (k && !k.isDelete) {
                        try {
                            return (
                                <div>
                                    {this.props.accessFrom !== 'Taxes' && this.props.accessFrom !== 'SellerNotification'
                                        && this.props.accessFrom !== 'Discounts' && this.props.accessFrom !== 'AdditionalFees' &&
                                        this.props.accessFrom !== 'Localization' &&
                                        <Form.Item
                                            {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                            required={false}
                                            key={k}

                                        >
                                            <Row span={24} className="attribute-row-view">
                                                <Col xs={12} sm={12} md={5} lg={5} id="attribute-view">
                                                    {first && <h6 className="status-rule-text" >Form Field Name</h6>}
                                                    {getFieldDecorator(`attributeName[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeName ? LoopItems[index].attributeName : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(

                                                        <Input className="atribute-name-input" onChange={(e) => this.onvaluesChange(index, 'attributeName', e)} />)}
                                                </Col>
                                                <Col xs={12} sm={12} md={5} lg={5} id="atribute-value-container">
                                                    {first && <h6 className="status-rule-text" id="atribute-value" style={{ width: 'max-content' }}>Form Field  Value</h6>}
                                                    {getFieldDecorator(`attributeValue[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeValue ? LoopItems[index].attributeValue !== '' ? LoopItems[index].attributeValue : '' : '',
                                                        rules: [
                                                            {
                                                                required: false,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input className="atribute-value-input" onChange={(e) => this.onvaluesChange(index, 'attributeValue', e)} />)}
                                                </Col>

                                                <Col xs={12} sm={12} md={5} lg={5} id="atribute-value-container">
                                                    {first && <h6 className="status-rule-text" >Form Field  Type</h6>}
                                                    {getFieldDecorator(`attributeType[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeType ? LoopItems[index].attributeType : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(

                                                        <Select className="atribute-type-input" placeholder="Rich Text Editor" value={LoopItems[index].attributeType} onChange={(e) => { this.onvaluesChange(index, 'attributeType', e) }}>
                                                            {this.props.accessFrom !== 'DiscountsPromotions' && this.props.accessFrom !== 'Document' &&
                                                                <Option value="Input">Text</Option>
                                                            }
                                                            {this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' && this.props.accessFrom !== 'Document' &&
                                                                <Option value="TextArea">Text Area</Option>
                                                            }
                                                            {this.props.accessFrom === 'Transaction' &&
                                                                <Option value="TrackingNumber">Tracking number</Option>
                                                            }
                                                             {this.props.accessFrom === 'Transaction' &&
                                                                <Option value="BusinessCode">Business Code</Option>
                                                            }
                                                             {this.props.accessFrom === 'Transaction' &&
                                                                <Option value="OCR">OCR</Option>
                                                            }
                                                            {this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' &&
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document' &&
                                                                <Option value="AddressField">Address Field</Option>
                                                            }
                                                            {this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' &&
                                                                this.props.accessFrom !== 'Transaction' &&
                                                                <Option value="FileUpload">FileUpload</Option>
                                                            }
                                                             {this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' &&
                                                                this.props.accessFrom !== 'Transaction' &&
                                                                <Option value="FileUpload_Extract_InvoiceData">FileUpload_Extract_InvoiceData</Option>
                                                            }
                                                             {this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' &&
                                                                this.props.accessFrom !== 'Transaction' &&
                                                                <Option value="FileUpload_Extract_FormData">FileUpload_Extract_FormData</Option>
                                                            }
                                                            {this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' &&
                                                                this.props.accessFrom !== 'Transaction' &&
                                                                <Option value="FileUpload_Extract_OCR">FileUpload_Extract_OCR</Option>
                                                            }
                                                            {(this.props.accessFrom !== 'Delivery' && this.props.accessFrom !== 'Pickup' &&
                                                                this.props.accessFrom !== 'Seating' && this.props.accessFrom !== 'Header' &&
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document' &&
                                                                this.props.accessFrom !== 'DiscountsPromotions') &&
                                                                <Option value="PhoneNumber">Phone Number</Option>
                                                            }
                                                            {(this.props.accessFrom !== 'Delivery' && this.props.accessFrom !== 'Pickup' &&
                                                                this.props.accessFrom !== 'Seating' && this.props.accessFrom !== 'Header' &&
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document' &&
                                                                this.props.accessFrom !== "Order" && this.props.accessFrom !== 'DiscountsPromotions') &&
                                                                <Option value="customerName">Customer Name</Option>
                                                            }
                                                            {(this.props.accessFrom !== 'Delivery' && this.props.accessFrom !== 'Pickup' &&
                                                                this.props.accessFrom !== 'Seating' && this.props.accessFrom !== 'Header' &&
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document' &&
                                                                this.props.accessFrom !== "Order" && this.props.accessFrom !== 'DiscountsPromotions') &&
                                                                <Option value="customerEmail">Customer Email</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' && 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') && 
                                                                <Option value="RequestDate">Request Date</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions'&& 
                                                                this.props.accessFrom !== 'Transaction'  && this.props.accessFrom !== 'Document') &&
                                                                <Option value="RequestTime">Request Time</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' && 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') &&
                                                                <Option value="deliveryFee">Delivery fee - by postal code</Option>
                                                            }
                                                            {((this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' || this.props.accessFrom === 'Transaction') && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions' && 
                                                                this.props.accessFrom !== 'Document') &&
                                                                <Option value="Option">Option</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions'&& 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') &&
                                                                <Option value="PaymentType">Payment type</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions'&& 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') &&
                                                                <Option value="MinOrderValue">Minimum order amount</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions'&& 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') &&
                                                                <Option value="Workinghours">Working Hours</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions'&& 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') &&
                                                                <Option value="Orderahead">Order ahead</Option>
                                                            }
                                                            {(this.props.accessFrom === 'Delivery' || this.props.accessFrom === 'Pickup' ||
                                                                this.props.accessFrom === 'Seating' && this.props.accessFrom !== 'Header' && this.props.accessFrom !== 'DiscountsPromotions'&& 
                                                                this.props.accessFrom !== 'Transaction' && this.props.accessFrom !== 'Document') &&
                                                                <Option value="OrderaheadStartDay">Order head start day</Option>
                                                            }
                                                            {this.props.accessFrom === 'Header' && 
                                                                <Option value="Name">Header Display</Option>
                                                            }
                                                            {this.props.accessFrom === 'DiscountsPromotions' &&
                                                                <Option value="DiscountCategorylevel">Discount - Category level</Option>
                                                            }
                                                            {this.props.accessFrom === 'DiscountsPromotions' &&
                                                                <Option value="DiscountItemlevel">Discount - Item level</Option>
                                                            }
                                                            {this.props.accessFrom === 'DiscountsPromotions' &&
                                                                <Option value="DiscountOrderlevel">Discount - Order level</Option>
                                                            }


                                                        </Select>
                                                    )}
                                                </Col>

                                                {this.props.accessFrom === 'Customer' && this.props.accessFrom !== 'Header' &&
                                                    <Col xs={12} sm={12} md={5} lg={3} id="Show-Attribute-Name">
                                                        {first && <h6 className="status-rule-text">Display Field</h6>}
                                                        {getFieldDecorator(`showAttributeName[${index}]`, {
                                                            initialValue: LoopItems[index] && LoopItems[index].showAttributeName ? LoopItems[index].showAttributeName : '',

                                                        })(
                                                            <Checkbox className="show-attribute-field" defaultChecked={false} checked={LoopItems[index].showAttributeName} onChange={(e) => this.onvaluesChange(index, 'showAttributeName', e)}></Checkbox>
                                                        )}
                                                    </Col>
                                                }
                                                {this.props.accessFrom !== 'Header' &&
                                                    <Col xs={4} sm={4} md={4} lg={2} id="required-field">
                                                        {first && <h6 className="status-rule-text" >Required</h6>}
                                                        {getFieldDecorator(`required[${index}]`, {
                                                            checked: LoopItems[index] && LoopItems[index].required ? LoopItems[index].required : '',
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: "Please input the field.",
                                                                },
                                                            ],
                                                        })(
                                                            <Checkbox className="required-field-checkbox" defaultChecked={false} checked={LoopItems[index].required} onChange={(e) => this.onvaluesChange(index, 'required', e)}></Checkbox>
                                                        )}
                                                    </Col>
                                                }
                                                <Col className="minus-icon-view" id="attribute-delete-icon" span={1} style={first && { marginTop: '26px' }}>
                                                    {/* {keys.length > 1 ? ( */}
                                                    <Icon
                                                        id="icon-view"
                                                        className="dynamic-delete-button"
                                                        type="delete"
                                                        onClick={() => this.onRemoveRow(index)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    {/* ) : null} */}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    }
                                    {this.props.accessFrom === 'Taxes' &&
                                        <Form.Item
                                            {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                            required={false}
                                            key={k}

                                        >
                                            <Row span={24}>
                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Tax Type</h6>}
                                                    {getFieldDecorator(`taxType[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].taxType ? LoopItems[index].taxType : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'taxType', e)} />)}
                                                </Col>

                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Tax Percentage</h6>}
                                                    {getFieldDecorator(`taxComputationType[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].taxComputationType ? LoopItems[index].taxComputationType : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'taxComputationType', e)} />)}
                                                </Col>
                                                <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                    {/* {keys.length > 1 ? ( */}
                                                    <Icon
                                                        id="icon-view"
                                                        className="dynamic-delete-button"
                                                        type="delete"
                                                        onClick={() => this.onRemoveRow(index)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    {/* ) : null} */}
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    }
                                    {this.props.accessFrom === 'SellerNotification' &&
                                        <Form.Item
                                            {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                            required={false}
                                            key={k}

                                        >
                                            <Row span={24}>
                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Phone Number</h6>}
                                                    {getFieldDecorator(`phoneNumber[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].phoneNumber ? LoopItems[index].phoneNumber : '',
                                                        rules: [
                                                            {
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'phoneNumber', e)} />)}
                                                </Col>

                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Email</h6>}
                                                    {getFieldDecorator(`email[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].email ? LoopItems[index].email : '',
                                                        rules: [
                                                            {
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'email', e)} />)}
                                                </Col>
                                                <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                    {/* {keys.length > 1 ? ( */}
                                                    <Icon
                                                        id="icon-view"
                                                        className="dynamic-delete-button"
                                                        type="delete"
                                                        onClick={() => this.onRemoveRow(index)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    {/* ) : null} */}
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    }
                                    {this.props.accessFrom === 'AdditionalFees' &&
                                        <Form.Item
                                            {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                            required={false}
                                            key={k}

                                        >
                                            <Row span={24}>
                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Description</h6>}
                                                    {getFieldDecorator(`attributeName[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeName ? LoopItems[index].attributeName : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'attributeName', e)} />)}
                                                </Col>

                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Amount</h6>}
                                                    {getFieldDecorator(`attributeValue[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeValue ? LoopItems[index].attributeValue : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'attributeValue', e)} />)}
                                                </Col>
                                                <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                    {/* {keys.length > 1 ? ( */}
                                                    <Icon
                                                        id="icon-view"
                                                        className="dynamic-delete-button"
                                                        type="delete"
                                                        onClick={() => this.onRemoveRow(index)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    {/* ) : null} */}
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    }
                                    {this.props.accessFrom === 'Discounts' &&
                                        <Form.Item
                                            {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                            required={false}
                                            key={k}

                                        >
                                            <Row span={24}>
                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Description</h6>}
                                                    {getFieldDecorator(`attributeName[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeName ? LoopItems[index].attributeName : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'attributeName', e)} />)}
                                                </Col>

                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Reduction Percentage</h6>}
                                                    {getFieldDecorator(`attributeValue[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].attributeValue ? LoopItems[index].attributeValue : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'attributeValue', e)} />)}
                                                </Col>
                                                <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                    {/* {keys.length > 1 ? ( */}
                                                    <Icon
                                                        id="icon-view"
                                                        className="dynamic-delete-button"
                                                        type="delete"
                                                        onClick={() => this.onRemoveRow(index)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    {/* ) : null} */}
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    }
                                    {this.props.accessFrom === 'Localization' &&
                                        <Form.Item
                                            {...(first ? formItemLayout : formItemLayoutWithOutLabel)}
                                            required={false}
                                            key={k}

                                        >
                                            <Row span={24}>
                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Default field name</h6>}
                                                    {getFieldDecorator(`DefaultValue[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].DefaultValue ? LoopItems[index].DefaultValue : '',
                                                        rules: [
                                                            {
                                                                required: false,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'DefaultValue', e)} />)}
                                                </Col>

                                                <Col xs={12} sm={12} md={5} lg={5}>
                                                    {first && <h6 className="status-rule-text" style={{ width: 'max-content' }}>Alternate field name</h6>}
                                                    {getFieldDecorator(`AlternateValue[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].AlternateValue ? LoopItems[index].AlternateValue : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(
                                                        <Input onChange={(e) => this.onvaluesChange(index, 'AlternateValue', e)} />)}
                                                </Col>
                                                <Col xs={12} sm={12} md={5} lg={5} id="atribute-value-container">
                                                    {first && <h6 className="status-rule-text" >Field  Type</h6>}
                                                    {getFieldDecorator(`FieldType[${index}]`, {
                                                        initialValue: LoopItems[index] && LoopItems[index].FieldType ? LoopItems[index].FieldType : '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Please input the field.",
                                                            },
                                                        ],
                                                    })(

                                                        <Select className="atribute-type-input" placeholder="Rich Text Editor" value={LoopItems[index].FieldType} onChange={(e) => { this.onvaluesChange(index, 'FieldType', e) }}>
                                                            <Option value="String">String</Option>
                                                            <Option value="Shop">Shop</Option>
                                                            <Option value="Search products">Search products</Option>
                                                            <Option value="All">All</Option>
                                                            <Option value="Customer details">Customer details</Option>
                                                            <Option value="Order details">Order details</Option>
                                                            <Option value="Payments details">Payments details</Option>
                                                            <Option value="Terms and conditions">Terms and conditions</Option>
                                                            <Option value="Delivery details">Delivery details</Option>
                                                            <Option value="Delivery">Delivery</Option>
                                                            <Option value="Seating">Seating</Option>
                                                            <Option value="Pick up">Pick up</Option>
                                                            <Option value="Place order">Place order</Option>
                                                        </Select>
                                                    )}
                                                </Col>
                                                <Col className="minus-icon-view" span={1} style={first && { marginTop: '26px' }}>
                                                    {/* {keys.length > 1 ? ( */}
                                                    <Icon
                                                        id="icon-view"
                                                        className="dynamic-delete-button"
                                                        type="delete"
                                                        onClick={() => this.onRemoveRow(index)}
                                                        style={{ fontSize: '16px' }}
                                                    />
                                                    {/* ) : null} */}
                                                </Col>

                                            </Row>
                                        </Form.Item>
                                    }
                                </div>
                            )
                        } finally {
                            first = false;

                        }
                    }
                })
                }
            </div>
        )
    }
}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(DynamicFields);
export default WrappedDynamicFieldSet

