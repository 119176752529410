import React, { Component } from "react";
import TransactionTemplateView from '../../components/TransactionTemplateView/index'

class TransactionTemplate extends Component {

    render() {
        return (
            <div>
                <TransactionTemplateView />
            </div>
        );
    }
};

export default TransactionTemplate;
