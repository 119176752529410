import React from "react";
import { Avatar, Modal, Input, Checkbox, message, Pagination, Button } from "antd";
import Moment from "moment";
import CustomScrollbars from "util/CustomScrollbars";
import moment from 'moment'

import labels from "routes/List/data/labels";
import users from "routes/List/data/users";
import Recipients from './Recipients'
import { CONTACT_LIST_BY_LISTING_ID, CONTACT_LIST_EMAIL, CONTACT_LIST_NUMBER } from '../../graphQl/subscriptions';
import { SEARCH_CONTACTS } from '../../graphQl/queries'
import { Subscription } from "react-apollo";
import * as _ from 'lodash'
import { cloneDeep } from 'lodash'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';

const { TextArea } = Input;
let noRestrictions = false;
const fetchOffset = 10
const defaultPageSize = 10
const { Search } = Input

let customerData = []

class AddUser extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            customersData: [],
            selectedContactsId: [],
            selectedContacts: [],
            isSelectAll: false,
            screen: '',
            currentpage: 1,
            paginatedCustomerdata: [],
            duplicateData: [],
            searchValue: '',
            searchFlag: false
        }
    }

    componentDidMount() {
        console.log('componentDidMount', this.props)
        this.setState({ screen: this.props.screenName }, () => {
            const { screen } = this.state
            //for msg screen
            if (screen === "messages") {
                this.setMessageData(this.props)
            }
            //for recipients screen
            if (screen === "recipients") {
                this.setRecipientsData(this.props)
            }
        })
    }

    setRecipientsData = (props) => {
        if (props.selectedContactsList.length === 0) {
            this.setState({
                customersData: props.listData,
            }, () => {
                this.asignData(this.state.customersData)
            })
        }
        //if already any data is selected
        else {
            this.setState({
                customersData: props.selectedContactsList,
                selectedContactsId: props.selectedContactsIdsData
            }, () => {
                this.asignData(this.state.customersData)
            })
        }

    }

    setMessageData = (props) => {

        if (props.selectedContactsList.length === 0) {
            const variables = {
                "startNumber": 0,
                "limit": 100,
                "listingId": localStorage.getItem('selectedListingId')
            };


            const gqlValue = props && props.messageType && props.messageType === 'sms' ?
                CONTACT_LIST_NUMBER : CONTACT_LIST_EMAIL
            const query = gql`
              ${gqlValue}
            `
            client
                .subscribe({
                    query,
                    variables: {
                        // "startNumber": 0,
                        // "limit": 100,
                        "listingId": localStorage.getItem('selectedListingId')
                    },
                })
                .subscribe(
                    res => {
                        console.log(res, 'resrsrr')
                        if (res && res.data) {
                            let list = []
                            res.data.contact.map((user, index) => {
                                let data = cloneDeep(user)
                                data.checkedValue = false
                                data.keyValue = index
                                list.push(data)
                            })
                            this.setState({ customersData: list, duplicateData: list }, () => {
                                this.asignData(this.state.customersData);
                            })
                        }
                    },
                    err => {
                    }
                )
        } else {
            this.setState({
                customersData: props.selectedContactsList,
                selectedContactsId: props.selectedContactsIdsData
            }, () => {
                this.asignData(this.state.customersData);
            })
        }
    }

    onSelectCustomer = (event, user, paginatedindex) => {
        let index = ((this.state.currentpage - 1) * 10) + paginatedindex;
        let customersData = cloneDeep(this.state.customersData)

        if (event.target.checked === true) {
            if (this.state.screen !== "messages" && localStorage.getItem('transactionType') === 'order') {
                if (this.state.selectedContactsId.length < 1) {
                    noRestrictions = true;
                } else {
                    noRestrictions = false;
                }
            } else {
                noRestrictions = true;
            }
            if (noRestrictions === true) {
                customersData[index].checkedValue = true;

                let data = this.state.selectedContactsId
                data.push(user.id)

                this.setState({ customersData, selectedContactsId: data }, () => {
                    this.asignData(this.state.customersData);
                })
            } else {
                message.warning('Add only one contact at a time')
            }
        } else if (event.target.checked === false) {
            customersData[index].checkedValue = false
            let ids = _.remove(this.state.selectedContactsId, function (n) {
                return n === user.id;
            });
            this.setState({ customersData }, () => { this.asignData(this.state.customersData) })
        }

    }

    onsubmit = () => {
        console.log('onsubmit', this.state.customersData, this.state.selectedContactsId)
        this.props.onAddCustomer(this.state.customersData, this.state.selectedContactsId)
    }

    onSelectAll = (event) => {
        if (this.state.screen !== "messages" && localStorage.getItem('transactionType') === 'order') {
            message.warning('Add only one contact at a time')
        } else {
            let value = false
            let list = []
            let selectedIds = []
            if (event.target.checked === true) {
                value = true
            } else if (event.target.checked === false) {
                value = false
            }
            this.state.customersData.map((user, index) => {
                let data = cloneDeep(user)
                data.checkedValue = value
                if (event.target.checked === true) {
                    selectedIds.push(user.id)
                }
                list.push(data)
            })
            this.setState({ customersData: list, selectedContactsId: selectedIds }, () => {
                this.asignData(this.state.customersData)
            })
        }
    }

    onPagePropsChange = (page, pageSize) => {
        console.log(page, pageSize, 'pgeseze');
        this.setState({ currentpage: page }, () => {
            this.asignData(this.state.customersData);
        });
    }

    asignData(val) {
        let startIndex = (this.state.currentpage - 1) * fetchOffset
        let endIndex = startIndex + fetchOffset
        let value = _.slice(val, startIndex, endIndex)
        this.setState({ paginatedCustomerdata: value }, () => {
            console.log(value, 'pgcdat1234')

        })
    }

    searchContacts = (value) => {
        console.log("searchContacts", value)
        this.setState({ currentpage: 1 }, () => {
            if (value) {
                let params = {
                    "p_value": value,
                    "p_listingid": localStorage.getItem('selectedListingId'),
                    "p_type": this.props && this.props.messageType && this.props.messageType === 'sms' ? "SMS" : "email"
                }
                console.log('params', params)
                client
                    .query({
                        query: SEARCH_CONTACTS,
                        variables: params
                    })
                    .then(res => {

                        if (res && res.data && res.data.search_contacts_by_type && res.data.search_contacts_by_type.length && res.data.search_contacts_by_type.length > 0) {
                            console.log('searchContacts res', res.data.search_contacts_by_type)
                            let list = []
                            res.data.search_contacts_by_type.map((user, index) => {
                                let data = cloneDeep(user)
                                data.checkedValue = false
                                data.keyValue = index
                                list.push(data)
                            })
                            console.log('list', list)
                            this.setState({ customersData: list }, () => {
                                this.asignData(this.state.customersData);
                            })
                        } else {
                            this.setState({ paginatedCustomerdata: [] })
                        }
                    })
                    .catch(err => {
                        console.log('searchContacts err', err)
                    })
            } else {
                this.clearSearch()
            }
        })
    }

    clearSearch = () => {
        console.log('clearSearch')
        this.setState({ customersData: this.state.duplicateData }, () => {
            this.asignData(this.state.customersData);
        })
    }

    render() {
        const { customersData, screen, currentpage, paginatedCustomerdata, searchFlag, searchValue } = this.state
        console.log(paginatedCustomerdata, 'pgcdat')
        return (
            <Modal
                title="Add Recipients"
                visible={this.props.isOpen}
                closable={true}
                onCancel={() => this.props.onCloseModal()}
                onOk={() => this.onsubmit()}
            >

                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {/* <Search
                                placeholder="Search"
                                onSearch={value => this.searchContacts(value)}
                                style={{ width: 200, marginRight: 5 }}
                                allowClear={true}
                            // enterButton
                            /> */}
                            {/* {searchFlag &&
                                <Button type="primary" onClick={() => this.clearSearch()}>Clear</Button>
                            } */}
                        </div>
                        <Checkbox
                            style={{
                                marginTop: 5,
                                fontSize: 14
                            }}
                            onChange={(event) => this.onSelectAll(event)}
                        >
                            Select All
                                </Checkbox>
                    </div>
                    {
                        paginatedCustomerdata &&
                        paginatedCustomerdata.map((user, index) => {
                            let userName = ""
                            if (user.firstName) {
                                userName = user.firstName
                            }
                            if (user.lastName) {
                                userName = `${userName} ${user.lastName}`
                            }
                            return (
                                <div className='gx-chat-user-item'>
                                    <div className="gx-chat-user-row">
                                        <div className="gx-chat-avatar">
                                            <div className="gx-status-pos">
                                                <Avatar src="https://via.placeholder.com/150x150" className="gx-size-40" alt="https://via.placeholder.com/150x150" />
                                                <span className={`gx-status gx-small gx-active`} />
                                            </div>
                                        </div>

                                        <div className="gx-chat-info">
                                            <span className="gx-name h6">{userName}</span>
                                            {
                                                screen === "messages" &&
                                                <div>
                                                    {this.props && this.props.messageType && this.props.messageType === 'sms' ?
                                                        <div className="gx-chat-info-des gx-text-truncate">{user.phoneNumber}</div>
                                                        :
                                                        <div className="gx-chat-info-des gx-text-truncate">{user.email}</div>
                                                    }
                                                </div>
                                            }

                                            {
                                                screen === "recipients" &&
                                                <div>
                                                    {user.email &&
                                                        <div className="gx-chat-info-des gx-text-truncate">{user.email}</div>
                                                    }
                                                    {user.phoneNumber &&
                                                        <div className="gx-chat-info-des gx-text-truncate">{user.phoneNumber}</div>
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <Checkbox
                                            checked={user.checkedValue}
                                            onChange={(event) =>
                                                this.onSelectCustomer(event, user, index)}>
                                        </Checkbox>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* {totalCount > 10 && */}
                    <div id="pagination-modal-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
                        <Pagination
                            showQuickJumper
                            current={currentpage}
                            total={customersData.length}
                            defaultPageSize={defaultPageSize}
                            onChange={(page, pageSize) => this.onPagePropsChange(page, pageSize)}
                        />
                    </div>
                    {/* } */}
                </div>
            </Modal>
        );
    }
}

export default AddUser;
