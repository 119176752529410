import React, { Component } from "react";
import PDFVIew from '../../routes/NewTemplate/index'
import { withRouter } from 'react-router-dom'
import { GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID, CONTACT_WITH_NUMBER_SUB } from '../../graphQl/subscriptions'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';

import { storage } from '../../firebase/firebase';

import JsonFormBuilder from '../JsonFormBuilder/index';
import Form from "react-jsonschema-form";
import { Button, message, Spin, Icon, Modal, Row, Col, Checkbox, Collapse, List, Input, Upload } from 'antd'
import ReactDOM from 'react-dom';
import axios from 'axios';
import { CONFIG } from "config/config";
import { getENVConfig } from '../../util/common'
import RecipientsForTransaction from './Recipients'
import EcommerceForm from "components/EcommerceForm";

const uuidv1 = require('uuid/v1');
const { Panel } = Collapse;

let yourForm;

class VariableFiledForTransaction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            templateData: {},
            showLoading: false,
            templateId: '',
            formDataValues: {},
            confirmSearchValue: '',
            finalContacts: [],
            selectedType: '',
            uploadedFile: {},
            attachedUrl: {}
        }
        this.state.attachedUrl.attachedUrl = [];
    }

    componentDidMount() {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
            this.setState({ templateId: this.props.match.params.id }, () => {

                const variables = { "id": this.props.match.params.id };

                client
                    .subscribe({
                        query: GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID,
                        variables: variables
                    })
                    .subscribe(
                        res => {
                            if (res && res.data && res.data.transaction_template.length > 0) {
                                let data = res.data.transaction_template[0]
                                this.setState({ templateData: data })
                            }
                        },
                        err => {
                            console.log('ress on  errr', err);
                        }
                    );
            })
        }
    }


    onContactsAdded(data, type) {
        this.setState({ finalContacts: data, selectedType: type }, () => {
            this.props.contactsAdded(this.state.finalContacts)
        });
    }


    getDetails() {
        return new Promise((resolve, reject) => {

            let GET_DEFAULTUSERDATA_QUERY = `query transaction($id: uuid!) {
            contact(where: {id: {_eq: $id}}) {
            email
            phoneNumber
            }
          }`;

            const query = gql`
          ${GET_DEFAULTUSERDATA_QUERY}
        `
            let hi = [];
            console.log(this.state.finalContacts, 'this.state.finalContacts')
            if (this.state.finalContacts.length > 0) {

                this.state.finalContacts.map(async (data, index) => {
                    let dat = client
                        .query({ query: query, variables: { id: data.defaultUserContactId } })
                        .then((_res) => {
                            this.state.finalContacts[index].FirstLastName = _res.data.contact[0].firstName + ' ' + _res.data.contact[0].lastName;
                            this.state.finalContacts[index].defaultUserContactMail = _res.data.contact[0].email;
                            this.state.finalContacts[index].defaultUserContactPhone = _res.data.contact[0].phoneNumber;
                            hi.push(this.state.finalContacts)
                            if (hi.length === this.state.finalContacts.length) {
                                resolve(this.state.finalContacts)
                            }
                        }).catch((err) => {

                        })
                });
            } else {
                this.setState({ showLoading: false })
                message.error('Please select recipients')

            }
        });
    }

    callForInsertTransaction = (commonFormData) => {
        if (this.state.uploadedFile.name) {
            const fileUrl = `OrderFiles/${this.state.uploadedFile.name}`;

            const uploadTask = storage.ref(fileUrl).put(this.state.uploadedFile);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    // progress function
                },
                error => {
                    this.setState({ showLoading: true })
                    //error function
                },
                async (uploaded) => {
                    await storage
                        .ref('OrderFiles')
                        .child(this.state.uploadedFile.name)
                        .getDownloadURL()
                        .then(async (uploadUrl) => {
                            this.state.attachedUrl.attachedUrl.push(uploadUrl);
                            let trans_template = {
                                "lead_object": {
                                    'transactionTemplateId': this.state.templateId,
                                    'id': uuidv1(),
                                    'listingId': localStorage.getItem("selectedListingId"),
                                    'createdBy': localStorage.getItem("uid"),
                                    'formData': commonFormData,
                                    'source': 'Internal',
                                    'type': localStorage.getItem('transactionType'),
                                    "attachmentUrls": this.state.attachedUrl
                                }
                            };
                            this.createTransactionFlow(trans_template, commonFormData)
                        });
                });
        } else {
            let trans_template = {
                "lead_object": {
                    'transactionTemplateId': this.state.templateId,
                    'id': uuidv1(),
                    'listingId': localStorage.getItem("selectedListingId"),
                    'createdBy': localStorage.getItem("uid"),
                    'formData': commonFormData,
                    'source': 'Internal',
                    'type': localStorage.getItem('transactionType'),
                    "attachmentUrls": this.state.attachedUrl
                }
            };
            this.createTransactionFlow(trans_template, commonFormData)
        }
    }

    async onSubmit({ formData }, e) {
        let ecommformData = {};
        if (this.state.templateData && this.state.templateData.eligibleStatusList) {

            this.setState({ showLoading: true }, async () => {
                if (formData === 'Ecommerce') {
                    console.log(this.props.ecomSubmitted(), 'this.props.ecomSubmitted()');
                    await this.props.ecomSubmitted() && this.props.ecomSubmitted().then(res => {
                        console.log(res, 'filledval')
                        // ecommValues = res

                        if (res) {
                            const isEmptyvalue = !Object.values(res).every(x => (x !== null && x !== ''));
                            if (!isEmptyvalue) {
                                if (res &&
                                    res.LineItems.length > 0) {
                                    ecommformData = res
                                    console.log(ecommformData, res, 'ecomformdataa');
                                    if (this.state.selectedType === 'bussiness') {
                                        this.getDetails().then((finalContacts) => {
                                            console.log(finalContacts, res, 'finalcontactres');
                                        });
                                    } else {
                                        this.callForInsertTransaction(res);
                                    }
                                } else {
                                    message.warning("Please fill any products")
                                    this.setState({ showLoading: false });
                                    return;
                                }
                            } else {
                                message.warning("Please fill required details and try again")
                                this.setState({ showLoading: false });
                                return;
                            }
                        } else {
                            message.warning("Please fill required details and try again")
                            this.setState({ showLoading: false });
                            return;
                        }
                    })
                } else {
                    console.log(formData === 'Ecommerce' ? ecommformData : formData, 'ecomformdataa');
                    this.callForInsertTransaction(formData);
                }
            });

        } else {
            message.warning('Please add rules for this template')
        }
    }

    createTransactionFlow(trans_template, formData) {
        var listingName = localStorage.getItem('selectedListingName');
        var tabName = localStorage.getItem('transactionType');
        const temp = {
            id: this.state.templateId,
            name: this.props.templateName
        }
        let finalContacts = [];
        if (this.state.selectedType === 'bussiness') {
            this.getDetails().then((finalContacts) => {
                if (finalContacts.length > 0) {

                    trans_template = {
                        'trans_template': trans_template,
                        'contacts': { contacts: finalContacts, type: this.state.selectedType }
                    };
                    this.setState({ SnackbarOpen: true, form_schema: {}, status: '', schema: false, formId: null }, () => { });

                    axios({
                        'method': 'POST',
                        url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/internalTransaction`,
                        // url: 'http://localhost:5001/totely-dev1/us-central1/internalTransaction',
                        headers: { 'Content-Type': 'application/json' },
                        'data': trans_template
                    }).then((response) => {
                        this.setState({ showLoading: false, formDataValues: {} })
                        message.success('Transaction(s) Created Sucessfully')
                        this.props.history.push({
                            pathname: `/app/console/${listingName}/transactions/${temp.id}`,
                            state: { fromtrans: 'Contract', tabName: tabName, templatedata: temp }
                        });

                        if (this.props.getFormData) {
                            this.props.getFormData(formData)
                        }
                    }).catch(err => {
                        this.setState({ showLoading: false })
                        message.error(err.message)
                    })
                } else {
                    message.error('Please select recipients')
                    this.setState({ showLoading: false })
                }
            });
        } else if (this.state.selectedType === 'individual') {
            finalContacts = this.state.finalContacts;
            if (finalContacts.length > 0) {
                trans_template = {
                    'trans_template': trans_template,
                    'contacts': { contacts: finalContacts, type: this.state.selectedType }
                };
                this.setState({ SnackbarOpen: true, form_schema: {}, status: '', schema: false, formId: null }, () => { });
                axios({
                    'method': 'POST',
                    url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/internalTransaction`,
                    headers: { 'Content-Type': 'application/json' },
                    'data': trans_template
                }).then((response) => {
                    this.setState({ showLoading: false, formDataValues: {} })
                    message.success('Transaction(s) Created Sucessfully')
                    this.props.history.push({
                        pathname: `/app/console/${listingName}/transactions/${temp.id}`,
                        state: { fromtrans: 'Contract', tabName: tabName, templatedata: temp }
                    });

                    if (this.props.getFormData) {
                        this.props.getFormData(formData)
                    }
                }).catch(err => {
                    this.setState({ showLoading: false })
                    message.error(err.message)
                })
            } else {
                message.error('Please select recipients')
                this.setState({ showLoading: false })

            }
        } else {
            message.error('Please select recipients')
            this.setState({ showLoading: false })
        }
    }
    onChangeValues = (formData, propsValue) => {
        this.setState({ formDataValues: formData.formData }, () => {
            if (propsValue && propsValue.gettingFormData) {
                propsValue.gettingFormData(this.state.formDataValues)
            }
        })
    }

    addMember(e) {
        if (e === 'individual') { this.setState({ isOpen: true }) }
        else { this.setState({ isOpen: true }) }
    }

    onSelectionSubmit() {
        const res = this.state.contactData.filter(f => f.checked === true);
        this.setState({ induviduals: res, isOpen: false });
    }

    onRemove() {
        this.setState({ uploadedFile: {} });
    }

    handleChange = (event) => {
        const file = event.fileList[0] && event.fileList[0].originFileObj && event.fileList[0].originFileObj;
        if (file) {
            this.setState({ uploadedFile: file }, () => {
            })
        }
    }


    render() {
        const { templateData } = this.state
        let detail = {};
        return (
            <div >
                <Spin spinning={this.state.showLoading} >

                    {
                        Object.keys(templateData).length > 0 &&
                        <div id="varialble-form-container">

                            <div>
                                {/* if order template has attachedUrl */}
                                {
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        accept=".pdf"
                                        onChange={this.handleChange}
                                        onRemove={this.onRemove.bind(this)}
                                    >
                                        {this.state.uploadedFile.name ? null :
                                            <div>
                                                <Icon id="icon-view" type="plus" />
                                                <div className="ant-upload-text">Upload</div>
                                            </div>}
                                    </Upload>
                                }
                            </div>

                            {/* recipients view */}
                            <RecipientsForTransaction onContactsAdded={this.onContactsAdded.bind(this)} />
                            {/* form */}
                            {templateData.displayTemplateType === 'EcommerceForm' ?
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Button type="primary" htmlType="submit" key="submit"
                                            onClick={() => this.onSubmit({ formData: 'Ecommerce' })}
                                        >
                                            Submit
                                         </Button>
                                    </div>
                                </>
                                :
                                <>
                                    <Form
                                        id='variabl-form-view'
                                        schema={templateData.formDefinition.JSONSchema}
                                        formData={this.state.formDataValues}
                                        uiSchema={templateData.formDefinition.UISchema}
                                        onSubmit={this.onSubmit.bind(this)}
                                        liveValidate
                                        children={<br />}
                                        onChange={(data) => this.onChangeValues(data, this.props)}
                                        ref={(form) => { yourForm = form; }}
                                    >
                                    </Form>

                                    {/* submit button */}
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Button type="primary" htmlType="submit" key="submit"
                                            onClick={() => yourForm.submit()}
                                        >
                                            Submit
                                </Button>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </Spin>
            </div>
        )
    }

};

export default (withRouter(VariableFiledForTransaction));
