import React from "react";
import _ from "lodash";
import { cloneDeep } from 'lodash'

import { message } from "antd";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import FirebaseUIAuth from "react-firebaseui-localized";

import { firebase, uiConfig, auth } from '../../firebase/firebase';
import { userPhoneSignIn } from '../../appRedux/actions/Auth';
import client from '../../apollo/apollo';
import { USERID_TO_GET_LISTING as query } from '../../graphQl/queries';
import { UPDATE_MAPPING_USERID, ADD_MEMBER_TO_LISTING, UPDATE_LISTING_CREATEDBY } from '../../graphQl/mutations'
import {
  USER_WITH_UID,
  CHECK_PHONENUMBER_WITH_CONTACT,
  CHECK_BUSINESS_CONTACT_WITH_LISTING
} from '../../graphQl/queries';

const uuidv3 = require('uuid/v3');
let checkValue = 0

class SignIn extends React.Component {

  componentDidMount() {

    let uid = localStorage.getItem('uid')
    //if user is already loggedin
    if (uid) {
      this.props.history.push('/app/console/listings')

    } else {
      //when verify phone number
      auth.onAuthStateChanged(async user => {

        if (user) {
          const { phoneNumber, uid } = user;

          //generate uuid from firebase auth user id
          const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
          const uuid = uuidv3(uid, MY_NAMESPACE);

          // save phoneNumber and uuid into localStorage
          localStorage.setItem('phoneNumber', phoneNumber);


          this.props.userPhoneSignIn(uid);


          //check whether user is exist in user table 
          const variablesData = { "uid": uuid };

          const response = client.query({ query: USER_WITH_UID, variables: variablesData }).then((res) => {
            if (res && res.data) {
              //if user stored in user table(already registered)
              if (res.data.user.length > 0) {

                localStorage.setItem('uid', uuid);

                // get user listings based on uid
                const variables = { "userId": uuid };
                const response = client.query({ query: query, variables: variables }).then((res) => {
                  console.log(this.props, res, 'resronlogin')
                  if (this.props && this.props.location && this.props.location.state && this.props.location.state.value) {
                    this.props.history.push(`/${this.props.location.state.value}`)
                  }
                  else if (this.props && this.props.location && this.props.location.state && this.props.location.state.fromPublicUrl) {
                    if (this.props.location.state.type === "order") {
                      var orderFormData = localStorage.getItem('orderFormData')
                      orderFormData = JSON.parse(orderFormData)
                      let data = cloneDeep(orderFormData)
                      data.loggedinPhoneNumber = phoneNumber
                      this.props.history.push({
                        pathname: `${this.props.location.state.url}`,
                        state: { loggedinForPublicurl: data }
                      })
                    } else {
                      this.props.history.push({
                        pathname: `${this.props.location.state.url}`,
                        state: { signatureFormData: this.props.location.state.signatureFormData }
                      })
                    }
                  }
                  else if (res && res.data && res.data.listing_team && res.data.listing_team.length > 0) {
                    const filterActiveListings = _.filter(res.data.listing_team, (o) => {
                      if (o.listing.status === 'ACTIVE') {
                        return o
                      }
                    })

                    const firstListing = filterActiveListings[0] && filterActiveListings[0].listing && filterActiveListings[0].listing.listing_urls[0] && filterActiveListings[0].listing.listing_urls[0].urlName ? filterActiveListings[0].listing.listing_urls[0].urlName : 'listings'
                    this.props.history.push(`/app/console/${firstListing}`)
                    if (filterActiveListings[0].listing && filterActiveListings[0].listing.businessContact[0] && filterActiveListings[0].listing.businessContact[0].id) {
                      localStorage.setItem('listingBusinessContactId', filterActiveListings[0].listing.businessContact[0].id)
                    } else {
                      localStorage.setItem('listingBusinessContactId', '')
                    }
                    localStorage.setItem('selectedListingId', filterActiveListings[0].listing.listing_urls[0].listingId)
                    localStorage.setItem('selectedListingName', filterActiveListings[0].listing.listing_urls[0].urlName)
                    localStorage.setItem('role', filterActiveListings[0].role)
                    localStorage.setItem('listinglogo', filterActiveListings[0].listing.listing_img)
                    localStorage.setItem('logoListingName', filterActiveListings[0].listing.name)
                  }
                }).catch((err) => {
                  console.log('err123 listing query', err)
                });
              }
              //if user not stored in user table(not registered)
              else {
                //For updating the mappingUserId in contact table and add listing admin for new users
                if (checkValue === 0) {
                  checkValue++
                  this.chekVerifiedPhoneNumber()
                }
                console.log(this.props.location, 'this.props.location')
                if (this.props && this.props.location && this.props.location.state && this.props.location.state.value) {
                  // localStorage.removeItem('uid');
                  this.props.history.push({
                    pathname: `/${this.props.location.state.value}`,
                    state: { value: 'temp' }
                  })
                } else if (this.props && this.props.location && this.props.location.state && this.props.location.state.fromPublicUrl) {
                  console.log('signin', this.props)
                  var orderFormData = localStorage.getItem('orderFormData')
                  orderFormData = JSON.parse(orderFormData)
                  let data = cloneDeep(orderFormData)
                  data.loggedinPhoneNumber = phoneNumber
                  console.log('data', data)
                  this.props.history.push({
                    pathname: `${this.props.location.state.url}`,
                    state: { loggedinForPublicurl: data }
                  })
                } else {
                  this.props.history.push({ pathname: '/signup' });
                }

              }
            }
          }).catch((err) => {
            console.log('err123 uid query', err)
          })

          const metadataRef = firebase.database().ref('metadata/' + user.uid);

          metadataRef.set({
            refreshTime: new Date().getTime(),
            phoneNumber,
            uid,
          });
        }
      });
    }
  }

  componentWillMount() {
    console.log(this.props.location.state, 'this.props.location.state')

    if (this.props && this.props.location && this.props.location.state && this.props.location.state.fromPublicUrl) {
      this.configUpdated = uiConfig;
      this.configUpdated.signInOptions[0].defaultNationalNumber = this.props.location.state.defaultValues.phonenumber;
      this.defaultCountry = this.props.location.state.defaultValues.code;
      if (this.props.location.state.defaultValues.code) {
        this.configUpdated.signInOptions[0].defaultCountry = this.props.location.state.defaultValues.code.toUpperCase();
      } else {
        this.configUpdated.signInOptions[0].defaultCountry = 'IN';
      }
    } else if (this.props && this.props.location && this.props.location.state && this.props.location.state.fromEcommerce) {
      this.configUpdated = uiConfig;
      this.defaultCountry = this.props.location.state.defaultValues.code;
      if (this.props.location.state.defaultValues.code) {
        this.configUpdated.signInOptions[0].defaultCountry = this.props.location.state.defaultValues.code.toUpperCase();
      } else {
        this.configUpdated.signInOptions[0].defaultCountry = 'IN';
      }
    } else {
      this.configUpdated = uiConfig;
      this.configUpdated.signInOptions[0].defaultCountry = 'IN';
    }
    console.log(this.configUpdated, 'configupdat')
  }


  //For checking the phone number exists in conatact
  chekVerifiedPhoneNumber = () => {
    console.log('chekVerifiedPhoneNumber', localStorage.getItem('phoneNumber'), localStorage.getItem('uid'))
    client
      .query({
        query: CHECK_PHONENUMBER_WITH_CONTACT,
        variables: {
          phoneNumber: localStorage.getItem('phoneNumber')
        }
      })
      .then(val => {
        console.log('val', val)
        if (val && val.data && val.data.contact && val.data.contact[0] && val.data.contact[0].id) {
          // val.data.contact.map(res => {
          //     console.log('res', res.id)
          //     if (res && res.id) {
          this.updateUid(val.data.contact[0].id)
          this.checkBusinessContact(val.data.contact[0].id)
          //     }
          // })
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  //Update the mappingUserId with uid
  updateUid = (id) => {
    client.mutate({
      mutation: UPDATE_MAPPING_USERID,
      variables: {
        id: id,
        mappingUserId: localStorage.getItem('uid')
      }
    }).then((response) => {
      console.log('response', response)
    })
      .catch(err => {
        console.log('err', err)
      })
  }

  //Checking contactId with business contact defaultUserContactId and getting the listing id
  checkBusinessContact = (id) => {
    client
      .query({
        query: CHECK_BUSINESS_CONTACT_WITH_LISTING,
        variables: {
          defaultUserContactId: id
        }
      })
      .then(res => {
        console.log('wwww', res)
        if (res.data && res.data.business_contact && res.data.business_contact[0] && res.data.business_contact.length > 0) {
          const listings = res.data.business_contact
          listings.map(ref => {
            console.log('listingIds', ref.mappedListingId)
            this.addAdminMember(ref.mappedListingId)
            this.updateListingCreated(ref.mappedListingId)
          })
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  updateListingCreated = (listingId) => {
    const payload = {
      id: listingId,
      createdBy: localStorage.getItem('uid')
    }
    client.mutate({ variables: payload, mutation: UPDATE_LISTING_CREATEDBY }).then((result) => {
      console.log('updateListingCreated result', result)
    })
      .catch(err => {
        console.log('updateListingCreated', err)
      })
  }

  //Adding this user as a listing member
  addAdminMember = (listingId) => {
    const addMember = {
      "listingId": listingId,
      "role": "listingAdmin",
      "userId": localStorage.getItem('uid')
    };
    client.mutate({ variables: addMember, mutation: ADD_MEMBER_TO_LISTING }).then((result) => {
      console.log('addListingAdmin', result)
    })
      .catch(err => {
        console.log('add listing admin error', err)
      })
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props;
    console.log((this.props && this.props.location && this.props.location.state && (this.props.location.state.fromPublicUrl || this.props.location.state.fromEcommerce)), this.props, 'fireaut')
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-wid"> </div>
            {!this.defaultCountry &&


              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                  <img src="https://cdn.pixabay.com/photo/2015/06/19/21/24/the-road-815297_1280.jpg" alt='Neature' />
                </div>
                <div style={{ height: '35%' }}>
                  <p style={{ fontSize: 17, fontWeight: 'bold', marginBottom: 20, }}> Enabling Digital Commerce</p>
                </div>
                {/* <div className="gx-app-logo-wid">
                <h5 style={{color:'#fff'}}><IntlMessages id="app.userAuth.signIn" /></h5>
                <div>
               <p style={{fontWeight: '400',color:'#fff'}}>By Signing Up,You can avail full features of our services.</p>
              </div>
                <p><IntlMessages id="app.userAuth.getAccount" /></p>
              </div> */}

                <div style={{ height: '40%' }}>
                  <p style={{ fontWeight: '350', fontSize: 13 }}>Contracts, Orders, Invoices, Leads and Channel Management.</p>
                </div>
                <div>
                  <p style={{ fontWeight: '400', }}> Create an account to get started.</p>
                </div>
                <div className="gx-app-logo" id="sigin-left-bar">
                  <img alt="example" src={require("assets/images/logo.png")} />
                  <span style={{ fontSize: 24, fontWeight: 'bold', marginLeft: 5 }}>Totely</span>
                </div>
              </div>
            }
            <div className="gx-app-login-content">
              {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /> */}
              <FirebaseUIAuth
                lang={this.defaultCountry === 'es' ? 'es' : 'en'}
                config={this.configUpdated}
                auth={auth}
                firebase={firebase}
              />
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};
const mapDispatchToProps = dispatch => ({
  userPhoneSignIn: payload => dispatch(userPhoneSignIn(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);


