import React, { Component } from "react";
import { GET_COMMUNICATION_PROVIDER } from '../../graphQl/subscriptions';
import { ADD_COMMUNICATION_PROVIDER, UPDATE_COMMUNICATION_PROVIDER } from '../../graphQl/mutations';
import client from '../../apollo/apollo'
import { Collapse, Icon, Select, Input, Button, message, Card, Tooltip } from 'antd';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash'

const { Panel } = Collapse;
const { Option } = Select

const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 4,
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
};

const addStyle = {
    // display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
}

let emailId = 'email'
let smsId = "sms"

let initialData = {
    id: 0,
    value: null
}

class AddCommunicationProvider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            providerList: [],
            smsProviderId: '',
            emailProviderId: '',
            emailPromotionalList: [],
            emailTransactionalList: [],
            smsPromotionalList: [],
            smsTransactionalList: [],
            isCreate: false,
            smsID: '',
            emailID: '',
        }

    }

    componentDidMount() {
        this.getProviderList()
        this.setCommunicationData(this.props)
        this.setApiCommunicationData(this.props)
    }

    componentWillReceiveProps(newProps) {
        if (this.props.listingInfo !== newProps.listingInfo) {
            this.setCommunicationData(newProps)
            this.setApiCommunicationData(newProps)
        }
    }

    setCommunicationData = (propsValue) => {
        if (propsValue && propsValue.listingInfo && propsValue.listingInfo.listing_communication_providers) {
            let data = propsValue.listingInfo.listing_communication_providers
            data.map((res, index) => {
                if (res && res.type && res.type === "sms") {
                    this.setState({
                        smsProviderId: res.communicationProviderId ? res.communicationProviderId : "",
                        smsID: res.ID ? res.ID : ""
                    })
                    if (res.communicationScope) {
                        let communicationScope = res.communicationScope
                        if (communicationScope.promotional) {
                            let promoData = []
                            communicationScope.promotional.map((item, index) => {
                                let initialData = {
                                    id: index,
                                    value: item
                                }
                                promoData.push(initialData)
                            })
                            this.setState({ smsPromotionalList: promoData })
                        }
                        if (communicationScope.transactional) {
                            let transData = []
                            communicationScope.transactional.map((item, index) => {
                                let initialData1 = {
                                    id: index,
                                    value: item
                                }
                                transData.push(initialData1)
                            })
                            this.setState({ smsTransactionalList: transData })
                        }
                    }
                }
                else if (res && res.type && res.type === "email") {

                    this.setState({
                        emailProviderId: res.communicationProviderId ? res.communicationProviderId : "",
                        emailID: res.ID ? res.ID : ""
                    })
                    if (res.communicationScope) {
                        let communicationScope = res.communicationScope
                        if (communicationScope.promotional) {
                            let promoData = []
                            communicationScope.promotional.map((item, index) => {
                                let initialData = {
                                    id: index,
                                    value: item
                                }
                                promoData.push(initialData)
                            })
                            this.setState({ emailPromotionalList: promoData })
                        }

                        if (communicationScope.transactional.length) {
                            let transData = []
                            communicationScope.transactional.map((item, index) => {
                                let initialData1 = {
                                    id: index,
                                    value: item
                                }
                                transData.push(initialData1)

                            })
                            this.setState({ emailTransactionalList: transData })
                        }
                    }
                }

            })
            this.setState({ isCreate: false })
        } else {
            let data = []
            data.push(initialData)
            this.setState({
                emailPromotionalList: data,
                emailTransactionalList: data,
                smsPromotionalList: data,
                smsTransactionalList: data,
                isCreate: true
            })
        }
    }

    setApiCommunicationData = (propsValue) => {
        if (propsValue && propsValue.listingInfo && propsValue.listingInfo.listing_communication_providers) {
            let data = propsValue.listingInfo.listing_communication_providers
            data.map((res, index) => {
                if (res && res.type && res.type === "sms") {
                    this.setState({
                        smsProviderId: res.communicationProviderId ? res.communicationProviderId : "",
                        smsID: res.ID ? res.ID : ""
                    })
                    if (res.apiCredentials) {
                        let apiCredentials = res.apiCredentials
                        if (apiCredentials.promotional !== null) {
                            let promoData = apiCredentials.promotional
                            this.setState({ smsApiPromotional: promoData })
                        }
                        if (apiCredentials.transactional !== null) {
                            let transData = apiCredentials.transactional
                            this.setState({ smsApiTransactional: transData })
                        }
                    }
                }
                else if (res && res.type && res.type === "email") {

                    this.setState({
                        emailProviderId: res.communicationProviderId ? res.communicationProviderId : "",
                        emailID: res.ID ? res.ID : ""
                    })
                    if (res.apiCredentials) {
                        let apiCredentials = res.apiCredentials
                        if (apiCredentials.promotional !== null) {
                            let promoData = apiCredentials.promotional
                            this.setState({ emailApiPromotional: promoData })
                        }

                        if (apiCredentials.transactional !== null) {
                            let transData = apiCredentials.transactional
                            this.setState({ emailApiTransactional: transData })
                        }
                    }
                }

            })
            this.setState({ isCreate: false })
        } else {
            let data = null
            this.setState({
                emailApiPromotional: data,
                emailApiTransactional: data,
                smsApiPromotional: data,
                smsApiTransactional: data,
                isCreate: true
            })
        }
    }

    getProviderList = () => {
        const gqlValue = GET_COMMUNICATION_PROVIDER
        const query = gql`
              ${gqlValue}
            `
        client
            .subscribe({
                query
            })
            .subscribe(
                res => {
                    if (res && res.data && res.data.communication_provider.length > 0) {
                        this.setState({ providerList: res.data.communication_provider })
                    }
                },
                err => {
                }
            )
    }

    renderProvider = (type, stateOption, stateValue) => {
        const { providerList } = this.state
        if (providerList.length > 0 && type) {
            return (
                <Select placeholder="Select Provider"
                    className=""
                    style={{ width: 300 }}
                    value={stateValue}
                    onChange={(event) => this.onChangeProvider(event, stateOption)}
                // allowClear={true}
                >
                    {providerList.map(val => {
                        if (val && val.providerName && val.type === type) {
                            return (
                                <Option value={val.id}>{val.providerName}</Option>
                            )
                        }
                    })
                    }
                </Select>
            )
        }
    }

    onChangeProvider = (event, stateOption) => {
        if (event) {
            this.setState({ [stateOption]: event })
        }
    }

    renderPromotional = (type, Option, stateList) => {
        if (stateList.length > 0) {
            return stateList.map((res, index) => {
                if (res && !res.isDelete) {
                    return (
                        <div style={{ paddingBottom: 15 }}>
                            <Input
                                style={{ width: 500 }}
                                value={res.value}
                                onChange={(event) => this.onChangeValues(event, Option, stateList, index)}
                            />
                            <Icon
                                id="icon-view"
                                className="dynamic-delete-button"
                                type="delete"
                                onClick={() => this.onRemoveListRow(index, Option, stateList)}
                                style={{ marginLeft: 20 }}
                            />
                        </div>
                    )
                }

            })
        }
    }

    onRemoveListRow = (indexValue, optionValue, list) => {
        let data = cloneDeep(list)
        data[indexValue].isDelete = true
        this.setState({ [optionValue]: data })
    }

    onChangeValues = (event, optionValue, list, indexValue) => {
        let data = cloneDeep(list)
        data[indexValue].value = event.target.value
        this.setState({ [optionValue]: data })
    }

    onChangeApiValues = (event, optionValue, list) => {
        let data = cloneDeep(list)
        data = event.target.value
        this.setState({ [optionValue]: data })
    }

    renderAddButton = (option, listData) => {
        return (
            <Button className="communication-dashed-button" type="dashed" onClick={() => this.onAddListRow(option, listData)} style={{ width: '100px', bottom: '2px' }} >
                <Icon id="icon-view" type="plus" />
            </Button>
        )
    }

    onAddListRow = (optionValue, list) => {
        let data = cloneDeep(list)
        let add = {
            id: data.length,
            value: null
        }
        data.push(add)
        this.setState({ [optionValue]: data })
    }

    onSaveData = () => {
        const { smsProviderId, emailProviderId } = this.state
        if (smsProviderId !== null && smsProviderId !== '') {
            this.setSMSData()
        }
        if (emailProviderId !== null && emailProviderId !== '') {
            this.setEmailData()
        }

    }

    setSMSData = () => {
        const { smsPromotionalList, smsTransactionalList, smsApiPromotional, smsApiTransactional } = this.state
        let promotional = []
        let Transactional = []
        let promotionalApi
        let transactionalApi
        if (smsPromotionalList.length > 0) {
            smsPromotionalList.map((res) => {
                if (res && res.value && !res.isDelete) {
                    promotional.push(res.value)
                }
            })
        }
        if (smsTransactionalList.length > 0) {
            smsTransactionalList.map((res) => {
                if (res && res.value && !res.isDelete) {
                    Transactional.push(res.value)
                }
            })
        }
        let scope = {
            "promotional": promotional,
            "transactional": Transactional
        }

        if (smsApiPromotional !== null && smsApiPromotional !== undefined) {
            promotionalApi = smsApiPromotional
        }
        if (smsApiTransactional !== null && smsApiTransactional !== undefined) {
            transactionalApi = smsApiTransactional
        }


        let apikey = {
            "promotional": promotionalApi,
            "transactional": transactionalApi
        }

        let variableObject
        let createVariableObject
        let mutationAdd
        if (this.state.smsID !== null && this.state.smsID !== '') {
            variableObject = {
                listingId: localStorage.getItem('selectedListingId'),
                communicationProviderId: this.state.smsProviderId,
                communicationScope: scope,
                type: smsId,
                apiCredentials: apikey,
                ID: this.state.smsID
            }
            mutationAdd = UPDATE_COMMUNICATION_PROVIDER
        } else {
            variableObject = {
                listingId: localStorage.getItem('selectedListingId'),
                communicationProviderId: this.state.smsProviderId,
                communicationScope: scope,
                type: smsId,
                apiCredentials: apikey
            }
            mutationAdd = ADD_COMMUNICATION_PROVIDER
        }


        if (variableObject) {
            client.mutate({ mutation: mutationAdd, variables: variableObject }).then((response) => {
                message.success('Added sms SuccessFully')
                this.onBackToList()
            })
                .catch(err => {
                    message.error(err.message)
                });
        }
    }

    setEmailData = () => {
        const { emailPromotionalList, emailTransactionalList, emailApiPromotional, emailApiTransactional } = this.state
        let promotional = []
        let Transactional = []
        let promotionalApi
        let transactionalApi
        if (emailPromotionalList.length > 0) {
            emailPromotionalList.map((res) => {
                if (res && res.value && !res.isDelete) {
                    promotional.push(res.value)
                }
            })
        }
        if (emailTransactionalList.length > 0) {
            emailTransactionalList.map((res) => {
                if (res && res.value && !res.isDelete) {
                    Transactional.push(res.value)
                }
            })
        }
        let scope = {
            "promotional": promotional,
            "transactional": Transactional
        }

        if (emailApiPromotional !== null && emailApiPromotional !== undefined) {
            promotionalApi = emailApiPromotional
        }
        if (emailApiTransactional !== null && emailApiTransactional !== undefined) {
            transactionalApi = emailApiTransactional
        }

        let apikey = {
            "promotional": promotionalApi,
            "transactional": transactionalApi
        }

        let variableObject
        let createVariableObject
        let mutationAdd
        if (this.state.emailID !== null && this.state.emailID !== '') {
            variableObject = {
                listingId: localStorage.getItem('selectedListingId'),
                communicationProviderId: this.state.emailProviderId,
                communicationScope: scope,
                type: emailId,
                apiCredentials: apikey,
                ID: this.state.emailID
            }
            mutationAdd = UPDATE_COMMUNICATION_PROVIDER
        } else {
            variableObject = {
                listingId: localStorage.getItem('selectedListingId'),
                communicationProviderId: this.state.emailProviderId,
                communicationScope: scope,
                type: emailId,
                apiCredentials: apikey
            }
            mutationAdd = ADD_COMMUNICATION_PROVIDER
        }

        if (variableObject) {
            client.mutate({ mutation: mutationAdd, variables: variableObject }).then((response) => {
                message.success('Added email SuccessFully')
                this.onBackToList()
            })
                .catch(err => {
                    message.error(err.message)
                });
        }
    }

    renderTitle = () => {
        return (

            <div class="gx-module-box-topbar" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                {
                    this.state.isCreate === false &&
                    <i className="icon icon-arrow-left gx-icon-btn"
                        onClick={() => this.onBackToList()}
                    ></i>
                }

                <span className="gx-module-logo">Communication</span>

                <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '20%' }}>
                    <Tooltip title="Save">
                        <Icon id="icon-view" type="save" style={{ cursor: 'pointer' }} onClick={() => this.onSaveData()} />
                    </Tooltip>
                </div>
            </div>
        )
    }

    onBackToList = () => {
        if (this.props.onBackToCommunicationList) {
            this.props.onBackToCommunicationList()
        }
    }


    render() {
        const { smsProviderId, emailProviderId, emailPromotionalList, emailTransactionalList,
            smsPromotionalList, smsTransactionalList, emailApiPromotional, emailApiTransactional, smsApiPromotional, smsApiTransactional } = this.state
        return (
            <div style={{ position: 'relative', bottom: '25px' }}>

                {this.renderTitle()}

                <Collapse bordered={false} defaultActiveKey={['1', '2']}
                    style={{ paddingTop: 25, paddingLeft: 25, paddingRight: 25 }}
                    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                >
                    <Panel header="Email" key="1" style={customPanelStyle}>
                        {/* {
                            this.renderProvider(emailId, 'emailProviderId', emailProviderId)
                        } */}

                        {
                            emailProviderId &&
                            <div>
                                <div>
                                    <div style={addStyle}>
                                        <h6>Promotional</h6>

                                        {this.renderAddButton('emailPromotionalList',
                                            emailPromotionalList)}
                                    </div>
                                    <div>
                                        {this.renderPromotional(emailId, 'emailPromotionalList',
                                            emailPromotionalList)}</div>
                                </div>
                                <div>
                                    <div style={addStyle}>
                                        <h6>Transactional</h6>
                                        {this.renderAddButton('emailTransactionalList',
                                            emailTransactionalList)}
                                    </div>
                                    <div>{this.renderPromotional(emailId, 'emailTransactionalList',
                                        emailTransactionalList)}</div>
                                </div>

                            </div>
                        }

                        {/* {emailProviderId &&
                            <div>
                                <div>
                                    <h6>ApiPromotional</h6>

                                    <div>
                                        <Input
                                            style={{ width: 500 }}
                                            value={emailApiPromotional}
                                            onChange={(event) => this.onChangeApiValues(event, 'emailApiPromotional', emailApiPromotional)}
                                        />
                                    </div>
                                </div>
                                <div style={{paddingTop:10}}>
                                    <h6>ApiTransactional</h6>

                                    <div>
                                        <Input
                                            style={{ width: 500 }}
                                            value={emailApiTransactional}
                                            onChange={(event) => this.onChangeApiValues(event, 'emailApiTransactional', emailApiTransactional)}
                                        />
                                    </div>
                                </div>
                            </div>
                        } */}

                    </Panel>
                    <Panel header="SMS" key="2" style={customPanelStyle}>
                        {/* {
                            this.renderProvider(smsId, 'smsProviderId', smsProviderId)
                        } */}
                        {
                            smsProviderId &&
                            <div>
                                <div>
                                    <div style={addStyle}>
                                        <h6>Promotional</h6>
                                        {this.renderAddButton('smsPromotionalList', smsPromotionalList)}
                                    </div>
                                    <div>{this.renderPromotional(smsId, 'smsPromotionalList', smsPromotionalList)}</div>

                                </div>
                                <div>
                                    <div style={addStyle}>
                                        <h6>Transactional</h6>
                                        {this.renderAddButton('smsTransactionalList', smsTransactionalList)}
                                    </div>
                                    <div>{this.renderPromotional(smsId, 'smsTransactionalList', smsTransactionalList)}</div>

                                </div>
                            </div>
                        }

                        {/* {smsProviderId &&
                            <div>
                                <div>
                                    <h6>ApiPromotional</h6>

                                    <div>
                                        <Input
                                            style={{ width: 500 }}
                                            value={smsApiPromotional}
                                            onChange={(event) => this.onChangeApiValues(event, 'smsApiPromotional', smsApiPromotional)}
                                        />
                                    </div>
                                </div>
                                <div style={{paddingTop:10}}>
                                    <h6>ApiTransactional</h6>

                                    <div>
                                        <Input
                                            style={{ width: 500 }}
                                            value={smsApiTransactional}
                                            onChange={(event) => this.onChangeApiValues(event, 'smsApiTransactional', smsApiTransactional)}
                                        />
                                    </div>
                                </div>
                            </div>
                        } */}
                    </Panel>
                </Collapse>
            </div>
        );
    }
};

export default AddCommunicationProvider;