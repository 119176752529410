
import {
    FETCH_TAGS_HISTORY
} from '../graphQl/subscriptions'
import client from '../apollo/apollo';



export function fetch_tag_history(listingId, tableName, refId, contactId, businessId) {
    return new Promise(function (resolve, reject) {
        try {
            if (listingId) {
                const tempVariable = {
                    listingId: listingId,
                    tableName: tableName,
                    refId: refId,
                    userContactId: contactId,
                    businessContactId: businessId
                }
                client
                    .subscribe({ query: FETCH_TAGS_HISTORY, variables: tempVariable })
                    .subscribe(
                        res => {
                            if (res && res.data && res.data.list_tags_history && res.data.list_tags_history.length > 0) {
                                resolve(res.data.list_tags_history);
                            } else {
                                reject([])
                            }
                        }, err => {
                            reject(err)
                        })
            }

        }
        catch (e) {
            reject(e)
        }
    })
}