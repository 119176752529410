import React, { Component } from "react";
import { connect } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch } from "react-router-dom";
import { LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../Auth/Signin";
import SignUp from "../Auth/Signup";
import { setInitUrl } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import { ApolloProvider } from 'react-apollo';
import client from '../../apollo/apollo';
import RedirectToD from '../RedirectTo'

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";
import { USERID_TO_GET_LISTING as query } from '../../graphQl/queries';
import { USER_WITH_UID } from '../../graphQl/queries';
import Notify from '../../routes/Notify/index';
import TransactionTemplate from '../../routes/TransactionTemplate'
import TransactionPublicUrl from '../../routes/TransactionPublicUrl'
import OrderSuccessView from '../../components/TransactionPublicUrlView/orderSuccess';
import axios from 'axios';
import { getENVConfig } from "util/common";
import { CONFIG } from "config/config";
import Config from 'util/config'

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => {
  let uid = localStorage.getItem('uid')
  return (
    <div>
      <Route
        {...rest}
        render={props =>
          uid
            ? <Component {...props} />
            : <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location }
              }}
            />}
      />;
  </div>
  )
}

const versionCheck = () => {
  axios({ method: 'POST', url: `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/latestVersion` })
    .then((result) => {
      console.log(result, 'result')
      if (result.data.latestVersion !== Config.version) {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          console.log("caches",caches)
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
            console.log(names,"names")
          });
          // delete browser cache and hard reload
          window.location.reload();
          // window.location.href = window.location.href
        }
        localStorage.setItem('VersionChecked', result.data.latestVersion)
      }
      console.log("configv",Config.version)
    })
    .catch(err => {
      console.log(err)
    });
}

class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    const { match, location, themeType, layoutType, navStyle, locale, authUser, initURL } = this.props;
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);
    versionCheck();
    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ApolloProvider client={client}>
        <LocaleProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>

            <Switch>
              <Route exact path='/signin' component={SignIn} />
              <Route exact path='/signup' component={SignUp} />
              <Route exact path="/f/:fileUrl" component={RedirectToD} />
              <Route exact path="/:shortUrl" component={TransactionTemplate} />
              <Route exact path="/ordersuccess/:sessionId" component={OrderSuccessView} />
              <Route exact path="/:listingName/:templatePublicUrl" component={TransactionPublicUrl} />
              <RestrictedRoute path={`${match.url}`} authUser={authUser}
                component={MainApp} />
            </Switch>
          </IntlProvider>
        </LocaleProvider>
      </ApolloProvider>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, themeType, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { locale, navStyle, themeType, layoutType, authUser, initURL }
};
export default connect(mapStateToProps, { setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange })(App);
