import React, { Component } from 'react'
import { Pagination, Button } from 'antd'
import _ from 'lodash'
import CustomScrollbars from 'util/CustomScrollbars'
import ListItem from "./ListItem";

const fetchOffset = 10
const defaultPageSize = 10

export class ListScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPageNo: 1,
      data: [],
      listData: [],
      limitValue: 50,
      totalCount: 0,
    }
  }

  componentDidMount() {
    this.setState({ selectedPageNo: this.props.pageIndex, totalCount: this.props.totalDataCount }, () => {
      if (this.props && this.props.toDos) {
        this.asignData(this.props.toDos)
      }
    })
  }

  componentWillReceiveProps(nxtprops) {
    if (nxtprops && nxtprops.totalDataCount && nxtprops.pageIndex) {
      this.setState({ selectedPageNo: nxtprops.pageIndex, totalCount: nxtprops.totalDataCount }, () => {
        if (nxtprops && nxtprops.toDos) {
          this.asignData(nxtprops.toDos)
        }
      })
    }
  }

  asignData(val) {
    let startIndex = (this.state.selectedPageNo - 1) * fetchOffset
    let endIndex = startIndex + fetchOffset
    let value = _.slice(val, startIndex, endIndex)
    this.setState({ data: val, listData: value }, () => {
    })
  }

  onPagePropsChange(page, pageSize) {

    this.setState(
      {
        selectedPageNo: page,
      },
      async () => {
        if (this.props.onChangePageIndex) {
          await this.props.onChangePageIndex(this.state.selectedPageNo)
          if (this.state.data.length < page * 10 && this.state.data.length !== this.state.totalCount) {
            let endval = Math.ceil(page / 5) * this.state.limitValue
            this.props.callListNext(endval)
          }
        }
      }
    )
  }

  // nextPress(endval) {
  // const endValue = this.state.data.length + this.state.limitValue
  // this.props.callListNext(endval)
  // let pageIndex = Math.ceil(this.state.data.length / fetchOffset)
  // if (this.props.onChangePageIndex) {
  //   console.log('2222', this.state.selectedPageNo)
  //   this.props.onChangePageIndex(pageIndex + 1)
  // }
  // }

  render() {
    const { toDos, onTodoSelect, onTodoChecked, onMarkAsStart, screenFrom, removeMember } = this.props
    const { selectedPageNo, data, listData, totalCount } = this.state
    return (
      <div className="gx-module-list" id="notify-list-view">
        <CustomScrollbars className="gx-module-content-scroll" id="list-scroll-view">
          {listData && listData.map((todo, index) =>
            <ListItem key={index} index={index} todo={todo} onTodoSelect={onTodoSelect}
              onMarkAsStart={onMarkAsStart}
              onTodoChecked={onTodoChecked}
              screenName={screenFrom}
              removeMember={removeMember}
            />
          )}
          {listData.length === 0 &&
            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100%', fontWeight: 500 }}>No data</span>
          }
          {totalCount > 10 &&
            <div id="pagination-modal-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
              <Pagination
                showQuickJumper
                current={selectedPageNo}
                total={totalCount}
                defaultPageSize={defaultPageSize}
                onChange={(page, pageSize) => this.onPagePropsChange(page, pageSize)}
              />
              {/* {screenFrom && screenFrom !== 'listingDetail' &&
                <div id="load-button-modal">
                  <Button
                    id="load-button"
                    style={{ marginLeft: 20, marginRight: 50 }}
                    type="primary"
                    onClick={() => this.nextPress()}
                  >
                    Load More
            </Button>
                </div>
              } */}
            </div>
          }
        </CustomScrollbars>
      </div>
    )
  }
}

export default ListScreen

