import React, { Component } from 'react'
import { Form, Icon, Button } from 'antd'
import AddProducts from './AddProducts'
import _ from 'lodash'

const initialData = {
    category: '',
    productName: '',
    productCode: '',
    productDescription: '',
    CGST: '',
    SGST: '',
    IGST: '',
    productConfigName1: '',
    productConfigPrice1: ''
}

export class ProductCatalog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            type: 'add',
        }
    }


    componentWillMount() {
        console.log('will mount', this.props)
        if (this.props && this.props.type) {
            this.setState({ type: this.props.type })
        }
        if (this.props.editData && this.props.editData.length > 0) {
            this.setState({
                categories: this.props.editData
            })
        }
        if (this.props.type === 'add') {
            let data = []
            data.push(initialData)
            this.setState({ categories: data })
        }
    }

    componentWillReceiveProps(nxtprops) {
        console.log('nxtprops', nxtprops, this.props)
        if (nxtprops.type === "edit" && nxtprops.editData && nxtprops.editData !== this.props.editData) {
            this.setState({ categories: nxtprops.editData })
        }
        // console.log('check nxtprops', nxtprops.editData !== this.props.editData)
        // if (nxtprops.type === 'add' && nxtprops.editData !== this.props.editData) {
        //     console.log('add', nxtprops)
        //     let data = []
        //     data.push(initialData)
        //     this.setState({ categories: data })
        // }
    }

    AddCategoryRow = () => {
        let data = this.state.categories
        let categoryData = {
            category: '',
            productName: '',
            productCode: '',
            productDescription: '',
            CGST: '',
            SGST: '',
            IGST: '',
            productConfigName1: '',
            productConfigPrice1: ''
        }

        data.push(categoryData)
        this.setState({ categories: data })
    }

    LoopItemsChange(categories) {
        this.setState({ categories }, () => {
            if (categories.length > 0) {
                const categoriesDeletedRemoved = categories.filter((i) => { return i.isDelete !== true });
                if (this.props && this.props.formatCatalog) {
                    this.props.formatCatalog(categoriesDeletedRemoved)
                }
                if (this.props && this.props.formatEditCatalog) {
                    this.props.formatEditCatalog(categories)
                }
            }
        })
    }

    onRemove(categories) {
        this.setState({ categories });
    }



    render() {
        const { categories, type } = this.state
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const imagevisible = this.props && this.props.productImage ? true : false
        console.log('imagevisible', imagevisible)
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <div>
                <Form>
                    <AddProducts
                        LoopItems={categories}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        type={type}
                        productImage={imagevisible}
                    />
                    {type === 'add' &&
                        <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Form.Item {...formItemLayoutWithOutLabel} >
                                <Button type="dashed" onClick={() => this.AddCategoryRow()} style={{ width: '100px' }} >
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Form.Item>
                        </div>
                    }
                </Form>
            </div>
        )
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(ProductCatalog);
export default WrappedDynamicFieldSet