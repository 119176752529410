import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from './DynamicFields';


let initialData = {
    phoneNumber: '',
    email: ''
}

class SellerNotification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notificationDetails: [],
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        if (this.props.editData) {
            this.setState({ notificationDetails: this.props.editData })
        } else {
            if (this.state.notificationDetails.length === 0) {
                let data = this.state.notificationDetails
                data.push(initialData)
                this.setState({ notificationDetails: data, loading: false })
            }
        }
    }

    onAddRow = () => {
        let data = this.state.notificationDetails
        let initialData = {
            phoneNumber: '',
            email: ''
        }

        if (data) { data.push(initialData) }
        this.setState({ notificationDetails: data })
    }


    LoopItemsChange(notificationDetails) {
        this.setState({ notificationDetails }, () => {
            this.props.notificationDetailSave(notificationDetails);
        })
    }

    onRemove(notificationDetails) {
        this.setState({ notificationDetails });
    }


    render() {
        const { notificationDetails } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <Form
                onSubmit={this.handleSubmit}
            >
                <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Enter the phone number and/or email of the people you want to notify when an order is placed. By default, the customer will be notified always.
                    </span>
                </div>

                <div >
                    <DynamicFields
                        accessFrom='SellerNotification'
                        LoopItems={notificationDetails}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        isEdit={this.props.isEdit ? true : false}

                    />
                    {/* {formItems} */}
                </div>
                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item {...formItemLayoutWithOutLabel} >
                        <Button type="primary" htmlType="submit" >
                            Save
                            </Button>
                    </Form.Item>
                </div> */}
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(SellerNotification);
export default WrappedDynamicFieldSet

