import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from './DynamicFields';


const { Option } = Select;
let initialData = {
    attributeName: '',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: ''
}
let initialData1 = [{
    attributeName: 'Shipment ID',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: 'Input'
},
{attributeName: 'Tracking Number',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: 'TrackingNumber'
},
{attributeName: 'Invoice Number',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: 'Input'
}
,
{attributeName: 'Customer PO',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: 'Input'
},
{attributeName: 'Courier',
    attributeValue: 'bluedart,shree-maruti,fedex,dhl',
    showAttributeName: false,
    required: false,
    attributeType: 'Option'
},
]


class CustomerDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            customerDetails: [],
            id: 0,
            deletedKeys: [],
            
        }
    };

    componentWillMount() {
        console.log(this.props.editData, 'editData')
        if (this.props.editData) {
            this.setState({ customerDetails: this.props.editData })
        } else {
            if (this.state.customerDetails.length === 0) {
                if ( this.props.section==='Transaction'){
                let data = this.state.customerDetails
                console.log(this.props.section,"shipment")
                data=initialData1
                this.setState({ customerDetails: data, loading: false }, () => {
                    this.props.customerDetailSave(this.state.customerDetails, this.props.section);
                })}
                else {
                    console.log(this.props.section,"ecommerce")
                    let data = this.state.customerDetails
                    data.push(initialData)
                    this.setState({ customerDetails: data, loading: false })
                   } 
            }
           
        }

    }

    
    onAddRow = () => {
        let data = this.state.customerDetails
        let initialData = {
            attributeName: '',
            attributeValue: '',
            showAttributeName: false,
            required: false,
            attributeType: ''
        }

        if(data) {data.push(initialData)}
        this.setState({ customerDetails: data })
    }


    LoopItemsChange(customerDetails) {
        this.setState({ customerDetails }, () => {
            this.props.customerDetailSave(customerDetails, this.props.section);
        })
    }

    onRemove(customerDetails) {
        this.setState({ customerDetails });
    }


    render() {
        const { customerDetails } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        console.log(customerDetails, 'cutomerdetails')
        return (
            <Form
                onSubmit={this.handleSubmit}
            >

                <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Add fields that you want the customer to fill such as Name, address etc in the order form. The form field name displays the title like Name. Leave the form field value blank for the customer to fill.
                    </span>
                </div>

                <div >
                    <DynamicFields
                        accessFrom={this.props.section ? this.props.section : 'Customer'}
                        LoopItems={customerDetails}
                        LoopItemsChange={this.LoopItemsChange.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        isEdit={this.props.isEdit ? true : false}
                        listingDetail={this.props.listingDetail && this.props.listingDetail}
                    />
                    {/* {formItems} */}
                </div>
                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item {...formItemLayoutWithOutLabel} >
                        <Button type="primary" htmlType="submit" >
                            Save
                            </Button>
                    </Form.Item>
                </div> */}
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(CustomerDetails);
export default WrappedDynamicFieldSet

