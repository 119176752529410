import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from './DynamicFields';


let deliveryinitialData = {
    attributeName: '',
    attributeValue: '',
    required: false,
    attributeType: ''
}

let pickupinitialData = {
    attributeName: '',
    attributeValue: '',
    required: false,
    attributeType: ''
}
let seatinginitialData = {
    attributeName: 'Table Number',
    attributeValue: '',
    required: false,
    attributeType: ''
}

class DeliveryPickup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            DeliveryDetails: [],
            PickupDetails: [],
            seatingDetails: [],
            id: 0,
            deletedKeys: [],
            deliverySelected: false,
            pickupSelected: false,
            seatingSelected: false
        }
    };

    componentWillMount() {
        if (this.props.editData) {
            if (this.props.editData.selectedOption === 'All') {
                this.state.deliverySelected = true;
                this.state.pickupSelected = true;
                this.state.seatingSelected = true;
            } else if (this.props.editData.selectedOption === 'Both') {
                this.state.deliverySelected = true;
                this.state.pickupSelected = true;
                this.state.seatingSelected = false;
            } else if (this.props.editData.selectedOption === 'DeliverySeating') {
                this.state.deliverySelected = true;
                this.state.pickupSelected = false;
                this.state.seatingSelected = true;
            } else if (this.props.editData.selectedOption === 'PickupSeating') {
                this.state.deliverySelected = false;
                this.state.pickupSelected = true;
                this.state.seatingSelected = true;
            } else if (this.props.editData.selectedOption === 'Delivery') {
                this.state.deliverySelected = true;
                this.state.pickupSelected = false;
                this.state.seatingSelected = false;
            } else if (this.props.editData.selectedOption === 'Pickup') {
                this.state.deliverySelected = false;
                this.state.pickupSelected = true;
                this.state.seatingSelected = false;
            } else if (this.props.editData.selectedOption === 'Seating') {
                this.state.deliverySelected = false;
                this.state.pickupSelected = false;
                this.state.seatingSelected = true;
            }
            this.setState({
                // DeliveryDetails: this.props.editData.DeliveryDetails,
                // PickupDetails: this.props.editData.PickUpDetails,
                pickupSelected: this.state.pickupSelected,
                deliverySelected: this.state.deliverySelected,
                seatingSelected: this.state.seatingSelected
            });
            if (this.props.editData.DeliveryDetails) {
                this.setState({ DeliveryDetails: this.props.editData.DeliveryDetails });
            } else {
                if (this.state.DeliveryDetails.length === 0) {
                    let data = this.state.DeliveryDetails
                    data.push(deliveryinitialData)
                    this.setState({ DeliveryDetails: data, loading: false })
                }
            }
            if (this.props.editData.PickUpDetails) {
                this.setState({ PickupDetails: this.props.editData.PickUpDetails });
            } else {
                if (this.state.PickupDetails.length === 0) {
                    let data = this.state.PickupDetails
                    data.push(pickupinitialData)
                    this.setState({ PickupDetails: data, loading: false })
                }
            }
            if (this.props.editData.seatingDetails) {
                this.setState({ seatingDetails: this.props.editData.seatingDetails });
            } else {
                if (this.state.seatingDetails.length === 0) {
                    let data = this.state.seatingDetails
                    data.push(seatinginitialData)
                    this.setState({ seatingDetails: data, loading: false })
                }
            }
        } else {
            if (this.state.DeliveryDetails.length === 0) {
                let data = this.state.DeliveryDetails
                data.push(deliveryinitialData)
                this.setState({ DeliveryDetails: data, loading: false })
            }
            if (this.state.PickupDetails.length === 0) {
                let data = this.state.PickupDetails
                data.push(pickupinitialData)
                this.setState({ PickupDetails: data, loading: false })
            }
            if (this.state.seatingDetails.length === 0) {
                let data = this.state.seatingDetails
                data.push(seatinginitialData)
                this.setState({ seatingDetails: data, loading: false })
            }
        }
    }

    onAddDeliveryRow = () => {
        let data = this.state.DeliveryDetails

        let deliveryinitialData = {
            attributeName: '',
            attributeValue: '',
            required: false,
            attributeType: ''
        }
        if (data) {
            data.push(deliveryinitialData)
        }
        this.setState({ DeliveryDetails: data })
    }

    onAddPickupRow = () => {
        let data = this.state.PickupDetails
        console.log('onAddPickupRow', data)
        let pickupinitialData = {
            attributeName: '',
            attributeValue: '',
            required: false,
            attributeType: ''
        }
        if (data) {
            data.push(pickupinitialData)
        }
        this.setState({ PickupDetails: data })
    }

    onAddSeatingRow = () => {
        let data = this.state.seatingDetails

        let seatinginitialData = {
            attributeName: '',
            attributeValue: '',
            required: false,
            attributeType: ''
        }
        if (data) { data.push(seatinginitialData) }
        this.setState({ seatingDetails: data })
    }


    LoopItemsChange(DeliveryPickupDetails, option) {
        console.log(DeliveryPickupDetails, 'DeliveryPickupDetailschange')
        if (option === 'Delivery') {
            this.setState({ DeliveryDetails: DeliveryPickupDetails });
        }
        if (option === 'Pickup') {
            this.setState({ PickupDetails: DeliveryPickupDetails });
        }
        if (option === 'Seating') {
            this.setState({ seatingDetails: DeliveryPickupDetails });
        }
        let selectedoption = ''
        if (this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
            selectedoption = 'All';
        } else if (this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
            selectedoption = 'Both';
        } else if (this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
            selectedoption = 'DeliverySeating';
        } else if (!this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
            selectedoption = 'PickupSeating';
        } else if (this.state.deliverySelected && !this.state.pickupSelected && !this.state.seatingSelected) {
            selectedoption = 'Delivery';
        } else if (!this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
            selectedoption = 'Pickup';
        } else if (!this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
            selectedoption = 'Seating';
        }

        this.setState({ selectedoption }, () => {
            const DeliverypickupDetails = {
                selectedOption: this.state.selectedoption,
                DeliveryDetails: this.state.DeliveryDetails,
                PickUpDetails: this.state.PickupDetails,
                seatingDetails: this.state.seatingDetails
            }
            this.props.DeliverypickupDetailSave(DeliverypickupDetails);
        });

    }

    onRemove(DeliveryPickupDetails, option) {
        if (option === 'Delivery') {
            this.setState({ DeliveryDetails: DeliveryPickupDetails });
        }
        if (option === 'Pickup') {
            this.setState({ PickupDetails: DeliveryPickupDetails });
        }
        if (option === 'Seating') {
            this.setState({ seatingDetails: DeliveryPickupDetails });
        }
    }

    deliveryChanged(e) {
        this.setState({ deliverySelected: e.target.checked }, () => {
            let selectedoption = ''
            if (this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'All';
            } else if (this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Both';
            } else if (this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'DeliverySeating';
            } else if (!this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'PickupSeating';
            } else if (this.state.deliverySelected && !this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Delivery';
            } else if (!this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Pickup';
            } else if (!this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'Seating';
            }

            this.setState({ selectedoption }, () => {
                const DeliverypickupDetails = {
                    selectedOption: this.state.selectedoption,
                    DeliveryDetails: this.state.DeliveryDetails,
                    PickUpDetails: this.state.PickupDetails,
                    seatingDetails: this.state.seatingDetails

                }
                this.props.DeliverypickupDetailSave(DeliverypickupDetails);
            });
        });

    }

    pickupChanged(e) {
        this.setState({ pickupSelected: e.target.checked }, () => {
            let selectedoption = ''
            if (this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'All';
            } else if (this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Both';
            } else if (this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'DeliverySeating';
            } else if (!this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'PickupSeating';
            } else if (this.state.deliverySelected && !this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Delivery';
            } else if (!this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Pickup';
            } else if (!this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'Seating';
            }

            this.setState({ selectedoption }, () => {
                const DeliverypickupDetails = {
                    selectedOption: this.state.selectedoption,
                    DeliveryDetails: this.state.DeliveryDetails,
                    PickUpDetails: this.state.PickupDetails,
                    seatingDetails: this.state.seatingDetails

                }
                this.props.DeliverypickupDetailSave(DeliverypickupDetails);
            });
        });

    }

    seatingChanged(e) {
        this.setState({ seatingSelected: e.target.checked }, () => {
            let selectedoption = ''
            if (this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'All';
            } else if (this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Both';
            } else if (this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'DeliverySeating';
            } else if (!this.state.deliverySelected && this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'PickupSeating';
            } else if (this.state.deliverySelected && !this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Delivery';
            } else if (!this.state.deliverySelected && this.state.pickupSelected && !this.state.seatingSelected) {
                selectedoption = 'Pickup';
            } else if (!this.state.deliverySelected && !this.state.pickupSelected && this.state.seatingSelected) {
                selectedoption = 'Seating';
            }

            this.setState({ selectedoption }, () => {
                const DeliverypickupDetails = {
                    selectedOption: this.state.selectedoption,
                    DeliveryDetails: this.state.DeliveryDetails,
                    PickUpDetails: this.state.PickupDetails,
                    seatingDetails: this.state.seatingDetails
                }
                this.props.DeliverypickupDetailSave(DeliverypickupDetails);
            });
        });

    }



    render() {
        const { DeliveryDetails, PickupDetails, seatingDetails } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };

        return (
            <Form
                onSubmit={this.handleSubmit}
            >
                <div style={{ paddingBottom: '17px' }} >
                    <span>
                        Add delivery related fields that you want the customer to fill such as delivery address, deliver contact etc in the order form. The form field name displays the title like Name or address. Leave the form field value blank for the customer to fill.
                    </span>
                </div>


                <Checkbox defaultChecked={false} checked={this.state.deliverySelected} onChange={(e) => this.deliveryChanged(e)} style={{ marginBottom: 12 }}>
                    <span>Delivery</span>
                </Checkbox>
                <Checkbox defaultChecked={false} checked={this.state.pickupSelected} onChange={(e) => this.pickupChanged(e)} style={{ marginBottom: 12 }}>
                    <span>Pick Up</span>
                </Checkbox>
                <Checkbox defaultChecked={false} checked={this.state.seatingSelected} onChange={(e) => this.seatingChanged(e)} style={{ marginBottom: 12 }}>
                    <span>Seating</span>
                </Checkbox>

                {this.state.deliverySelected &&
                    <div>
                        <span>Delivery</span>
                        <DynamicFields
                            accessFrom='Delivery'
                            LoopItems={DeliveryDetails}
                            LoopItemsChange={this.LoopItemsChange.bind(this)}
                            onRemove={this.onRemove.bind(this)}
                            isEdit={this.props.isEdit ? true : false}

                        />
                        {/* {formItems} */}

                        <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Item {...formItemLayoutWithOutLabel} >
                                <Button type="dashed" onClick={() => this.onAddDeliveryRow()} style={{ width: '100px' }} >
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Form.Item>
                        </div>

                    </div>
                }
                {this.state.pickupSelected &&
                    <div>
                        <span>Pick up</span>
                        <DynamicFields
                            accessFrom='Pickup'
                            LoopItems={PickupDetails}
                            LoopItemsChange={this.LoopItemsChange.bind(this)}
                            onRemove={this.onRemove.bind(this)}
                            isEdit={this.props.isEdit ? true : false}
                        />

                        <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Item {...formItemLayoutWithOutLabel} >
                                <Button type="dashed" onClick={() => this.onAddPickupRow()} style={{ width: '100px' }} >
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Form.Item>
                        </div>

                    </div>
                }
                {this.state.seatingSelected &&
                    <div>
                        <span>Seating</span>
                        <DynamicFields
                            accessFrom='Seating'
                            LoopItems={seatingDetails}
                            LoopItemsChange={this.LoopItemsChange.bind(this)}
                            onRemove={this.onRemove.bind(this)}
                            isEdit={this.props.isEdit ? true : false}
                        />

                        <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                            <Form.Item {...formItemLayoutWithOutLabel} >
                                <Button type="dashed" onClick={() => this.onAddSeatingRow()} style={{ width: '100px' }} >
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Form.Item>
                        </div>

                    </div>
                }

            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(DeliveryPickup);
export default WrappedDynamicFieldSet

