import React, { Component } from 'react'
import { Modal, Input, Button, Icon, Row, Col, message, Spin, Popconfirm } from 'antd'
import { cloneDeep } from 'lodash'
import { WithContext as ReactTags } from 'react-tag-input';
import {
    INSERT_TAG, INSERT_TAG_TRANSACTION, INSERT_TAG_INDIVIDUAL_CONTACT,
    INSERT_TAG_BUSINESS_CONTACT, UPDATE_TAG_HISTORY_BY_ID,
    INSERT_TAG_MESSAGES, DELETE_TAG_HISTORY_BY_ID
} from '../../graphQl/mutations'
import { fetch_tag_history } from '../../util/TagLogs'

import client from '../../apollo/apollo'
import * as _ from "lodash"

let initialData = {
    id: 0,
    tagValue: ''
}

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export class TagModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            tags: [],
            suggestions: [],
            SelectedListData: this.props.SelectedListData,
            tableName: this.props.screenName,
            transactionTemplateId: this.props.templateId,
            loading: false,
            isOpenSaveTag: false,
            isEdit: false,
            isFieldsReadOnly: false,
            editItem: {},
            recipientType: '',
            recipientUser: ''
        }
    }

    componentDidMount() {
        if (this.props && this.props.openModal) {
            this.setState({ visible: this.props.openModal })
        }
        this.setState({
            recipientType: this.props.receivedUserType,
            recipientUser: this.props.recipientUserId
        })
        this.setValues(this.props)
    }

    componentWillMount() {

        fetch_tag_history
            (localStorage.getItem('selectedListingId'), this.state.tableName, this.props.templateId)
            .then(async (res) => {
                this.suggestionsFetch(res);
            })
            .catch((err) => {
            });
    }

    componentWillReceiveProps(nxtprops) {

        if (this.props.editItem !== nxtprops.editItem) {
            this.setValues(nxtprops)
        }

        if (this.props.receivedUserType !== nxtprops.receivedUserType) {
            this.setState({ recipientType: nxtprops.receivedUserType })
        }
        if (this.props.recipientUserId !== nxtprops.recipientUserId) {
            this.setState({ recipientUser: nxtprops.recipientUserId })
        }

        this.setState({ visible: nxtprops.openModal })
    }

    setValues = (props) => {
        //when clicking on edit tags
        if (Object.keys(props.editItem).length > 0) {
            this.setState({ isEdit: true, isFieldsReadOnly: true })
            if (Object.keys(props.editItem.tagData).length > 0) {
                this.setState({
                    tags: props.editItem.tagData,
                    tagName: props.editItem.tagName,
                    editItem: props.editItem
                })
            }
        }
        //create tags
        else {
            this.setState({ tags: [], isEdit: false, isFieldsReadOnly: false, editItem: {} })
        }
    }

    suggestionsFetch = async (res) => {
        res.map(async (tagHistoryData, i) => {
            this.suggestionsFetch2(tagHistoryData).then((data) => {
                this.setState({ suggestions: data })
            })
        });
    }

    suggestionsFetch2(tagHistoryData) {
        let suggestions = [];
        return new Promise((resolve, reject) => {
            tagHistoryData.tagData.map(async (data, index) => {
                suggestions.push(data);
            });
            resolve(suggestions);
        });
    }

    handleCancel = (option) => {
        if (this.props.closeModal) {
            this.props.closeModal(option)
        }
    }

    //when click on save tag
    onOpenSaveFilterField = () => {
        this.setState({ isOpenSaveTag: true })
    }

    //when cancel the save tag
    onCancelSaveTag = () => {
        if (this.state.isEdit === false) {
            this.setState({ isOpenSaveFilter: false, tagName: '', })
        } else {
            this.setState({ isFieldsReadOnly: true })
        }
        this.setState({ isOpenSaveTag: false })
    }

    //footer
    renderFooter = () => {
        const { isEdit, isFieldsReadOnly } = this.state
        return (
            <div>
                <div>
                    {
                        (this.state.isOpenSaveTag === false && isEdit === false) &&
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button type="primary"
                                onClick={() => this.onOpenSaveFilterField()}
                            >Save Tag</Button>
                            <Button type="primary" onClick={() => this.onAddTagToRecord()}>Add Tag</Button>
                        </div>
                    }

                </div>
                {
                    ((this.state.isOpenSaveTag === true) ||
                        (isFieldsReadOnly === false && isEdit === true)) &&
                    <div>
                        <div style={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                            width: '100%', marginTop: 5
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '67%'
                            }}>
                                <h6 style={{
                                    // width: '28%',
                                    whiteSpace: 'nowrap',
                                    marginTop: 5
                                }}>Enter Tag Name:</h6>
                                <Input
                                    style={{ marginLeft: 8 }}
                                    onChange={(event) => this.setState({ tagName: event.target.value })}
                                    value={this.state.tagName}
                                />

                            </div>
                            <div style={{ marginTop: -10 }}>
                                <Button type="primary" onClick={() => this.onSaveTagToHistory()} style={{ marginTop: 10 }}>Save</Button>
                                <Button onClick={() => this.onCancelSaveTag()} style={{ marginTop: 10 }}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                }

                {(isEdit === true && isFieldsReadOnly === true) &&
                    <div>
                        <Button type="primary"
                            onClick={() => this.setState({ isFieldsReadOnly: !this.state.isFieldsReadOnly })}
                        >Edit</Button>

                        <Popconfirm
                            title="Are you sure to delete this filter?"
                            onConfirm={() => this.onDeleteTagItem()}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="danger"
                            >Delete</Button>
                        </Popconfirm>
                    </div>
                }

            </div>
        )
    }

    //Delete tag
    onDeleteTagItem = () => {
        if ((Object.keys(this.state.editItem).length > 0) && this.state.editItem.tagHistId) {
            let variableData = {
                tagHistId: this.state.editItem.tagHistId
            }
            client.mutate({ mutation: DELETE_TAG_HISTORY_BY_ID, variables: variableData }).then((response) => {
                message.success('Tag Deleted SuccessFully')
                this.setState({
                    loading: false, tags: [], isOpenSaveTag: false,
                    suggestions: [], tagName: []
                })
                this.handleCancel();
            })
                .catch(err => {
                    message.error('Filter Deleted Failed')
                });
        }
    }

    //tag save
    onSaveTagToHistory = async () => {
        this.setState({ loading: true }, async () => {
            //check whether tags are added/selected
            if (this.state.tags.length > 0) {
                if (this.state.tagName) {
                    const { recipientUser, recipientType } = this.state
                    let variableData = {}

                    let contactId = (recipientType && recipientType === "personal") ? recipientUser : null
                    let businessId = (recipientType && recipientType === "business") ? recipientUser : null
                    let listingId = recipientType ? localStorage.getItem('receivedListingId') : localStorage.getItem('selectedListingId')


                    //create tag
                    if (this.state.isEdit === false) {
                        variableData = {
                            listingId: listingId,
                            tableName: this.state.tableName,
                            refId: this.state.transactionTemplateId ? this.state.transactionTemplateId : null,
                            createdBy: localStorage.getItem('uid'),
                            tagData: this.state.tags,
                            tagName: this.state.tagName,
                            userContactId: contactId,
                            businessContactId: businessId
                        }
                    }
                    //edit tag
                    else {
                        variableData = {
                            tagHistId: this.state.editItem ? this.state.editItem.tagHistId : '',
                            listingId: listingId,
                            tableName: this.state.tableName,
                            refId: this.state.transactionTemplateId ? this.state.transactionTemplateId : null,
                            createdBy: localStorage.getItem('uid'),
                            tagData: this.state.tags,
                            tagName: this.state.tagName,
                            userContactId: contactId,
                            businessContactId: businessId
                        }

                    }
                    client.mutate({ mutation: this.state.isEdit === false ? INSERT_TAG : UPDATE_TAG_HISTORY_BY_ID, variables: variableData }).then((response) => {
                        message.success('Tag saved SuccessFully')
                        this.setState({
                            loading: false, tags: [], isOpenSaveTag: false,
                            suggestions: [], tagName: []
                        })
                        this.handleCancel();
                    })
                        .catch(err => {
                            this.setState({ loading: false })
                            message.error('tag saved Failed')
                        });

                } else {
                    this.setState({ loading: false })
                    message.warning('Please enter tag name')
                }
            }
            //if tags list is empty
            else {
                this.setState({ loading: false })
                message.warning('Please add tag')
            }
        })
    }

    //add tag functionality
    onAddTagToRecord = async () => {
        this.setState({ loading: true }, async () => {
            let ids = []

            //filter selected data from list
            let data = _.filter(this.state.SelectedListData, function (item) {
                if (item.selected === true) {
                    if (item) {
                        ids.push(item.id)
                    }
                }
            });
            if (ids.length > 0) {

                let variablesData = {}
                let tagValue = ''
                if (this.state.tags.length > 0) {
                    let data = []
                    await this.state.tags.map((res) => {
                        if (res) {
                            data.push(res.text)
                        }
                    })
                    let option = {
                        tagValues: data
                    }
                    if (((this.state.tableName === "transaction") ||
                        (this.state.tableName === "received_transaction"))) {
                        variablesData = {
                            tags: option,
                            transactionId: ids
                        }
                        tagValue = INSERT_TAG_TRANSACTION
                        this.InsetTagInTable(option, tagValue, variablesData)
                    } else {

                        // }
                        // if (this.state.tableName !== "transaction") {
                        variablesData = {
                            tags: option,
                            id: ids
                        }
                        switch (this.state.tableName) {
                            case "business_contact":
                                tagValue = INSERT_TAG_BUSINESS_CONTACT
                                break;
                            case "individual_contact":
                                tagValue = INSERT_TAG_INDIVIDUAL_CONTACT
                                break;
                            case "message_sms":
                            case "message_email":
                                tagValue = INSERT_TAG_MESSAGES
                                break;
                            default:
                                tagValue = ""
                                break;
                        }
                        this.InsetTagInTable(option, tagValue, variablesData)
                    }
                }
                else {
                    this.setState({ loading: false })
                    message.warning('Please Add tags')
                }
            }
            else {
                this.setState({ loading: false })
                message.warning('Please select records in list')
            }
        })
    }

    InsetTagInTable = (tagsvalue, mutationValue, paramsData) => {
        client.mutate({ mutation: mutationValue, variables: paramsData }).then((response) => {
            message.success('Tag added SuccessFully')
            this.setState({
                loading: false, tags: [], isOpenSaveTag: false,
                suggestions: [], tagName: []
            })
            this.handleCancel();
        })
            .catch(err => {
                this.setState({ loading: false })
                message.error('tag saved Failed in transaction')
            });
    }

    handleDelete = (i) => {
        const { tags } = this.state;
        this.setState({
            tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition = (tag) => {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }

    handleDrag = (tag, currPos, newPos) => {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }

    renderModalTitle = () => {
        return (
            <div style={{ display: 'flex' }}>
                {this.state.isEdit === true ?

                    <h6>{this.state.tagName}</h6> :
                    <h6>Add Tag</h6>
                }
            </div>
        )
    }


    render() {
        const { tags, suggestions, visible } = this.state;
        return (
            <div>
                <Modal
                    title={this.renderModalTitle()}
                    visible={visible}
                    footer={this.renderFooter()}
                    width={800}
                    onCancel={() => this.handleCancel('closeModal')}
                >
                    <Spin spinning={this.state.loading}>

                        <ReactTags
                            tags={tags}
                            suggestions={this.state.suggestions}
                            handleDelete={(i) => this.handleDelete(i)}
                            handleAddition={(i) => this.handleAddition(i)}
                            handleDrag={(i) => this.handleDrag(i)}
                            delimiters={delimiters}
                            readOnly={this.state.isFieldsReadOnly}
                        />

                    </Spin>
                </Modal>
            </div>
        )
    }
}

export default TagModal
