export default [
  {
    'id': 0,
    'handle': 'starred',
    'title': 'Profile',
    'icon': 'profile'
  },
  {
    'id': 1,
    'handle': 'important',
    'title': 'Team',
    'icon': 'team'
  },
  {
    'id': 2,
    'handle': 'dueDate',
    'title': 'Catalog',
    'icon': 'book'
  },
  {
    'id': 3,
    'handle': 'today',
    'title': 'Communication',
    'icon': 'calendar'
  },
  {
    'id': 4,
    'handle': 'deleted',
    'title': 'Transaction Types',
    'icon': 'delete'
  }
];
