import React, { Component } from "react";
import _, { cloneDeep } from 'lodash'

import { Form, Input, Icon, Button, Radio, Select, message, Row, Col, Checkbox } from 'antd';

import DynamicFields from '../../CreateTemplate/EcommerceFormdata/DynamicFields';


const { Option } = Select;

let initialData = {
    attributeName: '',
    attributeValue: '',
    showAttributeName: false,
    required: false,
    attributeType: ''
}

class EditPickupDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            PickupDetails: [],
            id: 0,
            deletedKeys: [],
        }
    };

    componentWillMount() {
        console.log(this.props.editData, 'editData')
        if(this.props.editData) {
            this.setState({ PickupDetails: this.props.editData })
        } else {
            if (this.state.PickupDetails.length === 0) {
                let data = this.state.PickupDetails
                data.push(initialData)
                this.setState({ PickupDetails: data, loading: false })
            }
        }
       
    }

    onAddRow = () => {
        let data = this.state.PickupDetails
        let initialData = {
            attributeName: '',
            attributeValue: '',
            showAttributeName: false,
            required: false,
            attributeType: ''
        }

        data.push(initialData)
        this.setState({ PickupDetails: data })
    }


    LoopItemsChange(PickupDetails) {
        this.setState({ PickupDetails}, () => {
            this.props.onPickupDetailEdit(PickupDetails);
        })
    }

    onRemove(PickupDetails) {
        this.setState({ PickupDetails});
    }


    render() {
        const { PickupDetails } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        getFieldDecorator('keys', { initialValue: [] });;
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
     console.log(PickupDetails, 'cutomerdetails')
        return (
            <Form
                onSubmit={this.handleSubmit}
            >
                <div >
                    <DynamicFields 
                    accessFrom='Header'
                    LoopItems={PickupDetails}
                    LoopItemsChange={this.LoopItemsChange.bind(this)}
                    onRemove={this.onRemove.bind(this)}
                    isEdit={this.props.isEdit ? true: false}
                    />
                </div>
                <div>
                    <div className="dashed-button-control" style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Item {...formItemLayoutWithOutLabel} >
                            <Button type="dashed" onClick={() => this.onAddRow()} style={{ width: '100px' }} >
                                <Icon id="icon-view" type="plus" />
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>

        );
    }

}


const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(EditPickupDetails);
export default WrappedDynamicFieldSet

