import firebase from 'firebase';
import {CONFIG} from '../../src/config/config';
import {getENVConfig} from '../util/common'

// Initialize Firebase
const config = {
  apiKey: getENVConfig(CONFIG.FIREBASE_API_KEY),
  authDomain: getENVConfig(CONFIG.AUTHDOMAIN),
  databaseURL: getENVConfig(CONFIG.DATABASEURL),
  projectId: getENVConfig(CONFIG.PROJECTID),
  storageBucket: getENVConfig(CONFIG.STORAGEBUCKET),
  messagingSenderId: getENVConfig(CONFIG.MESSAGINGSENDERID),
  domain: getENVConfig(CONFIG.DOMAIN),
  graphql: getENVConfig(CONFIG.GRAPHQL),
  websocket: getENVConfig(CONFIG.WEBSOCKET)
};

firebase.initializeApp(config);
const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/register',
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      // Invisible reCAPTCHA with image challenge and bottom left badge.
      recaptchaParameters: {
        type: 'image',
        size: 'invisible',
        badge: 'bottomleft',
      }
    },
  ],
  callbacks: {
    signInSuccessWithAuthResult: (data) => {
      console.log(data);
      // alert(data)

      return false;
    },
  },
};

const database = firebase.database();
const storage = firebase.storage();
export {
  firebase,
  storage,
  auth,
  database,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  uiConfig,
};
