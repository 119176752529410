import React, { Component } from "react";
import { Card, Icon, Menu, Tabs, Button, Input, message, Upload, Select, Spin } from "antd";
import { withRouter } from 'react-router-dom'
import PDFVIew from '../../routes/NewTemplate/index';
import VariableFields from './VariableFields'
import { renderToStaticMarkup } from 'react-dom/server';
import parse, { domToReact } from 'html-react-parser';
import CustomScrollbars from "util/CustomScrollbars";

import _ from 'lodash'
import Rules from './Rules';
import UploadFile from './UploadFile'
import { GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID } from '../../graphQl/subscriptions'
import client from '../../apollo/apollo';
import HeaderLayout from '../../components/CreateTemplate/HeaderLayout'
import EcommerceForm from '../EcommerceForm/index'
import UploadCsv from '../../components/contact/uploadCsv'
import { shortUrlDeeplinkUrlPrefix } from './const/index'

import {
    CREATE_NEW_TEMPLATE,
    UPDATE_TRANSACTION_TEMPLATE,
    UPDATE_FILTER_MASTER_DATA,
    INSERT_PRODUCT_CSV, ADD_TEMPLATE_MUTATION,
    DELETE_PRODUCTS
} from '../../graphQl/mutations';
import VariableFiledForTransaction from './VariableFiledForTransaction'
import Recipients from './Recipients'
import Product from './Product'
import gql from 'graphql-tag';
import { saveStatus, saveRules } from '../../gql/mutation/templateTransaction/index'
import { getENVConfig } from '../../util/common'
import { CONFIG } from '../../config/config';


const axios = require('axios');

const uuidv1 = require('uuid/v4');
const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Option } = Select;

let VariablesFromView = [];


class CreateTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.props.screenName === "TransactionList" ? "2" : "1",
            transactionData: {},
            templateData: {},
            templateId: '',
            templateViewData: "",
            Required: [],
            uiSChema: {},
            uploadedFile: {},
            shopVisible: false,
            shortUrlForDeeplinking: "",
            isRemovedOrderPdf: false,
            loading: false,
            csvFlag: false,
            leadData: {
                externalLink: false,
                displaySignedData: false
            },
            selectedForm: 'Editor',
            categoryParamsCsv: [],
            productParamsCsv: [],
            contactsDetails: []
            // activeTabs: "2",
        }
        this.variableRef = React.createRef();
        this.displayRef = React.createRef();
        this.previewRef = React.createRef();
    }

    componentDidMount() {
        if ((this.props.screenName === "TransactionList") || (this.props.screenName === "TemplateEdit")) {

            if (this.props && this.props.match && this.props.match.params && this.props.match.params.id) {
                this.setState({ templateId: this.props.match.params.id }, () => {

                    const variables = { "id": this.props.match.params.id };
                    client
                        .subscribe({
                            query: GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID,
                            variables: variables
                        })
                        .subscribe(
                            res => {
                                if (res && res.data && res.data.transaction_template.length > 0) {
                                    let data = res.data.transaction_template[0]
                                    let status = data.eligibleStatusList ? (this.props.screenName === "TransactionList" ? "2" : this.state.selectedForm === 'EcommerceForm' ? "2" : "1") : "4"
                                    this.setState({ templateData: data, activeTab: status })
                                }
                            },
                            err => {
                            }
                        );
                })
            }
        }
    }

    componentWillMount() {
        this.setState({ templateName: this.props.editData && this.props.editData.templateData.name });
        this.setState({ templatePublicUrl: this.props.editData && this.props.editData.templateData.templatepublicurl });
        this.setState({ selectedForm: this.props.editData ? this.props.editData.templateData.displayTemplateType ? this.props.editData.templateData.displayTemplateType : 'Editor' : 'Editor' });
        this.setState({ leadData: this.props.editData && this.props.editData.templateData.templateSettings }, () => {
        });
        this.setState({ shopVisible: this.props.editData && this.props.editData.templateData && this.props.editData.templateData.displayShop })
        this.setState({ displayTemplate: this.props.editData && this.props.editData.templateData && this.props.editData.templateData.displayTemplate });

        //for deeplink
        if (this.props.editData && this.props.editData.templateData && this.props.editData.templateData.shorturlForDeeplink) {
            let splitUrl = this.props.editData.templateData.shorturlForDeeplink.split(shortUrlDeeplinkUrlPrefix)
            if (splitUrl.length > 0) {
                this.setState({
                    shortUrlForDeeplinking: splitUrl[1] ? splitUrl[1] : ""
                }, () => {
                });
            }
        }
    }
    // onSave() {
    //     console.log(this.displayRef, this.state.activeTab, 'displayref')
    //     if (this.state.activeTab === '1') {
    //         var variables = this.variableRef.current.onSave();
    //         variables.JSONSchema.title = this.state.templateName;
    //         this.setState({ formDefinition: variables });
    //     }
    //     if (this.state.activeTab === '2') {
    //         var displayHtml = this.displayRef.current.onSave();
    //         this.setState({ displayTemplate: displayHtml });
    //     }
    //     if (this.state.activeTab === '3') {
    //         this.previewRef.current.onSave();
    //     }
    // }


    onDisplayChange(displayHtml, htmlData) {
        this.setState({ displayTemplate: displayHtml, templateViewData: htmlData });
        this.setState({ finalVal: this.props.editData ? this.props.editData.templateData.formDefinition.UISchema['ui:order'] : [] }, () => {
            this.setState({ duplicateVal: this.state.finalVal })
        });

        this.parseData();
    }


    parseData() {
        this.state.finalVal = [];
        this.state.properties = {};
        this.state.uiSChema = {};

        this.state.temp = [];
        //to parse varibales from html data
        var template = this.state.displayTemplate;
        if (!template) { template = `<p>no data</p>` }
        const options = {
            replace: ({ attribs, children }) => {
                if (!attribs) return;
                children.map((data, index) => {
                    if (data.data) {
                        var data = data.data;
                        var regex = /\[\[((\w+)]\])/g;
                        var variable = data.match(regex);
                        if (variable && variable.length > 0) {
                            variable.map((variableData, index) => {
                                this.state.temp.push(variableData);
                            })
                        }
                    }
                    if (data.name === 'input') {
                        var changeddata = data.attribs.name;
                        var updated_changeddata = changeddata.replace(/[^A-Z0-9]/ig, "");

                        // this.state.properties[changeddata] = data.attribs.type !== 'text' ? { type: 'boolean' } : { type: 'string' };
                        this.state.properties[updated_changeddata] = { type: 'string' };
                        this.state.temp.push(updated_changeddata);
                        this.state.uiSChema[updated_changeddata] = { "ui:widget": 'text' };
                    }
                    if (data.name === 'textarea') {
                        var data = data.attribs.name;
                        this.state.temp.push(data);
                    }
                })
                // }
            }
        }
        renderToStaticMarkup(parse(template, options));

        this.state.Values = this.state.temp.filter((x, i, a) => {
            return a.indexOf(x) == i;
        });
        this.state.Values.map((data, index) => {
            var updated = data.replace(/[^A-Z0-9]/ig, "");
            this.state.finalVal.push(updated);
            let property = {};
            if (this.state.properties[updated] && (this.state.properties[updated].type === 'boolean')) {
                property = {
                    type: this.state.properties[updated] ? this.state.properties[updated].type : 'string',
                    title: updated,
                    default: '',
                    enum: [data]
                };
            } else {
                property = {
                    type: this.state.properties[updated] ? this.state.properties[updated].type : 'string',
                    title: updated,
                    default: '',
                };
            }
            this.state.properties[updated] = property;
        });

        const tempArrr = []
        const notExistArr = []
        if (this.state.duplicateVal && this.state.duplicateVal.length > 0) {
            this.state.duplicateVal.map((val, index) => {
                if (_.includes(this.state.finalVal, val)) {
                    tempArrr.push(val)
                }
            })
            this.state.finalVal.map((val, index) => {
                if (_.includes(this.state.duplicateVal, val) === false) {
                    tempArrr.push(val)
                }
            })
        }

        if (notExistArr.length > 0) {
            notExistArr.map(val => {
                tempArrr.push(val)
            })
        }

        this.setState({ finalVal: tempArrr.length > 0 ? tempArrr : this.state.finalVal });
        const JsonSchema = {
            "type": "object",
            "required": this.state.Required,
            'properties': this.state.properties,
        };
        this.state.uiSChema["ui:order"] = this.state.finalVal ? this.state.finalVal : ['email, phoneNumber'];
        const formDef = {
            "UISchema": this.state.uiSChema,
            "formData": {},
            "JSONSchema": JsonSchema
        };

        this.setState({ variablesFetched: formDef });

        // this.props.saveVariables(formDef);
        // this.props.variablesParse(this.state.finalVal);
    }


    tabChanges(val) {
        this.setState({ activeTab: val })
    }

    onSubmit = async () => {
        console.log('onSubmit')
        if (this.state.templateViewData || this.state.templateData) {
            if (((this.state.variablesFetched && this.state.templateName) || (this.state.isRemovedOrderPdf === true))) {
                var variables = this.state.variablesFetched;
                variables.JSONSchema.title = this.state.templateName;
                var finalhtml = this.state.displayTemplate ? this.state.displayTemplate : this.state.templateData.displayTemplate;

                this.setState({ formDefinition: variables }, async () => {

                    // if ((this.props.from === 'Order' || this.props.from === 'order') && this.state.uploadedFile.name) {
                    //     const fileUrl = `OrderFiles/${this.state.uploadedFile.name}`;

                    //     const uploadTask = storage.ref(fileUrl).put(this.state.uploadedFile);

                    //     uploadTask.on(
                    //         'state_changed',
                    //         snapshot => {
                    //             // progress function
                    //         },
                    //         error => {
                    //             this.setState({ showLoading: true })
                    //             //error function
                    //         },
                    //         (uploaded) => {
                    //             storage
                    //                 .ref('OrderFiles')
                    //                 .child(this.state.uploadedFile.name)
                    //                 .getDownloadURL()
                    //                 .then(async (uploadUrl) => {
                    //                     this.state.attachedUrl.attachedUrl.push(uploadUrl);

                    //                     this.attributesUpdation(finalhtml).then((attribs, mutation_value, success_message) => {
                    //                         this.templateSave(attribs, mutation_value, success_message);
                    //                     })
                    //                 });
                    //         });
                    // } else {
                    this.attributesUpdation(finalhtml).then((attribs, mutation_value, success_message, type) => {
                        console.log(attribs, 'attribsattribsattribs')
                        this.templateSave(attribs, mutation_value, success_message, type);
                    })
                    // }

                });
            }
            else {
                message.warning('Please enter template name or change contents to proceed')
            }

        } else {
            message.warning('Please add content in template')
        }
    }

    attributesUpdation(finalhtml) {
        if (this.state.selectedForm === 'EcommerceForm') {
            if (!this.state.leadData.priceUnit) {
                this.state.leadData.priceUnit = "Rs";
            }
        }
        // if (this.props.editData) {
        //     this.state.leadData.fieldValues = this.props.editData.templateData.templateSettings.fieldValues && this.props.editData.templateData.templateSettings.fieldValues
        // }
        return new Promise((resolve, reject) => {
            var attributes = {};
            var mutation_value = '';
            var success_message = '';
            this.leadDataValid().then((isValid) => {
                console.log(isValid, 'isvalk')
                if (isValid) {
                    if (this.props.editData) {
                        attributes = {
                            id: this.props.editData.templateId,
                            name: this.state.templateName,
                            formDefinition: this.state.formDefinition,
                            UISchema: this.state.formDefinition.UISchema,
                            displayTemplate: finalhtml,
                            eligibleStatusList: this.state.templateData.eligibleStatusList,
                            signedStatus: this.state.templateData.signedStatus,
                            templateSettings: this.state.leadData,
                            templatepublicurl: this.state.templatePublicUrl,
                            displayTemplateType: this.state.selectedForm,
                            displayShop: this.state.shopVisible,
                            shorturlForDeeplink: this.state.shortUrlForDeeplinking ?
                                shortUrlDeeplinkUrlPrefix + this.state.shortUrlForDeeplinking : ""

                        }
                        console.log("attributes123", attributes)
                        mutation_value = UPDATE_TRANSACTION_TEMPLATE;
                        success_message = 'Template edited Successfully!';
                        resolve({ attributes: attributes, mutation_value: mutation_value, success_message: success_message, type: 'edit' });

                    } else {

                        let statusOption = {}
                        let signStatus = null
                        if (localStorage.getItem('transactionType') && localStorage.getItem('transactionType') === "order") {
                            // set default Status for order template and saved in template
                            let status = ["Created", "Submitted", "Shipped", "Pickup", "Delivered"]
                            statusOption = {
                                "status": status,
                                "initialStatus": "Created"

                            }
                            signStatus = "Submitted"
                        } else if (localStorage.getItem('transactionType') && localStorage.getItem('transactionType') === "contract") {
                            let status = ["Draft"]
                            statusOption = {
                                "status": status,
                                "initialStatus": "Draft"
                            }
                        } else if (localStorage.getItem('transactionType') && localStorage.getItem('transactionType') === "shipment") {
                            // set default Status for shipment template and saved in template
                            let status = ["Created", "ReadyForShipping", "InTransit", "OutForDelivery", "Delivered"]
                            statusOption = {
                                "status": status,
                                "initialStatus": "Created"
                            }
                        }
                        else {
                            statusOption = null
                            signStatus = null
                        }
                        console.log('statusOption123', statusOption)
                        attributes = {
                            name: this.state.templateName,
                            listingId: localStorage.getItem('selectedListingId'),
                            formDefinition: this.state.formDefinition,
                            type: localStorage.getItem('transactionType'),
                            createdBy: localStorage.getItem('uid'),
                            owner: localStorage.getItem('uid'),
                            UISchema: this.state.formDefinition.UISchema,
                            displayTemplate: finalhtml,
                            templateSettings: this.state.leadData,
                            templatepublicurl: this.state.templatePublicUrl,
                            displayTemplateType: this.state.selectedForm,
                            eligibleStatusList: statusOption,
                            signedStatus: signStatus,
                            displayShop: this.state.shopVisible,
                            shorturlForDeeplink: this.state.shortUrlForDeeplinking ?
                                shortUrlDeeplinkUrlPrefix + this.state.shortUrlForDeeplinking : ""
                        }
                        mutation_value = CREATE_NEW_TEMPLATE;
                        success_message = 'New Template created Successfully!';
                        resolve({ attributes: attributes, mutation_value: mutation_value, success_message: success_message, type: 'create' });
                    }
                } else {
                    message.warning('Some required fields are missing')
                }
            });
        });
    }

    leadDataValid() {
        return new Promise(async (resolve, reject) => {
            let isValid = true;
            if (this.state.selectedForm === 'EcommerceForm') {
                if (Object.keys(this.state.leadData.fieldValues).length > 0) {
                    Object.keys(this.state.leadData.fieldValues).forEach((key) => {
                        console.log(key, Object.keys(this.state.leadData.fieldValues).length, this.state.leadData.fieldValues[key], 'isvalid');
                        if (key === 'DeliveryPickupDetailValues') {
                            if ((this.state.leadData.fieldValues[key].selectedOption === "All" ||
                                this.state.leadData.fieldValues[key].selectedOption === "Delivery" ||
                                this.state.leadData.fieldValues[key].selectedOption === "Both" ||
                                this.state.leadData.fieldValues[key].selectedOption === "DeliverySeating") &&
                                this.state.leadData.fieldValues[key].DeliveryDetails === null) {
                                isValid = false;
                            }
                            if ((this.state.leadData.fieldValues[key].selectedOption === "All" ||
                                this.state.leadData.fieldValues[key].selectedOption === "Pickup" ||
                                this.state.leadData.fieldValues[key].selectedOption === "Both" ||
                                this.state.leadData.fieldValues[key].selectedOption === "PickupSeating") &&
                                this.state.leadData.fieldValues[key].PickUpDetails === null) {
                                isValid = false;
                            }
                            if ((this.state.leadData.fieldValues[key].selectedOption === "All" ||
                                this.state.leadData.fieldValues[key].selectedOption === "Seating" ||
                                this.state.leadData.fieldValues[key].selectedOption === "PickupSeating" ||
                                this.state.leadData.fieldValues[key].selectedOption === "DeliverySeating") &&
                                this.state.leadData.fieldValues[key].seatingDetails === null) {
                                isValid = false;
                            }

                        } else {
                            if (this.state.leadData.fieldValues[key] === null) {
                                isValid = false;
                            }
                        }
                    });
                    console.log(isValid, 'isvalid2')
                    resolve(isValid);
                } else {
                    resolve(isValid);
                }
            } else {
                resolve(isValid);
            }
        });
    }

    templateSave(mutationData) {
        client.mutate({ variables: mutationData.attributes, mutation: mutationData.mutation_value }).then(async (result) => {
            console.log('result1234', result)
            console.log('templateSave', mutationData)
            await message.success(mutationData.success_message);
            // const result = 0;
            // message.success(mutationData.success_message);

            var listingName = localStorage.getItem('selectedListingName');
            var listingId = localStorage.getItem('selectedListingId');
            var tabName = localStorage.getItem('transactionType');
            if (result && result.data
                && result.data.insert_transaction_template &&
                result.data.insert_transaction_template.returning &&
                result.data.insert_transaction_template.returning[0]) {
                this.setState({ createdTemplateId: result.data.insert_transaction_template.returning[0] })
            }

            if (mutationData.type === 'create') {

                //create message template/rules/status for ordre type templates
                if (tabName === "order" && this.state.createdTemplateId &&
                    this.state.createdTemplateId.id) {

                    let smsMsgTemplateid = ""
                    let emailMsgTemplateid = ""

                    let uid = localStorage.getItem('uid')

                    //msg template email object
                    let emailVariableObject = {
                        createdBy: uid,
                        title: this.state.createdTemplateId.name + ' ' + 'Email' + ' ' + 'Submitted',
                        content: `Thank you for placing the order. You can view the order #contract."here"#`,
                        templateType: 'EMAIL',
                        listingId: localStorage.getItem('selectedListingId'),
                        promOrTrans: "transactional",
                        senderId: "totelymail.com"
                    }

                    //email msg template mutation
                    client.mutate({ mutation: ADD_TEMPLATE_MUTATION, variables: emailVariableObject }).then((response) => {

                        if (response && response.data && response.data.insert_template &&
                            response.data.insert_template.returning &&
                            response.data.insert_template.returning[0] && response.data.insert_template.returning[0].id) {
                            emailMsgTemplateid = response.data.insert_template.returning[0].id
                        }

                        //msg template sms object
                        let smsVariableObject = {
                            createdBy: uid,
                            title: this.state.createdTemplateId.name + ' ' + 'SMS' + " " + 'Submitted',
                            content: `Thank you for placing the order. You can view the order #contract#`,
                            templateType: 'SMS',
                            listingId: localStorage.getItem('selectedListingId'),
                            promOrTrans: "transactional",
                            senderId: "PLIVO"
                        }
                        //sms msg template mutation
                        client.mutate({ mutation: ADD_TEMPLATE_MUTATION, variables: smsVariableObject }).then((response) => {

                            if (response && response.data && response.data.insert_template &&
                                response.data.insert_template.returning &&
                                response.data.insert_template.returning[0] && response.data.insert_template.returning[0].id) {
                                smsMsgTemplateid = response.data.insert_template.returning[0].id
                            }

                            //set default status and rules for template (store in status history nd rules table)
                            let status = ["Created", "Submitted", "Shipped", "Pickup", "Delivered"]
                            let statusHistory = []
                            let savedList = []

                            let ruleType = "statusChangeNotification"
                            status.map((res, index) => {
                                let option = {
                                    type: "CREATE",
                                    isDefaultYn: res === "Created" ? true : false,
                                    statusDesc: res,
                                    statusName: res,
                                    isChangeable: true,
                                    transactionTemplateId: this.state.createdTemplateId.id,
                                    signedStatus: "Submitted"
                                }
                                statusHistory.push(option)

                                let notification = {
                                    "SMS": res === "Submitted" ? [smsMsgTemplateid] : [],
                                    "EMAIL": res === "Submitted" ? [emailMsgTemplateid] : []
                                }

                                const tempPayload = {
                                    type: "CREATE",
                                    id: null,
                                    transactionTemplateId: this.state.createdTemplateId.id,
                                    status: res,
                                    createdBy: uid,
                                    ruleType: ruleType,
                                    notificationChannel: notification,
                                }
                                savedList.push(tempPayload);

                                if (status.length - 1 === index) {
                                    this.setStatusHistoryForOrder(statusHistory, savedList)
                                }
                            })

                        })
                            .catch(err => {
                                console.log('response123 sms', err)
                            });
                    })
                        .catch(err => {
                            console.log('response123 email', err)
                        });
                }

                //create message template/rules/status for ordre type templates
                if (tabName === "shipment" && this.state.createdTemplateId &&
                    this.state.createdTemplateId.id) {

                    let smsMsgTemplateid = ""
                    let emailMsgTemplateid = ""

                    let uid = localStorage.getItem('uid')

                    //set default status and rules for template (store in status history nd rules table)
                    let status = ["Created", "ReadyForShipping", "InTransit", "OutForDelivery", "Delivered"]
                    let statusHistory = []
                    let savedList = []

                    let ruleType = "statusChangeNotification"
                    status.map((res, index) => {
                        let option = {
                            type: "CREATE",
                            isDefaultYn: res === "Created" ? true : false,
                            statusDesc: res,
                            statusName: res,
                            isChangeable: true,
                            transactionTemplateId: this.state.createdTemplateId.id,
                            signedStatus: "Submitted"
                        }
                        statusHistory.push(option)

                        let notification = {
                            "SMS": res === "Submitted" ? [smsMsgTemplateid] : [],
                            "EMAIL": res === "Submitted" ? [emailMsgTemplateid] : []
                        }

                        const tempPayload = {
                            type: "CREATE",
                            id: null,
                            transactionTemplateId: this.state.createdTemplateId.id,
                            status: res,
                            createdBy: uid,
                            ruleType: ruleType,
                            notificationChannel: notification,
                        }
                        savedList.push(tempPayload);

                        if (status.length - 1 === index) {
                            this.setStatusHistoryForOrder(statusHistory, savedList)
                        }
                    })

                }

                if (tabName === "contract" && this.state.createdTemplateId &&
                    this.state.createdTemplateId.id) {
                    let status = ["Draft"]
                    let statusHistory = []
                    status.map((res, index) => {
                        let option = {
                            type: "CREATE",
                            isDefaultYn: true,
                            statusDesc: res,
                            statusName: res,
                            isChangeable: true,
                            transactionTemplateId: this.state.createdTemplateId.id,
                            signedStatus: "Submitted"
                        }
                        statusHistory.push(option)
                    })

                    this.setStatusHistoryForOrder(statusHistory, [])

                }

                if (
                    this.state.createdTemplateId &&
                    this.state.createdTemplateId.id &&
                    this.state.categoryParamsCsv.length > 0 &&
                    this.state.productParamsCsv.length > 0
                ) {
                    this.saveCsvData(tabName, listingName, this.state.createdTemplateId)

                }
                else {
                    if (listingName) {
                        if (this.state.createdTemplateId) {
                            this.props.history.push({
                                pathname: `/app/console/${listingName}/transactions/${this.state.createdTemplateId.id}`,
                                state: { fromTrans: true, tabName: tabName, templatedata: this.state.createdTemplateId }
                            });
                        }
                    }
                }



                //update filter criteria data when editing template
                if (this.props.editData) {

                    //filter criteria fields
                    let value = {
                        formDefinition: this.state.formDefinition,
                        eligibleStatusList: this.state.templateData.eligibleStatusList,
                        createdAt: "",
                        updatedAt: "",
                        assignedTo: "",
                        linkCount: "",
                        linkCountStatus: '',
                        type: ''
                    }

                    let variablesData = {
                        tableName: "transaction",
                        listingId: localStorage.getItem('selectedListingId'),
                        templateId: this.props.editData.templateId,
                        filterCriteria: value,
                    }

                    client.mutate({ mutation: UPDATE_FILTER_MASTER_DATA, variables: variablesData }).then((response) => {
                    })
                        .catch(err => {
                            message.error('Filter saved Failed')
                            this.setState({ loading: false })
                        });

                }

                // else if (this.state.templateData.id) {
                //     // this.props.listBack()
                //     this.props.history.push({
                //         pathname: `/app/console/${listingName}/transactions/${this.state.templateData.id}`,
                //         state: { fromtrans: 'Contract', tabName: tabName, templatedata: this.state.templateData }
                //     });
                // }
            }
        }).catch((err) => {
            message.error("Template creation failed");
        });
    }



    setStatusHistoryForOrder = (payload, rulesList) => {
        const mutation = gql`${saveStatus(payload)}`
        try {
            client.mutate({ mutation }).then((response) => {
                if (rulesList.length > 0) {
                    const mutation = gql`${saveRules(rulesList)}`
                    try {
                        client.mutate({ mutation }).then((response) => {
                            // message.success('Rules Saved SuccessFully')
                        })
                            .catch(err => {
                                this.setState({ loading: false })
                            });
                    } catch (e) {
                        this.setState({ loading: false })
                    }
                }
            })
                .catch(err => {
                    console.log('createdTemplateId123 err', err)
                });
        } catch (e) {
        }
    }


    saveVariablesNew(variables, RequiredValues, leadData, publicUrl, shopFlag, deeplinkUrl) {
        console.log("deeplinkUrl122", deeplinkUrl)
        this.setState({
            Required: RequiredValues, leadData: leadData,
            templatePublicUrl: publicUrl, shopVisible: shopFlag, shortUrlForDeeplinking: deeplinkUrl
        })
        if (this.props.editData) {
            this.props.editData.templateData.formDefinition.UISchema['ui:order'] = variables;
        }
        this.state.finalVal = [];
        this.state.properties = {};
        this.state.temp = [];
        //to parse varibales from html data
        var template = this.state.displayTemplate ? this.state.displayTemplate : this.state.templateData.displayTemplate;
        if (!template) { template = `<p>no data</p>` }

        const options = {
            replace: ({ attribs, children }) => {
                if (!attribs) return;
                children.map((data, index) => {
                    if (data.data) {
                        var data = data.data;
                        var regex = /\[\[((\w+)]\])/g;
                        var variable = data.match(regex);
                        if (variable && variable.length > 0) {
                            variable.map((variableData, index) => {
                                this.state.temp.push(variableData);
                            })
                        }
                    }
                    if (data.name === 'input') {
                        var changeddata = data.attribs.name;
                        // this.state.properties[changeddata] = data.attribs.type !== 'text' ? { type: 'boolean' } : { type: 'string' };
                        var updated_changeddata = changeddata.replace(/[^A-Z0-9]/ig, "");

                        this.state.properties[updated_changeddata] = { type: 'string' };
                        this.state.temp.push(updated_changeddata);
                        this.state.uiSChema[updated_changeddata] = { "ui:widget": data.attribs.type };
                    }
                    if (data.name === 'textarea') {
                        var data = data.attribs.name;
                        this.state.temp.push(data);
                    }
                })
                // }
            }
        }
        renderToStaticMarkup(parse(template, options));

        this.state.Values = this.state.temp.filter((x, i, a) => {
            return a.indexOf(x) == i;
        });
        this.state.Values.map((data, index) => {
            var updated = data.replace(/[^A-Z0-9]/ig, "");
            this.state.finalVal.push(updated);
            let property = {};
            if (this.state.properties[updated] && (this.state.properties[updated].type === 'boolean')) {
                property = {
                    type: this.state.properties[updated] ? this.state.properties[updated].type : 'string',
                    title: updated,
                    default: '',
                    enum: [data]
                };
            } else {
                property = {
                    type: this.state.properties[updated] ? this.state.properties[updated].type : 'string',
                    title: updated,
                    default: '',
                };
            }
            this.state.properties[updated] = property;
        });

        const JsonSchema = {
            "type": "object",
            "required": RequiredValues,
            'properties': this.state.properties,
        };
        const formDef = {
            "UISchema":
            {
                "ui:order": variables
            },
            "formData": {},
            "JSONSchema": JsonSchema
        };
        this.setState({ variablesFetched: formDef });
    }

    variablesParsed(VariablesFromTemplate) {
        console.log('caleedhere')
        VariablesFromView = VariablesFromTemplate;
    }

    getFormData = (formData) => {
        this.setState({ transactionData: formData }, () => {
        })
    }

    gettingFormData = (formData) => {
        this.setState({ transactionData: formData }, () => {
        })
    }

    handleChange = (event) => {
        const file = event.fileList[0] && event.fileList[0].originFileObj && event.fileList[0].originFileObj;
        if (file) {
            this.setState({ uploadedFile: file }, () => {
                this.parseData()
            })
        }
    }

    onRemove() {
        this.setState({ uploadedFile: {} });
    }


    formOptionSelected(e) {
        console.log(e);
        this.setState({ selectedForm: e })
        if (e === 'EcommerceForm' || e === 'ShipmentForm') {
            this.setState({ activeTab: "2" })
        }
    }

    saveCsvData = (tabName, listingName, template) => {
        const { categoryParamsCsv, productParamsCsv } = this.state
        if (template && template.id && categoryParamsCsv.length > 0 && productParamsCsv.length > 0) {

            let tempProduct = productParamsCsv.map(val => {
                val.transaction_template_id = template.id
                return val
            })

            let tempCategory = categoryParamsCsv.map(val => {
                val.transaction_template_id = template.id
                return val
            })

            console.log('tempProduct', tempProduct, tempCategory)

            let params = {
                categories: tempCategory,
                products: tempProduct
            }
            console.log('saveCsvData', params)
            this.setState({ loading: true }, () => {
                var url = `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/insertProductCatalog`;

                let config = { method: 'POST', url: url, data: params }

                axios(config)
                    .then(res => {
                        console.log('saveCsvData response', res)
                        this.setState({ loading: false })
                        this.props.history.push({
                            pathname: `/app/console/${listingName}/transactions/${template.id}`,
                            state: { fromTrans: true, tabName: tabName, templatedata: template }
                        });
                        message.success('Products added successfully')

                    })
                    .catch(err => {
                        console.log('csv upload err', err)
                        this.setState({ loading: false })
                    })
            })
        }
    }

    csvDetails(productParamsCsv, categoryParamsCsv) {
        console.log(productParamsCsv, categoryParamsCsv, 'calvalvlalv')
        this.setState({
            productParamsCsv: productParamsCsv,
            categoryParamsCsv: categoryParamsCsv
        });
    }

    removeCsv = () => {
        console.log('removeCsv')
    }


    setLoader = (val) => {
        console.log('setLoader', val)
    }

    renderUploadButton = () => {

        const uploadButton = (
            <div>
                <Icon id="icon-view" type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                accept=".pdf"
                onChange={this.handleChange}
                onRemove={this.onRemove.bind(this)}
            >
                {this.state.uploadedFile.name ? null : uploadButton}
            </Upload>
        )
    }

    onRemoveOrderPdf = () => {
        this.setState({ isRemovedOrderPdf: true }, () => {
            this.parseData()
        })
    }

    ecomSubmit() {
        let ecommerceValues = this.getFormData();
        console.log(ecommerceValues, 'ecommerceValues')
        return ecommerceValues;
    }

    contactsAdded(contacts) {
        this.setState({ contactsDetails: contacts });
    }

    settingsUpated() {
        console.log('settingsUpated')
        this.setState({ activeTab: '5' })
    }

    render() {
        let style1 = { width: '100%' }
        let style2 = {}
        console.log('templateData123', this.state.templateData)
        const templateId = this.props && this.props.editData && this.props.editData.templateId ? this.props.editData.templateId : null
        // const { templateData, activeTabs} = this.state
        return (
            <div>
                <Spin spinning={this.state.loading} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                        <div>
                            {
                                this.props.screenName !== "TransactionList" &&
                                <HeaderLayout onSubmit={this.onSubmit.bind(this)} templateId={templateId}
                                    disable={this.state.isRemovedOrderPdf === false ?
                                        this.state.variablesFetched && this.state.templateName
                                        : this.state.isRemovedOrderPdf
                                    }
                                // disable={this.state.isRemovedOrderPdf}
                                />
                            }

                            <CustomScrollbars id="variable-form-scroll" style={{ height: this.props.screenName === "TransactionList" && 'calc(100vh - 74px)' }}>
                                <Card className="gx-card" style={this.props.screenName === "TransactionList" ? style1 : style2}>
                                    {
                                        (this.props.screenName === "TransactionList" && this.state.templateData &&
                                            this.state.templateData.displayTemplateType !== "EcommerceForm") &&
                                        <UploadFile
                                        />
                                    }
                                    {
                                        this.props.screenName !== "TransactionList" &&
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'baseline' }}>
                                            {/* <Button onClick={() => this.onSubmit()}>Save</Button> */}
                                            <p style={{ fontWeight: 500 }}>Template Name:</p>
                                            <Input
                                                required
                                                style={{ width: '80%', marginLeft: 10 }}
                                                placeholder="Enter a distinct name for the template"
                                                value={this.state.templateName}
                                                onChange={(event) => { this.parseData(); this.setState({ templateName: event.target.value }) }}
                                                margin="none" />
                                        </div>
                                    }
                                    <p>{this.state.activeTabs}</p>
                                    {(localStorage.getItem('transactionType') === 'order'|| localStorage.getItem('transactionType') === 'shipment') && this.props.screenName !== "TransactionList" && !this.props.editData &&

                                        <div>
                                            <span>Select Template Type :</span>
                                            <Select placeholder="Rich Text Editor" defaultValue={this.state.selectedForm} style={{ width: 200, marginLeft: '1%' }} onChange={(e) => { this.formOptionSelected(e) }}>
                                                <Option value="Editor">Rich Text Editor</Option>
                                                <Option value="EcommerceForm">Ecommerce Form</Option>
                                                <Option value="ShipmentForm">Shipment Form</Option>
                                            </Select>
                                            {/* {this.state.selectedForm === 'EcommerceForm' &&
                                    <UploadCsv
                                        getData={this.getCsvData}
                                        loader={this.setLoader}
                                        screen='createTemplate'
                                        remove={this.removeCsv}
                                    />
                                } */}
                                            {/* <p onClick={() => this.getCsvData()}>test</p> */}
                                        </div>
                                    }
                                    <Tabs style={{ marginTop: '2%' }} activeKey={this.state.activeTab} onChange={(val) => this.tabChanges(val)}>

                                        {
                                            this.props.screenName !== "TransactionList" &&
                                            <TabPane tab={this.state.selectedForm === 'EcommerceForm' ? "Preview" : "Create"} key="1">
                                                <div>

                                                    {this.state.selectedForm === 'Editor' &&
                                                        <PDFVIew ref={this.displayRef}
                                                            screenName={this.props.screenName}
                                                            from={this.props.from}
                                                            formData={this.state.transactionData}
                                                            onDisplayChangeProp={this.onDisplayChange.bind(this)}
                                                            VariablesFromTemplate={VariablesFromView}
                                                            activeTabIndex={this.state.activeTab}
                                                            templateData={this.state.templateData}
                                                        />
                                                    }
                                                    {this.state.selectedForm === 'EcommerceForm' &&
                                                        <EcommerceForm
                                                            listingId={localStorage.getItem('selectedListingId')}
                                                            templateId={this.props.screenName === "TemplateEdit" ? templateId ? templateId : "" : ""}
                                                            accessingFrom={'CreateTemplate'} />
                                                    }
                                                </div>
                                            </TabPane>
                                        }
                                        <TabPane tab="Form Data" key="2">
                                            {
                                                this.props.screenName === "TransactionList" ?
                                                    <VariableFiledForTransaction
                                                        getFormData={this.getFormData}
                                                        gettingFormData={this.gettingFormData}
                                                        formDataValue={this.state.transactionData}
                                                        templateName={this.props.templateName}
                                                        callBack={this.props.listBack}
                                                        ecomSubmitted={this.ecomSubmit}
                                                        contactsAdded={this.contactsAdded.bind(this)}
                                                    /> :
                                                    <VariableFields onRef={ref => (this.variableRef = ref)}
                                                        displayHtml={this.state.displayTemplate}
                                                        variablesParse={this.variablesParsed.bind(this)}
                                                        editData={this.props.editData}
                                                        saveVariables={this.saveVariablesNew.bind(this)}
                                                        selectedForm={this.state.selectedForm}
                                                        csvDetails={this.csvDetails.bind(this)}
                                                    />
                                            }

                                        </TabPane>
                                        {
                                            this.props.screenName === "TransactionList" &&
                                            <TabPane tab={this.state.templateData.displayTemplateType === "EcommerceForm" ? "Preview" : "Create"} key="3">
                                                {/* {Object.keys(this.state.transactionData).length>0 && */}
                                                {this.state.templateData.displayTemplateType === "EcommerceForm" ?
                                                    <EcommerceForm
                                                        listingId={localStorage.getItem('selectedListingId')}
                                                        templateId={this.state.templateData ? this.state.templateData.id : ""}
                                                        getCurrentPoint={getFormData => { this.getFormData = getFormData }}
                                                        contactsDetails={this.state.contactsDetails.length > 0 && this.state.contactsDetails}
                                                    />
                                                    :
                                                    <PDFVIew ref={this.displayRef}
                                                        screenName={this.props.screenName}
                                                        from={this.props.from}
                                                        formData={this.state.transactionData}
                                                        onDisplayChangeProp={this.onDisplayChange.bind(this)}
                                                        VariablesFromTemplate={VariablesFromView}
                                                        activeTabIndex={this.state.activeTab}
                                                        templateData={this.state.templateData}
                                                    />
                                                }
                                                {/* } */}
                                            </TabPane>
                                        }
                                        {
                                            this.props.screenName === "TemplateEdit" &&
                                            <TabPane tab="Rules" key="4">
                                                <div>
                                                    <Rules
                                                        newTemplateId={this.props.screenName === "TemplateEdit" ? this.state.templateData : this.state.createdTemplateId}
                                                        screen={this.props.screenName}
                                                        templateId={this.state.templateId}
                                                    />

                                                    {/* } */}
                                                </div>
                                            </TabPane>
                                        }

                                        {localStorage.getItem('transactionType') === 'order' && this.props.screenName === "TemplateEdit" && this.state.selectedForm === 'EcommerceForm' &&
                                            <TabPane tab="Product" key="5">
                                                <Product
                                                    templateId={this.state.templateId}
                                                    editData={this.props.editData}
                                                // settingsUpate={this.settingsUpated.bind(this)}
                                                />
                                            </TabPane>
                                        }

                                    </Tabs>
                                </Card>
                            </CustomScrollbars>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }

};

export default withRouter((CreateTemplate));
