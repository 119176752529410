import React, { Component } from "react";
import gql from 'graphql-tag'
import { GET_LISTING_PRODUCTS_CATEGORIES_SUB } from '../../../graphQl/subscriptions'
import {
    LISTINGDETAILS
} from '../../../graphQl/queries';

import client from '../../../apollo/apollo'
import { Collapse } from 'antd';
import CustomerDetails from './CustomerDetails';
import DocumentSectionDetails from './DocumentSection';
import OrderDetails from './OrderDetails';
import Taxes from './Taxes';
import SellerNotification from './SellerNotification';
import PaymentDetails from './PaymentDetails';
import TermsConditions from './TermsConditions';
import DeliveryPickup from './DeliveryPickup';
import LocalizationDetails from './LocalizationDetails';
import HeaderDetails from './HeaderDetails';
import DiscountPromotions from './DiscountandPromotions';

const { Panel } = Collapse;


class EcommerceFormData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fieldValues: {},
            templateId: '',
            listingDetail: {}
        }
    };

    componentWillMount() {
        console.log(this.props, 'this.props.editData');
        if (this.props.editData) {
            this.setState({ fieldValues: this.props.editData.fieldValues });
        }
        if (this.props.templateId) {
            this.setState({ templateId: this.props.templateId }, () => {
            })
        }

        if (localStorage.getItem('selectedListingId')) {
            client.query({
                query: LISTINGDETAILS,
                variables: { listingId: localStorage.getItem('selectedListingId') }
            })
                .then((res) => {
                    console.log(res, 'res.data.transaction_template[0]')
                    if (res && res.data && res.data.listing.length > 0) {
                        console.log(res.data.listing[0].phoneNumber, 'res.data.transaction_template[0]')
                        this.setState({
                            listingDetail: res.data.listing[0],
                        })
                    }
                }).catch((err) => {
                    console.log(err, 'erer')
                });
        }
    }

    customerDetailSave = async (customerDetailsValues, type) => {

        let customerDetailsDeletedRemoved = [];
        if (customerDetailsValues && customerDetailsValues.length > 0) {
            customerDetailsDeletedRemoved = customerDetailsValues.filter((i) => { console.log(i, i.isDelete, 'ifromdet'); return i.isDelete !== true });
        } else {
            customerDetailsDeletedRemoved = customerDetailsValues ? customerDetailsValues : []
        }

        console.log(type, 'typecustsave')

        if (type) {
            if (type === 'Transaction') {
                this.state.fieldValues.transactionSectionDetails = await customerDetailsDeletedRemoved;
            } else if (type === 'Document') {
                this.state.fieldValues.documentSectionDetails = await customerDetailsDeletedRemoved;
            }
        } else {
            this.state.fieldValues.customerDetails = await customerDetailsDeletedRemoved;
        }

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }


    documentDetailsave = async (customerDetailsValues, type) => {

        let customerDetailsDeletedRemoved = [];
        if (customerDetailsValues && customerDetailsValues.length > 0) {
            customerDetailsDeletedRemoved = customerDetailsValues.filter((i) => { console.log(i, i.isDelete, 'ifromdet'); return i.isDelete !== true });
        } else {
            customerDetailsDeletedRemoved = customerDetailsValues ? customerDetailsValues : []
        }

        this.state.fieldValues.documentSectionDetails = await customerDetailsDeletedRemoved;


        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }


    orderDetailSave = async (orderDetailsValues) => {
        let orderDetailsValuesDeletedRemoved = [];
        if (orderDetailsValues && orderDetailsValues.length > 0) {
            orderDetailsValuesDeletedRemoved = orderDetailsValues.filter((i) => { console.log(i, i.isDelete, 'ifromdet'); return i.isDelete !== true });
        } else {
            orderDetailsValuesDeletedRemoved = orderDetailsValues
        }


        this.state.fieldValues.orderDetails = await orderDetailsValuesDeletedRemoved;
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    taxesDetailSave = async (taxesDetailsValues) => {
        console.log(taxesDetailsValues, 'taxesDetailsValues')
        let taxesDetailsValuesDeletedRemoved = [];
        if (taxesDetailsValues && taxesDetailsValues.length > 0) {
            taxesDetailsValuesDeletedRemoved = taxesDetailsValues.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            taxesDetailsValuesDeletedRemoved = taxesDetailsValues
        }

        this.state.fieldValues.taxesDetailsValues = await taxesDetailsValuesDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    taxesValuesSave = async (taxesValues) => {
        console.log(taxesValues, 'taxesValues')
        this.state.fieldValues.taxesValues = await taxesValues;
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    taxLevelSave = async (taxLevel) => {
        console.log(taxLevel, 'taxLevel')
        this.state.fieldValues.taxLevel = await taxLevel;
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }


    notificationDetailSave = async (notificationDetailsValues) => {
        let notificationDetailsValuesDeletedRemoved = [];
        if (notificationDetailsValues && notificationDetailsValues.length > 0) {
            notificationDetailsValuesDeletedRemoved = notificationDetailsValues.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            notificationDetailsValuesDeletedRemoved = notificationDetailsValues
        }

        this.state.fieldValues.sellerNotificationDetailsValues = await notificationDetailsValuesDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    paymentDetailSave(paymentDetailsValues) {
        this.state.fieldValues.paymentDetailsValues = paymentDetailsValues;
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    termsConditionsDetailSave(termsConditionsDetailValues) {
        this.state.fieldValues.termsConditionsDetailValues =
            termsConditionsDetailValues &&
                termsConditionsDetailValues.termsConditions ?
                termsConditionsDetailValues.termsConditions : "";
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    DeliverypickupDetailSave = async (DeliverypickupDetailValues) => {
        let DeliveryDetailValuesDeletedRemoved = [];
        console.log(DeliverypickupDetailValues, 'DeliverypickupDetailValues')
        let PickupDetailValuesDeletedRemoved = [];
        let seatingDetailValuesDeletedRemoved = [];

        let DeliverypickupDetailValuesCombined = {};

        if (DeliverypickupDetailValues.DeliveryDetails && DeliverypickupDetailValues.DeliveryDetails.length > 0) {
            DeliveryDetailValuesDeletedRemoved = DeliverypickupDetailValues.DeliveryDetails.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            DeliveryDetailValuesDeletedRemoved = DeliverypickupDetailValues.DeliveryDetails
        }

        if (DeliverypickupDetailValues.PickUpDetails && DeliverypickupDetailValues.PickUpDetails.length > 0) {
            PickupDetailValuesDeletedRemoved = DeliverypickupDetailValues.PickUpDetails.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });

        } else {
            PickupDetailValuesDeletedRemoved = DeliverypickupDetailValues.PickUpDetails
        }

        if (DeliverypickupDetailValues.seatingDetails && DeliverypickupDetailValues.seatingDetails.length > 0) {
            seatingDetailValuesDeletedRemoved = DeliverypickupDetailValues.seatingDetails.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });

        } else {
            seatingDetailValuesDeletedRemoved = DeliverypickupDetailValues.seatingDetails
        }


        DeliverypickupDetailValuesCombined.selectedOption = DeliverypickupDetailValues.selectedOption;
        DeliverypickupDetailValuesCombined.DeliveryDetails = DeliveryDetailValuesDeletedRemoved;
        DeliverypickupDetailValuesCombined.PickUpDetails = PickupDetailValuesDeletedRemoved;
        DeliverypickupDetailValuesCombined.seatingDetails = seatingDetailValuesDeletedRemoved;


        this.state.fieldValues.DeliveryPickupDetailValues = await DeliverypickupDetailValuesCombined;
        console.log(this.state.fieldValues, 'DeliverypickupDetailValues2')
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    HeaderDetailSave = async (HeaderDetailValues) => {
        let HeaderDetailValuesDeletedRemoved = [];
        if (HeaderDetailValues && HeaderDetailValues.length > 0) {
            HeaderDetailValuesDeletedRemoved = HeaderDetailValues.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            HeaderDetailValuesDeletedRemoved = HeaderDetailValues
        }
        console.log(this.state.fieldValues, 'this.state.fieldValues')

        this.state.fieldValues.HeaderDetailValues = await HeaderDetailValuesDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }

    localizationDetailsave = async (LocalizationDetailValues) => {
        let LocalizationDetailDeletedRemoved = [];
        if (LocalizationDetailValues && LocalizationDetailValues.length > 0) {
            LocalizationDetailDeletedRemoved = LocalizationDetailValues.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            LocalizationDetailDeletedRemoved = LocalizationDetailValues
        }

        this.state.fieldValues.LocalizationDetailValues = await LocalizationDetailDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        });
    }

    DiscountPromotionsSave = async (discountsPromotionsValues) => {
        let discountDetailDeletedRemoved = [];
        if (discountsPromotionsValues && discountsPromotionsValues.length > 0) {
            discountDetailDeletedRemoved = discountsPromotionsValues.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            discountDetailDeletedRemoved = discountsPromotionsValues
        }

        this.state.fieldValues.DiscountsPromotionsValues = await discountDetailDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        });
    }


    HeaderImageSave = async (HeaderImage) => {
        this.state.fieldValues.HeaderImage = await HeaderImage;
        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.ecommerceFormDataSaved(this.state.fieldValues);
        })
    }


    render() {
        console.log(this.props, this.state, 'this.state.fieldValues');

        return (
            <div>
                {this.props && this.props.formType === 'ShipmentForm' ?
                    <Collapse>
                        <Panel header="Header Details Section" key="1">
                            <HeaderDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues} HeaderDetailSave={this.HeaderDetailSave.bind(this)} HeaderImageSave={this.HeaderImageSave.bind(this)} />
                        </Panel>
                        <Panel header="Transaction Section" key="2">
                            <CustomerDetails isEdit={this.props.editData ? true : false}
                                editData={this.state.fieldValues && this.state.fieldValues.transactionSectionDetails}
                                customerDetailSave={this.customerDetailSave.bind(this)}
                                listingDetail={this.state.listingDetail}
                                section={'Transaction'} />
                        </Panel>
                        <Panel header="Document Section" key="3">
                            <DocumentSectionDetails isEdit={this.props.editData ? true : false}
                                editData={this.state.fieldValues && this.state.fieldValues.documentSectionDetails}
                                documentDetailsave={this.documentDetailsave.bind(this)}
                                listingDetail={this.state.listingDetail}
                            />

                        </Panel>

                    </Collapse>
                    :
                    <Collapse>
                        <Panel header="Header Details Section" key="1">
                            <HeaderDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues} HeaderDetailSave={this.HeaderDetailSave.bind(this)} HeaderImageSave={this.HeaderImageSave.bind(this)} />
                        </Panel>
                        <Panel header="Customer Details Section" key="2">
                            <CustomerDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.customerDetails}
                                customerDetailSave={this.customerDetailSave.bind(this)}
                                listingDetail={this.state.listingDetail} />
                        </Panel>
                        <Panel header="Shop Details Section" key="3">
                            <OrderDetails
                                isEdit={this.props.editData ? true : false}
                                editData={this.state.fieldValues && this.state.fieldValues.orderDetails}
                                orderDetailSave={this.orderDetailSave.bind(this)}
                                csvDetails={this.props.csvDetails}
                                templateId={this.state.templateId}
                                uploadProductImage={this.props.uploadProductImage}
                            />
                        </Panel>
                        <Panel header="Taxes Section" key="4">
                            <Taxes isEdit={this.props.editData ? true : false}
                                editData={this.state.fieldValues && this.state.fieldValues}
                                taxLevelSave={this.taxLevelSave.bind(this)}
                                taxesDetailSave={this.taxesDetailSave.bind(this)}
                                taxesValuesSave={this.taxesValuesSave.bind(this)} />
                        </Panel>
                        <Panel header="Seller Notification Section" key="5">
                            <SellerNotification isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.sellerNotificationDetailsValues} notificationDetailSave={this.notificationDetailSave.bind(this)} />
                        </Panel>
                        <Panel header="Payment Details Section" key="6">
                            <PaymentDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.paymentDetailsValues} paymentDetailSave={this.paymentDetailSave.bind(this)} />
                        </Panel>
                        <Panel header="Terms and Condition Section" key="7">
                            <TermsConditions isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.termsConditionsDetailValues} termsConditionsDetailSave={this.termsConditionsDetailSave.bind(this)} />
                        </Panel>
                        <Panel header="Delivery Details" key="8">
                            <DeliveryPickup isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.DeliveryPickupDetailValues} DeliverypickupDetailSave={this.DeliverypickupDetailSave.bind(this)} />
                        </Panel>
                        <Panel header="Discounts and Promotions Section" key="9">
                            <DiscountPromotions isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.DiscountsPromotionsValues} DiscountPromotionsSave={this.DiscountPromotionsSave.bind(this)} />
                        </Panel>
                        <Panel header="Localization Section" key="10">
                            <LocalizationDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.LocalizationDetailValues} localizationDetailsave={this.localizationDetailsave.bind(this)} />
                        </Panel>
                    </Collapse>
                }
            </div>
        );
    }

}
export default EcommerceFormData;

