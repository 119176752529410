import React, { Component } from 'react'
import client from '../../apollo/apollo'
import TransactionTemplateView from '../../components/TransactionTemplateView/index'
import { GET_SHORT_URL } from '../../graphQl/queries'

export class ReceivedContract extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transactionId: '',
            shortUrl: ''
        }
    }

    componentDidMount() {
        console.log('componentDidMount', this.props.todo.id)
        if (this.props && this.props.todo && this.props.todo.id) {
            this.setState({ transactionId: this.props.todo.id }, () => {
                this.getShortUrl()
            })
        }
    }

    getShortUrl = () => {
        const { transactionId } = this.state
        console.log('getShortUrl', transactionId)
        client
            .query({
                query: GET_SHORT_URL,
                variables: {
                    "transactionId": transactionId
                }
            })
            .then(
                response => {
                    console.log('CheckUser', response.data.short_url[0].shortURL)
                    if (response.data && response.data.short_url[0] && response.data.short_url[0].shortURL) {
                        this.setState({ shortUrl: response.data.short_url[0].shortURL }, () => {
                            console.log('shortUrl', this.state.shortUrl)
                        })
                    }
                })
            .catch(err => {
                console.log(err)
            })
    }

    render() {
        const { shortUrl } = this.state
        return (
            <div>
                {shortUrl &&
                    <TransactionTemplateView
                        screenName={'receivedContract'}
                        shortUrlId={shortUrl}
                    />
                }
            </div>
        )
    }
}

export default ReceivedContract
