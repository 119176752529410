
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import gql from 'graphql-tag';

import { split, concat } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from "apollo-link-context";
import {CONFIG} from '../config/config';
import {getENVConfig} from '../util/common'

/*authored by @akaDPR-gitHub*/



/*  The HTTP Client - for hasura-graphQl  */
const httpLink = new HttpLink({ 
  uri: getENVConfig(CONFIG.GRAPHQL), 
  headers: {'x-hasura-admin-secret':getENVConfig(CONFIG.GRAPHQL_PASS),'x-hasura-role':'admin'}
});
/*  The WebSocket  Client - for hasura-graphQl  */
const wsLink = new WebSocketLink ({
  uri: getENVConfig(CONFIG.WEBSOCKET),
  options: {
    reconnect: true,
    connectionParams: {headers:{'x-hasura-admin-secret':getENVConfig(CONFIG.GRAPHQL_PASS) ,'x-hasura-role':'admin'}},
  },
});



/* Splitter to split connections */
const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);


const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
