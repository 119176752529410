import React, { Component } from "react";
import {
    Select, DatePicker, Input, InputNumber, Modal, Button, message, Spin, Form,
    Icon, Radio
} from 'antd'
import { getTransactionTemplateStatus, getTransactionTemplateDetails } from '../../util/TransactionTemplate'
import { getListingTeamMembers } from '../../util/Listings'
import { getFilterLogs, getSelectedFilterDataById } from '../../util/FilterLogs'
// import Form from "react-jsonschema-form";
import { cloneDeep } from 'lodash'
import { INSERT_FILTER, UPDATE_FILTER, CREATE_FILTER_MASTER_DATA } from '../../graphQl/mutations'
import client from '../../apollo/apollo'
import { getYearMonthDate } from '../../util/DateFormat'
import moment from "moment"
import { withRouter } from 'react-router-dom'
import TransactionFormData from './TransactionFormData'
import _ from 'lodash'
import EcommerceForm from '../../components/EcommerceForm/index'

const { RangePicker } = DatePicker;
const { Option } = Select

const FormItem = Form.Item;

let currentStatus = "currentTransactionStatus"
let formData = "formDefinition"
let createdAt = "createdAt"
let updatedAt = "updatedAt"
let assignedTo = "assignedTo"
// let tableName = "transaction"
let linkCount = "linkCount"
let eligibleStatusList = "eligibleStatusList"
let type = "type"
let linkCountStatus = "linkCountStatus"

let linkCountArray = [
    true, false
]

let typeArray = [
    'SMS', 'EMAIL'
]

class TransactionFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            transactionTemplateId: this.props.templateId,
            filterCriteria: {},
            templateStatus: [],
            templateDetail: {},
            assignedMembers: [],
            formDataValues: {},
            selectedStatus: [],
            selectedAssignedMembers: [],
            selectedCreatedDateRange: [],
            selectedCreatedDate: '',
            selectedUpdatedDateRange: [],
            selectedUpdatedDate: '',
            isOpen: this.props.isOpenFilter,
            isOpenSaveFilter: this.props.isSavedFilter === true ? true : false,
            filterName: '',
            selectedFilterData: {},
            linkCount: null,
            templateData: {},
            tableName: this.props.screenName,
            loading: false,
            isReadOnly: false,
            assignedMembersList: [],
            selectedAssignedMembersName: [],
            linkCountStatus: null,
            transactionType: '',
            deliveredStatus: [],
            deliveredOptions: [],
            recipientType: '',
            recipientUser: '',
            orderId: "",
            userEmail: "",
            userPhoneNumber: "",
            createdAtFilterRadioOption: "",
            updatedAtFilterRadioOption: ""
        }
    }

    componentDidMount() {

        this.setState({
            templateData: this.props.transactionTemplateData, isReadOnly: this.props.isFieldsReadOnly,
            recipientType: this.props.receivedType,
            recipientUser: this.props.recipientUserId
        }, () => {
            //when clicking saved filter item edit icon
            if (Object.keys(this.props.editFilterItem).length > 0) {

                let data = cloneDeep(this.props.editFilterItem)
                data.option = "update"

                this.setState({ selectedFilterData: data }, () => {
                    if (!this.state.recipientType) {
                        this.getFilterCriteria()
                    } else {
                        this.setFilterData()
                    }
                })
            }
            //searching filter values till reset option
            else {
                //bind already searched option in modal
                if (this.props.selectedData) {
                    let data = cloneDeep(this.props.selectedData)
                    data.filterData = this.props.selectedData
                    data.option = "create"
                    this.setState({ selectedFilterData: data }, () => {
                        if (!this.state.recipientType) {
                            this.getFilterCriteria()
                        } else {
                            this.setFilterData()
                        }

                    })
                } else {
                    this.setState({ selectedFilterData: this.props.selectedData }, () => {
                        if (!this.state.recipientType) {
                            this.getFilterCriteria()
                        } else {
                            this.setFilterData()
                        }
                    })
                }
            }

        })
    }

    componentWillReceiveProps(newProps) {
        this.setState({ isOpen: newProps.isOpenFilter })
        //for transaction template data
        if (newProps.transactionTemplateData !== this.props.transactionTemplateData) {
            this.setState({ templateData: newProps.transactionTemplateData })
        }
        //when search button clicked
        if (this.props.isClickedOk !== newProps.isClickedOk && newProps.isClickedOk === true) {
            this.onSearchData()
        }
        //when save filter button clicked
        if (this.props.onSaveFilter !== newProps.onSaveFilter && newProps.onSaveFilter === true) {
            this.onSaveFilter()
        }
        // when edit button clicked
        if (this.props.isFieldsReadOnly !== newProps.isFieldsReadOnly) {
            this.setState({ isReadOnly: newProps.isFieldsReadOnly })
        }
        if (this.props.receivedType !== newProps.receivedType) {
            this.setState({ recipientType: newProps.receivedType })
        }
        if (this.props.recipientUserId !== newProps.recipientUserId) {
            this.setState({ recipientUser: newProps.recipientUserId })
        }
    }

    //get filter criteria from filter master table
    getFilterCriteria = () => {
        this.setState({ loading: true }, () => {

            const { selectedFilterData } = this.state
            let data = {
                tableName: this.state.tableName,
                listingId: localStorage.getItem('selectedListingId'),
                templateId: this.state.transactionTemplateId
            }
            getFilterLogs(data)
                .then((res) => {
                    let options = []
                    //if filter data not stored already for that template
                    if (res.length === 0) {
                        this.createFilterMasterData()
                    }
                    //if filter criteria stored in db for that template
                    else {
                        this.setState({ filterCriteria: res.length > 0 ? res[0].filterCriteria : {}, loading: false }, () => {
                            this.setFilterData()

                        })
                    }
                }).catch(err => {
                    this.setState({ loading: false })
                })
        })

    }

    setFilterData = () => {
        const { filterCriteria, selectedFilterData } = this.state
        if (selectedFilterData && selectedFilterData.filterData) {
            let data = selectedFilterData.filterData
            let ecommerceData = data.UserForm ? data.UserForm : {}
            //for created at
            if (data.hasOwnProperty(createdAt) && data.createdAt) {
                let value = data.createdAt
                let date = []
                let dateRange = []
                let startDate = moment(value[0])
                let endDate = moment(value[1])

                date.push(startDate, endDate)
                dateRange.push(value[0], value[1])

                date.push(startDate, endDate)
                this.setState({
                    selectedCreatedDateRange: date,
                    selectedCreatedDate: dateRange
                })
            }

            if (data.hasOwnProperty("createdAtFilterRadioOption") &&
                data.createdAtFilterRadioOption) {
                this.setState({ createdAtFilterRadioOption: data.createdAtFilterRadioOption })
            }
            if (data.hasOwnProperty("updatedAtFilterRadioOption") &&
                data.updatedAtFilterRadioOption) {
                this.setState({ updatedAtFilterRadioOption: data.updatedAtFilterRadioOption })
            }

            //for updated at
            if (data.hasOwnProperty(updatedAt) && data.updatedAt) {
                let value = data.updatedAt
                let date = []
                let dateRange = []
                let startDate = moment(value[0])
                let endDate = moment(value[1])
                date.push(startDate, endDate)
                dateRange.push(value[0], value[1])
                this.setState({
                    selectedUpdatedDateRange: date,
                    selectedUpdatedDate: dateRange
                })
            }

            if (selectedFilterData.filterName) {
                this.setState({ filterName: selectedFilterData.filterName })
            }

            //for linkCount
            if (data.hasOwnProperty(linkCount)) {
                this.setState({ linkCount: data.linkCount }, () => {
                })
            }
            //for linkCount status
            if (data.hasOwnProperty(linkCountStatus)) {
                this.setState({ linkCountStatus: data.linkCountStatus }, () => {
                })
            }
            //for linkCount status
            if (data.hasOwnProperty(linkCountStatus)) {
                this.setState({ transactionType: data.type }, () => {
                    this.setDeliveryOption()
                })
            }

            if (data.hasOwnProperty(linkCountStatus)) {
                this.setState({ deliveredStatus: data.deliveryStatusList }, () => {
                })
            }

            if (data.hasOwnProperty(formData)) {
                this.setState({ formDataValues: data.formDefinition })
            }

            if (data.hasOwnProperty(eligibleStatusList)) {
                this.setState({ selectedStatus: data.eligibleStatusList })
            }
            //ecommerce detail
            if (data.hasOwnProperty('orderId')) {
                let id = data.orderId ? data.orderId : ""
                this.setState({ orderId: id })
            }
            //ecommerce detail
            if (ecommerceData) {
                let email = ecommerceData.customerMail ? ecommerceData.customerMail : ""
                let phone = ecommerceData.customerMobile ? ecommerceData.customerMobile : ""
                this.setState({ userEmail: email, userPhoneNumber: phone })
            }
        }

        if (filterCriteria.hasOwnProperty(assignedTo)) {
            this.getListingTeamMembers()
        }
    }

    //create filter criteria
    createFilterMasterData = () => {
        if (this.state.templateData) {
            let template = this.state.templateData
            let name = `${localStorage.getItem('selectedListingName')}_${template.name}`

            //filter criteria fields
            let value = {
                formDefinition: template.formDefinition,
                eligibleStatusList: template.eligibleStatusList,
                createdAt: "",
                updatedAt: "",
                assignedTo: "",
                linkCount: ""
            }

            let variablesData = {
                name: name,
                description: name,
                tableName: this.state.tableName,
                listingId: localStorage.getItem('selectedListingId'),
                templateId: template.id,
                filterCriteria: value,
                createdBy: localStorage.getItem('uid')
            }

            client.mutate({ mutation: CREATE_FILTER_MASTER_DATA, variables: variablesData }).then((response) => {
                this.getFilterCriteria()
            })
                .catch(err => {
                    message.error('Filter saved Failed')
                    this.setState({ loading: false })
                });

        }
    }

    getListingTeamMembers = () => {
        if (this.state.transactionTemplateId) {
            let listingId = localStorage.getItem('selectedListingId')
            getListingTeamMembers(listingId)
                .then((res) => {
                    if (res.length > 0) {
                        let options = []
                        let options1 = []
                        res.map((item) => {
                            if (item && item.userInfo) {
                                let data = item.userInfo
                                let name = ''
                                if (data.firstName) {
                                    name = data.firstName
                                }
                                if (data.lastName) {
                                    name = name + ' ' + data.lastName
                                }
                                let value = {
                                    id: data.id,
                                    name: name
                                }
                                options1.push(value)
                                options.push(<Option key={data.id} value={data.id}>{name}</Option>)
                            }
                        })
                        this.setState({ assignedMembers: options, assignedMembersList: options1 })

                    } else {
                        this.setState({ assignedMembers: [], assignedMembersList: [] })
                    }
                    const { selectedFilterData } = this.state
                    if (selectedFilterData && selectedFilterData.filterData &&
                        selectedFilterData.filterData.hasOwnProperty(assignedTo)) {
                        this.setState({ selectedAssignedMembers: selectedFilterData.filterData.assignedTo })
                    }
                })
        }
    }

    renderDatePicker = (stateString, stateValue, state) => {
        return (
            <RangePicker
                format="DD/MM/YYYY"
                value={stateValue}
                onChange={(event) => this.onChangeDate(event, stateString, state)}
                disabled={this.state.isReadOnly}
            />
        )
    }

    onChangeFormValue = (event) => {
        if (event) {
            this.setState({ formDataValues: event.formData })
        }
    }

    onChangeStatus = (event) => {
        this.setState({ selectedStatus: event })
    }

    onChangeAssignedMembers = (event) => {

        let selectedList = _.filter(this.state.assignedMembersList, (data) =>
            _.includes(event, data.id))
        let option = []
        selectedList.map((res) => {
            option.push(res.name)
        })
        this.setState({ selectedAssignedMembers: event, selectedAssignedMembersName: option })
    }

    onChangeDate = (dateValue, stateString, statevalue) => {
        let startDate
        let endDate
        let date = []
        if (dateValue.length > 0) {
            startDate = getYearMonthDate(dateValue[0]) + ' 00:00:00' //start date
            endDate = getYearMonthDate(dateValue[1]) + ' 23:59:59'  //end date
            date.push(startDate, endDate)
            this.setState({ [stateString]: dateValue, [statevalue]: date }, () => {
            })
        } else {
            this.setState({ [stateString]: [], [statevalue]: [] })
        }

    }

    onOpenSaveFilterField = () => {
        this.setState({ isOpenSaveFilter: true })
    }

    onSaveFilter = () => {
        const { selectedFilterData, recipientUser, recipientType } = this.state
        let templateData = cloneDeep(this.state.filterCriteria)
        if (templateData && templateData.formDefinition) {
            templateData.formDefinition.formData = this.state.formDataValues
        }

        //ecommerce
        let value
        if (this.state.templateData && this.state.templateData.displayTemplateType === "EcommerceForm") {
            value = {
                customerMail: this.state.userEmail,
                customerMobile: this.state.userPhoneNumber,
            }
        }

        let data = {
            eligibleStatusList: this.state.selectedStatus,
            formDefinition: this.state.formDataValues,
            createdAt: this.state.selectedCreatedDate,
            updatedAt: this.state.selectedUpdatedDate,
            assignedTo: this.state.selectedAssignedMembers,
            assignedToNames: this.state.selectedAssignedMembersName,
            linkCount: this.state.linkCount,
            linkCountStatus: this.state.linkCountStatus,
            type: this.state.transactionType,
            deliveryStatusList: this.state.deliveredStatus,
            orderId: this.state.orderId ? this.state.orderId : "",
            UserForm: value,
            createdAtFilterRadioOption: this.state.createdAtFilterRadioOption,
            updatedAtFilterRadioOption: this.state.updatedAtFilterRadioOption,
        }

        let contactId = (recipientType && recipientType === "personal") ? recipientUser : null
        let businessId = (recipientType && recipientType === "business") ? recipientUser : null
        let listingId = recipientType ? localStorage.getItem('receivedListingId') : localStorage.getItem('selectedListingId')

        let variablesData = {}
        //for edit filter   
        if (selectedFilterData && Object.keys(selectedFilterData).length > 0 &&
            Object.keys(selectedFilterData.filterData).length > 0 && selectedFilterData.option === "update") {
            let id = selectedFilterData && selectedFilterData.filterHistId ? selectedFilterData.filterHistId : null
            variablesData = {
                filterHistId: id,
                listingId: listingId,
                tableName: this.state.tableName,
                refId: this.state.transactionTemplateId ? this.state.transactionTemplateId : null,
                createdBy: localStorage.getItem('uid'),
                filterData: data,
                userContactId: contactId,
                businessContactId: businessId
            }
            if (this.props.onSaveFilterData) {
                this.props.onSaveFilterData(variablesData, 'update')
            }
        }
        //to create filter
        else {
            variablesData = {
                listingId: listingId,
                tableName: this.state.tableName,
                refId: this.state.transactionTemplateId ? this.state.transactionTemplateId : null,
                createdBy: localStorage.getItem('uid'),
                filterData: data,
                userContactId: contactId,
                businessContactId: businessId
            }

            if (this.props.onSaveFilterData) {
                this.props.onSaveFilterData(variablesData, 'create')
            }
        }
    }

    //when searching data
    onSearchData = () => {
        let templateData = cloneDeep(this.state.filterCriteria)
        if (templateData && templateData.formDefinition) {
            templateData.formDefinition.formData = this.state.formDataValues
        }
        //ecommerce
        let value
        if (this.state.templateData && this.state.templateData.displayTemplateType === "EcommerceForm") {
            value = {
                customerMail: this.state.userEmail,
                customerMobile: this.state.userPhoneNumber,
            }
        }

        let data = {
            eligibleStatusList: this.state.selectedStatus,
            formDefinition: this.state.formDataValues,
            createdAt: this.state.selectedCreatedDate,
            updatedAt: this.state.selectedUpdatedDate,
            assignedTo: this.state.selectedAssignedMembers,
            assignedToNames: this.state.selectedAssignedMembersName,
            linkCount: this.state.linkCount,
            linkCountStatus: this.state.linkCountStatus,
            type: this.state.transactionType,
            deliveryStatusList: this.state.deliveredStatus,
            orderId: this.state.orderId ? this.state.orderId : "",
            UserForm: value,
            createdAtFilterRadioOption: this.state.createdAtFilterRadioOption,
            updatedAtFilterRadioOption: this.state.updatedAtFilterRadioOption,

        }
        if (this.props.onShowFilterTag) {
            this.props.onShowFilterTag(data)
        }
    }

    onChangeLinkCount = (event) => {
        this.setState({ linkCount: event })
    }

    onChangeLinkCountStatus = (event, key) => {
        this.setState({ [key]: event }, () => {
            this.setDeliveryOption()
        })

    }

    setDeliveryOption = () => {
        const smsStatus = ['Pushed', 'Pending', 'Delivered', 'Invalid', 'Undelivered', 'Expired', 'Blocked']
        const emailStatus = ['Accepted', 'Clicked', 'Opened', 'Failed', 'Delivered', 'Rejected', 'Stored', 'Complained', 'Unsubscribed']
        this.setState({
            deliveredOptions: this.state.transactionType === 'SMS' ? smsStatus : emailStatus,
            // deliveredStatus: []
        })
    }

    renderSelectTag = (optionData, StateOption) => {
        return (
            <Select
                className="add-drop-down"
                showSearch
                onChange={(event) => this.onChangeLinkCountStatus(event, StateOption)}
                disabled={this.state.isReadOnly}
                showArrow={true}
            >
                {optionData.length > 0 && optionData.map(val => {
                    return <Option value={val}>{val.toString()}</Option>
                })
                }
            </Select>
        )
    }

    renderDateFilterRadio = (key, stateOption, stateValue) => {
        return (
            <Radio.Group
                value={stateValue}
                style={{ marginBottom: 15 }}
                onChange={(event) => this.onChangeDateRadioOption(key, event, stateOption)}>
                <Radio value='TODAY'>Today</Radio>
                <Radio value='SINCE_YESTERDAY'>Since yesterday</Radio>
                <Radio value='SINCE_LAST_WEEK'>Since last week </Radio>
            </Radio.Group>
        )
    }

    onChangeDateRadioOption = (option, value, stateKey) => {
        this.setState({ [stateKey]: value.target.value }, () => {
            if (option === "createdAt") {
                this.setState({ selectedCreatedDateRange: [], selectedCreatedDate: [] })
            } else {
                this.setState({ selectedUpdatedDateRange: [], selectedUpdatedDate: [] })
            }
        })
    }

    render() {
        const { templateStatus, filterCriteria, templateDetail,
            assignedMembers, selectedUpdatedDateRange, selectedCreatedDateRange, isOpen, selectedStatus,
            templateData, loading, isReadOnly, transactionType, deliveredOptions, deliveredStatus,
            tableName } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Spin spinning={loading}>

                    {/* template formdata */}
                    {
                        (filterCriteria &&
                            filterCriteria.hasOwnProperty(formData) &&
                            filterCriteria.formDefinition && tableName !== "received_transaction") &&
                        <TransactionFormData
                            templateData={filterCriteria}
                            onChangeFormValue={this.onChangeFormValue}
                            formdata={this.state.formDataValues}
                            disabledValue={isReadOnly}
                        />
                    }

                    <Form id="messageForm"
                        layout="vertical"
                    //  className="transactionFilter"
                    >

                        {/* ecommerce order id */}
                        {
                            this.state.templateData && this.state.templateData.displayTemplateType === "EcommerceForm" &&
                            <FormItem
                                label="Order Id"
                            >
                                {getFieldDecorator('order_id', {
                                    initialValue: this.state.orderId
                                })(
                                    <Input
                                        disabled={isReadOnly}
                                        onChange={(e) => this.setState({ orderId: e.target.value })}
                                    />
                                )}
                            </FormItem>
                        }
                        {/* ecommerce order id */}
                        {
                            this.state.templateData && this.state.templateData.displayTemplateType === "EcommerceForm" &&
                            <FormItem
                                label="Email"
                            >
                                {getFieldDecorator('email', {
                                    initialValue: this.state.userEmail
                                })(
                                    <Input disabled={isReadOnly} onChange={(e) => this.setState({ userEmail: e.target.value })}
                                    />
                                )}
                            </FormItem>
                        }
                        {/* ecommerce order id */}
                        {
                            this.state.templateData && this.state.templateData.displayTemplateType === "EcommerceForm" &&
                            <FormItem
                                label="Mobile number"
                            >
                                {getFieldDecorator('mobile_number', {
                                    initialValue: this.state.userPhoneNumber
                                })(
                                    <Input disabled={isReadOnly} onChange={(e) => this.setState({ userPhoneNumber: e.target.value })}
                                    />
                                )}
                            </FormItem>
                        }

                        {/* status */}
                        {
                            ((filterCriteria && filterCriteria.hasOwnProperty(eligibleStatusList) &&
                                filterCriteria.eligibleStatusList &&
                                filterCriteria.eligibleStatusList.status.length > 0)) &&
                            <FormItem
                                label="Transaction Status"
                            >
                                {getFieldDecorator('status', {
                                    initialValue: selectedStatus
                                })(
                                    <Select
                                        className="add-drop-down"
                                        mode="multiple"
                                        showSearch
                                        onChange={(event) => this.onChangeStatus(event)}
                                        value={selectedStatus}
                                        disabled={isReadOnly}
                                        showArrow={true}
                                    >
                                        {
                                            filterCriteria.eligibleStatusList.status.map(res => {
                                                return (<Option value={res}>{res}</Option>)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        }

                        {/* assigned members */}
                        {
                            filterCriteria && filterCriteria.hasOwnProperty(assignedTo) &&
                            <FormItem
                                label="Assigned Members"
                            >
                                {getFieldDecorator('assigned_members', {
                                    initialValue: this.state.selectedAssignedMembers
                                })(
                                    <Select
                                        className="add-drop-down"
                                        mode="multiple"
                                        showSearch
                                        onChange={(event) => this.onChangeAssignedMembers(event)}
                                        disabled={isReadOnly}
                                        showArrow={true}
                                    >
                                        {assignedMembers}
                                    </Select>
                                )}
                            </FormItem>
                        }

                        {/* Link count sttaus */}
                        {/* {
                            filterCriteria && filterCriteria.hasOwnProperty(linkCountStatus) && */}
                        <FormItem
                            label="Link Count Status"
                        >
                            {getFieldDecorator('linkCountStatus', {
                                initialValue: this.state.linkCountStatus
                            })(
                                this.renderSelectTag(linkCountArray, 'linkCountStatus')
                            )}
                        </FormItem>
                        {/* } */}

                        {/* type */}
                        {/* {
                            filterCriteria && filterCriteria.hasOwnProperty(type) && */}
                        <FormItem
                            label="Type"
                        >
                            {getFieldDecorator('Type', {
                                initialValue: this.state.transactionType
                            })(
                                this.renderSelectTag(typeArray, 'transactionType')
                            )}
                        </FormItem>
                        {/* } */}
                        {transactionType &&
                            <FormItem
                                label="Delivery status"
                            >
                                {getFieldDecorator('DeliveryStatus', {
                                    initialValue: deliveredStatus
                                })(
                                    <Select
                                        className="add-drop-down"
                                        mode="multiple"
                                        showSearch
                                        onChange={(event) => this.setState({ deliveredStatus: event })}
                                        // value={deliveredStatus}
                                        // defaultValue={deliveredStatus}
                                        disabled={isReadOnly}
                                        showArrow={true}
                                    >
                                        {
                                            deliveredOptions.map(res => {
                                                return (<Option value={res}>{res}</Option>)
                                            })
                                        }
                                    </Select>
                                )}
                            </FormItem>
                        }

                        {/* link Clicked Count */}
                        {
                            filterCriteria && filterCriteria.hasOwnProperty(linkCount) &&
                            <FormItem
                                label="Link Clicked Count"
                            >
                                {getFieldDecorator('link_count', {
                                    initialValue: this.state.linkCount
                                })(
                                    <InputNumber
                                        onChange={(event) => this.onChangeLinkCount(event)}
                                        disabled={isReadOnly}
                                        min={0}
                                    />
                                )}
                            </FormItem>
                        }

                        {/* createdAt */}
                        {
                            ((filterCriteria && filterCriteria.hasOwnProperty(createdAt)) ||
                                (tableName === "received_transaction")) &&
                            <FormItem
                                label="Created At"
                            >
                                {getFieldDecorator('createdAt', {
                                    initialValue: this.state.selectedCreatedDateRange
                                })(
                                    this.renderDatePicker('selectedCreatedDateRange', selectedCreatedDateRange,
                                        'selectedCreatedDate')
                                )}
                            </FormItem>
                        }

                        {/* {this.renderDateFilterRadio("createdAt", "createdAtFilterRadioOption",
                            this.state.createdAtFilterRadioOption)} */}



                        {/* updatedAt */}
                        {
                            ((filterCriteria && filterCriteria.hasOwnProperty(updatedAt)) ||
                                (tableName === "received_transaction")) &&
                            <FormItem
                                label="Updated At"
                            >
                                {getFieldDecorator('updatedAt', {
                                    initialValue: this.state.selectedUpdatedDateRange
                                })(
                                    this.renderDatePicker('selectedUpdatedDateRange', selectedUpdatedDateRange,
                                        'selectedUpdatedDate')
                                )}
                            </FormItem>
                        }

                        {/* {this.renderDateFilterRadio("updatedAt", "updatedAtFilterRadioOption",
                            this.state.updatedAtFilterRadioOption)} */}

                    </Form>
                </Spin>
            </div>
        );
    }

};

const WrappedApp = Form.create()(TransactionFilter);


export default (withRouter(WrappedApp));


