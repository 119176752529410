import React, { Component } from "react";
import { Button, message, Spin, Icon, Modal, Row, Col, Checkbox, Collapse, List, Input } from 'antd'
import { GET_TRANSACTION_TEMPLATE_DETAIL_BY_ID, CONTACT_WITH_NUMBER_SUB, GET_BUSINESS_CONTACT_LIST } from '../../graphQl/subscriptions'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';
import AddUser from '../Notify/AddUser'
import Recipients from '../Notify/Recipients'
import { cloneDeep } from 'lodash'
import * as _ from "lodash"

const { Panel } = Collapse;

class RecipientsForTransaction extends Component {

    constructor(props) {
        super(props)
        this.state = {
            confirmSearchValue: '',
            individualContactList: [],
            bussinessContactList: [],
            showRecipientsModal: false,
            RecipientOption: '',
            selectedRecipients: [],
            selectedContactsIds: [],
            allRecipientsList: []
        }
    }

    componentDidMount() {
        this.getIndividualContactsList()
        this.getBussinessContactsList()
    }

    //get bussiness contact list
    getBussinessContactsList = () => {
        const variables = {
            startNumber: 0,
            limit: 50,
            status: "ACTIVE",
            listingId: localStorage.getItem('selectedListingId'),
        }
        const gqlValue = GET_BUSINESS_CONTACT_LIST
        const query = gql`
                  ${gqlValue}
                `
        client
            .subscribe({
                query,
                variables: variables
            })
            .subscribe(
                res => {
                    if (res.data && res.data.business_contact && res.data.business_contact.length > 0) {
                        let list = []
                        res.data.business_contact.map((user, index) => {
                            let data = cloneDeep(user)
                            data.checkedValue = false
                            data.firstName = data.name
                            data.keyValue = index
                            if (data.contactId) {
                                data.email = data.contactId.email
                                data.phoneNumber = data.contactId.phoneNumber
                            }
                            list.push(data)
                        })
                        this.setState({ bussinessContactList: list }, () => {
                        })
                    } else {
                        this.setState({ bussinessContactList: [] })
                    }
                },
                err => {
                    this.setState({ showLoading: false })
                })
    }

    //get individual contacts list
    getIndividualContactsList = () => {
        const variables = {
            startNumber: 0,
            limit: 50,
            searchValue: `%${this.state.confirmSearchValue}%`,
            listingId: localStorage.getItem('selectedListingId'),
            status: "ACTIVE",
        }
        const gqlValue = CONTACT_WITH_NUMBER_SUB
        const query = gql`
                  ${gqlValue}
                `
        client
            .subscribe({
                query,
                variables: variables
            })
            .subscribe(
                res => {
                    console.log(res, 'resrser')
                    if (res.data && res.data.contact && res.data.contact.length > 0) {
                        let list = []
                        res.data.contact.map((user, index) => {
                            let data = cloneDeep(user)
                            data.checkedValue = false
                            data.keyValue = index
                            list.push(data)
                        })
                        this.setState({ individualContactList: list }, () => {
                            console.log('individualContactList', this.state.individualContactList)
                        })
                    } else {
                        this.setState({ individualContactList: [] })
                    }
                },
                err => {
                    this.setState({ showLoading: false })
                })
    }

    addMember = (option) => {
        this.setState({ showRecipientsModal: true, RecipientOption: option })
    }

    onCloseModal = () => {
        this.setState({ showRecipientsModal: false, RecipientOption: '' })
    }

    onAddCustomer = (userData, userIds) => {
        let data = _.filter(userData, function (o) { return o.checkedValue === true; });
        this.setState({
            selectedRecipients: data, showRecipientsModal: false,
            selectedContactsIds: userIds,
            allRecipientsList: userData
        })
        this.props.onContactsAdded(data, this.state.RecipientOption);
    }

    setSelectedRecipients = () => {
        return (

            <div>
                <Recipients screenName="newBroadCast"
                    recipientsList={this.state.selectedRecipients}
                    onRemoveRecipients={this.onRemoveRecipients}
                />
            </div>
        )
    }

    onRemoveRecipients = (item) => {
        let list = []

        let users = _.filter(this.state.selectedRecipients, (data) =>
            !_.includes(item.id, data.id))

        this.state.allRecipientsList.map((res, index) => {
            let userData = cloneDeep(res)
            if (res) {
                if (res.id === item.id) {
                    userData.checkedValue = false
                }
                list.push(userData)
            }
        })

        let ids = _.filter(this.state.selectedContactsIds, (data) =>
            !_.includes(item.id, data))

        this.setState({ selectedRecipients: users, selectedContactsIds: ids, allRecipientsList: list }, () => {
            this.props.onContactsAdded(this.state.selectedRecipients, this.state.RecipientOption);

        })
    }

    onChangePanle = () => {
        this.setState({ selectedRecipients: [], selectedContactsIds: [], allRecipientsList: [] }, () => {
        })
    }

    render() {
        const { RecipientOption, individualContactList, bussinessContactList } = this.state
        return (
            <div className="recipiants-label-view">
                <h6 style={{
                    color: 'red',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: 20
                }}>Note: Select Recipients either from Individual or Business contact list at a time.</h6>
                <Collapse accordion onChange={() => this.onChangePanle()}>
                    <Panel header="Individual Recipients" key="1">
                        <Row>
                            <Col span={20}>
                                {this.setSelectedRecipients()}

                            </Col>
                            <Col span={4}>
                                <Button type="dashed" onClick={() => this.addMember('individual')}
                                    style={{ width: '100px' }} >
                                    <span className="recipiant-add-text">ADD</span>
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Col>
                        </Row>
                    </Panel>
                    <Panel header="Business Recipients" key="2">
                        <Row>
                            <Col span={20}>
                                {this.setSelectedRecipients()}
                            </Col>
                            <Col span={4}>
                                <Button type="dashed" onClick={() => this.addMember('bussiness')}
                                    style={{ width: '100px' }} >
                                    <span className="recipiant-add-text">ADD</span>
                                    <Icon id="icon-view" type="plus" />
                                </Button>
                            </Col>
                        </Row>
                    </Panel>

                </Collapse>

                {
                    this.state.showRecipientsModal === true &&
                    <AddUser
                        isOpen={this.state.showRecipientsModal}
                        listData={RecipientOption === "individual" ? individualContactList : bussinessContactList}
                        screenName="recipients"
                        messageType=""
                        onCloseModal={this.onCloseModal}
                        onAddCustomer={this.onAddCustomer}
                        selectedContactsList={this.state.allRecipientsList}
                        selectedContactsIdsData={this.state.selectedContactsIds}
                    />
                }
            </div>
        )
    }

};

export default RecipientsForTransaction;
