import React from "react";
import { Avatar, Badge, Checkbox, Row, Col, Tag, Icon, Divider, Popconfirm } from "antd";
import users from "routes/List/data/users";
import moment from 'moment'
import Linkify from 'react-linkify'
import ReactTextFormat from 'react-text-format';
import { CONFIG } from '../../../../config/config'
import { getENVConfig } from '../../../../util/common'
import _ from 'lodash'


const ListItem = (({ todo, onTodoSelect, onTodoChecked, onMarkAsStart, screenName, removeMember }) => {

  let priceUnit
  let userMail = ""
  let name = ""
  let paymentType = ""

  function customLinkDecorator(decoratedHref, decoratedText, linkTarget, key) {
    return (
      <a
        href={decoratedHref}
        key={key}
        target={linkTarget}
        rel='noopener'
        className='customLink'
      >
        {decoratedText}
      </a>
    )
  }

  function calculateTotalWithSellerValues(sellervalues) {
    let eachDiscount = 0;
    let Totalaftercalculation = 0;
    let eachAddfees = 0;

    if (sellervalues.Discounts && sellervalues.Discounts.length > 0) {
      sellervalues.Discounts.map((value, index) => {
        eachDiscount = eachDiscount + parseInt(sellervalues.editedTotal.TotalPriceAfterTax) * parseInt(value.attributeValue) / 100;
      });
    }
    if (sellervalues.AdditionalFees && sellervalues.AdditionalFees.length > 0) {
      sellervalues.AdditionalFees.map((value, index) => {
        eachAddfees = eachAddfees + parseInt(value.attributeValue);
      });
    }
    Totalaftercalculation = (sellervalues.editedTotal.TotalPriceAfterTax + eachAddfees) - eachDiscount;
    return Totalaftercalculation;
  }

  let user = null;
  if (todo.user > 0)
    user = users[todo.user - 1];
  todo.formDataLowered = {};
  var keyLow = [];
  if (todo.formData) {
    Object.keys(todo.formData).forEach((key) => {
      var lowkey = key.toLowerCase();
      todo.formDataLowered[lowkey] = todo.formData[key];
    })
  }

  //template price unit
  if (todo && todo.template && todo.template.templateSettings && todo.template.templateSettings.priceUnit) {
    priceUnit = todo.template.templateSettings.priceUnit
  }

  //getting order form name/email 
  if (todo && todo.formDataLowered.hasOwnProperty('userformfulldata') && todo.formDataLowered.userformfulldata.length > 0) {
    _.find(todo.formDataLowered.userformfulldata, (res, i) => {
      if ((res && (res.attributeType === "customerName"))) {
        name = res.attributeValue
      }
      if ((res && (res.attributeType === "customerEmail"))) {
        userMail = res.attributeValue
      }
    });
  } else {
    //mobile order customer name/email
    if (todo && todo.formDataLowered.hasOwnProperty('userform') && todo.formDataLowered.userform) {
      name = todo.formDataLowered.userform.Name || todo.formDataLowered.userform.name
      userMail = todo.formDataLowered.userform.email || todo.formDataLowered.userform.Email
    }
  }

  //for delivery details, payment type
  if (todo && todo.formDataLowered.hasOwnProperty('deliverydetailsfulldata')
    && todo.formDataLowered.deliverydetailsfulldata.length > 0) {
    let deliverydetail = _.find(todo.formDataLowered.deliverydetailsfulldata, (res, i) => {
      if ((res && (res.attributeType === "PaymentType"))) {
        return res
      }
    })

    if (deliverydetail && deliverydetail.hasOwnProperty('duplicateAttributeValue')) {

      if (typeof deliverydetail.duplicateAttributeValue === "string" && deliverydetail.duplicateAttributeValue) {
        let splitArray = deliverydetail.duplicateAttributeValue.split(":")

        if (splitArray.length > 0) {
          paymentType = splitArray[0]
        }

      } else if (Array.isArray(deliverydetail.duplicateAttributeValue) &&
        deliverydetail.duplicateAttributeValue.length > 0) {
        paymentType = deliverydetail.duplicateAttributeValue[0]
      }

    }

    // if (deliverydetail && deliverydetail.duplicateAttributeValue.length > 0) {
    //   paymentType = deliverydetail.duplicateAttributeValue[0]
    // } else {
    //   paymentType = ""
    // }
  }


  return (
    <div className="gx-module-list-item" id="list-alignment">
      <div className="gx-module-list-icon">
        {screenName !== 'listingDetail' &&
          <Checkbox color="primary"
            checked={todo.selected}
            onClick={(event) => {
              event.stopPropagation();
              onTodoChecked(todo);
            }}
            value="SelectTodo"
            className="gx-icon-btn"
          />
        }

        {screenName !== 'contactScreen' && screenName !== 'listingDetail' && screenName !== 'businessContactScreen' &&
          // <div onClick={() => {
          //   todo.starred = !todo.starred;
          //   onMarkAsStart(todo);
          // }}>
          //   {todo.starred ?
          //     <i className="gx-icon-btn icon icon-star" /> :
          //     <i className="gx-icon-btn icon icon-star-o" />
          //   }

          // </div>
          // :
          <Avatar icon="user"
          // alt="https://via.placeholder.com/150x150"
          //   src="https://via.placeholder.com/150x150" 
          />
        }

      </div>
      <div className="gx-module-list-info"
        onClick={() => { onTodoSelect(todo) }}
      >


        {
          screenName === "notifyScreen" &&
          <div className="gx-module-todo-content">
            <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
              style={{ fontWeight: 400 }}
            >
              <Row span={24}>
                <Col span={24} style={{ width: '100%', fontWeight: 600, fontSize: '14px' }}>
                  {todo.title}
                </Col>
              </Row>

            </div>
            <div className="gx-manage-margin" id="content-list-content" style={{ display: 'flex' }}>

              {/* <Linkify properties={{target: '_blank', style: {color: 'red', fontWeight: 'bold'}}}>{todo.content}</Linkify> */}
              {/* <ReactTextFormat
                linkDecorator={customLinkDecorator}
                linkTarget="_blank"
                allowedFormats={['URL']}
                terms={["Link", "phone", "image", "Anchor", "email", "Credit"]}
              >{todo.content}</ReactTextFormat> */}

              <p className="chatText" id="chat-paragraph-view" style={{ width: '75%' }} dangerouslySetInnerHTML={{ __html: todo.content }} />


              <div id="chat-date-modal">{moment(todo.createdAt).format('MMM DD YYYY, hh:mm A')}</div>
              {/* {todo.content.replace(/<br\s*\\?>/g, "\r\n")} */}

            </div>
            <div className="gx-flex-row">

              {todo && todo.logs && todo.logs.length > 0 && todo.logs[0].attachedFileLinks.length > 0
                && todo.logs[0].attachedFileLinks.map((_d, index) => {
                  return <div className="gx-flex-row">
                    <a
                      style={{ fontSize: 12, marginRight: 8 }}
                      target="_blank" href={`${getENVConfig(CONFIG.DOMAIN)}/f/${_d.shortURL}?mode=inapp`}>
                      {_d.files ? `${_d.files.fileName} (${_d.linkClickCount})` : 'file'}</a>
                  </div>
                })
              }
              {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                <div style={{ paddingLeft: '10px' }}>
                  {todo.tags.tagValues.map((data, index) => {
                    return (
                      <Tag color="green"> {data}</Tag>
                    );
                  })}
                </div>
              }
            </div>
          </div>
        }


        {
          screenName === "messageTemplateScreen" &&
          <div className="gx-module-todo-content">
            <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
              style={{ fontWeight: 400 }}
            >
              <Row span={24}>
                <Col span={24} style={{ width: '100%', fontWeight: 600, fontSize: '14px' }}>
                  {todo.title}{` (${todo.templateType})`}
                </Col>
              </Row>

            </div>
            <div className="gx-manage-margin" id="content-list-content" style={{ display: 'flex' }}>
              <p className="chatText" id="chat-paragraph-view" style={{ width: '75%' }} dangerouslySetInnerHTML={{ __html: todo.content }} />
              <div id="chat-date-modal" style={{ color: '#038fde', fontWeight: 300, fontSize: '12px', width: '25%', display: 'flex', justifyContent: 'flex-end' }}>{moment(todo.createdAt).format('MMM DD YYYY, hh:mm A')}</div>
              {/* {todo.content.replace(/<br\s*\\?>/g, "\r\n")} */}
              <div className="gx-flex-row">
                {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                  <div style={{ paddingLeft: '10px' }}>
                    {todo.tags.tagValues.map((data, index) => {
                      return (
                        <Tag color="green"> {data}</Tag>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        }

        {
          screenName === "transactionScreen" &&
          <div className="gx-module-todo-content">

            {
              todo && todo.template && todo.template.displayTemplateType === "EcommerceForm" ?
                // for ecommerce
                <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
                  style={{ fontWeight: 600, fontSize: '14px', textTransform: 'capitalize' }}
                >
                  {name || userMail}

                </div> :
                <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
                  style={{ fontWeight: 600, fontSize: '14px', textTransform: 'capitalize' }}
                >
                  {todo.formDataLowered.customername ||
                    todo.formDataLowered.shopname ||
                    todo.formDataLowered.businessname || todo.formDataLowered.username || todo.formDataLowered.phonenumber || todo.formDataLowered.email}
                </div>

            }
            {
              screenName === "transactionScreen" &&
              <div id="transaction-list-date" className="transaction-date-mobile" style={{
                color: '#038fde', fontWeight: 300,
                fontSize: '12px'
              }}>
                {moment(todo.updatedAt).format('MMM DD YYYY, hh:mm A')}</div>
            }

            <div className="gx-manage-margin">
              <div style={{ fontWeight: 350, fontSize: '12px', textTransform: 'capitalize' }}>{todo.formData.city} </div>
              {todo && todo.currentStatus &&
                <div style={{ display: 'flex', marginTop: 10 }}>
                  <Tag color="orange"> {todo.currentStatus}</Tag>
                  {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                    <div style={{ paddingLeft: '10px' }}>
                      {todo.tags.tagValues.map((data, index) => {
                        return (
                          <Tag color="green"> {data}</Tag>
                        );
                      })}
                    </div>
                  }
                </div>


              }
              {
                todo && todo.formData && todo.formData.orderId &&
                <p className="order-id-view">Order id: <Tag color="green"> {todo.formData.orderId}</Tag></p>
              }
              {todo && todo.formData.editedBySeller &&
                <div>
                  {todo.formData.editedBySellerValues.editedTotal && todo.formData.editedBySellerValues.editedTotal.TotalPriceAfterTax ?
                    <p style={{ fontSize: '12px', fontWeight: '350px' }}>Order Total: {priceUnit} {calculateTotalWithSellerValues(todo.formData.editedBySellerValues).toFixed(2)}</p> :
                    <p style={{ fontSize: '12px', fontWeight: '350px' }}>Order Total: {priceUnit} {todo.formData.Total.TotalPriceAfterTax.toFixed(2)}</p>
                  }
                </div>
              }
              <div>
                {
                  todo && todo.formData && todo && !todo.formData.editedBySeller && todo.formData.Total && todo.formData.Total &&
                  todo.formData.Total.hasOwnProperty('TotalPriceAfterTax') &&
                  <p style={{ fontSize: '12px', fontWeight: '350px' }}>Order Total: {priceUnit} {todo.formData.Total.TotalPriceAfterTax.toFixed(2)}</p>
                }
              </div>

              {
                paymentType &&
                <p style={{ fontSize: '13px', fontWeight: 500 }}>Payment Type: {paymentType}</p>
              }

              {todo.updatedAt &&
                (moment(todo.updatedAt).format('MMM DD YYYY, hh:mm A') !== moment(todo.createdAt).format('MMM DD YYYY, hh:mm A')) &&
                <p className="order-id-view">Updated At: {moment(todo.updatedAt).format('MMM DD YYYY, hh:mm A')}</p>
              }

            </div>
          </div>
        }

        {
          screenName === "received_transaction" &&
          <div className="gx-module-todo-content">
            <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
              style={{ fontWeight: 500 }}
            >
              {todo.formData.name || todo.formData.shopName}
            </div>
            <div className="gx-manage-margin" style={{ display: 'flex' }}>
              {todo.formData.city}
              {todo && todo.currentStatus &&
                <div style={{ paddingLeft: '10px' }}>
                  <Tag color="orange"> {todo.currentStatus}</Tag>
                </div>
              }
              {/* <div className="gx-flex-row"> */}
              {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                <div style={{ paddingLeft: '10px' }}>
                  {todo.tags.tagValues.map((data, index) => {
                    return (
                      <Tag color="green"> {data}</Tag>
                    );
                  })}
                </div>
              }
              {/* </div> */}
            </div>
          </div>
        }

        {
          screenName === "contactScreen" &&
          <div className="gx-module-todo-content">
            <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
              style={{ fontWeight: 500 }}
            >
              <div className="gx-flex-row">
                <h6 style={{ fontSize: '14px' }}>{todo.firstName ? todo.firstName : ''} {todo.lastName ? todo.lastName : ''}</h6>
                {/* <Divider type="vertical" style={{ height: 20 }} />
                <h4>Ceo</h4> */}
              </div>
              <div className="gx-flex-row">
                <div style={{ fontSize: 12, fontWeight: 400, fontSize: 14, color: '#b1aaaa' }}>{todo.email ? todo.email : ''} {todo.phoneNumber}</div>
              </div>
              <div className="gx-flex-row">
                {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                  <div style={{ paddingLeft: '10px' }}>
                    {todo.tags.tagValues.map((data, index) => {
                      return (
                        <Tag color="green"> {data}</Tag>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        }

        {
          screenName === 'businessContactScreen' &&
          <div className="gx-module-todo-content">
            <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
              style={{ fontWeight: 500 }}
            >
              <div className="gx-flex-row">
                <h6 style={{ fontSize: '14px' }}>{todo.name ? todo.name : ''}</h6>
                {/* <Divider type="vertical" style={{ height: 20 }} />
                <h4>Ceo</h4> */}
              </div>
              <div className="gx-flex-row">
                <div style={{ fontSize: 12, fontWeight: 400, fontSize: 14, color: '#b1aaaa' }}>{todo.phoneNumber}</div>
              </div>
              <div className="gx-flex-row">
                {todo.tags && todo.tags.tagValues && todo.tags.tagValues.length > 0 &&
                  <div style={{ paddingLeft: '10px' }}>
                    {todo.tags.tagValues.map((data, index) => {
                      return (
                        <Tag color="green"> {data}</Tag>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        }



        {
          screenName === "listingDetail" &&
          // <div className="gx-module-todo-content">
          //   <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
          //     style={{ fontWeight: 400 }}
          //   >
          //     {
          //       todo.userInfo &&
          //       <div className="gx-flex-row">
          //         <h4 style={{ fontSize: '17px' }}>{todo.userInfo.firstName} {todo.userInfo.lastName}</h4>
          //       </div>
          //     }


          //   </div>
          //   <div className="gx-manage-margin">
          //     <h4 style={{ fontSize: '17px' }}>{todo.userInfo ? todo.userInfo.phoneNumber : todo.phoneNumber}</h4>
          //     <Tag color="#87d068">{todo.role}</Tag>
          //   </div>
          // </div>

          <div className="gx-module-todo-content">
            <div className={`gx-subject ${todo.completed && 'gx-text-muted gx-text-strikethrough'}`}
              style={{ fontWeight: 400 }}
            >
              {
                todo.userInfo &&
                <div className="gx-flex-row">
                  <h6 style={{ fontSize: '14px' }}>{todo.userInfo.firstName ? todo.userInfo.firstName : ''} {todo.userInfo.lastName ? todo.userInfo.lastName : ''}</h6>
                  {/* <Divider type="vertical" style={{ height: 20 }} />
              <h4>Ceo</h4> */}
                </div>
              }
              <div className="gx-flex-row">
                <div style={{ fontSize: 12, fontWeight: 400, fontSize: 14, color: '#b1aaaa' }}>{todo.userInfo && todo.userInfo.email ? todo.userInfo.email : ''} {todo.userInfo ? todo.userInfo.phoneNumber : todo.phoneNumber}</div>
              </div>
              <div className="gx-manage-margin" style={{ paddingTop: 10 }}>
                <Tag color="#87d068">{todo.role}</Tag>
              </div>
            </div>
          </div>
        }
      </div>
      {
        screenName === "listingDetail" &&
        <div className="date-content-view" style={{
          color: '#038fde', fontWeight: 300,
          fontSize: '12px', width: '25%', display: 'flex', justifyContent: 'flex-end', paddingRight: '3%'
        }}>
          {moment(todo.createdAt).format('MMM DD YYYY, hh:mm A')}</div>
      }
      {
        screenName === "listingDetail" &&
        <div className="gx-manage-margin">
          <Popconfirm
            title="Are you sure remove this member?"
            onConfirm={() => removeMember(todo)}
            okText="Yes"
            cancelText="No"
          >
            <Icon id="icon-view" className="delete-icon-list" type="delete" style={{ fontSize: 25, cursor: 'pointer' }}
            // onClick={() => { removeMember(todo) }}
            />
          </Popconfirm>

        </div>
      }

      {
        screenName === "transactionScreen" &&
        <div id="transaction-list-date" className="transaction-date-web" style={{
          color: '#038fde', fontWeight: 300,
          fontSize: '12px', width: '25%', display: 'flex', justifyContent: 'flex-end'
        }}>
          {moment(todo.createdAt).format('MMM DD YYYY, hh:mm A')}</div>
      }
      {/* {screenName !== "contactScreen" ?
        <div className="gx-module-todo-right">

          <Avatar alt="https://via.placeholder.com/150x150"
            src="https://via.placeholder.com/150x150" />
        </div>
        :
        <Icon type="more" style={{ fontSize: 20 }} />
      } */}
    </div>

  )
});

export default ListItem;
