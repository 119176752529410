import React from "react";
import { Avatar, Modal, Input, Checkbox, Spin, Icon } from "antd";
import Moment from "moment";
import CustomScrollbars from "util/CustomScrollbars";
import moment from 'moment'

import labels from "routes/List/data/labels";
import users from "routes/List/data/users";
import Recipients from './Recipients'
import { FETCH_FILES } from '../../graphQl/subscriptions';
import { Subscription } from "react-apollo";
import * as _ from 'lodash'
import { cloneDeep } from 'lodash'
import client from '../../apollo/apollo';
import gql from 'graphql-tag';
import {CONFIG} from '../../config/config';
import {getENVConfig} from '../../util/common'

const { TextArea } = Input;

let customerData = []

class SelectFiles extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            uploadedList: [],
            showLoading: false,
            selectedFilesId: [],
            selectedFiles: [],
            isSelectAll: false,
        }
    }

    componentDidMount() {

        if (this.props.selectedFilesList.length === 0) {
            this.setState({ showLoading: true }, () => {
                let confirmSearchValue = ""

                const variables = {
                    "listingId": localStorage.getItem('selectedListingId'),
                    "searchValue": `%${confirmSearchValue}%`
                };

                const gqlValue = FETCH_FILES
                const query = gql`
                      ${gqlValue}
                    `
                client
                    .subscribe({
                        query,
                        variables: variables
                    })
                    .subscribe(
                        res => {
                            if (res && res.data) {
                                let listData = []
                                res.data.file_upload.map((list, index) => {
                                    let data = cloneDeep(list)
                                    data.checkedValue = false
                                    data.keyValue = index
                                    listData.push(data)
                                })
                                this.setState({ uploadedList: _.uniqBy(listData, 'id'), showLoading: false }, () => {
                                })
                            }
                        },
                        err => {
                            this.setState({ showLoading: true })
                        }
                    )
            })
        } else {
            this.setState({
                uploadedList: this.props.selectedFilesList,
                selectedFilesId: this.props.selectedFilesIdData
            }, () => {
            })
        }
    }

    onSelectFiles = (event, user, index) => {
        let uploadedList = cloneDeep(this.state.uploadedList)
        if (event.target.checked === true) {
            uploadedList[index].checkedValue = true

            let data = this.state.selectedFilesId
            let option = {
                'fileId': user.id,
                'url': `${getENVConfig(CONFIG.DOMAIN)}/f/${user.shortURL.shortURL}`
                //  'url': `https://totely.in/f/${user.shortURL.shortURL}`
            }
            data.push(option)

            this.setState({ uploadedList, selectedFilesId: data }, () => {
            })

        } else if (event.target.checked === false) {
            uploadedList[index].checkedValue = false
            let ids = _.remove(this.state.selectedFilesId, function (n) {
                return n === user.id;
            });
            this.setState({ uploadedList })
        }
    }

    onsubmit = () => {
        this.props.onAddFiles(this.state.uploadedList, this.state.selectedFilesId)
    }

    onSelectAll = (event) => {
        let value = false
        let list = []
        let selectedIds = []
        if (event.target.checked === true) {
            value = true
        } else if (event.target.checked === false) {
            value = false
        }
        this.state.uploadedList.map((user, index) => {
            let data = cloneDeep(user)
            data.checkedValue = value
            if (event.target.checked === true) {
                let option = { 'fileId': user.id, 'url': `${getENVConfig(CONFIG.DOMAIN)}/f/${user.shortURL.shortURL}` }
                selectedIds.push(option)
            }
            list.push(data)
        })
        this.setState({ uploadedList: list, selectedFilesId: selectedIds }, () => {
        })
    }


    render() {
        const { uploadedList } = this.state
        let confirmSearchValue = ""
        return (
            <Modal
                title="Select Files"
                visible={this.props.isOpen}
                closable={false}
                onCancel={() => this.props.onCloseSelectFileModal()}
                onOk={() => this.onsubmit()}

            >


                <Spin spinning={this.state.showLoading}>
                    <div>
                        <Checkbox
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                fontSize: 14
                            }}
                            onChange={(event) => this.onSelectAll(event)}
                        >
                            Select All
                                </Checkbox>
                        {
                            uploadedList &&
                            uploadedList.map((user, index) => {

                                return (
                                    <div className='gx-chat-user-item'>
                                        <div className="gx-chat-user-row">
                                            <div className="gx-chat-avatar">
                                                <div className="gx-status-pos">
                                                    <Icon id="icon-view" type="file-pdf" style={{ fontSize: 30 }} />
                                                    {/* <Avatar src="https://via.placeholder.com/150x150" className="gx-size-40" alt="https://via.placeholder.com/150x150" /> */}
                                                    <span className={`gx-status gx-small gx-active`} />
                                                </div>
                                            </div>

                                            <div className="gx-chat-info">
                                                <span className="gx-name h6">{user.fileName}</span>
                                                {/* <div className="gx-link">
                                                    {`https://totely.in/f/${user.shortURL.shortURL}`}
                                                </div> */}

                                            </div>

                                            <Checkbox
                                                checked={user.checkedValue}
                                                onChange={(event) =>
                                                    this.onSelectFiles(event, user, index)}>
                                            </Checkbox>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Spin>
            </Modal>
        );
    }
}

export default SelectFiles;
