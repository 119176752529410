import React, { Component } from 'react'
import { Form, Input, Icon, Button } from 'antd';
import _ from 'lodash'


export class CustomFields extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editFields: [],
            editValues: [],
            editKeys: [],
            id: 0
        }
    }

    componentWillMount() {
        if (this.props && this.props.editValues && this.props.editValues.length > 0) {
            const temp = JSON.parse(this.props.editValues)
            const fieldArr = []
            const valuedArr = []
            const keys = []
            Object.keys(temp).forEach((feild, Index) => {
                fieldArr.push(feild)
                keys.push(Index)
            })
            Object.values(temp).forEach(value => {
                valuedArr.push(value)
            })
            this.setState({
                editFields: fieldArr,
                editValues: valuedArr,
                editKeys: keys,
                id: keys.length - 1
            })
        }
    }


    remove = k => {
        var tempRemoveKeys = [];
        // var tempRemoveValues = [];
        // var tempRemoveFields = [];
        const removekeys = _.filter(this.state.editKeys, (o) => {
            if (o !== k) {
                return tempRemoveKeys.push(o)
            }
        });
        // const removeValue = _.filter(this.state.editValues, (o, index) => {
        //     if (index !== k) {
        //         console.log('oooo', o)
        //         return tempRemoveValues.push(o)
        //     }
        // });
        // const removeFields = _.filter(this.state.editFields, (o, index) => {
        //     if (index !== k) {
        //         console.log('oooo', o)
        //         return tempRemoveFields.push(o)
        //     }
        // });

        this.setState({ 
            editKeys: removekeys,
            // editFields: removeFields,
            // editValues: removeValue
         })
    };

    // filterArr = (arr, k) => {
    //     const temp = []
    //     const removekeys = _.filter(this.state[arr], (o, index) => {
    //         if (index !== k) {
    //             console.log('oooo', o)
    //             return temp.push(o)
    //         }
    //     });
    //     this.setState({[arr]: removekeys}, () => {
    //         console.log('11111', this.state[arr])
    //     })
    // }

    add = () => {
        const incId = this.state.id + 1
        this.setState({id: incId})
        this.state.editKeys.push(incId)
        this.setState({ editKeys: this.state.editKeys })
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, val) => {
            if (!err) {
                const { names, values } = val;
                let customAttributes = {};
                this.state.editKeys.map((k, index) => {
                    const obj = names[k];
                    customAttributes[obj] = values[k];
                    if (this.state.editKeys.length === index + 1) {
                        this.props.onClickSave(customAttributes)
                    }
                })
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { editFields, editValues, editKeys } = this.state
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        };
        getFieldDecorator('keys', { initialValue: [] });
        const keys = editKeys
        const formItems = keys.map((k, index) => (
           <div className="hfdbfhv">
            <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                required={false}
                key={k}
            >
                <div style={{ display: 'flex', flexDirection: 'row',width:'400px' }}>
                    {getFieldDecorator(`names[${k}]`, {
                        initialValue: editFields && editFields.length > 0 ? editFields[k] : '',
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: "Please input the field.",
                            },
                        ],
                    })(<Input placeholder="Field" style={{ width: 225, marginRight: 8 }} />)}
                    {getFieldDecorator(`values[${k}]`, {
                        initialValue: editValues && editValues.length > 0 ? editValues[k] : '',
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [
                            {
                                required: true,
                                whitespace: true,
                                message: "Please input the value.",
                            },
                        ],
                    })(<Input placeholder="Value" style={{ width: 225, marginRight: 8 }} />)}
                    {keys.length > 1 ? (
                        <Icon
                        id="icon-view"
                            className="dynamic-delete-button"
                            type="minus-circle-o"
                            onClick={() => this.remove(k)}
                        />
                    ) : null}
                </div>
            </Form.Item>
            </div>
        ));
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                   <div style={{height:'170px',overflowY:'auto',overflowX:'hidden',display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}> {formItems} </div>
                    <div style={{marginTop:'10px'}}>
                        <div className="dnk" style={{display:'flex',justifyContent:'center'}}>
                            
                    <Form.Item {...formItemLayoutWithOutLabel} >
                        <Button type="dashed" onClick={this.add} style={{width:'200px'}} >
                            <Icon id="icon-view" type="plus" /> Add field
          </Button>
                    </Form.Item>
                    {/* </div> */}
                    </div>
                    <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                    <Form.Item {...formItemLayoutWithOutLabel}>
                        <Button type="primary" htmlType="submit" >
                            Save
          </Button>
                    </Form.Item>
                    </div>
                    </div>
                </Form>
            </div>
        )
    }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(CustomFields);
export default WrappedDynamicFieldSet
