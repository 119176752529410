import React, { Component } from 'react'
import _ from 'lodash'
import { Icon, Tooltip, message, Popconfirm, Spin, Button, Modal, Checkbox } from 'antd';
import { GET_LISTING_PRODUCTS_CATEGORIES_SUB, GET_LISTING_PRODUCT_SUB, GET_LISTING_PRODUCT_COUNT } from '../../graphQl/subscriptions'
import client from '../../apollo/apollo'
import UploadCsv from '../../components/contact/uploadCsv'
import { formatCsv, formatEditProductCatalog } from '../../util/ProductCsv'
import gql from 'graphql-tag'
import { INSERT_PRODUCT_CSV, DELETE_PRODUCTS, CREATE_CATEGORIES, UPDATE_UPLOAD_IMAGE_STATUS, UPDATE_CATEGORY } from '../../graphQl/mutations';
import { CHECK_CATEGORIES, GET_PRODUCT_MASTER, GET_PRODUCT_INVENTORY } from '../../graphQl/queries'
import { getENVConfig } from '../../util/common'
import { CONFIG } from '../../config/config';
import ProductCatalog from '../CreateTemplate/EcommerceFormdata/ProductCatalog'
import { saveProducts } from '../../gql/mutation/templateTransaction/index'
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment'

const uuidv1 = require('uuid/v4');



const axios = require('axios');
export class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineItems: [],
            uploadView: false,
            csvData: {},
            saveView: false,
            loading: false,
            dataloading: false,
            csvLoading: false,
            type: '',
            visible: false,
            editProductData: [],
            cancelView: false,
            totalCount: 0,
            finalEditValue: [],
            categoriesData: [],
            editLoading: false,
            editType: '',
            imageVisible: false,
            imageUrl: '',
            uploadImageView: false,
            duplicateUploadImageView: false,
            templateSettingsData: {},
            ProdInventoryList: [],
            visibleSave: false,
            productList: [],
            csvready: false
        }
        this.ProdInventoryList = [];
    }



    componentDidMount() {
        console.log('this.props', this.props)
        if (this.props.editData && this.props.editData.templateData && this.props.editData.templateData.templateSettings && this.props.editData.templateData.templateSettings.uploadProductImage) {
            this.setState({
                uploadImageView: this.props.editData.templateData.templateSettings.uploadProductImage,
                duplicateUploadImageView: this.props.editData.templateData.templateSettings.uploadProductImage
            })
        }
        if (this.props.editData && this.props.editData.templateData && this.props.editData.templateData.templateSettings) {
            this.setState({ templateSettingsData: this.props.editData.templateData.templateSettings })
        }
        this.getProductCategories()
        // this.getOffsetProducts(0, 10)
        // this.getTotalCount()
        // this.editFormData()
    }


    getProductCategories = () => {
        this.setState({ dataloading: true }, () => {
            const params = {
                listingId: localStorage.getItem('selectedListingId'),
                templateId: this.props.templateId,
            }
            const gqlValue = GET_LISTING_PRODUCT_SUB
            const query = gql`${gqlValue}`
            client
                .subscribe({
                    query,
                    variables: params
                })
                .subscribe(
                    res => {
                        console.log(res, 'hyyres')
                        this.setState({ dataloading: false })
                        if (res && res.data && res.data.listing_products.length > 0) {
                            this.setState({
                                lineItems: res.data.listing_products,
                            }, () => {
                                console.log('lineItems', this.state.lineItems)
                            })
                        } else if (res && res.data && res.data.listing_products.length === 0) {
                            this.setState({ uploadView: true, type: 'upload' })
                        }
                    },
                    err => {
                        console.log('err', err)
                        this.setState({ dataloading: false })
                    })
        })
    }


    getOffsetProducts = (start, limit) => {
        const params = {
            listingId: localStorage.getItem('selectedListingId'),
            templateId: this.props.templateId,
            offset: start,
            limit: limit
        }
        const gqlValue = GET_LISTING_PRODUCT_SUB
        const query = gql`${gqlValue}`
        client
            .subscribe({
                query,
                variables: params
            })
            .subscribe(
                res => {
                    if (res && res.data && res.data.listing_products.length > 0) {
                        this.formatProductData(res.data.listing_products)
                    }
                },
                err => {
                    console.log('err')
                })
    }

    getTotalCount = () => {
        const params = {
            listingId: localStorage.getItem('selectedListingId'),
            templateId: this.props.templateId,
        }
        const gqlValue = GET_LISTING_PRODUCT_COUNT
        const query = gql`${gqlValue}`
        client
            .subscribe({
                query,
                variables: params
            })
            .subscribe(
                res => {
                    if (res.data && res.data.listing_products_aggregate && res.data.listing_products_aggregate.aggregate) {
                        this.setState({ totalCount: res.data.listing_products_aggregate.aggregate.count })
                    }
                },
                err => {
                    console.log('err')
                })
    }

    formatProductData = (data) => {
        console.log('formatProductData', data)
        this.setState({ editType: 'edit', editProductData: [] }, () => {
            let value = data
            let products = []
            // data.map(value => {
            let temp = {
                product_id: value.product_id,
                category_id: value.category_id,
                category: value.listing_product_category.category_name,
                productName: value.product_name,
                productCode: value.product_code === "null" ? "" : value.product_code,
                productDescription: value.product_description === "null" ? "" : value.product_description,
                CGST: value && value.product_taxes && value.product_taxes.CGST ? value.product_taxes.CGST : '',
                SGST: value && value.product_taxes && value.product_taxes.SGST ? value.product_taxes.SGST : '',
                IGST: value && value.product_taxes && value.product_taxes.IGST ? value.product_taxes.IGST : '',
                productConfigName1: value && value.product_config[0] && value.product_config[0].name ? value.product_config[0].name : '',
                productConfigPrice1: value && value.product_config[0] && value.product_config[0].price ? value.product_config[0].price : '',
                productImage: value && value.product_image && value.product_image.length > 0 ? value.product_image : null,
                hideFromCatalog: value && value.hide_from_catalog ? value.hide_from_catalog : false
            }
            products.push(temp)
            // if (data.length === products.length) {
            this.setState({ editProductData: products }, () => {
                this.setState({ visible: true })
            })
            // }
            // })
        })
    }

    getCsvData = (data) => {
        formatCsv(data, this.props.templateId)
            .then(val => {
                this.setState({
                    saveView: true,
                    csvData: val
                }, () => {
                    console.log('csvData', this.state.csvData)
                })
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    saveCsvData = (text) => {
        const { csvData } = this.state
        this.setState({ loading: true }, () => {
            let deleteParams = {
                listingId: localStorage.getItem('selectedListingId'),
                templateId: this.props.templateId
            }
            client.mutate({
                mutation: DELETE_PRODUCTS,
                variables: deleteParams
            }).then((res) => {
                var url = `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/insertProductCatalog`;

                let config = { method: 'POST', url: url, data: csvData }

                axios(config)
                    .then(res => {
                        this.setState({
                            loading: false,
                            uploadView: false,
                            saveView: false,
                            visible: false,
                            cancelView: false
                        })
                        message.success(text)

                    })
                    .catch(err => {
                        console.log('csv upload err', err)
                        this.setState({ loading: false })
                    })
            })
                .catch(err => {
                    console.log('csv delete err', err)
                    this.setState({ loading: false })
                });
        })
    }

    setLoader = (val) => {
        this.setState({ csvLoading: val })
    }

    removeCsv = () => {
        this.setState({ saveView: false })
    }

    onClickEdit = () => {
        this.setState({ uploadView: true, cancelView: true })
    }

    onClickCancel = () => {
        this.setState({ uploadView: false, cancelView: false, saveView: false })
    }

    editViaForm = () => {
        this.setState({ visible: true, editType: 'add', editProductData: [] })
    }

    onClickLoadMore = () => {
        const { editProductData } = this.state
        const limit = 10
        const offset = editProductData.length
        this.getOffsetProducts(offset, limit)
    }

    editFormData = (data) => {
        formatEditProductCatalog(data, this.props.templateId)
            .then(value => {
                console.log('value', value)
                this.setState({ finalEditValue: value.products }, () => {
                    console.log('finalEditValue origin', this.state.finalEditValue)
                    // this.saveEditedData()
                })
            })
            .catch(err => {
                console.log('error', err)
            })

    }

    saveEditedData = () => {
        const { finalEditValue } = this.state
        this.setState({ editLoading: true }, () => {
            if (finalEditValue.length > 0) {
                this.formatCategories(finalEditValue)
            }
        })
    }

    formatCategories = (productArr) => {
        const { lineItems } = this.state
        const lastdata = lineItems[lineItems.length - 1]
        console.log('lastdata', lastdata.product_serial_no)
        const lastIndex = lastdata.product_serial_no ? lastdata.product_serial_no : 0


        let createArr = _.filter(productArr, (o) => {
            if (o.type === 'CREATE') {
                return o
            }
        })

        let exceptCreate = _.filter(productArr, (o) => {
            if (o.type !== 'CREATE') {
                return o
            }
        })

        if (createArr.length > 0) {
            let tempArr = []
            createArr.map((val, index) => {
                let tempCount = index + 1
                let params = {
                    listingId: val.listing_id,
                    templateId: val.transaction_template_id,
                    category_name: val.category_name
                }
                this.checkCategories(params)
                    .then(ref => {
                        let tempId = this.generateUuid()
                        val.category_id = ref ? ref : tempId
                        val.createCategory = ref ? false : true
                        val.indexValue = lastIndex + tempCount
                        tempArr.push(val)
                        if (tempArr.length === createArr.length) {
                            const contact = _.concat(exceptCreate, tempArr)
                            if (contact.length === productArr.length) {
                                this.updateProducts(contact)
                            }
                        }
                    })
                    .catch(err => {
                        console.log('err', err)
                    })
            })
        } else {
            this.insertProducts(productArr)
        }
    }

    checkCategories(params) {
        return new Promise(function (resolve, reject) {
            client.query({ query: CHECK_CATEGORIES, variables: params })
                .then((response) => {
                    if (response.data && response.data.listing_product_categories && response.data.listing_product_categories.length > 0 && response.data.listing_product_categories[0] && response.data.listing_product_categories[0].category_id) {
                        let id = response.data.listing_product_categories[0].category_id
                        resolve(id)
                    } else {
                        resolve(null)
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    }

    generateUuid() {
        let MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
        let randomValue = Math.random().toString(36).substring(7)
        let uuid = uuidv1(randomValue, MY_NAMESPACE);
        return uuid
    }

    updateProducts = (data) => {
        const createCategories = _.filter(data, function (o) {
            if (o.createCategory) {
                return o
            }
        })

        if (createCategories.length > 0) {
            const finalCategories = []
            const { lineItems } = this.state
            const lastdata = lineItems[lineItems.length - 1]
            console.log('lastdata', lastdata.listing_product_category.category_serial_no)
            const categoryLastIndex = lastdata.listing_product_category &&
                lastdata.listing_product_category.category_serial_no ? lastdata.listing_product_category.category_serial_no : 0
            console.log('categoryLastIndex', categoryLastIndex)
            createCategories.map((res, index) => {
                let tempCount = index + 1
                let temp = {
                    category_name: res.category_name,
                    category_id: res.category_id,
                    listing_id: res.listing_id,
                    transaction_template_id: res.transaction_template_id,
                    category_serial_no: categoryLastIndex + tempCount
                }
                finalCategories.push(temp)
                if (createCategories.length === finalCategories.length) {
                    this.setState({ categoriesData: finalCategories }, () => {
                        this.insertCategories(data)
                    })
                }
            })
        } else {
            this.insertProducts(data)
        }
    }

    insertCategories = (data) => {
        const { categoriesData } = this.state
        if (categoriesData.length > 0) {
            const params = {
                categories: categoriesData
            }
            console.log('insertCategories params', params)
            client.mutate({
                mutation: CREATE_CATEGORIES,
                variables: params
            }).then((res) => {
                this.insertProducts(data)
            })
                .catch(err => {
                    console.log('create categories err', err)
                })
        }
    }

    insertProducts = (data) => {
        let updateArr = _.filter(data, (o) => {
            if (o.type === 'UPDATE') {
                return o
            }
        })
        console.log('updateArr', updateArr)
        if (updateArr.length > 0) {
            this.updateCategory(updateArr, data)
        } else {
            this.finalSaveProducts(data)
        }
    }

    updateCategory = (val, data) => {
        const params = {
            category_id: val[0].category_id,
            category_name: val[0].category_name
        }
        client.mutate({
            mutation: UPDATE_CATEGORY,
            variables: params
        }).then((ref) => {
            console.log('updateCategory ref', ref)
            this.finalSaveProducts(data)
        })
            .catch(err => {
                console.log('updateCategory err', err)
            })
    }

    finalSaveProducts = (data) => {
        const mutation = gql`${saveProducts(data)}`
        try {
            client.mutate({ mutation }).then((response) => {
                console.log('response1234567', response)
                message.success('Products updated successfully')
                this.setState({ visible: false, editLoading: false, editProductData: [] }, () => {
                })
            })
                .catch(err => {
                    console.log('error', err)
                });
        } catch (e) {
        }
    }

    updateTemplateSettings = () => {
        console.log('templateSettingsData', this.state.templateSettingsData, this.state.uploadImageView, this.state.duplicateUploadImageView)
        const { templateSettingsData, uploadImageView, duplicateUploadImageView } = this.state
        if (uploadImageView !== duplicateUploadImageView) {
            templateSettingsData.uploadProductImage = uploadImageView

            const params = {
                id: this.props.templateId,
                templateSettings: templateSettingsData
            }
            console.log('updated settings params', params)
            client.mutate({
                mutation: UPDATE_UPLOAD_IMAGE_STATUS,
                variables: params
            }).then((res) => {
                console.log('res update settings', res)
                message.success('Display product image status updated')
                this.setState({ duplicateUploadImageView: uploadImageView }, () => {
                    // this.props.settingsUpate()
                })
            })
                .catch(err => {
                    console.log('update template settings err', err)
                })
        }
    }

    viewProductImage = (image) => {
        console.log('image', image)
        this.setState({ imageUrl: image[0].url }, () => {
            this.setState({ imageVisible: true })
        })
    }

    imageCancel = () => {
        this.setState({ imageVisible: false, imageUrl: '' })
    }

    imageUpload = (e) => {
        console.log('product checked', e)
        this.setState({ uploadImageView: e.target.checked }, () => {
            this.updateTemplateSettings()
        })
    }

    getinventoryDetails = (eachProd, item) => {
        return new Promise(async (resolve, reject) => {
            client.query({
                query: GET_PRODUCT_INVENTORY,
                variables: {
                    templateId: eachProd.transaction_template_id,
                    productId: eachProd.product_id
                },
                fetchPolicy: 'no-cache'
            })
                .then((res) => {
                    console.log(res, 'rresrsr')
                    if (res && res.data && res.data.product_inventory && res.data.product_inventory.length > 0) {
                        let final = {
                            productId: eachProd.product_id,
                            productName: item.product_name,
                            onHandQuantity: res.data.product_inventory[0].onhand_quantity,
                            updatedAt: moment(res.data.product_inventory[0].updated_at).format("MMMM Do YYYY, h:mm a")
                        }
                        //product variants
                        if (eachProd && eachProd.product_variant_option) {
                            Object.keys(eachProd.product_variant_option).map(key => {
                                if (eachProd.product_variant_option[key]) {
                                    final[`${key}`] = eachProd.product_variant_option[key]
                                }
                            })
                        }
                        resolve(final);
                    } else {
                        let final = {
                            productId: eachProd.product_id,
                            productName: item.product_name,
                            onHandQuantity: '-',
                            updatedAt: '-'
                        }
                        //product variants
                        if (eachProd && eachProd.product_variant_option) {
                            Object.keys(eachProd.product_variant_option).map(key => {
                                if (eachProd.product_variant_option[key]) {
                                    final[`${key}`] = eachProd.product_variant_option[key]
                                }
                            })
                        }
                        resolve(final)
                    }
                });
        })
    }


    onProductInventoryCsv = () => {
        this.setState({
            loading: true,
            csvready: false
        }, () => {

            client.query({
                query: GET_PRODUCT_MASTER,
                variables: {
                    transaction_template_id: this.props.templateId
                },
                fetchPolicy: 'no-cache'
            })
                .then((response) => {
                    console.log('resppp', response)
                    if (response.data && response.data.product_master && response.data.product_master.length > 0) {
                        console.log('resppp', response)
                        let productList = response.data.product_master;
                        let allProductList = [];
                        let totalProductsAcrossOrders = 0;

                        let CompleteLoop = [];
                        let AllLoopOver = [];

                        let totalLength = productList.reduce((sum, { product_variant }) => sum + (product_variant.length === 0 ? 1 : product_variant.length), 0)

                        console.log(totalLength, 'ressult', allProductList)

                        productList.map((item, index) => {
                            console.log(item, 'titititi');

                            console.log(item, 'titititi');
                            if (item.product_variant && item.product_variant.length > 0) {
                                CompleteLoop = item.product_variant.map(async (eachProd, i) => {
                                    totalProductsAcrossOrders = totalProductsAcrossOrders + 1;
                                    return this.getinventoryDetails(eachProd, item).then((gotit) => {
                                        console.log(gotit, 'allprodlistiner');
                                        allProductList.push(gotit);
                                    });
                                });
                                Promise.all(CompleteLoop).then(() => {
                                    console.log(productList, allProductList.length, totalProductsAcrossOrders, totalLength, 'final')
                                    if (allProductList.length === totalLength && this.state.loading) {
                                        var withduplicates = allProductList.filter(value => JSON.stringify(value) !== '{}');
                                        console.log(withduplicates, 'completeProductList')
                                        this.setState({
                                            ProdInventoryList: withduplicates, visibleSave: false,
                                            csvready: true,
                                            loading: false
                                        }, () => {
                                            console.log(this.csvLink, 'csvlink');
                                            this.downloadCSV(withduplicates);
                                            allProductList = [];
                                            totalProductsAcrossOrders = 0;

                                            CompleteLoop = [];
                                            AllLoopOver = [];
                                            // if (this.csvLink) {
                                            //     this.csvLink.link.click();
                                            // }
                                        });
                                    }
                                });
                            }
                        });


                        AllLoopOver = productList.map((item, index) => {
                            console.log(item, 'titititi');

                            console.log(item, 'titititi');
                            if (item.product_variant && item.product_variant.length === 0) {
                                totalProductsAcrossOrders = totalProductsAcrossOrders + 1;
                                return this.getinventoryDetails(item, item).then((gotit) => {
                                    console.log(gotit, 'allprodlistiner');
                                    allProductList.push(gotit);
                                });
                            }
                        });


                        Promise.all(AllLoopOver).then(() => {
                            console.log('over over', allProductList, allProductList.length, totalLength);
                            if (allProductList.length === totalLength && this.state.loading) {
                                var withduplicates = allProductList.filter(value => JSON.stringify(value) !== '{}');
                                console.log(withduplicates, 'completeProductList')
                                this.setState({
                                    ProdInventoryList: withduplicates, visibleSave: false,
                                    csvready: true,
                                    loading: false
                                }, () => {
                                    this.ProdInventoryList = withduplicates;
                                    console.log(this.csvLink, 'csvlink');
                                    this.downloadCSV(withduplicates);
                                    allProductList = [];
                                    totalProductsAcrossOrders = 0;

                                    CompleteLoop = [];
                                    AllLoopOver = [];
                                    // if (this.csvLink) {
                                    //     this.csvLink.link.click();
                                    // }
                                });
                            }
                        })

                        console.log('allprodlist')
                    } else {
                        this.setState({
                            loading: false,
                            visibleSave: false,
                        })
                    }
                })
                .catch(err => {
                    console.log('resppperr', err)
                    this.setState({
                        loading: false
                    })
                });
        })
    }

    getFileName = () => {
        const listingName = localStorage.getItem('selectedListingName')
        var today = new Date();
        let initialDate = moment(today).format("MMMM Do YYYY, h:mm a");
        return listingName + '_' + initialDate + '.csv';
    }


    // downloadcsv = (data) => {
    //     console.log('donlodcsv', data)
    //     return (

    //         //       <CSVLink
    //         //       data={data}
    //         //       filename={this.getFileName()}
    //         //       // ref={(r) => this.csvLink = r}
    //         //       // ref={this.csvLink}

    //         //   />
    //         <CSVDownload data={data} target="_blank" />

    //     )
    // }


    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log('shuldupdate', nextState, this.csvLink)
    //     if (nextState.csvready) {
    //         if (this.csvLink) {
    //             this.csvLink.link.click();
    //             return true
    //         } else {
    //             return true
    //         }
    //     } else {
    //         return true
    //     }
    // }


    downloadCSV = (data) => {
        const listingName = localStorage.getItem('selectedListingName')
        var today = new Date();
        let initialDate = moment(today).format("MMMM Do YYYY, h:mm a");
        let name = listingName + '_' + initialDate + '.csv';

        let csvString = data;
        let isJSON = true;
        axios.post(
            `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/uploadCsvToFirebase`,
            { csvdata: csvString, csvname: name, isJson: isJSON }, {
        }).then(firstRes => {
            console.log("firstRes", firstRes);
            if (firstRes && firstRes.data && firstRes.data.apiUrl) {
                window.open(firstRes.data.apiUrl, '_self')
            }
        })
            .catch((e) => {
                console.log('The file saved to ERROR', e.message)
            });

    };


    render() {
        const { imageVisible, imageUrl, lineItems, uploadView, uploadImageView, totalCount, saveView, loading, dataloading, csvLoading, type, editProductData, cancelView, editLoading, editType } = this.state
        console.log('shuldupdaterender', this.state.ProdInventoryList)
        return (
            <div>
                {/* {downlodplease && */}

                {(loading || dataloading) ?
                    <Spin style={{ display: 'flex', justifyContent: 'center' }} />
                    :
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: 20 }}>
                            {!uploadView &&
                                // <Button type="primary" onClick={() => this.editViaForm()}>
                                //     {uploadView ? 'Add product' : 'Edit via form'}
                                // </Button>
                                <Button type="primary" onClick={() => this.editViaForm()}>
                                    Add product
                                </Button>
                            }

                            <div style={{
                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                marginTop: 0
                            }}>

                                <Button type="primary" style={{ marginLeft: 10 }} onClick={() => this.setState({ visibleSave: true })}>
                                    Download Product inventory CSV
                                    </Button>

                                <Popconfirm
                                    visible={this.state.visibleSave}
                                    title={"Are you sure to download Product Inventory as CSV?"}
                                    onConfirm={() => this.onProductInventoryCsv()}
                                    // onConfirm={() => this.downloadCSV()}
                                    onCancel={() => this.setState({ visibleSave: false })}
                                    // okText={this.downloadCSV()}
                                    cancelText="Cancel"
                                >
                                </Popconfirm>
                                {loading}

                                {/* <CSVLink
                                    data={this.state.ProdInventoryList}
                                    filename={this.getFileName()}
                                    className="hidden"
                                    // ref={(r) => this.csvLink = r}
                                    // ref={this.csvLink}
                                    ref={(r) => this.csvLink = r}
                                    target="_blank"

                                /> */}

                                {/* <Button onClick={() => this.onClearActionItem()}>Cancel</Button> */}
                            </div>


                            {uploadView ?
                                <Spin spinning={csvLoading} >
                                    <UploadCsv
                                        getData={this.getCsvData}
                                        loader={this.setLoader}
                                        screen=''
                                        remove={this.removeCsv}
                                    />
                                </Spin>
                                :
                                <div>

                                    {/* <Icon type="edit" style={{ fontSize: 17 }} onClick={() => this.onClickEdit()} /> */}

                                    <Button type="primary" style={{ marginLeft: 10 }} onClick={() => this.onClickEdit()}>
                                        Edit via csv
                                    </Button>
                                </div>
                            }

                            {cancelView &&
                                <Button htmlType="submit" onClick={() => this.onClickCancel()} >
                                    Cancel
                                </Button>
                            }

                            {saveView &&
                                <div>
                                    <Tooltip title="Save product">
                                        <Popconfirm
                                            title={`Are you sure. you want to ${type === 'upload' ? 'add' : 'replace'} this product ?`}
                                            onConfirm={() => this.saveCsvData('Csv uploaded successfully')}
                                            // onCancel={cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            {/* <Icon type="save" style={{ fontSize: 17 }} /> */}
                                            <Button type="primary" htmlType="submit" >
                                                Save
                                </Button>
                                        </Popconfirm>
                                    </Tooltip>
                                </div>
                            }



                            <Modal
                                title={editType === 'edit' ? "Edit Products" : "Add Products"}
                                width={'95%'}
                                visible={this.state.visible}
                                onOk={() => this.saveEditedData()}
                                okText={"Save"}
                                onCancel={() => this.setState({ visible: false, editProductData: [] })}
                            >
                                <Spin spinning={editLoading}>
                                    {editType === 'edit' &&
                                        <ProductCatalog
                                            productImage={uploadImageView}
                                            formatEditCatalog={this.editFormData}
                                            editData={editProductData}
                                            type={editType}
                                        />

                                    }
                                    {editType === 'add' &&
                                        <ProductCatalog
                                            productImage={uploadImageView}
                                            formatEditCatalog={this.editFormData}
                                            type={editType}
                                        />
                                    }
                                </Spin>
                                {/* {totalCount > editProductData.length && editProductData.length > 9 &&
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button type="primary" onClick={() => this.onClickLoadMore()} style={{ width: '100px' }} >
                                            Load more
                                </Button>
                                    </div>
                                } */}
                            </Modal>


                            <Modal className="modal-popup" visible={imageVisible} footer={null} onCancel={this.imageCancel}>
                                <img alt="example" style={{ width: '100%' }} src={imageUrl} />
                            </Modal>

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
                            <Checkbox checked={uploadImageView} onChange={(e) => this.imageUpload(e)}>Display product image</Checkbox>
                        </div>
                        {/* <p onClick={() => this.updateTemplateSettings()}>test save</p> */}
                        {lineItems && lineItems.length === 0 ?
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {/* No data */}
                            </div>
                            :
                            <div>
                                <div style={{ borderTop: '1px solid lightgrey', borderLeft: '1px solid lightgrey', borderRight: '1px solid lightgrey', marginTop: 20 }}>
                                    {lineItems && lineItems.length > 0 && lineItems.map((val, count) => (
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid lightgrey' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgrey', width: '25%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Name</b>
                                                    }
                                                    <p className="product-catogery-view" style={{ textAlign: 'center' }}>{val.product_name}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgrey', width: '20%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Category</b>
                                                    }
                                                    <p className="product-catogery-view" style={{ textAlign: 'center' }}>{val && val.listing_product_category && val.listing_product_category.category_name ? val.listing_product_category.category_name : '-'}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgrey', width: '15%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>CGST</b>
                                                    }
                                                    <p className="product-catogery-view" style={{ textAlign: 'center' }}>{val && val.product_taxes && val.product_taxes.CGST ? `${val.product_taxes.CGST}%` : '-'}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgrey', width: '15%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>SGST</b>
                                                    }
                                                    <p className="product-catogery-view" style={{ textAlign: 'center' }}>{val && val.product_taxes && val.product_taxes.SGST ? `${val.product_taxes.SGST}%` : '-'}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgrey', width: '15%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>IGST</b>
                                                    }
                                                    <p className="product-catogery-view" style={{ textAlign: 'center' }}>{val && val.product_taxes && val.product_taxes.IGST ? `${val.product_taxes.IGST}%` : '-'}</p>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid lightgrey', width: '25%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Config name</b>
                                                    }
                                                    {val && val.product_config.length > 0 && val.product_config.map(value => (
                                                        <p className="product-catogery-view" style={{ textAlign: 'center' }}>{value.name ? value.name : '-'}</p>
                                                    ))
                                                    }
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Config price</b>
                                                    }
                                                    {val && val.product_config.length && val.product_config.map(value => (
                                                        <p className="product-catogery-view" style={{ textAlign: 'center' }}>{value.price ? value.price : '-'}</p>
                                                    ))
                                                    }
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', width: '25%', borderLeft: '1px solid lightgrey' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Product image</b>
                                                    }
                                                    {val && val.product_image && val.product_image[0] ?
                                                        <Icon className="product-catogery-view" type="eye" style={{ textAlign: 'center' }} onClick={() => this.viewProductImage(val.product_image)} />
                                                        :
                                                        <p className="product-catogery-view" style={{ textAlign: 'center' }}>{'-'}</p>
                                                    }
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid lightgrey', width: '25%' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Hide Product</b>
                                                    }
                                                    <Checkbox className="product-catogery-view" style={{ textAlign: 'center' }} checked={val.hide_from_catalog}></Checkbox>
                                                </div>

                                                <div style={{ display: 'flex', flexDirection: 'column', width: '12%', borderLeft: '1px solid lightgrey' }}>
                                                    {count === 0 &&
                                                        <b className="product-table-header" style={{ borderBottom: '1px solid lightgrey', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px' }}>Edit</b>
                                                    }

                                                    <Icon className="product-catogery-view" type="edit" style={{ textAlign: 'center' }} onClick={() => this.formatProductData(val)} />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>

                        }
                    </div>
                }
            </div>
        )
    }
}

export default Product
