import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from 'react-avatar';

import { onNavStyleChange, toggleCollapsedSideNav } from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


class SidebarLogo extends Component {

  render() {
    const { width, themeType, navCollapsed } = this.props;
    let { navStyle } = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }
    const selectedListingName = localStorage.getItem('logoListingName');
    let selectedlistinglogo = localStorage.getItem('listinglogo');

    return (
      <div className="gx-layout-sider-header">

        {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">

          <i
            className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
            onClick={() => {
              if (navStyle === NAV_STYLE_DRAWER) {
                this.props.toggleCollapsedSideNav(!navCollapsed);
              } else if (navStyle === NAV_STYLE_FIXED) {
                this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
              } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
                this.props.toggleCollapsedSideNav(!navCollapsed);
              } else {
                this.props.onNavStyleChange(NAV_STYLE_FIXED)
              }
            }}
          />
        </div> : null}
        <div >
          <Link to="/" className="gx-site-logo" style={navStyle !== NAV_STYLE_MINI_SIDEBAR ? { display: 'flex', alignItems: 'center' } : null} >
            {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
              <img alt="" src={require("assets/images/w-logo.png")} style={{ width: 40, }} /> :
              themeType === THEME_TYPE_LITE ?
                <img alt="fdsfdf" src={selectedlistinglogo ? selectedlistinglogo : require("assets/images/logo.png")} style={{ width: 40 }} /> :
                <div>
                  {
                    selectedlistinglogo !== 'null' && selectedlistinglogo ?
                      <img alt="logo" src={selectedlistinglogo} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} /> :
                      <div>
                        {
                          selectedListingName &&
                          <Avatar
                            style={{ marginRight: 10 }}
                            className="avatarListingName"
                            fgColor="#FF0000" name={selectedListingName} size="40" round={true} />
                        }
                      </div>
                  }
                </div>
            }
            <span id="sidebar-header-text" style={{ color: '#fff', fontSize: 18, width: 160, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{selectedListingName ? selectedListingName : 'TOTELY'}</span>

          </Link>
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, width, navCollapsed } = settings;
  return { navStyle, themeType, width, navCollapsed }
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav
})(SidebarLogo);
