import React, { Component } from 'react'
import { Form, Input, Icon, message, Popconfirm, Modal, Button, Radio, Divider, Tag } from 'antd'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import client from '../../apollo/apollo'
import gql from 'graphql-tag';
import { GET_BUSINESS_CONTACT_ID } from 'graphQl/subscriptions';
import CircularProgress from "../../components/CircularProgress/index";
import {
    ADD_BUSINESS_CONTACT,
    EDIT_BUSINESS_CONTACT,
    DELETE_BUSINESS_CONTACT,
    ADD_CONTACT_MUTATION,
    ADD_MEMBER_TO_LISTING,
    ADD_LISTING_URL,
    CREATE_LISTING_VIA_BUSINESS_CONTACT,
    UPDATE_BUSINESS_CONTACT_ID
} from '../../graphQl/mutations'
import {
    CHECK_PHONE,
    CHECK_EMAIL_PHONE,
    CHECK_LISTING,
    GET_CONTACT_LIST,
    USER_WITH_PHONE_NUM_QUERY,
} from '../../graphQl/queries'
import UploadCsv from './uploadCsv'
import { CONFIG } from '../../config/config';
import { getENVConfig } from '../../util/common'
import { AddListingCommunictionProviderSMS, AddListingCommunictionProviderEMAIL } from '../../util/Listings'

const axios = require('axios');

const { TextArea } = Input;

export class BusinessContactDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledState: false,
            eidtField: false,
            businessData: {},
            id: '',
            showLoading: false,
            type: 'add',
            phoneValue: '',
            emailValue: '',
            contactVisible: false,
            ListingVisible: false,
            visibleCreateContactModal: false,
            visibleCreateListingModal: false,
            updateContact: false,
            tagClose: true,
            contactList: [],
            listingData: [],
            submittedValues: {},
            contactRadioValue: 0,
            listingRadioValue: 0,
            listingId: '',
            listingName: '',
            contactId: '',
            contactName: '',
            adminId: '',
            country: 'India',
            tableName: this.props.screenName,
            selectedFilterData: {}
        }
    }

    componentDidMount() {
        if (this.props && this.props.type === 'View' && this.props.todo && this.props.todo.id) {
            this.setState({
                disabledState: true,
                eidtField: true,
                id: this.props.todo.id
            }, () => {
                this.fetchBusinessContact()
            })
        }

        if (this.props.type === "filter") {
            if (document.getElementById("business-form-view")) {
                document.getElementById('link_default').style.display = 'none'
                document.getElementById('link_listing').style.display = 'none'
                document.getElementById('saveEditButton').style.display = 'none'
                document.getElementById('emailPhoneNumber').style.display = 'none'
            }
            this.setState({
                disabledState: this.props.isFieldsReadOnly,
                eidtField: this.props.isFieldsReadOnly,
                selectedFilterData: this.props.editFilterItem ? this.props.editFilterItem : {},
            }, () => {

                const { selectedFilterData } = this.state
                if (selectedFilterData && selectedFilterData.filterData) {
                    this.setState({
                        businessData: selectedFilterData.filterData,
                    }, () => {
                        console.log('contactData123', this.state.businessData)
                    })
                } else {
                    this.setState({
                        businessData: this.props.selectedData,
                    }, () => {
                    })
                }
            })
        }
    }

    componentWillReceiveProps(newProps) {
        //when save filter button clicked
        if (this.props.onSaveFilter !== newProps.onSaveFilter && newProps.onSaveFilter === true) {
            this.onSaveFilter()
        }
        // when edit button clicked
        if (this.props.isFieldsReadOnly !== newProps.isFieldsReadOnly) {
            this.setState({ disabledState: newProps.isFieldsReadOnly, eidtField: newProps.isFieldsReadOnly, })
        }

        //when search button clicked
        if (this.props.isClickedOk !== newProps.isClickedOk && newProps.isClickedOk === true) {
            this.onSearchData()
        }
    }

    //when searching data
    onSearchData = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log('linkCountStatus123', values)
            if (this.props.onShowFilterTag) {
                this.props.onShowFilterTag(values)
            }
        })
    }

    onSaveFilter = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            const { selectedFilterData } = this.state
            let variablesData = {}

            //for edit filter   
            if (Object.keys(selectedFilterData).length > 0) {
                let id = selectedFilterData && selectedFilterData.filterHistId ? selectedFilterData.filterHistId : null
                variablesData = {
                    filterHistId: id,
                    listingId: localStorage.getItem('selectedListingId'),
                    tableName: this.state.tableName,
                    refId: null,
                    createdBy: localStorage.getItem('uid'),
                    filterData: values
                }
                console.log('onSaveFilter23 update', variablesData)
                if (this.props.onSaveFilterData) {
                    this.props.onSaveFilterData(variablesData, 'update')
                }
            }
            //to create filter
            else {
                variablesData = {
                    listingId: localStorage.getItem('selectedListingId'),
                    tableName: this.state.tableName,
                    refId: null,
                    createdBy: localStorage.getItem('uid'),
                    filterData: values
                }
                console.log('onSaveFilter23 create', variablesData)
                if (this.props.onSaveFilterData) {
                    this.props.onSaveFilterData(variablesData, 'create')
                }
            }
        })
    }

    fetchBusinessContact = () => {
        this.setState({ showLoading: true }, () => {
            const variables = {
                id: this.state.id
            }
            const gqlValue = GET_BUSINESS_CONTACT_ID
            const query = gql`
                      ${gqlValue}
                    `
            client
                .subscribe({
                    query,
                    variables: variables
                })
                .subscribe(
                    res => {
                        if (res.data && res.data.business_contact && res.data.business_contact[0]) {
                            console.log('businessData', res.data.business_contact[0])
                            this.setState({
                                businessData: res.data.business_contact[0],
                                showLoading: false,
                                listingName: res.data.business_contact[0].mappedListing &&
                                    res.data.business_contact[0].mappedListing.name ? res.data.business_contact[0].mappedListing.name : '',
                                contactName: res.data.business_contact[0].contactId ? res.data.business_contact[0].contactId.firstName ? res.data.business_contact[0].contactId.firstName : '' : '',
                                tagClose: false,
                            }, () => {
                            })
                        } else {
                            this.setState({ showLoading: false })
                        }
                    },
                    err => {
                        this.setState({ showLoading: false })
                    })
        })
    }

    onClickDelete = () => {
        if (this.state.id) {
            const deleteVariable = {
                status: 'ARCHIVED',
                id: this.state.id
            }

            client.mutate({ mutation: DELETE_BUSINESS_CONTACT, variables: deleteVariable }).then((response) => {
                this.props.gobackCall()
                message.success('Business contact deleted SuccessFully')
            })
                .catch(err => {
                    message.error('Business contact delete Failed')
                });
        }

    }

    onClickEdit = () => {
        this.setState({
            // disabledState: false,
            type: 'edit',
            eidtField: false
            // tagClose: true
        })
    }

    //Getting the form values
    saveContact = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('value', values)
                this.setState({
                    submittedValues: values
                }, () => {
                    if (this.state.type === 'add') {
                        console.log('submittedValues', this.state.submittedValues)
                        this.createBusinessContact()

                    } else if (this.state.type === 'edit') {
                        this.editBusinessContact()
                    }
                })
            }
        })
    }

    checkValues = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log('value', values)
        })
    }

    checkIndividualContact = () => {
        const params = {
            listingId: localStorage.getItem('selectedListingId'),
            status: 'ACTIVE'
        }

        client
            .query({
                query: GET_CONTACT_LIST,
                variables: params
            })
            .then(
                response => {
                    console.log('then check', response)
                    if (response.data && response.data.contact && response.data.contact.length > 0) {
                        this.setState({ contactList: response.data.contact }, () => {
                            this.selectVisible('contactVisible')
                        })
                    }
                    else {
                        this.selectVisible('contactVisible')
                        // this.selectVisible('visibleCreateContactModal')
                    }
                })
            .catch(err => {
                console.log('check id', err)
            })

    }

    //For Create new individual contact
    cretaeIndividualContact = (type) => {
        console.log('cretaeIndividualContact')
        const values = {
            dealerName: this.props.form.getFieldValue('dealerName'),
            email: this.props.form.getFieldValue('email'),
            phone: this.props.form.getFieldValue('phone'),
            customerCode: this.props.form.getFieldValue('customerCode')
        }

        const variableObject = {
            firstName: values.dealerName,
            lastName: '',
            email: values.email || '',
            listingId: localStorage.getItem('selectedListingId'),
            phoneNumber: values.phone,
            notes: '',
            customAttributes: '',
            internalUserContactId: null,
            mappingUserId: type === 'userExists' ? this.state.adminId : null,
            createdBy: localStorage.getItem('uid'),
            creationSource: 'Bussiness',
            businessContactRole: 'Member',
            validEmail: false
        }
        console.log('variableObject', variableObject)


        client.mutate({ mutation: ADD_CONTACT_MUTATION, variables: variableObject }).then((response) => {
            console.log('ADD_CONTACT_MUTATION response', response)
            if (response && response.data && response.data.insert_contact && response.data.insert_contact.returning[0] && response.data.insert_contact.returning[0].id) {
                this.setState({
                    contactId: response.data.insert_contact.returning[0].id,
                    contactName: response.data.insert_contact.returning[0].firstName,
                    visibleCreateContactModal: false,
                    updateContact: true
                }, () => {
                    message.success('New contact created sucessfully!')
                })

            } else {
                message.error('Something went wrong')
            }
        })
            .catch(err => {
                console.log('error', err)
                message.error('Something went wrong')
            });
    }

    //Finally Create new business contact
    createBusinessContact = () => {
        const { submittedValues, contactId, listingId } = this.state
        console.log('submittedValues', submittedValues)
        if (contactId) {
            const variableObj = {
                listingId: localStorage.getItem('selectedListingId'),
                businessPhoneNumber: submittedValues.phone,
                businessEmail: submittedValues.email,
                address1: submittedValues.address1,
                address2: submittedValues.address2,
                city: submittedValues.city,
                state: submittedValues.state,
                country: submittedValues.country,
                postalCode: submittedValues.postalCode,
                internalBusinessContactId: submittedValues.customerCode,
                defaultUserContactId: contactId,
                mappedListingId: listingId ? listingId : null,
                name: submittedValues.dealerName,
                description: submittedValues.description || '',
                notes: submittedValues.notes || '',
                createdBy: localStorage.getItem('uid'),
                updatedBy: null
            }

            console.log('variableObj', variableObj)

            client.mutate({
                mutation: ADD_BUSINESS_CONTACT,
                variables: variableObj
            }).then((response) => {
                console.log('response', response)
                this.props.gobackCall()
                message.success(`Business contact added successFully`)
                if (this.state.updateContact && response.data && response.data.insert_business_contact && response.data.insert_business_contact.returning[0] && response.data.insert_business_contact.returning[0].id) {
                    this.updateBusinessId(response.data.insert_business_contact.returning[0].id)
                }
            })
                .catch(err => {
                    console.log('business err', err)
                    message.error(`Added business contact Failed`)
                });
        } else if (contactId === '') {
            message.error(`Please select contact`)
        }
        // else if (listingId === '') {
        //     message.error(`Please select list`)
        // }
        //  else if (contactId === '' && listingId === '') {
        //     message.error(`Please select contact and list`)
        // }
    }

    updateBusinessId = (id) => {
        console.log('updateBusinessId', this.state.contactId, id)
        client.mutate({
            mutation: UPDATE_BUSINESS_CONTACT_ID,
            variables: {
                id: this.state.contactId,
                businessContactId: id
            }
        }).then((response) => {
            console.log('response updateBusinessId', response)
        })
            .catch(err => {
                console.log('err', err)
            })
    }

    //For Edit business contact
    editBusinessContact = () => {
        const { submittedValues } = this.state
        const variableObj = {
            id: this.state.id,
            businessPhoneNumber: submittedValues.phone,
            businessEmail: submittedValues.email,
            address1: submittedValues.address1,
            address2: submittedValues.address2,
            city: submittedValues.city,
            internalBusinessContactId: submittedValues.customerCode,
            state: submittedValues.state,
            country: submittedValues.country,
            postalCode: submittedValues.postalCode,
            name: submittedValues.dealerName,
            description: submittedValues.description || '',
            notes: submittedValues.notes || '',
            updatedBy: localStorage.getItem('uid')
        }

        console.log('variableObj', variableObj);

        client.mutate({
            mutation: EDIT_BUSINESS_CONTACT,
            variables: variableObj
        }).then((response) => {
            this.setState({ eidtField: true })
            message.success(`Business contact edited successFully`)
        })
            .catch(err => {
                message.error(`Edited business contact Failed`)
            });
    }

    //For check the user id exists for this phone number
    CheckUser = (type) => {
        const { submittedValues } = this.state
        const phone = this.props.form.getFieldValue('phone')
        if (phone) {
            client
                .query({
                    query: USER_WITH_PHONE_NUM_QUERY,
                    variables: {
                        phone_number: phone
                    }
                })
                .then(
                    response => {
                        console.log('CheckUser', response)
                        if (response.data && response.data.user[0] && response.data.user[0].id) {
                            this.setState({ adminId: response.data.user[0].id }, () => {
                                if (type === 'Listing') {
                                    this.createListing('userExists')
                                } else if (type === 'Contact') {
                                    this.cretaeIndividualContact('userExists')
                                }
                            })
                        } else {
                            if (type === 'Listing') {
                                this.createListing('userNotExists')
                            } else if (type === 'Contact') {
                                this.cretaeIndividualContact('userNotExists')
                            }
                        }
                    })
                .catch(err => {
                    console.log(err)
                })
        } else {
            message.error('Please input phone number')
        }
    }

    //For Check the coutry, city, street name with existing list
    checkListingData = () => {
        const { submittedValues } = this.state
        this.setState({ listingData: [] })
        const name = this.props.form.getFieldValue('dealerName')
        const address1 = this.props.form.getFieldValue('address1')
        const address2 = this.props.form.getFieldValue('address2')
        const city = this.props.form.getFieldValue('city')
        const country = this.props.form.getFieldValue('country')

        if (name && address1 && city) {
            const checkListParams = {
                p_name: name,
                p_address1: address1,
                p_city: city,
                p_country: country
            }

            console.log('checkListParams', checkListParams)
            client
                .query({
                    query: CHECK_LISTING,
                    variables: checkListParams
                })
                .then(
                    response => {
                        console.log('response', response)
                        if (response.data && response.data.search_listings && response.data.search_listings.length > 0) {
                            this.setState({ listingData: response.data.search_listings }, () => {
                                this.selectVisible('ListingVisible')
                            })
                        }
                        else {
                            this.selectVisible('visibleCreateListingModal')
                            // this.createListing(type)
                        }
                    })
                .catch(err => {
                    console.log('checkListingData err', err)
                })
        } else {
            message.error('Please fill the above fields')
        }
    }

    //For Create new listing
    createListing = (type) => {
        const { submittedValues, contactId, adminId } = this.state
        const name = this.props.form.getFieldValue('dealerName')
        const address1 = this.props.form.getFieldValue('address1')
        const address2 = this.props.form.getFieldValue('address2')
        const city = this.props.form.getFieldValue('city')
        const country = this.props.form.getFieldValue('country')
        const state = this.props.form.getFieldValue('state')
        const postalCode = this.props.form.getFieldValue('postalCode')
        const phone = this.props.form.getFieldValue('phone')

        if (phone && postalCode && state && country && city && address1 && name) {
            const payload = {
                name: name,
                phoneNumber: phone,
                description: '',
                address1: address1,
                address2: address2,
                city: city,
                state: state,
                country: country,
                postalCode: postalCode,
                createdBy: adminId ? adminId : null,
                type: 'Bussiness',
                listingClaimed: false,
                listingCreationBySource: 'businessContactUpload',
                listingCreationBySourceListingId: localStorage.getItem('selectedListingId'),
                listing_img: null
            };
            console.log('createListing payload', payload, `${city}.${name}`, contactId)
            client.mutate({ mutation: CREATE_LISTING_VIA_BUSINESS_CONTACT, variables: payload }).then((response) => {
                if (response.data && response.data.insert_listing && response.data.insert_listing.returning[0] && response.data.insert_listing.returning[0].id) {
                    this.setState({ listingId: response.data.insert_listing.returning[0].id }, () => {
                        console.log('listingId', this.state.listingId)
                        if (type === 'userExists') {
                            console.log('userExists')
                            this.addListingAdmin(`${city}.${name}`)
                        } else if (type === 'userNotExists') {
                            console.log('userNotExists')
                            this.addListingUrl(`${city}.${name}`)
                        }
                    })
                }
            }).catch(err => {
                console.log('submit err', err)
                message.error('New Listing Creation Failed')
            });
        } else {
            message.error('Please fill the above fields')
        }
    }

    addListingAdmin = (name) => {
        const { adminId } = this.state
        const addMember = {
            "listingId": this.state.listingId,
            "role": "listingAdmin",
            "userId": adminId
        };
        if (adminId) {
            client.mutate({ variables: addMember, mutation: ADD_MEMBER_TO_LISTING }).then((result) => {
                console.log('result', result)
                this.addListingUrl(name)
            }).catch(err => {
                console.log('22222', err)
                message.error('New Listing Created but failed to add listing member');
            });
        } else {
            message.error('Listing member id missing')
        }
    }

    addListingUrl = (name) => {
        const { submittedValues } = this.state
        const addUrl = {
            "listingId": this.state.listingId,
            "urlName": name
        };
        client.mutate({ variables: addUrl, mutation: ADD_LISTING_URL }).then((urlres) => {
            console.log('urlres', urlres)
            if (urlres && urlres.data && urlres.data.insert_listing_url &&
                urlres.data.insert_listing_url.returning.length > 0 &&
                urlres.data.insert_listing_url.returning[0].listingId) {
                let listingId = urlres.data.insert_listing_url.returning[0].listingId
                AddListingCommunictionProviderEMAIL(listingId)
                    .then((res) => {
                    }).catch(err => {
                        message.error('Error on creating AddListingCommunictionProviderEMAIL')
                    })
                AddListingCommunictionProviderSMS(listingId)
                    .then((res) => {
                    }).catch(err => {
                        message.error('Error on creating AddListingCommunictionProviderSMS')
                    })
            }
            this.setState({ listingName: name, visibleCreateListingModal: false })
            // this.createBusinessContact()
            message.success('New listing created sucessfully!')
        }).catch((err) => {
            console.log('11111', err)
            message.error('New Listing Created but failed to create listing url');
        })
    }

    //Getting the  individual contact id which selected from modal
    onClickOk = () => {
        console.log('this.state.contactRadioValue', this.state.contactRadioValue)
        if (this.state.contactRadioValue) {
            this.setState({
                contactId: this.state.contactList[this.state.contactRadioValue - 1].id,
                contactName: this.state.contactList[this.state.contactRadioValue - 1].firstName,
                contactVisible: false
            }, () => {
                this.props.form.setFieldsValue({ individualContact: this.state.contactName })
                console.log('contactId', this.state.contactId, this.state.contactList[this.state.contactRadioValue - 1])
            })
        } else {
            message.error(`Please select contact`)
        }
    }

    //Getting the listing id which selected from modal
    onClickList = () => {
        if (this.state.listingRadioValue) {
            console.log('this.state.listingRadioValue', this.state.listingRadioValue)
            this.setState({
                listingId: this.state.listingData[this.state.listingRadioValue - 1].id,
                listingName: this.state.listingData[this.state.listingRadioValue - 1].name,
                ListingVisible: false
            }, () => {
                this.props.form.setFieldsValue({ listing: this.state.listingName })
                console.log('listingId', this.state.listingId, this.state.listingData[this.state.listingRadioValue - 1])
                // this.createBusinessContact()
            })
        } else {
            message.error(`Please select list`)
        }
    }

    //For choosing the existing contact
    selectIndividualContact = () => {
        const { contactList } = this.state
        return (

            <Modal
                title="Select Contact"
                visible={this.state.contactVisible}
                onOk={this.onClickOk}
                onCancel={() => { this.setState({ contactVisible: false }) }}
            >
                <div id="contact-Modal">
                    {contactList.length > 0 ?
                        <Radio.Group name="radiogroup" onChange={(val) => this.setState({ contactRadioValue: val.target.value }, () => { console.log('contactRadioValue', this.state.contactRadioValue) })}>
                            {contactList.map((val, index) => (
                                <div>
                                    <Radio value={index + 1} style={{ fontSize: 15, fontWeight: 600 }}>{val.firstName}</Radio>
                                    <p style={{ marginLeft: 25 }}>{val.email}{' '}{val.phoneNumber}</p>
                                    {index + 1 !== contactList.length &&
                                        <Divider style={{ width: '100%' }} />
                                    }
                                </div>
                            ))
                            }
                        </Radio.Group>
                        :
                        <p>No data</p>
                    }
                </div>
            </Modal>
        )
    }

    createContactModal = () => {
        return (
            <Modal
                visible={this.state.visibleCreateContactModal}
                footer={null}
                onCancel={() => { this.setState({ visibleCreateContactModal: false }) }}
                className="contact-close-icon"
            >
                <div>
                    <p>Entered phone number not matched in individual contacts. Please create new individual contact for that number.</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="primary" onClick={() => this.CheckUser('Contact')}>Create new contact</Button>
                    </div>
                </div>
            </Modal>
        )
    }

    //For choosing the existing listing
    selectListing = () => {
        const { listingData } = this.state
        return (

            <Modal
                title="Select List"
                visible={this.state.ListingVisible}
                onOk={this.onClickList}
                onCancel={() => { this.setState({ ListingVisible: false }) }}
            >
                <div id="contact-Modal">
                    <Radio.Group name="radiogroup" onChange={(val) => this.setState({ listingRadioValue: val.target.value }, () => { console.log('listingRadioValue', this.state.listingRadioValue) })}>
                        {listingData.map((val, index) => (
                            <div>
                                <Radio value={index + 1} style={{ fontSize: 15, fontWeight: 600 }}>{val.name}</Radio>
                                {index + 1 !== listingData.length &&
                                    <Divider style={{ width: '100%' }} />
                                }
                            </div>
                        ))
                        }
                    </Radio.Group>
                </div>
            </Modal>
        )
    }

    createListingModal = () => {
        return (
            <Modal
                className="contact-close-icon"
                visible={this.state.visibleCreateListingModal}
                footer={null}
                onCancel={() => { this.setState({ visibleCreateListingModal: false }) }}
            >
                <div>
                    <p>Entered address not matched in listings. Please create new listing for that address.</p>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="primary" onClick={() => this.CheckUser('Listing')}>Create new listing</Button>
                    </div>
                </div>
            </Modal>
        )
    }

    selectVisible = (stateValue) => {
        console.log('selectVisible', stateValue)
        this.setState({ [stateValue]: true })
    }

    bulkUpload = (data) => {
        console.log('bulkUpload123', data)

        this.setState({ showLoading: true }, () => {
            if (data.length > 0) {
                const temp = []
                data.map(val => {
                    let lowerCaseKeys = {};
                    for (let [key, value] of Object.entries(val)) {
                        lowerCaseKeys[key.toLowerCase()] = value;
                    }
                    temp.push(lowerCaseKeys)
                })
                if (temp.length === data.length) {
                    console.log('value123', temp)


                    const params = {
                        createdBy: localStorage.getItem('uid'),
                        listingId: localStorage.getItem('selectedListingId'),
                        value: temp
                    }
                    console.log('params', params)
                    var url = `${getENVConfig(CONFIG.CLOUDFUNCTIONS)}/businessContactBulkUpload`

                    //`http://localhost:5001/totely-devcodetest1/us-central1/businessContactBulkUpload`




                    let config = { method: 'POST', url: url, data: params }

                    axios(config)
                        .then(res => {
                            console.log('bulkUpload123', res)
                            this.setState({ showLoading: false })
                            if (res.data) {
                                console.log('res', res.data)
                                if (res.data === 'Csv list user had duplicate contacts') {
                                    message.error('Some user have duplicate contacts')
                                } else {
                                    message.success(res.data)
                                    this.props.gobackCall()
                                }
                            } else {
                                message.error("Something went wrong")
                            }
                        })
                        .catch(err => {
                            this.setState({ showLoading: false })
                            message.error("Something went wrong")
                            console.log(err)
                        });
                }

            } else {
                message.error('Something went wrong')
                this.setState({ showLoading: false })
            }
        })
    }

    setLoader = (val) => {
        this.setState({ showLoading: val })
    }

    render() {
        const { getFieldDecorator } = this.props.form
        const { type } = this.props
        const { disabledState, businessData, showLoading, country, contactName, listingName, tagClose, eidtField } = this.state

        let formRequiredValue = type === "filter" ? false : true

        return (
            <div style={{ paddingTop: '25px' }}>
                <div className="gx-flex-row">
                    {this.props && this.props.todo && this.props.todo.tags && this.props.todo.tags.tagValues && this.props.todo.tags.tagValues.length > 0 &&
                        <div style={{ paddingLeft: '10px' }}>
                            {this.props.todo.tags.tagValues.map((data, index) => {
                                return (
                                    <Tag color="green"> {data}</Tag>
                                );
                            })}
                        </div>
                    }
                </div>
                {showLoading &&
                    <div className="loader-view"
                        style={{ height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)' }}
                    >
                        <CircularProgress />
                    </div>
                }
                {!showLoading &&
                    <div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
                            {type === 'New' &&
                                <UploadCsv
                                    getData={this.bulkUpload}
                                    loader={this.setLoader}
                                    screen='BusinessContact'
                                />
                            }
                            {type === 'View' &&
                                <Popconfirm
                                    title="Are you sure delete this business contact?"
                                    onConfirm={() => { this.onClickDelete() }}
                                    okText="Yes"
                                    cancelText="No"
                                    placement="bottomRight"
                                >
                                    <Icon id="icon-view" type="delete" style={{ marginRight: 10 }} />
                                </Popconfirm>
                            }
                            <div id="saveEditButton">
                                {type === 'New' || eidtField === false ?
                                    <Icon id="icon-view" type="save" style={{ marginRight: 10 }} onClick={() => {
                                        this.saveContact()
                                    }} />
                                    :
                                    <Icon id="icon-view" type="edit" style={{ marginRight: 10 }} onClick={() => this.onClickEdit()} />
                                }
                            </div>
                        </div>

                        {this.selectIndividualContact()}
                        {this.selectListing()}
                        {this.createContactModal()}
                        {this.createListingModal()}
                        <Form id="business-form-view">

                            <Form.Item
                                label="Business name"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}
                                className="Sample"

                            >
                                {getFieldDecorator(type === "filter" ? 'name' : 'dealerName', {
                                    initialValue: businessData && businessData.name ? businessData.name : '',
                                    rules: [
                                        {
                                            required: formRequiredValue,
                                            message: 'Please input dealer name',
                                        },
                                    ],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            {
                                <div id="emailPhoneNumber">
                                    <Form.Item label="Business email"
                                        labelCol={{ xs: 24, sm: 5 }}
                                        wrapperCol={{ xs: 24, sm: 12 }}>
                                        {getFieldDecorator('email', {
                                            initialValue: businessData ? businessData.businessEmail ? businessData.businessEmail : '' : '',
                                            rules: [
                                                {
                                                    type: 'email',
                                                    message: 'The input is not valid E-mail',
                                                },
                                                {
                                                    required: false,
                                                    message: 'Please input E-mail',
                                                },
                                            ],
                                        })(<Input disabled={eidtField} onChange={(val) => { this.setState({ emailValue: val.target.value }) }} />)}
                                    </Form.Item>
                                    <Form.Item label="Business phone number"
                                        labelCol={{ xs: 24, sm: 5 }}
                                        wrapperCol={{ xs: 24, sm: 12 }}>
                                        {getFieldDecorator(type === "filter" ? 'phoneNumber' : 'phone', {
                                            initialValue: businessData && businessData.businessPhoneNumber ? businessData.businessPhoneNumber : '',
                                        })(
                                            <PhoneInput
                                                country={'in'}
                                                enableAreaCodes={true}
                                                enableSearch={true}
                                                autoFormat={false}
                                                inputStyle={{ width: '100%' }}
                                                disabled={eidtField}
                                                onChange={(val) =>
                                                    this.setState({ phoneValue: val })
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </div>
                            }
                            <Form.Item
                                label="Business code"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}
                                className="Sample"

                            >
                                {getFieldDecorator(type === "filter" ? 'internalBusinessContactId' : 'customerCode', {
                                    initialValue: businessData && businessData.internalBusinessContactId ? businessData.internalBusinessContactId : '',
                                    rules: [
                                        {
                                            required: formRequiredValue,
                                            message: 'Please input customer code',
                                        },
                                    ],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item className="Sample"
                                label="Address1"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}
                            >
                                {getFieldDecorator('address1', {
                                    initialValue: businessData && businessData.address1 ? businessData.address1 : '',
                                    rules: [
                                        {
                                            required: formRequiredValue,
                                            message: 'Please input address1',
                                        },
                                    ],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item className="Sample"
                                label="Address2"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}
                            >
                                {getFieldDecorator('address2', {
                                    initialValue: businessData && businessData.address2 ? businessData.address2 : '',
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please input address2',
                                        },
                                    ],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item className="Sample"
                                label="City"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}
                            >
                                {getFieldDecorator('city', {
                                    initialValue: businessData && businessData.city ? businessData.city : '',
                                    rules: [
                                        {
                                            required: formRequiredValue,
                                            message: 'Please input city',
                                        },
                                    ],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item
                                label="Postal code"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}>
                                {getFieldDecorator('postalCode', {
                                    initialValue: businessData && businessData.postalCode ? businessData.postalCode : '',
                                    rules: [{ required: formRequiredValue, message: 'Please input postal code', }],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item className="Sample"
                                label="State"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}
                            >
                                {getFieldDecorator('state', {
                                    initialValue: businessData && businessData.state ? businessData.state : '',
                                    rules: [
                                        {
                                            required: formRequiredValue,
                                            message: 'Please input state',
                                        },
                                    ],
                                })(<Input disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item
                                label="Country"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}>
                                {getFieldDecorator('country', {
                                    initialValue: businessData && businessData.country ? businessData.country : country,
                                    rules: [{ required: formRequiredValue, message: 'Please input country', }],
                                })(
                                    <CountryDropdown
                                        value={country}
                                        disabled={eidtField}
                                        style={{ width: '100%', height: 40, borderRadius: 5 }}
                                    />
                                )}
                            </Form.Item>
                            <div id={"link_default"}>
                                <Form.Item
                                    label="Link default contact"
                                    labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}>
                                    {getFieldDecorator('individualContact', {
                                        initialValue: contactName,
                                        value: contactName,
                                        rules: [{ required: formRequiredValue, message: 'Please input individual contact', }],
                                    })(
                                        // <Input
                                        //     onClick={() => { this.checkIndividualContact() }}
                                        //     disabled={disabledState}
                                        // />
                                        <div>
                                            {contactName ?
                                                <Tag
                                                    style={{ height: 30 }}
                                                    closable={tagClose}
                                                    onClose={() => {
                                                        this.setState({ contactName: '' })
                                                        this.props.form.setFields({ individualContact: '' })
                                                    }}
                                                >
                                                    {contactName}
                                                </Tag>
                                                :
                                                <Button
                                                    onClick={() => { this.checkIndividualContact() }}
                                                >
                                                    <Icon type="plus" />
                                                </Button>
                                            }
                                        </div>
                                    )}
                                </Form.Item>
                            </div>
                            <div id={"link_listing"}>
                                <Form.Item
                                    label="Link listing"
                                    labelCol={{ xs: 24, sm: 5 }}
                                    wrapperCol={{ xs: 24, sm: 12 }}>
                                    {getFieldDecorator('listing', {
                                        initialValue: listingName,
                                        rules: [{ required: false, message: 'Please input listing contact', }],
                                    })(
                                        // <Input
                                        //     onClick={() => { this.checkListingData() }}
                                        //     disabled={disabledState}
                                        // />
                                        <div>
                                            {listingName ?
                                                <Tag
                                                    style={{ height: 30 }}
                                                    closable={tagClose}
                                                    onClose={() => {
                                                        this.setState({ listingName: '' })
                                                        this.props.form.setFields({ listing: '' })
                                                    }}
                                                >
                                                    {listingName}
                                                </Tag>
                                                :
                                                <Button
                                                    onClick={() => { this.checkListingData() }}
                                                >
                                                    <Icon type="plus" />
                                                </Button>
                                            }
                                        </div>
                                    )}
                                </Form.Item>
                            </div>
                            <Form.Item
                                label="Description"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}>
                                {getFieldDecorator('description', {
                                    initialValue: businessData && businessData.description ? businessData.description : '',
                                    rules: [{ required: false, }],
                                })(<TextArea rows={3} disabled={eidtField} />)}
                            </Form.Item>
                            <Form.Item
                                label="Notes"
                                labelCol={{ xs: 24, sm: 5 }}
                                wrapperCol={{ xs: 24, sm: 12 }}>
                                {getFieldDecorator('notes', {
                                    initialValue: businessData && businessData.notes ? businessData.notes : '',
                                    rules: [{ required: false, }],
                                })(<TextArea rows={3} disabled={eidtField} />)}
                            </Form.Item>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

const WrappedApp = Form.create()(BusinessContactDetail);
export default WrappedApp
