import React, { Component } from "react";
import { Collapse } from 'antd';
import EditDeliveryDetails from './EditDeliveryDetails';
import EditPickupDetails from './EditPickupDetails';
import EditOrderDetails from './EditOrderDetails';
import AdditionalFees from './AdditionalFees';
import Discounts from './Discounts';
import { THEME_COLOR_SELECTION_CUSTOMIZE } from "constants/ThemeSetting";

const { Panel } = Collapse;


class EditEcommerce extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fieldValues: {}
        }
    };

    componentWillMount() {
        console.log(this.props.editData, 'this.props.editData');
        if (this.props.editData) {
            this.setState({ fieldValues: this.props.editData.formData.editedBySellerValues });
        }
        if (!this.props.editData && this.props.initialValue) {
            console.log(this.props.initialValue);
            if (this.props.initialValue.formData && this.props.initialValue.formData.DeliveryDetails) {
                if (this.props.initialValue.formData.DeliveryDetails.selectedOption === "Delivery") {
                    let deliveryDetails = [];
                    Object.keys(this.props.initialValue.formData.DeliveryDetails.detail).forEach((field) => {
                        let updatedObj = {
                            attributeName: field,
                            attributeValue: this.props.initialValue.formData.DeliveryDetails.detail[field],
                        };
                        deliveryDetails.push(updatedObj)
                    })
                    this.state.fieldValues.EditedDeliveryDetails = deliveryDetails;
                } else {
                    let pickupDetails = [];
                    if (this.props.initialValue && this.props.initialValue.formData && this.props.initialValue.formData.DeliveryDetails && this.props.initialValue.formData.DeliveryDetails.detail) {
                        Object.keys(this.props.initialValue.formData.DeliveryDetails.detail).forEach((field) => {
                            let updatedObj = {
                                attributeName: field,
                                attributeValue: this.props.initialValue.formData.DeliveryDetails.detail[field],
                            };
                            pickupDetails.push(updatedObj)
                        })
                    }
                    this.state.fieldValues.EditedPickupDetails = pickupDetails;
                }
            }
        }
    }

    onDeliveryDetailEdit = async (DeliveryDetails) => {
        let DeliveryDetailsDeletedRemoved = [];
        if (DeliveryDetails.length > 0) {
            DeliveryDetailsDeletedRemoved = DeliveryDetails.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            DeliveryDetailsDeletedRemoved = DeliveryDetails
        }

        this.state.fieldValues.EditedDeliveryDetails = await DeliveryDetailsDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.EditEcommerceOrder(this.state.fieldValues);
        })
    }

    onOrderDetailEdit = async (orderDetails) => {
        let orderDetailsDeletedRemoved = [];
        if (orderDetails.length > 0) {
            orderDetailsDeletedRemoved = orderDetails.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            orderDetailsDeletedRemoved = orderDetails
        }

        this.state.fieldValues.EditedOrderDetails = await orderDetailsDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.EditEcommerceOrder(this.state.fieldValues);
        })
    }

    onPickupDetailEdit = async (PickupDetail) => {
        let PickupDetailDeletedRemoved = [];
        if (PickupDetail.length > 0) {
            PickupDetailDeletedRemoved = PickupDetail.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            PickupDetailDeletedRemoved = PickupDetail
        }

        this.state.fieldValues.EditedPickupDetails = await PickupDetailDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.EditEcommerceOrder(this.state.fieldValues);
        })
    }


    onAdditionalFeesChange = async (additionalFees) => {
        let additionalFeesDeletedRemoved = [];
        if (additionalFees.length > 0) {
            additionalFeesDeletedRemoved = additionalFees.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            additionalFeesDeletedRemoved = additionalFees
        }

        this.state.fieldValues.AdditionalFees = await additionalFeesDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.EditEcommerceOrder(this.state.fieldValues);
        })
    }

    onDiscountChange = async (discounts) => {
        let discountsDeletedRemoved = [];
        if (discounts.length > 0) {
            discountsDeletedRemoved = discounts.filter((i) => { console.log(i, 'ifromdet'); return i.isDelete !== true });
        } else {
            discountsDeletedRemoved = discounts
        }

        this.state.fieldValues.Discounts = await discountsDeletedRemoved;

        this.setState({ fieldValues: this.state.fieldValues }, () => {
            this.props.EditEcommerceOrder(this.state.fieldValues);
        })
    }


    render() {
        console.log(this.state.fieldValues, 'this.state.fieldValues');

        return (
            <div>
                <Collapse>
                    <Panel header="Delivery Details Section" key="1">
                        <EditDeliveryDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.EditedDeliveryDetails} onDeliveryDetailEdit={this.onDeliveryDetailEdit.bind(this)} />
                    </Panel>
                    <Panel header="Pickup Details Section" key="2">
                        <EditPickupDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.EditedPickupDetails} onPickupDetailEdit={this.onPickupDetailEdit.bind(this)} />
                    </Panel>
                    <Panel header="Order Details Section" key="3">
                        <EditOrderDetails isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.EditedOrderDetails} onOrderDetailEdit={this.onOrderDetailEdit.bind(this)} />
                    </Panel>
                    <Panel header="Additional Fees Section" key="4">
                        <AdditionalFees isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.AdditionalFees} onAdditionalFeesChange={this.onAdditionalFeesChange.bind(this)} />
                    </Panel>
                    <Panel header="Discounts Section" key="5">
                        <Discounts isEdit={this.props.editData ? true : false} editData={this.state.fieldValues && this.state.fieldValues.Discounts} onDiscountChange={this.onDiscountChange.bind(this)} />
                    </Panel>
                </Collapse>
            </div>
        );
    }

}
export default EditEcommerce;

