import React, { Component } from 'react';
import Form from "react-jsonschema-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, message, Spin } from 'antd'


class TransactionFormData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            templateData: this.props && this.props.templateData ? this.props.templateData : {}
        }
    }

    componentDidMount() {
        this.setState({ templateData: this.props && this.props.templateData ? this.props.templateData : {} })
    }

    componentWillReceiveProps(newProps) {
        this.setState({ templateData: newProps && newProps.templateData ? newProps.templateData : {} })
    }

    onChangeFormValue = (event) => {
        if (this.props.onChangeFormValue) {
            this.props.onChangeFormValue(event)
        }
    }

    render() {
        const { templateData } = this.state

        return (
            <div className="form">
                <Form
                    //    id='variabl-form-view'
                    className="popup-form-view"
                    schema={templateData.formDefinition.JSONSchema}
                    formData={this.props.formdata}
                    ref={el => this.form = el}
                    uiSchema={templateData.formDefinition.UISchema}
                    children={<br />}
                    onChange={(event) => this.onChangeFormValue(event)}
                    disabled={this.props.disabledValue}
                />
            </div>
        );
    }
}

export default TransactionFormData;