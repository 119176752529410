import React, { Component } from "react";
import { Icon, Modal, Tag, Button, Input, message, Popconfirm } from 'antd'
import TransactionFilter from './TransactionFilter'
import NewBroadCast from '../../components/Notify/NewBroadCast'
import BusinessContactDetail from '../../components/contact/businessContactDetail'
import ContactDetail from '../../components/contact/contactDetail'
import { INSERT_FILTER, UPDATE_FILTER, DELETE_FILTER } from '../../graphQl/mutations'
import client from '../../apollo/apollo'
import { cloneDeep } from "apollo-utilities";

let tableName = "transaction"

class FilterIcon extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpenFilter: this.props.isOpenFilterModal,
            screenFor: this.props.screenName,
            selectedTemplateId: this.props.templateId,
            optionFor: this.props.option,
            filterListData: [],
            selectedFilteritem: {},
            selectedFilterData: {},
            templateData: this.props.transactionTemplateData,
            filterName: '',
            isClickedOk: false,
            onSaveFilter: false,
            editItem: {},
            isOpenSaveFilter: false,
            isFieldsReadOnly: false
        }
    }

    componentDidMount() {
        this.setState({ templateData: this.props.transactionTemplateData, selectedFilterData: this.props.filteredData })
    }

    componentWillReceiveProps(newProps) {
        if (newProps.transactionTemplateData !== this.props.transactionTemplateData) {
            this.setState({ templateData: newProps.transactionTemplateData })
        }
        if (newProps.filteredData !== this.props.filteredData) {
            this.setState({ selectedFilterData: newProps.filteredData })
        }
        if ((this.props.editFilterItem !== newProps.editFilterItem)) {
            if (Object.keys(newProps.editFilterItem).length > 0) {
                this.setState({ isOpenFilter: true, editItem: newProps.editFilterItem }, () => {
                    if (this.state.editItem) {
                        this.setState({
                            filterName: this.state.editItem.filterName,
                            isOpenSaveFilter: true,
                            isFieldsReadOnly: true
                        })
                    }
                })
            } else {
                this.setState({
                    isOpenFilter: false, editItem: {}, isFieldsReadOnly: false,
                    isOpenSaveFilter: false,
                }
                )
            }

        }
    }

    onOpenFilterModal = () => {
        this.setState({ isOpenFilter: true }, () => {
            if (this.props.callFilterList) {
                this.props.callFilterList()
            }
        })
    }

    renderModalTitle = () => {
        return (
            <div style={{ display: 'flex' }}>
                <h6>{this.state.filterName}</h6>

            </div>
        )
    }

    onDeleteFilterItem = () => {
        if ((Object.keys(this.state.editItem).length > 0) && this.state.editItem.filterHistId) {
            let variableData = {
                filterHistId: this.state.editItem.filterHistId
            }
            client.mutate({ mutation: DELETE_FILTER, variables: variableData }).then((response) => {
                message.success('Filter Deleted SuccessFully')
                this.onCloseModal()
            })
                .catch(err => {
                    message.error('Filter Deleted Failed')
                });
        }
    }

    onCancelSaveFilter = () => {
        if ((Object.keys(this.state.editItem).length === 0)) {
            this.setState({ isOpenSaveFilter: false, filterName: '', onSaveFilter: false })
        } else {
            this.setState({ isFieldsReadOnly: true, onSaveFilter: false })
        }
    }

    ResetFilterData = () => {
        if (this.props.onResetFilterData) {
            this.props.onResetFilterData()
            this.onCloseModal()
        }
    }

    renderFooter = () => {
        const { isFieldsReadOnly } = this.state
        return (
            <div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {
                        ((Object.keys(this.state.editItem).length === 0 && this.state.isOpenSaveFilter === false)) &&
                        <Button type="primary"
                            onClick={() => this.onOpenSaveFilterField()}
                        >Save Filter</Button>
                    }
                    <div>
                        {
                            ((Object.keys(this.state.editItem).length === 0 && this.state.isOpenSaveFilter === false)) &&
                            <Button
                                onClick={() => this.ResetFilterData()}
                            >Reset</Button>
                        }

                        {(((Object.keys(this.state.editItem).length === 0 && this.state.isOpenSaveFilter === false))
                            || (isFieldsReadOnly === true && Object.keys(this.state.editItem).length > 0)) &&
                            <Button type="primary" onClick={() => this.onSearchData()}>Search</Button>
                        }
                    </div>

                    {(Object.keys(this.state.editItem).length > 0 && this.state.isFieldsReadOnly === true) &&
                        <div>
                            <Button type="primary"
                                onClick={() => this.setState({ isFieldsReadOnly: !this.state.isFieldsReadOnly })}
                            >Edit</Button>

                            <Popconfirm
                                title="Are you sure to delete this filter?"
                                onConfirm={() => this.onDeleteFilterItem()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="danger"
                                >Delete</Button>
                            </Popconfirm>
                        </div>
                    }

                </div>

                <div>
                    {
                        ((this.state.isOpenSaveFilter === true &&
                            Object.keys(this.state.editItem).length === 0)
                            || (isFieldsReadOnly === false && Object.keys(this.state.editItem).length > 0)) &&
                        <div style={{
                            display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                            width: '100%', marginTop: 5
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '77%'
                            }}>
                                <h6 style={{
                                    width: '21%',
                                    marginTop: 5
                                }}>Filter Name:</h6>
                                <Input
                                    style={{ marginLeft: 8 }}
                                    onChange={(event) => this.setState({ filterName: event.target.value })}
                                    value={this.state.filterName}
                                />

                            </div>
                            <div style={{ marginTop: -10 }}>
                                <Button type="primary" onClick={() => this.onSaveFilter()} style={{ marginTop: 10 }}>Save</Button>
                                <Button onClick={() => this.onCancelSaveFilter()} style={{ marginTop: 10 }}>Cancel</Button>
                            </div>
                        </div>
                    }
                </div>


            </div>
        )
    }

    onOpenSaveFilterField = () => {
        this.setState({ isOpenSaveFilter: true })
    }

    //when search button clicked
    onSearchData = () => {
        this.setState({ isClickedOk: true, onSaveFilter: false })
    }

    //when searching filter data, show chip tag and get filtered results
    onShowFilterTag = (data) => {

        this.setState({ selectedFilterData: data, isOpenFilter: false, isClickedOk: false }, () => {
            if (this.props.onFilterData) {
                this.props.onFilterData(data, data)
            }
        })
    }

    onSaveFilter = () => {
        this.setState({ onSaveFilter: true })
    }

    onSaveFilterData = (variableData, option) => {
        if (this.state.filterName) {

            let data = cloneDeep(variableData)
            variableData.filterName = this.state.filterName
            let value = option === "create" ? INSERT_FILTER : UPDATE_FILTER
            client.mutate({ mutation: value, variables: variableData }).then((response) => {
                message.success('Filter saved SuccessFully')
                this.onCloseModal()
            })
                .catch(err => {
                    message.error('Filter saved Failed')
                });
        } else {
            message.warn('Please add filter name')
            this.setState({ onSaveFilter: false })
        }
    }

    onCloseModal = (option) => {
        this.setState({ isOpenFilter: false, isClickedOk: false, onSaveFilter: false, filterName: "" }, () => {
            if (this.props.onCloseFilterModal) {
                this.props.onCloseFilterModal(option)
            }
        })
    }

    onCloseFilterTag(key) {
        let filterData = this.state.selectedFilterData
        delete filterData[key];
        this.setState({ selectedFilterData: filterData, isOpenFilter: false, isClickedOk: false }, () => {
            if (this.props.onFilterData) {
                this.props.onFilterData(filterData, filterData)
            }
        })
    }

    renderTag = (value, key) => {
        return (
            <Tag color="magenta" closable
                key={key}
                onClose={() => this.onCloseFilterTag(key)}
            >{value}</Tag>
        )
    }

    render() {
        const { optionFor, filterListData, selectedFilterData } = this.state
        return (
            <div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* chip */}
                    <div>
                        {
                            selectedFilterData && Object.keys(selectedFilterData).map(function (key) {
                                if (key !== "assignedTo") {
                                    let value = selectedFilterData[key]
                                    //for array
                                    if (Array.isArray(value)) {
                                        return value.map((res, index) => {
                                            return <Tag color="magenta">{res}</Tag>
                                        })
                                    }
                                    //for object
                                    if (typeof value === "object" && value) {
                                        return Object.keys(value).map(function (res, index) {
                                            if (value[res]) {
                                                return <Tag color="magenta" >{value[res]}</Tag>
                                            }
                                        })
                                    }
                                    if (typeof value === "string" && value) {
                                        return <Tag color="magenta" >{value}</Tag>
                                    }
                                    if (typeof value === "number" && value) {
                                        return <Tag color="magenta" >{value}</Tag>
                                    }
                                    if (typeof value === "boolean" && value) {
                                        return <Tag color="magenta" >{value.toString()}</Tag>
                                    }
                                }
                            })
                        }
                        {/* {
                            selectedFilterData && Object.keys(selectedFilterData).map(function (key) {
                                if (key !== "assignedTo") {
                                    let value = selectedFilterData[key]
                                    //for array
                                    if (Array.isArray(value)) {
                                        return value.map((res, index) => {
                                            return this.renderTag(res, key)
                                        })
                                    }
                                    //for object
                                    if (typeof value === "object" && value) {
                                        return Object.keys(value).map(function (res, index) {
                                            if (value[res]) {
                                                console.log("res123", res, value[res])
                                                return <Tag color="magenta" closable
                                                    key={key}
                                                    onClose={() => this.onCloseFilterTag(key)}
                                                >{value}</Tag>
                                                // console.log("res123", res, value[res])
                                                // return this.renderTag(value[res], key)
                                            }
                                        }, this)
                                    }
                                    if (typeof value === "string" && value) {
                                        return this.renderTag(value, key)
                                    }
                                    if (typeof value === "number" && value) {
                                        return this.renderTag(value, key)
                                    }
                                    if (typeof value === "boolean" && value) {
                                        return this.renderTag(value.toString(), key)
                                    }
                                }
                            }, this)
                        } */}
                    </div>

                    {/* filter icon */}
                    {/* {this.state.screenFor === "transaction" && */}
                    {/* <Tag color="magenta" closable
                                       onClose={this.onCloseFilterTag.bind(this, key)}
                                        >{value}</Tag> */}
                    <Icon type="filter" style={{ fontSize: '20px' }} onClick={() => this.onOpenFilterModal()} />
                    {/* } */}
                </div>

                {
                    this.state.isOpenFilter === true &&
                    <div>
                        <Modal
                            title={this.renderModalTitle()}
                            visible={this.state.isOpenFilter}
                            footer={this.renderFooter()}
                            width={800}
                            onCancel={() => this.onCloseModal(true)}
                            maskClosable={false}
                        >
                            {((this.state.screenFor === "transaction") || (this.state.screenFor === "received_transaction")) &&
                                <TransactionFilter templateId={this.state.selectedTemplateId}
                                    isOpenFilter={this.state.isOpenFilter}
                                    onCloseModal={this.onCloseModal}
                                    onShowFilterTag={this.onShowFilterTag}
                                    transactionTemplateData={this.state.templateData}
                                    screenName={this.state.screenFor}
                                    isClickedOk={this.state.isClickedOk}
                                    onSaveFilter={this.state.onSaveFilter}
                                    onSaveFilterData={this.onSaveFilterData}
                                    editFilterItem={this.state.editItem}
                                    isFieldsReadOnly={this.state.isFieldsReadOnly}
                                    selectedData={this.state.selectedFilterData}
                                    recipientUserId={this.props.recipientId}
                                    receivedType={this.props.receivedUserType}
                                />
                            }
                            {this.state.screenFor === "business_contact" &&
                                <BusinessContactDetail
                                    type="filter"
                                    screenName={this.state.screenFor}
                                    onSaveFilter={this.state.onSaveFilter}
                                    onSaveFilterData={this.onSaveFilterData}
                                    editFilterItem={this.state.editItem}
                                    isFieldsReadOnly={this.state.isFieldsReadOnly}
                                    onShowFilterTag={this.onShowFilterTag}
                                    isClickedOk={this.state.isClickedOk}
                                    selectedData={this.state.selectedFilterData}
                                />
                            }
                            {this.state.screenFor === "individual_contact" &&
                                <ContactDetail
                                    type="filter"
                                    screenName={this.state.screenFor}
                                    onSaveFilter={this.state.onSaveFilter}
                                    onSaveFilterData={this.onSaveFilterData}
                                    editFilterItem={this.state.editItem}
                                    isFieldsReadOnly={this.state.isFieldsReadOnly}
                                    onShowFilterTag={this.onShowFilterTag}
                                    isClickedOk={this.state.isClickedOk}
                                    selectedData={this.state.selectedFilterData}
                                />
                            }
                            {((this.state.screenFor === "message_sms") ||
                                (this.state.screenFor === "message_email")) &&
                                <NewBroadCast
                                    broadcastType={this.props.broadcastType}
                                    optionFor="messageFilter"
                                    isClickedOk={this.state.isClickedOk}
                                    onShowFilterTag={this.onShowFilterTag}
                                    onSaveFilter={this.state.onSaveFilter}
                                    onSaveFilterData={this.onSaveFilterData}
                                    screenName={this.state.screenFor}
                                    editFilterItem={this.state.editItem}
                                    isFieldsReadOnly={this.state.isFieldsReadOnly}
                                    selectedData={this.state.selectedFilterData}
                                />
                            }
                        </Modal>
                    </div>
                }
            </div>
        );
    }

};

export default FilterIcon;
