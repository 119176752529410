import React, { Component } from 'react';
import Form from "react-jsonschema-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, message, Spin, Input } from 'antd'
import { UPDATE_TRANSACTION, UPDATE_CONTACT_FROM_CONTRACT_MUTATION } from '../../graphQl/mutations'
import { GET_CONTACT_BY_ID } from '../../graphQl/subscriptions'
import client from '../../apollo/apollo'

let yourForm;

class JsonFormBuilder extends Component {



  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      transactionData: this.props && this.props.detailData ? this.props.detailData : {},
      contactMail: this.props && this.props.detailData ? this.props.detailData.email : '',
      contactPhone: this.props && this.props.detailData ? this.props.detailData.phoneNumber : '',
      editedContacts: false
    }
  }

  componentDidMount() {
    console.log(this.props.detailData, 'dededed')
    this.setState({
      transactionData: this.props && this.props.detailData ? this.props.detailData : {},
      // contactMail: this.props.detailData.email ? this.props.detailData.email : '',
      // contactPhone: this.props.detailData.phoneNumber ? this.props.detailData.phoneNumber : ''
    })

    this.getContactInfoByContactID(this.props.contactId)

  }


  componentWillReceiveProps(newProps) {
    console.log('reveiceprop', newProps)
    this.setState({
      transactionData: newProps && newProps.detailData ? newProps.detailData : {},
      // contactMail: newProps.detailData.email ? newProps.detailData.email : '',
      // contactPhone: newProps.detailData.phoneNumber ? newProps.detailData.phoneNumber : ''
    })

    // if (!this.state.editedContacts) {
    //   this.setState({
    //     contactMail: newProps.detailData.email ? newProps.detailData.email : '',
    //     contactPhone: newProps.detailData.phoneNumber ? newProps.detailData.phoneNumber : ''
    //   })
    // }

    //when saving edit transaction
    if (newProps.isSaveEditContract === true) {
      yourForm.submit()
    }

    if (this.props.contactId !== newProps.contactId) {
      this.getContactInfoByContactID(newProps.contactId)
    }
  }

  getContactInfoByContactID = (contactId) => {
    if (contactId) {
      client.subscribe({
        query: GET_CONTACT_BY_ID,
        variables: { id: contactId }
      }).subscribe(res => {
        if (res && res.data && res.data.contact && res.data.contact.length > 0) {
          this.setState({
            contactMail: res.data.contact[0].email ? res.data.contact[0].email : '',
            contactPhone: res.data.contact[0].phoneNumber ? res.data.contact[0].phoneNumber : ''
          })
        }
      }, err => {
      }, err => {
      })
    }
  }


  async onSubmit({ formData }, e) {

    if (this.props.ItemDetail && this.props.ItemDetail.id) {
      formData.email = this.state.contactMail
      formData.phoneNumber = this.state.contactPhone
      this.setState({ loading: true, transactionData: formData }, () => {
        let oldNumber = this.props.ItemDetail.formData && this.props.ItemDetail.formData.phoneNumber ?
          this.props.ItemDetail.formData.phoneNumber : ""
        let newNumber = formData.phoneNumber
        let oldEmail = this.props.ItemDetail.formData && this.props.ItemDetail.formData.email ?
          this.props.ItemDetail.formData.email : ""
        let newEmail = formData.email


        const variableObject = {
          formData: formData,
          transactionId: this.props.ItemDetail.id,
        }

        // variableObject['oldNumber'] = oldNumber;
        // variableObject['newNumber'] = newNumber;
        // variableObject['oldEmail'] = oldEmail;
        // variableObject['newEmail'] = newEmail;
        // variableObject['listingId'] = localStorage.getItem("selectedListingId");
        variableObject['contactId'] = this.props.contactId
        variableObject['email'] = this.state.contactMail
        variableObject['phoneNumber'] = this.state.contactPhone

        const contactObject = {
          id: this.props.contactId,
          email: this.state.contactMail,
          phoneNumber: this.state.contactPhone
        }
        console.log(contactObject, 'contactonj')

        client.mutate({ mutation: UPDATE_TRANSACTION, variables: variableObject }).then((response) => {
          client.mutate({ mutation: UPDATE_CONTACT_FROM_CONTRACT_MUTATION, variables: contactObject }).then((response) => {
            console.log('response', response)
            this.setState({ loading: false })
            message.success('Saved SuccessFully')
            if (this.props.GoBackToDetail) {
              this.props.GoBackToDetail()
            }
          })
            .catch(err => {
              console.log('error', err)
              message.error('Add Contact Failed')
            });
        })
          .catch(err => {
            this.setState({ loading: false })
            message.error('Save Failed')
          });
      })

    }
  }

  sumbitValues = () => {
    yourForm.submit()
  }

  render() {
    const { formData, detailData, isEdit } = this.props
    const { transactionData } = this.state

    console.log(detailData, transactionData, 'ddata');
    console.log("contactId123", this.props.contactId)
    return (
      <div className="form">
        {
          isEdit === false ?
            // for edit contact
            <Spin spinning={this.state.loading}>
              <label>Email</label>
              <Input
                id='email'
                style={{ width: '100%', marginBottom: '1rem' }}
                value={this.state.contactMail}
                onChange={(e) => { this.setState({ contactMail: e.target.value, editedContacts: true }) }}

              />
              <label>Phonenumber</label>
              <Input
                id='phoneNumber'
                style={{ width: '100%', marginBottom: '1rem' }}
                value={this.state.contactPhone}
                onChange={(e) => { this.setState({ contactPhone: e.target.value, editedContacts: true }) }}

              />
              <Form schema={formData.JSONSchema} formData={transactionData}
                disabled={isEdit}
                onSubmit={this.onSubmit.bind(this)}
                liveValidate uiSchema={formData.UISchema} children={<br />}
                ref={(form) => { yourForm = form; }}
              >
              </Form>
            </Spin>
            :
            // for read only
            <>
              <label>Email</label>
              <Input
                id='email'
                style={{ width: '100%', marginBottom: '1rem' }}
                value={this.state.contactMail}
                // value={detailData.email}
                disabled={true}

              />
              <label>Phonenumber</label>
              <Input
                id='phoneNumber'
                style={{ width: '100%', marginBottom: '1rem' }}
                // value={detailData.phoneNumber}
                disabled={true}
                value={this.state.contactPhone}
              />
              <Form schema={formData.JSONSchema} formData={detailData}
                disabled={true} liveValidate uiSchema={formData.UISchema} children={<br />} />
            </>
        }

      </div>
    );
  }
}

export default JsonFormBuilder;