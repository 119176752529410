export default [
  {
    'title': 'Perform Re-Notify',
    'confirmMsg': "Are you sure you want to re-notify?",
    'showForSignedStatus': true
  },
  {
    'title': 'Status Change',
    'confirmMsg': "Are you sure you want to change the status?",
    'showForSignedStatus': false
  },
  {
    'title': 'Download as CSV',
    'confirmMsg': "Are you sure you want to download?",
    'showForSignedStatus': true
  },
  {
    'title': 'Download item order summary CSV',
    'confirmMsg': "Are you sure you want to download item order summary csv?",
    'showForSignedStatus': true
  },
  // {
  //   'title': 'Download Product Inventory CSV',
  //   'confirmMsg': "Are you sure you want to Download Product Inventory CSV?",
  //   'showForSignedStatus': true
  // },
  {
    'title': 'Delete All',
    'confirmMsg': "Are you sure you want to delete?",
    'showForSignedStatus': false
  },
  {
    'title': 'Add Tags',
    'confirmMsg': "Are you sure you want to add tags?",
    'showForSignedStatus': false
  },
];
