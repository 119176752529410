export function saveStatus(statusArray) {
    console.log('statusArray', statusArray)
    let mutationGqlStr = ``;
    let mutationFragmentsGqlStr = ``;

    // get total count
    let totalStatus = statusArray.length;
    let processCompleted = 0;
    let i;

    // process each count
    for (i = 0; i < totalStatus; i++) {

        let statusObj = statusArray[i];

        // dynamic gql
        if (statusObj.id == null && statusObj.type == "CREATE") {

            mutationGqlStr = `mutation_${i}: insert_transaction_template_status(objects: {
                    isDefaultYn: ${statusObj.isDefaultYn},
                    statusDesc:  "${statusObj.statusDesc}",
                    statusName:  "${statusObj.statusName}",
                    isChangeable:  ${statusObj.isChangeable},
                    transactionTemplateId: "${statusObj.transactionTemplateId}",
                }) {
                    returning {
                      ...transaction_template_status_fields
                    }
                }
                `

            processCompleted = processCompleted + 1;

        } else {
            if (statusObj.type == "UPDATE") {

                mutationGqlStr = `mutation_${i}: update_transaction_template_status(where: {
                        id: {_eq: "${statusObj.id}"}
                    }, _set: {
                        isDefaultYn: ${statusObj.isDefaultYn},
                        statusDesc:  "${statusObj.statusDesc}",
                        statusName:  "${statusObj.statusName}",
                        isChangeable:  ${statusObj.isChangeable},
                        transactionTemplateId: "${statusObj.transactionTemplateId}"
                    }) {
                        returning {
                          ...transaction_template_status_fields
                        }
                    }
                    `

                processCompleted = processCompleted + 1;
            }

            if (statusObj.type == "DELETE") {

                mutationGqlStr = `mutation_${i}: delete_transaction_template_status(where: {
                        id: {_eq: "${statusObj.id}"}
                    }) {
                        returning {
                          ...transaction_template_status_fields
                        }
                    }
                    `

                processCompleted = processCompleted + 1;

            }
        }

        mutationFragmentsGqlStr = `${mutationFragmentsGqlStr} ${mutationGqlStr} `;

    }

    let finalGqlStr = `mutation {
                    ${mutationFragmentsGqlStr}
                }
                fragment transaction_template_status_fields on transaction_template_status {
                    id
                    isDefaultYn
                    statusDesc
                    statusName
                    transactionTemplateId
                    isChangeable
                }
            `;

    console.log('finalGqlStr', finalGqlStr);

    return finalGqlStr;

}


